import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import "../../StudentDetail.scss";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Input } from "@components/molecules/Input";
import { Modal } from "@components/organisms/modal/Modal";

import { deleteUser } from "@containers/school/Students/StudentDetail/StudentDetailAPI";

export function DeleteStudentModal({ student, show, onClose }) {
	const [cantDelete, setCantDelete] = useState(true);
	const [inputValue, setInputValue] = useState("");
	const navigate = useNavigate();
	const isDelete = (str) => {
		if (str?.toUpperCase() !== "SUPPRIMER") {
			return false;
		}
		return true;
	};

	const deleteStudent = (id) => {
		deleteUser(id).then(() => {
			navigate("/students", {
				state: {
					isDeletingStudent: true,
				},
			});
		});
	};

	const handleOnChange = (event) => {
		setInputValue(event.target.value);

		if (isDelete(event.target.value)) {
			setCantDelete(false);
		} else setCantDelete(true);
	};
	return (
		<div>
			<Modal
				show={show}
				onClose={() => {
					onClose();
				}}
				title={`Supprimer ${student.firstname} ${student.lastname}`}
				body={
					<section className="delete-modal-container">
						<p className="delete-modal-alert">
							Attention, la suppression ne doit être utilisée que pour corriger
							des erreurs. Vous pouvez mettre vos étudiants en statut
							&quot;Placé&quot; ou &quot;Abandon&quot; s&apos;ils ont fini leur
							recherche.
						</p>
						<p className="font-semifont-bold mt-md text-xsm text-primary-700P">
							Voulez-vous supprimer cet étudiant ?
						</p>
						<p className="mt-sm text-xsm text-primary-700P">
							Pour confirmer la suppresion, saisissez &quot;SUPPRIMER&quot;
							ci-dessous.
						</p>
						<Input
							onChange={handleOnChange}
							value={inputValue}
							type="text"
							className="mt-xsm"
							placeholder="Saisissez SUPPRIMER pour valider la suppresion"
						/>
					</section>
				}
				buttonsRight={[
					<ButtonSecondary
						label="Annuler"
						onClick={() => {
							setInputValue("");
							onClose();
						}}
					/>,
					<ButtonPrimary
						disabled={cantDelete}
						onClick={() => {
							deleteStudent(student.id);
						}}
						label="Confirmer la suppresion"
					/>,
				]}
			/>
		</div>
	);
}

DeleteStudentModal.propTypes = {
	student: PropTypes.shape({
		id: PropTypes.string,
		firstname: PropTypes.string,
		lastname: PropTypes.string,
	}).isRequired,
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};
