import type { EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect } from "react";

import {
	gridOutline,
	link2Outline,
	pinOutline,
	videoOutline,
} from "@assets/Icons";

import { checkStartUrlAndAutocomplete } from "@tools/Checkers";

import { CardTertiary } from "@components/molecules/CardTertiary";
import { Input } from "@components/molecules/Input";
import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";

export function EventLocation() {
	const eventToCreate = useAppSelector((state) => state.events.eventToCreate);
	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;

	const handleLocationChange = (
		location: "Distanciel" | "Sur site" | "Hybride",
	) => {
		dispatchEvent(actions.setEventToCreate({ ...eventToCreate, location }));
	};

	const handleOnlineLinkChange = (link: string) => {
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				eventLink:
					link.length !== 0 ? checkStartUrlAndAutocomplete(link) : undefined,
			}),
		);
	};

	const handleAddressChange = (newAddressValue: string) => {
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				address: newAddressValue.length !== 0 ? newAddressValue : undefined,
			}),
		);
	};

	useEffect(() => {
		if (!eventToCreate) {
			dispatchEvent(actions.setEventToCreate({ location: "Sur site" }));
		}
	}, [dispatchEvent, eventToCreate]);

	return (
		<article
			data-testid="eventPlaceSection"
			className="flex w-full flex-col gap-6 rounded-lg bg-white px-6 py-4"
		>
			<div
				className="text-sm font-bold text-primary-700P"
				data-testid="eventPlaceSectionTitle"
			>
				Localisation
			</div>
			<div
				className="flex justify-between gap-4"
				data-testid="eventPlaceSelectionRow"
			>
				<CardTertiary
					title="Sur site"
					icon={pinOutline}
					className="flex-1"
					selected={
						eventToCreate
							? checkIfOptionIsSelected(eventToCreate, "Sur site")
							: true
					}
					onSelect={() => handleLocationChange("Sur site")}
				/>
				<CardTertiary
					title="A distance"
					icon={videoOutline}
					className="flex-1"
					selected={
						eventToCreate
							? checkIfOptionIsSelected(eventToCreate, "Distanciel")
							: false
					}
					onSelect={() => handleLocationChange("Distanciel")}
				/>
				<CardTertiary
					title="Hybride"
					icon={gridOutline}
					className="flex-1"
					selected={
						eventToCreate
							? checkIfOptionIsSelected(eventToCreate, "Hybride")
							: false
					}
					onSelect={() => handleLocationChange("Hybride")}
				/>
			</div>
			{renderOptionsInputs(
				eventToCreate,
				handleOnlineLinkChange,
				handleAddressChange,
			)}
		</article>
	);
}

const checkIfOptionIsSelected = (
	eventToCreate: EventType,
	option: "Distanciel" | "Sur site" | "Hybride",
) => {
	return eventToCreate.location === option;
};

const renderOptionsInputs = (
	event: EventType | undefined,
	handleLinkChange: Function,
	handleAddressChange: Function,
) => {
	const placeType = event?.location;
	return (
		<div className="flex flex-col gap-4">
			{placeType === "Distanciel" || placeType === "Hybride" ? (
				<Input
					icon={link2Outline}
					useURLEncoding
					value={event?.eventLink || ""}
					label="Ajouter un lien"
					placeholder="Lien de l'évènement en ligne"
					type="text"
					onChange={(e) => handleLinkChange(e.target.value)}
				/>
			) : (
				<></>
			)}
			{placeType === "Sur site" || placeType === "Hybride" ? (
				<Input
					icon={pinOutline}
					value={event?.address ? event.address : ""}
					label="Lieu"
					placeholder="ex : 36 Pl. Napoléon III 75010 Paris"
					type="text"
					onChange={(e) => {
						handleAddressChange(e.target.value);
					}}
				/>
			) : (
				<></>
			)}
		</div>
	);
};
