import type {
	ResourceCollectionType,
	ResourceName,
	ResourceType,
} from "@/types/resourcesTypes";
import type { UserType } from "@/types/userTypes";
import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import { useContext, useEffect, useState } from "react";
import { getResources } from "./ResourcesAPI";

export type UseResourcesNavigationType = {
	motivationLetter: string;
	cvModels: string;
	resources: string;
	tabSelected: ResourcesNavigationType;
	selectThisTab: (tab: ResourcesNavigationType) => void;
	user: UserType | undefined;
	callUpdateResourcesList: (newFilter: any) => void;
	isLoading: boolean;
	mgResources: ResourceType[];
	schoolResources: ResourceType[];
	currentFilter: Pick<ResourceType, "type">;
	isSchool: boolean;
};

export type ResourcesNavigationType =
	| "motivationLetterModels"
	| "cvModels"
	| "resources";

type NavigationFilter = {
	name: string;
	value: ResourceName;
};

const FILTER_TO_RESOURCE_TYPE: Record<string, ResourceName> = {
	motivationLetterModels: "motivationLetterModel",
	cvModels: "cvModel",
	resources: "resource",
};

const DEFAULT_FILTER: NavigationFilter = {
	name: "type",
	value: "resource",
};

export const useResourcesNavigation = (
	defaultTab: ResourcesNavigationType,
): UseResourcesNavigationType => {
	const resolveNavigationOutlets = (
		outletName: ResourcesNavigationType,
		isSchool: boolean,
	) => {
		switch (outletName) {
			case "motivationLetterModels":
				return isSchool
					? "/school-resources-motivation-letter"
					: "/motivation-letter";
			case "cvModels":
				return isSchool ? "/school-resources-cv-models" : "/cv-models";
			case "resources":
				return isSchool ? "/school-resources" : "/resources";
			default:
				return isSchool ? "/school-resources" : "/resources";
		}
	};

	const updateResourcesList = async (
		newFilter: NavigationFilter = DEFAULT_FILTER,
	) => {
		if (!user) return;
		const newFilterList: any = filter;
		if (newFilter) {
			newFilterList[newFilter.name] = newFilter.value;
			setFilter(newFilterList);
		}
		setLoad(true);
		getResources(user.school, newFilterList).then(
			({
				schoolResources,
				mgResources,
			}: {
				schoolResources: ResourceCollectionType;
				mgResources: ResourceCollectionType;
			}) => {
				setMgResources(mgResources.collection);
				setSchoolResources(schoolResources.collection);
				setLoad(false);
			},
		);
	};

	const handleSetTab = (tab: ResourcesNavigationType) => {
		if (tab === tabSelected) return;
		setTabSelected(tab);
	};
	const [load, setLoad] = useState(true);
	const [tabSelected, setTabSelected] =
		useState<ResourcesNavigationType>(defaultTab);

	const [filter, setFilter] = useState<Pick<ResourceType, "type">>({
		type: "resource",
	});
	const [mgResources, setMgResources] = useState<ResourceType[]>([]);
	const [schoolResources, setSchoolResources] = useState<ResourceType[]>([]);

	const { user, currentInterface } =
		useContext<GlobalContextType>(GlobalContext);

	const isSchool = currentInterface === "school";
	useEffect(() => {
		updateResourcesList({
			name: "type",
			value: FILTER_TO_RESOURCE_TYPE[defaultTab],
		});
		setTabSelected(defaultTab);
	}, [defaultTab]);

	return {
		motivationLetter: resolveNavigationOutlets(
			"motivationLetterModels",
			isSchool,
		),
		cvModels: resolveNavigationOutlets("cvModels", isSchool),
		resources: resolveNavigationOutlets("resources", isSchool),
		tabSelected,
		selectThisTab: handleSetTab,
		user,
		callUpdateResourcesList: updateResourcesList,
		isLoading: load,
		mgResources,
		schoolResources,
		currentFilter: filter,
		isSchool,
	};
};
