import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import { fetchReferents } from "@containers/school/Referents/core/usecases/fetchReferents.usecase";
import { GlobalContext } from "@navigation/Router";
import { extractIdNumberFromId } from "@tools/Users";
import { useContext } from "react";
import { CompanyDetailsPresenter } from "../../core/companyDetails/companyDetails.presenter";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";
import { endOfCompanyIdExtraction } from "../../core/usecases/endOfCompanyIdExtraction.usecase";

export const useCompanyDetails = () => {
	const { status, referentsListReceived } = useAppSelector(
		(state) => state.referents,
	);
	const retrieveAllReferents = async () => {
		if (
			status === "loading" ||
			referentsListReceived?.schoolReferents?.length !== 0
		)
			return;
		const campusId = user?.campuses[0].id;
		const campusList = [];
		if (campusId) {
			campusList.push(extractIdNumberFromId(campusId).toString());
		}
		dispatch(fetchReferents(campusList, `${user?.school}`));
	};

	const startCompanyIdExtraction = () => {
		const url = window.location.href;
		const idFound = presenter.retrieveCompanyIdFromUrl(url);
		if (idFound.success) {
			dispatch(endOfCompanyIdExtraction(idFound.id));
		} else {
			ErrorToast("Merci de renseigner un identifiant valide.");
		}
	};

	const getTargetCompany = () => {
		return detailPageState?.targetCompany;
	};

	const resetDetailsState = () => {
		dispatch(companiesManagementSlice.actions.resetDetailsState);
	};

	const presenter = new CompanyDetailsPresenter();
	const { user } = useContext(GlobalContext);
	const dispatch = useAppDispatch();
	const { detailPageState } = useAppSelector(
		(state) => state.companiesManagement,
	);

	return {
		retrieveAllReferents,
		startCompanyIdExtraction,
		user,
		resetDetailsState: resetDetailsState,
		isExtractingId: detailPageState?.isExtractingId,
		targetCompany: getTargetCompany(),
	};
};
