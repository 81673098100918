import { CheckboxLabelPrimary } from "@components/molecules/CheckboxLabel";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";
import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

function LetterRequiredModifier() {
	const dispatchEvent = useDispatch();
	const { motivationLetterRequired } = useAppSelector(
		(state) => state.offers.offerToCreate,
	);
	const handleApplicationLetterRequirements = (value: boolean) => {
		dispatchEvent(modifyOfferToCreate({ motivationLetterRequired: value }));
	};

	return (
		<div data-testid="LetterRequiredModifier" className="mb-sm">
			<CheckboxLabelPrimary
				className="primary-50 mt-3"
				label="Lettre de motivation obligatoire pour postuler"
				checked={motivationLetterRequired || false}
				onCheck={(e: boolean) => {
					handleApplicationLetterRequirements(e);
				}}
			/>
		</div>
	);
}

export default LetterRequiredModifier;
