import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { STUDENT_MANAGEMENT_TABS } from "@constants/Table";

import { ScrollableTabGroup } from "@components/molecules/TabGroup";
import { setPageState } from "@components/organisms/usersFilters/usersFiltersSlice";

import { EState, type UserCompleteType } from "@/types/userTypes";
import type { StandardGraphQLResponse } from "../../../../../tools/API";
import { getStudentsToRelaunch } from "../StudentsManagementAPI";
import type { FetchStudensListParamsType } from "../studentsManagementSlice";

import { TableView } from "./StudentsList/TableView";

type StudentsListType = {
	buildParams: (
		useInitialStateOfFilters: boolean,
	) => FetchStudensListParamsType;
};

export function StudentsList({ buildParams }: StudentsListType) {
	const dispatchEvent = useDispatch();
	const [checkAllLoader, setCheckAllLoader] = useState<boolean>(false);
	const {
		studentsListTotalCount,
		requestedUsersCount,
		requestedUsersCountLoading,
	} = useAppSelector((state) => state.studentsManagement);
	const [selectedStudents, setSelectedStudents] = useState<UserCompleteType[]>(
		[],
	);
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);
	const handleOnCheckStudent = (student: UserCompleteType) => {
		const index = selectedStudents.findIndex(
			(studentToCheck: { id: string }) => studentToCheck.id === student.id,
		);

		if (index === -1) {
			setSelectedStudents([...selectedStudents, student]);
		} else {
			const newSelectedStudents = [...selectedStudents];
			newSelectedStudents.splice(index, 1);
			setSelectedStudents(newSelectedStudents);
		}
	};

	const handleOnCheckAll = async () => {
		if (selectedStudents.length === studentsListTotalCount) {
			setSelectedStudents([]);
			return;
		}

		setCheckAllLoader(true);
		const params = buildParams(false);
		params.filters.itemsPerPage = 100000;
		const { data }: StandardGraphQLResponse = await getStudentsToRelaunch(
			params.formatView,
			params.filters,
		);
		setSelectedStudents(data.users.collection);
		setCheckAllLoader(false);
	};

	const checkStateExist = (): boolean => {
		if (currentFilters?.state === undefined) return false;
		if (STUDENT_MANAGEMENT_TABS.includes(currentFilters.state)) {
			return true;
		}
		return false;
	};
	useEffect(() => {
		if (currentFilters?.state === undefined || !checkStateExist()) {
			dispatchEvent(setPageState({ page: 1, state: "all" }));
		}
	}, []);

	return (
		<section>
			<ScrollableTabGroup
				tabs={[
					{
						label: "Tous les étudiants",
						active: currentFilters?.state === "all",
						state: "all",
					},
					{
						label: "En recherche active",
						active: currentFilters?.state === EState.ACTIVE,
						state: EState.ACTIVE,
					},
					{
						label: "En attente de connexion",
						active: currentFilters?.state === EState.WAIT_CONNECTION,
						state: EState.WAIT_CONNECTION,
					},
					{
						label: `Statuts à approuver (${
							requestedUsersCountLoading ? "..." : requestedUsersCount
						})`,
						active: currentFilters?.state === EState.WAIT_APPROVAL,
						state: EState.WAIT_APPROVAL,
					},
				]}
				onTabChange={(i) => {
					setSelectedStudents([]);
					switch (i) {
						case 1:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: EState.ACTIVE,
								}),
							);
							break;
						case 2:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: EState.WAIT_CONNECTION,
								}),
							);
							break;
						case 3:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: EState.WAIT_APPROVAL,
								}),
							);
							break;
						default:
							dispatchEvent(
								setPageState({ ...currentFilters, page: 1, state: "all" }),
							);
							break;
					}
				}}
			/>
			<TableView
				setSelectStudents={setSelectedStudents}
				handleOnCheckAll={handleOnCheckAll}
				checkAllLoader={checkAllLoader}
				selectedStudents={selectedStudents}
				handleOnCheckStudent={handleOnCheckStudent}
			/>
		</section>
	);
}
