import React from "react";

import { getContractType } from "@tools/Offers";

export function Information({ selectedStudent }) {
	return (
		<div>
			<div className="mt-xlg flex items-center justify-between">
				<p className="text-xsm font-bold text-primary-200">Recherche</p>
				<p className="text-xsm text-primary-700P">
					{selectedStudent.contractSearched
						? getContractType(selectedStudent.contractSearched).label
						: "Non renseigné"}
				</p>
			</div>
			<div className="mt-sm flex items-center justify-between">
				<p className="text-xsm font-bold text-primary-200">Email</p>
				<p className="text-xsm text-primary-700P">{selectedStudent.email}</p>
			</div>
			<div className="mt-sm flex items-center justify-between">
				<p className="text-xsm font-bold text-primary-200">Téléphone</p>
				<p className="text-xsm text-primary-700P">
					{selectedStudent.phone ? selectedStudent.phone : "Non renseigné"}
				</p>
			</div>
			<div className="mt-sm flex items-center justify-between">
				<p className="text-xsm font-bold text-primary-200">Adresse</p>
				<p className="text-xsm text-primary-700P">
					{selectedStudent.address ? selectedStudent.address : "Non renseigné"}
				</p>
			</div>
			<hr className="my-sm" />
		</div>
	);
}
