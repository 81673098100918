import React, { useEffect, useState } from "react";
import {
	NavLink,
	useLocation,
	useNavigate,
	useOutletContext,
} from "react-router-dom";

import { arrowBackOutline, trash2Fill, trash2Outline } from "@assets/Icons";

import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonQuinary } from "@components/atoms/ButtonQuinary";

import { getResourceByID } from "@containers/school/Resources/ResourceEditor/ResourceEditorAPI";
import { Layout } from "@containers/school/Resources/ResourceEditor/ui/Layout";
import { ResourceLoader } from "@containers/school/Resources/ResourceEditor/ui/ResourceLoader";

import { DeleteResourceModal } from "./ui/Modal/DeleteResourceModal";

export function ResourceEditor() {
	const location = useLocation();
	const { setTitle } = useOutletContext();
	const [resourceId] = useState(
		`/referent/resources/${location.pathname.split("/")[3]}`,
	);
	const [newResource, setNewResource] = useState({
		color: "bg-accent-1",
		type: "resource",
	});
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const getResource = () => {
		if (resourceId) {
			setLoading(true);
			getResourceByID(resourceId).then((data) => {
				setLoading(false);
				if (data?.isMentorgoal) navigate("/403");
				setNewResource({ ...newResource, ...data });
			});
		}
	};

	useEffect(() => {
		setTitle("Mes ressources");
		getResource();
	}, [setNewResource]);

	return (
		<main className=" w-full gap-4 xxl:gap-8">
			{loading ? (
				<ResourceLoader />
			) : (
				<div className="w-full">
					<div className="mb-md flex items-center justify-between rounded-lg bg-white p-sm">
						<div className="flex items-center">
							<NavLink
								exact
								to="/school-resources"
								className="text-decoration-none"
							>
								<ButtonQuinary icon={arrowBackOutline} />
							</NavLink>

							<p className="ml-sm text-sm font-bold text-primary-700P">
								{window.location.pathname.split("/")[2] === "edit"
									? newResource.title
									: "Nouvelle ressource"}
							</p>
						</div>
						<div className="flex items-center">
							{window.location.pathname.split("/")[2] !== "create" && (
								<ButtonQuaternary
									id="deleteResourceBtn"
									className="ml-md"
									icon={trash2Outline}
									activeIcon={trash2Fill}
									onClick={() => {
										setDeleteConfirmation(!deleteConfirmation);
									}}
								/>
							)}
						</div>
					</div>
					<Layout
						loadingState={{ loading, setLoading }}
						resourceId={resourceId}
						newResourceProps={{ newResource, setNewResource }}
					/>
				</div>
			)}

			<DeleteResourceModal
				show={deleteConfirmation}
				onClose={() => setDeleteConfirmation(!deleteConfirmation)}
				resourceId={newResource.id}
			/>
		</main>
	);
}
