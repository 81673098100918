import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";
import React from "react";
import "@assets/sass/Inputs.scss";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { PasswordInput } from "@components/molecules/InputPassword";
import { checkPassword } from "@tools/Checkers";
import "react-nice-dates/build/style.css";

type InputDoublePasswordType = {
	className?: string;
	onValidate: () => void;
	onChange: (value: string) => void;
	onChangeConfirm: (value: string) => void;
	buttonLabel: string;
	title?: string;
	firstPlaceholder: string;
	secondPlaceholder: string;
};

export function InputDoublePassword({
	className = "",
	onValidate,
	onChange,
	onChangeConfirm,
	buttonLabel,
	title,
	firstPlaceholder,
	secondPlaceholder,
}: InputDoublePasswordType) {
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordVerify, setNewPasswordVerify] = useState("");
	const [blurred, setBlurred] = useState(false);
	const [passwordBtnDisabled, setPasswordBtnDisabled] = useState(true);
	const [passwordError, setPasswordError] = useState<{
		matching?: string;
		rgpd?: string;
		misc?: string;
	}>({});

	useEffect(() => {
		if (blurred) checkPasswordValue();
		else setPasswordBtnDisabled(true);
	}, [newPassword, newPasswordVerify]);

	const handleBlur = () => {
		checkPasswordValue();
		if (newPassword.length && newPasswordVerify.length) {
			setBlurred(true);
		}
	};
	const handleConfirmBlur = () => {
		checkPasswordValue();
		setBlurred(true);
	};

	const checkPasswordValue = () => {
		if (!newPassword || !newPasswordVerify) {
			setPasswordBtnDisabled(true);
		}
		const pwCheck = checkPassword(
			newPassword,
			newPasswordVerify.length ? newPasswordVerify : undefined,
		);
		if (pwCheck.missing.length) {
			setPasswordError({ rgpd: pwCheck.message });
			setPasswordBtnDisabled(true);
		} else if (!pwCheck.status) {
			setPasswordError({ matching: pwCheck.message });
			setPasswordBtnDisabled(true);
		} else if (newPassword && newPasswordVerify) {
			setPasswordError({});
			setPasswordBtnDisabled(false);
		} else {
			setPasswordError({});
		}
	};

	const handleConfirmChange = (value: string) => {
		setNewPasswordVerify(value);
		onChangeConfirm(value);
	};

	return (
		<div className={className}>
			{title && <h3>{title}</h3>}
			<div onBlur={handleBlur} className="mt-lg w-full">
				<PasswordInput
					key="mainPassword"
					className="w-full [&>input]:bg-primary-50"
					name="newPassword"
					errorMessage={passwordError.rgpd}
					value={newPassword}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setNewPassword(e.target.value);
						onChange(e.target.value);
					}}
					placeholder={firstPlaceholder}
				/>
			</div>
			<div onBlur={handleConfirmBlur} className="mt-lg w-full">
				<PasswordInput
					key="repeatPassword"
					showRules={false}
					errorMessage={passwordError.matching}
					className="mt-lg w-full [&>input]:bg-primary-50"
					name="newPasswordVerify"
					value={newPasswordVerify}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						handleConfirmChange(e.target.value);
					}}
					placeholder={secondPlaceholder}
				/>
			</div>
			<div className="flex justify-end">
				<ButtonPrimary
					label={buttonLabel}
					className="w-fit-content mt-lg"
					disabled={passwordBtnDisabled}
					onClick={onValidate}
				/>
			</div>
		</div>
	);
}
