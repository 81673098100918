import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

import type { FiltersType } from "@/types/usersFiltersTypes";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { stringifyRequestFilters } from "@tools/Objects";

import type { ViewType } from "@/types/genericType";
import type { SelectionType } from "@/types/profileSharingTypes";
import { getSchoolSubDomain, querySelections } from "./ProfileSharingAPI";
import { Head } from "./ui/Head";
import { TableView } from "./ui/TableView";
export type ProfilSharingFiltersType = FiltersType & {
	search?: string;
	spsReferent_coreSchool?: string;
	coreCampuses?: string[];
};

export function ProfileSharing() {
	const { structureView, setTitle } = useOutletContext<{
		structureView: ViewType;
		setTitle: Function;
	}>();
	const { user } = useContext(GlobalContext);
	const [page, setPage] = useState<number>(1);
	const [filters, setFilters] = useState<ProfilSharingFiltersType>({
		page,
		search: "",
	});

	useEffect(() => {
		const newFilters = { ...filters };
		if (Object.prototype.hasOwnProperty.call(structureView, "school")) {
			delete newFilters.coreCampuses;
			newFilters.spsReferent_coreSchool = user!.school;
		} else {
			delete newFilters.spsReferent_coreSchool;
			if (structureView.campus)
				newFilters.coreCampuses = [structureView.campus];
		}
		setFilters(newFilters);
	}, [structureView]);

	const [load, setLoad] = useState<boolean>(true);
	const [selections, setSelections] = useState<SelectionType[]>([]);
	const [lastPage, setLastPage] = useState<number>(1);
	const fetchSelections = () => {
		setLoad(true);
		const stringifyFilters = stringifyRequestFilters(filters);

		type QuerySelectionType = {
			collection: SelectionType[];
			paginationInfo: { lastPage: number };
		};

		querySelections(stringifyFilters).then(
			async ({ collection, paginationInfo }: QuerySelectionType) => {
				setSelections(collection);
				setLastPage(paginationInfo.lastPage);
				const response = await getSchoolSubDomain(user!.school);
				if (response.responseLabel === "success") {
					user!.schoolSubdomain = response.data.school.subdomain;
					setLoad(false);
				} else {
					ErrorToast(`Une erreur est survenue : ${response.message}`);
				}
			},
		);
	};

	useEffect(() => {
		setTitle("Partage de profils");
	}, [structureView]);

	useEffect(() => {
		fetchSelections();
	}, [filters]);

	return (
		<main className="flex flex-col gap-md">
			<Head filtersProps={{ filters, setFilters }} load={load} />
			<TableView
				onDelete={fetchSelections}
				pageProps={{ page, setPage }}
				selections={selections}
				lastPage={lastPage}
				filtersProps={{ filters, setFilters }}
				load={load}
				user={user}
			/>
		</main>
	);
}
