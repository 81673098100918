import React from "react";
import { Droppable } from "react-beautiful-dnd";

import { Card } from "./Card";
import type { CardType } from "./index";

export const ColumnContent = ({
	cards,
	columnId,
	columnStrongColor,
	onCardClick,
}: {
	cards: CardType[];
	columnId: number;
	columnStrongColor: string;
	onCardClick: (card: CardType) => void;
}) => {
	return (
		<Droppable key={`COLUMN-${columnId}`} droppableId={`COLUMN-${columnId}`}>
			{(provided, snapshot) => (
				<div
					className={`flex flex-1 flex-col overflow-y-auto p-4 pt-0 ${
						snapshot.isDraggingOver ? columnStrongColor : ""
					}`}
					{...provided.droppableProps}
					ref={provided.innerRef}
				>
					{cards.map((card, index) => {
						return (
							<Card
								key={`ATS-CARD-${card.id}`}
								index={index}
								card={card}
								onCardClick={onCardClick}
							/>
						);
					})}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};
