import { Icon } from "@iconify/react";
import { format } from "date-fns";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "../../ApplicationDetails.scss";

import {
	alertTriangleOutline,
	bellOutline,
	calendarOutline,
} from "@assets/Icons";

import { formatDate } from "@tools/Dates";
import { capitalizeFirstLetter } from "@tools/Strings";

import { Checkbox } from "@components/atoms/Checkbox";
import { Divider } from "@components/atoms/Divider";
import { ListPrimary } from "@components/molecules/ListPrimary";
import { AccordionCustom } from "@components/organisms/Accordion";
import { Success } from "@components/organisms/toast/ToastNotification";
import { Table } from "@components/templates/table/Table";

import {
	deleteAction,
	editAction,
} from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";
import { ManageActionModal } from "@containers/student/Applications/ApplicationDetail/ui/Modals/ManageActionModal";

export function ActionFlow({ actionsState, contacts }) {
	const [showManageActionModal, setShowManageActionModal] = useState(false);

	const [selectedAction, setSelectedAction] = useState({});
	const { actions, setActions } = actionsState;

	const getTimeOfAction = (date) => {
		if (!date) return {};
		const momentDate = moment(date.split("/").reverse().join("-"));
		const result = moment().diff(momentDate, "h");
		if (result < 0) {
			return {
				label: "À venir",
				iconColor: "warning-dark",
				icon: calendarOutline,
			};
		}
		if (result > 24) {
			return {
				label: "En retard",
				iconColor: "error-dark",
				icon: alertTriangleOutline,
			};
		}
		return {
			label: "Aujourd'hui",
			iconColor: "success-dark",
			icon: bellOutline,
		};
	};

	const getTimeOfActionElement = (date) => {
		const momentDate = moment(date.split("/").reverse().join("-"));
		const result = moment().diff(momentDate, "h");
		if (result < 0) {
			return (
				<Icon
					icon={calendarOutline}
					className="text-warning-dark"
					height={24}
					width={24}
					label="A venir"
				/>
			);
		}
		if (result > 24) {
			return (
				<Icon
					icon={alertTriangleOutline}
					className="text-error-dark"
					height={24}
					width={24}
					label="En retard"
				/>
			);
		}
		return (
			<Icon
				icon={bellOutline}
				className="text-success-dark"
				height={24}
				width={24}
				label="Ajourd'hui"
			/>
		);
	};

	const toggleAction = (action) => {
		const actionsCopy = [...actions];

		const targetIndex = actionsCopy.indexOf(action);

		const targetAction = { ...actionsCopy.find((x) => x === action) };
		targetAction.done = !targetAction.done;
		actionsCopy.splice(targetIndex, 1);

		actionsCopy.push(targetAction);
		setActions(actionsCopy);

		editAction({
			id: action.id,
			done: !action.done,
		}).then(() => {
			if (!action?.done) Success("L'action a bien été validée");
		});
	};

	const wantEditAction = (id, action) => {
		const actionsCopy = [...actions];

		const dataToSent = {
			id,
			title: action.title,
			note: action.note,
			datetime: new Date(`${action.date}`),
			done: action.done,
		};
		editAction(dataToSent).then(() => {
			Success("L'action a bien été modifiée");
			const targetAction = actionsCopy.find((x) => x.id === id);

			const indexTarget = actionsCopy.indexOf(targetAction);
			if (indexTarget > -1) {
				actionsCopy.splice(indexTarget, 1);
			}

			targetAction.title = action.title;
			targetAction.date = dataToSent.datetime;
			targetAction.hour = action.hour;
			targetAction.notes = action.note;
			targetAction.done = action.done;

			actionsCopy.unshift(targetAction);

			setActions([...actionsCopy]);
		});
	};

	const wantDeleteAction = (id) => {
		const actionsCopy = [...actions];

		const targetAction = actionsCopy?.find((x) => x.id === id);

		const indexTarget = actionsCopy?.indexOf(targetAction);
		if (indexTarget > -1) {
			actionsCopy?.splice(indexTarget, 1);
		}
		deleteAction(id);

		setActions([...actionsCopy]);
	};

	function actionRows() {
		const Rows = [];
		actions
			?.filter((x) => !x.done)
			?.forEach((action) => {
				if (!action?.datetime) return;
				const date = capitalizeFirstLetter(
					format(new Date(action?.datetime), "eeee dd MMMM"),
				);
				Rows.push({
					cells: [
						{
							children: <p>{date}</p>,
						},
						{
							children: (
								<div className="flex items-center gap-xsm">
									<p>{action.title}</p>
									<Checkbox
										onEnabled={() => {
											toggleAction(action);
										}}
										onDisabled={() => {
											toggleAction(action);
										}}
										checked={false}
									/>
								</div>
							),
						},
						{
							children: getTimeOfActionElement(action?.datetime),
						},
					],
				});
			});
		actions
			?.filter((x) => x.done)
			?.forEach((action) => {
				const date = capitalizeFirstLetter(
					format(new Date(action?.datetime), "eeee dd MMMM"),
				);
				Rows.push({
					onClick: () => {
						setShowManageActionModal(true);
						setSelectedAction(action);
					},
					cells: [
						{
							children: <p>{date}</p>,
						},
						{
							children: (
								<div className="flex items-center gap-xsm">
									<p>{action?.title}</p>
									<Checkbox
										onEnabled={() => {
											toggleAction(action);
										}}
										onDisabled={() => {
											toggleAction(action);
										}}
										checked={true}
									/>
								</div>
							),
						},
						{
							children: <p className="color-primary-150">Action terminée</p>,
						},
					],
				});
			});
		return Rows;
	}

	useEffect(() => {}, [actions]);

	return (
		<div className="action-flow">
			<div className="mobile-container">
				<p className="title ">"Flux des actions"</p>
				<p className="subtitle font-bold">En cours</p>
				<div className="pl-sm pr-sm">
					<Divider className="" />
				</div>
				{actions
					?.filter((x) => !x.done)
					?.map((action) => {
						const date = formatDate(action?.datetime, "dd/MM");
						const actionTime = getTimeOfAction(action?.datetime);
						return (
							<span key={action?.id}>
								<ListPrimary
									checked={action?.done}
									onClick={() => {
										setSelectedAction(action);
										setShowManageActionModal(true);
									}}
									onCheck={() => {
										toggleAction(action);
									}}
									label={date}
									icon={actionTime?.icon}
									iconColor={actionTime?.iconColor}
									title={actionTime?.label}
									subtitle={action?.title}
								/>
							</span>
						);
					})}
				<p className="subtitle history mt-md">Historique</p>
				<div className="pl-sm pr-sm">
					<Divider className="" />
				</div>
				{actions
					?.filter((x) => x.done)
					?.map((action) => {
						const date = formatDate(action?.datetime, "dd/MM");

						const actionTime = getTimeOfAction(action?.datetime);
						return (
							<span
								key={action?.id}
								onMouseEnter={() => {
									setSelectedAction(action);
								}}
							>
								<ListPrimary
									checked={action?.done}
									onClick={() => {
										setSelectedAction(action);
										setShowManageActionModal(true);
									}}
									onCheck={() => {
										toggleAction(action);
									}}
									label={date}
									icon={actionTime?.icon}
									iconColor={actionTime?.iconColor}
									title={actionTime?.label}
									subtitle={action?.title}
								/>
							</span>
						);
					})}
			</div>
			<div className="desktop-container">
				<AccordionCustom
					title="Flux des actions"
					headColor="white"
					defaultState
					body={<Table className="mt-sm" rows={actionRows()} />}
				/>
			</div>

			<ManageActionModal
				onDelete={wantDeleteAction}
				onApply={wantEditAction}
				contacts={contacts}
				onClose={(e) => {
					if (e !== undefined) {
						setActions([
							...actions.filter((x) => x.id !== selectedAction.id),
							e,
						]);
					}
					setShowManageActionModal(false);
				}}
				action={selectedAction}
				show={showManageActionModal}
			/>
		</div>
	);
}
ActionFlow.propTypes = {
	actionsState: PropTypes.shape({
		actions: PropTypes.arrayOf(PropTypes.shape({})),
		setActions: PropTypes.func,
	}).isRequired,
	contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
