import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import { arrowDownOutline, arrowUpOutline } from "@assets/Icons";

import { DropdownItem } from "@components/molecules/DropdownItem";
import { ItemSimpleSmall } from "@components/molecules/ItemSimpleSmall";

import { SelectModal } from "../selectModal/SelectModal";

type SelectCampusType = {
	className?: string;
	placeholder?: string;
	options: Array<{
		label: string;
		value: string;
	}>;
	onChange: (input: any) => void;
	disabled?: boolean;
	label?: string;
	icon?: any;
	defaultValue?: any;
	menuPosition?: string;
	position?: string;
	errorMessage?: string;
};

let uniqueId = 0;

// Inline Select
export function SelectCampus({
	className,
	placeholder,
	options,
	onChange,
	disabled = false,
	label,
	defaultValue,
	menuPosition = "bottom",
	icon,
	position,
	errorMessage,
}: SelectCampusType) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState(defaultValue);
	const [displayModal, setDisplayModal] = useState(false);
	const ref = useRef(null);

	const returnValue = (input: any) => {
		if (typeof input === "boolean" || typeof input === "object") {
			return input.value;
		}
		if (typeof input === "string") return input;
		return input.label;
	};

	useEffect(() => {
		if (Object.keys(defaultValue).length !== 0) {
			setValue(returnValue(defaultValue));
		}
	}, [defaultValue, options]);

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div
			id={`select-campus-${componentId}`}
			className={`select-campus dropdown w-full ${className} ${
				disabled ? "disabled" : ""
			}`}
			ref={ref}
		>
			{label && <p className="label mb-xxsm">{label}</p>}
			<div
				role="menu"
				onClick={() => {
					setIsOpen(!isOpen);
					setDisplayModal(true);
				}}
				id={`select-campus-dropdown-container-${componentId}`}
				className="select-campus-dropdown-container flex w-full cursor-pointer items-center"
			>
				<div className="flex items-center">
					{icon && <Icon className="icon-left mr-xsm h-4" icon={icon} />}
					<p className="value pr-xsm text-xsm font-bold">
						{options?.find((x) => x.value === value || x.label === value)
							?.label || placeholder}
					</p>
				</div>
				<Icon
					className="icon-dropdown-input h-4"
					icon={isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>

			<div
				id={`#dropdown-${componentId}`}
				className={"dropdown-container w-full "}
			>
				<div
					className={`dropdown-menu flex w-fit max-w-min flex-wrap bg-white ${position} ${
						isOpen ? "show" : "hidden"
					} ${menuPosition === "top" && "topversion"}`}
				>
					{options?.map((input, i) => {
						return (
							<DropdownItem
								truncate
								key={i}
								onClick={() => {
									setValue(input.value);
									if (onChange) {
										onChange(input);
									}
									setIsOpen(false);
									setDisplayModal(false);
								}}
								label={input.label}
							/>
						);
					})}
				</div>
				{errorMessage && (
					<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
				)}
			</div>

			<SelectModal
				title={label || placeholder || ""}
				onClick={() => {
					setDisplayModal(false);
				}}
				body={
					<div>
						{options?.map((input, i) => {
							return (
								<div key={i}>
									<ItemSimpleSmall
										onClick={() => {
											setValue(input.value);
											if (onChange) onChange(input);
										}}
										label={input.label}
									/>
								</div>
							);
						})}
					</div>
				}
				show={displayModal}
			/>
		</div>
	);
}
