import type {
	ConversationStatusType,
	NewFilterType,
	studentSelectedType,
} from "@/types/mailbox";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";

import { stringifyRequestFilters } from "@tools/Objects";
import { resolveView } from "@tools/Users";

import type { StandardGraphQLResponse } from "@tools/API";
import { getStudents } from "./controller/mailBox.request";
import {
	setCurrentPage,
	setCurrentTabSelected,
	setFetchInBackGround,
	setLastPage,
	setLoading,
	setMailBoxFilters,
	setStudentSelected,
	setStudentsList,
	setTotalUnsolvedConversationCount,
} from "./controller/mailBox.slice";
import { fetchUnsolvedConversationCounter } from "./controller/mailBoxManagement.thunks";
import Chat from "./ui/Chat";
import StudentsList from "./ui/StudentsList";
import MailBoxSearchbar from "./widget/MailboxSearchBar";

export default function MailBox() {
	const dispatchMailBox = useDispatch();
	const { user } = useContext(GlobalContext);
	const { structureView, setTitle } = useOutletContext<{
		structureView: { school?: string; campus?: string };
		setTitle: Function;
	}>();
	const {
		mailBoxFilters,
		loading,
		studentsList,
		currentTabSelected,
		currentSearch,
	} = useAppSelector((state) => state.mailBox);
	const { currentFilters, useFilters } = useAppSelector(
		(state) => state.usersFilters,
	);
	const constructFiltersCopy = (initialUseSearchValue: boolean) => {
		const filtersCopy = initialUseSearchValue
			? {
					search: currentSearch,
					state: currentFilters?.state,
					page: currentFilters?.page,
				}
			: currentSearch !== undefined &&
					currentSearch !== null &&
					currentSearch !== ""
				? {
						search: currentSearch,
						state: currentFilters?.state,
						page: currentFilters?.page,
					}
				: useFilters
					? { ...currentFilters }
					: ({} as Partial<FiltersType>);

		return filtersCopy;
	};
	const manageStateFilters = (filtersCopy: Partial<FiltersType>) => {
		if ("state" in filtersCopy && filtersCopy.state === "all") {
			delete filtersCopy.state;
		}

		return filtersCopy;
	};

	const filterByStatusAndDatetime = (
		data: any,
		status: ConversationStatusType,
	): any[] => {
		const undefinedMessage = data?.filter(
			(x: studentSelectedType) =>
				x.conversation?.spsMessages?.collection[0]?.datetime === undefined &&
				x.conversation?.status === status,
		);

		return data
			?.filter(
				(x: studentSelectedType) =>
					x.conversation?.status === status &&
					x.conversation?.spsMessages?.collection[0]?.datetime !== undefined,
			)
			?.sort((a: any, b: any) => {
				const dateA =
					new Date(a.conversation?.spsMessages?.collection[0]?.datetime) || 0;
				const dateB =
					new Date(b.conversation?.spsMessages?.collection[0]?.datetime) || 0;

				return dateB.valueOf() - dateA.valueOf();
			})
			?.concat(undefinedMessage);
	};
	const filterStudentList = (list: studentSelectedType[]) => {
		const studentWithoutConversation = list.filter((x) => !x.conversation);
		const studentToUnsolvedSorted = filterByStatusAndDatetime(list, "unsolved");
		const studentResolvedSorted = filterByStatusAndDatetime(list, "resolved");

		return [
			...studentToUnsolvedSorted,
			...studentResolvedSorted,
			...studentWithoutConversation,
		];
	};
	const getData = async (useInitialStateOfFilters: boolean) => {
		let filtersCopy: Partial<FiltersType> = constructFiltersCopy(
			useInitialStateOfFilters,
		);
		dispatchMailBox(setLoading(true));
		filtersCopy = manageStateFilters(filtersCopy);
		const stringifyFilters = stringifyRequestFilters({
			...resolveView(structureView, "spsPromotion"),
			...filtersCopy,
		});
		const { data }: any = await getStudents(stringifyFilters);
		if (data.users) {
			const studentToUnsolvedSorted = filterByStatusAndDatetime(
				data.users.collection,
				"unsolved",
			);

			const studentResolvedSorted = filterByStatusAndDatetime(
				data.users.collection,
				"resolved",
			);

			const studentList = [
				...studentToUnsolvedSorted,
				...studentResolvedSorted,
			];

			dispatchMailBox(setLastPage(data.users.paginationInfo.lastPage));
			dispatchMailBox(setStudentsList(studentList));
		}
		dispatchMailBox(setLoading(false));
	};

	const getCountTab = async (search = "") => {
		dispatchMailBox(
			fetchUnsolvedConversationCounter({
				...resolveView(structureView, "spsPromotion"),
				search,
			}) as any,
		);
	};

	const filtersResolver = (newFilter: NewFilterType) => {
		let newFiltersList = { ...mailBoxFilters };
		delete newFiltersList.tab;
		if (newFilter) {
			switch (newFilter.name) {
				case "page":
					newFiltersList.page = newFilter.value;
					dispatchMailBox(setCurrentPage(newFilter.value));
					break;
				default:
					newFiltersList[newFilter.name] = newFilter.value;
					newFiltersList.page = 1;
					dispatchMailBox(setCurrentPage(1));
			}
			newFiltersList = Object.entries(newFiltersList).filter(
				(e) => e[1] !== null,
			);
			newFiltersList = Object.fromEntries(newFiltersList);

			dispatchMailBox(setMailBoxFilters(newFiltersList));
		}
		return newFiltersList;
	};

	const updateStudents = async (newFilter: NewFilterType = null) => {
		const newFiltersList = filtersResolver(newFilter);
		const query = stringifyRequestFilters({
			...resolveView(structureView, "spsPromotion"),
			...newFiltersList,
			search:
				newFiltersList?.search !== currentSearch
					? currentSearch
					: newFiltersList?.search,
		});
		dispatchMailBox(setFetchInBackGround(true));

		const { data }: StandardGraphQLResponse = await getStudents(query);

		if (data?.users) {
			if (newFilter?.name === "page") {
				const studentList = [...(studentsList ?? []), ...data.users.collection];

				dispatchMailBox(setStudentsList(filterStudentList(studentList)));
			} else {
				dispatchMailBox(
					setStudentsList(filterStudentList(data.users.collection)),
				);
			}

			if (newFilter?.value === "unsolved") {
				dispatchMailBox(
					setTotalUnsolvedConversationCount(
						data.users.paginationInfo.totalCount,
					),
				);
			} else {
				getCountTab(currentSearch);
			}

			dispatchMailBox(setLastPage(data.users.paginationInfo.lastPage));
		}
		dispatchMailBox(setFetchInBackGround(false));
	};

	useEffect(() => {
		setTitle("Messagerie");
		if (!loading && user) {
			getData(true);
			dispatchMailBox(setStudentSelected(null));
			dispatchMailBox(setCurrentTabSelected("all"));
		}
	}, [structureView]);

	useEffect(() => {
		if (user !== null && !loading) {
			updateStudents({ name: "search", value: currentSearch ?? "" });
		}
	}, [currentSearch]);

	useEffect(() => {
		if (currentTabSelected === "all") getCountTab(currentSearch);
	}, [currentTabSelected, structureView]);

	return (
		<main className="flex flex-wrap pt-lg md:flex md:items-center justify-between">
			<div className="flex w-full flex-wrap gap-md lg:gap-0">
				<div className="mb-sm flex flex-col w-full lg:mb-0 lg:w-3/12 max-h-[80vh] gap-sm">
					<p className="text-base font-semibold">Discussions</p>
					<MailBoxSearchbar />
					<StudentsList
						className="max-h-[76vh] flex flex-col mt-xsm lg:mr-sm"
						updateStudents={updateStudents}
					/>
				</div>

				<Chat className="w-full lg:w-9/12 h-[81vh]" />
			</div>
		</main>
	);
}
