import type { EventType } from "@/types/eventsTypes";
import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { type StandardGraphQLResponse, extractError } from "@tools/API";
import { eventsSlice } from "../store/events.slice";

export const fetchStudentInvitations =
	(currentDisplayedTab: string) =>
	async (
		dispatch: AppDispatch,
		api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.eventsGateway) {
				throw new Error("eventsGateway is not available in dependencies");
			}
			dispatch(eventsSlice.actions.handleInvitationsLoading());
			const fetchingResponse: StandardGraphQLResponse =
				await dependencies.eventsGateway?.getStudentInvitations(
					api().events.studentId,
					currentDisplayedTab,
				);
			if (!fetchingResponse || fetchingResponse.responseLabel !== "success") {
				throw new Error("Failed to fetch invitations");
			}

			const rawData: unknown = fetchingResponse.data;
			const combinedEventsInvitations: Partial<EventType>[] = [];
			if (!rawData || typeof rawData !== "object") {
				throw new Error("Invalid data");
			}

			if (
				"mentorgoalEvents" in rawData &&
				typeof rawData.mentorgoalEvents === "object" &&
				rawData.mentorgoalEvents &&
				"collection" in rawData.mentorgoalEvents
			) {
				combinedEventsInvitations.push(
					...(rawData.mentorgoalEvents.collection as EventType[]),
				);
			}

			if (
				"events" in rawData &&
				typeof rawData.events === "object" &&
				rawData.events &&
				"collection" in rawData.events
			) {
				combinedEventsInvitations.push(
					...(rawData.events.collection as EventType[]),
				);
			}
			dispatch(
				eventsSlice.actions.addInvitationsToEvents(combinedEventsInvitations),
			);
		} catch (error: unknown) {
			dispatch(
				eventsSlice.actions.handleInvitationsError(
					extractError(error).message.toString(),
				),
			);
		}
	};
