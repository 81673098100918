import { useAppSelector } from "@config/redux/hook";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";

import { briefcaseOutline } from "@assets/Icons";

import { extractLogoFromClearbit } from "@tools/Companies";

import { InputLargePrimary } from "@components/molecules/InputLargePrimary";

import { useAppDispatch } from "@config/redux/store";
import {
	fetchVerifyCompanyExistance,
	searchCompanyInClearbit,
} from "../../core/api/companiesManagement.request";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";

export function ChooseCompanyName() {
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatchEvent = useAppDispatch();
	const { companyNameAlreadyExist } = useAppSelector(
		(state) => state.companiesManagement,
	);

	const [inputCompanyNameValue, setInputCompanyNameValue] =
		useState<string>("");

	const debouncedCompanyNameValue = useDebounce(inputCompanyNameValue, 500);

	const handleCompanyNameModification = async (companyName: string) => {
		if (companyName === "") {
			dispatchEvent(
				companiesManagementSlice.actions.setCompanyAlreadyExist(false),
			);
			dispatchEvent(modifyCompanyToCreate({ name: "" }));
			return;
		}
		setInputCompanyNameValue(companyName);
	};

	const findCompanyLogo = async () => {
		const clearbitResponse = await searchCompanyInClearbit(
			debouncedCompanyNameValue,
		);
		const extractedLogo = extractLogoFromClearbit(
			clearbitResponse,
			debouncedCompanyNameValue,
		);
		if (extractedLogo) {
			dispatchEvent(
				modifyCompanyToCreate({
					logo: extractedLogo,
				}),
			);
		} else {
			dispatchEvent(modifyCompanyToCreate({ logo: "" }));
		}
	};

	const checkThisCompanyExist = async () => {
		const response = await fetchVerifyCompanyExistance(
			debouncedCompanyNameValue,
		);

		if (response?.data?.crmCompanies.collection[0] !== undefined) {
			dispatchEvent(
				companiesManagementSlice.actions.setCompanyAlreadyExist(true),
			);
		} else {
			dispatchEvent(
				companiesManagementSlice.actions.setCompanyAlreadyExist(false),
			);
		}
		dispatchEvent(
			modifyCompanyToCreate({
				name: debouncedCompanyNameValue,
			}),
		);
	};

	useEffect(() => {
		if (debouncedCompanyNameValue && debouncedCompanyNameValue.length > 2) {
			findCompanyLogo();
			checkThisCompanyExist();
		}
	}, [debouncedCompanyNameValue]);
	return (
		<article className="flex gap-sm justify-start items-center">
			<div className="flex flex-col gap-2 w-full">
				<InputLargePrimary
					label="Nom de l’entreprise*"
					placeholder="Renseigner le nom de l’entreprise…"
					icon={briefcaseOutline}
					onChange={(e) => handleCompanyNameModification(e.target.value)}
				/>
				{companyNameAlreadyExist && (
					<p className="text-red-500">Cette entreprise existe déjà</p>
				)}
			</div>
		</article>
	);
}
