import React from "react";
import Skeleton from "react-loading-skeleton";

export function MyProfileSkeleton() {
	return (
		<div className="">
			<div className="flex flex-col items-center">
				<section className="flex w-full flex-col items-center">
					<div className="flex w-full justify-end pr-sm">
						<Skeleton height={20} width={20} />
					</div>
					<Skeleton height={100} width={100} circle />
				</section>
				<div className="w-full">
					<div className="mb-4 mt-4 text-center">
						<Skeleton width={200} height={16} />
						<Skeleton width={150} height={8} />
					</div>
				</div>
				<div className="mt-sm flex w-full items-center">
					<div className="flex w-full flex-col">
						<Skeleton width={120} height={8} />
						<Skeleton height={35} />
					</div>
					<div className="ml-sm flex w-full flex-col">
						<Skeleton width={120} height={8} />
						<Skeleton height={35} />
					</div>
				</div>
				<div className="mt-sm w-full">
					<Skeleton width={100} height={8} />
					<Skeleton height={35} />
				</div>
				<div className="mt-sm w-full">
					<Skeleton width={100} height={8} />
					<Skeleton height={35} />
				</div>
				<div className="mt-sm flex w-full items-center">
					<div className="flex w-full flex-col">
						<Skeleton width={120} height={8} />
						<Skeleton height={35} />
					</div>
					<div className="ml-sm flex w-full flex-col">
						<Skeleton width={120} height={8} />
						<Skeleton height={35} />
					</div>
				</div>
				<div className="mt-sm flex w-full items-center">
					<div className="flex w-full flex-col">
						<Skeleton width={120} height={8} />
						<Skeleton height={35} />
					</div>
					<div className="ml-sm flex w-full flex-col">
						<Skeleton width={120} height={8} />
						<Skeleton height={35} />
					</div>
				</div>
				<div className="mt-lg flex w-full items-start">
					<p className="text-xsm font-bold text-primary-700P">Mes documents</p>
				</div>
				<div className="files mb-lg mt-4 w-full">
					<Skeleton className="mb-md" height={200} />
					<Skeleton height={200} />
				</div>
				<div className="mt-sm flex w-full items-start">
					<p className="text-xsm font-bold text-primary-700P">
						Mes compétences
					</p>
				</div>
				<div className="files mb-lg mt-sm w-full">
					<Skeleton className="mb-md" height={80} />
					<Skeleton height={80} />
				</div>
			</div>
		</div>
	);
}
