import {
	differenceInDays,
	endOfWeek,
	format,
	formatDistanceToNow,
	isSameDay,
	isValid,
	parse,
	parseISO,
	startOfWeek,
	subYears,
} from "date-fns";
import { fr } from "date-fns/locale";

export function convertDateFromNow(date: Date | string, shortFormat = false) {
	try {
		if (!date) return "-";
		const dateTest = typeof date === "string" ? new Date(date) : date;
		const locale = { locale: fr, includeSeconds: true };
		const convertDate = formatDistanceToNow(dateTest, locale);
		if (shortFormat) {
			let convertDateArray = convertDate.split(" ");
			if (convertDateArray.length > 2) {
				const convertDateArrayIndex = convertDateArray.findIndex(
					(val: string) => !Number.isNaN(Number(val)),
				);
				if (convertDateArrayIndex) {
					convertDateArray = convertDateArray.slice(convertDateArrayIndex);
				}
			}
			const shortFormatConvertDate = convertDateArray
				.join(" ")
				.replace(
					/\bmois\b|\bjour(s)?\b|\bheure(s)?\b|\bminute(s)?\b|\bseconde(s)?\b/g,
					(match: string) => {
						switch (match) {
							case "jours":
							case "jour":
								return "j";
							case "heures":
							case "heure":
								return "h";
							case "minutes":
							case "minute":
								return "min";
							case "secondes":
							case "seconde":
								return "sec";
							default:
								return match;
						}
					},
				);
			return shortFormatConvertDate.replace(
				/\b(\d+)(?=(\s*(m|j|h)\b))/g,
				"$1 ",
			);
		}
		return convertDate;
	} catch (_error) {
		return "-";
	}
}

export function formatTime(date: Date | string) {
	try {
		if (!date) return "Date invalide";
		const dateTest = typeof date === "string" ? new Date(date) : date;
		return format(dateTest, "HH:mm");
	} catch (_error) {
		return "00:00";
	}
}

export function getAgeFromBirthdate(birthdate: Date | string) {
	const today = new Date();
	const date = new Date(birthdate);
	let age = today.getFullYear() - date.getFullYear();
	const m = today.getMonth() - date.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
		age--;
	}
	return age;
}

export function diffDate(oldDate: Date | string) {
	if (typeof oldDate === "string") {
		oldDate = parseISO(oldDate);
	}
	const date = differenceInDays(new Date(), oldDate);

	return date;
}

export function formatMonthYear(date: Date | string) {
	try {
		if (!date) return "";
		const dateTest = typeof date === "string" ? new Date(date) : date;
		const locale = { locale: fr, addSuffix: true };
		const formattedDate = format(dateTest, "MMMM yyyy", locale);
		const upcaseDate =
			formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
		return upcaseDate;
	} catch (_error) {
		return "";
	}
}

export function getMonday(date: Date | string) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	const day = date.getDay();

	const diff = date.getDate() - day + (day === 0 ? -6 : 1);
	return new Date(date.setDate(diff));
}

export function getSunday(date: Date | string) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	const dayOfWeek = date.getDay();
	const diff = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
	const sundayDate = new Date(date.getTime() + diff * 24 * 60 * 60 * 1000);
	return sundayDate;
}

type AcceptedFormat = "dd/mm/yyyy" | "dd/mm";
export function formatDate(
	date: Date | string,
	format: AcceptedFormat = "dd/mm/yyyy",
) {
	if (!date) return "";
	if (typeof date === "string") {
		let parsedDate = parseISO(date);
		if (!isValid(parsedDate)) {
			parsedDate = parse(date, "dd/MM/yyyy", new Date());
		}
		if (!isValid(parsedDate)) {
			return "";
		}
		date = parsedDate;
	}

	const dd = String(date.getDate()).padStart(2, "0");
	const mm = String(date.getMonth() + 1).padStart(2, "0");
	const yyyy = date.getFullYear();

	switch (format) {
		case "dd/mm/yyyy":
			return `${dd}/${mm}/${yyyy}`;
		default:
			return `${dd}/${mm}`;
	}
}

export function getLastWeekDate(date: Date | string) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	const diff = date.getDate() - 7;
	return new Date(date.setDate(diff));
}

export function isLastWeek(date: Date | string | null) {
	if (!date) return false;
	if (typeof date === "string") {
		date = parseISO(date);
	}
	const lastWeekDate = getLastWeekDate(new Date());
	const mondayDate = formatDate(getMonday(lastWeekDate));
	const sundayDate = formatDate(getSunday(lastWeekDate));
	const dateToCompare = formatDate(date);

	// return true if dateToCompare is between mondayDate and sundayDate
	const isInTheRange = (a: string, b: string, c: string): boolean => {
		return b >= a && b <= c;
	};

	if (isSameDay(date, getMonday(lastWeekDate))) return true;
	if (isSameDay(date, getSunday(lastWeekDate))) return true;
	return isInTheRange(mondayDate, dateToCompare, sundayDate);
}

export function isThisWeek(date: Date | string) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	const startWeek = startOfWeek(new Date());
	const endWeek = endOfWeek(new Date());
	const dateToCompare = new Date(date);

	return dateToCompare >= startWeek && dateToCompare <= endWeek;
}

export function extractTimeFromISOString(date: string) {
	if (!date || date === "") return "00:00";
	if (Number.isNaN(new Date(date).getTime())) return "00:00";

	// adjust date to timezone, for exemple its false when we use a timepicker but true if we init the timepicker with ISOString
	const extractedDate = new Date(date);

	return extractedDate.toLocaleTimeString("fr-FR", {
		hour: "2-digit",
		minute: "2-digit",
	});
}

export const getDateLimitFromAge = (age: number) => {
	const date = subYears(new Date(), age);
	const formattedDate = format(date, "yyyy-MM-dd");
	return formattedDate;
};

export const getDateAfterDays = (days: number, startAtThisDate?: Date) => {
	const date = startAtThisDate || new Date();

	date.setDate(date.getDate() + days);

	return date;
};
