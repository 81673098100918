import type { ReferentType } from "@/types/userTypes";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

type ReferentsState = {
	status: "idle" | "loading" | "success" | "error";
	fetchingError: string;
	referentsListReceived?: {
		campusReferents: ReferentType[];
		schoolReferents: ReferentType[];
	};
};

const initialState: ReferentsState = {
	status: "idle",
	fetchingError: "",
};

export const referentsSlice = createSlice({
	name: "referents",
	initialState,
	reducers: {
		handleReferentsLoading(state) {
			state.status = "loading";
		},
		handleReferentsFetchingError(state, action: PayloadAction<string>) {
			ErrorToast(action.payload);
			state.fetchingError = action.payload;
			state.status = "error";
		},
		handleReferentsFetchingAborted(state) {
			state.status = "idle";
			state.fetchingError = "";
		},
		modifyReferentsListReceived(
			state,
			action: PayloadAction<ReferentsState["referentsListReceived"]>,
		) {
			state.status = "success";
			state.referentsListReceived = action.payload;
		},
	},
});

export const referentsReducer = referentsSlice.reducer;
export const referentsActions = referentsSlice.actions;
