import { Icon, type IconifyIcon } from "@iconify/react";
import React, { type MouseEvent, useEffect, useState } from "react";

import "@assets/sass/ItemMenu.scss";

import { Avatar } from "@components/atoms/Avatar";

import { chevronRightOutline } from "../../assets/Icons";

let uniqueId = 0;

type ItemSimpleLargePropsType = {
	id?: string;
	label: string;
	icon?: null | IconifyIcon;
	className?: string;
	isSubMenu?: boolean;
	onClick?: (e?: MouseEvent) => void;
	avatar?: { image: string; firstname: string; lastname: string } | null;
};
export function ItemSimpleLarge({
	label,
	icon = null,
	className = "",
	isSubMenu = false,
	onClick = () => {},
	avatar = null,
	id = "",
}: ItemSimpleLargePropsType) {
	const [componentId] = useState(() => uniqueId++);

	useEffect(() => {
		const element = document.getElementById(
			id || `simple-item-large-${componentId}`,
		);
		if (element) {
			element.addEventListener("keyup", (event) => {
				if (event.keyCode === 13) {
					event.preventDefault();
					element.click();
				}
			});
		}
	}, [id, componentId]);

	return (
		<div
			data-id="itemSimpleLarge"
			id={id || `simple-item-large-${componentId}`}
			role="menu"
			className={`${className} simple-item-large-container`}
			onClick={onClick}
		>
			<div className="flex items-center">
				{icon && <Icon icon={icon} className="with-icon ml-0" />}
				<p className="text-primary-700P">{label}</p>
			</div>
			{isSubMenu && (
				<Icon icon={chevronRightOutline} className="with-sub-menu" />
			)}
			{avatar && (
				<Avatar
					image={avatar.image}
					firstname={avatar.firstname}
					lastname={avatar.lastname}
					size="xxs"
				/>
			)}
		</div>
	);
}
