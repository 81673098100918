import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const fetchCompanyRelations = async (
	dispatch: AppDispatch,
	api: AppGetState,
	dependencies: Dependencies,
) => {
	try {
		if (!dependencies.companyRelationsGateway) {
			throw new Error(
				"companyRelationsGateway is not available in dependencies",
			);
		}
		dispatch(companiesManagementSlice.actions.handleRelationsLoading());
		const companyRelations =
			await dependencies.companyRelationsGateway.getCompanyRelations(
				api().companiesManagement.targetCompany,
			);
		dispatch(
			companiesManagementSlice.actions.modifyCompanyRelations(companyRelations),
		);
	} catch (error: unknown) {
		dispatch(
			companiesManagementSlice.actions.handleRelationsError(
				extractError(error).message.toString(),
			),
		);
	}
};
