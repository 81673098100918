import React, { useEffect, useState } from "react";

import "@assets/sass/RadioPrimitive.scss";

type RadioPrimitiveType = {
	name: string;
	defaultState?: boolean;
	itemSelected?: number;
	onChange: (value: number) => void;
};

const getIndexFromName = (name: string) => {
	const index = name.split("-")[2];
	return Number.parseInt(index);
};

export function RadioPrimitive({
	name,
	defaultState = false,
	itemSelected,
	onChange,
}: RadioPrimitiveType) {
	const [isChecked, setIsChecked] = useState<boolean>(defaultState);
	const itemIndex: number = getIndexFromName(name);

	useEffect(() => {
		setIsChecked(itemSelected === itemIndex);
	}, [itemSelected]);

	return (
		<div className="w-fit-content">
			<div
				className="radio-container mb-0"
				onClick={() => {
					if (onChange) onChange(itemIndex);
				}}
			>
				<input
					id={`radio-${name}`}
					type="radio"
					readOnly
					checked={isChecked}
					name={name}
				/>
				<span className={`checkmark ${!isChecked && "!border-primary-300"}`} />
			</div>
		</div>
	);
}
