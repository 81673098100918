import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function StudentDetailLoader() {
	const arrayInputLayout = [];
	arrayInputLayout.length = 3;
	arrayInputLayout.fill("");

	const arrayStatsLayout = [{ id: 0 }, { id: 1 }, { id: 2 }];

	const arrayAppLayout = [];
	arrayAppLayout.length = 6;
	arrayAppLayout.fill("");

	return (
		<div className="mb-xlg mt-lg flex gap-md">
			<div className="h-fit-content flex w-5/12 flex-col items-center">
				<div className="w-full rounded-lg bg-white p-sm">
					<div className="flex justify-end">
						<Skeleton className="mt-sm" height={36} width={36} />
					</div>
					<div className="flex flex-col items-center justify-center">
						<Skeleton circle height={100} width={100} />
						<div className="w-6/12">
							<Skeleton className="mt-sm" height={16} />
						</div>
					</div>
					<div className="mt-xsm flex items-center justify-center">
						<Skeleton className="mt-sm w-full" height={32} width={100} />
					</div>
					<div className="mb-md mt-md flex flex-col items-center p-2">
						{arrayInputLayout.map((i) => {
							return (
								<div
									key={i + Math.random()}
									className="mt-md flex w-full items-center  justify-between"
								>
									<div>
										<Skeleton className="w-full" height={16} width={100} />
									</div>
									<div className="w-6/12">
										<Skeleton height={16} />
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="tags-container w-full">
					<Skeleton className="mt-sm w-full  " height={24} width={100} />
					<div>
						<Skeleton className="mt-sm w-full  " height={16} width={250} />
						<Skeleton className="ml-sm mt-sm w-full" height={16} width={16} />
					</div>
				</div>
				<div className="tags-container w-full">
					<Skeleton className="mt-sm w-full  " height={24} width={100} />
					<div>
						<Skeleton className="mt-md w-full" height={16} width={300} />
						<div>
							<Skeleton className="mt-sm w-full  " height={16} width={250} />
							<Skeleton className="ml-sm mt-sm w-full" height={16} width={16} />
						</div>
						<Skeleton className="mt-md w-full" height={16} width={300} />
						<div>
							<Skeleton className="mt-sm w-full  " height={16} width={250} />
							<Skeleton className="ml-sm mt-sm w-full" height={16} width={16} />
						</div>
					</div>
				</div>
				<div className="document-container w-full">
					<Skeleton className="mt-sm w-full  " height={24} width={150} />
					<div>
						<Skeleton className="mt-sm w-full  " height={16} width={250} />
						<Skeleton className="ml-sm mt-sm w-full" height={16} width={16} />
					</div>
				</div>
			</div>
			<div className="student-detail-left flex w-7/12 flex-col items-center">
				<div className="flex w-full items-center">
					{arrayStatsLayout.map(({ id }) => {
						return (
							<div
								key={id + Math.random()}
								className={`stats-container rounded-lg bg-white ${
									id > 0 ? "ml-sm" : ""
								}`}
							>
								<Skeleton className="mt-sm w-full" height={16} width={100} />
								<div>
									<Skeleton className=" mt-md" height={32} width={32} />
								</div>
								<Skeleton className="mt-sm w-full" height={16} width={100} />
							</div>
						);
					})}
				</div>
				<div className="mb-md mt-md w-full rounded-lg bg-white p-lg">
					<div className="mt-md flex flex-wrap">
						<Skeleton className="mt-sm w-full" height={24} width={100} />
						<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
						<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
						<Skeleton className="ml-sm mt-sm w-full" height={24} width={100} />
					</div>
					<div className="mb-md mt-md w-full rounded-lg bg-white">
						{arrayAppLayout.map((i) => {
							return (
								<div
									key={i + Math.random()}
									className="mt-lg flex items-center"
								>
									<Skeleton className="" circle height={40} width={40} />
									<div className="w-full">
										<Skeleton className="ml-sm" height={16} />
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}
