import { GlobalContext } from "@navigation/Router";
import React, { useContext, useState } from "react";

import "./Onboarding.scss";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";

import { ONBOARDING_TITLES } from "@constants/Others";

import { editUser } from "./OnboardingAPI";
import { dataOnboarding } from "./shared/data";

type OnboardingPropsType = {
	show: boolean;
	onClose: () => void;
	currentPath: 0 | 1 | 2 | 3;
};

export function Onboarding({
	show,
	onClose,
	currentPath,
}: OnboardingPropsType) {
	const { user, setUser } = useContext(GlobalContext);
	const [page, setPage] = useState(0);

	const matchOnboarding = () => {
		switch (currentPath) {
			case 0:
				return "dashboard";
			case 1:
				return "applications";
			case 2:
				return "resources";
			default:
				return "jobboard";
		}
	};

	const onCloseModals = () => {
		onClose();
	};
	const updateOnboardingSteps = async () => {
		if (!user?.onboardingSteps) return;
		const currentOnboardingSteps: string[] = [...user.onboardingSteps];
		currentOnboardingSteps[currentPath] = "1";
		let onBoardingStepsString = "";
		for (const step of currentOnboardingSteps) {
			onBoardingStepsString += step;
		}
		await editUser({
			id: `${user.id}`,
			onboardingSteps: onBoardingStepsString,
		}).then(() => {
			setUser({
				...user,
				onboardingSteps: onBoardingStepsString,
			});
		});
	};

	return (
		<>
			<Modal
				className="onBoarding"
				show={show}
				title={
					ONBOARDING_TITLES[matchOnboarding()]?.[page]?.title
						? ONBOARDING_TITLES[matchOnboarding()][page].title
						: ""
				}
				subtitle={
					<span className="wg-no-translate">
						- {page + 1}/{dataOnboarding(page)?.[matchOnboarding()]?.length}
					</span>
				}
				body={
					dataOnboarding(page)[matchOnboarding()]?.[page]
						? dataOnboarding(page)?.[matchOnboarding()][page].content
						: ""
				}
				onClose={() => {
					updateOnboardingSteps();
					onCloseModals();
				}}
				buttonsRight={[
					<ButtonSecondary
						key="later"
						label={ONBOARDING_TITLES[matchOnboarding()][page].firstBtn.label}
						onClick={
							dataOnboarding(
								page,
								setPage,
								onCloseModals,
								updateOnboardingSteps,
							)[matchOnboarding()][page].firstBtn.onClick
						}
					/>,
					<ButtonPrimary
						key="next"
						label={ONBOARDING_TITLES[matchOnboarding()][page].secondBtn.label}
						onClick={
							dataOnboarding(
								page,
								setPage,
								onCloseModals,
								updateOnboardingSteps,
							)[matchOnboarding()][page].secondBtn.onClick
						}
					/>,
				]}
			/>
		</>
	);
}
