import type { EventsTabLabelType, EventsTabType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { type Dispatch } from "react";

import {
	type ScrollableTab,
	ScrollableTabGroup,
} from "@components/molecules/TabGroup";

type EventsTabsRowProps = {
	eventTabs: EventsTabType[];
	activeTabLabel: EventsTabLabelType;
	selectTabEvent: Dispatch<EventsTabLabelType>;
};

function EventsTabsRow(props: EventsTabsRowProps) {
	const isFetchingData = useAppSelector((state) => state.events.isFetchingData);
	const selectIt = (label: EventsTabLabelType) => {
		props.selectTabEvent(label);
	};
	const getTabLabelFromId = (id: number): EventsTabLabelType => {
		return props.eventTabs[id].label as EventsTabLabelType;
	};
	const MakeScrollableTabGroupTabs = () => {
		const tabs: ScrollableTab[] = [];
		props.eventTabs.forEach((tab) => {
			tabs.push({
				label: `${tab.label} (${isFetchingData ? "..." : tab.counter})`,
				active: props.activeTabLabel === tab.label,
			});
		});
		return tabs;
	};

	return (
		<section className="flex max-h-8 min-w-[300px] sm:min-w-[500px]">
			<ScrollableTabGroup
				tabs={MakeScrollableTabGroupTabs()}
				onTabChange={(label) => selectIt(getTabLabelFromId(label))}
			/>
		</section>
	);
}

export default EventsTabsRow;
