import React from "react";

import CampusModifier from "../actions/CampusModifier";
import StatusModifier from "../actions/StatusModifier";
import VisibilityModifier from "../actions/VisibilityModifier";

export function OfferCreationParameters() {
	return (
		<article className="flex w-full flex-col gap-sm rounded-lg bg-white p-md">
			<p className="text-sm font-bold text-primary-700P">Paramètres</p>
			<StatusModifier />
			<CampusModifier />
			<VisibilityModifier />
		</article>
	);
}
