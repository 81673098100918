import { Icon } from "@iconify/react";
import React from "react";

import { bulbOutline, peopleOutline, trendingUpOutline } from "@assets/Icons";

export function Cards() {
	return (
		<div className="w-full p-sm lg:w-7/12">
			<div className="community-card">
				<div className="flex items-center">
					<Icon className="h-6 text-accent-3" icon={bulbOutline} />
					<h5 className="ml-sm mt-xxsm text-accent-3">Demande de l'aide !</h5>
				</div>
				<p>
					Partage ton CV, ta lettre de motivation ou autre et reçois des avis
					constructifs sur tes travaux.
				</p>
			</div>
			<div className="community-card mt-sm md:mt-lg">
				<div className="flex items-center">
					<Icon className="h-6 text-accent-2" icon={peopleOutline} />
					<h5 className="ml-sm mt-xxsm text-accent-2">Aide</h5>
				</div>
				<p>
					Demande de l'aide aux autres afin d'avancer et ne pas rester bloqué.
				</p>
			</div>
			<div className="community-card mt-sm md:mt-lg">
				<div className="flex items-center">
					<Icon className="h-6 text-accent-1" icon={trendingUpOutline} />
					<h5 className="ml-sm mt-xxsm text-accent-1">Progresse</h5>
				</div>
				<p>
					En discutant avec les autres de vos différents travaux, progressez
					ensemble !
				</p>
			</div>
		</div>
	);
}
