import { COMPANY_RELATIONS_OPTIONS } from "@constants/Company";
import React from "react";

import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";
import { useAppDispatch } from "@config/redux/store";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";

type SelectRelationToCompanyProps = {
	triggerUpdate?: (valueFromInput: unknown) => void;
	choosenRelation?: number;
};
export function SelectRelationToCompany({
	triggerUpdate,
	choosenRelation,
}: SelectRelationToCompanyProps) {
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatchEvent = useAppDispatch();

	const handleRelationChange = (option: { value: string; label: string }) => {
		if (triggerUpdate) triggerUpdate(option.value);

		dispatchEvent(modifyCompanyToCreate({ relation: option.value }));
	};

	const findDefaultOption = () => {
		const optionAlreadySelected = COMPANY_RELATIONS_OPTIONS.find(
			(option) => option.value === choosenRelation,
		);
		return optionAlreadySelected
			? optionAlreadySelected
			: COMPANY_RELATIONS_OPTIONS[0];
	};
	return (
		<article className="w-full">
			<SelectSecondary
				position="right"
				options={COMPANY_RELATIONS_OPTIONS}
				onChange={handleRelationChange}
				label={`Quelle relation avez-vous avec cette entreprise ? ${
					triggerUpdate ? "" : "*"
				}`}
				defaultValue={findDefaultOption()}
				className="w-full min-w-full"
			/>
		</article>
	);
}
