import type { OfferApplicationType, SchoolOfferType } from "@/types/offerTypes";
import type { FetchOffersListParamsType } from "@/types/offersRequestTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { DEFAULT_OFFERS_TABLE_CONFIG } from "@constants/Offers";

import type { StandardGraphQLResponse } from "@tools/API";
import { getCookie, setCookie } from "@tools/Cookies";

import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import type { UserType } from "@/types/userTypes";
import {
	getOfferApplication,
	getOfferApplicationsThumbs,
} from "../api/offerApplication.request";
import {
	getEntOfferDetails,
	getMailsLogForOffer,
	getOfferDetails,
	modifyCampus,
	modifyOffer,
	modifyOfferMemo,
	modifyReferent,
	modifyStatus,
	modifyTags,
	modifyVisibility,
} from "../api/offerDetails.request";
import {
	createOffer,
	deleteOffer,
	getCampusesList,
	getCounters,
	getOffers,
	getReferents,
} from "../api/offersManagement.request";

export const SETTINGS_COOKIE_NAME = "offersSettings";

export const fetchOffersList = createAsyncThunk(
	"offersManagementAPI/fetchOffersList",
	async (params: FetchOffersListParamsType) => {
		const response = await getOffers(params);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data.collectionQueryOffers;
	},
);

type FetchOfferDetailsParamsType = {
	id: string;
	copyToCreate?: boolean;
	entOfferType?: OfferApplicationType;
	user?: UserType;
};
export const fetchOfferDetails = createAsyncThunk(
	"offersManagementAPI/fetchOfferDetails",
	async (params: FetchOfferDetailsParamsType) => {
		let response: StandardGraphQLResponse;
		if (!params.entOfferType) {
			response = await getOfferDetails(params.id);
		} else {
			response = await getEntOfferDetails(params.id);
			if (response.responseLabel === "error") {
				throw new Error("aborted");
			}
			response.data.offer.type = params.entOfferType;
			response.data.offer.status = "online";
			response.data.offer.wayToApply = response.data.offer.url;
			if (params.user?.coreCampus) {
				response.data.offer.campuses = {
					collection: [
						{ id: params.user.coreCampus, name: params.user.campusName },
					],
				};
			}
		}
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}

		const responsePayload = {
			data: response.data,
			needToCopyToCreate: params.copyToCreate || false,
		};
		return responsePayload;
	},
);
export const fetchOffersCounters = createAsyncThunk(
	"offersManagementAPI/fetchOffersCounter",
	async (params: FetchOffersListParamsType) => {
		const response = await getCounters(params);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data;
	},
);

export const fetchOffersApplicationsThumbnails = createAsyncThunk(
	"offersManagementAPI/fetchOffersApplicationsThumbnails",
	async (offerId: string) => {
		const response = await getOfferApplicationsThumbs(offerId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data?.offer;
	},
);

export const fetchOffersApplication = createAsyncThunk(
	"offersManagementAPI/fetchOffersApplication",
	async (applicationId: string) => {
		const response = await getOfferApplication(applicationId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data;
	},
);

export const fetchUserTableConfig = createAsyncThunk(
	"offersManagementAPI/fetchUserTableConfig",
	async () => {
		let cookie = [];
		const cookieFound = getCookie(SETTINGS_COOKIE_NAME);
		if (cookieFound) {
			cookie = JSON.parse(cookieFound);
		} else {
			cookie = DEFAULT_OFFERS_TABLE_CONFIG;
			setCookie(
				SETTINGS_COOKIE_NAME,
				JSON.stringify(DEFAULT_OFFERS_TABLE_CONFIG),
			);
		}
		const data: StandardGraphQLResponse = {
			responseLabel: "success",
			message: "success",
			data: cookie,
		};
		if (data.responseLabel === "error") {
			ErrorToast("Erreur lors de la récupération de votre configuration");
			throw new Error("aborted");
		}
		return data;
	},
);

export const fetchDeleteOffer = createAsyncThunk(
	"offersManagementAPI/fetchDeleteOffer",
	async (id: string) => {
		const response = await deleteOffer(id);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la suppression de l’offre");
			throw new Error("aborted");
		}
		return response;
	},
);

export const fetchModifyOfferVisibility = createAsyncThunk(
	"offersManagementAPI/fetchModifyOfferVisibility",
	async (params: { id: string; visibility: string }) => {
		const response = await modifyVisibility(params.id, params.visibility);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la modification de la visibilité de l’offre");
			throw new Error("aborted");
		}
		Success("Visibilité modifiée avec succès");
		return response;
	},
);

export const fetchModifyOfferReferent = createAsyncThunk(
	"offersManagementAPI/fetchModifyOfferReferent",
	async (params: { id: string; referent: string }) => {
		const response = await modifyReferent(params.id, params.referent);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la modification du référent de l’offre");
			throw new Error("aborted");
		}
		Success("Référent modifié avec succès");
		return response;
	},
);

export const fetchModifyOfferStatus = createAsyncThunk(
	"offersManagementAPI/fetchModifyOfferStatus",
	async (params: { id: string; status: string; closeReason: string }) => {
		const response = await modifyStatus(
			params.id,
			params.status,
			params.closeReason,
		);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		Success("Statut modifié avec succès");
		return response;
	},
);

export const fetchModifyOfferTags = createAsyncThunk(
	"offersManagementAPI/fetchModifyOfferTags",
	async (params: { id: string; tags: string[] }) => {
		const response = await modifyTags(params.id, params.tags);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la modification des tags de l’offre");
			throw new Error("aborted");
		}
		Success("Tags modifiés avec succès");
		return response;
	},
);
export const fetchModifyOfferCampus = createAsyncThunk(
	"offersManagementAPI/fetchModifyOfferCampus",
	async (params: { id: string; campus: string[] }) => {
		const response = await modifyCampus(params.id, params.campus);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		Success("Campus modifié avec succès");
		return response;
	},
);

export const fetchModifyOffer = createAsyncThunk(
	"offersManagementAPI/fetchModifyOffer",
	async (params: { offerData: Partial<SchoolOfferType> }) => {
		const response = await modifyOffer(params.offerData);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la modification de l’offre");
			throw new Error("aborted");
		}
		Success("Offre modifiée avec succès");
		return response;
	},
);

export const fetchModifyOfferMemo = createAsyncThunk(
	"offersManagementAPI/fetchModifyOfferMemo",
	async (params: { id: string; memo: string }) => {
		const response = await modifyOfferMemo(params.id, params.memo);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la modification du mémo");
			throw new Error("aborted");
		}
		return response;
	},
);

export const fetchCreateOffer = createAsyncThunk(
	"offersManagementAPI/fetchCreateOffer",
	async (params: { offerData: Partial<SchoolOfferType> }) => {
		const response = await createOffer(params.offerData);
		if (response.responseLabel === "error") {
			ErrorToast("Erreur lors de la création de l’offre");
			throw new Error("aborted");
		}
		Success("Offre créée avec succès");
		return response;
	},
);

export const fetchCampuses = createAsyncThunk(
	"offersManagementAPI/fetchCampuses",
	async (params: { schoolId: string }) => {
		const response = await getCampusesList(params.schoolId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}

		return response.data.campuses.collection;
	},
);

export const fetchReferents = createAsyncThunk(
	"offersManagementAPI/fetchReferents",
	async (params: { campusList: string[]; schoolId: string }) => {
		const response = await getReferents(params.campusList, params.schoolId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data;
	},
);
export const fetchLogs = createAsyncThunk(
	"offersManagementAPI/fetchLogs",
	async (offerId: string) => {
		const response = await getMailsLogForOffer(offerId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data;
	},
);
