import type { EntCompanyType } from "@/types/EntCompanyTypes";
import { GlobalContext } from "@navigation/Router";
import React, { type ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import manIllustration from "@assets/illustrations/entreprise/entreprise-man.svg";

import { checkSiren, checkSiret } from "@tools/Checkers";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Spinner } from "@components/atoms/Spinner";
import { Input } from "@components/molecules/Input";
import { Modal } from "@components/organisms/modal/Modal";

import type { UserType } from "@/types/userTypes";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import { checkIfEntExists, joinCompany } from "../RegisterCompanyAPI";

type ProfileNextType = {
	goNext: () => void;
	setSiret: (siret: string) => void;
	setLoading: (e: boolean) => void;
	showModal: boolean;
	setShowModal: (e: boolean) => void;
	loading: boolean;
	siret: string;
};
export function ProfileCreated({
	setSiret,
	loading,
	siret,
	setLoading,
	goNext,
	showModal,
	setShowModal,
}: ProfileNextType) {
	const [validSiret, setValidSiret] = useState(false);
	const [siretError, setSiretError] = useState<string>("");
	const [siretExists, setSiretExists] = useState<null | boolean>(null);
	const [existingCompany, setExistingCompany] = useState<null | EntCompanyType>(
		null,
	);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const { user, setUser } = useContext(GlobalContext);
	const { school } = useAppSelector((state: AppState) => state.company);

	const requestJoin = async () => {
		setLoading(true);
		try {
			await joinCompany(
				user?.id as string,
				existingCompany?.id as number | undefined,
			);

			const newUser: UserType = { ...user } as UserType;
			newUser.entCompany = existingCompany || undefined;
			newUser.entEmployeeStatus = "askAccess";
			setUser(newUser);
			navigate(`/company/company-create/sent?${school?.subdomain || ""}`);
			setShowModal(false);
		} catch {
			ErrorToast("Une erreur est survenue");
		} finally {
			setLoading(false);
		}
	};

	const validateSiret = (siret: string) => {
		if (
			!siret ||
			siret.length === 0 ||
			(!checkSiret(siret) && !checkSiren(siret))
		) {
			setSiretError("Veuillez entrer un numéro SIRET/SIREN valide");
			setValidSiret(false);
		} else {
			setSiretError("");
			setValidSiret(true);
		}
	};

	const siretMessages = {
		new: `Le numéro SIRET/SIREN ${siret} n'est attribué à aucune entreprise. Vous pouvez créer votre entreprise en cliquant ci-dessous :`,
		alreadyExists:
			"L’entreprise que vous avez renseigné existe déjà, pour rejoindre l’espace de cette entreprise vous devez envoyer une demande d’adhésion.",
	};

	const profileNotFound = searchParams.get("profileNotFound");

	const headMessage = () => {
		if (profileNotFound) {
			return (
				<div className="mb-lg bg-white p-lg text-center shadow-md">
					<p className="mb-lg font-bold">Attention !</p>
					<p>Vous n'avez pas rempli vos informations d'entreprise.</p>
					<p>
						Pour déposer des offres et accéder à la CVthèque, veuillez
						renseigner votre entreprise.
					</p>
				</div>
			);
		}
		return (
			<div className="mb-lg bg-white p-lg text-center shadow-md">
				<p className="mb-lg font-bold">Félicitations !</p>
				<p>Votre compte a bien été créé.</p>
				<p>
					Pour déposer des offres et accéder à la CVthèque, veuillez renseigner
					votre entreprise.
				</p>
			</div>
		);
	};

	const modalContent = () => {
		if (siretExists === null) {
			return {
				body: (
					<p>
						{siretExists === null ? (
							<Input
								errorMessage={siretError}
								label="Numéro SIRET/SIREN"
								onBlur={(e: ChangeEvent<HTMLInputElement>) => {
									validateSiret(e.target.value.replaceAll(" ", ""));
									setSiret(e.target.value.replaceAll(" ", ""));
								}}
								onChange={(e) => {
									validateSiret(e.target.value.replaceAll(" ", ""));
									setSiret(e.target.value.replaceAll(" ", ""));
								}}
								value={siret}
								name="siret"
								type="text"
								placeholder="SIRET/SIREN"
							/>
						) : !siretExists ? (
							siretMessages.new
						) : (
							siretMessages.alreadyExists
						)}
					</p>
				),
				buttons: [
					<ButtonSecondary
						key="next"
						label="Suivant"
						onClick={async () => {
							setLoading(true);
							try {
								const companies = await checkIfEntExists(siret);
								setSiretExists(companies.length > 0);
								if (companies[0]) {
									setExistingCompany(companies[0]);
								}
							} catch {
								Error("Une erreur est survenue");
							} finally {
								setLoading(false);
							}
						}}
						disabled={!validSiret || loading}
					/>,
				],
			};
		}
		if (!siretExists) {
			return {
				body: (
					<p>
						Le numéro SIRET/SIREN <span className="font-bold">{siret}</span>{" "}
						n'est attribué à aucune entreprise. Vous pouvez créer votre
						entreprise en cliquant ci-dessous :
					</p>
				),
				buttons: [
					<ButtonSecondary
						key="cancel"
						label="Annuler"
						onClick={() => {
							setSiretExists(null);
							setExistingCompany(null);
						}}
						disabled={loading}
					/>,

					<ButtonPrimary
						key="create"
						label="Créer mon entreprise"
						onClick={goNext}
					/>,
				],
			};
		}
		return {
			body: "L’entreprise que vous avez renseigné existe déjà, pour rejoindre l’espace de cette entreprise vous devez envoyer une demande d’adhésion.",
			buttons: [
				<ButtonSecondary
					key="cancel"
					label="Annuler"
					onClick={() => {
						setSiretExists(null);
						setExistingCompany(null);
					}}
					disabled={loading}
				/>,

				<ButtonPrimary
					key="request"
					label={`Envoyer une demande d'adhésion à ${existingCompany?.name}`}
					onClick={async () => {
						await requestJoin();
					}}
					disabled={!validSiret || loading}
				/>,
			],
		};
	};

	return (
		<div className="mx-auto mb-lg h-auto w-full">
			{headMessage()}
			<div className="bg-white p-lg shadow-md">
				<p className="mb-lg font-bold">Mon entreprise</p>
				<img
					src={manIllustration as string}
					alt="Illustration d'homme"
					className="mx-auto my-md"
				/>
				<ButtonSecondary
					className="mx-auto"
					label="Renseigner mon entreprise"
					onClick={() => {
						setShowModal(true);
					}}
				/>
			</div>
			<Modal
				show={showModal}
				title="Votre entreprise"
				onClose={() => {
					setSiretError("");
					setSiret("");
					setValidSiret(false);
					setShowModal(false);
				}}
				body={
					!loading ? (
						modalContent().body
					) : (
						<div className="flex w-full">
							<Spinner size="small" className="mx-auto" />
						</div>
					)
				}
				buttonsRight={modalContent().buttons}
			/>
		</div>
	);
}
