import type { UserType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { createContext, useContext, useEffect, useState } from "react";

import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import { fetchProfileData, sendFile, updateSpsStudent } from "../DashboardAPI";

import { Competence } from "./MyProfile/Competence";
import { Documents } from "./MyProfile/Documents";
import { Identity } from "./MyProfile/Identity";
import { MyProfileSkeleton } from "./MyProfile/MyProfileSkeleton";

export type MyProfileContextType = {
	profile: UserType;
	setProfile: Function;
	updateProfileValue: Function;
	uploadFile: Function;
};

export const MyProfileContext = createContext<MyProfileContextType>({
	profile: {} as UserType,
	setProfile: () => {},
	updateProfileValue: async () => {},
	uploadFile: () => {},
});

export function MyProfile() {
	const [profile, setProfile] = useState<UserType>();
	const { user } = useContext(GlobalContext);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const fetchData = async () => {
		if (!user) return;
		const response = await fetchProfileData(user.id);
		setProfile(response);

		setIsLoading(false);
	};

	const uploadFile = async (file: any, fileName: string) => {
		if (!fileName) return;

		const formData = new FormData();

		formData.append("file", file);
		formData.append("type", fileName);

		const response = await sendFile(formData);

		return response;
	};

	const updateProfileValue = async (updateInputs: object, showToast = true) => {
		if (!user) return;

		try {
			await updateSpsStudent({
				id: user.id,
				...updateInputs,
			});
			if (showToast) {
				Success("Modification enregistrée");
			}
		} catch {
			if (showToast) {
				ErrorToast("Erreur lors de la modification");
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (isLoading || !profile) return <MyProfileSkeleton />;

	return (
		<div className="flex flex-col gap-sm">
			<MyProfileContext.Provider
				value={{
					profile,
					setProfile,
					updateProfileValue,
					uploadFile,
				}}
			>
				<Identity />
				<Documents />
				<Competence />
			</MyProfileContext.Provider>
		</div>
	);
}
