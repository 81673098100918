import { GlobalContext } from "@navigation/Router";
import React from "react";
import { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Spinner } from "@components/atoms/Spinner";

import { queryActions } from "../../SlidingPaneContentAPI";

import { ActionCard } from "./Actions/ActionCard";

export function Actions({
	setSlidingPaneIsOpen,
}: { setSlidingPaneIsOpen: (isOpen: boolean) => void }) {
	const { user } = useContext(GlobalContext);
	const [actions, setActions] = useState([]);
	const [lastPage, setLastPage] = useState(2);
	const [nextPage, setNextPage] = useState(1);

	const fetchActions = (page = 1) => {
		queryActions(user?.id as string, page).then(
			({ collection, paginationInfo }) => {
				setActions(collection);
				setNextPage(page + 1);
				setLastPage(paginationInfo.lastPage);
			},
		);
	};

	return (
		<div className="flex h-full flex-col">
			<p className=" text-center text-sm font-semibold text-primary-700P ">
				Voici ta to-do list, la liste des actions à faire pour booster ta
				recherche d’emploi.
			</p>
			<div className="mt-lg flex flex-col overflow-y-scroll">
				<InfiniteScroll
					pageStart={1}
					loadMore={() => {
						fetchActions(nextPage);
					}}
					useWindow={false}
					hasMore={nextPage < lastPage}
					loader={
						<div className="mt-sm text-center" key={0}>
							<Spinner />
						</div>
					}
				>
					<div className="flex flex-col gap-lg px-sm">
						{actions.length === 0 && (
							<p className="text-center text-primary-700P">
								Vous n'avez aucune action à réaliser
							</p>
						)}
						{actions.map((action) => {
							return (
								<ActionCard
									action={action}
									setSlidingPaneIsOpen={setSlidingPaneIsOpen}
								/>
							);
						})}
					</div>
				</InfiniteScroll>
			</div>
		</div>
	);
}
