import type { JobContractSearchOptionType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { JOB_CONTRACT_OPTIONS } from "@constants/Users";

import {
	countCurrentFilters,
	setCurrentFilters,
} from "@components/organisms/usersFilters/usersFiltersSlice";

import { Select } from "../../select/Select";

export function JobContractFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const getFormatedJobContracts = () => {
		const formattedJobContractOptions: JobContractSearchOptionType[] =
			JOB_CONTRACT_OPTIONS.map((statusOption: JobContractSearchOptionType) => {
				return {
					...statusOption,
					checked:
						currentFilters.status_list?.includes(statusOption.value) || false,
					label: statusOption.label,
					value: statusOption.value,

					type: "checkbox",
				};
			});

		return formattedJobContractOptions;
	};

	const [jobContractSearch, setJobContractSearch] = useState<
		JobContractSearchOptionType[]
	>(getFormatedJobContracts());

	useEffect(() => {
		if (currentFilters.status_list?.length !== 0) {
			setJobContractSearch(getFormatedJobContracts());
		}
	}, [currentFilters]);

	const handleChange = (statusSelected: JobContractSearchOptionType[]) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				contractSearched_list: statusSelected.map((status) => status?.value),
				page: 1,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<Select
			position="right"
			placeholder="Contrat"
			inputs={jobContractSearch.map((jobContractItem) => ({
				id: jobContractItem.value,
				name: jobContractItem.label,
				value: jobContractItem.value,
				label: jobContractItem.label,
				type: "checkbox",
				checked:
					currentFilters.contractSearched_list?.includes(
						jobContractItem.value,
					) || false,
			}))}
			onChange={(statusSelected: any) => {
				handleChange(statusSelected);
			}}
			multiselect
			breakSpaces
		/>
	);
}
