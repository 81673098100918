import { useAppSelector } from "@config/redux/hook";
import React from "react";

import emptyIllustration from "@assets/illustrations/empty-events.svg";

import EventsSkeleton from "./EventsSkeleton";

function NoEvent() {
	const { isFetchingData } = useAppSelector((state) => state.events);

	if (isFetchingData) {
		return (
			<article className="flex w-full flex-col gap-6">
				<EventsSkeleton />
			</article>
		);
	}

	return (
		<article
			className="
      flex w-full max-w-5xl
      flex-col items-center justify-center 
      rounded-md"
		>
			<>
				<img src={emptyIllustration} alt="No event" />
				<span className="text-xxsm font-normal leading-5 text-primary-500">
					Aucun évènement
				</span>
			</>
		</article>
	);
}

export default NoEvent;
