import type { FilterCheckboxType, FilterType } from "@/types/filterType";

export const formatFilterForMultiselect = (
	filters: FilterType[],
	currentFilters: string[],
) => {
	const filtersArray: FilterCheckboxType[] = [];

	filters.forEach((filter: FilterType) => {
		filtersArray.push({
			label: filter.label,
			value: filter.value,
			type: "checkbox",
			checked: currentFilters.includes(filter.value as unknown as string),
		});
	});
	return filtersArray;
};
