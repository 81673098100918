import type { ApplicationType } from "@/types/applicationTypes";

import api, {
	checkGraphQLError,
	graphqlRequestConstructor,
	type StandardGraphQLResponse,
} from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
import algoliasearch from "algoliasearch/lite";

const client = algoliasearch(
	import.meta.env.VITE_ALGOLIA_ID ?? "",
	import.meta.env.VITE_ALGOLIA_KEY ?? "",
);

const index = client.initIndex(import.meta.env.VITE_ALGOLIA_OFFER_INDEX ?? "");

export async function importOffer(id: string, isMentorGoal = true) {
	try {
		const { data } = await api.post(
			`create_application_from${isMentorGoal ? "_mentorgoal" : ""}_offer/${id}`,
		);
		return data;
	} catch (_error) {
		const responseToReturnIfError: StandardGraphQLResponse = {
			responseLabel: "error",
			data: {},
			message: "Une erreur est survenue",
		};
		return responseToReturnIfError;
	}
}

export async function retrieveSchoolOffers(filters: string) {
	const query = {
		query: `{
      collectionQueryOffers(${filters}, order: [{date: "DESC"}], status: "online" ) {
        collection {
          id
          job
					displayCompanyName
          location
          contract
          date
        },
        paginationInfo {
          totalCount
          lastPage
        }
      },
    
    }`,
	};

	const responseToQuery = await graphqlRequestConstructor(
		query,
		"retrieveSchoolOffers",
		"Récupération des offres impossible",
	);

	return responseToQuery;
}

export async function retrieveScrapedOffers(
	search: string,
	algoliaFilters: object,
) {
	const { hits, nbHits, nbPages } = await index.search(
		search ?? "",
		algoliaFilters,
	);

	const responseToReturn: StandardGraphQLResponse = {
		responseLabel: "success",
		data: {
			collectionQueryOffers: {
				collection: hits,
				paginationInfo: {
					totalCount: nbHits,
					lastPage: nbPages,
				},
			},
		},
		message: "request successful",
	};

	return responseToReturn;
}

export async function getSchoolOffersCount(filters: string) {
	let rep = "...";

	const data = {
		query: `{
      collectionQueryOffers(${filters}, order: [{date: "DESC"}], status: "online") {
        paginationInfo {
          totalCount
        }
      },
    
    }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export async function retrieveApplications(userId: string) {
	let rep: ApplicationType[] = [];

	const data = {
		query: `{
      applications(spsStudent: "${userId}", itemsPerPage: 10000) {
        collection {
          id
          url: offerLink
          offer {
            id
          }
        },
      },
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.applications.collection;
	});
	return rep;
}

export async function editApplication(inputs: object) {
	let rep = {};
	const data = {
		query: `mutation {
        updateApplication(input: ${stringifyRequestInputs(inputs)}) {
          application {
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateApplication;
	});
	return rep;
}
