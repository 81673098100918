import { data } from "jquery";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { formatTime } from "@tools/Dates";

import { AccordionCustom } from "@components/organisms/Accordion";

import { getInterview } from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";

import { getCookie } from "@tools/Cookies";
import { InterviewInformation } from "./InterviewInformation";
import { PostInterview } from "./PostInterview";
import { Questions } from "./Questions";

export function Interview() {
	const location = useLocation();
	const applicationId = Number(location.pathname.split("/")[2]);
	const [interviewInfo, setInterviewInfo] = useState({
		date: data.interviewDate !== null ? data.interviewDate : undefined,
		hour: "",
		contacts: [],
		typeOfInterview: "téléphone",
	});
	const [interviewLoadedStatus, setInterviewLoadedStatus] = useState(false);

	useEffect(() => {
		setInterviewLoadedStatus(false);
		getInterview(
			`${getCookie("interface")}/applications/${applicationId}`,
		).then((data) => {
			setInterviewInfo({
				...data,
				date: new Date(
					data.interviewDate !== null ? data.interviewDate : undefined,
				),
				hour: data.interviewDate && formatTime(data?.interviewDate),
				contacts: data.contacts?.collection,
				typeOfInterview: data.interviewLocation,
				answerPreparationQuestions: data.answerPreparationQuestions,
			});
			setInterviewLoadedStatus(true);
		});
	}, [setInterviewInfo]);

	return (
		<div>
			<div id="questions">
				<InterviewInformation
					interviewInfoProps={{ interviewInfo, setInterviewInfo }}
				/>
				<AccordionCustom
					title=" Les 10 questions d'un entretien réussi"
					headColor="primary-100"
					className="mt-md"
					parentId="#questions"
					headClassName="p-sm"
					body={
						<Questions
							applicationId={applicationId}
							interviewInfo={interviewInfo}
							interviewLoaded={interviewLoadedStatus}
						/>
					}
				/>
				<AccordionCustom
					title="Comment s'est passé votre entretien ?"
					headColor="primary-100"
					className="mt-md"
					parentId="#questions"
					headClassName="p-sm"
					body={
						<PostInterview
							applicationId={applicationId}
							interviewInfo={interviewInfo}
							interviewLoaded={interviewLoadedStatus}
						/>
					}
				/>
			</div>
		</div>
	);
}
