import { Icon } from "@iconify/react";
import type { IconifyIcon } from "@iconify/types";
import React, { type MouseEventHandler } from "react";

import "@assets/sass/Button.scss";

type ButtonQuinaryPropsType = {
	label?: string;
	className?: string;
	disabled?: boolean;
	icon: string | IconifyIcon;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	dropdownButton?: boolean;
	id?: string;
	shape?: string;
	notifCount?: number;
	iconColor?: string;
};

export function ButtonQuinary({
	className = "",
	disabled = false,
	icon,
	shape = "rectangle",
	onClick,
	notifCount = 0,
	dropdownButton = false,
	iconColor = "text-primary-700P",
}: ButtonQuinaryPropsType) {
	return (
		<button
			data-id="buttonQuinary"
			type="button"
			data-toggle={dropdownButton && "dropdown"}
			aria-haspopup={dropdownButton && true}
			aria-expanded={dropdownButton && false}
			onClick={onClick}
			disabled={disabled}
			className={`button-quinary ${className} ${shape}`}
		>
			{notifCount > 0 && (
				<span className="notif">{notifCount < 10 ? notifCount : "9+"}</span>
			)}
			<Icon icon={icon} className={iconColor} />
		</button>
	);
}
