import React from "react";
import { DragDropContext, type DropResult } from "react-beautiful-dnd";

import { Column, type ColumnType } from "./Column";

export type FieldType = {
	label: string;
	value: string;
	name: string;
	type: string;
	required: boolean;
};

interface ProspectBaseType {
	id: number;
	columnId: number;
	position: number;
	createdAt: string;
	hasBeenOpened: boolean;
}

export interface CardType extends ProspectBaseType {
	data: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
	};
}

export interface ProspectType extends ProspectBaseType {
	data: {
		[key: string]: FieldType;
	};
}

type ATSProps = {
	onCardClick: (card: CardType) => void;
	cards: CardType[];
	columns: ColumnType[];
	reorderCards: (
		sourceIndex: number,
		destinationIndex: number,
		sInd: number,
	) => void;
	updateCardsColumn: (
		cardId: number,
		dInd: number,
		destinationIndex: number,
		sInd: number,
	) => void;
};

export const Kanban = ({
	onCardClick,
	cards,
	columns,
	reorderCards,
	updateCardsColumn,
}: ATSProps) => {
	const onDragEnd = (result: DropResult) => {
		const { source, destination, draggableId } = result;
		// dropped outside the list
		if (!destination) {
			return;
		}
		const sInd = +source.droppableId.split("-")[1];
		const dInd = +destination.droppableId.split("-")[1];
		const cardId = +draggableId.split("-")[1];
		if (sInd === dInd) {
			reorderCards(source.index, destination.index, sInd);
			return;
		}
		updateCardsColumn(cardId, dInd, destination.index, sInd);
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<div className="flex flex-1 flex-row gap-4 overflow-x-auto">
				{columns.map((column) => (
					<Column
						key={`ATS_COLUMN-${column.id}`}
						column={column}
						cards={cards.filter((card) => card.columnId === column.id)}
						onCardClick={onCardClick}
					/>
				))}
			</div>
		</DragDropContext>
	);
};
