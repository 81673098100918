import type { SchoolOfferType, ScrapedOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { bulbOutline } from "@assets/Icons";

import { PaginationPrimary } from "@components/molecules/PaginationPrimary";
import InformationBanner from "@components/templates/informationBanner/InformationBanner";

import { setFilters } from "@containers/student/Jobboard/controller/jobboard.slice";

import { NoResults } from "./shared/NoResults";
import { OfferCardsSkeleton } from "./shared/OfferCardSkeleton";
import { SchoolOfferCard } from "./shared/SchoolOfferCard";

export function SchoolOffers() {
	const dispatchJobboard = useDispatch();
	const { schoolOffers, lastPage, loadSchoolOffers, filters } = useAppSelector(
		(state) => state.jobboard,
	);
	return (
		<div className="col-span-4 flex flex-col md:col-span-8 lg:col-span-12">
			<InformationBanner
				icon={bulbOutline}
				className="mb-sm hidden md:flex"
				body={
					<div>
						<p>Tu trouveras ici :</p>
						<ul className="ml-sm list-disc">
							<li>
								les offres d'emplois des entreprises partenaires de ton école
							</li>
							<li>les offres d'emplois sélectionnées par ton école</li>
						</ul>
					</div>
				}
			/>
			{schoolOffers.length === 0 && !loadSchoolOffers && <NoResults />}
			{schoolOffers.length > 0 && !loadSchoolOffers && (
				<div id="offers" className="flex flex-col gap-sm">
					{schoolOffers?.map((offer: SchoolOfferType | ScrapedOfferType) => {
						return (
							<SchoolOfferCard
								offer={offer as SchoolOfferType}
								key={offer.id}
							/>
						);
					})}
					<PaginationPrimary
						last={lastPage}
						current={filters.page}
						onChange={(e) => {
							dispatchJobboard(setFilters({ ...filters, page: e }));
							window.scrollTo(0, 0);
						}}
					/>
				</div>
			)}
			{loadSchoolOffers && <OfferCardsSkeleton />}
		</div>
	);
}
