import React from "react";

import "@assets/sass/Divider.scss";

export function Divider({
	className = "h-[1px] bg-primary-150",
}: {
	className?: string;
}) {
	return <div data-id="divider" className={className} />;
}
