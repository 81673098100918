import type { SelectionType } from "@/types/profileSharingTypes";
import type { UserType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";

import { alertTriangleOutline, emailOutline, eyeOutline } from "@assets/Icons";

import { convertDateFromNow, formatDate } from "@tools/Dates";

import type { EmailTrackingType } from "@/types/offerTypes";
import type { SelectionUserType } from "@/types/profileSharingTypes";
import { Avatar } from "@components/atoms/Avatar";
import type { BadgeType } from "@components/atoms/Badge";
import { Badge } from "@components/atoms/Badge";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { RatingStars } from "@components/atoms/RatingStars";
import { InputCopy } from "@components/molecules/InputCopy";
import type { StudentStatus } from "@containers/public/Selection/ui/StudentCard";
import {
	DOWNLOAD_SHARE_TYPE,
	EMAIL_SHARE_TYPE,
	LINK_SHARE_TYPE,
} from "@containers/school/CVBoard/ui/CVsList/TableView/ShareCVModal/ShareV2";
import { resolveTrackingStatus } from "@containers/school/Offers_v3/ui/details/ShipmentTrackingCard";
import { Icon } from "@iconify/react";

type SelectionModalBodyType = {
	selection: SelectionType;
	user: UserType;
	load: boolean;
	toggleEmailModal: () => void;
};

export const NOT_OPENED_STATUS = 0;

const getTypeStringFromType = (type: number) => {
	switch (type) {
		case EMAIL_SHARE_TYPE:
			return "Par mail";
		case LINK_SHARE_TYPE:
			return "Par lien";
		case DOWNLOAD_SHARE_TYPE:
			return "Téléchargement";
		default:
			return "Inconnu";
	}
};

export const getBadgeByStatus = (status: StudentStatus): BadgeType | null => {
	switch (status) {
		case 2:
			return {
				label: "À rencontrer",
				bgColor: "bg-alert-success-vert-100",
				textColor: "text-alert-success-vert-500",
				borderColor: "border-alert-success-vert-500",
			};
		case 3:
			return {
				label: "Refusé",
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
			};
		default:
			return {
				label: "En attente",
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
			};
	}
};

export function SelectionModalBody({
	selection,
	user,
	toggleEmailModal,
}: SelectionModalBodyType) {
	const [consultedSelectionUsers, setConsultedSelectionUsers] = useState<
		SelectionUserType[]
	>([]);
	const [notConsultedSelectionUsers, setNotConsultedSelectionUsers] = useState<
		SelectionUserType[]
	>([]);

	const _consultUser = async (userTarget: UserType) => {
		window.open(`${import.meta.env.VITE_S3}${userTarget?.cv?.filePath}`);
	};
	useEffect(() => {
		if (selection) {
			setConsultedSelectionUsers(
				selection?.selectionUsers?.collection?.filter(
					({ status }) => status !== NOT_OPENED_STATUS,
				),
			);
			setNotConsultedSelectionUsers(
				selection?.selectionUsers?.collection?.filter(
					({ status }) => status === NOT_OPENED_STATUS,
				),
			);
		}
	}, [selection]);

	const getIsLast20EmailSentOver1day = () => {
		if (selection?.emailsTracking && selection?.emailsTracking.length < 20) {
			return true;
		}
		const lastEmailSent =
			selection?.emailsTracking?.[selection?.emailsTracking.length - 20];
		if (!lastEmailSent) {
			return true;
		}
		const nextDay = new Date(lastEmailSent.sentAt);
		nextDay.setDate(nextDay.getDate() + 1);
		return new Date() > nextDay;
	};
	const uri = `${import.meta.env.VITE_URL}/selection/${user?.schoolSubdomain}/${selection?.token}`;
	return (
		<div className="flex">
			<div className="flex w-4/12 flex-col gap-sm text-primary-700P">
				<p className="text-sm font-bold">Informations</p>
				<div className="flex flex-col gap-md rounded-md shadow-sm p-sm">
					<div>
						<p className="text-xxsm text-primary-300">Entreprise</p>
						<p className="wg-no-translate text-xsm">
							{selection?.crmCompany?.name}
						</p>
					</div>
					<div>
						<p className="text-xxsm text-primary-300">Date de création</p>
						<p className="text-xsm">
							{formatDate(new Date(selection?.sendDate))}
						</p>
					</div>
					<div>
						<p className="text-xxsm text-primary-300">Type de partage</p>
						<p className="text-xsm">{getTypeStringFromType(selection.type)}</p>
					</div>
					<div>
						<p className="text-xxsm text-primary-300">Profils partagés</p>
						<p className="text-xsm">
							{selection.selectionUsers?.collection?.length}
						</p>
					</div>
					<div>
						<p className="text-xxsm text-primary-300">Partagés par</p>
						<p className="text-xsm">
							{selection.spsReferent.firstname} {selection.spsReferent.lastname}
						</p>
					</div>
					<div>
						<p className="text-xxsm text-primary-300">Dernière ouverture</p>
						<p className="text-xsm">
							{selection?.lastOpening
								? convertDateFromNow(new Date(selection?.lastOpening))
								: "Jamais ouvert"}
						</p>
					</div>
					<div>
						<p className="text-xxsm text-primary-300">Campus</p>
						<p className="text-xsm">
							{selection.coreCampuses?.collection.map(({ name }, index) =>
								selection.coreCampuses
									? `${name}${index < selection.coreCampuses.collection.length - 1 ? ", " : ""}`
									: "",
							)}
						</p>
					</div>
					<div className="flex flex-col gap-xsm">
						<InputCopy URI={uri} label="Lien du partage" />
						<InlineButton
							label="Prévisualiser le partage"
							icon={eyeOutline}
							onClick={() => {
								window.open(uri, "_blank");
							}}
						/>
					</div>
				</div>
			</div>
			<div className="w-8/12 pl-lg flex flex-col gap-sm">
				<div>
					<p className="text-primary700P text-sm font-bold">
						Profils consultés ({consultedSelectionUsers.length})
					</p>
					{consultedSelectionUsers.length !== 0 && (
						<div className="mt-md flex flex-col gap-sm overflow-y-auto">
							{consultedSelectionUsers.map((selectionUser) => (
								<SelectionProfileCard selectionUser={selectionUser} />
							))}
						</div>
					)}
				</div>
				<div>
					<p className="text-primary700P text-sm font-bold">
						Profils non consultés ({notConsultedSelectionUsers.length})
					</p>
					{notConsultedSelectionUsers.length !== 0 && (
						<div className="mt-md flex flex-col gap-sm overflow-y-auto">
							{notConsultedSelectionUsers.map((selectionUser) => (
								<SelectionProfileCard selectionUser={selectionUser} />
							))}
						</div>
					)}
				</div>
			</div>
			{(selection.type === EMAIL_SHARE_TYPE ||
				(selection.emailsTracking &&
					selection.emailsTracking.length !== 0)) && (
				<div className="w-8/12 pl-lg flex flex-col gap-sm">
					<p className="text-primary700P text-sm font-bold">Suivi des emails</p>
					{selection.emailsTracking &&
						selection.emailsTracking.length !== 0 && (
							<div className="mt-md flex flex-col gap-sm overflow-y-auto">
								{selection.emailsTracking.map((email) => (
									<SelectionEmailCard email={email} />
								))}
							</div>
						)}
					<p className="text-alert-error-500 flex items-center gap-xxsm">
						<Icon icon={alertTriangleOutline} className={"h-5 w-5"} /> Partage
						non consulté, relancer ?
					</p>
					<ButtonPrimary
						icon={emailOutline}
						label={"Renvoyer la sélection"}
						size={"small"}
						onClick={toggleEmailModal}
						disabled={!getIsLast20EmailSentOver1day()}
					/>
				</div>
			)}
		</div>
	);
}

const SelectionEmailCard = ({ email }: { email: EmailTrackingType }) => {
	const [badge, setBadge] = useState<Partial<BadgeType>>();
	useEffect(() => {
		setBadge(resolveTrackingStatus(email.status));
	}, []);
	return (
		<div className="flex flex-col p-sm gap-sm rounded-lg shadow-sm bg-white">
			<div>
				<p className="text-xxsm text-primary-300">Destinataire</p>
				<p className="text-xsm flex items-center gap-xxsm">
					<Icon icon={emailOutline} />
					{email.emailAddress}
				</p>
			</div>
			<div>
				<p className="text-xxsm text-primary-300">Historique du mail</p>
				<p className="text-xsm flex gap-xsm flex-col">
					Mail envoyé le {formatDate(new Date(email.sentAt))}
					{badge && (
						<Badge
							label={badge.label ?? ""}
							bgColor={badge.bgColor}
							textColor={badge.textColor}
							borderColor={badge.borderColor}
							size={"medium"}
						/>
					)}
				</p>
			</div>
		</div>
	);
};

const SelectionProfileCard = ({
	selectionUser,
}: { selectionUser: SelectionUserType }) => {
	const [badge, setBadge] = useState<BadgeType | null>(null);
	useEffect(() => {
		setBadge(getBadgeByStatus(selectionUser.status));
	}, []);
	return (
		<div
			key={`user${selectionUser?.user.lastname}`}
			className="flex w-11/12 flex-col rounded-lg px-sm py-xsm shadow-sm bg-white gap-xsm"
		>
			<div className="flex items-center gap-sm">
				<Avatar
					firstname={selectionUser.user.firstname || ""}
					lastname={selectionUser.user.lastname || ""}
					image={
						selectionUser.user.avatar
							? `${import.meta.env.VITE_S3}${
									selectionUser.user.avatar?.filePath
								}`
							: null
					}
				/>
				<div className="flex flex-col gap-xxsm">
					<p className="wg-no-translate font-bold text-xsm">
						{`${selectionUser.user.firstname} ${selectionUser.user.lastname}`}
					</p>
					{selectionUser.consultedTimes !== 0 && (
						<p className="text-xxsm text-primary-300">
							Consulté {selectionUser.consultedTimes} fois
						</p>
					)}
				</div>
			</div>
			{selectionUser.status !== NOT_OPENED_STATUS && (
				<>
					{selectionUser.stars !== 0 && (
						<RatingStars checkedStars={selectionUser.stars} />
					)}
					{selectionUser.comment && (
						<>
							<p className="text-xxsm text-primary-300">
								Commentaire du recruteur
							</p>
							<p className="text-xsm">{selectionUser.comment}</p>
						</>
					)}
					<p className="text-xxsm text-primary-300">Action du recruteur</p>
					{badge && (
						<Badge
							label={badge.label}
							bgColor={badge.bgColor}
							textColor={badge.textColor}
							borderColor={badge.borderColor}
							size={"medium"}
						/>
					)}
				</>
			)}
		</div>
	);
};
