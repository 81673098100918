import type { EventType, EventsTabLabelType } from "@/types/eventsTypes";
import React from "react";

import { categorizePlannedEvents } from "@tools/Events";

import EventsList from "@containers/shared/Events/UI/list/EventsList";

import {
	EventsTabLabelEnum,
	useEventsView,
} from "@containers/shared/Events/UI/useEventsView";
import EventsTabsRow from "../../../shared/Events/UI/EventsTabsRow";

import Header from "./Header";

export function EventsSchoolView() {
	const presenter = useEventsView({ title: "Évènements et Coachings Emploi" });

	return (
		<main className="grid-page" data-testid="eventSchoolView">
			<Header />
			<div
				className="
          col-span-4 flex flex-col
          gap-8 rounded-lg bg-white px-4 py-6 md:col-span-8 lg:col-span-12"
			>
				<EventsTabsRow
					eventTabs={presenter.tabsCounters}
					selectTabEvent={presenter.setActiveTabLabel}
					activeTabLabel={presenter.activeTabLabel}
				/>
				{renderEventsList(presenter.events, presenter.activeTabLabel)}
			</div>
		</main>
	);
}

const renderEventsList = (
	events: EventType[],
	tabLabel: EventsTabLabelType,
) => {
	switch (tabLabel) {
		case EventsTabLabelEnum.planned: {
			const { todayEvents, upcomingEvents } = categorizePlannedEvents(events);
			return (
				<>
					{todayEvents?.length > 0 && (
						<EventsList
							events={todayEvents}
							listTitle="Aujourd'hui"
							listID="planned"
						/>
					)}
					{upcomingEvents?.length > 0 && (
						<EventsList
							events={upcomingEvents}
							listTitle="Prochainement"
							listID="planned"
						/>
					)}
					{todayEvents?.length === 0 && upcomingEvents?.length === 0 && (
						<EventsList
							events={upcomingEvents}
							listTitle="Prochainement"
							listID="planned"
						/>
					)}
				</>
			);
		}
		case EventsTabLabelEnum.replay:
			return (
				<EventsList
					events={events}
					listTitle="Évènements passés"
					listID="replay"
				/>
			);
		case EventsTabLabelEnum.finished:
			return (
				<EventsList
					events={events}
					listTitle="Évènements terminés"
					listID="finished"
				/>
			);
		default:
			return (
				<EventsList
					events={events}
					listTitle="Prochainement"
					listID="planned"
				/>
			);
	}
};
