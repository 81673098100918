import React from "react";

import "@assets/sass/Spinner.scss";

export function Spinner({
	className = "",
	size = "large",
}: {
	className?: string;
	size?: "small" | "large";
}) {
	return (
		<div
			className={`lds-ring ${className} ${
				size === "large" ? "h-20 w-20" : "h-6 w-6"
			}`}
		>
			{Array.from({ length: 4 }, (_, index) => (
				<div
					key={`spinner-${index}`}
					className={`border border-white ${
						size === "large" ? "m-2 h-16 w-16 border-8 " : "h-5 w-5 border-2"
					}`}
				/>
			))}
		</div>
	);
}
