import React from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";
type CloseOfferModalType = {
	show: boolean;
	onClose: () => void;
	onDelete: () => void;
};
export function CloseOfferModal({
	show,
	onClose,
	onDelete,
}: CloseOfferModalType) {
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Confirmer la clôture"
			body={
				<p>
					Êtes-vous sûr de vouloir clôturer cette offre ? <br /> Cette action
					est irréversible
				</p>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					label="Non, annuler"
					onClick={(e) => {
						e.stopPropagation();
						onClose();
					}}
				/>,
				<ButtonPrimary
					key={1}
					label="Oui, confirmer"
					onClick={(e) => {
						e.stopPropagation();
						onDelete();
					}}
				/>,
			]}
		/>
	);
}
