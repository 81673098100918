import PropTypes from "prop-types";
import React from "react";

import {
	emailFill,
	linkedinFill,
	phoneFill,
	starFill,
	starOutline,
} from "@assets/Icons";

import { ToggleButtonFavorite } from "@components/atoms/ToggleButton";
import { Accordion } from "@components/organisms/Accordion";

export function Contacts({ listContacts }) {
	return (
		<div className="w-6/12">
			<h4 className="text-sm font-bold text-primary-700P">Contacts</h4>
			{listContacts.length > 0 ? (
				listContacts.map((elem, i) => {
					const items = [];
					if (elem.email) {
						items.push({
							icon: emailFill,
							label: elem.email,
							onClick: () => {},
						});
					}
					if (elem?.phone?.length > 0 && elem.phone) {
						elem.phone.map((phone) => {
							if (phone) {
								return items.push({
									icon: phoneFill,
									label: phone,
									onClick: () => {},
								});
							}
							return null;
						});
					}
					if (elem.linkedin) {
						items.push({
							icon: linkedinFill,
							label: elem.linkedin,
							onClick: () => {},
						});
					}

					if (items.length === 0) {
						items.push({
							label: "Aucune information renseignée",
						});
					}

					return (
						<span key={elem.id}>
							<Accordion
								parentID="accordion3"
								title={`${elem.firstname} ${elem.lastname}`}
								subtitle={elem.job}
								button
								avatar
								avatarImage={elem.image}
								toggleButton={
									<ToggleButtonFavorite
										icon={starOutline}
										activeIcon={starFill}
										active={elem.favorite}
									/>
								}
								className="mt-sm"
								items={items}
								deployed={i === 0}
							/>
						</span>
					);
				})
			) : (
				<p className="mb-xxlg mt-xxlg pb-xxlg pt-xxlg text-center text-xsm font-bold text-primary-300">
					Aucun contact
				</p>
			)}
		</div>
	);
}

Contacts.propTypes = {
	listContacts: PropTypes.arrayOf.isRequired,
};
