import { stringifyRequestInputs } from "@tools/Objects";

import api from "../../../tools/API";

export async function changePassword(data) {
	let rep = {};
	await api.post("change_password", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function editCoreUser(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
            updateUser(input: ${stringifyRequestInputs(inputs)}) {
                user {
                    id
                }
            }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateUser.user;
	});
	return rep;
}

export async function editUser(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
            updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
                user: spsStudent {
                    id
                }
            }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateSpsStudent.user;
	});
	return rep;
}

export async function getSchoolSmsCredits(schoolId) {
	let rep = {};

	const data = {
		query: `query {
      school(id: "${schoolId}"){
            id
            smsCredits
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
