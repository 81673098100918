import React from "react";
import Skeleton from "react-loading-skeleton";

export function MyCareerSkeleton() {
	return (
		<div className="flex flex-col gap-lg">
			{["Expérience professionnelle", "Formation", "Projet"].map((title) => {
				return (
					<div className="flex flex-col gap-sm">
						<p className="font-bold text-[18px]">{title}</p>
						<Skeleton height={128} borderRadius={16} />
					</div>
				);
			})}
		</div>
	);
}
