import type { FetchCompaniesListParamsType } from "@/types/companyRequest.types";
import type { ViewType } from "@/types/genericType";
import { useAppSelector } from "@config/redux/hook";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { companiesManagementSlice } from "@containers/school/Companies/core/store/companiesManagement.slice";
import { fetchCompaniesList } from "@containers/school/Companies/core/store/companiesManagement.thunks";
import { fetchUserTableConfig } from "@containers/school/Offers_v3/core/store/offersManagement.thunks";

function useCompaniesReloadManagement() {
	const { structureView }: { structureView: ViewType } = useOutletContext();
	const dispatchEvent = useDispatch();
	const { paginationState, isInitialized, dataWasModified } = useAppSelector(
		(state) => state.companiesManagement,
	);

	const { currentSearch, currentFilters } = useAppSelector(
		(state) => state.offersFilters,
	);

	const constructFetchParams = () => {
		const params: FetchCompaniesListParamsType = {
			search: currentSearch,
			page: paginationState.pageShown,
			itemsPerPage: paginationState.itemsPerPage,
			filters: currentFilters,
			campuses: structureView?.campus ? structureView.campus : undefined,
			schoolId: structureView?.school ? structureView.school : "",
			order: paginationState.order,
		};
		return params;
	};

	useEffect(() => {
		if (isInitialized) {
			dispatchEvent(companiesManagementSlice.actions.setCurrentPage(1));
			dispatchEvent(fetchCompaniesList(constructFetchParams()) as any);
		}
	}, [currentSearch, currentFilters]);

	useEffect(() => {
		if (isInitialized) {
			dispatchEvent(fetchCompaniesList(constructFetchParams()) as any);
		}
	}, [
		paginationState.pageShown,
		paginationState.itemsPerPage,
		paginationState.order,
		structureView,
	]);

	useEffect(() => {
		if (dataWasModified) {
			dispatchEvent(fetchCompaniesList(constructFetchParams()) as any);
			dispatchEvent(companiesManagementSlice.actions.setDataWasModified(false));
		}
	}, [dataWasModified]);

	useEffect(() => {
		if (isInitialized) {
			return;
		}
		dispatchEvent(fetchUserTableConfig() as any);
		dispatchEvent(fetchCompaniesList(constructFetchParams()) as any);
	}, []);
}

export default useCompaniesReloadManagement;
