import type { EventType } from "@/types/eventsTypes";
import type { UserType } from "@/types/userTypes";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

import { addPerson, link2Outline } from "@assets/Icons";

import {
	checkEventAccessiblity,
	getAccessButtonLabel,
	isMentorGoalEvent,
} from "@tools/Events";
import { extractIdNumberFromId } from "@tools/Users";

import ButtonOpenAddress from "@components/atoms/ButtonOpenAddress";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";
import { StudentsTargetingModal } from "@components/templates/studentsTargetingModal";

import { useAppDispatch } from "@config/redux/store";
import {
	type InvitationsRequestDataType,
	sendEventInvitations,
	sendMentorgoalInvitations,
} from "@containers/shared/Events/core/api/events.request";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";

type OptionPlannedProps = {
	event: EventType;
};
export function OptionPlanned({ event }: OptionPlannedProps) {
	const dispatchEvent = useAppDispatch();
	const { structureView }: { structureView: object } = useOutletContext();
	const [displayTargetingModal, setDisplayTargetingModal] =
		useState<boolean>(false);
	const handleOpenEventLink = () => {
		if (event?.eventLink) {
			window.open(
				decodeURIComponent(event.eventLink),
				"_blank",
				"noopener noreferrer",
			);
			toast.success("Lien ouvert dans un nouvel onglet");
		}
	};
	const handleCopyEventLink = () => {
		navigator.clipboard.writeText(event?.eventLink || "");
		toast.success("Lien copié dans le presse-papier");
	};

	const handleInviteStudents = async (
		sendEmail: boolean,
		sendSMS: boolean,
		selectedStudents: Partial<UserType>[],
	) => {
		if (selectedStudents.length === 0) {
			ErrorToast("Les invitations ont échouées, aucun étudiant sélectionné");
			return;
		}
		if (!sendEmail && !sendSMS) {
			ErrorToast(
				"Les invitations ont échouées, aucun moyen de contact sélectionné",
			);
			return;
		}
		let response;
		const invitationsData: InvitationsRequestDataType = {
			eventId: extractIdNumberFromId(event.id.toString()),
			selectedStudents,
			sendEmail,
			sendSMS,
		};
		if (isMentorGoalEvent(event.type)) {
			response = await sendMentorgoalInvitations(invitationsData);
		} else response = await sendEventInvitations(invitationsData);
		dispatchEvent(eventsSlice.actions.setPerformFetchEvents(true));
		if (response.responseLabel === "success") {
			Success(`${selectedStudents.length} invitations envoyées`);
		} else {
			ErrorToast(response.message);
		}
	};

	return (
		<div
			className="flex flex-col items-center justify-center gap-4"
			data-testid="open-event-link"
		>
			{event.address &&
				(event.location === "Sur site" || event.location === "Hybride") && (
					<ButtonOpenAddress address={event.address || ""} />
				)}
			{event?.location &&
				(event.location === "Distanciel" || event.location === "Hybride") && (
					<ButtonPrimary
						label={getAccessButtonLabel(event)}
						onClick={() => {
							handleOpenEventLink();
						}}
						className="w-full"
						disabled={!checkEventAccessiblity(event)}
					/>
				)}
			<div className="flex w-full flex-col justify-between gap-2 sm:flex-row">
				<ButtonSecondary
					label="Copier le lien"
					icon={link2Outline}
					className="flex-1"
					onClick={() => {
						handleCopyEventLink();
					}}
					disabled={!event.eventLink}
				/>
				<ButtonSecondary
					label="Inviter des étudiants"
					icon={addPerson}
					className="flex-1"
					onClick={() => {
						setDisplayTargetingModal(true);
					}}
				/>
				<StudentsTargetingModal
					show={displayTargetingModal}
					onClose={() => {
						setDisplayTargetingModal(false);
					}}
					structureView={structureView}
					label="Vous pouvez sélectionner des étudiants à qui partager l’évènement pour les notifier."
					title={`Inviter les étudiants - ${event?.title}`}
					share={(sendEmail, sendSMS, selectedStudents) => {
						handleInviteStudents(sendEmail, sendSMS, selectedStudents);
					}}
				/>
			</div>
		</div>
	);
}
