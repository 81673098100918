import type { LogType } from "@/types/logTypes";
import React from "react";

import { formatTime } from "@tools/Dates";
import { resolveLog } from "@tools/Logs";

import { Avatar } from "@components/atoms/Avatar";
import { LogDescription } from "@components/organisms/LogHistory/LogDescription";

type LogProps = {
	log: LogType;
	selectedStudentState: any;
	setShowStudent: Function;
};
export function Log({ log, selectedStudentState, setShowStudent }: LogProps) {
	const { setSelectedStudent } = selectedStudentState;

	return (
		<div className="log flex">
			<Avatar
				firstname={log?.user?.firstname || ""}
				lastname={log?.user?.lastname || ""}
				image={
					log?.user?.avatar?.filePath
						? `${import.meta.env.VITE_S3}${log?.user?.avatar?.filePath}`
						: ""
				}
			/>
			<div className="ml-sm">
				<p className="text-xsm text-primary-700P">
					<span
						className="wg-no-translate cursor-pointer font-bold"
						onClick={() => {
							setSelectedStudent(log.user);
							setShowStudent(true);
						}}
					>
						{log?.user?.firstname} {log?.user?.lastname}{" "}
					</span>
					<span
						dangerouslySetInnerHTML={{
							__html: resolveLog(log, "school").label,
						}}
					/>
				</p>
				<LogDescription log={log} />
				<hr className="mb-xxsm mt-xxsm" />
				<p className="text-xxxsm font-bold text-primary-200">
					À {log?.createdAt ? formatTime(log?.createdAt) : ""}
				</p>
			</div>
		</div>
	);
}
