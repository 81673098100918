import { Icon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import moment from "moment";
import React from "react";

import {
	birthdayCake,
	globe2Outline,
	link2Outline,
	linkedinFill,
} from "@assets/Icons";

import { STUDENT_STATUS_OPTIONS } from "@constants/Users";

import { getAgeFromBirthdate } from "@tools/Dates";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import { editUser } from "../../../Students/StudentsManagement/StudentsManagementAPI";

export function Head({ selectedStudent }) {
	const changeStudentStatus = async (student, newStatus) => {
		const data = {
			status: newStatus,
			id: student.id,
		};
		editUser(data).then(() => {});
	};
	return (
		<div className="flex flex-col  items-center">
			<Avatar
				image={selectedStudent.avatar?.filePath}
				firstname={selectedStudent.firstname}
				lastname={selectedStudent.lastname}
				modify={false}
				size="lg"
			/>
			<p className="wg-no-translate font-semifont-bold mt-sm text-sm text-primary-700P">
				{`${selectedStudent.firstname} ${selectedStudent.lastname} `}
				<span className="text-primary-200">
					- {selectedStudent?.promotion?.name}
				</span>
			</p>
			{selectedStudent.birthdate && (
				<p className="flex text-xxxsm text-primary-300">
					<Icon className="h-4 text-primary-300" icon={birthdayCake} />{" "}
					{getAgeFromBirthdate(selectedStudent.birthdate)} ans -{" "}
					{moment(selectedStudent.birthdate).format("DD/MM/YYYY")}
				</p>
			)}

			{(selectedStudent.linkedin ||
				selectedStudent.portfolio ||
				selectedStudent.customLink1 ||
				selectedStudent.customLink2) && (
				<div className="mt-xsm flex items-center gap-xsm">
					{selectedStudent.linkedin && (
						<ButtonQuaternary
							icon={linkedinFill}
							onClick={() => {
								window.open(selectedStudent.linkedin, "_blank");
							}}
						/>
					)}
					{selectedStudent.portfolio && (
						<ButtonQuaternary
							icon={globe2Outline}
							onClick={() => {
								window.open(selectedStudent.portfolio, "_blank");
							}}
						/>
					)}
					{selectedStudent.customLink1 && (
						<Tippy
							className="truncate"
							content={selectedStudent.customLink1}
							theme="primary"
							animation="fade"
							placement="top"
							zIndex={5}
						>
							<span>
								<ButtonQuaternary
									icon={link2Outline}
									onClick={() => {
										window.open(selectedStudent.customLink1, "_blank");
									}}
								/>
							</span>
						</Tippy>
					)}
					{selectedStudent.customLink2 && (
						<Tippy
							className="truncate"
							content={selectedStudent.customLink2}
							theme="primary"
							animation="fade"
							placement="top"
							zIndex={5}
						>
							<span>
								<ButtonQuaternary
									icon={link2Outline}
									onClick={() => {
										window.open(selectedStudent.customLink2, "_blank");
									}}
								/>
							</span>
						</Tippy>
					)}
				</div>
			)}

			<div className="flex justify-center">
				<SelectSecondary
					className="mt-sm"
					defaultValue={() => {
						return STUDENT_STATUS_OPTIONS.find(
							(x) => x.value === selectedStudent.status,
						);
					}}
					name="status"
					options={STUDENT_STATUS_OPTIONS}
					onChange={(value) => {
						changeStudentStatus(selectedStudent, value.value);
					}}
				/>
			</div>
			{selectedStudent?.createdAt && (
				<p className="mt-md text-center text-xxxsm text-primary-500">
					Étudiant créé le{" "}
					{moment(selectedStudent?.createdAt).format("DD/MM/YYYY")}
				</p>
			)}
		</div>
	);
}
