import useDebounce from "@hooks/useDebounce";
import { Icon } from "@iconify/react";
import React, { createElement, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-number-input";

import { arrowDownOutline, arrowUpOutline } from "@assets/Icons";

import { checkPhone } from "@tools/Checkers";

import "react-phone-number-input/style.css";

type E164Number = string & { __tag: "E164Number" };

type InputPhoneNumberType = {
	value?: string;
	onChange: (e?: string) => void;
	onBlur: (e?: string) => void;
	className?: string;
	errorMessage?: string;
	errorCondition?: boolean;
	required?: boolean;
	label: string;
	disabled?: boolean;
	name?: string;
};
export function InputPhoneNumber({
	value,
	onChange,
	className = "",
	label,
	onBlur,
	errorMessage = "Le format est incorrect. Ex : 06 12 13 14 15",
	errorCondition = true,
	required = true,
	disabled = false,
	name = "phone",
}: InputPhoneNumberType) {
	const [validNumber, setValidNumber] = useState(false);
	const [showError, setShowError] = useState(true);
	const [touched, setTouched] = useState(false);
	const [numInitialized, setNumInitialized] = useState(false);
	const debouncedValue = useDebounce(value);

	useMemo(() => {
		if (!value) {
			setValidNumber(false);
			setNumInitialized(true);
			return;
		}
		if (Array.from(value)[0] !== "+") {
			value = `+${value}`;
		}
		setValidNumber(checkPhone(value));
	}, []);

	useEffect(() => {
		if (numInitialized) {
			if (!debouncedValue) {
				return;
			}
			setValidNumber(
				checkPhone(
					debouncedValue.length && Array.from(debouncedValue)[0] !== "+"
						? `+${debouncedValue}`
						: debouncedValue,
				),
			);
			setShowError(true);
		} else {
			setNumInitialized(true);
		}
	}, [debouncedValue]);

	useEffect(() => {}, [validNumber]);

	if (value?.length && Array.from(value)[0] !== "+") {
		value = `+${value}`;
	}

	return (
		<div className={`phone-input-container ${className}`}>
			<p className="mb-xxsm text-xxsm font-normal text-primary-500">{label}</p>
			<div className="flex min-h-[40px] w-full rounded-md border border-primary-150 bg-white px-4 py-2 transition">
				<PhoneInput
					name={name}
					autoComplete="off"
					disabled={disabled}
					className="input w-full rounded-md border-none outline-none"
					countrySelectProps={{
						arrowComponent: () => {
							return createElement(Icon, {
								className: "h-6 text-primary-700P ml-xsm",
								icon:
									document.activeElement ===
									document.querySelector(".PhoneInputCountrySelect")
										? arrowUpOutline
										: arrowDownOutline,
							});
						},
					}}
					defaultCountry="FR"
					initialValueFormat="national"
					onChange={(currentNumber) => {
						if (currentNumber?.length && Array.from(currentNumber)[0] !== "+") {
							currentNumber = `+${currentNumber}` as E164Number;
						}
						value = currentNumber;
						setShowError(false);
						if (!touched) {
							setTouched(true);
						}
						if (onChange) onChange(currentNumber);
					}}
					onBlur={() => {
						const isValid = checkPhone(value ? value.toString() : "");
						setValidNumber(isValid);
						if (onBlur) {
							onBlur(value);
						}
					}}
					value={value}
					placeholder="Ex : 06 12 34 56 78"
				/>
			</div>
			{value &&
				!validNumber &&
				value.length > 0 &&
				showError &&
				errorCondition && (
					<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
				)}
			{(!value || value.length === 0) && required && touched && (
				<p className="text-p pt-xxsm text-error-dark">
					Veuillez entrer un numéro de téléphone
				</p>
			)}
		</div>
	);
}
