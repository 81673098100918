import type { OrganizationType } from "@/types/organizationTypes";
import React from "react";

import { briefcaseOutline, peopleOutline, pinOutline } from "@assets/Icons";

import { Badge } from "@components/atoms/Badge";

export function CompanyTagsIcon(company: OrganizationType) {
	return (
		<div className={"flex flex-wrap items-start justify-start text-xs"}>
			{company.businessSector && (
				<Badge
					label={company.businessSector}
					className="pointer-events-none m-xsm"
					leftIcon={briefcaseOutline}
				/>
			)}
			{company.locations?.[0] && (
				<Badge
					label={`${company.locations[0]} ${
						company.locations.length > 1
							? ` et ${company.locations.length - 1} autre(s)`
							: ""
					}`}
					className="pointer-events-none m-xsm"
					leftIcon={pinOutline}
				/>
			)}
			{company.size && (
				<Badge
					label={company.size}
					className="pointer-events-none m-xsm"
					leftIcon={peopleOutline}
				/>
			)}
		</div>
	);
}
