import React from "react";

import HeroJobboard from "@assets/illustrations/heroJobboard.svg";

import { SearchBar } from "./Head/SearchBar";

export function Head() {
	return (
		<section className="col-span-4 flex flex-col items-center md:col-span-8 lg:col-span-12">
			<div
				id="hero"
				className="relative flex aspect-video !w-full items-center justify-center overflow-hidden rounded-lg object-cover md:aspect-10/1"
			>
				<img
					src={HeroJobboard}
					alt="Jobboard illustration"
					className="h-full w-full object-cover"
				/>
				<p className="absolute text-center text-lg text-white">
					Trouve l'offre qui te correspond
				</p>
			</div>
			<SearchBar />
		</section>
	);
}
