import React from "react";

import { InputSecondary } from "@components/molecules/InputSecondary";

import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "../../core/store/events.slice";

export function EditReplayLinkModal() {
	const dispatchEvent = useAppDispatch();
	const replayLinkToAdd = useAppSelector(
		(state) => state.events.replayLinkToAdd,
	);
	return (
		<div className="flex h-full flex-col items-start justify-start gap-4 px-6">
			<h3 className="mt-8 !text-sm !font-semibold text-primary-700P">
				Coller le lien du replay. Il sera ensuite accessible à vos étudiants.
			</h3>
			<InputSecondary
				label=""
				name="test"
				value={replayLinkToAdd}
				onChange={(e) => {
					dispatchEvent(eventsSlice.actions.setReplayLinkToAdd(e.target.value));
				}}
				placeholder="https://www.youtube.com"
				icon={null as any}
				className="w-full"
			/>
		</div>
	);
}
