import React, { useState } from "react";

import { EStatus } from "@/types/userTypes";
import { UsersFilters } from "@components/organisms/usersFilters";
// UNUSED PAGE
export function CompanyCVBoard() {
	const [filters, setFilters] = useState<any>({
		page: 1,
		spsPromotion_archived: false,
		state: "all",
		status_list: [EStatus.TOPLACE],
		birthdate: null,
	});

	return (
		<div>
			<UsersFilters
				enabledFilters={[
					"activity",
					"promotions",
					"tags",
					"location",
					"birthdate",
				]}
				filtersProps={{
					filters,
					setFilters,
				}}
			/>
			<div>cc</div>
		</div>
	);
}
