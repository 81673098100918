export const ALLOWED_TAGS_FOR_MESSAGE_VIEW = [
	"ul",
	"ol",
	"li",
	"strong",
	"u",
	"em",
	"br",
	"a",
	"p",
];
