import { Icon, type IconifyIcon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import "@assets/sass/Card.scss";

import { checkmarkCircle2Fill, checkmarkCircle2Outline } from "@assets/Icons";

type CardTertiaryPropsType = {
	icon?: IconifyIcon;
	iconColor?: string;
	title: string;
	onSelect?: (selected: boolean) => void;
	className?: string;
	selected?: boolean;
	disabled?: boolean;
};

export function CardTertiary({
	icon,
	iconColor = "text-primary-700P",
	title,
	onSelect = () => {},
	className = "",
	selected = false,
	disabled = false,
}: CardTertiaryPropsType) {
	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		setIsSelected(selected);
	}, [selected]);

	return (
		<div
			role="menu"
			onClick={() => {
				if (!disabled) {
					onSelect(!isSelected);
					setIsSelected(!isSelected);
				}
			}}
			className={`${className} card-tertiary flex flex-col items-center justify-center ${
				isSelected && "selected"
			} ${disabled && "disabled"}`}
		>
			<Icon className="check" icon={checkmarkCircle2Outline} />
			<Icon className="checked" icon={checkmarkCircle2Fill} />
			{icon && (
				<div className={`icon-container ${iconColor}`}>
					<Icon icon={icon} height={24} width={24} />
				</div>
			)}
			<p className="mt-xsm flex text-center text-xxsm text-primary-700P">
				{title}
			</p>
		</div>
	);
}
