import { Icon } from "@iconify/react";
import React from "react";

import { bulbOutline } from "@assets/Icons";

export function EventCreationHelp() {
	return (
		<article
			data-testid="eventHelpSection"
			className="flex w-full flex-col rounded-lg bg-white px-6 py-4"
		>
			<div
				className="flex gap-2 text-sm font-bold text-primary-700P"
				data-testid="eventHelpSectionTitle"
			>
				<Icon icon={bulbOutline} className="h-6 w-6 text-secondary-500P" />
				<span>Conseils</span>
			</div>
			<div className="my-6 flex flex-col justify-start gap-6 text-xxsm font-normal text-primary-700P">
				<p>Le Titre doit être le plus explicite possible.</p>
				<p>
					La description de l&apos;évènement est très{" "}
					<strong>importante</strong> et doit permettre à l'apprenant de
					comprendre clairement l'intérêt d&apos;assister au live ou à
					l&apos;évènement.
				</p>
				<p>
					Commencez votre phrase par :{" "}
					<em>"Au cours de ce live ou évènement, nous aborderons ..."</em> et
					finissez par :{" "}
					<em>
						"A l'issue de ce live ou évènement, vous serez en capacité de ..."
					</em>
				</p>
			</div>
			<div className="font-blod mb-6 text-xxsm text-primary-700P">
				<strong>Il faut donner :</strong>{" "}
				<ul className="list-disc pl-[10px]">
					<li>
						Le pré-requis pour suivre le Live (ressources / modules / thèmes à
						avoir vus / préparation d'un support en pièce jointe)
					</li>
					<li>Le thème, la compétence, les questions qui vont être vus</li>
					<li> Les objectifs pédagogiques de la séance du live</li>
					<li>
						La méthode / le déroulement (si exercice à faire en amont, durant le
						live, si ce sera un exposé, si phase de questions / réponses...).
					</li>
				</ul>
			</div>
			<p>
				Finissez par une phrase attractive pour donner envie de s'inscrire et
				vous rencontrez.
			</p>
		</article>
	);
}
