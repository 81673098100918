import type { MessageType } from "@/types/mailbox";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React, { useContext, useEffect, useRef } from "react";

import { checkmarkCircle2Fill } from "@assets/Icons";

import Message from "../Message";

type ChatContentType = {
	loadConversation: boolean;
};
export default function ChatContent({ loadConversation }: ChatContentType) {
	const chatContentRef = useRef<HTMLDivElement>(null);
	const { conversation, messages } = useAppSelector((state) => state.mailBox);
	const { currentInterface } = useContext(GlobalContext);

	useEffect(() => {
		setTimeout(() => {
			if (chatContentRef.current) {
				chatContentRef.current?.scrollTo({
					top: chatContentRef.current?.scrollHeight,
					behavior: "smooth",
				});
			}
		}, 100);
	}, [messages]);
	return (
		<div ref={chatContentRef} id="chat" className="overflow-auto h-full mb-xsm">
			{!loadConversation && (
				<>
					{messages?.length === 0 && (
						<div className="flex items-center justify-center my-lg">
							Aucun nouveau message
						</div>
					)}
					{messages?.map((message: MessageType, i: number) => (
						<Message
							key={message.id}
							className="item"
							message={message}
							prevMessage={messages[i - 1]}
						/>
					))}

					{currentInterface === "school" &&
						conversation?.status === "resolved" && (
							<div className="w-full flex flex-col items-center mb-md">
								{conversation?.updatedAt && messages?.length > 0 && (
									<div className="text-xxxsm font-semibold uppercase text-primary-200 mb-sm w-fit">
										{format(new Date(conversation?.updatedAt), "d MMMM yyyy", {
											locale: fr,
										})}
									</div>
								)}

								<div className="py-xsm px-sm bg-accent-4-lighter w-fit flex items-center justify-center gap-xsm rounded-full">
									<p className="text-xxsm font-bold">
										La conversation est résolue
									</p>
									<Icon
										icon={checkmarkCircle2Fill}
										className="text-accent-4"
										width={20}
										height={20}
									/>
								</div>
							</div>
						)}
				</>
			)}
		</div>
	);
}
