// Images
import React from "react";

import line from "@assets/illustrations/errorLine.svg";
import surprisedMan from "@assets/illustrations/surprisedMan.svg";
export default function NotFoundPublic() {
	return (
		<main className="bg-gradient-to-br from-primary-700P to-primary-500 text-white">
			<section className="overflow-hidden">
				<div className="z-10 col-span-5 mt-[48px]">
					<h1 className="mb-[8px] text-[62px] font-extrabold">404</h1>
					<h2 className="mb-[16px] text-[40px] font-bold">Page introuvable</h2>
					<p className="mb-[48px] text-[16px] font-semibold">
						La sélection à laquelle vous essayez d'accéder n'existe pas.
						Vérifiez l'URL ou assurez-vous que la sélection n'a pas été
						supprimée.
					</p>
				</div>
				<div className="col-span-6 mb-[100px] flex justify-end">
					<img className="z-10" src={surprisedMan} alt="surprisedMan" />
				</div>
				<div className="absolute bottom-[-70px]">
					<img src={line} alt="line" />
				</div>
			</section>
		</main>
	);
}
