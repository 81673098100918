import { Icon } from "@iconify/react";
import React from "react";

type WhyProps = {
	text: string;
	setWhyExpanded: (value: boolean) => void;
	whyExpanded: boolean;
};

export const Why = ({ text, setWhyExpanded, whyExpanded }: WhyProps) => {
	return (
		<>
			<div
				className="flex gap-1 flex-row items-center cursor-pointer w-fit"
				onClick={() => setWhyExpanded(!whyExpanded)}
			>
				<span className="border-b border-b-gray-600">
					Pourquoi ces questions ?
				</span>
				<div className="hover:bg-gray-200 rounded-3xl p-2">
					<Icon
						icon={
							whyExpanded
								? "eva:arrow-ios-upward-fill"
								: "eva:arrow-ios-downward-fill"
						}
						className="text-sm"
					/>
				</div>
			</div>
			<div
				className={`gap-4 bg-blue-50 overflow-hidden p-4 ${
					whyExpanded ? "flex" : "hidden"
				} `}
			>
				<p>{text}</p>
			</div>
		</>
	);
};
