import React from "react";
export const Separator = ({ date }: { date: string }) => {
	return (
		<div className="flex flex-row justify-center items-center gap-4">
			<div className="flex-1 border-b border-[1.5px] border-gray-300" />
			<span className="text-sm text-gray-500 font-semibold">{date}</span>
			<div className="flex-1 border-b border-[1.5px] border-gray-300" />
		</div>
	);
};
