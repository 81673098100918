import React, { useEffect } from "react";

import "../ResourceDetail.scss";

import { convertDateFromNow } from "@tools/Dates";

import { Avatar } from "@components/atoms/Avatar";

export function Information({ resource }) {
	useEffect(() => {}, [resource]);

	return (
		<div className="information-ressources-container ml-md mt-md">
			<p className="title">Informations</p>
			{resource?.author && (
				<div className="mt-md flex items-center">
					<Avatar
						className="shadow-sm"
						firstname={resource?.author?.firstname}
						lastname={resource?.author?.lastname}
						image={resource?.author?.avatar?.filePath}
					/>
					<div className="ml-xsm">
						<p className="text-xxxsm text-primary-300">
							{`Publié ${convertDateFromNow(resource?.createdAt)} par :`}
						</p>
						<p className="wg-no-translate mt-xxsm text-xxxsm font-bold text-primary-300">
							{resource?.author.firstname} {resource?.author.lastname}
						</p>
					</div>
				</div>
			)}
		</div>
	);
}
