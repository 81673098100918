import { Icon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { infoOutline } from "@assets/Icons";

import { getRandomItem } from "@tools/Arrays";

import "react-loading-skeleton/dist/skeleton.css";

export function ColumnsViewLoader({ title, description, className }) {
	const array = [];
	array.length = Math.floor(Math.random() * 5) + 1;
	array.fill("");

	function getRandomArbitrary(min, max) {
		return Math.random() * (max - min) + min;
	}

	const randomColor = useMemo(() => {
		getRandomItem(["#bfe2de", "#fad1b0", "#f3b7a8", "#bfe1b2"]);
	}, []);

	return (
		<div className="md:w-full">
			<div className={`border-b-2 ${className} pb-xsm`}>
				<div className="flex items-start gap-xxsm">
					<p className="truncate text-sm font-semibold">{title}</p>
					<Tippy
						content={description}
						theme="primary"
						animation="fade"
						placement="top"
						zIndex={5}
					>
						<span>
							<Icon icon={infoOutline} className="text-primary-300" />
						</span>
					</Tippy>
				</div>
				<Skeleton height={16} width={getRandomArbitrary(50, 150)} />
			</div>
			<div className="w-64 overflow-y-scroll px-xsm pt-md scrollbar-hide md:w-full">
				{array.map(() => {
					return (
						<div className="relative mb-sm h-[112px] cursor-pointer rounded-lg bg-white p-sm shadow-xsm">
							{getRandomItem([1, 2, 3]) === 1 && (
								<div className="absolute right-3 top-[-10px]">
									<Skeleton
										height={20}
										width={getRandomArbitrary(50, 100)}
										className=" rounded-full"
										baseColor={randomColor}
									/>
								</div>
							)}
							<Skeleton height={16} width={getRandomArbitrary(50, 150)} />
							<Skeleton
								height={16}
								width={getRandomArbitrary(50, 100)}
								className="mt-xsm"
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
