import axios from "axios";

import { getCookie } from "@tools/Cookies";
import { manageErrors } from "./Errors";

const TIMEOUT_MAX_LIMIT_TIME = 120000;

export default axios.create({
	baseURL: `${import.meta.env.VITE_BACK}/${getCookie("interface")}/`,
	timeout: TIMEOUT_MAX_LIMIT_TIME,
	headers: {
		Authorization: `Bearer ${getCookie("token")}`,
		"X-Forwarded-For": getCookie("ip"),
	},
});

export async function findCities(name: string) {
	try {
		if (!name) return [];
		if (name.length < 3) return [];
		const response = await axios.get(
			`https://api-adresse.data.gouv.fr/search/?q=${name}&type=municipality&autocomplete=1&limit=4`,
		);
		if (response.data.features.length > 0) {
			const featuresFound = response.data.features;
			return featuresFound;
		}
	} catch (error: any) {
		throw new Error(
			`Une erreur est survenue lors de la requete avec l'api gouv.fr => ${error}`,
		);
	}
}

export type StandardGraphQLResponse = {
	responseLabel: string;
	message: string;
	data?: any;
};

export type StandardRestResponse = {
	code: number;
	message: string;
};

export const checkGraphQLError = (
	response: any,
	successMessage: string,
): StandardGraphQLResponse => {
	const parseData = JSON.parse(JSON.stringify(response));
	if (parseData?.data?.errors?.[0]?.message) {
		return {
			responseLabel: "error",
			message: parseData?.data?.errors[0]?.message,
			data: {},
		};
	}
	return {
		responseLabel: "success",
		message: successMessage,
		data: parseData?.data?.data || {},
	};
};

export const abortControllers: Map<string, AbortController | null> = new Map();

export async function graphqlRequestConstructor(
	data: Object,
	id: string,
	customErrorMessage?: string,
	useAbortController = true,
) {
	let isAborted = false;
	try {
		const newController = new AbortController();
		if (useAbortController) {
			if (abortControllers.has(id)) {
				const controller = abortControllers.get(id);
				if (controller) {
					controller.abort();
				}
				abortControllers.set(id, null);
				isAborted = true;
			}

			abortControllers.set(id, newController);
		}
		const response = await axios
			.create({
				baseURL: `${import.meta.env.VITE_BACK}/${getCookie("interface")}/`,
				timeout: TIMEOUT_MAX_LIMIT_TIME,
				headers: {
					Authorization: `Bearer ${getCookie("token")}`,
					"X-Forwarded-For": getCookie("ip"),
				},
			})
			.post("graphql", data, {
				signal: newController.signal,
			});
		return checkGraphQLError(response, "request successful");
	} catch (error) {
		return {
			responseLabel: "error",
			message:
				useAbortController && (isAborted || error.code === "ERR_CANCELED")
					? "aborted"
					: customErrorMessage || error,
			data: {},
		};
	}
}

export async function getAuthenticatedUser(token: string) {
	let rep = {};
	await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token ?? getCookie("token")}`,
				"X-Forwarded-For": getCookie("ip"),
			},
		})
		.get(`/${getCookie("interface")}/get_authenticated_user`)
		.then(({ data }) => {
			rep = data;
		})
		.catch((error) => manageErrors(error));
	return rep;
}
export const extractError = (e: unknown): Error => {
	if (e instanceof Error) {
		return e;
	}
	return new Error("Erreur inconnue");
};
