import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function DashboardLoader() {
	const arrayStatsLayout = [null, "#e9f5f4", "#fdf0e4"];

	const arraySecondStatsLayout = ["#e9f5e4", "#fdf0e4", null, "#fbe6e1"];
	const arrayThreeStatsLayout = [null, "#fdf0e4", "#fbe6e1", "#e9f5e4"];

	const arrayDoughnutStatsLayout = [
		{ color: "#e9f5f4", legendColors: [null, "#e9f5e4", "#fdf0e4", "#fbe6e1"] },
		{ color: "#e9f5f4", legendColors: ["#e9f5e4", null, "#fdf0e4", "#fbe6e1"] },
	];

	return (
		<div className="flex items-center justify-between">
			<div className="flex w-full flex-wrap">
				<div className="mb-md w-full">
					<Skeleton height={35} width={200} />
				</div>
				<p className="font-semifont-bold pb-sm text-xsm text-primary-700P">
					L'essentiel des étudiants en chiffres
				</p>
				<div className="flex w-full">
					{arrayStatsLayout.map((color) => (
						<div className="stats-secondary-container w-full">
							<div className=" flex items-center justify-end ">
								<Skeleton height={8} width={8} />
							</div>
							<div className="flex items-center">
								<Skeleton
									baseColor={color}
									className="mr-sm"
									circle
									height={30}
									width={30}
								/>
								<Skeleton baseColor={color} height={15} width={120} />
							</div>

							<Skeleton className="mt-xsm" height={45} width={36} />
						</div>
					))}
				</div>
				<div className="mb-xxlg mt-md flex w-full">
					{arraySecondStatsLayout.map((color) => (
						<div className="stats-secondary-container w-full">
							<div className=" flex items-center justify-end">
								<Skeleton height={8} width={8} />
							</div>
							<div className="flex items-center">
								<Skeleton
									className="mr-sm"
									baseColor={color}
									height={36}
									width={36}
									circle
								/>
								<Skeleton baseColor={color} height={12} width={72} />
							</div>
							<Skeleton className="mt-sm" height={48} width={36} />
						</div>
					))}
				</div>
				<p className="font-semifont-bold pb-sm text-xsm text-primary-700P ">
					L'essentiel des actions des étudiants en chiffres
				</p>
				<div className="mt-sm flex w-full">
					{arrayThreeStatsLayout.map((color) => (
						<div className="stats-secondary-container w-full">
							<div className=" flex items-center justify-end">
								<Skeleton height={8} width={8} />
							</div>
							<div className="flex items-center">
								<Skeleton
									className="mr-sm"
									baseColor={color}
									height={36}
									width={36}
									circle
								/>
								<Skeleton baseColor={color} height={12} width={72} />
							</div>
							<Skeleton className="mt-sm" height={48} width={36} />
						</div>
					))}
				</div>

				<div className="mt-sm flex w-full">
					{arrayDoughnutStatsLayout.map(({ color, legendColors }) => (
						<div className="stats-secondary-container w-full">
							<div className=" jutify-content-start mt-sm flex items-center">
								<Skeleton height={12} width={200} />
							</div>
							<div className="flex flex-col items-center">
								<div>
									<Skeleton
										circle
										className="mt-sm"
										baseColor={color}
										height={150}
										width={150}
									/>
								</div>

								<div className="mt-md flex w-6/12 flex-wrap items-center justify-between">
									{legendColors.map((color) => (
										<Skeleton baseColor={color} height={12} width={72} />
									))}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
