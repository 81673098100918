import $ from "jquery";
import React, { useEffect, useState } from "react";

import "@assets/sass/Stepper.scss";

import { StepperPrimary } from "./StepperItem";

type StepperPropsType = {
	steps: { label: string; days?: any }[];
	color?: number;
	className?: string;
	indexActive?: number;
	onClickOnStep?: ((step: number) => void) | boolean | null;
};

export function Stepper({
	steps,
	color = 1,
	className = "",
	indexActive = 0,
	onClickOnStep = null,
}: StepperPropsType) {
	const [activeStep, setActiveStep] = useState(indexActive);

	useEffect(() => {
		const targetElement = $(".stepper-container .stepper-item");

		targetElement.mouseenter(function () {
			const currentIndex = $(this).index();
			targetElement.each(function (i) {
				const children = $(this).children(".stepper-item-primary-container");
				if (
					!children.hasClass("active") &&
					!children.hasClass("passed") &&
					i < currentIndex
				) {
					$(this)
						.children(".stepper-item-primary-container")
						.addClass("skipped");
				}
			});
		});

		targetElement.mouseleave(() => {
			targetElement.each(function () {
				$(this)
					.children(".stepper-item-primary-container")
					.removeClass("skipped");
			});
		});
	}, [steps, activeStep]);

	useEffect(() => {
		setActiveStep(indexActive);
	}, [indexActive]);

	return (
		<div className={`stepper-container ${className}`}>
			{steps.map((step, stepIndex) => {
				return (
					<div
						key={stepIndex}
						className="stepper-item flex w-full"
						onClick={() => {
							if (onClickOnStep && typeof onClickOnStep === "function") {
								onClickOnStep(stepIndex);
								setActiveStep(stepIndex);
							}
						}}
					>
						<StepperPrimary
							className={`stepper-${stepIndex}`}
							label={step.label}
							color={color}
							stepIndex={stepIndex}
							displayArrow={indexActive + 1 === stepIndex}
							activeStep={activeStep}
							clickable={typeof onClickOnStep === "function"}
						/>
					</div>
				);
			})}
		</div>
	);
}
