import type { SchoolOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import { checkIfAlreadyApply } from "@tools/Applications";

export const useSchoolOfferCard = (offer: SchoolOfferType) => {
	const { userApplications } = useAppSelector(
		(state: AppState) => state.jobboard,
	);
	return {
		alreadyApply: checkIfAlreadyApply(userApplications, offer),
	};
};
