import type { UserType } from "@/types/userTypes";

declare global {
	interface Window {
		$crisp: {
			push: (array: [string, string, any[]]) => void;
			help: () => {};
			get: (value: string) => {};
		};
	}
}
const _parseJwt = (token: string) => {
	try {
		return JSON.parse(atob(token.split(".")[1]));
	} catch (_e) {
		return null;
	}
};

export function crispSettings(user?: UserType, userInterface?: string) {
	const sessionData = [];

	if (user) {
		window.$crisp.push([
			"set",
			"user:nickname",
			[`${user.firstname} ${user.lastname} `],
		]);
		window.$crisp.push(["set", "user:email", [user.email]]);
		window.$crisp.push(["set", "session:segments", [user.roles]]);
		window.$crisp.push([
			"set",
			"user:avatar",
			[`${import.meta.env.VITE_S3}${user?.avatar?.filePath}`],
		]);
		if (user.schoolName) {
			window.$crisp.push(["set", "user:company", [user.schoolName]]);
		} else if (user.entCompany?.name) {
			window.$crisp.push(["set", "user:company", [user.entCompany.name]]);
		}

		if (user?.roles) {
			sessionData.unshift(["roles", `${user?.roles}`]);
		}
		if (user.promotionName) {
			sessionData.unshift(["promotion", user?.promotionName]);
		}
		if (user.schoolName) {
			sessionData.unshift(["school", user?.schoolName]);
		}
		if (user.campusName) {
			sessionData.unshift(["campus", user?.campusName]);
		}
		if (userInterface) {
			sessionData.unshift(["user_interface", userInterface]);
		}
		if (sessionData) {
			window.$crisp.push(["set", "session:data", [sessionData]]);
		}
	}
}
