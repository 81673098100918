import type { ApplicationType } from "@/types/applicationTypes";
import React, { type ReactNode } from "react";
import { createContext, useContext, useState } from "react";

type AppContextType = {
	applicationData: ApplicationType;
	setApplicationData: (data: ApplicationType) => void;
};

const ApplicationsContext = createContext<AppContextType>({
	applicationData: {} as ApplicationType,
	setApplicationData: () => {},
});

export function AppplicationsWrapper({
	children,
}: {
	children: ReactNode;
}) {
	const [applicationData, setApplicationData] = useState<ApplicationType>(
		{} as ApplicationType,
	);

	return (
		<ApplicationsContext.Provider
			value={{
				applicationData,
				setApplicationData,
			}}
		>
			{children}
		</ApplicationsContext.Provider>
	);
}

export function useAppplicationsContext() {
	const context = useContext(ApplicationsContext);
	if (!context) {
		throw new Error("useAppplicationsContext must be used within a AppWrapper");
	}
	return context;
}
