import type { CityType } from "@/types/LocationTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { DISTANCE_OPTIONS } from "@constants/Offers";

import SelectLocation from "@components/organisms/select/SelectLocation";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

import { countCurrentFilters, setCurrentFilters } from "../usersFiltersSlice";

type SelectOptionType = {
	id?: number;
	label?: string;
	value: string | number | null;
};

const getMaxDistanceObjectFromFilters = (
	currentFilters: Partial<FiltersType>,
): SelectOptionType => {
	if (currentFilters.max_distance) {
		return {
			value: currentFilters.max_distance,
		};
	}
	return {
		value: null,
	};
};

export function LocationFilter() {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.usersFilters);

	const [defaultMaxDistanceOption, setDefaultMaxDistanceOption] =
		useState<SelectOptionType>(getMaxDistanceObjectFromFilters(currentFilters));
	const handleLocationChange = (cityReceived: CityType) => {
		if (!cityReceived) {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					location: undefined,
					position: undefined,
					max_distance: undefined,
				}),
			);
			return;
		}
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				location: cityReceived.location,
				position: [cityReceived.longitude, cityReceived.latitude],
				max_distance:
					cityReceived.location !== "" ? currentFilters.max_distance : 50,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const handleMaxDistanceChange = (option: SelectOptionType) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				max_distance: Number(option.value ?? 50),
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const getCityObjectFromFilters = (): CityType | null => {
		if (currentFilters.location && currentFilters.position) {
			return {
				location: currentFilters.location,
				latitude: currentFilters.position[1],
				longitude: currentFilters.position[0],
			};
		}
		return null;
	};

	useEffect(() => {
		setDefaultMaxDistanceOption(
			getMaxDistanceObjectFromFilters(currentFilters),
		);
	}, [currentFilters]);

	return (
		<div className="flex w-fit gap-1">
			<SelectLocation
				withLabel={false}
				handleNewLocation={(cityReceived: CityType) => {
					handleLocationChange(cityReceived);
				}}
				defaultValue={
					currentFilters.location ? getCityObjectFromFilters() : null
				}
			/>
			<SelectTertiary
				position="right"
				placeholder="Distance"
				options={DISTANCE_OPTIONS}
				defaultValue={defaultMaxDistanceOption}
				className="w-full max-w-[120px]"
				onChange={(option) => {
					handleMaxDistanceChange(option);
				}}
				disabled={!currentFilters.location || currentFilters.location === ""}
			/>
		</div>
	);
}
