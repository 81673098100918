import React, { type MouseEventHandler } from "react";

import "@assets/sass/Card.scss";

import { Avatar } from "@components/atoms/Avatar";

type CardSecondaryPropsType = {
	title: string;
	secondTitle?: string;
	subtitle?: string;
	body?: string | null;
	className?: string;
	onClick?: MouseEventHandler;
};

export function CardSecondary({
	title,
	secondTitle,
	subtitle = "",
	body = null,
	className = "",
	onClick = () => null,
}: CardSecondaryPropsType) {
	return (
		<div
			className={`${className} bg-primary-50 p-md rounded-lg cursor-pointer hover:shadow-sm transition-shadow`}
			onClick={onClick}
		>
			<div className="flex gap-sm">
				<Avatar firstname={title} lastname="" image="" />

				<div className="flex flex-col">
					<div className="flex gap-xsm">
						<p className={"text-primary-500 font-bold wg-no-translate"}>
							{title}
						</p>
						{secondTitle && (
							<p className="text-primary-200"> - {secondTitle}</p>
						)}
					</div>
					<p className={"text-primary-200"}>{subtitle}</p>
				</div>
			</div>
			<p className={"mt-sm break-all wg-no-translate"}>{body}</p>
		</div>
	);
}
