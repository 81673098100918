export const RESOURCE_TYPE_OPTIONS: {
	label: string;
	value: "cvModel" | "motivationLetterModel" | "resource";
}[] = [
	{ label: "Ressource", value: "resource" },
	{ label: "Modèle de CV", value: "cvModel" },
	{
		label: "Modèles de document de motivation",
		value: "motivationLetterModel",
	},
];
