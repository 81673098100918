import { InlineIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useContext } from "react";

import { infoOutline } from "@assets/Icons";

import { Switch } from "@components/atoms/Switch";

import { StudentsTargetingModalContext } from "..";

export function Notifications() {
	const {
		selectedStudents,
		sendEmail,
		setSendEmail,
		sendSMS,
		SMSCredits,
		setSendSMS,
	} = useContext(StudentsTargetingModalContext);

	return (
		<div className="flex flex-col gap-sm">
			<p className="font-bold text-primary-700P">Options</p>
			<div>
				<div className="flex flex-wrap items-center justify-start ">
					<Switch
						checked={sendEmail}
						onEnabled={() => {
							setSendEmail(true);
						}}
						onDisabled={() => {
							setSendEmail(false);
						}}
						className="mr-xsm"
					/>
					<p className="text-xxsm text-primary-700P">
						Notifier les étudiants sélectionnés par mail
					</p>
					<Tippy
						content="Si désactivé, vos étudiants ne seront pas avertis de la publication de votre offre."
						theme="primary"
						animation="fade"
						placement="right"
						zIndex={60}
					>
						<span className="ml-xxsm cursor-pointer">
							<InlineIcon
								className="text-primary-200"
								height={16}
								width={16}
								icon={infoOutline}
							/>
						</span>
					</Tippy>
				</div>
				<div className="flex flex-wrap items-center justify-start">
					<Switch
						checked={sendSMS}
						onEnabled={() => {
							setSendSMS(true);
						}}
						onDisabled={() => {
							setSendSMS(false);
						}}
						className="mr-xsm"
					/>
					<p className="text-xxsm text-primary-700P">
						Notifier les étudiants sélectionnés par SMS
					</p>
					<Tippy
						content="Si désactivé, vos étudiants ne seront pas avertis de la publication de votre offre."
						theme="primary"
						animation="fade"
						placement="right"
						zIndex={60}
					>
						<span className="ml-xxsm cursor-pointer">
							<InlineIcon
								className="text-primary-200"
								height={16}
								width={16}
								icon={infoOutline}
							/>
						</span>
					</Tippy>
				</div>
			</div>
			{sendSMS &&
				selectedStudents.filter((student) => student.phone).length >
					SMSCredits && (
					<p className="text-xxsm text-error-dark">
						Vous n'avez pas assez de crédit SMS, veuillez décocher cette option
						ou recharger vos crédits SMS
					</p>
				)}

			<div>
				<p className="flex text-xxxsm text-primary-500">
					SMS disponible{`${SMSCredits > 1 ? "(s)" : ""}`} : {SMSCredits}
					<Tippy
						content={
							<p>
								Les demandes de crédits SMS sont à adresser à{" "}
								<a
									className="font-bold underline"
									href="mailto:thomas@mentorgoal.com"
								>
									thomas@mentorgoal.com
								</a>
							</p>
						}
						theme="primary"
						placement="right"
						zIndex={60}
					>
						<span className="ml-xxsm cursor-pointer">
							<InlineIcon
								className="icon-sm text-primary-500"
								icon={infoOutline}
							/>
						</span>
					</Tippy>
				</p>
				{sendSMS && (
					<p className="text-xxxsm text-primary-500">
						{`Nombre d'étudiants qui seront notifié${
							selectedStudents.length -
								selectedStudents.filter((student) => !student.phone).length >
							1
								? "s"
								: ""
						}`}{" "}
						par SMS:{" "}
						{`${
							selectedStudents.length -
							selectedStudents.filter((student) => !student.phone).length
						}`}
					</p>
				)}
			</div>
		</div>
	);
}
