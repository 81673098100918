import React, { useEffect, useState } from "react";

import "@assets/sass/ItemMenu.scss";

import { Checkbox } from "@components/atoms/Checkbox";

let uniqueId = 0;

type ItemInteractionLargePropsType = {
	label: string;
	className?: string;
	onClick: (isChecked: boolean) => void;
	checked: boolean;
	breakSpaces?: boolean;
};

export function ItemInteractionLarge({
	label,
	className = "",
	onClick,
	checked,
	breakSpaces = false,
}: ItemInteractionLargePropsType) {
	const [componentId] = useState(() => uniqueId++);
	const [isChecked, setIsChecked] = useState(checked);

	useEffect(() => {
		setIsChecked(checked);
	}, [checked]);

	useEffect(() => {
		const element = document.getElementById(
			`interaction-item-large-${componentId}`,
		);
		if (element) {
			element.addEventListener("keyup", (event) => {
				if (event.keyCode === 13) {
					element.click();
				}
			});
		}
	}, [componentId]);

	return (
		<div
			data-id="itemInteractionLarge"
			id={`interaction-item-large-${componentId}`}
			role="menu"
			onClick={(e: any) => {
				e.preventDefault();
				if (onClick) onClick(!isChecked);
				setIsChecked(!isChecked);
			}}
			className={`${className} interaction-item-large-container w-full`}
		>
			<Checkbox className="mr-xsm" checked={isChecked} />
			<p
				className={`w-[250px] text-primary-700P ${
					breakSpaces ? "whitespace-break-spaces" : "truncate"
				}`}
			>
				{label}
			</p>
		</div>
	);
}
