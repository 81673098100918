import React from "react";
import Skeleton from "react-loading-skeleton";

export function HeaderSkeletons() {
	return (
		<div className="flex min-h-[72px] w-full items-center justify-start gap-4 rounded-lg bg-white pl-2">
			<Skeleton width={28} height={28} />
			<div className="flex items-center justify-start gap-2">
				<Skeleton width={48} height={48} className="rounded-full" />
				<Skeleton width={150} height={48} />
			</div>
			<Skeleton width={100} height={48} />
		</div>
	);
}
