import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function ResourceLoader() {
	const arrayInputLayout = [];
	arrayInputLayout.length = 3;
	arrayInputLayout.fill("");

	const arrayInputPart = [];
	arrayInputPart.length = 6;
	arrayInputPart.fill("");
	return (
		<div className="hidden w-full pt-5 md:flex flex-col">
			<div className="mt-header-offer mb-md flex items-center justify-between rounded-lg bg-white p-sm pl-sm pr-sm w-full">
				<Skeleton className="w-10/12" height={16} width={210} />
			</div>
			<div className="w-full flex gap-md">
				<div className="h-fit-content part w-8/12 bg-white px-md rounded-lg">
					{arrayInputLayout.map((i) => {
						return <Skeleton key={i} className="mt-sm w-full" height={28} />;
					})}
					<Skeleton className="mt-sm w-full" height={300} />
					<Skeleton className="mt-sm w-full" height={100} />
					<div className="mb-md ml-[66%] mt-xlg flex w-4/12 items-center justify-end">
						<Skeleton className="mr-sm mt-sm" height={35} />
						<Skeleton className="ml-sm mt-sm" height={35} width={100} />
					</div>
				</div>
				<div className="w-4/12 pr-0 bg-white px-md rounded-lg h-fit py-md">
					<Skeleton className="w-10/12" height={12} width={210} />
					<div className="mt-sm flex flex-col">
						<Skeleton className="label mb-md" height={8} width={100} />
						<Skeleton className="label mb-xxsm mt-sm" height={16} width={100} />
						<Skeleton className="mt-0 w-full" height={56} width={400} />
					</div>
				</div>
			</div>
		</div>
	);
}
