import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NotFoundIllustration from "@assets/illustrations/404.svg";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";

const REDIRECT_TIMEOUT = 5; // seconds
export function ErrorNotFound() {
	const [redirectTimeout, setRedirectTimeout] =
		useState<number>(REDIRECT_TIMEOUT);
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			navigate(`/?redirectTo=${window.location.pathname}`);
		}, REDIRECT_TIMEOUT * 1000);

		const interval = setInterval(() => {
			setRedirectTimeout((prev) => prev - 1);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<main className="errors">
			<div className="main-content flex flex-col items-center justify-center">
				<img
					alt="Un homme qui cherche la page avec vous..."
					className="mt-[103px] w-[140px] md:w-[280px]"
					src={NotFoundIllustration}
				/>
				<h2 className="mt-8 text-center text-3xl font-bold text-primary-900 md:mt-6">
					Page non trouvée
				</h2>
				<p className="mt-2 text-center text-xsm font-normal text-primary-300 md:mt-6">
					La page recherchée n&apos;existe plus ou a été déplacée.
				</p>
				<ButtonPrimary
					className="mt-8 md:mt-6"
					onClick={() => navigate(`/?redirectTo=${window.location.pathname}`)}
					label={`Redirection dans ${redirectTimeout}`}
				/>
			</div>
		</main>
	);
}
