import { useBreakpoints } from "@hooks/useBreakpoints";
import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { arrowIosDownwardFill, arrowIosUpwardOutline } from "@assets/Icons";
import borderLeft from "@assets/background/BorderLeftShape.svg";

import { NavigationBarContext } from "@components/organisms/NavigationBar/NavigationBar";

import { GlobalContext } from "./Router";
import type { NavigationItemType } from "./type";
export function NavigationItem({
	nav,
	onClick,
	showSubMenu,
}: NavigationItemType) {
	const { activeIcon, icon, link, label } = nav;
	const { setMenuActiveNavigation } = useContext(GlobalContext);
	const { currentLabelSubMenu, menuHover, setMenuHover } =
		useContext(NavigationBarContext);
	const { isMobile } = useBreakpoints();
	const [activeNav, setActiveNav] = useState<boolean>(false);

	const iconProps = () => {
		if (
			(location.pathname === "/" && nav.exact) ||
			(activeNav && location.pathname.split("/")[1] === nav.link?.split("/")[1])
		) {
			return { icon: activeIcon ?? "" };
		}

		return { icon: icon ?? "" };
	};
	const targetProps = (link: string) => {
		if (link?.includes("http")) return { target: "_blank", to: link };
		return { to: link };
	};

	useEffect(() => {
		if (nav.subNavigation) {
			const matchUrl = nav.subNavigation.find(
				(item) => item.link?.split("/")[1] === location.pathname.split("/")[1],
			);

			return setActiveNav(matchUrl !== undefined);
		}

		if (!nav.subNavigation) {
			return setActiveNav(
				location.pathname.split("/")[1] === nav.link?.split("/")[1],
			);
		}
	}, [menuHover]);

	useEffect(() => {
		const matchUrl = nav.subNavigation?.find(
			(item) => item.link?.split("/")[1] === location.pathname.split("/")[1],
		);

		if (matchUrl) {
			return setActiveNav(true);
		}

		if (currentLabelSubMenu !== nav.label && nav.subNavigation) {
			setActiveNav(false);
		}
	}, [currentLabelSubMenu]);

	const renderLink = () => {
		return (
			<>
				<Icon
					className={`min-h-[24px] min-w-[24px] duration-300 ease-in ${
						(location.pathname === "/" && nav.exact) ||
						(activeNav && !nav.link?.includes("http"))
							? "font-bold text-white"
							: "text-primary-150"
					}`}
					{...iconProps()}
				/>

				<div className="flex h-[24px] w-full items-center justify-between">
					<label className="block cursor-pointer whitespace-nowrap pl-xsm">
						{label}
					</label>
					{nav.subNavigation && (
						<Icon
							icon={showSubMenu ? arrowIosUpwardOutline : arrowIosDownwardFill}
							className={`m-0 min-h-[24px] w-[24px] overflow-hidden ${
								showSubMenu ? "font-bold text-white" : "text-primary-150"
							}`}
						/>
					)}
				</div>
			</>
		);
	};

	return (
		<>
			<div
				className={`${
					!nav.separator ? "mb-xsm" : ""
				} flex w-full cursor-pointer overflow-hidden`}
			>
				{((location.pathname === "/" && nav.exact) ||
					(location.pathname.split("/")[1] === nav.link?.split("/")[1] &&
						!nav.subNavigation &&
						!nav.link?.includes("http")) ||
					(activeNav &&
						nav.subNavigation &&
						!menuHover &&
						!nav.link?.includes("http"))) && (
					<img className="absolute left-0" src={borderLeft} alt="shape" />
				)}
				{!nav.subNavigation && link ? (
					<NavLink
						className={`flex w-full cursor-pointer items-center overflow-hidden p-xsm pr-0 text-xxsm ${
							(location.pathname === "/" && nav.exact) ||
							(activeNav && !nav.link?.includes("http"))
								? "font-bold text-white"
								: "text-primary-150"
						}`}
						onClick={(e: any) => {
							e.stopPropagation();
							if (onClick) onClick(label);
							if (nav.link?.includes("http")) return setActiveNav(false);
							if (isMobile) setMenuActiveNavigation(false);
							if (
								!activeNav &&
								location.pathname.split("/")[1] !== nav.link?.split("/")[1]
							) {
								setActiveNav(!activeNav);
							}

							setMenuHover(false);
						}}
						{...targetProps(link)}
					>
						{renderLink()}
					</NavLink>
				) : (
					<div
						className={`flex w-full cursor-pointer items-center overflow-hidden p-xsm pr-0 text-xxsm ${
							showSubMenu && !nav.link?.includes("http")
								? "font-bold text-white"
								: "text-primary-150"
						}`}
						onClick={() => {
							if (onClick) onClick(label);

							if (
								!activeNav &&
								location.pathname.split("/")[1] !== nav.link?.split("/")[1]
							) {
								setActiveNav(!activeNav);
							}
						}}
					>
						{renderLink()}
					</div>
				)}
			</div>
			{nav.separator && !nav.subNavigation && (
				<hr className="my-xsm h-[1px] w-full border-none bg-primary-200" />
			)}
		</>
	);
}
