import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { getAllOfferContractOptions } from "@tools/Offers";

import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import { countCurrentFilters, setCurrentFilters } from "../offersFiltersSlice";

export function ContractFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);

	const handleChange = (contractSelected: string) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				contract: contractSelected,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<SelectSecondary
			position="right"
			defaultValue={
				getAllOfferContractOptions()?.find(
					(contract) => contract.value === currentFilters?.contract,
				) || { value: "", label: "Contrat" }
			}
			options={getAllOfferContractOptions()}
			onChange={(e) => {
				handleChange(e.value);
			}}
		/>
	);
}
