import React from "react";
import Skeleton from "react-loading-skeleton";

export const FormLoader = () => {
	return (
		<div className="w-full">
			<div className="flex flex-col">
				<div className="flex flex-col gap-4 p-8">
					<h2 className="text-2xl font-bold text-black">
						<Skeleton width={200} />
					</h2>
					<div className={"flex flex-col gap-2 group"}>
						<Skeleton height={20} width={100} />
						<Skeleton height={35} />
					</div>
					<div className={"flex flex-col gap-2 group"}>
						<Skeleton height={20} width={100} />
						<Skeleton height={35} />
					</div>
					<div className={"flex flex-col gap-2 group"}>
						<Skeleton height={20} width={100} />
						<Skeleton height={35} />
					</div>
				</div>
				<div
					className={`border-t border-gray-200 p-4 px-6 w-full flex
           justify-end
        `}
				>
					<Skeleton width={100} height={30} />
				</div>
			</div>
		</div>
	);
};
