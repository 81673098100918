import { Field as FieldFormik } from "formik";
import React, { type ChangeEvent } from "react";
import Dropzone from "react-dropzone";

import RadioGroup from "../../../../components/atoms/RadioGroup";
import { InputPhoneNumber } from "../../../../components/molecules/PhoneInput";
import { uploadFile } from "../RegisterFormAPI";
import type { Field } from "../index";

type FieldBuilderProps = {
	field: Field;
	setFormValues: (formValues: object) => void;
	formValues: object;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	handleBlur: (event: ChangeEvent<HTMLInputElement>) => void;
	setFieldValue: (field: string, value: string) => void;
	values: object;
	touched: object;
	errors: object;
	registrationToken: string;
};

export const FieldBuilder = ({
	field,
	setFormValues,
	formValues,
	handleChange,
	handleBlur,
	setFieldValue,
	values,
	touched,
	errors,
	registrationToken,
}: FieldBuilderProps) => {
	const getInputElement = () => {
		switch (field.type) {
			case "checkbox":
				return (
					<div className={"flex gap-4"}>
						<div>
							<FieldFormik
								type={field.type}
								name={field.name}
								id={field.name}
								className={"border border-gray-200 p-2 rounded-sm"}
							/>
						</div>
						<label htmlFor={field.name}>{field.label}</label>
					</div>
				);
			case "select":
				return (
					<div className="relative">
						<FieldFormik
							component="select"
							className={
								"border border-gray-200 p-2 rounded-sm focus:outline-none focus:border-indigo-600 hover:border-indigo-600 focus:ring-1 focus:ring-indigo-600 ease-in-out duration-100 w-full"
							}
							value={values[field.name as keyof typeof values] || ""}
							name={field.name}
							id={field.name}
							required={field.isRequired}
						>
							{field.subFields?.map((subField) => (
								<option value={subField.value} key={`select-${subField.value}`}>
									{subField.label}
								</option>
							)) || []}
						</FieldFormik>
					</div>
				);
			case "radio":
				return (
					<RadioGroup
						elements={
							field.subFields?.map((subField) => ({
								...subField,
								className: "cursor-pointer",
							})) || []
						}
						handleOnChange={(value) => {
							setFormValues({
								...formValues,
								[field.name]: value,
							});
						}}
						storedValue={formValues[field.name as keyof typeof formValues]}
						className="flex w-full items-start justify-start gap-2"
						direction="vertical"
					/>
				);
			case "phone":
				return (
					<InputPhoneNumber
						name={field.name}
						value={values[field.name as keyof typeof values] || ""}
						onChange={(value) => {
							setFieldValue(field.name, value as string);
						}}
						onBlur={(value) => {
							setFieldValue(field.name, value as string);
						}}
						required={field.isRequired}
						label={""}
					/>
				);
			case "email":
			case "text":
				return (
					<input
						type={field.type}
						name={field.name}
						id={field.name}
						placeholder={field.label}
						onChange={handleChange}
						onBlur={handleBlur}
						value={values[field.name as keyof typeof values] || ""}
						className={
							"border border-gray-200 p-2 rounded-sm focus:outline-none focus:border-indigo-600 hover:border-indigo-600 focus:ring-1 focus:ring-indigo-600 ease-in-out duration-100"
						}
						required={field.isRequired}
					/>
				);
			case "file":
				return (
					<Dropzone
						onDrop={(acceptedFiles) => {
							uploadFile(acceptedFiles[0], field.name, registrationToken);
							setFieldValue(field.name, acceptedFiles[0].name);
						}}
						maxFiles={1}
						accept={{
							"application/pdf": [".pdf"],
						}}
					>
						{({ getRootProps, getInputProps, isDragActive }) => (
							<div
								className={`bg-gray-100 p-8 flex flex-col items-center rounded-xl cursor-pointer border-2 border-gray-100 ${
									isDragActive ? "border-dashed border-gray-500" : ""
								}`}
								{...getRootProps()}
							>
								<input {...getInputProps()} required={field.isRequired} />
								<p className={"text-center text-gray-500 text-sm"}>
									{values[field.name as keyof typeof values] ||
										"Déposez le fichier ici ou cliquez ici"}
								</p>
							</div>
						)}
					</Dropzone>
				);

			default:
				return (
					<div className={"text-red-600 font-bold"}>Type de champ inconu</div>
				);
		}
	};
	return (
		<div className={"flex flex-col gap-2 group"}>
			{field.type !== "checkbox" && (
				<label
					htmlFor={field.name}
					className={`text-black group-focus-within:text-indigo-600 ${
						field.isRequired &&
						"after:content-['*'] after:text-red-600 after:ml-0.5"
					}`}
				>
					{field.label}
				</label>
			)}
			{field.help && <p className={"text-gray-500 text-xxsm"}>{field.help}</p>}
			{getInputElement()}
			{errors[field.name as keyof typeof errors] &&
				touched[field.name as keyof typeof touched] && (
					<p className="text-p pt-xxsm text-error-dark">
						{errors[field.name as keyof typeof errors]}
					</p>
				)}
		</div>
	);
};
