import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { bulbOutline } from "@assets/Icons";

import { getCookie } from "@tools/Cookies";
import { stringifyRequestFilters } from "@tools/Objects";

import InformationBanner from "@components/templates/informationBanner/InformationBanner";
import { Onboarding } from "@components/templates/onboarding/Onboarding";

import {
	getApplicationsColumnView,
	getApplicationsList,
} from "@containers/student/Applications/ApplicationsManagement/ApplicationsManagementAPI";
import { HeadBar } from "@containers/student/Applications/ApplicationsManagement/ui/HeadBar";

import { EOnboarding } from "@constants/Others";
import { Applications } from "./ui/Applications";
import { AppplicationsWrapper } from "./ui/Applications/context";

export function ApplicationsManagement() {
	const { user } = useContext(GlobalContext);
	const [load, setLoad] = useState(true);
	const [view, setView] = useState(
		getCookie("view") ? getCookie("view") : "columns",
	);
	const [lastPage, setLastPage] = useState(1);
	const { setTitle } = useOutletContext();
	const [filters, setFilters] = useState({
		spsStudent: user.id,
		favorite: null,
		archived: false,
		search: "",
	});
	const [applications, setApplications] = useState({
		readyToApply: [],
		sent: [],
		relaunch: [],
		interview: [],
	});
	const [applicationsListView, setApplicationsListView] = useState([]);
	const [displayOnBoarding, setDisplayOnBoarding] = useState(false);

	const getApplications = async (
		cols = ["readyToApply", "sent", "relaunch", "interview"],
	) => {
		if (getCookie("view") === "column" || filters.search) {
			filters.page = undefined;
		}

		const stringifyFilters = stringifyRequestFilters(filters);
		setLoad(true);

		if (getCookie("view") === "list") {
			const { collection, paginationInfo } =
				await getApplicationsList(stringifyFilters);
			setApplicationsListView(collection);
			setLastPage(paginationInfo.lastPage);
		} else {
			const { readyToApply, sent, relaunch, interview } =
				await getApplicationsColumnView(stringifyFilters, cols);
			setApplications({
				readyToApply: readyToApply?.collection ?? [],
				sent: sent?.collection ?? [],
				relaunch: relaunch?.collection ?? [],
				interview: interview?.collection ?? [],
			});
		}
		setLoad(false);
	};

	useEffect(() => {
		if (load) {
			setTitle("Mes candidatures");
			setDisplayOnBoarding(!user.onboardingSteps.split("")[1]);
		}
	}, [view]);

	useEffect(() => {
		getApplications();
	}, [filters]);

	useEffect(() => {
		window.history.replaceState({}, document.title);
	}, [applications]);

	useEffect(() => {
		if (user?.onboardingSteps) {
			const hasSeenOnboarding =
				user.onboardingSteps?.split("")[EOnboarding.APPLICATIONS];
			if (!hasSeenOnboarding) return;
			setDisplayOnBoarding(Number.parseInt(hasSeenOnboarding) === 0);
		}
	}, []);

	return (
		<AppplicationsWrapper>
			<main className={"overflow-y-hidden"}>
				<InformationBanner
					icon={bulbOutline}
					className="mb-lg mt-sm hidden w-full md:mt-0 md:flex"
					body={
						<div>
							<p>
								Ton tableau de suivi des candidatures te permet de gérer ta
								recherche d'emploi : enregistrer des offres pour postuler plus
								tard, indiquer quand tu as postulé, quand tu as relancé et quand
								tu as obtenu un entretien.
							</p>
						</div>
					}
				/>
				<HeadBar
					setView={setView}
					setLoad={setLoad}
					load={load}
					getApplications={getApplications}
					filtersProps={{ filters, setFilters }}
					applications={applications}
				/>
				<Applications
					applicationsProps={{
						applications,
						setApplications,
						applicationsListView,
						setApplicationsListView,
					}}
					view={view}
					load={load}
					filtersProps={{ filters, setFilters }}
					getApplications={getApplications}
					lastPage={lastPage}
				/>
				<Onboarding
					currentPath={EOnboarding.APPLICATIONS}
					show={displayOnBoarding}
					onClose={() => {
						setDisplayOnBoarding(false);
					}}
				/>
			</main>
		</AppplicationsWrapper>
	);
}
