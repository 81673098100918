import type { OfferType } from "@/types/offerTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";

import { DEFAULT_MAX_DISTANCE } from "@constants/Location";

import { combineExists } from "@containers/school/CVBoard/CVBoardAPI";
import api, { graphqlRequestConstructor } from "@tools/API";
import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";

export async function getSchoolCredits(school: string) {
	let rep = {};

	const data = {
		query: `query {
        school (id: "${school}") {
            smsCredits
        }
      }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.school;
	});
	return rep;
}

export async function retrieveStudents(
	filters: Partial<FiltersType>,
	view: object,
) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const allStudentsFilters = { ...filters };
	if (allStudentsFilters.location) {
		allStudentsFilters.location = undefined;
	}
	allStudentsFilters.state = undefined;

	if (filters.position && !filters.max_distance) {
		allStudentsFilters.max_distance = DEFAULT_MAX_DISTANCE;
	}

	if (filters.birthdate) {
		const cleanBirthdate = {
			...filters?.birthdate?.value,
		};
		if (cleanBirthdate) {
			allStudentsFilters.birthdate = cleanBirthdate;
		}
	}
	if (birthdateExists) delete allStudentsFilters.birthdate;
	delete allStudentsFilters?.cvExists;
	const filtersCpy = stringifyRequestFilters({
		...view,
		...allStudentsFilters,
	});

	const data = {
		query: `query {
      visibleStudents : collectionQuerySpsStudents (${combineExists(filters)}, ${filtersCpy}, spsPromotion_type: "student", order: [{lastname: "ASC"}]) {
        collection {
          id
          avatar {
            filePath
          }
          firstname
          lastname
          spsPromotion {
            name
          }
          phone
        },
        paginationInfo {
          totalCount
        }
      },
    }`,
	};
	return await graphqlRequestConstructor(data, "retrieveStudents");
}

export async function getAllStudents(
	filters: Partial<FiltersType>,
	view: object,
) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const allStudentsFilters = { ...filters };
	if (allStudentsFilters.location) {
		allStudentsFilters.location = undefined;
	}
	allStudentsFilters.state = undefined;

	if (filters.position && !filters.max_distance) {
		allStudentsFilters.max_distance = DEFAULT_MAX_DISTANCE;
	}

	if (filters.birthdate) {
		const cleanBirthdate = {
			...filters?.birthdate?.value,
		};
		if (cleanBirthdate) {
			allStudentsFilters.birthdate = cleanBirthdate;
		}
	}
	if (birthdateExists) delete allStudentsFilters.birthdate;
	delete allStudentsFilters?.cvExists;
	const filtersCpy = stringifyRequestFilters({
		...view,
		...allStudentsFilters,
	});

	const data = {
		query: `query {
      allStudents : collectionQuerySpsStudents (${combineExists(filters)}, ${filtersCpy}, spsPromotion_type: "student", order: [{lastname: "ASC"}], itemsPerPage: 10000) {
        collection {
          id
          phone
        },
      },
    }`,
	};
	return await graphqlRequestConstructor(data, "getAllStudents");
}

export async function scrollStudents(filters: FiltersType, view: object) {
	let rep = {};
	const allStudentsFilters = { ...filters };
	if (allStudentsFilters.location) delete allStudentsFilters.location;
	if (filters.position && !filters.max_distance) {
		allStudentsFilters.max_distance = DEFAULT_MAX_DISTANCE;
	}
	delete allStudentsFilters.state;

	if (filters.birthdate) {
		allStudentsFilters.birthdate = {
			...filters?.birthdate?.value,
		};
	}
	delete allStudentsFilters?.cvExists;
	const filtersCpy = stringifyRequestFilters({
		...view,
		...allStudentsFilters,
	});
	const data = {
		query: `query {
          visibleStudents : collectionQuerySpsStudents (${combineExists(filters)},${filtersCpy}, spsPromotion_type: "student", order: [{lastname: "ASC"}]) {
            collection {
              id
              avatar {
                filePath
              }
              firstname
              lastname
              spsPromotion  {
                name
              }
              phone
            },
          },
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function viewSelection(ids: string[]) {
	let rep = {};

	const data = {
		query: `query {
          visibleStudents : collectionQuerySpsStudents (id_list:[${ids.map(
						(id) => `"${id}"`,
					)}], page: 1, itemsPerPage: 1000) {
            collection {
              id
              avatar {
                filePath
              }
              firstname
              lastname
              spsPromotion  {
                name
              }
              phone
            },
          },
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function editOffer(inputs: OfferType) {
	let rep = {};

	const data = {
		query: `
      mutation{
        updateOffer(input: ${stringifyRequestInputs(inputs)}){
          offer{
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
