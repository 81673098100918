import React, { forwardRef, type RefObject } from "react";

type YearPopUpPropsType = {
	show?: boolean;
	handleOnChangeYear: (year: number) => void;
	selectedYear?: number;
};

const MAX_FUTURE_YEARS = 5;
const MAX_PAST_YEARS = 30;

export const YearPopUp = forwardRef(
	(props: YearPopUpPropsType, ref: RefObject<HTMLDivElement>) => {
		const currentYear = new Date().getFullYear();
		const {
			show = true,
			handleOnChangeYear,
			selectedYear = currentYear,
		} = props;

		if (!show) {
			return <></>;
		}

		return (
			<div ref={ref} className="year-picker">
				{Array.from(Array(MAX_PAST_YEARS).keys()).map((_, index) => (
					<div
						onClick={() =>
							handleOnChangeYear(currentYear - MAX_PAST_YEARS + index)
						}
						className={`year-pick ${
							selectedYear === currentYear - MAX_PAST_YEARS + index
								? "selected"
								: ""
						}`}
						key={index}
					>
						<p>{currentYear - MAX_PAST_YEARS + index}</p>
					</div>
				))}

				{Array.from(Array(MAX_FUTURE_YEARS).keys()).map((_, index) => (
					<div
						onClick={() => handleOnChangeYear(currentYear + index)}
						className={`year-pick ${
							selectedYear === currentYear + index ? "selected" : ""
						}`}
						key={index}
					>
						<p>{currentYear + index}</p>
					</div>
				))}
			</div>
		);
	},
);
