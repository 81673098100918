import type {
	ApplicationCompleteType,
	ApplicationThumbType,
} from "@/types/applicationTypes";
import type { CampusType } from "@/types/campusTypes";
import type { LogType } from "@/types/logTypes";
import type {
	OfferCellNameType,
	OffersCounterType,
	OffersStatusType,
	SchoolOfferType,
} from "@/types/offerTypes";
import type { ReferentType } from "@/types/userTypes";
import { createSlice } from "@reduxjs/toolkit";

import {
	DEFAULT_OFFERS_TABLE_CONFIG,
	DEFAULT_OFFER_TO_CREATE,
	STATUS_ALLOWED_FOR_TAB,
} from "@constants/Offers";

import { getCookie, setCookie } from "@tools/Cookies";
import {
	filterOffersByStatus,
	removeDuplicateReferents,
	sortReferentsByLastName,
} from "@tools/Offers";

import { ErrorToast } from "@components/organisms/toast/ToastNotification";

import {
	SETTINGS_COOKIE_NAME,
	fetchCampuses,
	fetchCreateOffer,
	fetchLogs,
	fetchModifyOffer,
	fetchModifyOfferCampus,
	fetchOfferDetails,
	fetchOffersApplication,
	fetchOffersApplicationsThumbnails,
	fetchOffersCounters,
	fetchOffersList,
	fetchReferents,
} from "./offersManagement.thunks";

export type OfferManagementLoadingStatesType = {
	isFetchingOffersList: boolean;
	isFetchingOffersCounters: boolean;
	isFetchingApplicationsThumbnail: boolean;
	isFetchingApplication: boolean;
	isFetchingOfferDetails: boolean;
	isCreatingOffer: boolean;
	isFetchingCampuses: boolean;
	isFetchingReferents: boolean;
	isFetchingLogs: boolean;
};

type OffersManagementState = {
	currentSearch: string;
	loadingStates: OfferManagementLoadingStatesType;
	fetchInBackGround: boolean;
	offersList: SchoolOfferType[];
	filteredOffersList: SchoolOfferType[];
	applicationsThumbnails: ApplicationThumbType[];
	currentTabSelected: OffersStatusType;
	userTableConfig?: OfferCellNameType[];
	currentFilters?: any;
	pageShown: number;
	lastPage: number;
	itemsPerPage: number;
	counters: OffersCounterType[];
	offerToCreate: Partial<SchoolOfferType>;
	offerToEdit: SchoolOfferType | undefined;
	currentDetailTabSelected?: string;
	closeOfferStatus: Object;
	order: string;
	isInitialized: boolean;
	applicationSelectedId?: string;
	applicationSelectedData?: ApplicationCompleteType;
	sendRelaunchInvitationWasClicked?: boolean;
	offerToEditDataWasModified?: boolean;
	campusesListReceived?: CampusType[];
	referentsListReceived?: {
		campusReferents: ReferentType[];
		schoolReferents: ReferentType[];
	};
	offerStatusWasUpdated: boolean;
	sendProfilWasClicked: boolean;
	logsReceived?: LogType[];
	userWasInEditionPage?: boolean;
};

const getSettingsCookie = () => {
	const cookie = getCookie(SETTINGS_COOKIE_NAME);
	if (cookie) {
		return JSON.parse(cookie) as OfferCellNameType[];
	}
	return DEFAULT_OFFERS_TABLE_CONFIG;
};

const initialState: OffersManagementState = {
	currentSearch: "",
	loadingStates: {
		isFetchingOffersList: true,
		isFetchingOffersCounters: true,
		isFetchingApplicationsThumbnail: true,
		isFetchingApplication: true,
		isFetchingOfferDetails: false,
		isCreatingOffer: false,
		isFetchingCampuses: false,
		isFetchingReferents: false,
		isFetchingLogs: false,
	},
	fetchInBackGround: false,
	offersList: [],
	applicationsThumbnails: [],
	filteredOffersList: [],
	currentTabSelected: "online",
	userTableConfig: getSettingsCookie(),
	pageShown: 1,
	lastPage: 1,
	itemsPerPage: 10,
	counters: [],
	offerToCreate: DEFAULT_OFFER_TO_CREATE as Partial<SchoolOfferType>,
	offerToEdit: undefined,
	currentDetailTabSelected: "offer",
	closeOfferStatus: {},
	order: `order: [{ date: "DESC" }]`,
	isInitialized: false,
	offerStatusWasUpdated: false,
	sendProfilWasClicked: false,
	userWasInEditionPage: true,
};

const offersManagementSlice = createSlice({
	name: "offersManagement",
	initialState,
	reducers: {
		setCurrentSearch(state, action) {
			state.offersList = [];
			state.pageShown = initialState.pageShown;
			state.currentSearch = action.payload;
		},
		setCurrentTabSelected(state, action) {
			if (action.payload && action.payload !== state.currentTabSelected) {
				state.pageShown = initialState.pageShown;
				state.currentTabSelected = action.payload;
				state.filteredOffersList = [...state.offersList];
				let copyOfFilteredList = [...state.filteredOffersList];
				const allowedStatus: OffersStatusType[] = STATUS_ALLOWED_FOR_TAB.get(
					state.currentTabSelected,
				) as OffersStatusType[];
				copyOfFilteredList = filterOffersByStatus(
					copyOfFilteredList,
					allowedStatus,
				);
				state.filteredOffersList = copyOfFilteredList;
			}
		},
		setCurrentDetailTabSelected(state, action) {
			state.applicationsThumbnails = [];
			state.applicationSelectedData = undefined;
			state.currentDetailTabSelected = action.payload;
		},
		setSendRelaunchInvitationWasClicked(state, action) {
			state.sendRelaunchInvitationWasClicked = action.payload;
		},
		setOfferToEditDataWasModified(state, action) {
			state.offerToEditDataWasModified = action.payload;
		},
		setUserTableConfig(state, action) {
			state.userTableConfig = action.payload;
			setCookie(SETTINGS_COOKIE_NAME, JSON.stringify(action.payload));
		},
		setCurrentFilters(state, action) {
			state.currentFilters = action.payload;
		},
		setFetchInBackGround(state, action) {
			state.fetchInBackGround = action.payload;
		},
		setCurrentPage(state, action) {
			state.pageShown = action.payload;
		},
		setItemsPerPage(state, action) {
			state.itemsPerPage = action.payload;
		},
		setOfferToCreate(state, action) {
			state.offerToCreate = action.payload;
		},
		setSendProfilWasClicked(state, action) {
			state.sendProfilWasClicked = action.payload;
		},
		modifyOfferToCreate(state, action) {
			state.offerToCreate = {
				...state.offerToCreate,
				...action.payload,
			};
		},
		modifyOfferToEdit(state, action) {
			state.offerToEdit = {
				...state.offerToEdit,
				...action.payload,
			};
		},
		setOfferToEdit(state, action) {
			state.offerToEdit = action.payload;
		},
		clearDataFetched(state) {
			state.campusesListReceived = undefined;
			state.referentsListReceived = undefined;
		},
		resyncTargetOffer(state, action) {
			const filteredOffersListCopy = [...state.filteredOffersList];
			const index = filteredOffersListCopy.findIndex(
				(offer) => offer.id === action.payload.id,
			);
			if (index !== -1) {
				filteredOffersListCopy[index] = action.payload;
			}
			state.filteredOffersList = filteredOffersListCopy;
		},
		clearOfferToEdit(state) {
			state.offerToEdit = undefined;
		},
		selectOfferToEditFromId(state, action) {
			let newOfferToEdit: any = {};
			newOfferToEdit = state.filteredOffersList.find(
				(offer) => offer.id === `${action.payload}`,
			);
			state.offerToEdit = newOfferToEdit;
		},
		selectNextOfferToEdit(state) {
			const index = state.filteredOffersList.findIndex(
				(offer) => offer.id === state.offerToEdit?.id,
			);
			if (index !== -1 && index < state.filteredOffersList.length - 1) {
				state.offerToEdit = state.filteredOffersList[index + 1];
			} else {
				state.offerToEdit = state.filteredOffersList[0];
			}
			state.offerToEditDataWasModified = true;
		},
		selectPreviousOfferToEdit(state) {
			const index = state.filteredOffersList.findIndex(
				(offer) => offer.id === state.offerToEdit?.id,
			);
			if (index !== -1 && index > 0) {
				state.offerToEdit = state.filteredOffersList[index - 1];
			} else {
				state.offerToEdit =
					state.filteredOffersList[state.filteredOffersList.length - 1];
			}
			state.offerToEditDataWasModified = true;
		},
		setOrder(state, action) {
			state.order = action.payload;
		},
		setSelectedApplicationId(state, action) {
			state.applicationSelectedId = action.payload;
		},
		setOfferStatusWasUpdated(state, action) {
			state.offerStatusWasUpdated = action.payload;
		},
		updateReferentList(state, action) {
			state.referentsListReceived = action.payload;
		},
		setUserWasInEditionPage(state, action) {
			state.userWasInEditionPage = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchOffersList.pending, (state) => {
			if (state.offersList.length === 0 || state.fetchInBackGround === false) {
				state.loadingStates.isFetchingOffersList = true;
			}
		});
		builder.addCase(fetchOffersList.fulfilled, (state, action) => {
			state.offersList = action.payload.collection;
			state.filteredOffersList = action.payload.collection;
			state.lastPage = action.payload.paginationInfo.lastPage;
			let copyOfFilteredList = [...state.filteredOffersList];
			const allowedStatus: OffersStatusType[] = STATUS_ALLOWED_FOR_TAB.get(
				state.currentTabSelected,
			) as OffersStatusType[];
			copyOfFilteredList = filterOffersByStatus(
				copyOfFilteredList,
				allowedStatus,
			);
			state.filteredOffersList = copyOfFilteredList;
			state.loadingStates.isFetchingOffersList = false;
			if (!state.isInitialized) {
				state.isInitialized = true;
			}
		});
		builder.addCase(fetchOffersList.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				ErrorToast(
					"Une erreur est survenue lors de la récupération des données.",
				);
				state.loadingStates.isFetchingOffersList = false;
				if (!state.isInitialized) {
					state.isInitialized = true;
				}
			}
		});
		builder.addCase(fetchOfferDetails.pending, (state) => {
			state.loadingStates.isFetchingOfferDetails = true;
		});
		builder.addCase(fetchOfferDetails.fulfilled, (state, action) => {
			state.offerToEdit = action.payload.data.offer;
			if (action.payload.needToCopyToCreate) {
				state.offerToCreate = action.payload.data.offer;
			}
			state.loadingStates.isFetchingOfferDetails = false;
		});
		builder.addCase(fetchOfferDetails.rejected, (state) => {
			state.loadingStates.isFetchingOfferDetails = false;
		});
		builder.addCase(fetchOffersCounters.pending, (state) => {
			state.loadingStates.isFetchingOffersCounters = true;
		});
		builder.addCase(fetchOffersCounters.rejected, (state) => {
			state.loadingStates.isFetchingOffersCounters = false;
		});
		builder.addCase(fetchOffersCounters.fulfilled, (state, action) => {
			const newCounters: OffersCounterType[] = [];
			const onlineCounter: OffersCounterType = {
				offerType: "online",
				count: action.payload.onlineCounter.paginationInfo.totalCount,
			};
			const draftCounter: OffersCounterType = {
				offerType: "draft",
				count: action.payload.draftCounter.paginationInfo.totalCount,
			};
			const providedCounter: OffersCounterType = {
				offerType: "provided",
				count: action.payload.providedCounter.paginationInfo.totalCount,
			};
			const notProvidedCounter: OffersCounterType = {
				offerType: "notProvided",
				count: action.payload.notProvidedCounter.paginationInfo.totalCount,
			};
			const expiredCounter: OffersCounterType = {
				offerType: "expired",
				count: action.payload.expiredCounter.paginationInfo.totalCount,
			};
			newCounters.push(draftCounter);
			newCounters.push(providedCounter);
			newCounters.push(notProvidedCounter);
			newCounters.push(expiredCounter);
			newCounters.push(onlineCounter);
			state.counters = newCounters;
			state.userWasInEditionPage = false;
			state.loadingStates.isFetchingOffersCounters = false;
		});
		builder.addCase(fetchModifyOffer.pending, (state) => {
			state.loadingStates.isFetchingOffersCounters = true;
		});
		builder.addCase(fetchModifyOffer.fulfilled, (state) => {
			state.loadingStates.isFetchingOffersCounters = false;
		});
		builder.addCase(fetchModifyOffer.rejected, (state) => {
			state.loadingStates.isFetchingOffersList = false;
		});
		builder.addCase(fetchOffersApplicationsThumbnails.pending, (state) => {
			state.applicationSelectedData = undefined;
			state.applicationsThumbnails = [];
			state.loadingStates.isFetchingApplicationsThumbnail = true;
		});
		builder.addCase(
			fetchOffersApplicationsThumbnails.fulfilled,
			(state, action) => {
				const applications = action.payload?.applications?.collection;
				state.applicationsThumbnails = applications;
				let appliationsList = applications;
				if (state.currentDetailTabSelected !== "applications") {
					appliationsList = applications?.filter(
						(thumbnail: ApplicationThumbType) => {
							return thumbnail?.state === "accepted";
						},
					);
				}
				state.applicationSelectedId = appliationsList[0]?.id || null;
				state.applicationSelectedData = appliationsList[0];
				// update the applicationCount
				if (state.offerToEdit) {
					state.offerToEdit.applicationsCount =
						action.payload?.applicationsCount;
					state.offerToEdit.applicationsToTreat =
						action.payload?.applicationsToTreat;
					state.offerToEdit.applicationsToSend =
						action.payload?.applicationsToSend;
				}

				state.loadingStates.isFetchingApplicationsThumbnail = false;
			},
		);
		builder.addCase(
			fetchOffersApplicationsThumbnails.rejected,
			(state, action) => {
				if (action.error.message !== "aborted") {
					ErrorToast(
						"Une erreur est survenue lors de la récupération des miniatures.",
					);
					state.loadingStates.isFetchingApplicationsThumbnail = false;
				}
			},
		);
		builder.addCase(fetchOffersApplication.pending, (state) => {
			state.loadingStates.isFetchingApplication = true;
		});
		builder.addCase(fetchOffersApplication.fulfilled, (state, action) => {
			state.loadingStates.isFetchingApplication = false;
			state.applicationSelectedData = action.payload.application;
		});
		builder.addCase(fetchOffersApplication.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				ErrorToast(
					"Une erreur est survenue lors de la récupération de la candidature.",
				);
				state.loadingStates.isFetchingApplication = false;
			}
		});
		builder.addCase(fetchModifyOfferCampus.fulfilled, (state) => {
			state.offerStatusWasUpdated = true;
		});
		builder.addCase(fetchModifyOfferCampus.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				ErrorToast(
					"Une erreur est survenue lors de la modification du campus.",
				);
				state.loadingStates.isFetchingOffersList = false;
			}
		});
		builder.addCase(fetchCreateOffer.pending, (state) => {
			state.loadingStates.isCreatingOffer = true;
		});
		builder.addCase(fetchCreateOffer.rejected, (state) => {
			state.loadingStates.isCreatingOffer = false;
		});
		builder.addCase(fetchCreateOffer.fulfilled, (state) => {
			state.offersList = [];
			state.offerToCreate = DEFAULT_OFFER_TO_CREATE;
			state.filteredOffersList = [];
			state.pageShown = 1;
			state.loadingStates.isCreatingOffer = false;
		});
		builder.addCase(fetchCampuses.pending, (state) => {
			state.campusesListReceived = undefined;
			state.loadingStates.isFetchingCampuses = true;
		});
		builder.addCase(fetchCampuses.rejected, (state) => {
			state.loadingStates.isFetchingCampuses = false;
		});
		builder.addCase(fetchCampuses.fulfilled, (state, action) => {
			state.campusesListReceived = action.payload;
			state.loadingStates.isFetchingCampuses = false;
		});
		builder.addCase(fetchReferents.pending, (state) => {
			state.referentsListReceived = undefined;
			state.loadingStates.isFetchingReferents = true;
		});
		builder.addCase(fetchReferents.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				ErrorToast(
					"Une erreur est survenue lors de la récupération des référents.",
				);
				state.loadingStates.isFetchingReferents = false;
			}
		});
		builder.addCase(fetchReferents.fulfilled, (state, action) => {
			const newReferentsList = {
				campusReferents: sortReferentsByLastName(
					removeDuplicateReferents(action.payload.campusReferents.collection),
				),
				schoolReferents: sortReferentsByLastName(
					removeDuplicateReferents(action.payload.schoolReferents.collection),
				),
			};
			state.referentsListReceived = newReferentsList;
			state.loadingStates.isFetchingReferents = false;
		});
		builder.addCase(fetchLogs.pending, (state) => {
			state.loadingStates.isFetchingLogs = true;
		});
		builder.addCase(fetchLogs.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				ErrorToast("Une erreur est survenue lors de la récupération des logs.");
				state.loadingStates.isFetchingLogs = false;
			}
		});
		builder.addCase(fetchLogs.fulfilled, (state, action) => {
			state.logsReceived = action.payload.offer.logs.collection;
			state.loadingStates.isFetchingLogs = false;
		});
	},
});

export const {
	setCurrentSearch,
	setCurrentTabSelected,
	setCurrentDetailTabSelected,
	setSendRelaunchInvitationWasClicked,
	setUserTableConfig,
	setCurrentFilters,
	setFetchInBackGround,
	setCurrentPage,
	setItemsPerPage,
	setOfferToCreate,
	modifyOfferToCreate,
	clearDataFetched,
	setOfferToEdit,
	setSendProfilWasClicked,
	resyncTargetOffer,
	modifyOfferToEdit,
	clearOfferToEdit,
	selectOfferToEditFromId,
	selectNextOfferToEdit,
	selectPreviousOfferToEdit,
	setOrder,
	setSelectedApplicationId,
	setOfferStatusWasUpdated,
	setOfferToEditDataWasModified,
	updateReferentList,
	setUserWasInEditionPage,
} = offersManagementSlice.actions;

export default offersManagementSlice.reducer;
