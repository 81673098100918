import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import type { EvaluationType, InterviewDataType } from "@/types/interviewTypes";
import RadioGroup, {
	type RadioElementType,
} from "@components/atoms/RadioGroup";
import { TextArea } from "@components/atoms/TextArea";

enum EvaluationEnum {
	VERY_BAD = "Très mal",
	BAD = "Mal",
	GOOD = "Bien",
	VERY_GOOD = "Très bien",
	EXCELLENT = "Excellent !",
}
export function PostInterview({
	interviewInfo,
	interviewLoaded,
}: InterviewDataType) {
	let { explanationEvaluation, interviewEvaluation } = interviewInfo;

	const [evaluations, setEvaluations] = useState<EvaluationType[]>([
		{
			label: EvaluationEnum.VERY_BAD,
			value: 0,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.BAD,
			value: 1,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.GOOD,
			value: 2,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.VERY_GOOD,
			value: 3,
			defaultValue: false,
		},
		{
			label: EvaluationEnum.EXCELLENT,
			value: 4,
			defaultValue: false,
		},
	]);
	const [interviewEvaluationValue, setInterviewEvaluationValue] =
		useState<number>(0);

	useEffect(() => {
		const copyEvaluation = [...evaluations];

		if (interviewEvaluation) {
			copyEvaluation[interviewEvaluation].defaultValue =
				!evaluations[interviewEvaluation].defaultValue;
		}
		setEvaluations(copyEvaluation);
	}, [interviewInfo]);

	useEffect(() => {
		if (!interviewLoaded) return;
		if (!interviewInfo) return;
		if (interviewLoaded) {
			explanationEvaluation = interviewInfo.explanationEvaluation;
			setInterviewEvaluationValue(interviewInfo.interviewEvaluation);
		}
	}, [interviewInfo, interviewLoaded]);

	return (
		<div className="w-full pl-sm pr-sm">
			<RadioGroup
				className="mb-md"
				elements={evaluations as Object[] as RadioElementType[]}
				handleOnChange={(value: number) => {
					return value;
				}}
				storedValue={interviewEvaluationValue}
				direction="horizontal"
			/>
			<p className="font-semifont-bold mb-xxsm text-primary-500">Pourquoi ?</p>
			<TextArea
				disabled
				className="mb-md"
				value={explanationEvaluation}
				placeholder="Qu'est ce qui vous a marqué ?"
			/>
		</div>
	);
}
PostInterview.propTypes = {
	application: PropTypes.shape({
		id: PropTypes.string,
		explanationEvaluation: PropTypes.string,
		interviewEvaluation: PropTypes.string,
	}).isRequired,
};
