import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./Contacts.scss";

import { stringifyRequestFilters } from "@tools/Objects";
import { resolveView } from "@tools/Users";

import {
	getContactsManagementData,
	getContactsTableView,
} from "@containers/school/Contacts/ContactsAPI";

import { TableView } from "./ui/ContactsList/TableView";
import { Head } from "./ui/Head";
export function Contacts() {
	const [lastPage, setLastPage] = useState(1);
	const { structureView, setTitle } = useOutletContext();
	const [contacts, setContacts] = useState([]);
	const [contactIdsList, setContactIdsList] = useState([]);
	const [filters, setFilters] = useState({
		page: 1,
		order: [{ lastname: "ASC" }],
	});
	const [load, setLoad] = useState(true);

	const updateContactsList = async () => {
		setLoad(true);
		getContactsTableView(
			resolveView(structureView, "spsStudent_spsPromotion"),
			filters,
		).then(({ contacts, contactIdsList }) => {
			setContacts(contacts?.collection);
			setLoad(false);
			setLastPage(contacts?.paginationInfo.lastPage);
			setContactIdsList(
				contactIdsList.collection?.map((contact) => {
					return Number(contact.id.split("/")[3]);
				}),
			);
		});
	};

	const getData = async () => {
		await getContactsManagementData(
			stringifyRequestFilters({
				...resolveView(structureView, "spsStudent_spsPromotion"),
			}),
		).then(({ contacts, contactIdsList }) => {
			setContacts(contacts.collection);
			setLastPage(contacts.paginationInfo.lastPage);

			setContactIdsList(
				contactIdsList.collection.map((contact) => {
					return Number(contact.id.split("/")[3]);
				}),
			);
			setLoad(false);
		});
	};

	useEffect(() => {
		setTitle("Contacts");
		if (load) {
			getData();
		} else {
			updateContactsList();
		}
	}, [filters, structureView]);

	return (
		<main className="gap-4 sm:gap-6 xxl:gap-8">
			<Head
				filtersProps={{ filters, setFilters }}
				contactIdsList={contactIdsList}
				load={load}
			/>
			<TableView
				load={load}
				contacts={contacts}
				lastPage={lastPage}
				filtersProps={{ filters, setFilters }}
			/>
		</main>
	);
}
