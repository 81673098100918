import { Icon } from "@iconify/react";
import React from "react";

import "@assets/sass/Files.scss";

import {
	checkmarkCircle2Fill,
	closeCircleFill,
	cloudDownloadOutline,
	eyeOutline,
	fileTextOutline,
	refreshFill,
	trash2Outline,
} from "@assets/Icons";

import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { Spinner } from "@components/atoms/Spinner";
import TooltipContainer from "@components/atoms/TooltipContainer";
type FilesPrimaryType = {
	className?: string;
	title: string;
	subtitle?: string;
	isLoading?: boolean;
	image?: string;
	error?: boolean;
	progressValue?: number;
	onDelete?: Function;
	onDownload?: Function;
	onRefresh?: Function;
	onlyDownload?: boolean;
	onlyView?: boolean;
	onView?: Function;
	onValidated?: boolean;
	validated?: boolean;
};
export function FilesPrimary({
	className,
	title,
	subtitle,
	image,
	error = false,
	isLoading = false,
	progressValue,
	onDelete,
	onDownload,
	onRefresh,
	onlyDownload,
	onlyView,
	onView,
	onValidated = false,
	validated,
}: FilesPrimaryType) {
	if (!error && progressValue && progressValue > 0 && progressValue < 100) {
		return (
			<div
				data-id="files"
				className={`${className} files-container ${error && "error"}`}
			>
				{!isLoading ? (
					<div className="files-left w-full">
						<div className="icon-files flex items-center justify-center rounded-md bg-primary-100 p-xsm">
							<Icon
								icon={fileTextOutline}
								className={`h-6  ${
									error ? "text-error-dark" : "text-primary-500"
								}`}
							/>
						</div>
						<div className="files-center ml-sm">
							<div className="flex items-center justify-between">
								<p className="title text-primary-700P">{title}</p>
								<p className="progress-value text-primary-200">
									{progressValue}%
								</p>
							</div>
							<div className="container-progress-bar mt-xxsm">
								<div className="progress-bar">
									<span
										className="progress-bar-fill"
										style={{ width: `${progressValue}%` }}
									/>
								</div>
							</div>
						</div>
					</div>
				) : (
					<Spinner />
				)}
			</div>
		);
	}
	return (
		<div className={`${className} files-container ${error && "error"}`}>
			{!isLoading ? (
				<>
					<div className="files-left w-9/12">
						<div className="icon-files flex w-3/12 items-center justify-center rounded-md bg-primary-100 p-xsm">
							{image ? (
								<div className="image-container">
									<img src={image} alt="illustration" />
								</div>
							) : (
								<Icon
									icon={fileTextOutline}
									className={`h-6  ${
										error ? "text-error-dark" : "text-primary-500"
									}`}
								/>
							)}
						</div>
						<div className="ml-sm mr-sm w-9/12 overflow-hidden text-ellipsis">
							<div className="flex w-fit max-w-full">
								{" "}
								<p
									className={`title !w-none flex max-w-full truncate !whitespace-break-spaces ${
										error ? "text-error-dark" : "text-primary-700P"
									}`}
								>
									{title}
									{onValidated && (
										<Icon
											className={`ml-xsm text-2xl ${
												validated ? "text-success-dark" : "text-error-dark"
											}`}
											icon={validated ? checkmarkCircle2Fill : closeCircleFill}
										/>
									)}
								</p>
							</div>
							{subtitle && (
								<p className="subtitle-p text-primary-200">{subtitle}</p>
							)}
						</div>
					</div>
					<div className="flex w-3/12 justify-end">
						{!onlyDownload && !error && (
							<span id="deletefile-tooltip-from-filesprimay">
								{onlyView ? (
									<ButtonTertiary
										onClick={() => {
											onView?.();
										}}
										size="small"
										icon={eyeOutline}
									/>
								) : (
									onDelete && (
										<ButtonTertiary
											size="small"
											icon={trash2Outline}
											iconColor="text-error-dark"
											onClick={() => {
												onDelete();
											}}
										/>
									)
								)}
								<TooltipContainer
									anchorId="#deletefile-tooltip-from-filesprimay"
									children={onlyView ? "Voir" : "Supprimer"}
									place="top"
								/>
							</span>
						)}
						{onView && (
							<div className="ml-sm">
								<span id="showFile-tooltip-from-filesprimay">
									<ButtonTertiary
										onClick={() => {
											onView();
										}}
										size="small"
										icon={eyeOutline}
									/>
									<TooltipContainer
										anchorId="#showFile-tooltip-from-filesprimay"
										children="Voir"
										place="top"
									/>
								</span>
							</div>
						)}
						{onDownload && (
							<div className="ml-sm">
								<span id="retry-tooltip-from-files-primary">
									<ButtonTertiary
										icon={error ? refreshFill : cloudDownloadOutline}
										size="small"
										onClick={(event) => {
											if (error) {
												onRefresh?.();
											} else {
												onDownload(event);
											}
										}}
									/>
									<TooltipContainer
										anchorId="#retry-tooltip-from-files-primary"
										children={error ? "Réessayer" : "Télécharger"}
										place="top"
									/>
								</span>
							</div>
						)}
					</div>
				</>
			) : (
				<div className="flex h-full w-full items-center justify-center">
					<Spinner />
				</div>
			)}
		</div>
	);
}
