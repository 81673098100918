import React from "react";
import Skeleton from "react-loading-skeleton";

function EventsSkeleton() {
	return (
		<div className="flex w-full flex-wrap items-start justify-start gap-6">
			<div className="hidden w-full md:block xl:hidden xxl:hidden">
				<Skeleton
					className="md:max-w-5/12 sm:max-w-1/3 flex w-full max-w-full gap-1 rounded-lg 
        p-6 pb-2 sm:min-h-[780px] md:max-w-[48%] xl:max-w-[32.3%] xxl:max-w-[24%]"
					containerClassName="flex w-full flex-wrap items-start justify-start gap-2"
					width={"48%"}
					height={780}
					count={6}
					enableAnimation={true}
					highlightColor="#f3f3f3"
				/>
			</div>
			<div className="hidden w-full md:hidden xl:block xxl:hidden">
				<Skeleton
					className="md:max-w-5/12 sm:max-w-1/3 flex w-full max-w-full gap-1 rounded-lg 
        p-6 pb-2 sm:min-h-[780px] md:max-w-[48%] xl:max-w-[32.3%] xxl:max-w-[24%]"
					containerClassName="flex w-full flex-wrap items-start justify-start gap-2"
					width={"32%"}
					height={780}
					count={6}
					enableAnimation={true}
				/>
			</div>
			<div className="hidden w-full md:hidden xl:hidden xxl:block">
				<Skeleton
					className="md:max-w-5/12 sm:max-w-1/3 flex w-full max-w-full gap-1 rounded-lg
        p-6 pb-2 sm:min-h-[780px] md:max-w-[48%] xl:max-w-[32.3%] xxl:max-w-[24%]"
					containerClassName="flex w-full flex-wrap items-start justify-start gap-2"
					width={"24%"}
					height={780}
					count={6}
					enableAnimation={true}
					highlightColor="#f3f3f3"
				/>
			</div>
		</div>
	);
}

export default EventsSkeleton;
