import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "../ResourceDetail.scss";

import { arrowBackOutline, editOutline } from "@assets/Icons";

import { ButtonTertiary } from "@components/atoms/ButtonTertiary";

export function Header({ resource, currentInterface }) {
	const navigate = useNavigate();

	return (
		<div
			className={`ressources-details-header relative p-0 ${
				resource?.color ?? "bg-accent-1"
			}`}
		>
			<div className="head-bar w-full">
				<div className="flex items-center">
					<NavLink
						to={
							currentInterface === "school" ? "/school-resources" : "/resources"
						}
						className="text-decoration-none"
					>
						<ButtonTertiary icon={arrowBackOutline} iconColor="primary-700P" />
					</NavLink>
				</div>
				<div className="flex items-center">
					{currentInterface === "school" && !resource?.isMentorgoal && (
						<ButtonTertiary
							icon={editOutline}
							className="mr-sm"
							onClick={() => {
								navigate(
									`/school-resources/edit/${resource.id.split("/")[3]}`,
									{
										data: resource,
										replace: true,
									},
								);
							}}
						/>
					)}
				</div>
			</div>
			<div className="content">
				{resource?.illustration?.filePath ? (
					<img
						src={`${import.meta.env.VITE_S3}${resource?.illustration.filePath}`}
						alt="logo"
						className="max-w-full"
					/>
				) : (
					<h1 className="wg-no-translate mt-xlg">{resource?.title}</h1>
				)}
			</div>
		</div>
	);
}
