import React, { type MouseEventHandler } from "react";

import "@assets/sass/Card.scss";

import { Badge } from "@components/atoms/Badge";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";

import { editOutline, externalLinkFill } from "../../assets/Icons";

type CardResourcePropsType = {
	title: string;
	description: string;
	image?: string;
	color?: string;
	tags?: string[];
	onEdit?: MouseEventHandler;
	edit: boolean;
	onClick?: MouseEventHandler;
	isDirectLink?: boolean;
};
export function CardResource({
	title,
	description,
	image = "",
	color = "bg-emerald-light",
	tags = [],
	onEdit = () => {},
	edit,
	onClick = () => {},
	isDirectLink = false,
}: CardResourcePropsType) {
	const textColor =
		color === "bg-white"
			? "text-primary-700P"
			: `text-${color.split("-")[1]}-dark`;
	return (
		<div
			className={
				"rounded-lg relative shadow-sm outline-none overflow-hidden cursor-pointer w-full "
			}
		>
			<div
				className={`flex items-start justify-center h-[150px] overflow-hidden ${color}`}
			>
				<div className="flex justify-between w-fit absolute top-0 left-0 p-sm">
					{isDirectLink && (
						<Badge label="Lien direct" leftIcon={externalLinkFill} />
					)}
					{edit && (
						<ButtonTertiary
							onClick={onEdit}
							className="small enabled ml-xxsm"
							icon={editOutline}
						/>
					)}
				</div>

				<div className="w-full h-[96%]" onClick={onClick}>
					{image ? (
						<img
							alt={title}
							src={image}
							className="h-full w-full object-cover"
						/>
					) : (
						<p
							className={`wg-no-translate flex h-full w-full items-center justify-center text-center text-md ${textColor}`}
						>
							{title}
						</p>
					)}
				</div>
			</div>
			<div className="h-[200px] flex flex-col bg-white p-sm" onClick={onClick}>
				<p className="truncate font-bold wg-no-translate">{title}</p>
				<p className="text-primary-200 mt-1 truncate text-xsm wg-no-translate">
					{description}
				</p>
				<div className="tags-container pt-xsm">
					{tags.map((tag, index) => (
						<span key={index}>
							<Badge className="no-event m-xxsm" label={tag} />
						</span>
					))}
				</div>
			</div>
		</div>
	);
}
