import type {
	ConversationType,
	MessageType,
	UnsolvedConversationCounterType,
} from "@/types/mailbox";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
	getUnreadStudentMessagesCounter,
	getUnsolvedConversationCounter,
	updateConversation,
	updateMessage,
} from "./mailBox.request";

export const fetchUpdateConversation = createAsyncThunk(
	"MailBoxAPI/fetchUpdateConversation",
	async (params: ConversationType) => {
		const response = await updateConversation(params);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data.updateSpsConversation.spsConversation;
	},
);

export const fetchUnsolvedConversationCounter = createAsyncThunk(
	"MailBoxAPI/fetchUnsolvedConversationCounter",
	async (params: UnsolvedConversationCounterType) => {
		const response = await getUnsolvedConversationCounter(params);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data.users.paginationInfo.totalCount;
	},
);

export const fetchUnreadStudentMessagesCounter = createAsyncThunk(
	"MailBoxAPI/fetchUnsolvedConversationCounter",
	async () => {
		const totalCount = await getUnreadStudentMessagesCounter();
		return totalCount;
	},
);

export const fetchMessage = createAsyncThunk(
	"MailBoxAPI/fetchMessage",
	async (params: MessageType) => {
		const response = await updateMessage(params);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data;
	},
);
