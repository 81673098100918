import type { EventType } from "@/types/eventsTypes";
import { isSameDay } from "date-fns";
import React from "react";

import { getDiplayableEventDate, getDisplayableEventTime } from "@tools/Events";

export default function EventDateInformations({ event }: { event: EventType }) {
	const startDate = new Date(event?.startDate);
	const endDate = new Date(event?.endDate);

	return (
		<div className="flex flex-wrap gap-2">
			{renderDate(event?.startDate)}
			{renderSeparator(startDate, endDate)}
			{isSameDay(startDate, endDate) ? (
				<>{renderTime(event)}</>
			) : (
				<>{renderDate(event?.endDate)}</>
			)}
		</div>
	);
}

const renderSeparator = (startDate: Date, endDate: Date) => {
	return (
		<span className="text-xsm font-bold text-primary-300">
			{isSameDay(startDate, endDate) ? "|" : "jusqu'au"}
		</span>
	);
};

const renderTime = (event: EventType) => {
	return (
		<span className="text-xsm font-bold text-primary-700P">
			{getDisplayableEventTime(event)}
		</span>
	);
};

const renderDate = (dateToShow: string) => {
	return (
		<span className="text-xsm font-bold text-primary-700P">
			{getDiplayableEventDate(dateToShow)}
		</span>
	);
};
