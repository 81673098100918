import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";

import "@assets/sass/List.scss";

import { Checkbox } from "@components/atoms/Checkbox";

import { chevronRightOutline } from "../../assets/Icons";

type ListSecondaryPropsType = {
	icon: IconifyIcon | string;
	label: string;
	title: string;
	subtitle: string;
	onClick: () => void;
	onCheck?: ((isChecked: boolean) => void) | null;
	iconColor?: string;
};

export function ListSecondary({
	label,
	title,
	icon = "",
	iconColor = "",
	subtitle,
	onClick,
	onCheck = null,
}: ListSecondaryPropsType) {
	return (
		<div className="list-secondary-container flex cursor-pointer items-center">
			{onCheck && (
				<Checkbox
					className="mr-sm"
					onEnabled={() => {
						onCheck(true);
					}}
					onDisabled={() => {
						onCheck(false);
					}}
				/>
			)}
			<div role="menu" onClick={onClick} className="flex w-full flex-col">
				<div className="flex w-full items-center justify-between">
					<div className="flex items-center">
						<Icon className={`mr-xsm text-${iconColor}`} icon={icon} />
						<p className="mr-sm text-primary-700P">{label}</p>

						<p className="title">{title}</p>
					</div>
					<Icon className="text-primary-500P" icon={chevronRightOutline} />
				</div>
				<p className="mt-xsm">{subtitle}</p>
			</div>
		</div>
	);
}
