import type {
	JobContractSearchOptionType,
	StatusOptionType,
	StudentBirthdateOptionType,
} from "@/types/usersFiltersTypes";

import {
	checkMarkCircleOutline,
	clockOutline,
	closeCircleOutline,
	editOutline,
} from "@assets/Icons";

import { getDateLimitFromAge } from "@tools/Dates";

import type { EntEmployeeStatusType } from "@/types/EntCompanyTypes";
import { EStatus } from "@/types/userTypes";

export const ACTIVITY_OPTIONS = [
	{
		id: 0,
		label: "Activité : Tous",
		value: null,
	},
	{
		id: 1,
		label: "Actif (- de 15 jours)",
		value: "after",
	},
	{
		id: 2,
		label: "Inactif",
		value: "before",
	},
];

export const STUDENT_GENDER_OPTIONS = [
	{ label: "Homme", value: false },
	{ label: "Femme", value: true },
	{ label: "Non renseigné", value: null },
];

export const STUDENT_STATUS_OPTIONS: StatusOptionType[] = [
	{
		checked: false,
		label: "En recherche",
		value: EStatus.TOPLACE,
		icon: clockOutline,
		color: "#3A7E75",
		backgroundColor: "bg-info-light",
	},
	{
		checked: false,
		label: "En entreprise",
		value: EStatus.PLACED,
		icon: checkMarkCircleOutline,
		color: "#529339",
		backgroundColor: "bg-success-light",
		textColor: "text-success-darker",
		borderColor: "border-success-darker",
	},
	{
		checked: false,
		label: "En cours de signature",
		value: EStatus.BEING_SIGNED,
		icon: editOutline,
		color: "#D5A019",
		backgroundColor: "bg-secondary-50",
		textColor: "text-secondary-700",
		borderColor: "border-secondary-700",
	},
	{
		checked: false,
		label: "Ne recherche pas",
		value: EStatus.NOT_SEARCHING,
		icon: closeCircleOutline,
		color: "#466A8B",
		backgroundColor: "bg-primary-100",
		textColor: "text-primary-500",
		borderColor: "border-primary-500",
	},
	{
		checked: false,
		label: "Abandon",
		value: EStatus.SURREND,
		icon: closeCircleOutline,
		color: "#CA3F1C",
		backgroundColor: "bg-error-light",
	},
];

export const STUDENT_SEARCH_STATUS_OPTIONS = [
	...STUDENT_STATUS_OPTIONS.filter(
		(option) => option.value !== EStatus.SURREND,
	),
];

export const MOBILITY_OPTIONS = [
	{ label: "Non défini", value: null },
	{ label: "Permis et véhiculé", value: "vehicle" },
	{ label: "Permis et non-véhiculé", value: "license" },
	{ label: "Pas de permis", value: "no license" },
];

export const ARCHIVED_OPTIONS = [
	{ label: "Archivé : Tous", value: null },
	{ label: "Non archivés", value: false },
	{ label: "Archivés", value: true },
];

export const STUDENT_BIRTHDATE_OPTIONS: StudentBirthdateOptionType[] = [
	{ label: "Age : Tous", value: null },
	{ label: "Moins de 21 ans", value: { after: getDateLimitFromAge(21) } },
	{
		label: "21 à 25 ans",
		value: { after: getDateLimitFromAge(25), before: getDateLimitFromAge(21) },
	},
	{
		label: "26 à 29 ans",
		value: { after: getDateLimitFromAge(29), before: getDateLimitFromAge(25) },
	},
	{ label: "30 ans et plus", value: { before: getDateLimitFromAge(29) } },
	{ label: "Non renseigné", value: { exists: { birthdate: false } } },
];

export const JOB_CONTRACT_OPTIONS: JobContractSearchOptionType[] = [
	{
		checked: false,
		label: "Non renseigné",
		value: 0,
	},
	{
		checked: false,
		label: "Stage",
		value: 4,
	},
	{
		checked: false,
		label: "Alternance",
		value: 1,
	},
	{
		checked: false,
		label: "Emploi",
		value: 2,
	},
];
// ESP ENTREPRISES ------------ (mettre à jour les tags)

export const ENT_USER_STATUS: {
	label: string;
	type: EntEmployeeStatusType;
	color: string;
}[] = [
	{
		label: "Accès validé",
		type: "active",
		color: "validated",
	},
	{
		label: "Refusé",
		type: "refused",
		color: "required",
	},
	{
		label: "Accès demandé",
		type: "askAccess",
		color: "neutral",
	},
	{
		label: "Invité",
		type: "invited",
		color: "neutral",
	},
];
