import type { reviewStarsType } from "@containers/public/Selection/SelectionAPI";
import React from "react";
import { starFill, starOutline } from "../../assets/Icons";
import { IconButton } from "./IconButton";

export type starsType = 0 | reviewStarsType;

type RatingStarsProps = {
	checkedStars: starsType;
	onUpdate?: (stars: reviewStarsType) => void;
	disabled?: boolean;
};

const HOVER_TEXT = [
	"Inadapté",
	"Manque de compétences",
	"À examiner",
	"Intéressant",
	"Coup de coeur",
];

export const RatingStars = ({
	checkedStars = 0,
	onUpdate,
	disabled = false,
}: RatingStarsProps) => {
	const stars = Array.from({ length: 5 }, (_, index) => {
		return index < checkedStars;
	});

	return (
		<div className="flex gap-xxsm">
			{stars.map((star, index) => (
				<IconButton
					onClick={() => onUpdate?.((index + 1) as reviewStarsType)}
					icon={star ? starFill : starOutline}
					disabled={disabled}
					key={index}
					className="text-secondary-600"
					tooltip={HOVER_TEXT[index]}
				/>
			))}
		</div>
	);
};
