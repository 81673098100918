import type { TagType } from "@/types/tagTypes";
import type { starsType } from "@components/atoms/RatingStars";
import { getCookie } from "@tools/Cookies";
import axios from "axios";
import type { StudentStatus } from "./ui/StudentCard";

export type Referent = {
	firstname: string;
	lastname: string;
	avatar: string;
	portfolio: string;
	linkedin: string;
	email: string;
	phone: string;
};

export type School = {
	name: string;
	jobTitle: string;
	logo: string;
	campus: string;
};

export type File = {
	filePath: string;
	size: number;
};

export const UNKNOWN_CONTRACT = 0;
export const APPRENTICESHIP_CONTRACT = 1;
export const PERMANENT_CONTRACT = 2;
export const PROFESSIONALIZATION_CONTRACT = 3;
export const INTERNSHIP_CONTRACT = 4;
export const TEMPORARY_CONTRACT = 5;
export const VOLUNTEERING_CONTRACT = 6;
export const CIVIC_CONTRACT = 7;

export interface User {
	id: number;
	firstname: string;
	lastname: string;
	avatar: string;
	postalCode: string;
	city: string;
	linkedin: string;
	portfolio: string;
	promotion: string;
	contractSearched:
		| typeof UNKNOWN_CONTRACT
		| typeof APPRENTICESHIP_CONTRACT
		| typeof PERMANENT_CONTRACT
		| typeof PROFESSIONALIZATION_CONTRACT
		| typeof INTERNSHIP_CONTRACT
		| typeof TEMPORARY_CONTRACT
		| typeof VOLUNTEERING_CONTRACT
		| typeof CIVIC_CONTRACT;
	tags: TagType[];
	selectionStatus: StudentStatus;
	stars: starsType;
}

export interface UserDetails extends User {
	birthdate: string;
	mobility: string;
	cv: File;
	motivationLetter: File;
	portfolioPdf: File;
	linkedin: string;
	portfolio: string;
	comment: string;
	email: string;
	phone: string;
}

export interface Selection {
	code: number;
	name: string;
	company: string;
	referent: Referent;
	school: School;
	totalCount: number;
	users: User[];
}

const API = axios.create({
	baseURL: `${import.meta.env.VITE_BACK}/`,
	timeout: 20000,
	headers: {
		"Content-Type": "application/json",
		"X-Forwarded-For": getCookie("ip"),
	},
});

const APIBearer = axios.create({
	baseURL: `${import.meta.env.VITE_BACK}/${getCookie("token") && getCookie("interface") === "referent" ? "referent/" : ""}`,
	timeout: 20000,
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${getCookie("token")}`,
		"X-Forwarded-For": getCookie("ip"),
	},
});

export async function querySelection(token: string, offset = 0, search = "") {
	let rep = {};

	await APIBearer.post("retrieve_selection", { token, offset, search }).then(
		({ data }) => {
			rep = data;
		},
	);
	return rep as Selection;
}

export async function querySelectionUser(token: string, userId: string) {
	let rep = {};

	await APIBearer.get(`get_selection_user/${token}/${userId}`).then(
		({ data }) => {
			rep = data.data;
		},
	);

	return rep as UserDetails;
}

export type reviewStarsType = 1 | 2 | 3 | 4 | 5;

type setStarsSelectionUserResponse = {
	code: number;
	message: string;
};

export async function setStarsSelectionUser(
	token: string,
	userId: number,
	stars: reviewStarsType,
) {
	let rep = {};

	await API.post(`set_stars_selection_user/${token}/${userId}`, { stars })
		.then(({ data }) => {
			rep = data;
		})
		.catch((e) => {
			rep = { code: 500, message: e.message };
		});

	return rep as setStarsSelectionUserResponse;
}

export type SelectionStatusUpdate = 1 | 2 | 3;

export async function setStatusSelectionUser(
	token: string,
	userId: number,
	selectionStatus: SelectionStatusUpdate,
) {
	let rep = {};

	await API.post(`set_status_selection_user/${token}/${userId}`, {
		selectionStatus,
	}).then(({ data }) => {
		rep = data;
	});

	return rep as setStarsSelectionUserResponse;
}

export async function setCommentSelectionUser(
	token: string,
	userId: number,
	comment: string,
) {
	let rep = {};

	await API.post(`set_comment_selection_user/${token}/${userId}`, {
		comment,
	}).then(({ data }) => {
		rep = data;
	});

	return rep as setStarsSelectionUserResponse;
}
