import type { OrganizationType } from "@/types/organizationTypes";
import useDebounce from "@hooks/useDebounce";
import React, { type ChangeEvent } from "react";
import { useEffect, useState } from "react";

import { briefcaseOutline, plusOutline } from "@assets/Icons";

import { InlineButton } from "@components/atoms/InlineButton";
import { InputLargePrimary } from "@components/molecules/InputLargePrimary";

import { getCompanyList } from "@containers/school/Offers_v3/core/api/offersManagement.request";

import { ErrorToast } from "../toast/ToastNotification";

const MIN_COMPANY_LENGTH = 2;

type SelectCompanyProps = {
	defaultValue?: Partial<OrganizationType> | null;
	errorMessage?: string;
	handleNewCompany: (company: string) => void;
	className?: string;
	disabled?: boolean;
};

export default function SelectCompany({
	disabled = false,
	errorMessage,
	handleNewCompany,
	defaultValue,
	className,
}: SelectCompanyProps) {
	const [inputValue, setInputValue] = useState<string>("");
	const [companyToSearch, setCompanyToSearch] = useState<string>("");
	const [companies, setCompanies] = useState<string[]>([]);
	const [load, setLoad] = useState<boolean>(false);

	const [openSelection, setOpenSelection] = useState<boolean>(false);
	const [inputValueHasChanged, setInputValueHasChanged] =
		useState<boolean>(false);

	const debouncedValue = useDebounce(inputValue, 500);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValueHasChanged(true);
		event.target.value = event.target.value.replace(/[+&#;]/g, "");
		if (event.target.value.length <= 1) {
			event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
		}

		setInputValue(event.target.value);
	};

	const handleSelection = (companySelected: string | null) => {
		if (companySelected) {
			setInputValue(companySelected);
			setOpenSelection(false);
		}

		if (!handleNewCompany || !companySelected) return;
		handleNewCompany(companySelected);
	};

	const retrieveCompanyList = async (companyToFind: string) => {
		if (defaultValue?.name === companyToFind) {
			setOpenSelection(false);
			return;
		}
		if (!companyToFind || companyToFind.length < MIN_COMPANY_LENGTH) return;
		setLoad(true);
		const response = await getCompanyList(companyToFind);
		if (response?.responseLabel === "success") {
			const computedCompanies = response?.data.crmCompanies?.collection.map(
				(company: Partial<OrganizationType>) => company.name,
			);
			setCompanies(computedCompanies);
		} else {
			ErrorToast(response?.message);
			setCompanies([]);
		}
		setLoad(false);
		setOpenSelection(true);
	};

	useEffect(() => {
		setInputValue(`${defaultValue ? defaultValue?.name : ""}`);
	}, [defaultValue]);

	useEffect(() => {
		setCompanyToSearch(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		if (companyToSearch === "") {
			setOpenSelection(false);
		}

		if (inputValueHasChanged) {
			retrieveCompanyList(companyToSearch);
		}
	}, [companyToSearch]);

	return (
		<section
			data-id="company-selector"
			className={`${className} relative w-full`}
			onKeyUp={(e) => {
				if (e.key === "Enter") {
					if (companies.length === 0) {
						handleSelection(companyToSearch);
					} else {
						handleSelection(companies[0]);
					}
				}
			}}
		>
			<InputLargePrimary
				disabled={disabled}
				className="w-full"
				type="text"
				placeholder="Rechercher une entreprise..."
				value={inputValue || ""}
				onChange={(event) => {
					handleChange(event);
					if (event.target.value === "") {
						handleSelection(null);
					}
				}}
				onFocus={() => setOpenSelection(false)}
				icon={briefcaseOutline}
				label="Nom de l'entreprise*"
				isLoading={load}
			/>
			{openSelection && !disabled ? (
				<SelectionOptionsContainer
					companies={companies}
					companyToSearch={companyToSearch}
					selectionFunction={handleSelection}
				/>
			) : null}

			{errorMessage && (
				<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
			)}
		</section>
	);
}

function SelectionOptionsContainer({
	companies,
	companyToSearch,
	selectionFunction,
}: {
	companies: string[];
	companyToSearch: string;
	selectionFunction: (company: string) => void;
}) {
	return (
		<div className="selection-options-container top-[3.75rem] absolute right-0 z-10 my-2.5 grid max-h-[200px] w-full overflow-y-auto rounded-sm bg-white p-2 shadow-md">
			{companies.map((company: string, index) => (
				<SelectionCompanyOption
					key={index}
					company={company}
					selectionFunction={selectionFunction}
				/>
			))}
			{companyToSearch && companyToSearch?.length >= MIN_COMPANY_LENGTH && (
				<InlineButton
					className="text-sm text-primary-700P"
					onClick={() => selectionFunction(companyToSearch)}
					label={`Créer "${companyToSearch}" en tant qu'entreprise`}
					id="create-company-btn"
					icon={plusOutline}
					iconPosition="left"
				/>
			)}
		</div>
	);
}

function SelectionCompanyOption({
	company,
	selectionFunction,
}: {
	company: string;
	selectionFunction: (company: string) => void;
}) {
	return (
		<div
			key={company || ""}
			className="flex w-full cursor-pointer items-center justify-between rounded-sm px-sm py-xsm hover:bg-primary-50"
			onClick={() => selectionFunction(company)}
		>
			<p className="text-p text-primary-700P">{company}</p>
		</div>
	);
}
