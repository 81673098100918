import React, { useEffect, useRef, useState } from "react";

import "@assets/sass/TextArea.scss";

type TextAreaProps = {
	className?: string;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	resize?: boolean;
	height?: string | number;
	value?: string;
	onChange?: (value: string) => void;
	onBlur?: (value: any) => void;
	errorMessage?: string;
	areaBackground?: string;
	autosize?: boolean;
	maxLength?: number;
};

export function TextArea({
	className = "",
	label = "",
	placeholder = "",
	disabled = false,
	resize = false,
	height = 88,
	value = "",
	onChange = () => {},
	onBlur = () => {},
	errorMessage,
	areaBackground,
	autosize = false,
	maxLength = 50000,
}: TextAreaProps) {
	const [textAreaValue, setTextAreaValue] = useState(value);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (textareaRef.current && autosize) {
			textareaRef.current.style.height = "auto";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [value, textAreaValue, textareaRef]);

	useEffect(() => {
		setTextAreaValue(value);
	}, [value]);

	return (
		<div
			className={`text-area-container ${errorMessage ? "error" : ""} ${
				disabled ? "disabled" : ""
			} ${className}`}
		>
			{label && <p className="text-area-label">{label}</p>}
			<textarea
				maxLength={maxLength}
				ref={textareaRef}
				value={textAreaValue}
				onChange={(e) => {
					setTextAreaValue(e.target.value);
					if (onChange) onChange(e.target.value);
				}}
				onBlur={onBlur}
				style={{ minHeight: height }}
				placeholder={placeholder}
				className={`text-area ${resize ? "resize" : ""} !${areaBackground}`}
			/>
			{errorMessage && (
				<p className="text-area-error-message">{errorMessage}</p>
			)}
		</div>
	);
}
