import React, { useState } from "react";

import { layoutOutline } from "@assets/Icons";

import { ButtonTertiary } from "@components/atoms/ButtonTertiary";

import { CheckboxLabelPrimary } from "./CheckboxLabel";

export type SettingsItem = {
	label: string;
	value: string;
	positionValue: number;
};
type SettingsProps = {
	settingsList: SettingsItem[];
	currentSettings?: SettingsItem[];
	onChangeTrigger: (settings: SettingsItem[]) => void;
};

const reorderSettingsByPosition = (settings: SettingsItem[] | undefined) => {
	if (!settings) return [];
	return settings.sort((a, b) => a.positionValue - b.positionValue);
};

const checkIfSettingAreSelected = (
	settingValue: string,
	currentSettings?: SettingsItem[],
) => {
	if (currentSettings) {
		return currentSettings.some((selectedSetting) => {
			return selectedSetting.value === settingValue;
		});
	}
	return false;
};

export function Settings({
	settingsList,
	currentSettings,
	onChangeTrigger,
}: SettingsProps) {
	const [selectedSettings, setSelectedSettings] = useState<SettingsItem[]>(
		reorderSettingsByPosition(currentSettings) || [],
	);

	const [showSettings, setShowSettings] = useState<boolean>(false);
	const selectThisSetting = (setting: SettingsItem) => {
		return [...selectedSettings, setting];
	};
	const deselectThisSetting = (settingValue: string) => {
		return (
			selectedSettings?.filter(
				(selectedSetting) => selectedSetting.value !== settingValue,
			) || []
		);
	};
	return (
		<div data-testid="settings">
			<div
				className="relative"
				data-testid="settingsMenu"
				onBlur={(e) => {
					if (
						!e.currentTarget.contains(e.relatedTarget as Node) &&
						showSettings
					) {
						setShowSettings(false);
					}
				}}
			>
				<ButtonTertiary
					icon={layoutOutline}
					onClick={() => setShowSettings(!showSettings)}
					tooltip="Afficher/Masquer des colonnes"
				/>
				{showSettings && (
					<div
						className="absolute right-0 z-50 w-56 mt-4 min-w-max  rounded-sm  bg-white p-2 shadow-md transition-all"
						id="settings-select"
						onClick={(e) => e.stopPropagation()}
					>
						<p className="mb-1 text-xxsm font-bold text-primary-200">
							Colonnes à afficher :
						</p>
						<div className="max-h-96 overflow-y-auto">
							{settingsList?.map((setting) => {
								return (
									<div
										key={setting.value}
										className="flex items-center px-3 py-2"
									>
										<CheckboxLabelPrimary
											label={setting.label}
											checked={checkIfSettingAreSelected(
												setting.value,
												selectedSettings,
											)}
											onCheck={(newCheckedStatus: boolean) => {
												let newSettings: SettingsItem[] = [];
												if (newCheckedStatus) {
													newSettings = reorderSettingsByPosition(
														selectThisSetting(setting),
													);
													onChangeTrigger(newSettings);
													setSelectedSettings(newSettings);
												} else {
													newSettings = reorderSettingsByPosition(
														deselectThisSetting(setting.value),
													);
													onChangeTrigger(newSettings);
												}
												setSelectedSettings(newSettings);
											}}
										/>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
