import {
	fetchGetCompanyInformations,
	fetchGetCompanymemo,
	fetchUpdateCompanyInformations,
	fetchUpdateCompanyMemo,
	fetchVerifyCompanyExistance,
	fetchVerifyCompanySiretExistance,
} from "../api/companiesManagement.request";
import type { ICompanyInformationsGateway } from "../gateway/companyInformations.gateway";
import type { CrmCompanyDomainModel } from "../model/crmCompany.domainModel";

export class InAPICompanyInformationsGateway
	implements ICompanyInformationsGateway
{
	async getCompanyInformations(
		id: string,
	): Promise<CrmCompanyDomainModel.Company> {
		const response = await fetchGetCompanyInformations(id);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors du chargement des informations de l'entreprise",
			);
		}
		if (response.data.crmCompany) {
			return response.data.crmCompany;
		}
		return undefined as any;
	}

	async updateCompanyInformations(
		companyId: string,
		valueToUpdate: CrmCompanyDomainModel.CompanyValue[],
	) {
		const response = await fetchUpdateCompanyInformations(
			companyId,
			valueToUpdate,
		);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors de la mise à jour des informations de l'entreprise",
			);
		}
		return response.data;
	}

	async verifyCompanyExistance(companyName: string) {
		const response = await fetchVerifyCompanyExistance(companyName);
		if (response.responseLabel === "error") {
			throw new Error(
				"Erreur lors de la vérification de l'existence de l'entreprise",
			);
		}
		return response.data.crmCompanies.collection;
	}

	async verifyCompanySiretExistance(siret: string) {
		const response = await fetchVerifyCompanySiretExistance(siret);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(response.message);
		}
		if (
			!response?.data?.crmCompanies?.collection ||
			response.data.crmCompanies.collection === null
		) {
			const tmpResponse = {
				data: {
					crmCompanies: {
						collection: [],
					},
				},
				...response,
			};
			return tmpResponse;
		}
		return response?.data?.crmCompanies?.collection || [];
	}

	async updateCompanyMemo(companyId: string, memo: string) {
		const escapedMemo = JSON.stringify(memo);
		const response = await fetchUpdateCompanyMemo(companyId, escapedMemo);

		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors de la mise à jour des informations de l'entreprise",
			);
		}
		return response.data;
	}

	async getCompanyMemo(companyId: string) {
		const { responseLabel, message, data } =
			await fetchGetCompanymemo(companyId);
		if (responseLabel === "error" && message !== "aborted") {
			throw new Error(
				"Erreur lors du chargement des informations de l'entreprise",
			);
		}
		if (
			typeof data === "object" &&
			"crmCompany" in data &&
			data.crmCompany?.memo
		) {
			return data?.crmCompany?.memo || "";
		}
		return "";
	}
}
