import React, { type ReactNode } from "react";

type CompanyNameProps = {
	nameToDisplay: string;
	nameColor?: string;
	additionalClass?: string;
	children: ReactNode;
};
function CompanyName({
	nameToDisplay,
	nameColor = "text-primary-700P",
	children,
}: CompanyNameProps) {
	return (
		<span
			className={`text-xsm text-primary-700P truncate ${nameColor} ${
				nameToDisplay ? "wg-no-translate" : ""
			}`}
		>
			{children}
			{nameToDisplay}
		</span>
	);
}

export default CompanyName;
