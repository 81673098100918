import React from "react";

import { EvaluationRow } from "./EvaluationRow";
export const Evaluation = () => {
	return (
		<div className="flex flex-1 w-full flex-col gap-10">
			<span className="text-sm font-semibold flex">Dernières évaluations</span>
			<div className="flex flex-col items-center justify-center gap-3">
				<EvaluationRow isGlobal={true} title="Score Global" evaluation={4} />
				<EvaluationRow title="Soft Skills" evaluation={5} />
				<EvaluationRow title="Hard Skills" evaluation={3} />
				<EvaluationRow title="Entretiens" />
			</div>
		</div>
	);
};
