import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import "@assets/sass/ToggleButton.scss";

type ToggleButtonType = {
	icon: any;
	className?: string;
	active: boolean;
	onChange?: Function;
	onClick: Function;
	label?: string | number | null;
};

type ToggleButtonQuaternaryType = ToggleButtonType & {
	activeIcon: any;
};

// Rename ToggleButton et move dans ToggleButtonGroup
export function ToggleButtonTertiary({
	icon,
	className = "",
	active,
	onChange,
	onClick,
	label,
}: ToggleButtonType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<div
			data-id="toggleButonTertiary"
			className={`p-xsm w-10 h-10 rounded-md transition cursor-pointer flex items-center justify-center ${
				isActive
					? "bg-primary-100 shadow-primary700"
					: "hover:shadow-[inset_0px_0px_0px_3px_white] hover:bg-primary-50"
			} ${className}`}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				onClick();
				if (onChange) onChange(!isActive);
			}}
		>
			<Icon icon={icon} className="min-w-6 min-h-6" color="#2a4054" />
			{label && <p className="text-xsm font-bold text-primary-700P">{label}</p>}
		</div>
	);
}

// Delete et replace par un button
export function ToggleButtonQuaternary({
	icon,
	className = "",
	active = false,
	activeIcon = null,
	onChange = () => {},
	onClick = () => {},
}: ToggleButtonQuaternaryType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<div
			role="menu"
			data-id="toggleButtonQuaternary"
			className={`toggle-button-quaternary-container ${
				isActive && "active"
			} $ ${className}`}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				onClick();
				setIsActive(!isActive);
				onChange(!isActive);
			}}
		>
			{isActive ? (
				<Icon icon={activeIcon} className="toggle-button-quaternary-active" />
			) : (
				<Icon icon={icon} className="toggle-button-quaternary-icon" />
			)}
		</div>
	);
}

// A delete
export function ToggleButtonQuaternaryLarge({
	icon,
	className = "",
	active = false,
	activeIcon = null,
	onChange = () => {},
	onClick = () => {},
}: ToggleButtonQuaternaryType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<div
			data-id="toggleButtonQuaternaryLarge"
			role="menu"
			className={`toggle-button-quaternary-container ${
				isActive && "active"
			} $ ${className}`}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				onClick();
				setIsActive(!isActive);
				onChange(!isActive);
			}}
		>
			{isActive ? (
				<Icon icon={activeIcon} className="toggle-button-quaternary-active" />
			) : (
				<Icon icon={icon} className="toggle-button-quaternary-icon" />
			)}
		</div>
	);
}

// A delete et replace par un button
export function ToggleButtonFavorite({
	icon,
	className = "",
	active = false,
	activeIcon = null,
	onChange = () => {},
	onClick = () => {},
}: ToggleButtonQuaternaryType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<div
			data-id="toggleButtonFavorite"
			role="menu"
			className={`toggle-button-favorite-container ${
				isActive && "active"
			} ${className}`}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				onClick();
				onChange(!isActive);
			}}
		>
			{isActive ? (
				<Icon icon={activeIcon} className="toggle-button-favorite-active" />
			) : (
				<Icon icon={icon} className="toggle-button-favorite-icon" />
			)}
		</div>
	);
}
