import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";

import "@assets/sass/Combobox.scss";

import { keysToCamel } from "@tools/Objects";

type ComboboxSearchAddressPropsType = {
	placeholder: string;
	className?: string;
	name: string;
	onChange?: (e: any) => void;
	onBlur?: (e: any) => void;
	value: string;
	icon?: any;
	label: string;
	errorMessage?: string;
	onClick?: () => void;
	disabled?: boolean;
};

type AddressType = {
	searchAddress: string;
	postalCode?: string;
	longitude?: number;
	latitude?: number;
	country?: string;
	streetNumber?: string;
	streetName?: string;
	city?: string;
	searchValue?: string;
	active?: boolean;
};
export function ComboboxSearchAddress({
	placeholder,
	className,
	name = "address",
	onChange,
	onBlur,
	onClick,
	value,
	icon,
	label,
	errorMessage,
	disabled = false,
}: ComboboxSearchAddressPropsType) {
	const [address, setAddress] = useState<AddressType>({ searchAddress: "" });
	const [searchType, setSearchType] = useState(["geocode"]);

	const parseAddressComponents = (components: any, filter: any) => {
		const dataToParse = components?.filter((component: any) =>
			component.types.includes(filter),
		)?.[0];

		return dataToParse && keysToCamel(dataToParse);
	};

	const handleSelect = (searchAddress: any) => {
		geocodeByAddress(searchAddress).then((results) => {
			const Addresscomponents = results[0].address_components;
			const addressFormatedData = {
				...{
					postalCode: parseAddressComponents(Addresscomponents, "postal_code"),
					longitude: results[0].geometry.location.lng(),
					latitude: results[0].geometry.location.lat(),
					country: parseAddressComponents(Addresscomponents, "country"),
				},
				...(name === "address"
					? {
							streetNumber: parseAddressComponents(
								Addresscomponents,
								"street_number",
							),
							streetName: parseAddressComponents(Addresscomponents, "route"),
							city: parseAddressComponents(Addresscomponents, "locality"),

							searchValue: results[0].formatted_address || address,
						}
					: {
							city: parseAddressComponents(Addresscomponents, "locality"),
							searchValue:
								(name === "postalCode" &&
									parseAddressComponents(Addresscomponents, "postal_code")
										?.longName) ||
								(name === "city" &&
									parseAddressComponents(Addresscomponents, "locality")
										?.longName) ||
								address,
						}),
			};
			setAddress(addressFormatedData as any);
			onChange?.(addressFormatedData);
		});
	};
	const handleChange = (address: string) => {
		setAddress({ searchValue: address } as any);
		onChange?.({ searchValue: address });
	};

	useEffect(() => {
		if (name === "postalCode") setSearchType(["postal_code"]);
		if (name === "city") setSearchType(["locality"]);
		if (value !== address.searchValue) {
			setAddress({ searchValue: value } as any);
		}
	}, [value, name]);

	return (
		<PlacesAutocomplete
			value={address.searchValue}
			onChange={handleChange}
			onSelect={handleSelect}
			searchOptions={{
				types: searchType,
			}}
			debounce={500}
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
				return (
					<div className={`combobox-container ${className}`}>
						{label && (
							<p className="mb-xxsm text-xxsm font-normal text-primary-500">
								{label}
							</p>
						)}
						<div
							className="m-[1px] relative flex flex-col rounded-md hover:outline outline-primary-150 outline-1 outline
            hover:border hover:outline-2 hover:outline-info-light hover:border-accent-1"
						>
							{icon && <Icon className="icon-combobox" icon={icon} />}
							<input
								readOnly={disabled}
								id="input-combobox"
								name={name}
								{...getInputProps({
									placeholder,
									className: `location-search-input ${icon && "with-icon"} ${
										errorMessage && "error-message"
									} !bg-white !rounded-md !border !border-primary-150 hover:border-1        
                  focus:border-1 focus:outline focus:border focus:outline-2 focus:border-accent-1-dark focus:outline-accent-1-light 
                  active:border-1 active:outline active:border active:outline-2 active:border-accent-1-dark active:outline-accent-1-light`,
									onBlur: (e) => {
										e.stopPropagation();
										onBlur?.(address);
									},
								})}
								onClick={onClick}
							/>
						</div>

						{errorMessage && (
							<p className="text-xs pt-xxsm text-error-dark font-bold">
								{errorMessage}
							</p>
						)}

						{suggestions.length > 0 && !loading && (
							<div className="autocomplete-dropdown-container options-list !mt-6">
								<div
									className={`simple-item-large-container ${
										address.active
											? "suggestion-item--active"
											: "suggestion-item"
									}  no-event`}
								>
									<span className="text-primary-300">
										{address.searchValue}
									</span>
								</div>
								{suggestions.map((suggestion, i) => {
									const className = `simple-item-large-container ${
										suggestion.active
											? "suggestion-item--active"
											: "suggestion-item"
									}`;
									const style = suggestion.active
										? { backgroundColor: "#fafafa", cursor: "pointer" }
										: { backgroundColor: "#ffffff", cursor: "pointer" };

									return (
										i <= 2 && (
											<div
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
											>
												<span className="text-primary-700P">
													{name === "address"
														? suggestion.description
														: suggestion.formattedSuggestion.mainText}
												</span>
											</div>
										)
									);
								})}
							</div>
						)}
					</div>
				);
			}}
		</PlacesAutocomplete>
	);
}
