import React from "react";

import PageBanner from "@components/templates/page/PageBanner";

import EventsHeaderActionsRow from "@containers/shared/Events/UI/EventsHeaderActionsRow";

export default function Header() {
	return (
		<section className="col-span-4 flex flex-col items-center md:col-span-8 lg:col-span-12">
			<PageBanner title="Évènements et coaching emploi" />
			<EventsHeaderActionsRow />
		</section>
	);
}
