import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import "@assets/sass/ColorBoxes.scss";
import "@assets/sass/ColorChoice.scss";

import { checkmarkCircle2Outline } from "@assets/Icons";

type ColorType = { id?: number; value?: string; active?: boolean }[];
type SingleColorType = { id?: number; value?: string; active?: boolean };
type ColorChoicePropsTypes = {
	defaultColor?: string;
	optionsColor: ColorType;
	onChange: (color: SingleColorType) => void;
};

type ColorBoxesType = {
	bgColor?: string;
	className?: string;
	onClick: () => void;
	onChange: (color: string) => void;
	active?: boolean;
	key: number;
};

export function ColorBoxes({
	bgColor = "",
	className = "",
	onClick,
	onChange,
	active = false,
	key,
}: ColorBoxesType) {
	const [activated, setActivated] = useState<boolean>(false);

	useEffect(() => {
		setActivated(active);
	}, [active]);

	return (
		<div
			onClick={onClick}
			key={key}
			id={bgColor}
			className={`${className} flex h-8 w-8 cursor-pointer items-center justify-center rounded-sm p-xxsm ${bgColor}`}
			onChange={() => {
				if (onChange) onChange(bgColor);
			}}
		>
			{activated && (
				<Icon
					className="check text-white"
					icon={checkmarkCircle2Outline}
					height="20px"
				/>
			)}
		</div>
	);
}

export function ColorChoice({
	onChange,
	optionsColor,
	defaultColor = "",
}: ColorChoicePropsTypes) {
	const [options, setOptions] = useState<ColorType>([]);
	const findColor = (arrayColor: ColorType, value: string) => {
		let arrayColorCopy = [...arrayColor];
		let targetColor = arrayColorCopy.find((x) => x.value === value);
		if (!targetColor) targetColor = arrayColorCopy[0];
		arrayColorCopy = arrayColorCopy.map((item) => {
			return item.id === targetColor?.id
				? { ...item, active: true }
				: { ...item, active: false };
		});

		return arrayColorCopy;
	};

	useEffect(() => {
		setOptions(findColor(optionsColor, defaultColor));
	}, [defaultColor, optionsColor]);

	return (
		<div className="text-choice-container flex items-center gap-xsm">
			{options?.map(
				(color: { value: string; active: boolean }, index: number) => {
					return (
						<ColorBoxes
							key={index}
							bgColor={color.value}
							active={color.active}
							onClick={() => {
								if (onChange) onChange(color);
								setOptions(findColor(optionsColor, color.value));
							}}
							onChange={() => onChange(color)}
						/>
					);
				},
			)}
		</div>
	);
}
