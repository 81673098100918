import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "../ApplicationDetails.scss";

import { ScrollableTabGroup } from "@components/molecules/TabGroup";

import { useBreakpoints } from "../../../../../hooks/useBreakpoints";

import { ActionFlow } from "./Extensions/ActionFlow";
import { ActivityFlow } from "./Extensions/ActivityFlow";
import { Actions } from "./Tabs/Actions";
import { Application } from "./Tabs/Application";
import { Contacts } from "./Tabs/Contacts";
import { Interview } from "./Tabs/Interview/Interview";
import { Notes } from "./Tabs/Notes";
import { Organization } from "./Tabs/Organization";

export function Information({
	contactsProps,
	companyProps,
	applicationProps,
	applicationId,
	actionsProps,
}) {
	const { isMobile } = useBreakpoints(799);
	const [tabIndex, setTabIndex] = useState(0);
	const { actions, setActions } = { ...actionsProps };
	const { application, setApplication } = { ...applicationProps };

	useEffect(() => {
		const anchor = window.location.href.split("#")[1];

		switch (anchor) {
			case "action":
				if (window.innerWidth <= 799) {
					setTabIndex(2);
					break;
				}
				setTabIndex(1);
				break;
			case "contact":
				if (window.innerWidth <= 799) {
					setTabIndex(1);
				}
				break;
			default:
				setTabIndex(0);
		}
	}, []);

	useEffect(() => {}, [actions]);

	const returnTab = (index) => {
		switch (index) {
			case 1:
				if (window.innerWidth <= 799) {
					return (
						<Contacts
							contactsProps={contactsProps}
							applicationId={applicationId}
						/>
					);
				}
				return (
					<Actions
						actionsState={{ actions, setActions }}
						application={application}
					/>
				);
			case 2:
				if (window.innerWidth <= 799) {
					return (
						<>
							<Actions
								actionsState={{ actions, setActions }}
								application={application}
								contacts={contactsProps.contacts}
							/>
							<ActionFlow
								contacts={contactsProps.contacts}
								actionsState={{ actions, setActions }}
							/>
						</>
					);
				}
				return <Notes applicationId={application?.id} />;
			case 3:
				if (window.innerWidth <= 799) {
					return <Notes applicationId={application?.id} />;
				}
				return (
					<Interview
						application={application}
						contacts={contactsProps.contacts}
					/>
				);
			case 4:
				return (
					<Interview
						application={application}
						contacts={contactsProps.contacts}
					/>
				);
			case 5:
				return (
					<Organization application={application} companyProps={companyProps} />
				);
			default:
				return (
					<Application applicationProps={{ application, setApplication }} />
				);
		}
	};

	const returnExtension = (index) => {
		switch (index) {
			case 0:
			case 3:
			case 4:
			case 5:
			case 6:
				return null;
			case 1:
				if (window.innerWidth <= 799) {
					return null;
				}
				return (
					<ActionFlow
						contacts={contactsProps.contacts}
						actionsState={{ actions, setActions }}
					/>
				);
			case 2:
				return null;
			default:
				return <ActivityFlow />;
		}
	};

	return (
		<div className="information-container w-full !overflow-visible pl-0">
			<div className="content flex-col">
				{!isMobile && (
					<ScrollableTabGroup
						className="mb-lg "
						tabs={[
							{
								label: "Candidature",
								active: tabIndex === 0,
							},
							{ label: "Actions", active: tabIndex === 1 },
							{ label: "Notes", active: tabIndex === 2 },
							{
								label: "Entretien",
								active: tabIndex === 3,
								display: application?.step === "interview",
							},
						]}
						onTabChange={(i) => {
							setTabIndex(i);
						}}
					/>
				)}

				{isMobile && (
					<ScrollableTabGroup
						className="tab-group-secondary mb-md "
						tabs={[
							{
								label: "Candidature",
								active: tabIndex === 0,
							},
							{ label: "Contacts", active: tabIndex === 1 },
							{ label: "Actions", active: tabIndex === 2 },
							{ label: "Notes", active: tabIndex === 3 },
							{
								label: "Entretien",
								active: tabIndex === 4,
								display: application?.step === "interview",
							},
						]}
						onTabChange={(i) => {
							setTabIndex(i);
						}}
					/>
				)}
				{returnTab(tabIndex)}
			</div>
			<div className={`${returnExtension(tabIndex) && "extension"} mt-md`}>
				{returnExtension(tabIndex)}
			</div>
		</div>
	);
}

Information.propTypes = {
	contactsProps: PropTypes.shape({
		contacts: PropTypes.arrayOf(PropTypes.shape({})),
	}).isRequired,
	companyProps: PropTypes.shape({
		company: PropTypes.shape({
			id: PropTypes.string,
		}),
	}).isRequired,
	applicationProps: PropTypes.shape({
		application: PropTypes.shape({
			id: PropTypes.string,
			step: PropTypes.string,
			notes: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		setApplication: PropTypes.func,
	}).isRequired,
	applicationId: PropTypes.number.isRequired,
	actionsProps: PropTypes.shape({
		actions: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
			}),
		),
		setActions: PropTypes.func,
	}).isRequired,
};
