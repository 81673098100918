import { cookiesNameMapping } from "@constants/Cookies";

export function getCookie(name: string) {
	let nameCpy = name;

	if (import.meta.env.VITE_DOMAIN === "test.mentorgoal.com") {
		nameCpy = `test.${name}`;
	}
	if (import.meta.env.VITE_DOMAIN === "staging.mentorgoal.com") {
		nameCpy = `staging.${name}`;
	}

	const match = document.cookie.match(new RegExp(`(^| )${nameCpy}=([^;]+)`));

	if (match) return match[2];
	return null;
}

export function getAllCookies() {
	return document.cookie.split("; ");
}

export function setCookie(name: string, value: string, duration = 604800) {
	let nameCpy = name;

	if (import.meta.env.VITE_DOMAIN === "test.mentorgoal.com") {
		nameCpy = `test.${name}`;
	}
	if (import.meta.env.VITE_DOMAIN === "staging.mentorgoal.com") {
		nameCpy = `staging.${name}`;
	}

	document.cookie = `${nameCpy}=${value}; Max-Age=${duration}; Domain=${
		import.meta.env.VITE_DOMAIN
	}; path=/; SameSite=Lax`;
}

export function deleteAllCookies() {
	document.cookie.split(";").forEach((c) => {
		document.cookie = `${
			c.trim().split("=")[0]
		}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${
			import.meta.env.VITE_DOMAIN
		};path=/`;
	});
}

export function deleteCookie(name: string) {
	let nameCpy = name;

	if (import.meta.env.VITE_DOMAIN === "test.mentorgoal.com") {
		nameCpy = `test.${name}`;
	}
	if (import.meta.env.VITE_DOMAIN === "staging.mentorgoal.com") {
		nameCpy = `staging.${name}`;
	}

	document.cookie = `${nameCpy}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${
		import.meta.env.VITE_DOMAIN
	};path=/`;
}

export function retrieveCookiesNameFromUrl(urlToUse?: string) {
	const url = urlToUse || window.location.href;
	const cookiesName = url.split("/")[3];
	return cookiesNameMapping.get(cookiesName) || "";
}
