import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import "./StudentHelp.scss";

import { arrowForwardFill } from "@assets/Icons";

import { InlineButton } from "@components/atoms/InlineButton";

export function StudentHelp() {
	const { setTitle }: any = useOutletContext();

	useEffect(() => {
		setTitle("Aide");
	}, []);

	return (
		<main className="help-container">
			<iframe
				className="rounded-lg h-[90vh] w-full p-0 -mt-5 max-h-[82vh]"
				title="notion"
				src="https://v2-embednotion.com/Aide-l-utilisation-Etudiant-d02c9c7eeb1c408b9d1ead5825ebaf7f"
			/>
			<InlineButton
				id="help-button"
				className="more flex items-center"
				icon={arrowForwardFill}
				label="Cliquez ici si cette page ne s’affiche pas"
				onClick={() => {
					window.open(
						"https://mentorgoal.notion.site/Aide-l-utilisation-Etudiant-d02c9c7eeb1c408b9d1ead5825ebaf7f",
						"_blank",
					);
				}}
			/>
		</main>
	);
}
