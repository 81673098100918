import login_blue from "@assets/illustrations/login/login_blue.svg";
import login_green from "@assets/illustrations/login/login_green.svg";
import login_orange from "@assets/illustrations/login/login_orange.svg";
import login_pink from "@assets/illustrations/login/login_pink.svg";
import login_yellow from "@assets/illustrations/login/login_yellow.svg";

export const getAuthIllustrationFromURL = () => {
	// return random illustration
	const illustrations = [
		login_yellow,
		login_blue,
		login_pink,
		login_green,
		login_orange,
	];
	return illustrations[Math.floor(Math.random() * illustrations.length)];
};
