import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "../../StudentDetail.scss";

import { ApplicationItem } from "@components/organisms/ApplicationItem";

import { DisplayApplicationModal } from "./ApplicationsList/DisplayApplicationModal";

export function Applications({ applications, student }) {
	const [apps, setApps] = useState([]);
	const [currentApplication, setCurrentApplication] = useState(0);
	const [displayApplication, setDisplayApplication] = useState(false);

	const goPrevious = () => {
		setCurrentApplication(
			currentApplication === 0 ? apps?.length - 1 : currentApplication - 1,
		);
	};

	const goNext = () => {
		setCurrentApplication(
			currentApplication === apps?.length - 1 ? 0 : currentApplication + 1,
		);
	};

	useEffect(() => {
		setApps(applications);
	}, [applications, currentApplication]);

	return (
		<div className="applications-container pb-lg pt-sm">
			{apps?.length === 0 && (
				<p className="subtitle-p text-primary-200">Aucune candidature créée</p>
			)}

			{apps
				?.sort(
					(a, b) =>
						new Date(
							b.interviewDate ?? b.relaunchDate ?? b.sentDate ?? b.createdAt,
						) -
						new Date(
							a.interviewDate ?? a.relaunchDate ?? a.sentDate ?? a.createdAt,
						),
				)
				?.map((application, i) => {
					return (
						<ApplicationItem
							key={application.id}
							className="mt-md"
							onClick={() => {
								setCurrentApplication(i);
								setDisplayApplication(!displayApplication);
							}}
							application={application}
							companyName={application?.offer?.crmCompany?.name}
						/>
					);
				})}
			{apps[currentApplication] && (
				<DisplayApplicationModal
					application={apps[currentApplication]}
					student={student}
					show={displayApplication}
					goPrevious={goPrevious}
					goNext={goNext}
					onClose={() => {
						setDisplayApplication(false);
					}}
				/>
			)}
		</div>
	);
}

Applications.propTypes = {
	applications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	student: PropTypes.shape({}).isRequired,
};
