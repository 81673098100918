import type { ContactType } from "@/types/contactTypes";
import React, { useEffect, useState } from "react";

import "./Tabs.scss";

import {
	editOutline,
	emailFill,
	linkedinFill,
	phoneFill,
	plusFill,
} from "@assets/Icons";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { Accordion } from "@components/organisms/Accordion";

import { ModifyContactModal } from "../Modals/Contact/ModifyContactModal";
import { NewContactModal } from "../Modals/Contact/NewContactModal";

type ContactsPropsType = {
	contactsProps: any;
	applicationId: number;
};

export function Contacts({ contactsProps, applicationId }: ContactsPropsType) {
	const { contacts, setContacts } = contactsProps;
	const [showModifyContact, setShowModifyContact] = useState(false);

	const [targetContact, setTargetContact] = useState<ContactType>(
		{} as ContactType,
	);

	const [showNewContactModal, setShowNewContactModal] =
		useState<boolean>(false);

	const unfavoriteAllContacts = () => {
		const contactsCopy = [...contacts];
		contactsCopy.forEach((contact) => {
			contact.favorite = false;
		});
		return contactsCopy;
	};

	const _favoriteThisContact = (contactId: string) => {
		let contactsCopy = [...contacts];
		if (
			contacts.find((contact: ContactType) => contact.id === contactId).favorite
		) {
			contactsCopy = unfavoriteAllContacts();
			setContacts(contactsCopy);
			return;
		}

		contactsCopy = unfavoriteAllContacts();

		const contactToFavorite = contactsCopy.find(
			(contact) => contact.id === contactId,
		);
		contactToFavorite.favorite = true;
		setContacts(contactsCopy);
	};

	const handleModifyContactClick = () => {
		setShowModifyContact(!showModifyContact);
	};

	const sortContactsByFirstName = () => {
		const contactsCopy = [...contacts];
		contactsCopy.sort((a, b) => {
			if (a.firstname < b.firstname) return -1;
			if (a.firstname > b.firstname) return 1;
			return 0;
		});
		return contactsCopy;
	};

	useEffect(() => {}, [setContacts]);

	return (
		<div className="flex h-full justify-center">
			{contacts.length > 0 ? (
				<div>
					<div className="flex justify-center">
						<ButtonPrimary
							label="Ajouter un contact"
							onClick={() => {
								setShowNewContactModal(!showNewContactModal);
							}}
						/>
					</div>
					<div id="accordion3">
						{sortContactsByFirstName().map(
							(contact: ContactType, index: number) => {
								const items = [];
								if (contact.email) {
									items.push({
										icon: emailFill,
										label: contact.email,
										onClick: () => {},
									});
								}
								if (contact?.phone?.length > 0 && contact.phone) {
									contact.phone.map((phone) => {
										if (phone) {
											return items.push({
												icon: phoneFill,
												label: phone,
												onClick: () => {},
											});
										}
										return null;
									});
								}
								if (contact.linkedin) {
									items.push({
										icon: linkedinFill,
										label: contact.linkedin,
										onClick: () => {
											window.open(contact.linkedin, "_blank");
										},
									});
								}
								const hasInformations =
									contact.linkedin ||
									contact.email ||
									contact?.phone?.length > 0;
								return (
									<span key={contact.id}>
										<Accordion
											translate={false}
											parentID="accordion3"
											title={`${contact.firstname} ${contact.lastname}`}
											subtitle={contact.job}
											avatarImage={contact.image}
											className="mt-sm"
											items={items}
											deployed={index === 0}
											link={
												<InlineButton
													id="add-experience"
													onClick={() => {
														if (
															contact.id !== undefined &&
															contact.id !== null &&
															contact.id !== ""
														) {
															setTargetContact(contact);
															handleModifyContactClick();
														}
													}}
													label={getLabelFromInformations(hasInformations)}
													icon={hasInformations ? editOutline : plusFill}
												/>
											}
										/>
									</span>
								);
							},
						)}
					</div>
				</div>
			) : (
				<div className="add-mobile-contact">
					<ButtonPrimary
						label="Ajouter un contact"
						onClick={() => {
							setShowNewContactModal(!showNewContactModal);
						}}
					/>
				</div>
			)}

			<NewContactModal
				contactsProps={contactsProps}
				applicationId={applicationId}
				show={showNewContactModal}
				onClose={() => {
					setShowNewContactModal(!showNewContactModal);
				}}
			/>
			<ModifyContactModal
				contact={targetContact as ContactType}
				show={showModifyContact}
				onClose={() => {
					setShowModifyContact(false);
				}}
				contactsProps={contactsProps}
			/>
		</div>
	);
}

const getLabelFromInformations = (contactInformations: string | boolean) => {
	if (!contactInformations) return "Ajouter des informations";
	return contactInformations
		? "Modifier le contact"
		: "Ajouter des informations";
};
