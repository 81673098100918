import type { LogType } from "@/types/logTypes";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";

import { eyeOutline } from "@assets/Icons";

import { InlineButton } from "@components/atoms/InlineButton";

const MAX_LENGTH_TO_SHOW = 90;

type LogDescriptionType = {
	log: LogType;
	formatLogDescriptionFunction?: (logDescription: string) => string;
};
export function LogDescription({
	log,
	formatLogDescriptionFunction,
}: LogDescriptionType) {
	const [seeMore, setSeeMore] = useState(
		DOMPurify.sanitize(
			formatLogDescriptionFunction
				? formatLogDescriptionFunction(log?.description || "")
				: log.description ?? "",
		)?.length < MAX_LENGTH_TO_SHOW,
	);
	const [isLong, setIsLong] = useState(false);

	const isEllipsisActive = () => {
		const description = document.getElementById(`description${log.id}`);
		const parent = document.getElementById(`description-parent${log.id}`);
		if (!description || !parent) return false;
		const temp: HTMLElement = description.cloneNode(true) as HTMLElement;

		temp.style.overflow = "visible";
		temp.style.visibility = "hidden";
		temp.classList.remove("line-clamp-2");
		parent.appendChild(temp);

		try {
			const fullHeight = temp.getBoundingClientRect().height;
			const displayHeight = description.getBoundingClientRect().height;
			return fullHeight > displayHeight;
		} finally {
			temp.remove();
		}
	};

	useEffect(() => {
		setIsLong(isEllipsisActive());
	}, []);

	if (!log.description) return null;

	return (
		<>
			{log.action === "modifyCV" || log.action === "deleteCV" ? (
				<a
					href={`${import.meta.env.VITE_S3}${log.description}`}
					target="_blank"
					rel="noreferrer"
				>
					<InlineButton
						label="Voir l'ancien CV"
						icon={eyeOutline}
						onClick={() => {}}
					/>
				</a>
			) : (
				<>
					<p
						className="wg-no-translate mt-1 text-xxsm"
						id={`description-parent${log.id}`}
					>
						<div
							className={seeMore ? "italic" : "line-clamp-2 italic"}
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(
									`<div>${
										formatLogDescriptionFunction
											? formatLogDescriptionFunction(log?.description)
											: log?.description
									}</div>`,
								),
							}}
							id={`description${log.id}`}
						/>
					</p>
					{isLong && (
						<InlineButton
							label={seeMore ? "Voir moins" : "Voir plus"}
							onClick={() => {
								setSeeMore(!seeMore);
							}}
						/>
					)}
				</>
			)}
		</>
	);
}
