import type { UserType } from "@/types/userTypes";
import { getStudents } from "@containers/school/Students/StudentsManagement/StudentsManagementAPI";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import type { ViewType } from "@/types/genericType";
import { Combobox } from "./Combobox";

type FormattedStudentType = {
	id: string;
	label: string;
	image: string;
	firstname?: string;
	lastname?: string;
};

export function ComboboxSearchStudent({
	handleNewSelection,
}: {
	handleNewSelection: Function;
}) {
	const { structureView }: { structureView: ViewType } = useOutletContext();

	const [students, setStudents] = useState([]);
	const [selectedStudent, setSelectedStudent] = useState<FormattedStudentType>(
		{} as FormattedStudentType,
	);
	const [search, setSearch] = useState("");
	const debouncedValue = useDebounce(search, 500);
	const formatStudents = (students: UserType[]) => {
		const formattedStudents: FormattedStudentType[] = [];

		students.map((student) => {
			return formattedStudents.push({
				id: student?.id,
				label: `${student?.firstname} ${student?.lastname}`,
				firstname: student?.firstname,
				lastname: student?.lastname,
				image: `${student?.avatar?.filePath}`,
			});
		});

		return formattedStudents;
	};

	const retrieveOptions = async () => {
		const structureFilter = Object.prototype.hasOwnProperty.call(
			structureView,
			"school",
		)
			? `spsPromotion_school: "${structureView.school}"`
			: `spsPromotion_campus: "${structureView.campus}"`;

		const serverResponse = await getStudents(structureFilter, debouncedValue);
		if (serverResponse.responseLabel === "success") {
			const users: UserType[] = serverResponse.data?.users?.collection;
			setStudents(formatStudents(users) as any);
		}
	};

	useEffect(() => {
		retrieveOptions();
	}, [debouncedValue]);
	return (
		<div data-testid="comboboxSearchStudent">
			<Combobox
				className="mt-xxsm"
				options={students}
				placeholder="Rechercher un étudiant..."
				onChange={(e) => {
					const searchSanitize = e.target.value.replace(" ", "%");
					setSearch(searchSanitize);
					if (e.target.value === "") {
						setSelectedStudent({} as FormattedStudentType);
					}
				}}
				value={selectedStudent?.label}
				onClickOnItem={(e) => {
					setSelectedStudent(e);
					handleNewSelection(e);
				}}
			/>
		</div>
	);
}
