import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";

import { InputLargePrimary } from "@components/molecules/InputLargePrimary";
import type { ProfilSharingFiltersType } from "@containers/school/ProfileSharing";

type HeadType = {
	filtersProps: {
		filters: ProfilSharingFiltersType;
		setFilters: (filters: ProfilSharingFiltersType) => void;
	};
	load: boolean;
};

export function Head({ filtersProps, load }: HeadType) {
	const { filters, setFilters } = filtersProps;
	const [search, setSearch] = useState<string>("");
	const debouncedValue = useDebounce(search, 1000);

	useEffect(() => {
		if (!load) setFilters({ ...filters, search: debouncedValue, page: 1 });
	}, [debouncedValue]);

	return (
		<div className="w-4/12">
			<InputLargePrimary
				type="text"
				placeholder="Recherche..."
				onChange={(e) => {
					e.target.value = e.target.value.replace(";", "");
					setSearch(e.target.value);
				}}
			/>
		</div>
	);
}
