import { Icon } from "@iconify/react";
import React from "react";
import { Tooltip } from "react-tooltip";

export const CardAction = ({
	cardId,
	icon,
	onActionClick,
	actionName,
}: {
	cardId: number;
	icon: string;
	onActionClick: () => void;
	actionName: string;
}) => {
	return (
		<>
			<div
				className="flex items-center h-full text-2xl text-white cursor-pointer"
				onClick={onActionClick}
			>
				<Icon
					icon={icon}
					id={`card-action-${actionName.replaceAll(" ", "-")}-${cardId}`}
				/>
			</div>
			<Tooltip
				place={"top"}
				anchorSelect={`#card-action-${actionName.replaceAll(
					" ",
					"-",
				)}-${cardId}`}
				className={"z-50"}
			>
				{actionName}
			</Tooltip>
		</>
	);
};
