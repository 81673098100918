import { GlobalContext } from "@navigation/Router";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import { Form } from "./Form";
import { Personnalisation } from "./Personnalisation";

export function Layout({ loadingState, newResourceProps, resourceId }) {
	const { newResource, setNewResource } = newResourceProps;
	const { user } = useContext(GlobalContext);
	const [promoSelected, setPromoSelected] = useState([]);
	const [illustration, setIllustration] = useState();

	return (
		<div className="layout-new-resources flex flex-col gap-sm lg:flex-row">
			<div className="h-fit-content part w-4/4 md:w-8/8 lg:w-8/12">
				<Form
					loadingState={loadingState}
					userId={user?.id}
					resourceId={resourceId}
					newResourceProps={{ newResource, setNewResource }}
					promoSelectedProps={{ promoSelected, setPromoSelected }}
					illustrationProps={{ illustration, setIllustration }}
				/>
			</div>
			<div className="w-4/4 md:w-8/8 pr-0 lg:w-4/12">
				<div className="part md:mb-md">
					<Personnalisation
						resourceId={resourceId}
						newResourceProps={{ newResource, setNewResource }}
						illustrationProps={{ illustration, setIllustration }}
					/>
				</div>
			</div>
		</div>
	);
}
Layout.propTypes = {
	loadingState: PropTypes.shape({
		loading: PropTypes.bool,
		setLoading: PropTypes.func,
	}).isRequired,
	newResourceProps: PropTypes.shape({
		newResource: PropTypes.shape({
			illustration: PropTypes.shape({}),
		}),
		setNewResource: PropTypes.func,
	}).isRequired,
	resourceId: PropTypes.string.isRequired,
};
