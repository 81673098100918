import type { LogType } from "@/types/logTypes";
import { format, isSameDay, parseISO } from "date-fns";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";

import { Spinner } from "@components/atoms/Spinner";

import { Log } from "./Results/Log";

type ResultsProps = {
	logs: LogType[];
	updateLogs: Function;
	pageState: any;
	lastPage: number;
	setShowStudent: Function;
	selectedStudentState: any;
	filters: any;
	inRequestProps: any;
};
export function Results({
	logs,
	updateLogs,
	pageState,
	lastPage,
	setShowStudent,
	selectedStudentState,
	filters,
	inRequestProps,
}: ResultsProps) {
	const { inRequest, setInRequest } = inRequestProps;
	const { page } = pageState;

	const randomColor = (stepIndex: number) => {
		switch (stepIndex) {
			case 0:
				return "bg-accent-1-lighter";
			case 1:
				return "bg-accent-2-lighter";
			case 2:
				return "bg-accent-3-lighter";
			case 3:
				return "bg-accent-4-lighter";
			case 4:
				return "bg-secondary-50";
			case 5:
				return "bg-info-light";
			default:
				return "bg-primary-150";
		}
	};

	const dateTag = (log: LogType, previousLog: LogType) => {
		if (previousLog) {
			return (
				typeof log?.createdAt === "string" &&
				typeof previousLog?.createdAt === "string" &&
				!isSameDay(
					new Date(log?.createdAt),
					new Date(previousLog?.createdAt),
				) && (
					<div
						className={`mb-sm mt-lg w-fit rounded-full px-md py-xxsm ${randomColor(
							Math.floor(
								Number.parseInt(format(parseISO(log?.createdAt), "d")) / 5,
							),
						)}`}
					>
						<p>{format(parseISO(log?.createdAt), "P")}</p>
					</div>
				)
			);
		}
		return (
			typeof log?.createdAt === "string" && (
				<div
					className={`mb-sm mt-lg w-fit rounded-full px-md py-xxsm ${randomColor(
						Math.floor(
							Number.parseInt(format(parseISO(log?.createdAt), "d")) / 5,
						),
					)}`}
				>
					<p>{format(parseISO(log?.createdAt), "P")}</p>
				</div>
			)
		);
	};

	return (
		<div className="results">
			{logs?.length === 0 &&
				(filters?.length > 1 ? (
					<p className="text-center text-primary-300">
						Aucun résultat ne correspond à votre recherche.
					</p>
				) : (
					<p className="text-center text-primary-300">
						Aucune activité disponible pour le moment.
					</p>
				))}
			<InfiniteScroll
				pageStart={1}
				loadMore={() => {
					if (!inRequest) {
						setInRequest(true);
						updateLogs({ name: "page", value: page + 1 }, true);
					}
				}}
				useWindow={false}
				hasMore={page < lastPage}
				loader={
					<div className="mt-sm text-center" key={0}>
						<Spinner />
					</div>
				}
			>
				{logs?.map((log, index: number) => {
					return (
						<div className="mb-md">
							{dateTag(log, logs[index - 1])}
							<Log
								log={log}
								selectedStudentState={selectedStudentState}
								setShowStudent={setShowStudent}
							/>
						</div>
					);
				})}
			</InfiniteScroll>
		</div>
	);
}
