import { useEffect, useState } from "react";

import API from "@tools/API";

interface Response {
	error: string | null;
	goals: any;
	loading: boolean;
}

export function useGoals(studentId: any, defaultGoals: any): Response {
	const [response, setResponse] = useState<Response>({
		error: null,
		loading: true,
		goals: defaultGoals || null,
	});

	const query = {
		query: `
    query {
      goals(spsStudent:"${studentId}", isArchived: false, itemsPerPage:3, order: [{id: "DESC"}]){ 
        collection{
            id
            title
            studentComment
            completionDate
            isCompleted
            deadline
        }
      }
    }
    `,
	};

	const fetchGoals = async () => {
		try {
			const { data } = await API.post("graphql", query);

			setResponse({
				goals: data?.data?.goals?.collection,
				loading: false,
				error: null,
			});
		} catch (error) {
			setResponse({
				goals: null,
				loading: false,
				error: error.message,
			});
		}
	};

	useEffect(() => {
		fetchGoals();
	}, [studentId]);

	return response;
}
