import type { EntCompanyType } from "@/types/EntCompanyTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import { Formik, type FormikErrors } from "formik";
import React, { useContext, useState } from "react";

import {
	ORGANIZATIONS_SECTORS,
	ORGANIZATIONS_SIZE_OPTIONS,
} from "@constants/Organizations";

import { checkSiren, checkSiret } from "@tools/Checkers";

import { pinOutline } from "@assets/Icons";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Spinner } from "@components/atoms/Spinner";
import TooltipContainer from "@components/atoms/TooltipContainer";
import TooltipElement from "@components/atoms/TooltipElement";
import { ComboboxSearchAddress } from "@components/molecules/ComboboxSearchAddress";
import { FileInput } from "@components/molecules/FileInput";
import { FilesPrimary } from "@components/molecules/Files";
import { Input } from "@components/molecules/Input";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import type { AppState } from "@config/redux/store";
import {
	createCompany,
	sendFile,
} from "@containers/auth/RegisterCompany/RegisterCompanyAPI";
import { createProfileApi } from "@containers/company/Layout/LayoutAPI";

type EntrepriseFormType = {
	setHasErrors: (e: boolean) => void;
	goNext: () => void;
	siret: string;
	loading: boolean;
};
export function CompanyForm({
	setHasErrors,
	loading,
	goNext,
	siret,
}: EntrepriseFormType) {
	const [logoLoading, setLogoLoading] = useState(false);
	const [logoError, setLogoError] = useState(false);
	const [createLoading, setCreateLoading] = useState(false);

	const { user, setUser } = useContext(GlobalContext);
	const { school } = useAppSelector((state: AppState) => state.company);

	type ValuesType = Partial<EntCompanyType> & {
		city?: string;
		address?: string;
		postalCode?: string;
		longitude?: number;
		latitude?: number;
	};

	const handleUpload = async (
		e: File[],
		setFieldError: (field: string, value?: string) => void,
		setFieldValue: (
			field: string,
			value?: string | Partial<MediaObjectType> | null,
		) => void,
	) => {
		if (createLoading) return;

		const errorMessage =
			"Le fichier envoyé dépasse la limite de 1Mo ou n'est pas sous format accepté";

		setFieldError("logo", undefined);

		if (e !== undefined) {
			const fileData = new FormData();
			fileData.append("file", e[0]);
			fileData.append("type", "ent_company_logo");

			try {
				setLogoLoading(true);
				const file = (await sendFile(fileData)) as Partial<MediaObjectType>;
				setFieldValue("logo", file);
			} catch {
				setFieldError("logo", errorMessage);
			} finally {
				setLogoLoading(false);
			}
		} else {
			setFieldError("logo", errorMessage);
		}
	};

	const submitCompany = async (values: ValuesType) => {
		if (!user) return;
		const dataToSend = { ...values };
		dataToSend.createdBy = user?.id;
		if (values.logo) {
			// @ts-ignore
			dataToSend.logo = values.logo["@id"];
		}

		setCreateLoading(true);
		try {
			const { data } = await createCompany(dataToSend, user.id);
			if (data.alreadyExists) {
				ErrorToast("Ce numéro SIRET/SIREN est déjà attribué.");
				return;
			}
			const newCompanyId = data.data.createEntCompany.entCompany.id;
			const newUser = { ...user };
			newUser.entCompany = data.data.createEntCompany.entCompany;
			setUser(newUser);
			await createProfileApi(school?.id, newCompanyId);
			goNext();
		} catch {
			ErrorToast("Une erreur est survenue");
		} finally {
			setCreateLoading(false);
		}
	};

	const hasMissingValues = (values: ValuesType) => {
		return (
			!values.name ||
			!values.city ||
			!values.address ||
			!values.postalCode ||
			!values.longitude ||
			!values.latitude ||
			!values.siret ||
			!values.businessSector ||
			!values.type
		);
	};

	return (
		<Formik
			initialValues={{
				name: "",
				city: "",
				address: "",
				postalCode: "",
				siret,
				businessSector: "",
				type: "",
				logo: null,
			}}
			onSubmit={async (values: ValuesType) => await submitCompany(values)}
			validate={(values: ValuesType) => {
				if (loading) return;
				const errors: {
					name?: string;
					address?: string;
					siret?: string;
					businessSector?: string;
					type?: string;
					logo?: string;
				} = {};
				if (!values.name) {
					errors.name = "Veuillez entrer un nom d'entreprise";
				}

				if (
					!values.city ||
					!values.address ||
					!values.postalCode ||
					!values.longitude ||
					!values.latitude
				) {
					errors.address = "Veuillez entrer une adresse";
				}
				if (
					!values.siret ||
					(!checkSiret(values.siret) && !checkSiren(values.siret))
				) {
					errors.siret = "Veuillez entrer un siret/siren valide";
				}
				if (!values.businessSector) {
					errors.businessSector =
						"Veuillez indiquer le secteur d'activité de votre entreprise";
				}
				if (!values.type) {
					errors.type = "Veuillez indiquer le type de votre entreprise";
				}
				setHasErrors(Object.values(errors).length > 0);
				return errors;
			}}
		>
			{({
				values,
				handleChange,
				handleSubmit,
				setFieldValue,
				errors,
				setFieldError,
			}) => (
				<form
					id="form-login"
					className="mx-auto mb-lg w-full"
					onSubmit={handleSubmit}
				>
					{loading ? (
						<div className="mx-auto mt-lg flex w-full flex-col items-center">
							<Spinner />
							<p className="mt-2 text-xxxsm text-primary-500">
								Veuillez patienter...
							</p>
						</div>
					) : (
						<>
							<div className="rounded-md bg-white p-sm shadow-md">
								<p className="font-bold">Votre entreprise</p>
								<div className="ml-0 mt-lg w-full">
									<Input
										disabled={createLoading}
										label="Entreprise*"
										onChange={handleChange}
										value={values.name}
										name="name"
										type="text"
										placeholder="Nom de votre entreprise"
									/>
									<ComboboxSearchAddress
										icon={pinOutline}
										className="address mt-sm items-center"
										label="Adresse*"
										placeholder="Ex : 5 rue Rivoli"
										name="address"
										value={values.address as string}
										disabled={createLoading}
										onBlur={({
											streetNumber,
											streetName,
											city,
											postalCode,
											searchValue,
											longitude,
											latitude,
										}) => {
											setFieldValue(
												"address",
												streetNumber?.longName && streetName?.longName
													? `${streetNumber?.longName} ${streetName?.longName}`
													: searchValue,
											);

											setFieldValue("city", city?.longName);
											setFieldValue("postalCode", postalCode?.longName);
											setFieldValue("longitude", longitude);
											setFieldValue("latitude", latitude);
										}}
										onChange={({
											streetNumber,
											streetName,
											city,
											postalCode,
											searchValue,
											longitude,
											latitude,
										}) => {
											setFieldValue(
												"address",
												streetNumber?.longName && streetName?.longName
													? `${streetNumber?.longName} ${streetName?.longName}`
													: searchValue,
											);

											setFieldValue("city", city?.longName);
											setFieldValue("postalCode", postalCode?.longName);
											setFieldValue("longitude", longitude);
											setFieldValue("latitude", latitude);
										}}
									/>
									<Input
										disabled={createLoading}
										className="mt-sm"
										label="Numéro de SIRET/SIREN*"
										onChange={(e) =>
											setFieldValue("siret", e.target.value.replaceAll(" ", ""))
										}
										value={(values.siret as string)?.replaceAll(" ", "")}
										name="siret"
										type="text"
										placeholder="SIRET/SIREN de votre entreprise"
									/>
									<div className="mt-sm flex justify-between">
										<div className="w-1/2 pr-sm">
											<SelectTertiary
												disabled={createLoading}
												label="Secteur d'activité*"
												className="w-full"
												placeholder="Choisissez..."
												options={ORGANIZATIONS_SECTORS}
												onChange={(e) => {
													setFieldValue("businessSector", e.value);
												}}
											/>
										</div>
										<div className="w-1/2 pl-sm">
											<SelectTertiary
												disabled={createLoading}
												label="Taille de l'entreprise*"
												className="w-full"
												placeholder="Choisissez..."
												options={ORGANIZATIONS_SIZE_OPTIONS}
												onChange={(e) => {
													setFieldValue("type", e.value);
												}}
											/>
										</div>
									</div>
									<p className="mb-xsm mt-sm text-xxsm font-normal text-primary-500">
										Logo
									</p>
									{values.logo &&
									typeof values.logo !== "string" &&
									values.logo.filePath ? (
										<FilesPrimary
											isLoading={logoLoading}
											title={values.logo.filePath}
											subtitle={`${values.logo.size / 1000} Ko`}
											onView={() => {
												window.open(
													`${import.meta.env.VITE_S3}${
														typeof values.logo !== "string" &&
														values.logo?.filePath
													}`,
												);
											}}
											onDelete={async () => {
												if (createLoading) return;
												await setFieldValue("logo", null);
											}}
										/>
									) : (
										<FileInput
											isLoading={logoLoading}
											onSuccess={async (e: File[]) => {
												setLogoError(false);
												await handleUpload(e, setFieldError, setFieldValue);
											}}
											onError={() => {
												setLogoError(true);
											}}
											error={logoError}
											maxSize={1}
											id="logo"
											acceptFormats={["png", "jpg", "jpeg"]}
										/>
									)}

									<p className="text-label-size pt-xxsm text-error-dark">
										{errors.logo}
									</p>
								</div>
							</div>
							{!createLoading ? (
								<div className="flex gap-sm mt-sm justify-center">
									<ButtonPrimary
										onClick={undefined}
										id="register-btn"
										name="submit"
										disabled={hasMissingValues(values) || logoLoading}
										type="submit"
										label="Suivant"
									/>
									{hasMissingValues(values) ? (
										<>
											<TooltipContainer
												anchorId="#register-indicator"
												makeClickable={false}
												place="top"
											>
												<div className="flex flex-col">
													{Object.keys(errors).map((e) => {
														return (
															<span key={`error-${e}`}>
																{`- ${errors[e as keyof FormikErrors<ValuesType>]}`}
															</span>
														);
													})}
												</div>
											</TooltipContainer>
											<TooltipElement id="register-indicator" type="alert" />
										</>
									) : null}
								</div>
							) : (
								<div className="flex w-full mt-sm">
									<Spinner size="small" className="mx-auto" />
								</div>
							)}
						</>
					)}
				</form>
			)}
		</Formik>
	);
}
