import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { CheckboxLabelPrimary } from "@components/molecules/CheckboxLabel";
import { Modal } from "@components/organisms/modal/Modal";
import React from "react";

const DOCUMENT_LINK = "https://app.mentorgoal.com/school-resources/117";

type SaveApplicationModalType = {
	triggerDontShowAgain: (status: boolean) => void;
	triggerApproved: () => void;
	triggerClose: () => void;
	showModal: boolean;
	companyName: string;
};
export function SaveApplicationModal({
	triggerDontShowAgain,
	triggerApproved,
	triggerClose,
	showModal,
	companyName,
}: SaveApplicationModalType) {
	const handleClickOnLink = () => {
		window.open(DOCUMENT_LINK, "_blank");
	};

	return (
		<Modal
			show={showModal}
			title="Bon à savoir"
			body={
				<div className="flex flex-col gap-md">
					<p>
						{`En cliquant ici, tu ajouteras simplement l'entreprise ${companyName} à ta liste de candidatures en préparation. C'est à toi
						d'envoyer ta candidature spontanée à l'entreprise.`}
					</p>
					<p className="flex flex-col justify-start gap-xxsm">
						<span>
							Pour t’aider, un guide est mis à ta disposition dans les
							ressources:
						</span>
						<InlineButton
							id="guideCandidatureSpontanee"
							label="👉​ Guide d'une candidature spontanée réussie"
							onClick={handleClickOnLink}
						/>
					</p>
				</div>
			}
			onClose={triggerClose}
			buttonsRight={[
				<ButtonPrimary
					label="J'ai compris"
					onClick={triggerApproved}
					key={"closeSaveApplicationModal"}
				/>,
			]}
			buttonsLeft={[
				<CheckboxLabelPrimary
					label="Ne plus afficher ce message"
					key={"dontShowAgain"}
					onCheck={triggerDontShowAgain}
					checked={false}
				/>,
			]}
		/>
	);
}
