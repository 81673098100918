import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

import "@assets/sass/ChartDoughnut.scss";

ChartJS.register(ArcElement, Tooltip, Legend);

type ChartDoughnutPropsType = {
	data: { color: string; value: any }[];
	topTitle?: string;
	midTitle?: number;
	bottomTitle?: string;
};
export function ChartDoughnut({
	data,
	topTitle = "",
	midTitle = 0,
	bottomTitle = "",
}: ChartDoughnutPropsType) {
	const dataElement = {
		datasets: [
			{
				data: data.map((elem) => {
					return elem.value;
				}),
				backgroundColor: [
					data[0]?.color || "#466a8b",
					data[1]?.color || "#7fc4bc",
					data[2]?.color || "#f4a261",
					data[3]?.color || "#e76f51",
					data[4]?.color || "#f1d99e",
				],
				borderWidth: 0,
			},
		],
	};

	const options = {
		cutout: "78%",
		plugins: {
			legend: { display: false },
			tooltip: {
				fontSize: 50,
				mode: "point" as const,
				backgroundColor: "#2a4054",
				displayColors: false,
				callbacks: {
					label: (tooltipItem: any) =>
						tooltipItem.dataset.data[tooltipItem.dataIndex],
				},
			},
		},
	};

	return (
		<div className="doughnut-chart-container">
			<Doughnut data-id="doughnutChart" data={dataElement} options={options} />
			<div className="doughnut-chart-text">
				<p>{topTitle}</p>
				<p>{midTitle}</p>
				<p>{bottomTitle}</p>
			</div>
		</div>
	);
}
