import React from "react";
import Skeleton from "react-loading-skeleton";

export function DefaultGoalsSkeleton() {
	return (
		<div className="mt-sm">
			{Array.from({ length: 3 }).map(() => {
				return (
					<div className="flex flex-col gap-xxsm">
						<div className="items-center flex justify-between">
							<div className="items-center flex">
								<p>
									<Skeleton width={184} />
								</p>
							</div>
							<p>
								<Skeleton width={32} />
							</p>
						</div>
						<Skeleton height={8} />
					</div>
				);
			})}
		</div>
	);
}
