import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { arrowBackOutline } from "@assets/Icons";

import { checkEmail } from "@tools/Checkers";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { Spinner } from "@components/atoms/Spinner";
import { Input } from "@components/molecules/Input";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";

import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import type { CompanySliceType } from "@containers/company/slice/companySlice";
import { mailChangePassword } from "../ForgotPassword/ForgotPasswordAPI";

export function CompanyForgotPassword() {
	const [mailSent, setMailSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [accountValidation, setAccountValidation] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { school } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const emailFromUrl = searchParams.get("email");
		if (searchParams.get("email")) {
			setEmail(emailFromUrl as string);
		}
		if (location.pathname.includes("set_password")) {
			setAccountValidation(true);
		}
	}, []);
	const sendMail = async (setEmail: string = email) => {
		setLoading(true);
		try {
			if (!school?.id) return;
			await mailChangePassword(setEmail, school.id);

			setMailSent(true);
		} catch {
			ErrorToast(
				"Une erreur est survenue : cette adresse e-mail n'est pas assignée",
			);
		} finally {
			setLoading(false);
		}
	};

	const getContent = () => {
		return (
			<div className="mx-auto mt-sm w-11/12 md:w-10/12 lg:w-8/12">
				{!mailSent ? (
					<InlineButton
						disabled={loading}
						className="mb-sm"
						label="Retour"
						icon={arrowBackOutline}
						onClick={() => {
							navigate(`/company?${school?.subdomain || ""}`);
						}}
					/>
				) : null}
				<div className="mb-lg rounded-md bg-white p-lg shadow-md">
					{!mailSent ? (
						<>
							{!accountValidation && (
								<>
									<p className="mb-lg font-bold">Mot de passe oublié ?</p>
									<p className="mb-sm mt-md text-xxsm text-primary-300 md:text-left">
										Entrez votre adresse e-mail ci-dessous pour débuter la
										réinitialisation du mot de passe.
									</p>
								</>
							)}
							{!loading ? (
								<>
									<Input
										// errorMessage={errors.email}
										label="Email"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										name="email"
										type="email"
										placeholder="Votre adresse e-mail"
									/>

									<ButtonPrimary
										id="register"
										name="submit"
										disabled={!email || !email.length || !checkEmail(email)}
										onClick={() => {
											sendMail();
										}}
										className="mx-auto mt-md"
										type="submit"
										label="Envoyer"
									/>
								</>
							) : (
								<div className="mt-md flex">
									<Spinner className="mx-auto" />
								</div>
							)}
						</>
					) : (
						<>
							<p className="mb-lg font-bold">E-mail envoyé !</p>
							<p className="text-center">
								Consultez votre boite mail et suivez les instructions de
								{accountValidation ? " validation" : " réinitialisation"}.
							</p>
						</>
					)}
				</div>
			</div>
		);
	};

	return getContent();
}
