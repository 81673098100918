import type { FormErrorType } from "@/types/genericType";
import { useNavigate } from "react-router";

export function manageErrors(error: any) {
	const navigate = useNavigate();
	const statusCode = error?.response?.status;

	switch (statusCode) {
		case 404:
			navigate("/400");
			break;
		case 403:
			navigate("/403");
			break;
		case 500:
			navigate("/500");
			break;
		default:
			break;
	}
}

export function getTextError(codeError: number) {
	if (!codeError) {
		return "";
	}
	switch (codeError) {
		case 401:
			return "Email ou mot de passe incorrect.";
		case 404:
			return "La page n'a pas été trouvée..";
		case 500:
			return "Une erreur est survenue, veuillez réessayez plus tard.";
		default:
			return "Nous ne connaissons pas la cause du problème.. Veuillez réessayer plus tard.";
	}
}

export const buildTooltipFromStackErrors = (errors: FormErrorType[]) => {
	if (!errors) return "";
	let tooltip = "";
	errors?.forEach((error) => {
		tooltip += `- ${error.errorMessage}\n`;
	});
	return tooltip;
};
