import type { NoteType } from "@/types/notesType";
import type { UserType } from "@/types/userTypes";
import React, { useState } from "react";

import { moreHorizontalOutline } from "@assets/Icons";

import { formatDate } from "@tools/Dates";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { Divider } from "@components/atoms/Divider";
import { TextArea } from "@components/atoms/TextArea";
import { Dropdown } from "@components/molecules/Dropdown";
import { AccordionPrimarySmall } from "@components/organisms/Accordion";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import {
	createEngNote,
	deleteEngNote,
	editEngNote,
	fetchStudentNotes,
} from "@containers/school/Students/StudentDetail/StudentDetailAPI";

import { DeleteNoteModal } from "./Notes/DeleteNoteModal";
import { ManageNoteModal } from "./Notes/ManageNoteModal";

type NotesProps = {
	student: UserType;
	setStudent: Function;
};

type ValuesType = {
	note: string;
};
const emptyNote = {
	id: "",
	datetime: "",
	content: "",
};
export function Notes({ student, setStudent }: NotesProps) {
	const [showManageNoteModal, setShowManageNoteModal] =
		useState<boolean>(false);
	const [showDeleteNoteModal, setShowDeleteNoteModal] =
		useState<boolean>(false);
	const [noteContentToCreate, setNoteContentToCreate] = useState<string>("");
	const [noteCreationError, setNoteCreationError] = useState<string>("");
	const [selectedNote, setSelectedNote] = useState<NoteType>(emptyNote);

	const handleEditNoteResponse = async (response: any, successMessage: any) => {
		if (response.error) {
			ErrorToast("Une erreur s'est produite, veuillez réessayer");
		}
		if (response.engNote) {
			Success(successMessage);
		}
		const actualisedNotes = await fetchStudentNotes(student.id);
		setStudent({ ...student, engNotes: actualisedNotes });
	};

	const newNote = async (values: ValuesType) => {
		const inputs = {
			spsStudent: student.id,
			datetime: new Date().toISOString(),
			content: values.note,
		};

		const createEngNoteResponse = await createEngNote(inputs);
		handleEditNoteResponse(createEngNoteResponse, "La note a bien été créée");
	};

	const editNote = async (id: string, content: string) => {
		const inputs: Partial<NoteType> = {
			id: id,
			content: content,
			datetime: new Date().toISOString(),
		};

		const editEngNoteResponse = await editEngNote(inputs);
		handleEditNoteResponse(editEngNoteResponse, "La note a bien été modifiée");
		setSelectedNote({ id: id, datetime: "", content });
	};

	const deleteNote = async (id: string) => {
		const editUserResponse = await deleteEngNote(id);
		handleEditNoteResponse(editUserResponse, "La note a bien été supprimée");
	};

	const handleCreateNote = () => {
		if (!noteContentToCreate) {
			return setNoteCreationError(
				"Veuillez remplir le champs afin d'ajouter une note",
			);
		}
		setNoteContentToCreate("");
		newNote({
			note: noteContentToCreate,
		});
	};

	const handleReset = () => {
		setNoteContentToCreate("");
		setNoteCreationError("");
	};

	return (
		<div className="notes-container mb-2 mt-lg">
			<div className="flex flex-col gap-md">
				<TextArea
					value={noteContentToCreate}
					onChange={(noteContent) => {
						setNoteContentToCreate(noteContent);
					}}
					errorMessage={noteCreationError}
					placeholder="Ecrivez votre note ici..."
					height="30vh"
				/>
				<div className="button-form w-fit-content ml-auto mt-sm flex">
					<ButtonQuaternary
						onClick={handleReset}
						label="Réinitialiser"
						className="w-fit-content reset-responsive mr-sm"
					/>
					<ButtonPrimary
						type="submit"
						onClick={handleCreateNote}
						label="Ajouter"
						className="w-fit-content add-responsive"
					/>
				</div>
			</div>
			<p className="text-sm font-bold text-primary-700P">Toutes les notes</p>
			<div className="mobile-container action-flow mt-sm">
				<div className="pl-sm pr-sm">
					<Divider className="" />
				</div>
				{!student.engNotes.collection?.length ? (
					<p className="subtitle-p my-4 text-primary-200">
						Aucune note renseignée
					</p>
				) : (
					""
				)}
				{student.engNotes.collection?.map((note, index) => {
					const date = formatDate(note?.datetime, "dd/mm/yyyy");
					return (
						<span
							id={`note-${note?.id}${note?.datetime}` || index.toString()}
							key={note?.id + note?.datetime || index}
						>
							<AccordionPrimarySmall
								items={student.engNotes.collection}
								label={date}
								title="Note ajoutée"
								subtitle={note.content}
								translate={false}
								content={note.content}
								parentID={
									`note-${note?.id}${note?.datetime}` || index.toString()
								}
								button={
									<Dropdown
										direction="left"
										items={[
											{
												label: "Modifier",
												action: () => {
													setSelectedNote(note);
													setShowManageNoteModal(!showManageNoteModal);
												},
											},
											{
												label: "Supprimer",
												action: () => {
													setSelectedNote(note);
													setShowDeleteNoteModal(!showDeleteNoteModal);
												},
											},
										]}
										button={
											<ButtonQuaternary
												className="small"
												dropdownButton
												icon={moreHorizontalOutline}
											/>
										}
									/>
								}
							/>
						</span>
					);
				})}
			</div>
			<ManageNoteModal
				note={selectedNote}
				show={showManageNoteModal}
				onDelete={() => {
					setShowDeleteNoteModal(!showDeleteNoteModal);
				}}
				onClose={() => {
					setShowManageNoteModal(!showManageNoteModal);
				}}
				onApply={editNote}
			/>
			<DeleteNoteModal
				onDelete={deleteNote}
				note={selectedNote}
				onClose={() => {
					setShowDeleteNoteModal(!showDeleteNoteModal);
				}}
				show={showDeleteNoteModal}
			/>
		</div>
	);
}
