import React from "react";

import "./Interview.scss";

import { InputDatePicker } from "@components/molecules/InputDatePicker";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

export function InterviewInformation({ application }) {
	const interviewTypeOptions = [
		{ label: "Téléphone", value: "phone" },
		{ label: "Visio-conférence", value: "videoconference" },
		{ label: "Locaux de l'entreprise", value: "company_premises" },
	];

	return (
		<div className="w-full">
			<div className="flex flex-wrap sm:flex-nowrap">
				<div className="w-full ">
					<p className="mb-xxsm text-primary-500">Date de l&apos;entretien</p>
					<InputDatePicker
						disabled
						value={
							application?.interviewDate !== undefined
								? application.interviewDate
								: new Date()
						}
					/>
				</div>
			</div>
			<div className="flex">
				<div className="w-full">
					<p className="mb-xxsm mt-sm  text-primary-500">
						Type d&lsquo;entretien
					</p>

					<SelectTertiary
						disabled
						className="interview-type"
						placeholder="Choisissez un type d'entretien"
						defaultValue={interviewTypeOptions.find(
							(option) => option.value === application?.interviewLocation,
						)}
						options={interviewTypeOptions}
					/>
				</div>
			</div>
		</div>
	);
}
