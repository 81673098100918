import { useAppSelector } from "@config/redux/hook";
import { TYPE_OF_EVENT } from "@constants/Events";
import React from "react";

import { getTagColorFromEventType, isMentorGoalEvent } from "@tools/Events";

import type { BackgroundColorType } from "@/types/colorsType";
import { Badge } from "@components/atoms/Badge";
import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";

export function EventCloudTags() {
	const eventToCreate = useAppSelector((state) => state.events.eventToCreate);
	const isMentorGoalUser = useAppSelector(
		(state) => state.events.isMentorGoalUser,
	);
	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;

	const getCreationBackgroundColor = (type: string): BackgroundColorType => {
		return getTagColorFromEventType(type).bgColor;
	};

	const handleTagSelection = (typeSelected: string) => {
		dispatchEvent(
			actions.setEventToCreate({ ...eventToCreate, type: typeSelected }),
		);
	};
	return (
		<article data-testid="cloudOfTagsContainer" className="flex flex-col gap-6">
			<span className="text-sm font-bold text-primary-700P">
				Type d'évènement
			</span>
			<div className="flex flex-wrap gap-2" data-testid="cloudOfTags">
				{TYPE_OF_EVENT && TYPE_OF_EVENT.length > 0
					? TYPE_OF_EVENT.map((type) => (
							<div
								onClick={() => {
									handleTagSelection(type);
								}}
								key={type}
								className={`cursor-pointer ${
									isMentorGoalEvent(type)
										? isMentorGoalUser
											? ""
											: "hidden"
										: ""
								}`}
							>
								<Badge
									label={type}
									bgColor={getCreationBackgroundColor(type)}
									hasBorder={type === eventToCreate?.type}
								/>
							</div>
						))
					: null}
			</div>
		</article>
	);
}
