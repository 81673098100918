import type {
	ApplicationMaybeReasonType,
	ApplicationRejectReasonType,
	ApplicationStepType,
} from "@/types/applicationTypes";

export const APPLICATION_TYPE_OPTIONS = [
	{
		label: "Spontanée",
		value: "spontaneous",
	},
	{
		label: "Offre d'emploi",
		value: "jobOffer",
	},
	{
		label: "Approche réseau",
		value: "networkApproach",
	},
	{
		label: "Réseau école",
		value: "schoolNetwork",
	},
	{
		label: "Autre",
		value: "other",
	},
];

export const APPLICATION_STEP_OPTIONS: {
	indexColor: number;
	label: string;
	value: ApplicationStepType;
}[] = [
	{ label: "En préparation", value: "readyToApply", indexColor: 0 },
	{ label: "Candidature envoyée", value: "sent", indexColor: 1 },
	{ label: "Relance réalisée", value: "relaunch", indexColor: 2 },
	{ label: "Entretien obtenu", value: "interview", indexColor: 3 },
];

export const INTERVIEW_TYPE_OPTIONS = [
	{ label: "Téléphone", value: "phone" },
	{ label: "Visio-conférence", value: "videoconference" },
	{ label: "Locaux de l'entreprise", value: "company_premises" },
];

export const APPLICATION_MAYBE_REASON_OPTIONS = new Map<
	ApplicationMaybeReasonType,
	string
>([
	["updateCV", "Mettre à jour le CV"],
	["requestInformation", "Demande d'information"],
	["motivationLetter", "Lettre de motivation"],
	["geographicalArea", "Zone géographique"],
]);

export const APPLICATION_MAYBE_REASON_MAPPING = new Map<
	ApplicationMaybeReasonType,
	string
>([
	["updateCV", "Merci de mettre à jour votre CV."],
	["requestInformation", "Pouvez-vous préciser les informations suivantes :"],
	["motivationLetter", "Merci d'ajouter une lettre de motivation."],
	[
		"geographicalArea",
		"Merci de préciser votre zone géographique de recherche.",
	],
]);

export const APPLICATION_REJECT_REASON_MAPPING = new Map<
	ApplicationRejectReasonType,
	string
>([
	[
		"tooMuchApplications",
		"Non sélectionné en raison d'un grand nombre de candidats.<br />Nous avons reçu un grand nombre de candidatures pour un nombre de places en entretien limité. Malgré la qualité de votre candidature, elle n'a pas été sélectionnée.",
	],
	[
		"recrutmentStep",
		"L'entreprise à décidé d'avancer avec d'autres candidats.<br />Malheureusement, elle est désormais à l'étape suivante de son processus de recrutement et votre candidature n'a pas été retenue.",
	],
	[
		"offerNotCorresponding",
		"L'offre de l'entreprise ne correspond pas à votre profil, votre candidature n'a donc pas été sélectionnée.",
	],
	["offerProvided", "Malheureusement, l'offre est déjà pourvue."],
]);
