import { toCamel } from "./Strings";

export function howManyInObject(obj: object, value: any) {
	let count = 0;
	Object.values(obj).forEach((objValue) => {
		if (objValue === value) count += 1;
	});
	return count;
}

export function removeEmptyString(obj: { [key: string]: any }) {
	const newObj: { [key: string]: any } = {};
	Object.keys(obj).forEach((prop: string) => {
		if (obj[prop] === "") {
			newObj[prop] = null;
		} else {
			newObj[prop] = obj[prop];
		}
	});
	return newObj;
}

export function keysToCamel(object: object) {
	const n: { [key: string]: any } = {};

	Object.entries(object).map((items) => {
		if (items[0].includes("_")) n[toCamel(items[0])] = items[1];
		else n[items[0]] = items[1];
		return n;
	});

	return n;
}

export function isEmpty(obj: object) {
	return Object.keys(obj).length === 0;
}

export function stringifyRequestInputs(inputs: object) {
	return JSON.stringify(inputs).replace(/"([^"]+)":/g, "$1:");
}

export function stringifyRequestFilters(filters: object) {
	return JSON.stringify(filters)
		.replace(/"([^"]+)":/g, "$1:")
		.substring(1)
		.slice(0, -1);
}

export function removeUndefinedValue(obj: Object) {
	const newObject = Object.fromEntries(
		Object.entries(obj).filter(([, value]) => value !== undefined),
	);
	return newObject;
}

export function stringifyFileFormats(formats: object) {
	if (Array.isArray(formats)) {
		return `| Formats acceptés :${formats.map(
			(format: string) => ` .${format}`,
		)}`;
	}
	const keys = Object.keys(formats);
	const regex: RegExp = /^[-\w.]+\/[-\w.]+$/; // Regex teste si le format est un mime type (image/png par exemple)
	const mappedKeys = keys.map((key) => {
		if (regex.test(key)) {
			return `.${key.split("/")[1]}`;
		}
		return key;
	});
	return `| Format accepté : ${mappedKeys}`;
}

export function objectsAreEqual(
	obj1: { [key: string]: any },
	obj2: { [key: string]: any },
) {
	const keys1 = Object.keys(obj1);

	for (const key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
}
