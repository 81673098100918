import React, { type Dispatch } from "react";

import { InputLargePrimary } from "@components/molecules/InputLargePrimary";

function DeleteConfirmationModal({
	setConfirmDelete,
}: {
	setConfirmDelete: Dispatch<Uppercase<string>>;
}) {
	return (
		<div className="flex flex-col gap-4">
			<p className="text-sm font-semibold text-primary-700P">
				Êtes-vous sûr de vouloir supprimer définitivement cet évènement ?
			</p>
			<InputLargePrimary
				placeholder="Saisissez SUPPRIMER pour valider la suppression"
				type="text"
				onChange={(e) => {
					setConfirmDelete(e.target.value.toUpperCase());
				}}
			/>
		</div>
	);
}

export default DeleteConfirmationModal;
