import { Icon } from "@iconify/react";
import type { IconifyIcon } from "@iconify/types";
import React, { type MouseEventHandler } from "react";

import "@assets/sass/Button.scss";

type ButtonQuaternaryPropsType = {
	label: string;
	className: string;
	disabled: boolean;
	icon: string | IconifyIcon;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	dropdownButton: boolean;
	id: string;
	shape: string;
	activeIcon: string;
};

export function ButtonQuaternary({
	className,
	disabled,
	icon,
	activeIcon,
	onClick,
	label,
	dropdownButton,
	shape,
	id,
}: ButtonQuaternaryPropsType) {
	return (
		<button
			id={id}
			data-id="button-quaternary"
			type="button"
			data-toggle={dropdownButton && "dropdown"}
			aria-haspopup={dropdownButton && true}
			aria-expanded={dropdownButton && false}
			onClick={onClick}
			disabled={disabled}
			className={`no-print button-quaternary ${className} ${shape}`}
		>
			{icon && <Icon className="icon" icon={icon} />}
			{activeIcon && <Icon className="active-icon" icon={activeIcon} />}
			{label && <p className="pl-sm pr-sm">{label}</p>}
		</button>
	);
}

ButtonQuaternary.defaultProps = {
	className: "",
	disabled: false,
	activeIcon: null,
	icon: null,
	onClick: () => {},
	dropdownButton: false,
	label: "",
	shape: "rectangle",
	id: "",
};
