import API from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function editUser(inputs: object) {
	const data = {
		query: `mutation {
            updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
                user: spsStudent {
                    id
                    firstname
                    lastname
                    birthdate
                    avatar {
                        filePath
                    }
                }
            }
        }`,
	};
	const rep = await API.post("graphql", data);
	return rep.data.data.updateSpsStudent.user;
}
