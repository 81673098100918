import React, { useContext } from "react";

import {
	ColumnsContext,
	OnSlidingPanelCloseContext,
	UpdateProspectsColumnContext,
} from "../../index";
import type { ProspectType } from "../Kanban";

export const CurrentStep = ({ prospect }: { prospect: ProspectType }) => {
	const columns = useContext(ColumnsContext);
	const updateProspectsColumn = useContext(UpdateProspectsColumnContext);
	const onSlidingPanelClose = useContext(OnSlidingPanelCloseContext);
	return (
		<div className="flex flex-row justify-between p-4 items-center bg-blue-50 rounded-xl">
			<span className="text-sm font-bold">Étape de recrutement</span>
			<select
				className={
					"border border-gray-200 p-2 rounded-sm focus:outline-none focus:border-indigo-600 hover:border-indigo-600 focus:ring-1 focus:ring-indigo-600 ease-in-out duration-100 min-w-60"
				}
				value={prospect.columnId}
				onChange={(e) => {
					onSlidingPanelClose();
					updateProspectsColumn(
						prospect.id,
						Number(e.target.value),
						0,
						prospect.columnId,
					);
				}}
			>
				{columns.map((column) => (
					<option key={`COLUMN-${column.id}`} value={column.id}>
						{column.emoji} {column.title}
					</option>
				))}
			</select>
		</div>
	);
};
