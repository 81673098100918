import React, { useEffect, useState } from "react";

import { ChartDoughnut } from "@components/molecules/ChartDoughnut";
import { Legend } from "@components/molecules/Legend";

export function StudentsDistributionByLastConnexion({ structure }) {
	const [connexionDoughnut, setConnexionDoughnut] = useState({});

	const defineConnexionChart = () => {
		setConnexionDoughnut({
			week: structure.weeklyActiveUsers,
			month: structure.monthlyActiveUsers,
			other: structure.inactiveUsers,
			never: structure.neverConnectedUsers,
		});
	};

	useEffect(() => {
		if (structure) {
			defineConnexionChart();
		}
	}, [structure]);

	return (
		<div className="mt-sm flex h-full w-full flex-col justify-between rounded-lg shadow-sm bg-white p-4 md:mt-0 md:w-6/12">
			<p className="font-semifont-bold pb-sm text-xsm text-primary-700P">
				Répartition des étudiants à placer par date de dernière connexion
			</p>

			<div className="mt-4 flex flex-col items-center">
				<ChartDoughnut
					className="doughnut-responsive-items"
					topTitle="Sur"
					midTitle={structure.id ? structure.toPlace : 0}
					bottomTitle="Étudiants"
					data={[
						{ value: connexionDoughnut.week, color: "#7FC4BC" },
						{ value: connexionDoughnut.month, color: "#466A8B" },
						{ value: connexionDoughnut.other, color: "#F4A261" },
						{ value: connexionDoughnut.never, color: "#E76F51" },
					]}
				/>
				<Legend
					className="mt-3"
					direction="wrap"
					legend={[
						{ name: "- de 7 jours", color: "#7FC4BC" },
						{ name: "Entre 7 et 30 jours", color: "#466A8B" },
						{ name: "+ de 30 jours", color: "#F4A261" },
						{ name: "Jamais connecté", color: "#E76F51" },
					]}
				/>
			</div>
		</div>
	);
}
