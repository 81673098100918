import { useAppDispatch } from "@config/redux/store";
import { companiesManagementSlice } from "@containers/school/Companies/core/store/companiesManagement.slice";

export const useCompaniesTable = () => {
	const dispatch = useAppDispatch();

	const modifyItemPerPage = (itemsPerPage: number) => {
		dispatch(companiesManagementSlice.actions.setItemsPerPage(itemsPerPage));
	};

	const modifyCurrentPage = (pageToShow: number) => {
		dispatch(companiesManagementSlice.actions.setCurrentPage(pageToShow));
	};

	return {
		modifyItemPerPage,
		modifyCurrentPage,
	};
};
