import type { ViewType } from "@/types/genericType";
import { InlineIcon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import Tippy from "@tippyjs/react";
import { Formik, type FormikErrors } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";

import { infoOutline } from "@assets/Icons";

import {
	STUDENT_GENDER_OPTIONS,
	STUDENT_STATUS_OPTIONS,
} from "@constants/Users";

import { checkEmail, checkPhone } from "@tools/Checkers";
import { resolveView } from "@tools/Users";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Switch } from "@components/atoms/Switch";
import { ComboboxSearchAddress } from "@components/molecules/ComboboxSearchAddress";
import { Input } from "@components/molecules/Input";
import { InputPhoneNumber } from "@components/molecules/PhoneInput";
import { Modal } from "@components/organisms/modal/Modal";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

import {
	createUser,
	getPromotions,
	getSchoolSmsCredits,
} from "@containers/school/Students/StudentsManagement/StudentsManagementAPI";

import type { promotionType } from "@/types/promotionTypes";
import type { StandardGraphQLResponse } from "@tools/API";

interface FormValues {
	firstname: string;
	lastname: string;
	email: string;
	phone: string;
	gender: number | null;
	spsPromotion: string;
	status: number;
	address: string | null;
	streetNumber: string | null;
	streetName: string | null;
	city: string | null;
	postalCode: string | null;
	sendSMS: boolean;
	exportToGallia: boolean;
}

const INITIAL_VALUES = {
	firstname: "",
	lastname: "",
	email: "",
	phone: "",
	gender: -1,
	spsPromotion: "",
	status: 0,
	address: null,
	streetNumber: null,
	streetName: null,
	city: null,
	postalCode: null,
	sendSMS: false,
	exportToGallia: false,
} as FormValues;

type CreateStudentModalProps = {
	show: boolean;
	onClose: () => void;
	onCreateUserSuccess?: () => void;
	initialValues?: Partial<FormValues>;
};

interface PromotionOption extends promotionType {
	label: string;
	value: string;
}

type SchoolCredits = {
	school: {
		id: string;
		smsCredits: number;
	};
};

export function CreateStudentModal({
	show,
	onClose,
	onCreateUserSuccess,
	initialValues = {},
}: CreateStudentModalProps) {
	const { user } = useContext(GlobalContext);
	const { structureView }: { structureView: ViewType } = useOutletContext();
	const [promotions, setPromotions] = useState<PromotionOption[]>([]);
	const [defaultValues] = useState<FormValues>({
		...INITIAL_VALUES,
		...initialValues,
	});
	const [errorMail, setErrorMail] = useState(false);
	const [smsCredits, setSmsCredits] = useState(0);
	const [showGallia, setShowGallia] = useState(false);
	const navigate = useNavigate();

	const createNewUser = async (values: FormValues) => {
		const data = {
			firstname: values.firstname,
			lastname: values.lastname,
			email: values.email,
			phone: values.phone,
			spsPromotion: values.spsPromotion,
			status: values.status,
			address: values.address,
			streetNumber: values.streetNumber,
			streetName: values.streetName,
			city: values.city,
			postalCode: values.postalCode,
			sendSMS: values.sendSMS,
			exportToGallia: values.exportToGallia,
			gender: values.gender,
		};

		const response: StandardGraphQLResponse = await createUser(data);
		if (response.data.data.createSpsStudent === null) {
			setErrorMail(true);
			return;
		}
		if (onCreateUserSuccess) {
			onCreateUserSuccess();
			return;
		}
		navigate(
			`/students/${response?.data.data.createSpsStudent.user.id?.split("/")[3]}`,
			{
				state: {
					isCreateStudent: true,
				},
			},
		);
	};

	useEffect(() => {
		if (show) {
			getPromotions(resolveView(structureView)).then(
				({ collection }: { collection: promotionType[] }) => {
					setPromotions(
						collection.map((promotion: promotionType) => {
							return {
								...promotion,
								label: promotion.name,
								value: promotion.id,
							};
						}),
					);
				},
			);
			if (user?.school) {
				getSchoolSmsCredits(user.school).then((response: SchoolCredits) => {
					setSmsCredits(response.school.smsCredits);
				});
			}
		}
	}, [user, show]);
	return (
		<div>
			<Formik
				initialValues={defaultValues}
				validate={(values) => {
					const errors: FormikErrors<FormValues> = {};
					if (!values.firstname) {
						errors.firstname = "Le prénom de l'étudiant n'est pas renseigné";
					}
					if (!values.lastname) {
						errors.lastname = "Le nom de l'étudiant n'est pas renseigné";
					}
					if (!checkEmail(values.email)) {
						errors.email = "adresse mail invalide";
					}
					if (!values.email) {
						errors.email = "Veuillez remplir l'adresse email";
					}
					if (!checkPhone(values.phone) && values.sendSMS) {
						errors.phone = "Numéro de téléphone invalide";
					}
					if (!values.spsPromotion) {
						errors.spsPromotion = "Veuillez entrer une promotion";
					}
					if (values.gender === -1) {
						errors.gender = "Veuillez entrer un genre";
					}
					if (smsCredits === 0 && values.sendSMS) {
						errors.sendSMS =
							"Vous n'avez pas suffisamment de SMS disponibles pour notifier l'étudiant";
					}

					return errors;
				}}
				onSubmit={(values) => {
					createNewUser(values);
				}}
			>
				{({
					values,
					errors,
					touched,
					setFieldValue,
					handleChange,
					handleSubmit,
					resetForm,
				}) => (
					<Modal
						onClose={() => {
							onClose();
							resetForm({});
						}}
						show={show}
						title="Ajouter un étudiant"
						body={
							<section>
								<div className="flex items-center">
									<Input
										className="w-full"
										label="Prénom"
										placeholder="Ex : Julien"
										value={values.firstname.trim()}
										name="firstname"
										onChange={handleChange}
										errorMessage={(touched.firstname && errors.firstname) || ""}
									/>
									<Input
										className="ml-md w-full"
										label="Nom"
										placeholder="Ex : Dupont"
										value={values.lastname.trim()}
										name="lastname"
										onChange={handleChange}
										errorMessage={(touched.lastname && errors.lastname) || ""}
									/>
								</div>
								<div className="flex items-center">
									<Input
										className="mt-md w-full"
										label="Email"
										placeholder="Ex : juliendupont@gmail.com"
										value={values.email}
										name="email"
										onChange={(e) => {
											setErrorMail(false);
											setFieldValue("email", e.target.value, false);
										}}
										errorMessage={
											(touched.email && errors.email) ||
											(touched.email &&
												errorMail &&
												"Cette adresse mail est déjà associée à un compte étudiant dans votre école.") ||
											""
										}
									/>
									<InputPhoneNumber
										className="ml-md mt-md w-full"
										label="Téléphone"
										value={values.phone}
										required={values.sendSMS}
										onChange={(e) => {
											setFieldValue("phone", e);
										}}
										onBlur={(e) => {
											setFieldValue("phone", e);
										}}
									/>
								</div>
								<SelectTertiary
									className="h-fit-content mt-md"
									label="Genre"
									placeholder="Choisir un genre"
									onChange={(gender) => {
										setFieldValue("gender", gender.value);
									}}
									errorMessage={(touched.gender && errors.gender) || ""}
									options={STUDENT_GENDER_OPTIONS}
								/>
								<ComboboxSearchAddress
									className="address mt-md"
									label="Adresse"
									name="address"
									placeholder="Ex : 5 rue de Rivolis"
									value={values.address || ""}
									onChange={({
										streetNumber,
										streetName,
										city,
										postalCode,
										searchValue,
									}) => {
										setFieldValue("address", searchValue);
										setFieldValue("streetNumber", streetNumber?.longName);
										setFieldValue("streetName", streetName?.longName);
										setFieldValue("postalCode", postalCode?.longName);
										setFieldValue("city", city?.longName);
									}}
								/>

								<div className="aign-items-center mt-md flex gap-sm">
									<SelectTertiary
										truncate
										label="Promotion"
										placeholder="Choisir une promotion"
										className="w-6/12"
										options={promotions}
										onChange={(promo) => {
											setFieldValue("spsPromotion", promo.value);
											if (promo.campus.isGalliaEnabled) {
												setShowGallia(true);
											} else if (showGallia) {
												setShowGallia(false);
												setFieldValue("exportToGallia", false);
											}
										}}
										breakSpaces
									/>

									<SelectTertiary
										className="h-fit-content w-6/12"
										label="Statut"
										placeholder="À placer"
										onChange={(status) => {
											setFieldValue("status", status.value);
										}}
										options={STUDENT_STATUS_OPTIONS}
									/>
								</div>
								{errors.spsPromotion && touched.spsPromotion && (
									<p className="text-p ml-xxsm mt-sm text-xsm text-error-dark">
										{errors.spsPromotion}
									</p>
								)}
								<div className="mt-md flex items-center">
									<Switch
										checked={values.sendSMS}
										onDisabled={() => {
											setFieldValue("sendSMS", false);
										}}
										onEnabled={() => {
											setFieldValue("sendSMS", true);
										}}
									/>
									<p className="ml-xsm text-xsm text-primary-700P">
										Notifier l'étudiant par SMS
									</p>
								</div>
								<p className="mt-xxsm flex text-xxxsm text-primary-500">
									SMS disponible : {smsCredits}
									<Tippy
										content={
											<p>
												Les demandes de crédits SMS sont à adresser à{" "}
												<a
													className="font-bold underline"
													href="mailto:thomas@mentorgoal.com"
												>
													thomas@mentorgoal.com
												</a>
											</p>
										}
										theme="primary"
										placement="right"
										zIndex={5}
									>
										<span className="ml-xxsm cursor-pointer">
											<InlineIcon
												className="icon-sm text-primary-500"
												icon={infoOutline}
											/>
										</span>
									</Tippy>
								</p>
								{errors.sendSMS && (
									<p className="text-p color-error-dark mt-xxsm text-xsm">
										{errors.sendSMS}
									</p>
								)}
								{showGallia && (
									<div className="mt-md flex items-center">
										<Switch
											checked={values.exportToGallia}
											onDisabled={() => {
												setFieldValue("exportToGallia", false);
											}}
											onEnabled={() => {
												setFieldValue("exportToGallia", true);
											}}
										/>
										<p className="ml-xsm text-xsm text-primary-700P">
											Importer l'étudiant dans Gallia
										</p>
									</div>
								)}
							</section>
						}
						buttonsRight={[
							<ButtonSecondary
								key={0}
								label="Annuler"
								onClick={() => {
									resetForm({});
									onClose();
								}}
							/>,
							<ButtonPrimary
								key={1}
								label="Valider"
								onClick={() => {
									handleSubmit();
								}}
							/>,
						]}
					/>
				)}
			</Formik>
		</div>
	);
}
