import { getCookie } from "@tools/Cookies";
import axios from "axios";

export async function fetchProfiles() {
	const response = await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getCookie("mainToken")}`,
			},
		})
		.get("/user/retrieve_profiles");

	return response.data.profiles;
}
