import { type PointerEvent, useEffect } from "react";

type UseDoubleClickType = {
	ref: any;
	ignoreRefs?: any[]; // Array of refs to ignore
	latency?: number;
	onSingleClick?: (
		event: PointerEvent<HTMLButtonElement> | undefined,
	) => void | null;
	onDoubleClick?: (
		event: PointerEvent<HTMLButtonElement> | undefined,
	) => void | null;
};

/**
 * A React hook for differentiating single and double clicks on the same component using PointerEvents.
 *
 * @param {node} ref Dom node to watch for double clicks
 * @param {array} ignoreRefs Array of refs to ignore during click handling
 * @param {number} [latency=300] The amount of time (in milliseconds) to wait before differentiating a single from a double click
 * @param {function} onSingleClick A callback function for single click events
 * @param {function} onDoubleClick A callback function for double click events
 */
const useDoubleClick = ({
	ref,
	ignoreRefs = [],
	latency = 300,
	onSingleClick,
	onDoubleClick,
}: UseDoubleClickType) => {
	useEffect(() => {
		const clickRef = ref.current;
		let clickCount = 0;

		const handleClick = (e: PointerEvent) => {
			// Check if the event target is within any of the ignored refs
			const shouldIgnore = ignoreRefs.some((ignoreRef) =>
				ignoreRef.current?.contains(e.target as Node),
			);

			if (shouldIgnore) {
				return; // Ignore the click if it is within one of the ignored refs
			}

			clickCount += 1;

			setTimeout(() => {
				if (clickCount === 1 && onSingleClick)
					onSingleClick(e as PointerEvent<HTMLButtonElement>);
				else if (clickCount === 2 && onDoubleClick)
					onDoubleClick(e as PointerEvent<HTMLButtonElement>);

				clickCount = 0;
			}, latency);
		};

		// Add event listener for pointer events in the bubbling phase
		clickRef.addEventListener("click", handleClick);

		// Cleanup event listener on unmount
		return () => {
			clickRef.removeEventListener("click", handleClick);
		};
	}, [ref, ignoreRefs, latency, onSingleClick, onDoubleClick]);
};

export default useDoubleClick;
