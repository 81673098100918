import { useAppSelector } from "@config/redux/hook";
import React from "react";

import noDataFoundIllustration from "@assets/illustrations/noDataFound.svg";
import noSearchResultIllustration from "@assets/illustrations/noSearchResult.svg";

export function EmptyList() {
	const { currentSearch } = useAppSelector(
		(state) => state.companiesManagement,
	);
	return (
		<article
			data-testid="companiesEmptyView"
			className="
        col-span-full
        flex h-[60vh] w-full
       "
		>
			{currentSearch ? (
				<div className="flex w-full flex-col items-center justify-center gap-6">
					<p
						data-testid="noSearchResultMessage"
						className="text-base font-normal text-primary-300"
					>
						Aucun résultat ne correspond à votre recherche.
					</p>
					<img
						src={noSearchResultIllustration}
						alt="No search result"
						className="h-72 w-80"
					/>
				</div>
			) : (
				<div className="flex w-full flex-col items-center justify-center gap-6">
					<p
						data-testid="noSearchResultMessage"
						className="text-base font-normal text-primary-300"
					>
						Aucune entreprise pour l'instant
					</p>
					<img
						src={noDataFoundIllustration}
						alt="No data result"
						className="h-72 w-80"
					/>
				</div>
			)}
		</article>
	);
}
