import { Icon, type IconifyIcon, InlineIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React from "react";

import "@assets/sass/Stats.scss";

import { infoOutline } from "@assets/Icons";

type StatsSecondaryPropsType = {
	className?: string;
	icon: IconifyIcon;
	label?: string;
	value: string;
	color?: string;
	iconColor?: string;
	bgIcon: string;
	tippyContent: string;
};

export function StatsSecondary({
	label = "",
	icon,
	value,
	color = "primary-500",
	iconColor = "",
	bgIcon,
	className = "",
	tippyContent,
}: StatsSecondaryPropsType) {
	return (
		<div className={`stats-secondary-container ${className}`}>
			<div className="flex w-full items-center justify-end">
				<Tippy
					content={tippyContent}
					theme="primary"
					animation="fade"
					placement="top"
					className="text-left"
					zIndex={10}
				>
					<span className="text-xxxsm">
						<InlineIcon
							className="ml-xsm h-4 text-primary-200"
							icon={infoOutline}
						/>
					</span>
				</Tippy>
			</div>
			<div className="flex items-center">
				<div
					className={`mr-xsm flex items-center justify-center rounded-lg p-xxsm ${bgIcon}`}
				>
					<Icon className={`${iconColor} icon`} icon={icon} />
				</div>
				<p className={`${color} text-xsm font-bold`}>{label}</p>
			</div>
			<p className="mt-xxsm text-xxlg font-bold text-primary-700P">{value}</p>
		</div>
	);
}
