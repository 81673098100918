import type { ViewType } from "@/types/genericType";
import type { UserType } from "@/types/userTypes";

import { DEFAULT_MAX_DISTANCE } from "@constants/Location";

import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";

import api, {
	checkGraphQLError,
	graphqlRequestConstructor,
} from "../../../tools/API";

import type { structureViewType } from ".";

export const combineExists = (
	filters: any,
	forceCVExists: boolean | undefined = undefined,
) => {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const cvExists = "cvExists" in filters;
	const cvExistsValue = cvExists ? "value" in filters.cvExists : false;
	if (!cvExists && !forceCVExists && !birthdateExists) return "";
	if (forceCVExists !== undefined) {
		return `exists: {cv: ${forceCVExists}, ${
			birthdateExists ? "birthdate: false" : ""
		}}`;
	}
	return `exists: {
    ${cvExists && cvExistsValue ? `cv: ${filters?.cvExists.value},` : ""}
    ${birthdateExists ? "birthdate: false" : ""}
  }`;
};

export async function getCVs(filters: any, formatView: ViewType) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const filtersCopy = { ...filters };
	delete filtersCopy.state;

	if (filtersCopy.position && !filtersCopy.max_distance) {
		filtersCopy.max_distance = DEFAULT_MAX_DISTANCE;
	}

	if (filtersCopy.birthdate) {
		filtersCopy.birthdate = {
			...filtersCopy.birthdate.value,
		};
	}
	if (birthdateExists) {
		delete filtersCopy.birthdate;
	}
	delete filtersCopy?.cvExists;
	const urisFilters = { ...filtersCopy };
	delete urisFilters.page;

	const data = {
		query: `query {
        collectionQuerySpsStudents (${
					combineExists(filters) ? combineExists(filters) : ""
				} ${stringifyRequestFilters({
					...formatView,
					...filtersCopy,
				})}, itemsPerPage: 10) {
          collection {
            id
            avatar {
              filePath
            }
            birthdate
            city
            postalCode
            geoAreaOfResearch
            tags
            firstname
            lastname
            email
            promotion: spsPromotion  {
              name
            }
            linkedin
            lastConnexion
            phone
            cvRelaunchDate
            status
            cv {
              id
              filePath
              state
              updatedAt
              createdAt
            }
          },
          paginationInfo {
            lastPage
            totalCount
          }
        },
      }`,
	};
	return await graphqlRequestConstructor(data, "getCVs");
}

export async function getAllCVs(filters: any, formatView: ViewType) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const filtersCopy = { ...filters };
	delete filtersCopy.state;

	if (filtersCopy.position && !filtersCopy.max_distance) {
		filtersCopy.max_distance = DEFAULT_MAX_DISTANCE;
	}

	if (filtersCopy.birthdate) {
		filtersCopy.birthdate = {
			...filtersCopy.birthdate.value,
		};
	}
	if (birthdateExists) {
		delete filtersCopy.birthdate;
	}
	delete filtersCopy?.cvExists;
	const urisFilters = { ...filtersCopy };
	delete urisFilters.page;

	const data = {
		query: `query {
        collectionQuerySpsStudents (${
					combineExists(filters) ? combineExists(filters) : ""
				} ${stringifyRequestFilters({
					...formatView,
					...filtersCopy,
				})}, itemsPerPage: 100000, cvRelaunchDate: {before: "${new Date().toISOString()}"}) {
          collection {
            id
						email
            phone
            linkedin
            firstname
            cvRelaunchDate
            lastname
            avatar {
              filePath
            }
            promotion: spsPromotion  {
              name
            }
            cv {
              id
              filePath
            }
            birthdate
            address
          }
        },
      }`,
	};
	return await graphqlRequestConstructor(data, "getAllCVs");
}

export async function getTabCounts(filters: any, formatView: ViewType) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const filtersCopy = { ...filters };
	delete filtersCopy.state;

	if (filtersCopy.position && !filtersCopy.max_distance) {
		filtersCopy.max_distance = DEFAULT_MAX_DISTANCE;
	}

	if (filtersCopy.birthdate) {
		filtersCopy.birthdate = {
			...filtersCopy.birthdate.value,
		};
	}
	if (birthdateExists) {
		delete filtersCopy.birthdate;
	}
	delete filtersCopy?.cvExists;
	const data = {
		query: `query {
      all: collectionQuerySpsStudents (${stringifyRequestFilters({
				...formatView,
				...filtersCopy,
			})}, ${combineExists(filters, true)}) {
          paginationInfo {
            totalCount
          }
      },
      validated: collectionQuerySpsStudents (${stringifyRequestFilters({
				...formatView,
				...filtersCopy,
			})},${combineExists(filters)}, cv_state: "validated") {
        paginationInfo {
          totalCount
        }
      },
      toValidate: collectionQuerySpsStudents (${stringifyRequestFilters({
				...formatView,
				...filtersCopy,
			})},${combineExists(filters)}, cv_state: "toValidate") {
        paginationInfo {
          totalCount
        }
      },
      editing: collectionQuerySpsStudents (${stringifyRequestFilters({
				...formatView,
				...filtersCopy,
			})},${combineExists(filters)}, cv_state: "editing") {
        paginationInfo {
          totalCount
        }
      },
      without: collectionQuerySpsStudents (${stringifyRequestFilters({
				...formatView,
				...filtersCopy,
			})}, ${combineExists(filters, false)}) {
        paginationInfo {
          totalCount
        }
      }
    }`,
	};
	return await graphqlRequestConstructor(data, "getTabCounts");
}

export async function getStudents(view: structureViewType, name: string) {
	let rep = {};
	const data = {
		query: `query {
      collectionQuerySpsStudents (search: "${name}", ${stringifyRequestFilters(
				view,
			)}, page: 1, itemsPerPage: 5) {
        collection {
          id
          firstname
          lastname
          avatar {
            filePath
          }
        }
      },
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function sendFile(formdata: any) {
	let rep = {};

	await api.post("media_objects", formdata).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function editUser(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
            updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
                user: spsStudent {
                    id
                    firstname
                    lastname
                    cv {
                      id
                      filePath
                    }
                }
            }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateSpsStudent.user;
	});
	return rep;
}

export async function exportCVs(cvs: any) {
	let rep = {};
	await api.post("export_cv_zip", cvs).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function editMedia(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
      updateMediaObject(input: ${stringifyRequestInputs(inputs)}) {
        mediaObject {
          id
          filePath
          state
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function relaunchCVStudent(id: string) {
	let rep = {};

	await api.post(`relaunch_cv_mail/${id}`).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function relaunchCvsMultipleStudents(
	selectedStudents: Partial<UserType>[],
) {
	let rep = {};

	await api
		.post("relaunch_cvs_multiple_students", selectedStudents)
		.then(({ data }) => {
			rep = data.code;
		});
	return rep;
}

export async function createSelection(inputs: string) {
	const data = {
		query: `mutation {
            createSelection(input: ${inputs}) {
                selection {
                    id
                    name
                    token
                    sendDate
                    type
			  		sharedData
                    selectionUsers(itemsPerPage: 100) {
						collection {
							id
							user: spsStudent {
								id
								email
							}
						}
					}
                    crmCompany {
                   		name
                   	}
                }
            }
        }`,
	};

	return await graphqlRequestConstructor(data, "createSelection");
}

export async function updateSelection(inputs: string) {
	const data = {
		query: `mutation {
         updateSelection(input: ${inputs}) {
			selection {
			  id
			  name
			  sendDate
			  token
			  type
			  sharedData
			  selectionUsers (itemsPerPage: 1000) {
				collection {
				  user: spsStudent {
					id
				  }
				}
			  }
			  crmCompany {
          		name
              }
			}
          }
        }`,
	};
	return await graphqlRequestConstructor(data, "updateSelection");
}

export async function deleteFile(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
      deleteMediaObject(input: ${stringifyRequestInputs(inputs)}) {
        mediaObject {
          id
        }	
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function getSchoolSubDomain(school: string) {
	let rep = {};
	const data = {
		query: `query {
      school (id: "${school}") {
          id
          subdomain
      }
    }`,
	};
	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export const exportSelectionUsersFiles = async (
	userIds: string[],
	exportPortfolios = false,
	exportMotivationLetter = false,
) => {
	const data = {
		userIds,
		motivationLetter: exportMotivationLetter,
		portfolio: exportPortfolios,
	};
	return await api.post("export_selection_users_files", data);
};

export const sendSelectionByEmail = async (
	selectionId: string,
	email: string,
	ccs: string[],
	object: string,
	message: string,
) => {
	const data = {
		selectionId,
		email,
		ccs,
		object,
		message,
	};
	return await api.post("send_selection_by_email", data);
};
