import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";
import { deleteResource } from "../../ResourceEditorAPI";

export function DeleteResourceModal({ show, onClose, resourceId }) {
	const navigate = useNavigate();

	return (
		<Modal
			title="Supprimer la ressouces"
			show={show}
			onClose={() => {
				onClose();
			}}
			body={
				<div>
					<p>
						Êtes-vous sûr de vouloir suprrimer cette ressource ? Cette action
						est irréversible
					</p>
				</div>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					label="Annuler"
					onClick={() => {
						onClose();
					}}
				/>,

				<ButtonPrimary
					key={1}
					label="Confirmer"
					onClick={() => {
						deleteResource({ id: resourceId }).then(() => {
							navigate("/school-resources", {
								state: {
									deleteResource: true,
								},
							});
						});
						onClose();
					}}
				/>,
			]}
		/>
	);
}

DeleteResourceModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	resourceId: PropTypes.string.isRequired,
};
