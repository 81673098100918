import { Icon } from "@iconify/react";
import React from "react";

import {
	arrowForwardFill,
	bulbOutline,
	pieChartOutline,
	trendingUpOutline,
} from "@assets/Icons";

import { InlineButton } from "@components/atoms/InlineButton";

export default function Cards() {
	return (
		<div className="p-sm lg:w-6/12 flex flex-col gap-lg">
			<div className="bg-white rounded-lg px-md py-lg shadow-sm">
				<div className="flex items-center">
					<Icon className="text-md text-primary-500" icon={bulbOutline} />
					<h5 className="ml-sm mt-xxsm text-primary-500">
						Qu'est-ce que Prepera ?
					</h5>
				</div>
				<p>
					Prepera est une plateforme partenaire de Mentor Goal, qui te permettra
					de mieux te vendre durant les entretiens d’embauche. Tu seras guidé.e
					en fonction de tes besoins, de ton métier et des objectifs que tu te
					fixes.
				</p>
				<InlineButton
					id="en-savoir-plus"
					className="more flex items-center"
					icon={arrowForwardFill}
					label="En savoir plus"
					onClick={() => {
						window.open(
							"https://app.mentorgoal.com/resources/428?type=resource",
							"_blank",
						);
					}}
				/>
			</div>
			<div className="bg-white rounded-lg px-md py-lg shadow-sm">
				<div className="flex items-center">
					<Icon className="text-md text-accent-2" icon={pieChartOutline} />
					<h5 className="ml-sm mt-xxsm text-accent-2">Comment ça marche ?</h5>
				</div>
				<p>
					Prepera est simple d’utilisation : cet outil te permettra de
					structurer ta pensée et donc de te démarquer auprès des recruteurs !
				</p>
			</div>
			<div className="bg-white rounded-lg px-md py-lg shadow-sm">
				<div className="flex items-center">
					<Icon className="text-md text-accent-1" icon={trendingUpOutline} />
					<h5 className="ml-sm mt-xxsm text-accent-1">
						Que propose ce partenaire ?
					</h5>
				</div>
				<p>
					Progresse avec de multiples entretiens vidéos auprès d’experts :
					pitch, entretien RH ou encore des entretiens Manager !{" "}
				</p>
			</div>
		</div>
	);
}
