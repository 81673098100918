import type { EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import { type AppState, useAppDispatch } from "@config/redux/store";
import { isMentorGoalEvent } from "@tools/Events";
import { type EventsState, eventsSlice } from "../../core/store/events.slice";

type CloseRepliesModal = () => void;
export const useEventReplies = () => {
	const closeRepliesModal: CloseRepliesModal = () => {
		const payload: {
			showRepliesListModal: boolean;
			eventId?: string;
			isMentorGoalEvent?: boolean;
		} = {
			showRepliesListModal: false,
		};
		dispatch(eventsSlice.actions.setShowRepliesListModal(payload));
	};
	const openRepliesModal = (event?: EventType) => {
		const payload: {
			showRepliesListModal: boolean;
			eventId?: string;
			isMentorGoalEvent?: boolean;
		} = {
			showRepliesListModal: true,
			eventId: event?.id,
			isMentorGoalEvent: isMentorGoalEvent(event?.type || ""),
		};
		dispatch(eventsSlice.actions.setShowRepliesListModal(payload));
	};

	const targetThisEvent = (event: EventType) => {
		dispatch(eventsSlice.actions.setEventTarget(event));
	};

	const { showRepliesListModal } = useAppSelector(
		(state: AppState) => state.events as EventsState,
	);

	const dispatch = useAppDispatch();

	return {
		showRepliesListModal,
		closeRepliesModal,
		openRepliesModal,
		targetThisEvent,
	};
};
