import type { ViewType } from "@/types/genericType";
import type { studentSelectedType } from "@/types/mailbox";
import type { FormatViewType, UserType } from "@/types/userTypes";
import type { EStatus } from "@/types/userTypes";
import type { StatusOptionType } from "@/types/usersFiltersTypes";

import { STUDENT_STATUS_OPTIONS } from "@constants/Users";

export function getStatusFromName(statusValue: EStatus) {
	return STUDENT_STATUS_OPTIONS.find(
		(e) => statusValue === e.value,
	) as Required<StatusOptionType>;
}

export function getUserAvatarPath(user: Pick<UserType, "avatar">): string {
	if (user?.avatar?.filePath) return user.avatar.filePath;
	if (typeof user?.avatar === "string") return user.avatar;
	return "";
}

export function extractIdNumberFromId(id: string | undefined) {
	if (typeof id !== "string") return 0;
	const splittedId = id.split("/")[3];
	if (!splittedId) return -1;
	return Number.parseInt(splittedId);
}

export function computeUsersIdString(users: Pick<UserType, "id">[]) {
	const computedId: string[] = [];
	if (users.length > 0) {
		for (let i = 0; i < users.length; i++) {
			computedId.push(extractIdNumberFromId(users[i].id).toString());
		}
	}
	return computedId;
}

export function computeUsersIdNumber(users: Pick<UserType, "id">[]) {
	const computedId: number[] = [];
	if (users.length > 0) {
		for (let i = 0; i < users.length; i++) {
			computedId.push(extractIdNumberFromId(users[i].id));
		}
	}
	return computedId;
}

type initialStructureViewType = {
	user?: string;
	school?: string;
	campus?: string;
};
export const resolveStrView = (
	initialStructureView: initialStructureViewType,
	format: FormatViewType,
) => {
	const hasSchool = Object.prototype.hasOwnProperty.call(
		initialStructureView,
		"school",
	);
	const hasCampus = Object.prototype.hasOwnProperty.call(
		initialStructureView,
		"campus",
	);
	const hasStudent = Object.prototype.hasOwnProperty.call(
		initialStructureView,
		"student",
	);
	switch (format) {
		case "default":
			return hasSchool || hasCampus
				? `${Object.keys(initialStructureView)[0]}`
				: `${initialStructureView.campus}`;
		default:
			if (hasSchool) {
				return `${format}_school`;
			}
			if (hasCampus) {
				return `${format}_campus`;
			}
			if (hasStudent) {
				return `${format}_student`;
			}
			return "";
	}
};
export function resolveView(
	initialStructureView: initialStructureViewType,
	format: FormatViewType = "default",
) {
	const viewCopy: ViewType = {};
	const dynamicKey = resolveStrView(initialStructureView, format);

	if (Object.keys(initialStructureView)[0] === "school") {
		viewCopy[dynamicKey] = initialStructureView.school;
		return viewCopy;
	}
	if (Object.keys(initialStructureView)[0] === "campus") {
		viewCopy[dynamicKey] = initialStructureView.campus;
		return viewCopy;
	}
	if (Object.keys(initialStructureView)[0] === "user") {
		viewCopy[dynamicKey] = initialStructureView.user;
		return viewCopy;
	}
	return initialStructureView;
}

/**
 * Remove duplicate students
 * @param students
 * @returns  Partial<UserType>[]
 */
export const removeDuplicateSelectedStudent = (
	students: Partial<studentSelectedType>[],
) => {
	if (!students) return [];
	const uniqueStudents: Partial<studentSelectedType>[] = students.reduce(
		(acc, current) => {
			const x = acc.find((item) => item.id === current.id);
			if (!x) {
				return acc.concat([current]);
			}
			return acc;
		},
		[] as Partial<studentSelectedType>[],
	);

	return uniqueStudents;
};
