import { useAppSelector } from "@config/redux/hook";
import { type AppState, useAppDispatch } from "@config/redux/store";
import {
	type EventsState,
	eventsSlice,
} from "@containers/shared/Events/core/store/events.slice";
import { fetchEventReplies } from "@containers/shared/Events/core/usecases/fetchEventReplies.usecase";
import { isMentorGoalEvent } from "@tools/Events";
import { useState } from "react";

type ToggleRepliesListModal = () => void;

export const useRepliesModal = () => {
	const toggleRepliesListModal: ToggleRepliesListModal = () => {
		dispatch(
			eventsSlice.actions.setShowRepliesListModal({
				showRepliesListModal: !showRepliesListModal,
				eventId: targetEvent?.id,
				isMentorGoalEvent: isMentorGoalEvent(targetEvent?.type || ""),
			}),
		);
	};
	const { eventReplies, showRepliesListModal, targetEvent } = useAppSelector(
		(state: AppState) => state.events as EventsState,
	);
	const dispatch = useAppDispatch();
	const selectThisRepliesTab = (tabSelected: number) => {
		setCurrentTab(tabSelected);
		const isMentorgoal = isMentorGoalEvent(targetEvent?.type || "");
		dispatch(
			fetchEventReplies(targetEvent?.id || "", isMentorgoal, tabSelected),
		);
	};

	const [currentTab, setCurrentTab] = useState<number>(0);

	return {
		isFetchingInvitations: eventReplies.status === "loading",
		isFetchingCounters: eventReplies.counters.status === "loading",
		showRepliesListModal,
		toggleRepliesListModal,
		repliesTabSelected: currentTab,
		selectThisRepliesTab,
		repliesToShow: eventReplies.replies,
		maybeCounter: eventReplies.counters.maybe,
		registeredCounter: eventReplies.counters.registered,
		notParticipatinCounter: eventReplies.counters.notParticipating,
		noReplyCounter: eventReplies.counters.noReply,
	};
};
