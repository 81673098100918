import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React from "react";

import { bulbOutline } from "@assets/Icons";

import { OFFER_CREATION_HEADER_DESCRIPTION } from "@constants/Offers";

export function OfferCreationInformationHeader() {
	const currentOfferType = useAppSelector(
		(state) => state.offers.offerToCreate?.type,
	);

	return (
		<section className="flex items-start justify-start gap-4 rounded-lg bg-white px-md py-sm">
			<Icon
				icon={bulbOutline}
				className="h-md w-md min-w-[24px] text-secondary-500P"
			/>
			<p className="text-base text-primary-700P">
				{OFFER_CREATION_HEADER_DESCRIPTION.get(currentOfferType || "direct")}
			</p>
		</section>
	);
}
