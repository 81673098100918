import type { EntOfferUserDataType } from "@/types/EntCompanyTypes";
import React from "react";

export function OfferNumbersBlock({
	userData,
	className = "",
}: {
	userData: EntOfferUserDataType;
	className?: string;
}) {
	return (
		<div className={`flex flex-col gap-xsm sm:flex-row ${className}`}>
			<div className="w-full rounded-md border-[1px] border-primary-150 p-xsm">
				<p className="text-center text-md font-bold">{userData?.total}</p>
				<p className="text-center">Nombre de canditats</p>
			</div>
			<div className="w-full rounded-md border-[1px] border-primary-150 p-xsm">
				<p
					className={`text-center text-md font-bold${
						userData?.total! - userData?.consulted! > 0
							? " text-error-dark"
							: ""
					}`}
				>
					{userData?.total! - userData?.consulted!}
				</p>
				<p className="text-center">Candidatures à traiter</p>
			</div>
			<div className="w-full rounded-md border-[1px] border-primary-150 p-xsm">
				<p className="text-center text-md font-bold">2</p>
				<p className="text-center">Candidats accéptés</p>
			</div>
		</div>
	);
}
