import { Icon } from "@iconify/react";
import React from "react";

import {
	alertTriangleOutline,
	checkmarkOutline,
	clockOutline,
} from "@assets/Icons";

type StatusType = {
	status: string;
};

export function CVStatus({ status }: StatusType) {
	if (status === "toValidate") {
		return (
			<span className="my-auto rounded-2xl border border-accent-3-dark bg-accent-3-lighter p-1 px-2 text-xs font-bold text-accent-3-dark">
				À valider
				<Icon
					className="mb-0.5 ml-1 inline h-4 w-4"
					icon={alertTriangleOutline}
				/>
			</span>
		);
	}
	if (status === "validated") {
		return (
			<span className="my-auto rounded-2xl border border-accent-4-dark bg-accent-4-lighter p-1 px-2 text-xs font-bold text-accent-4-dark">
				CV validé
				<Icon className="mb-0.5 ml-1 inline h-4 w-4" icon={checkmarkOutline} />
			</span>
		);
	}
	return (
		<span className="my-auto whitespace-nowrap rounded-2xl border border-secondary-700 bg-secondary-50 p-1 px-2 text-xs font-bold text-secondary-700">
			En modification
			<Icon className="mb-0.5 ml-1 inline h-4 w-4" icon={clockOutline} />
		</span>
	);
}
