import type { EntOffersType } from "@/types/EntCompanyTypes";
import DOMPurify from "dompurify";
import React from "react";

export function OfferDescription({
	activeOffer,
}: {
	activeOffer: EntOffersType;
}) {
	return (
		<div className="mt-sm">
			<p className="font-bold">Description de l'offre</p>
			{activeOffer.description ? (
				<p
					className="mt-sm"
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(activeOffer.description!),
					}}
				/>
			) : (
				<p className="mt-sm">Cette offre n'a pas de description.</p>
			)}
		</div>
	);
}
