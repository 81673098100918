import api, { checkGraphQLError } from "@tools/API";

export async function getTags(school: string) {
	let rep = {};

	const data = {
		query: `query {
        tags (school: "${school}", itemsPerPage: 1000) {
          collection {
            id
            color
            name
          }
        }
      }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.tags;
	});
	return rep;
}

export async function getReferents(schoolId: string) {
	try {
		const queryToSend = {
			query: `
      query {
        schoolReferents: spsReferents ( itemsPerPage: 1000,coreSchool: "${schoolId}", exists:  {spsOffers: true}, order: [{lastname: "ASC"}]){
            collection {
							id
							firstname
							lastname
							avatar{
								filePath
							}
          }
        }
    }
      `,
		};
		const response = await api.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la récupération des référents",
		);
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}
