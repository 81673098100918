import type { promotionType } from "@/types/promotionTypes";
import React, { useState } from "react";

import { PaginationPrimary } from "@components/molecules/PaginationPrimary";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";
import { Table } from "@components/templates/table/Table";

import { editPromotion } from "@containers/school/Promotions/PromotionsAPI";

import { EditorPromotionModal } from "../Modal/EditorPromotionModal";

type TableViewProps = {
	load: boolean;
	tabIndex: number;
	promotions: promotionType[];
	lastPageProps: { lastPage: number };
	totalCount: number;
	campuses: { label: string; value: number }[];
	filtersProps: {
		filters: { page: number; search: string };
		setFilters: (filters: { page: number; search: string }) => void;
	};
	updatePromos: () => void;
};

const EMPTY_PROMOTION: promotionType = {
	id: "",
	name: "",
	campus: {
		isGalliaEnabled: false,
		name: "",
	},
	toPlace: 0,
	placed: 0,
	surrender: 0,
	applications: 0,
	relaunchs: 0,
	surrend: 0,
	interviews: 0,
	archived: false,
	selectedCampus: {
		value: "",
	},
};
export function TableView({
	load,
	tabIndex,
	promotions,
	lastPageProps,
	totalCount,
	campuses,
	filtersProps,
	updatePromos,
}: TableViewProps) {
	const [promotionSelected, setPromotionSelected] =
		useState<promotionType>(EMPTY_PROMOTION);
	const [displayModifyPromotion, setDisplayModifyPromotion] =
		useState<boolean>(false);
	const { lastPage } = lastPageProps;
	const { filters, setFilters } = filtersProps;

	const headers = [
		{
			label: `Promotions (${totalCount})`,
			sort: { fieldName: "name", defaultOrder: "ASC" },
		},
		{
			label: "À placer",
			sort: { fieldName: "toPlace", defaultOrder: "ASC" },
		},
		{
			label: "Placé",
			sort: { fieldName: "placed", defaultOrder: "ASC" },
		},
		{
			label: "Abandon",
			sort: { fieldName: "surrend", defaultOrder: "ASC" },
		},
		{
			label: "Candidatures",
			sort: { fieldName: "applications", defaultOrder: "ASC" },
		},
		{
			label: "Relances",
			sort: { fieldName: "relaunchs", defaultOrder: "ASC" },
		},
		{
			label: "Entretiens",
			sort: { fieldName: "interviews", defaultOrder: "ASC" },
		},
	];

	const cellsResolver = (promo: promotionType) => {
		return [
			{
				children: <span className="wg-no-translate">{promo.name}</span>,
			},
			{
				children: promo.toPlace,
			},
			{
				children: promo.placed,
			},
			{
				children: promo.surrend,
			},
			{
				children: promo.applications,
			},
			{
				children: promo.relaunchs,
			},
			{
				children: promo.interviews,
			},
		];
	};

	const getEmptyMessage = (index: number) => {
		switch (index) {
			case 0:
				return "Vous n’avez aucune promotion pour l’instant. Ajoutez une promotion pour commencer.";
			case 1:
				return "Vous n’avez archivé aucune promotion pour l’instant.";
			default:
				return "Aucun résultat ne correspond à votre recherche.";
		}
	};

	const handleApplyModification = async (promotionToModify: promotionType) => {
		const editResponse = await editPromotion({
			id: promotionToModify.id,
			name: promotionToModify.name,
			archived: promotionToModify.archived,
			campus: promotionToModify?.selectedCampus?.value,
		});
		if (editResponse.responseLabel === "success") {
			Success("La promotion a bien été modifiée !");
			updatePromos();
		} else {
			ErrorToast(editResponse.message);
		}
	};

	return (
		<>
			<Table
				className="lg:my-md"
				isLoading={load}
				headers={headers}
				filtersProps={{ filters, setFilters }}
				emptyMessage={
					promotions === null ||
					(promotions?.length === 0 &&
						getEmptyMessage(promotions?.length === 0 ? tabIndex : 0))
				}
				rows={promotions?.map((promo) => {
					return {
						onClick: () => {
							setPromotionSelected(promo);
							setDisplayModifyPromotion(!displayModifyPromotion);
						},
						cells: cellsResolver(promo),
					};
				})}
			/>
			<PaginationPrimary
				onChange={(page) => {
					setFilters({ ...filters, page });
				}}
				current={filters.page}
				last={lastPage}
			/>

			<EditorPromotionModal
				show={displayModifyPromotion}
				title="Modifier la promotion"
				campuses={campuses}
				promotion={promotionSelected}
				onClose={() => {
					setDisplayModifyPromotion(!displayModifyPromotion);
				}}
				onApply={(currentPromotion: promotionType) => {
					handleApplyModification(currentPromotion);
				}}
			/>
		</>
	);
}
