import { Icon } from "@iconify/react";
import React from "react";
import Skeleton from "react-loading-skeleton";

export const CardLoading = () => {
	return (
		<div className="relative my-2">
			<div
				className={
					"flex flex-row bg-white p-4 gap-2 rounded-md shadow-md relative"
				}
			>
				<Skeleton circle width={44} height={44} />
				<div className="flex flex-1 flex-row justify-between ">
					<div className="flex flex-col justify-between">
						<span className="font-bold line-clamp-1">
							<Skeleton width={144} height={24} />
						</span>
						<span className="text-gray-500 text-xs">
							<Skeleton width={120} height={16} />
						</span>
					</div>
					<div className="flex items-center h-full text-xl text-gray-500 cursor-pointer">
						<Icon icon="eva:more-vertical-fill" />
					</div>
				</div>
			</div>
		</div>
	);
};
