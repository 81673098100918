import { InlineIcon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import { infoOutline } from "@assets/Icons";

import { Switch } from "@components/atoms/Switch";
import { ColorChoice } from "@components/molecules/ColorChoice";
import { FileInput } from "@components/molecules/FileInput";
import { FilesPrimary } from "@components/molecules/Files";

import type { FileType } from "@/types/mediaObjectTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { ResourceType } from "@/types/resourcesTypes";
import TooltipContainer from "@components/atoms/TooltipContainer";
import {
	editResources,
	upload,
} from "@containers/school/Resources/ResourceEditor/ResourceEditorAPI";

type PersonnalisationPropsType = {
	illustrationProps: {
		illustration: FileType | string | null;
		setIllustration: (file?: FileType | string) => void;
	};
	newResourceProps: {
		newResource: ResourceType;
		setNewResource: (resource: ResourceType) => void;
	};
};

export function Personnalisation({
	illustrationProps,
	newResourceProps,
}: PersonnalisationPropsType) {
	const { illustration, setIllustration } = illustrationProps;
	const { newResource, setNewResource } = newResourceProps;

	const [hasIllustration, setHasIllustration] = useState(false);
	const [isDocumentLoading, setIsDocumentLoading] = useState(false);

	const uploadFile = async (files: File[]): Promise<void> => {
		setIsDocumentLoading(true);

		const data = new FormData();
		data.append("file", files[0]);

		const media: MediaObjectType = (await upload(data)) as MediaObjectType;

		setNewResource({
			...newResource,
			illustration: media["@id"],
		});

		setIllustration({
			id: media["@id"],
			size: media.size,
			filePath: media.filePath,
		});

		setIsDocumentLoading(false);
	};

	useEffect(() => {
		if (newResource?.illustration) {
			setIllustration(newResource.illustration);
			setHasIllustration(true);
		}
	}, []);

	useEffect(() => {
		if (hasIllustration) {
			setNewResource({ ...newResource, illustration: illustration });
		} else {
			setNewResource({ ...newResource, illustration: null });
		}
	}, [hasIllustration]);

	return (
		<div>
			<p className="mb-md text-sm font-bold">Personnalisation</p>
			<div className="mb-md flex flex-col">
				<div className="mb-sm flex items-center ">
					<p className="font-semifont-bold text-xsm text-primary-700P">
						Image en avant
					</p>
					<TooltipContainer anchorId="#info-icon" place="top">
						Désactiver cette option affichera uniquement le titre en couverture
						de la ressource.
					</TooltipContainer>

					<span id="info-icon" className="ml-xxsm cursor-pointer">
						<InlineIcon className=" h-4 text-primary-500" icon={infoOutline} />
					</span>

					<Switch
						className="ml-md"
						checked={hasIllustration}
						onEnabled={() => {
							setHasIllustration(!hasIllustration);
						}}
						onDisabled={() => {
							setHasIllustration(!hasIllustration);
						}}
					/>
				</div>
				{hasIllustration &&
					(illustration ? (
						<FilesPrimary
							isLoading={isDocumentLoading}
							title={
								typeof illustration === "string"
									? illustration
									: illustration.filePath
							}
							subtitle={
								typeof illustration === "string"
									? undefined
									: `${Math.floor(illustration.size / 1000)} Ko`
							}
							onDelete={() => {
								if (window.location.pathname.split("/")[2] !== "create") {
									editResources({ id: newResource.id, illustration: null });
								}
								setNewResource({ ...newResource, illustration: undefined });
								setIllustration(undefined);
							}}
						/>
					) : (
						<FileInput
							onError={() => {}}
							isLoading={isDocumentLoading}
							id="documents-upload"
							maxSize={0.5}
							subtitle="500Ko max - JPEG, PNG"
							onSuccess={uploadFile}
							acceptFormats={["png", "jpg", "jpeg"]}
						/>
					))}
			</div>
			<p className="font-semifont-bold text-xsm text-primary-700P">Couleur</p>
			<p className="mt-sm text-xsm text-primary-300">
				Vous pouvez attribuer ci-dessous une couleur à cette ressource
			</p>
			<div className="mt-sm flex items-center">
				<ColorChoice
					defaultColor={newResource?.color}
					optionsColor={[
						{ id: 0, value: "bg-emerald-light" },
						{ id: 1, value: "bg-terracotta-light" },
						{ id: 2, value: "bg-sakura-light" },
						{ id: 3, value: "bg-kaki-light" },
						{ id: 4, value: "bg-aquamarine-light" },
						{ id: 5, value: "bg-sunflower-light" },
						{ id: 6, value: "bg-lavender-light" },
						{ id: 7, value: "bg-stone-light" },
					]}
					onChange={(e) => {
						setNewResource({ ...newResource, color: e.value as string });
					}}
				/>
			</div>
		</div>
	);
}
