import type { ApplicationType } from "@/types/applicationTypes";
import type { ScrapedOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React from "react";
import { NavLink, useOutletContext } from "react-router-dom";

import {
	briefcaseOutline,
	checkmarkCircle2Fill,
	externalLinkOutline,
	paperPlaneOutline,
	pinOutline,
} from "@assets/Icons";

import { convertDateFromNow } from "@tools/Dates";
import { getContractType } from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";

import type { BackgroundColorType } from "@/types/colorsType";
import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { Divider } from "@components/atoms/Divider";
import TooltipContainer from "@components/atoms/TooltipContainer";

export function ScrapedOfferCard({
	offer,
	setSelectedOffer,
	setShowHaveYouPostulateModal,
}: {
	offer: ScrapedOfferType;
	setSelectedOffer?: Function;
	setShowHaveYouPostulateModal?: Function;
}) {
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const { userApplications } = useAppSelector((state) => state.jobboard);

	const ALREADY_APPLY =
		userApplications.filter(
			(application: ApplicationType) =>
				application.offer?.id === offer.id ||
				(application.url === offer.url &&
					offer.url !== "" &&
					offer.url !== null),
		).length > 0;

	const publicationDate = () => {
		return offer.date ? (
			<div className="mb-xxsm flex w-fit flex-col items-end justify-end text-xxsm font-bold text-primary-200">
				<p>Publiée {convertDateFromNow(offer.date)}</p>
				<Divider className="mt-xxsm w-7/12" />
			</div>
		) : (
			""
		);
	};

	return (
		<NavLink
			to={
				offer?.jobboard === "partner" || offer?.jobboard === "jobboard"
					? `/${
							currentInterface === "student" ? "jobboard" : "school-jobboard"
						}/${extractIdNumberFromId(offer.id)}`
					: ""
			}
			onClick={() => {
				if (offer?.jobboard !== "partner" && offer?.jobboard !== "jobboard") {
					window.open(offer?.url);
					if (setSelectedOffer && setShowHaveYouPostulateModal) {
						setSelectedOffer(offer);
						setShowHaveYouPostulateModal(true);
					}
				}
			}}
			className="flex flex-col gap-xxsm transition-shadow hover:shadow-lg"
		>
			<div
				id="offer"
				className="flex w-full cursor-pointer flex-col justify-between rounded-t-lg bg-white p-sm shadow-sm md:flex-row md:items-start  md:rounded-lg"
			>
				<div
					id="leftPart"
					className="flex flex-col md:w-full md:flex-[1_1_100%]"
				>
					<div className="md:hidden">{publicationDate()}</div>
					<div className="flex items-center gap-md">
						<div className="hidden md:block">
							<Avatar
								firstname={offer.organization?.name || ""}
								lastname=""
								image=""
							/>
						</div>
						<div className="flex max-w-full flex-col">
							<div className="flex items-center gap-xxsm">
								{ALREADY_APPLY && (
									<span
										id={`application-validated-${
											extractIdNumberFromId(offer.id) || offer.id
										}`}
									>
										<Icon
											icon={checkmarkCircle2Fill}
											className="text-accent-4"
										/>
										<TooltipContainer
											anchorId={`#application-validated-${
												extractIdNumberFromId(offer.id) || offer.id
											}`}
											children="Tu as postulé à cette offre"
											place="top"
										/>
									</span>
								)}
								<div className="max-w-full">
									<p className="wg-no-translate overflow-hidden text-ellipsis whitespace-nowrap text-xsm font-bold text-primary-700P md:!whitespace-break-spaces">
										{offer.job}
									</p>
								</div>
							</div>
							<div className="mt-xxsm flex w-full gap-md truncate text-primary-700P">
								<div className="flex items-center">
									<Icon
										icon={briefcaseOutline}
										className="mr-xxsm"
										height="16px"
									/>
									<p className="truncate wg-no-translate">
										{offer?.organization?.name}
									</p>
								</div>
								<div className="ml-xsm hidden w-auto items-center truncate text-primary-700P md:flex">
									<Icon icon={pinOutline} className="mr-xxsm" height="16px" />
									<p className="truncate">{offer?.location}</p>
								</div>
								{offer.url && (
									<div className="flex items-center">
										{offer?.jobboard !== "preselection" ? (
											<>
												<Icon
													icon={externalLinkOutline}
													className="mr-xxsm"
													height="16px"
												/>
												<p className="truncate">{offer?.jobboard}</p>
											</>
										) : null}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full items-end truncate md:mt-0 md:w-[30%] md:flex-col md:gap-xxsm">
					<div className="hidden md:block">{publicationDate()}</div>
					{offer.contract && (
						<Badge
							label={getContractType(offer.contract).label}
							bgColor={
								getContractType(offer.contract).color as BackgroundColorType
							}
						/>
					)}
					<div className="ml-xsm flex items-center text-primary-700P md:hidden">
						<Icon icon={pinOutline} className="mr-xxsm" height="16px" />
						<p className="truncate">{offer.location}</p>
					</div>
				</div>
			</div>
			{ALREADY_APPLY && (
				<div className="flex flex-col rounded-b-md bg-white p-sm text-xxsm text-primary-300 md:hidden">
					<div className="flex items-center gap-xxsm">
						<Icon
							className="text-accent-4"
							icon={paperPlaneOutline}
							height="16px"
						/>
						<p>Tu as postulé à cette offre.</p>
					</div>
					<p>Retrouve ta candidature dans ton tableau de suivi.</p>
				</div>
			)}
		</NavLink>
	);
}
