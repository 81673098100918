import type { OfferVisibilityType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { getVisibilityOptions } from "@tools/Offers";

import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import { countCurrentFilters, setCurrentFilters } from "../offersFiltersSlice";

export function VisibilityFilter() {
	const dispatchEvent = useDispatch();

	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);

	const handleChange = (visibilitySelected: string) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				visibility: visibilitySelected,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<SelectSecondary
			position="right"
			defaultValue={
				getVisibilityOptions({ cutDetails: true, roles: [] })?.find(
					(visibility) =>
						visibility.value ===
						(currentFilters?.visibility as OfferVisibilityType),
				) || { value: "", label: "Visibilité" }
			}
			options={getVisibilityOptions({ cutDetails: true, roles: [] })}
			onChange={(e) => {
				handleChange(e.value);
			}}
		/>
	);
}
