import type { StandardGraphQLResponse } from "@tools/API";
import { isMentorGoalEvent } from "@tools/Events";
import { extractIdNumberFromId } from "@tools/Users";
import {
	fetchGetStudentInvitations,
	getEventParticipants,
	getEventParticipantsCounters,
	sendEventInvitations,
	sendMentorgoalInvitations,
} from "../api/events.request";
import type { IEventsGateway } from "../gateway/events.gateway";

export class InAPIEventsGateway implements IEventsGateway {
	async getStudentInvitations(
		studentId: string,
		tab: string,
	): Promise<StandardGraphQLResponse> {
		const result = await fetchGetStudentInvitations({ studentId, tab });
		if (!result) {
			throw new Error("Failed to fetch invitations");
		}
		return result;
	}

	async getEventReplies(
		eventId: string,
		isMentorGoalEvent: boolean,
		currentTab: number,
	): Promise<StandardGraphQLResponse> {
		const result = await getEventParticipants(
			eventId,
			isMentorGoalEvent,
			currentTab,
		);
		if (!result) {
			throw new Error("Failed to fetch replies");
		}
		return result;
	}

	async getRepliesCounters(
		eventId: string,
		isMentorGoalEvent: boolean,
	): Promise<StandardGraphQLResponse> {
		const result = await getEventParticipantsCounters(
			eventId,
			isMentorGoalEvent,
		);
		if (!result) {
			throw new Error("Failed to fetch replies counters");
		}
		return result;
	}

	async relaunchIndividualInvitation(
		eventId: string,
		studentId: string,
		eventType: string,
	): Promise<StandardGraphQLResponse> {
		let response: StandardGraphQLResponse;
		if (isMentorGoalEvent(eventType)) {
			response = await sendMentorgoalInvitations({
				eventId: extractIdNumberFromId(eventId),
				selectedStudents: [{ id: studentId }],
				sendEmail: true,
				sendSMS: false,
			});
		} else {
			response = await sendEventInvitations({
				eventId: extractIdNumberFromId(eventId),
				selectedStudents: [{ id: studentId }],
				sendEmail: true,
				sendSMS: false,
			});
		}

		if (!response) {
			throw new Error("No response for relaunch individual invitation");
		}
		return response;
	}
}
