import React, { useContext, useEffect, useState } from "react";

import { type ProspectActivity, getProspectActivity } from "../../AtsAPI";
import { CurrentProspectContext } from "../../index";
import type { ProspectType } from "../Kanban";

import { ActivityRow } from "./ActivityRow";
import { ActivityRowLoading } from "./ActivityRowLoading";
import { Separator } from "./Separator";
import { SeparatorLoading } from "./SeparatorLoading";

type ActivityGroup = {
	date: string;
	activities: ProspectActivity[];
};

const getRandomNumber = () => Math.floor(Math.random() * 3) + 1;

const FAKE_ACTIVITIES: number[] = [
	getRandomNumber(),
	getRandomNumber(),
	getRandomNumber(),
];

export const Activity = () => {
	const currentProspect = useContext(CurrentProspectContext)
		.currentProspect as ProspectType;
	const [activityGroups, setActivityGroups] = useState<ActivityGroup[] | null>(
		null,
	);
	useEffect(() => {
		getProspectActivity(currentProspect.id).then((data) => {
			const groups: ActivityGroup[] = [];
			data.forEach((activity) => {
				let date = new Date(activity.createdAt).toLocaleDateString("fr-FR", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
				});
				// if date is today, we use "Aujourd'hui" instead of the date and if it's yesterday, we use "Hier"
				if (
					date ===
					new Date().toLocaleDateString("fr-FR", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})
				) {
					date = "Aujourd'hui";
				} else if (
					date ===
					new Date(
						new Date().setDate(new Date().getDate() - 1),
					).toLocaleDateString("fr-FR", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
					})
				) {
					date = "Hier";
				}
				const index = groups.findIndex((group) => group.date === date);
				if (index === -1) {
					groups.push({ date, activities: [activity] });
				} else {
					groups[index].activities.push(activity);
				}
			});
			setActivityGroups(groups);
		});
	}, []);
	return (
		<div className="flex flex-col gap-4">
			{activityGroups !== null
				? activityGroups
						.sort((a, b) => {
							if (a.date === "Aujourd'hui") {
								return 1;
							}
							if (a.date === "Hier") {
								return 0;
							}
							if (Date.parse(a.date) > Date.parse(b.date)) {
								return 1;
							}
							return -1;
						})
						.map((group) => (
							<div key={group.date} className="flex flex-col gap-4">
								<Separator date={group.date} />
								{group.activities.map((activity) => (
									<ActivityRow activity={activity} key={activity.createdAt} />
								))}
							</div>
						))
				: FAKE_ACTIVITIES.map((nbr, index) => (
						<div
							className={"flex flex-col gap-4"}
							key={`SeparatorLoading-${index}`}
						>
							<SeparatorLoading />
							{[...Array(nbr)].map((_, index) => (
								<ActivityRowLoading key={`ActivityRowLoading-${index}`} />
							))}
						</div>
					))}
		</div>
	);
};
