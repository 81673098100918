import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink, useOutletContext } from "react-router-dom";

import { emailOutline } from "@assets/Icons";

import { getSubdomain } from "@tools/Auth";
import { setCookie } from "@tools/Cookies";
import { getTextError } from "@tools/Errors";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { Spinner } from "@components/atoms/Spinner";
import { InputLargePrimary } from "@components/molecules/InputLargePrimary";
import { PasswordInput } from "@components/molecules/InputPassword";

import { checkMicrosoftEmail } from "@tools/Checkers";

import type { SchoolType } from "@/types/schoolTypes";
import { login } from "./LoginAPI";

export function Login({
	setIsMicrosoftLogin,
	defineInstance,
}: {
	defineInstance: (value: string) => undefined | null;
	setIsMicrosoftLogin: (value: boolean) => void;
}) {
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState("");
	const { school }: { school?: SchoolType } = useOutletContext();

	const [connectionInProgress, setConnectionInProgress] = useState(false);

	const sendLogin = async (values: {
		email: string | null;
		password: string;
	}) => {
		login(values)
			.then(({ token }: { token: string }) => {
				localStorage.setItem("email", values.email ?? "");
				setCookie("mainToken", token);
				window.location.replace("/choose_profile");
			})
			.catch((error) => {
				setConnectionInProgress(false);
				setErrorMessage(getTextError(error.response.status));
			});
	};

	useEffect(() => {
		document.title = "Connexion - Mentor Goal";
		if (getSubdomain() === "sso-igs") {
			window.location.replace("/login/okta");
		}
	}, []);
	return (
		<>
			<Formik
				initialValues={{
					email: localStorage.getItem("email")
						? localStorage.getItem("email")
						: "",
					password: "",
				}}
				onSubmit={(
					values: { email: string | null; password: string },
					{ resetForm },
				) => {
					if (!values.email) {
						values.email = "";
					}
					setConnectionInProgress(true);
					sendLogin(values);
					resetForm({});
				}}
			>
				{({ values, handleChange, handleSubmit }) => (
					<form
						id="form-login"
						className="login ml-0 w-full md:ml-[8%] lg:w-11/12"
						onSubmit={handleSubmit}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleSubmit();
							}
						}}
					>
						{school && (
							<div className="flex items-center">
								<Avatar
									size="lg"
									modify={false}
									image={school?.logo as string}
								/>
								<h2 className="ml-sm !text-md text-primary-900">
									Bienvenue sur votre espace {school?.name}
								</h2>
							</div>
						)}
						{!school && <h2 className="text-primary-900">Connexion</h2>}

						{checkMicrosoftEmail(
							values.email || "",
							setIsMicrosoftLogin,
							navigate,
						)}

						{connectionInProgress ? (
							<div className="ml-0 mt-5 flex w-full flex-col items-center px-6 md:w-8/12 md:px-0">
								<Spinner />
								<p className="mt-2 text-xxxsm text-primary-500">
									Connexion en cours...
								</p>
							</div>
						) : (
							<div className="ml-0 mt-lg w-full px-0 sm:px-16 md:w-10/12 md:px-0  lg:w-8/12">
								<InputLargePrimary
									onChange={(e) => {
										handleChange(e);
										defineInstance(e.target.value);
									}}
									value={values.email as string}
									name="email"
									type="email"
									icon={emailOutline}
									placeholder="Adresse e-mail"
								/>
								<PasswordInput
									showRules={false}
									className="mt-sm"
									value={values.password}
									name="password"
									onChange={handleChange}
									placeholder="Mot de passe"
								/>
								<p className="mt-md text-xsm text-error-dark">{errorMessage}</p>
								<ButtonPrimary
									id="login"
									name="submit"
									disabled={!values.email || !values.password}
									className="mt-md w-full"
									type="submit"
									onClick={() => handleSubmit}
									label="Se connecter"
								/>
								<div className="mt-sm flex flex-col items-center justify-center text-center md:flex-row">
									<NavLink
										to="/forgot_password"
										className="text-decoration-none mr-none md:mr-sm"
									>
										<InlineButton
											onClick={undefined}
											label="Mot de passe oublié ?"
										/>
									</NavLink>{" "}
									<span className="hidden md:inline-block">|</span>{" "}
									<NavLink
										to="/activate"
										className="text-decoration-none ml-none md:ml-sm"
									>
										<InlineButton
											onClick={undefined}
											label="Activer mon compte"
											className="!text-primary-500"
										/>
									</NavLink>
								</div>
							</div>
						)}
					</form>
				)}
			</Formik>
		</>
	);
}
