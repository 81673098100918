export const CV_COMMENT = [
	{
		id: 0,
		name: "CV difficile à lire",
		comment:
			"Les informations sont trop denses et la police trop petite. Essaie d'utiliser une police claire et une mise en page plus aérée pour rendre ton CV plus facile à lire. Tu peux également utiliser une liste à puce ou numérotée.",
	},
	{
		id: 1,
		name: "CV trop long",
		comment:
			"Ton CV contient trop d'informations ce qui rend la lecture fastidieuse. Essaie de te concentrer sur les informations les plus pertinentes pour le poste pour lequel tu postules.",
	},
	{
		id: 2,
		name: "CV mal structuré",
		comment:
			"Les informations ne sont pas organisées de manière claire et les compétences et réalisations ne sont pas mises en évidence. Essaie d'utiliser des titres et des sous-titres pour organiser les informations et de mettre en évidence tes compétences et réalisations les plus importantes.",
	},
	{
		id: 3,
		name: "CV pas personnalisé",
		comment:
			"Ton CV n'est pas adapté à l'entreprise ou au poste pour lequel tu postules. Essaie d'adapter ton CV à chaque entreprise et à chaque poste pour lequel tu postules. Essaie d'utiliser des couleurs et des graphiques adaptés.",
	},
	{
		id: 4,
		name: "CV manque de détails",
		comment:
			"Ton CV ne contient pas suffisamment d'informations sur tes compétences et réalisations. Essaie de mettre en avant tes réalisations en utilisant des chiffres et des faits pour montrer comment tu as contribué dans tes anciennes expériences.",
	},
	{
		id: 5,
		name: "CV mal orthographié",
		comment:
			"Attention ton CV comporte des fautes d'orthographe. Fais-toi relire par tes proches et/ou utilise une extension repérant les fautes de grammaire, orthographe et conjugaison.",
	},
];

export const DAYS_BETWEEN_RELAUNCH = 7;
