import React from "react";
import Skeleton from "react-loading-skeleton";

function RepliesSkeleton() {
	return (
		<>
			<article className="flex flex-col gap-xxsm w-full justify-start items-start max-h-96">
				<aside className="text-primary-200 text-xxsm font-bold">
					<Skeleton width={150} />
				</aside>
				<div className="flex gap-4">
					<Skeleton circle={true} height={44} width={44} />
					<div className="flex flex-col gap-xxsm">
						<p className="text-primary-700P text-base font-semibold flex">
							<Skeleton width={100} height={22} />
							<Skeleton width={100} height={22} />
						</p>
						<p className="text-primary-700P text-base font-normal">
							<Skeleton width={300} height={22} />
						</p>
					</div>
				</div>
			</article>
			<article className="flex flex-col gap-xxsm w-full justify-start items-start">
				<aside className="text-primary-200 text-xxsm font-bold">
					<Skeleton width={150} />
				</aside>
				<div className="flex gap-4">
					<Skeleton circle={true} height={44} width={44} />
					<div className="flex flex-col gap-xxsm">
						<p className="text-primary-700P text-base font-semibold flex">
							<Skeleton width={100} height={22} />
							<Skeleton width={100} height={22} />
						</p>
						<p className="text-primary-700P text-base font-normal">
							<Skeleton width={300} height={22} />
						</p>
					</div>
				</div>
			</article>
			<article className="flex flex-col gap-xxsm w-full justify-start items-start">
				<aside className="text-primary-200 text-xxsm font-bold">
					<Skeleton width={150} />
				</aside>
				<div className="flex gap-4">
					<Skeleton circle={true} height={44} width={44} />
					<div className="flex flex-col gap-xxsm">
						<p className="text-primary-700P text-base font-semibold flex">
							<Skeleton width={100} height={22} />
							<Skeleton width={100} height={22} />
						</p>
						<p className="text-primary-700P text-base font-normal">
							<Skeleton width={300} height={22} />
						</p>
					</div>
				</div>
			</article>
			<article className="flex flex-col gap-xxsm w-full justify-start items-start">
				<aside className="text-primary-200 text-xxsm font-bold">
					<Skeleton width={150} />
				</aside>
				<div className="flex gap-4">
					<Skeleton circle={true} height={44} width={44} />
					<div className="flex flex-col gap-xxsm">
						<p className="text-primary-700P text-base font-semibold flex">
							<Skeleton width={100} height={22} />
							<Skeleton width={100} height={22} />
						</p>
						<p className="text-primary-700P text-base font-normal">
							<Skeleton width={300} height={22} />
						</p>
					</div>
				</div>
			</article>
		</>
	);
}

export default RepliesSkeleton;
