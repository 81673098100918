import type { ApplicationThumbType } from "@/types/applicationTypes";
import { OfferDetailsPresenter } from "@containers/school/Offers_v3/core/model/offerDetails.presenter";

export const useOfferDetailsApplications = () => {
	const presenter = new OfferDetailsPresenter();
	const getDateToShow = (applicationData: ApplicationThumbType) => {
		const formmattingResult = presenter.formatApplicationDate(applicationData);
		return `${formmattingResult.prefix} ${formmattingResult.date}`;
	};
	return {
		getDateToShow,
	};
};
