import React, { useEffect, useState } from "react";

import { Badge } from "@components/atoms/Badge";

type BadgesGroupType = {
	badges: any[];
	onClick?: (label: string) => void;
	className?: string;
	selectedBadges?: string[];
};
export function BadgesGroup({
	badges,
	onClick,
	className,
	selectedBadges,
}: BadgesGroupType) {
	const [indexActiveBadge, setIndexActiveBadge] = useState<number | null>();

	useEffect(() => {
		if (selectedBadges?.length === 0) setIndexActiveBadge(null);
	}, [selectedBadges, indexActiveBadge]);

	return (
		<div className={`tags-group flex ${className} gap-1`}>
			{badges?.map((badge) => {
				return (
					<Badge
						label={badge.label}
						bgColor={badge.bgColor}
						onClick={onClick}
					/>
				);
			})}
		</div>
	);
}
