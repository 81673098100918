import axios from "axios";

export async function getToken(token?: string) {
	const response: any = await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
		})
		.post(`okta_login/${token}`);

	return response.data;
}
