import { InlineIcon } from "@iconify/react";
import React, { type MouseEventHandler } from "react";

import { closeOutline } from "@assets/Icons";

interface TagType {
	label: string;
	className?: string;
	type?: string;
	bgColor?: string;
	onDelete?: MouseEventHandler<SVGSVGElement> | null;
	truncate?: boolean;
}

export function Tag({
	label,
	bgColor = "bg-primary-200",
	className = "",
	onDelete,
	truncate = true,
}: TagType) {
	return (
		<div
			className={`wg-no-translate w-fit ${bgColor} flex items-center gap-xxsm rounded-sm px-xsm py-xxsm ${className}`}
		>
			<p
				className={`text-xxsm text-primary-700P ${truncate ? "truncate max-w-[300px]" : ""}`}
			>
				{label}
			</p>
			{onDelete && (
				<InlineIcon
					id="deleteTagBtn"
					onClick={onDelete}
					className="aspect-square cursor-pointer rounded-full bg-white"
					style={{ marginTop: "3px" }}
					icon={closeOutline}
				/>
			)}
		</div>
	);
}
