import type { UserCompleteType } from "@/types/userTypes";
import React from "react";

import { refreshOutline } from "@assets/Icons";

import { convertDateFromNow } from "@tools/Dates";

import { InlineButton } from "@components/atoms/InlineButton";

type ToValidateType = {
	student: UserCompleteType;
	load: boolean;
	changeStep: (
		newStep: string,
		refusalMotive?: string,
		validMotive?: string,
		sendMail?: boolean,
	) => void;
};

export function Valid({ student, load, changeStep }: ToValidateType) {
	return (
		<div className="relative mb-4 rounded-2xl bg-accent-4-lighter p-sm  ">
			<p>
				Le CV de l'étudiant a été validé{" "}
				{convertDateFromNow(new Date(student.cv?.updatedAt ?? ""))}.
			</p>
			<InlineButton
				id="cancel-decision"
				onClick={() => changeStep("toValidate", undefined, undefined, false)}
				className="mt-xxsm"
				label="Annuler la décision"
				disabled={load}
				icon={refreshOutline}
			/>
		</div>
	);
}
