import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { fetchEventReplies } from "../usecases/fetchEventReplies.usecase";
import { fetchRepliesCounters } from "../usecases/fetchRepliesCounters.usecase";
import { fetchStudentInvitations } from "../usecases/fetchStudentInvitations.usecase";
import { eventsSlice } from "./events.slice";

export const registerEventsFetcherListeners = (
	listener: ListenerMiddlewareInstance,
) => {
	listener.startListening({
		actionCreator: eventsSlice.actions.setEvents,
		effect: (action, api) => {
			if (typeof action.payload === "undefined") return;
			if (typeof action.payload === "object" && "studentId" in action.payload) {
				const studentId = action.payload.studentId;

				if (studentId && studentId !== "") {
					api.dispatch(fetchStudentInvitations(action.payload.tab || ""));
				}
			}
		},
	});
	listener.startListening({
		actionCreator: eventsSlice.actions.setShowRepliesListModal,
		effect: (action, api) => {
			if (action.payload.showRepliesListModal === false) {
				api.dispatch(eventsSlice.actions.resetEventReplies());
			} else {
				api.dispatch(
					fetchEventReplies(
						action.payload.eventId || "",
						action.payload.isMentorGoalEvent || false,
						0,
					),
				);
				api.dispatch(
					fetchRepliesCounters(
						action.payload.eventId || "",
						action.payload.isMentorGoalEvent || false,
					),
				);
			}
		},
	});
};
