import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";

import { cloudDownloadOutline } from "@assets/Icons";

import { downloadResumeForApplications } from "@tools/Offers";

import type { ApplicationType } from "@/types/applicationTypes";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import TooltipContainer from "@components/atoms/TooltipContainer";

export function DownloadResume() {
	const { applicationsThumbnails } = useAppSelector((state) => state.offers);
	const [downloadingCVs, setDownloadingCVs] = useState<boolean>(false);

	const handleDownloading = async () => {
		if (downloadingCVs) return;
		setDownloadingCVs(true);
		await downloadResumeForApplications(
			applicationsThumbnails.filter(
				(application: ApplicationType) => application.state === "accepted",
			),
		);
		setDownloadingCVs(false);
	};

	return (
		<span id="doawnloadCVTooltip">
			<ButtonTertiary icon={cloudDownloadOutline} onClick={handleDownloading} />
			<TooltipContainer
				anchorId="#doawnloadCVTooltip"
				children={"Télécharger tous les CV reçus"}
				place="top"
			/>
		</span>
	);
}
