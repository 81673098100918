import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";

import "@assets/sass/List.scss";

import { chevronRightOutline } from "@assets/Icons";

import { Checkbox } from "@components/atoms/Checkbox";

type ListPrimaryPropsType = {
	icon?: IconifyIcon | string;
	label: string;
	title: string;
	subtitle: string;
	onClick: () => void;
	onCheck?: ((isChecked: boolean) => void) | null;
	iconColor?: string;
	className?: string;
	checked?: boolean;
};
export function ListPrimary({
	label,
	title,
	icon = "",
	iconColor = "",
	subtitle,
	onClick,
	onCheck = null,
	className = "",
	checked = false,
}: ListPrimaryPropsType) {
	return (
		<div className={`list-container flex items-center ${className}`}>
			{onCheck && (
				<Checkbox
					checked={checked}
					className="mr-sm"
					onEnabled={() => {
						onCheck(true);
					}}
					onDisabled={() => {
						onCheck(false);
					}}
				/>
			)}
			<div role="menu" onClick={onClick} className="flex w-full flex-col">
				<div className="flex w-full items-center">
					<p className="mr-sm">{label}</p>
					<Icon className={`mr-xsm text-${iconColor}`} icon={icon} />
					<p className="title">{title}</p>
					<Icon
						className="text-primary-500P ml-auto"
						icon={chevronRightOutline}
					/>
				</div>
				<p className="mt-xsm">{subtitle}</p>
			</div>
		</div>
	);
}
