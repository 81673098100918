import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import {
	arrowIosBackFill,
	arrowIosForwardFill,
	arrowheadLeftFill,
	arrowheadRightFill,
	downloadOutline,
	minusOutline,
	plusOutline,
	printerOutline,
	searchOutline,
} from "@assets/Icons";
import { ButtonOctonary } from "@components/atoms/ButtonOctonary";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";

import { ButtonSecondary } from "./ButtonSecondary";

pdfjs.GlobalWorkerOptions.workerSrc = "/assets/pdf.worker.min.js";

const options = {
	cMapUrl: "/cmaps/",
	standardFontDataUrl: "/standard_fonts/",
};

type PdfViewerType = {
	src: string;
	title: string;
	className?: string;
};

const SCALE_STEP = 0.1;

export function PdfViewer({ src, title, className }: PdfViewerType) {
	const [numPages, setNumPages] = useState<number>();
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [scale, setScale] = useState<number>(1);
	const [fileUrl, setFileUrl] = useState<string | null>(null);
	const iframeRef = useRef<HTMLIFrameElement | null>(null);

	useEffect(() => {
		setFileUrl(null);
		setNumPages(undefined);
		setPageNumber(1);
		setScale(1);
	}, [src]);

	const onDocumentLoadSuccess = async (successEvent: any) => {
		setNumPages(successEvent.numPages);
		const data = await successEvent.getData();
		const blob = new Blob([data], { type: "application/pdf" });
		const url = URL.createObjectURL(blob);
		setFileUrl(url);
	};

	const handlePrint = () => {
		if (iframeRef.current && fileUrl) {
			iframeRef.current.src = fileUrl;
		}
	};

	const openPDF = () => {
		if (fileUrl) {
			const newWindow = window.open(fileUrl, "_blank", "noopener,noreferrer");
			if (newWindow) newWindow.opener = null;
		}
	};

	useEffect(() => {
		if (iframeRef.current && fileUrl) {
			iframeRef.current.onload = () => {
				iframeRef.current?.contentWindow?.focus();
				iframeRef.current?.contentWindow?.print();
			};
		}
	}, [fileUrl]);

	function changePage(offset: number) {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}

	const increaseScale = () => {
		setScale((prevScale) => prevScale + SCALE_STEP);
	};

	const decreaseScale = () => {
		setScale((prevScale) =>
			prevScale > 0.1 ? prevScale - SCALE_STEP : prevScale,
		);
	};

	const resetScale = () => {
		setScale(1);
	};

	function onItemClick({ pageNumber: itemPageNumber }: { pageNumber: number }) {
		setPageNumber(itemPageNumber);
	}

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const parsedValue = Number.parseInt(value); // check if value is a number
		if (Number.isNaN(parsedValue)) return;
		if (parsedValue > (numPages || 0) || parsedValue < 1) return;
		setPageNumber(parsedValue);
	};

	return (
		<>
			<div className="flex sm:hidden justify-left items-center">
				<ButtonSecondary label="Voir le PDF" onClick={() => openPDF()} />
			</div>
			<div
				className={`hidden flex-col gap-sm bg-primary-150 p-md rounded-md pdf-container ${className} overflow-x-auto sm:flex`}
			>
				{!numPages ? (
					<Skeleton width="100%" height="100%" borderRadius={10} />
				) : (
					<div className={"flex"}>
						<div
							className={"flex border p-sm rounded-md gap-sm bg-white mx-auto"}
						>
							<div className={"flex gap-xsm items-center"}>
								<ButtonOctonary
									disabled={pageNumber === 1}
									onClick={() => {
										setPageNumber(1);
									}}
									icon={arrowheadLeftFill}
								/>
								<ButtonOctonary
									disabled={pageNumber === 1}
									onClick={() => {
										changePage(-1);
									}}
									icon={arrowIosBackFill}
								/>
								<span>Page</span>
								<div className={"flex gap-xsm"}>
									<input
										className={
											"w-12 px-3 bg-primary-100 rounded-sm text-center"
										}
										value={pageNumber}
										onChange={onInputChange}
									/>
									<span>/</span>
									<span>{numPages}</span>
								</div>

								<ButtonOctonary
									disabled={pageNumber === numPages}
									onClick={() => {
										changePage(1);
									}}
									icon={arrowIosForwardFill}
								/>
								<ButtonOctonary
									disabled={pageNumber === numPages}
									onClick={() => {
										setPageNumber(numPages);
									}}
									icon={arrowheadRightFill}
								/>
							</div>

							<VerticalSeparator />

							<div className={"flex gap-md items-center"}>
								<ButtonTertiary
									icon={minusOutline}
									onClick={decreaseScale}
									disabled={scale <= 0.1}
									tooltip={"Diminuer la taille"}
								/>
								<ButtonTertiary
									icon={searchOutline}
									onClick={resetScale}
									tooltip={"Réinitialiser la taille"}
								/>
								<ButtonTertiary
									icon={plusOutline}
									onClick={increaseScale}
									tooltip={"Augmenter la taille"}
								/>
							</div>

							<VerticalSeparator />

							<div className={"flex gap-md items-center"}>
								<ButtonTertiary
									icon={downloadOutline}
									onClick={() => {
										if (!fileUrl) return;
										const link = document.createElement("a");
										link.href = fileUrl;
										link.download = title;
										link.click();
									}}
									tooltip={"Télécharger"}
								/>
								<ButtonTertiary
									icon={printerOutline}
									onClick={handlePrint}
									tooltip={"Imprimer"}
								/>
							</div>
						</div>
					</div>
				)}
				<Document
					className="flex overflow-auto"
					file={src}
					onLoadSuccess={onDocumentLoadSuccess}
					noData={"Aucun document à afficher"}
					loading={"Chargement..."}
					error={"Erreur de chargement"}
					options={options}
					onItemClick={onItemClick}
				>
					{pageNumber - 1 > 0 && (
						<Page
							scale={scale}
							pageNumber={pageNumber - 1}
							className={"hidden"}
						/>
					)}{" "}
					{/* hidden page to preload previous page */}
					<Page
						scale={scale}
						pageNumber={pageNumber}
						className={"w-fit mx-auto"}
						loading={"Chargement..."}
						error={"Erreur de chargement de la page"}
					/>
					{pageNumber + 1 <= (numPages || 1) && (
						<Page
							scale={scale}
							pageNumber={pageNumber + 1}
							className={"hidden"}
						/>
					)}{" "}
					{/* hidden page to preload next page */}
				</Document>
				<iframe ref={iframeRef} style={{ display: "none" }} title={title} />
			</div>
		</>
	);
}

const VerticalSeparator = () => (
	<div className="w-[1px] h-full bg-primary-700P" />
);
