import type { EventType } from "@/types/eventsTypes";
import { isMentorGoalEvent } from "@tools/Events";
import { isFuture } from "date-fns";

const MIN_STUDENT_FOR_COUNTERS = 7;

export const useEventCounters = (
	event: EventType,
	currentInterface: string,
) => {
	const confirmations = event?.inscriptionsCount ?? 0;
	const guests = event?.invitationsCount ?? 0;
	const participants = event.participationsCount ?? 0;

	const getLabelForCounterButton = () => {
		if (confirmations > 1) {
			if (guests > 1) {
				return `${confirmations} inscrits / ${guests} invités`;
			}
			return `${confirmations} inscrits / ${guests} invité`;
		}
		if (guests > 1) {
			return `${confirmations} inscrit / ${guests} invités`;
		}
		return `${confirmations} inscrit / ${guests} invité`;
	};

	const getParticipantsLabelForPlanned = () => {
		if (event.location === "Sur site" || isFuture(new Date(event.startDate)))
			return "";
		if (isMentorGoalEvent(event?.type) && currentInterface !== "school") {
			if (participants > MIN_STUDENT_FOR_COUNTERS) {
				return `${participants} participants`;
			}
			return `${MIN_STUDENT_FOR_COUNTERS} participants`;
		}
		return `${participants} participant${participants > 1 ? "s" : ""}`;
	};

	return {
		confirmations,
		guests,
		participants,
		labelForCounterButton: getLabelForCounterButton(),
		participantsLabelForPlanned: getParticipantsLabelForPlanned(),
	};
};
