import { format, isBefore, isSameDay } from "date-fns";
import React from "react";

type DaysPanelPropsType = {
	selectedDate: Date;
	handleOnClickOnDay: (day: number) => void;
};

export const DaysPanel = (props: DaysPanelPropsType) => {
	const { selectedDate, handleOnClickOnDay } = props;
	const currentDate = new Date();

	const numberOfDayInMonth = new Date(
		selectedDate.getFullYear(),
		selectedDate.getMonth() + 1,
		0,
	).getDate();

	const dayOfWeekStart = new Date(
		selectedDate.getFullYear(),
		selectedDate.getMonth(),
		1,
	).getDay();

	const adjustedDayOfWeekStart = dayOfWeekStart === 0 ? 6 : dayOfWeekStart - 1;

	return (
		<div className="days">
			{Array.from({ length: adjustedDayOfWeekStart }, (_, index) => {
				return (
					<div key={index} className="day-wrapper">
						<div className={"day-item"}>
							<p className="text-xsm text-primary-200" />
						</div>
					</div>
				);
			})}

			{Array.from({ length: numberOfDayInMonth }, (_, index) => {
				const oldDate = new Date(
					selectedDate.getFullYear(),
					selectedDate.getMonth(),
					index + 1,
				);

				const isPassed = isBefore(oldDate, currentDate);

				return (
					<div key={index} className="day-wrapper">
						<div
							className={`day-item ${
								isSameDay(selectedDate, oldDate) ? "selected" : ""
							} ${isSameDay(oldDate, currentDate) ? "today" : ""} ${
								isPassed ? "previous" : "future"
							}`}
							onClick={() => handleOnClickOnDay(index + 1)}
						>
							<p className="text-xsm text-primary-200">
								{format(oldDate, "d")}
							</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};
