import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function StudentsActivityFlowLoader() {
	return (
		<div>
			<Skeleton className="mt-sm w-full" height={110} />
			<Skeleton className="mt-sm w-full" height={110} />
			<Skeleton className="mt-sm w-full" height={110} />
			<Skeleton className="mt-sm w-full" height={110} />
		</div>
	);
}
