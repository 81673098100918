import confetti from "canvas-confetti";

export function bigConfetti() {
	const end = Date.now() + 2 * 1000;
	const colors = ["#edc666", "#2A9D8F"];

	(function frame() {
		confetti({
			particleCount: 2,
			angle: 60,
			spread: 55,
			origin: { x: 0 },
			colors,
		});
		confetti({
			particleCount: 2,
			angle: 120,
			spread: 55,
			origin: { x: 1 },
			colors,
		});

		if (Date.now() < end) {
			requestAnimationFrame(frame);
		}
	})();
}

export function littleConfetti() {
	confetti({
		angle: Math.random() * (125 - 55) + 55,
		particleCount: 100,
		spread: 70,
		origin: { y: 0.6 },
		colors: ["#7FC4BC", "#F4A261", "#E76F51", "#466A8B"],
	});
}
