import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
	useIsAuthenticated,
	useMsal,
} from "@azure/msal-react";
import { loginRequest } from "@config/azureAuthConfig";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { NavLink } from "react-router-dom";

import { arrowBackOutline } from "@assets/Icons";
import microsoftIcon from "@assets/logos/microsoft.svg";

import { setCookie } from "@tools/Cookies";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Spinner } from "@components/atoms/Spinner";

import { getToken } from "./LoginMicrosoftAPI";

export function LoginMicrosoft({ setIsMicrosoftLogin }) {
	const { instance, accounts } = useMsal();
	const { school } = useOutletContext();

	const isAuthenticated = useIsAuthenticated();
	const [authSuccessfull, setAuthSuccessfull] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const azureLogin = async (azureToken) => {
		await getToken(azureToken)
			.then(({ code, message, token }) => {
				if (code === 404) {
					setErrorMessage(message);
				} else {
					setAuthSuccessfull(true);
					setCookie("mainToken", token);
					location.href = "/choose_profile";
				}
			})
			.catch(() => {
				setErrorMessage(
					"Une erreur est survenue, veuillez réessayer plus tard. Si le problème persiste, merci de contacter le support Mentorgoal.",
				);
			});
	};

	function requestAccessToken() {
		const request = {
			...loginRequest,
			account: accounts[0],
		};
		instance
			.acquireTokenSilent(request)
			.then((response) => {
				azureLogin(response.accessToken);
			})
			.catch(() => {
				instance
					.acquireTokenPopup(request)
					.then((response) => {
						azureLogin(response.accessToken);
					})
					.catch(() => {
						setErrorMessage(
							"Une erreur est survenue lors de l'authentification. Si le problème persiste, veuillez contacter le support Mentorgoal.",
						);
					});
			});
	}

	const handleLogin = () => {
		const accounts = instance.getAllAccounts();

		if (accounts.length > 0) {
			instance.setActiveAccount(accounts[0]);
		}

		instance.addEventCallback(
			(event) => {
				if (event.eventType === "msal:loginSuccess" && event.payload.account) {
					const account = event.payload.account;
					instance.setActiveAccount(account);
				}
			},
			() => {
				setErrorMessage(
					"Une erreur est survenue lors de l'authentification Microsoft. Si le problème persiste, merci de contacter le support Mentorgoal.",
				);
			},
		);

		instance
			.handleRedirectPromise()
			.then(() => {
				const account = instance.getActiveAccount();
				if (!account) {
					instance.loginRedirect();
				}
			})
			.catch(() => {
				setErrorMessage(
					"Une erreur est survenue lors de l'authentification Microsoft. Si le problème persiste, veuillez contacter le support Mentorgoal.",
				);
			});
	};

	useEffect(() => {
		document.title = "Connexion - Mentor Goal";
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			requestAccessToken();
		}
	}, [isAuthenticated]);

	return (
		<div className="login ml-0 w-full md:ml-[8%] lg:w-11/12">
			{school ? (
				<div className="flex w-full items-center md:w-8/12">
					<Avatar size="lg" modify={false} image={school?.logo} alt="logo" />
					<p className="ml-sm text-md font-semibold text-primary-700P">
						Bienvenue sur votre espace {school?.name}
					</p>
				</div>
			) : (
				<h2 className="text-primary-900">Connexion</h2>
			)}
			<AuthenticatedTemplate>
				<div className="ml-0 mt-5 w-full pl-0 md:w-8/12">
					{authSuccessfull ? (
						<>
							<Spinner />
							<p className="mt-2">Connexion en cours...</p>
						</>
					) : (
						<p className="mt-3 w-9/12 text-xsm text-error-dark">
							{errorMessage}
						</p>
					)}
				</div>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<button
					onClick={handleLogin}
					className="button-primary mt-xlg flex w-full !rounded-md px-1 md:w-8/12"
					type="button"
				>
					<img src={microsoftIcon} alt="microsoftLogo" className="mr-sm" />
					Se connecter avec Microsoft
				</button>
				{errorMessage && (
					<p className="mt-3 w-9/12 text-xsm text-error-dark">{errorMessage}</p>
				)}
				<NavLink
					to={import.meta.env.VITE_URL}
					className="mt-sm flex w-full items-center md:w-8/12"
				>
					<ButtonSecondary
						onClick={() => {
							setIsMicrosoftLogin(false);
						}}
						icon={arrowBackOutline}
						label="Retourner a la page de connexion"
					/>
				</NavLink>
			</UnauthenticatedTemplate>
		</div>
	);
}
