import useDebounce from "@hooks/useDebounce";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./Promotions.scss";

import { plusFill, searchFill } from "@assets/Icons";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InputLargePrimary } from "@components/molecules/InputLargePrimary";
import { ScrollableTabGroup } from "@components/molecules/TabGroup";
import { Success } from "@components/organisms/toast/ToastNotification";

import {
	createPromotion,
	getCampus,
	getFilteredPromotions,
} from "@containers/school/Promotions/PromotionsAPI";
import { TableView } from "@containers/school/Promotions/ui/Tabs/TableView";

import { EditorPromotionModal } from "./ui/Modal/EditorPromotionModal";

const formatCampusForSelect = (campuses) => {
	const array = [];

	campuses.forEach((campus) => {
		array.push({
			id: campus.id,
			label: campus.name,
			value: campus.id,
		});
	});

	return array;
};

export function Promotions() {
	const { user } = useContext(GlobalContext);
	const [load, setLoad] = useState(true);
	const [search, setSearch] = useState("");
	const { structureView, setTitle } = useOutletContext();
	const [displayNewPromotion, setDisplayNewPromotion] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [lastPage, setLastPage] = useState(5);
	const [filters, setFilters] = useState({
		order: [{ name: "ASC" }],
		archived: false,
		page: 1,
	});
	const [promotions, setPromotions] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [campuses, setCampuses] = useState([]);
	const debouncedValue = useDebounce(search);

	const getData = async () => {
		setLoad(true);
		getCampus(user.school).then((rep) => {
			setCampuses(formatCampusForSelect(rep.campuses.collection));
			setLoad(false);
		});
	};

	const getPromotions = async () => {
		setLoad(true);
		getFilteredPromotions(structureView, filters).then(({ promotions }) => {
			setPromotions(promotions.collection);
			setTotalCount(promotions.paginationInfo.totalCount);
			setLastPage(promotions.paginationInfo.lastPage);
			setLoad(false);
		});
	};

	useEffect(() => {
		setTitle("Promotions");
		getData();
	}, []);

	useEffect(() => {
		getPromotions();
	}, [filters, structureView]);

	useEffect(() => {
		if (!load) setFilters({ ...filters, name: search, page: 1 });
	}, [debouncedValue]);

	return (
		<main className="w-full gap-4 sm:gap-6 xxl:gap-8">
			<div className="flex items-center justify-between">
				<InputLargePrimary
					icon={searchFill}
					placeholder="Recherche"
					type="text"
					className="w-fit"
					value={search}
					onChange={(e) => {
						e.target.value = e.target.value.replace(";", "");
						setSearch(e.target.value);
					}}
				/>
				<ButtonPrimary
					icon={plusFill}
					label="Ajouter une promotion"
					onClick={() => {
						setDisplayNewPromotion(!displayNewPromotion);
					}}
				/>
			</div>
			<div className="mt-md w-full p-0">
				<ScrollableTabGroup
					tabs={[
						{ label: "Active", active: tabIndex === 0 },
						{ label: "Archivée", active: tabIndex === 1 },
					]}
					onTabChange={(i) => {
						setTabIndex(i);
						switch (i) {
							case 1:
								setFilters({ ...filters, archived: true, page: 1 });
								break;
							default:
								setFilters({ ...filters, archived: false, page: 1 });
								break;
						}
					}}
				/>

				<TableView
					load={load}
					tabIndex={tabIndex}
					promotions={promotions}
					totalCount={totalCount}
					lastPageProps={{ lastPage, setLastPage }}
					updatePromos={getPromotions}
					filtersProps={{ filters, setFilters }}
					campuses={campuses}
					displayNewPromotionProps={{
						displayNewPromotion,
						setDisplayNewPromotion,
					}}
				/>
			</div>
			<EditorPromotionModal
				show={displayNewPromotion}
				title="Ajouter une promotion"
				campuses={campuses}
				onClose={() => {
					setDisplayNewPromotion(!displayNewPromotion);
				}}
				onApply={(newPromotion) => {
					createPromotion({
						archived: false,
						school: user?.school,
						name: newPromotion.name,
						campus: newPromotion.selectedCampus.value,
					}).then(() => {
						Success("La promotion a bien été créée !");
						setFilters(filters);
					});
				}}
			/>
		</main>
	);
}
