import PropTypes from "prop-types";
import React from "react";

import "../StudentDetail.scss";

import { TagsFinder } from "@components/organisms/TagsFinder/TagsFinder";

export function Tags({ schoolTags, editStudentTags, tags }) {
	return (
		<div className="tags-container w-full">
			<h3>Tags</h3>
			<section>
				<TagsFinder
					placeholder="Rechercher un tag..."
					defaultOptions={schoolTags}
					options={tags
						?.filter((x) => x.type === "school")
						.sort((a, b) => a.name.localeCompare(b.name))}
					onChange={(tags) => {
						editStudentTags("school", tags);
					}}
					onDelete={(tags) => {
						editStudentTags("school", tags);
					}}
					errorMessage={schoolTags?.length === 0 && "Aucun tag renseigné"}
				/>
			</section>
		</div>
	);
}

Tags.propTypes = {
	schoolTags: PropTypes.arrayOf({}).isRequired,
	editStudentTags: PropTypes.func.isRequired,
	tags: PropTypes.arrayOf({}).isRequired,
};
