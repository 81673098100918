import { useAppSelector } from "@config/redux/hook";
import { InlineIcon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { infoOutline } from "@assets/Icons";

import RadioGroup from "@components/atoms/RadioGroup";
import TooltipContainer from "@components/atoms/TooltipContainer";
import { Input } from "@components/molecules/Input";

import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

function ApplicationDetailModifier() {
	const dispatchEvent = useDispatch();
	const { wayToApply } = useAppSelector((state) => state.offers.offerToCreate);
	const [applicationTypeSelected, setApplicationTypeSelected] =
		useState<number>(wayToApply?.includes("@") ? 1 : 0);

	const handleApplicationTypeChange = (value: string) => {
		dispatchEvent(modifyOfferToCreate({ wayToApply: value }));
	};

	const handleApplicationTypeSwitch = (value: number) => {
		setApplicationTypeSelected(value);
	};

	useEffect(() => {
		setApplicationTypeSelected(wayToApply?.includes("@") ? 1 : 0);
	}, [wayToApply]);

	return (
		<div
			data-testid="applicationDetailModifier"
			className="flex w-full flex-col gap-3"
		>
			<div>
				<p className="text-base font-semibold text-primary-700P">
					Comment l’étudiant doit-il candidater ?
				</p>
				<RadioGroup
					elements={[
						{
							label: "Sur un site externe (ex : Indeed)",
							value: "link",
							defaultCheckedState: applicationTypeSelected === 0,
						},
						{
							label: "Via une adresse mail",
							value: "mail",
							defaultCheckedState: applicationTypeSelected === 1,
						},
					]}
					storedValue={applicationTypeSelected}
					handleOnChange={(value) => {
						handleApplicationTypeSwitch(value);
					}}
					className="mt-sm flex w-full items-start justify-start"
					direction="vertical"
				/>
			</div>
			<div className="flex flex-col gap-1">
				<div className="text-xxsm font-normal text-primary-500">
					{applicationTypeSelected === 0 ? (
						<span className="flex items-center justify-start gap-2">
							Lien du site où l'étudiant doit candidater*
							<span
								className="flex h-6 items-start justify-center"
								id="tooltipApplicationLink"
							>
								<InlineIcon
									icon={infoOutline}
									className="h-4 w-4 text-primary-300"
								/>
							</span>
						</span>
					) : (
						"Adresse mail où l'étudiant doit candidater*"
					)}
					<TooltipContainer
						anchorId="#tooltipApplicationLink"
						makeClickable={false}
						children={"Lien vers le site de l'entreprise"}
						place="top"
					/>
				</div>
				<Input
					label=""
					name="applicationLink"
					value={wayToApply || ""}
					onChange={(e) => {
						handleApplicationTypeChange(e.target.value);
					}}
					placeholder={
						applicationTypeSelected === 0
							? "https://..."
							: "example@example.com"
					}
					icon={null as any}
					className="w-full"
				/>
			</div>
		</div>
	);
}

export default ApplicationDetailModifier;
