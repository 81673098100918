import React, { useEffect, useState } from "react";
import { fetchMetabaseEmbed } from "./SchoolStatisticsRequests";

export function SchoolStatistics() {
	const [embedUrl, setEmbedUrl] = useState("");

	const getEmbedUrl = async () => {
		const response = await fetchMetabaseEmbed();
		setEmbedUrl(response);
	};

	useEffect(() => {
		getEmbedUrl();
	}, []);
	return (
		<main>
			<iframe src={embedUrl} title="metabase" className="w-full h-[75vh]" />
		</main>
	);
}
