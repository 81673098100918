import type { EventInvitationType } from "@/types/eventsInvitationType";
import { useAppSelector } from "@config/redux/hook";
import {
	type AppDispatch,
	type AppState,
	useAppDispatch,
} from "@config/redux/store";
import type { EventsState } from "@containers/shared/Events/core/store/events.slice";
import { fetchRelaunchIndividualInvitation } from "@containers/shared/Events/core/usecases/fetchRelaunchIndividualInvitation.usecase";
import { diffDate, formatDate } from "@tools/Dates";
import { useState } from "react";

export const useReplyViewCard = (replyData: EventInvitationType) => {
	const checkIfMailCanBeResent = () => {
		return (
			currentData?.reply === null &&
			!isResending &&
			(currentData?.sentAt === null || diffDate(currentData?.sentAt) > 2)
		);
	};

	const resendInvitationEmail = async (e: any) => {
		e.preventDefault();
		setIsResending(true);
		const response = await dispatchEvent(
			fetchRelaunchIndividualInvitation(
				targetEvent?.id || "",
				currentData?.student?.id || "",
				targetEvent?.type || "",
			),
		);
		if (response === false) {
			setIsResending(false);
			return;
		}
		setCurrentData({
			...currentData,
			sentAt: new Date(),
		});
		setIsResending(false);
	};

	const [currentData, setCurrentData] =
		useState<EventInvitationType>(replyData);
	const [isResending, setIsResending] = useState<boolean>(false);
	const { targetEvent } = useAppSelector(
		(state: AppState) => state.events as EventsState,
	);
	const dispatchEvent: AppDispatch = useAppDispatch();
	return {
		canResentEmail: checkIfMailCanBeResent(),
		creationDateToShow: formatDate(currentData?.createdAt),
		isResending,
		showResendDate: currentData?.sentAt !== null && currentData?.reply === null,
		hasParticipated: currentData?.hasParticipated,
		resendDate: formatDate(currentData?.sentAt),
		resendInvitationEmail,
		resendTooltip:
			currentData?.sentAt !== null && currentData?.reply === null
				? "Email envoyé il y a moins de 48h"
				: "Renvoyer l'invitation",
	};
};
