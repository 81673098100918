import axios from "axios";
import moment from "moment";

import { stringifyRequestFilters } from "@tools/Objects";

import type { SchoolType } from "@/types/schoolTypes";
import { getCookie } from "@tools/Cookies";
import api from "../tools/API";

export async function retrieveCampuses(school: string) {
	let rep = {};
	const data = {
		query: `query {
      campuses (itemsPerPage: 1000, school: "${school}") {
          collection {
            id
            name
          }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.campuses;
	});
	return rep;
}

export async function retrievePromotions(campus: string) {
	let rep = {};
	const data = {
		query: `query {
      promotions (itemsPerPage: 1000, campus: "${campus}") {
        collection {
          id
          name
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.promotions;
	});
	return rep;
}

export async function getStudentsActivityFlowData(
	view: any,
	formatView: object,
	filters: object,
) {
	let rep = {};
	const data = {
		query: `query {
      promotions (${stringifyRequestFilters(view)}, itemsPerPage:1000, archived: false) {
        collection {
          id
          name
        }
      }
      logs (${stringifyRequestFilters({
				...formatView,
				...filters,
			})}, hidden: false, itemsPerPage: 30, order: [{createdAt: "DESC"}]) {
        collection {
          user: spsStudent {
            id
            avatar {
              filePath
            }
            firstname
            lastname
          }
          application {
            id
            job
            organization : organizationV2
          }
          action
          createdAt
        },
        paginationInfo {
          totalCount
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function updateLogsConsultationLastDate(userId: string) {
	let rep = {};

	const data = {
		query: `mutation { 
      updateSpsStudent (input: {id: "${userId}", logsConsultationLastDate: "${moment(
				new Date(),
			)}"}) {
        user: spsStudent {
          logsConsultationLastDate
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getLogs(formatView: object, filters: object) {
	let rep = {};
	const data = {
		query: `query {
      logs (${stringifyRequestFilters({
				...formatView,
				...filters,
			})}, hidden: false, itemsPerPage: 25, order: [{createdAt: "DESC"}]) {
        collection {
          user: spsStudent {
            id
            avatar {
              filePath
            }
            firstname
            lastname
          }
          application {
            id
            job
            organization : organizationV2
          }
          action
          createdAt
        },
        paginationInfo {
          totalCount
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.logs;
	});
	return rep;
}

export async function getStudentDetail(id: string) {
	let rep = {};
	const data = {
		query: `query {
      user (id: "${id}") {
        id
        avatar {
          filePath
        }
        firstname
        lastname
        promotion: spsPromotion  {
          name
        }
        portfolio
        linkedin
        status
        desiredJob
        customLink1
        customLink2
        email
        phone
        address
        tags {
          collection {
            name
            color
          }
        }
        cv {
          filePath
        }
        motivationLetter {
          filePath
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getSchoolBySubdomain(
	subdomain: string,
): Promise<Partial<SchoolType>> {
	const { data }: { data: Partial<SchoolType> } = await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
				"X-Forwarded-For": getCookie("ip"),
			},
		})
		.get(`get_school_by_subdomain/${subdomain}`);

	return data;
}
