import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useMemo } from "react";

import { closeOutline, moonOutline, sunOutline } from "@assets/Icons";

import { ButtonQuinary } from "@components/atoms/ButtonQuinary";

import Chat from "@containers/school/Mailbox/ui/Chat";
import { useBreakpoints } from "@hooks/useBreakpoints";

type ConversationPanelType = {
	onClose: Function;
};

export function ConversationPanel({ onClose }: ConversationPanelType) {
	const { user } = useContext(GlobalContext);
	const { isMobile } = useBreakpoints();
	const currentHour = new Date().getHours();
	useMemo(() => {
		const elementBody = document.getElementsByTagName("body")[0];
		elementBody.style.removeProperty("overflow");
		elementBody.style.removeProperty("position");
	}, [onClose]);

	useEffect(() => {
		const elementBody = document.getElementsByTagName("body")[0];
		elementBody.style.overflow = "hidden";
		elementBody.style.position = "fixed";
	}, []);

	return (
		<div id="panel" className="h-full p-sm">
			<div className={`${!isMobile && "mb-md"} flex justify-end`}>
				<ButtonQuinary
					className=""
					disabled={false}
					shape="rectangle"
					dropdownButton={false}
					notifCount={0}
					icon={closeOutline}
					onClick={() => {
						onClose();
					}}
				/>
			</div>
			<div className="mx-xxsm">
				{!isMobile && (
					<div className="flex w-full  justify-center text-md text-primary-700P mb-10">
						{currentHour >= 4 && currentHour <= 17 ? (
							<div className="flex items-center">
								<p>
									Bonjour{" "}
									<span className="wg-no-translate">{user?.firstname}</span>
								</p>
								<Icon icon={sunOutline} className="ml-xsm text-accent-2" />
							</div>
						) : (
							<div className="flex items-center">
								<p>
									Bonsoir{" "}
									<span className="wg-no-translate">{user?.firstname}</span>
								</p>
								<Icon icon={moonOutline} className="ml-xsm text-accent-2" />
							</div>
						)}
					</div>
				)}

				<div id="panel-student" className="max-h-dvh">
					<Chat className="h-[80vh] [&>*]:ml-0 [&>hr]:!hidden !p-0" />
				</div>
			</div>
		</div>
	);
}
