import React from "react";
import Skeleton from "react-loading-skeleton";

import { getRandomItem } from "@tools/Arrays";

import "react-loading-skeleton/dist/skeleton.css";

export function SpontaneousApplicationScrappingLoader() {
	const colors = ["#e9f5e4", "#fdf0e4", "#fbe6e1", "#e9f5f4"];

	const array: string[] = new Array(24).fill("");

	return (
		<div className="mt-sm flex w-full flex-wrap justify-between gap-4">
			{array.map((_item, index) => (
				<div
					className="relative w-full rounded-md bg-white shadow-lg md:w-[48%] lg:w-[30%] xl:w-[24%]"
					key={`skeleton-scapping-${index}`}
				>
					<Skeleton height={168} baseColor={getRandomItem(colors)} />

					<div className="pb-sm pl-sm pr-sm">
						<div className="flex">
							<Skeleton
								className="mr-sm"
								height={48}
								width={48}
								circle
								baseColor={getRandomItem(colors)}
							/>
							<div>
								<Skeleton height={16} width={64} />
								<Skeleton height={16} width={144} />
							</div>
						</div>
						<div className="mt-md flex items-end justify-between">
							<div>
								<Skeleton height={16} width={216} />
								<div className="flex">
									<Skeleton
										height={16}
										width={16}
										baseColor={getRandomItem(["#e9f5e4", "#fbe6e1"])}
										circle
									/>
									<Skeleton height={16} width={100} className="ml-sm" />
								</div>
							</div>
							<div className="flex">
								<Skeleton height={36} width={36} />
								<Skeleton height={36} width={36} className="ml-sm" />
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
