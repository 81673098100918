import moment from "moment";
import { arrayOf, func, shape, string } from "prop-types";
import React from "react";
import { useLocation } from "react-router";

import "./Interview.scss";

import { INTERVIEW_TYPE_OPTIONS } from "@constants/Applications";

import { InputTime } from "@components/molecules/InputTime";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

import { editApplication } from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";

import { Success } from "@components/organisms/toast/ToastNotification";
import { getCookie } from "@tools/Cookies";
import { DatePicker } from "../../../../../../../components/organisms/DatePicker";

export function InterviewInformation({ interviewInfoProps }) {
	const { interviewInfo, setInterviewInfo } = interviewInfoProps;

	const location = useLocation();
	const applicationId = Number(location.pathname.split("/")[2]);

	const handleInterviewDate = async (date = "", hour = "") => {
		if (
			(interviewInfo?.date?.length !== 0 ||
				(date.length !== 0 ? date : interviewInfo)) &&
			(interviewInfo?.hour?.length !== 0 ||
				(hour.length !== 0 ? hour : interviewInfo))
		) {
			await editApplication({
				id: `${getCookie("interface")}/applications/${applicationId}`,
				interviewDate: `${
					date || moment(interviewInfo.date, "DD-MM-YYYY").format("YYYY-MM-DD")
				} ${hour || interviewInfo.hour}`,
			});
			Success("Modification effectuée avec succès");
		}
	};

	const getSelectedDate = () => {
		if (interviewInfo?.date === null) return undefined;
		if (interviewInfo?.date?.length !== 0) {
			return new Date(interviewInfo.date);
		}
		return undefined;
	};

	return (
		<div className="w-full">
			<div className="flex flex-wrap md:flex-nowrap">
				<div className="mr-0 w-full md:mr-xsm">
					<p className="mb-xxsm text-primary-500">Date de l&apos;entretien</p>
					<DatePicker
						withIcon
						selectedDate={getSelectedDate()}
						onDateChange={(value) => {
							const currentDate = moment(value, "DD-MM-YYYY").format(
								"YYYY-MM-DD",
							);
							setInterviewInfo({
								...interviewInfo,
								date: new Date(value),
							});
							handleInterviewDate(currentDate);
						}}
					/>
				</div>
				<div className="ml-0 mt-4 w-full md:ml-xsm md:mt-0">
					<p className="mb-xxsm text-primary-500">Heure de l&apos;entretien</p>
					<InputTime
						value={interviewInfo.hour}
						onChange={(value) => {
							setInterviewInfo({ ...interviewInfo, hour: value });
							handleInterviewDate(null, value);
						}}
					/>
				</div>
			</div>
			<div className="flex">
				<div className="w-full">
					<p className="mb-xxsm mt-sm  text-primary-500">
						Type d&lsquo;entretien
					</p>

					<SelectTertiary
						className="interview-type"
						placeholder="Choisissez un type d'entretien"
						defaultValue={INTERVIEW_TYPE_OPTIONS.find(
							(option) => option.value === interviewInfo?.interviewLocation,
						)}
						onChange={(e) => {
							editApplication({
								id: `${getCookie("interface")}/applications/${applicationId}`,
								interviewLocation: e.value,
							}).then(() => {
								Success("Modification effectuée avec succès");
							});
						}}
						options={INTERVIEW_TYPE_OPTIONS}
					/>
				</div>
			</div>
		</div>
	);
}

InterviewInformation.propTypes = {
	interviewInfoProps: shape({
		interviewInfo: shape({
			date: string,
			hour: string,
			contacts: arrayOf(string),
			location: string,
			interviewLocation: string,
		}),
		setInterviewInfo: func,
	}).isRequired,
};
