import { useAppSelector } from "@config/redux/hook";
import { APPLICATION_MAYBE_REASON_OPTIONS } from "@constants/Applications";
import { convertDateFromNow } from "@tools/Dates";
import React from "react";

export function ApplicationMessages() {
	const { applicationSelectedData } = useAppSelector((state) => state.offers);
	return (
		<aside className="flex flex-col gap-sm shadow-sm p-sm">
			{applicationSelectedData.state === "update" && (
				<p className="font-semibold text-base text-primary-700P">
					La candidature a été mise à jour par l'étudiant il y a{" "}
					{convertDateFromNow(applicationSelectedData?.updateAt)}
				</p>
			)}
			<p className="font-normal text-base text-primary-500">
				Motif :{" "}
				{APPLICATION_MAYBE_REASON_OPTIONS.get(
					applicationSelectedData?.treatmentReason,
				)}
			</p>
			<div className="flex flex-col gap-xxsm">
				<p className="text-primary-200 text-xxsm font-normal">
					Commentaire du référent :
				</p>
				<p className="text-primary-500 text-base font-normal">
					{applicationSelectedData?.messages?.[0]}
				</p>
			</div>
			{applicationSelectedData.state === "update" && (
				<div className="flex flex-col gap-xxsm">
					<p className="text-primary-200 text-xxsm font-normal">
						Commentaire de l'étudiant :
					</p>
					<p className="text-primary-500 text-base font-normal">
						{applicationSelectedData?.messages?.[1]}
					</p>
				</div>
			)}
		</aside>
	);
}
