import { Icon, InlineIcon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import "@assets/sass/StepperItem.scss";

import { arrowRightFill, clockOutline } from "@assets/Icons";
type StepperPrimaryPropsType = {
	label: string;
	className?: string;
	date?: string;
	color?: number;
	stepIndex: number;
	activeStep: number;
	displayArrow?: boolean;
	clickable?: boolean;
};

export function StepperPrimary({
	label,
	className = "",
	activeStep,
	color = 1,
	displayArrow = false,
	date,
	clickable = true,
	stepIndex,
}: StepperPrimaryPropsType) {
	const [state, setState] = useState(
		stepIndex === activeStep
			? "active"
			: stepIndex <= activeStep
				? "passed"
				: "",
	);

	useEffect(() => {
		setState(
			stepIndex === activeStep
				? "active"
				: stepIndex <= activeStep
					? "passed"
					: "",
		);
	}, [activeStep]);
	return (
		<div
			className={`${className}  ${clickable && "cursor-pointer"} ${state} ${
				state === "active" || state === "passed"
					? `bg-accent-${color}`
					: "bg-primary-100"
			} stepper-item-primary-container`}
		>
			<p>{label}</p>

			{date ? (
				<div className="days flex items-center">
					<InlineIcon className="clock" icon={clockOutline} />
					<span>{date}</span>
				</div>
			) : null}

			{displayArrow && (
				<Icon icon={arrowRightFill} className={`arrow text-accent-${color}`} />
			)}
		</div>
	);
}
