import type { BackgroundColorType, BorderColorType } from "@/types/colorsType";
import React, { type ReactNode } from "react";

type LeftBorderCardType = {
	content: ReactNode;
	className?: string;
	bgColor?: BackgroundColorType;
	borderColor?: BorderColorType;
};

export function LeftBorderCard({
	content,
	className = "",
	bgColor = "bg-primary-50",
	borderColor = "border-primary-500",
}: LeftBorderCardType) {
	return (
		<div
			className={`flex h-fit flex-col rounded-sm border-[1px] border-l-4 p-sm ${className} ${bgColor} ${borderColor}`}
		>
			<div>{content}</div>
		</div>
	);
}
