import {
	fetchGetCompanyContact,
	fetchUpdateCompanyContact,
} from "../api/companiesManagement.request";
import type { ICompanyContactGateway } from "../gateway/companyContact.gateway";
import type { CrmCompanyDomainModel } from "../model/crmCompany.domainModel";

export class InAPICompanyContactGateway implements ICompanyContactGateway {
	async getCompanyContact(id: string): Promise<CrmCompanyDomainModel.Contact> {
		const response = await fetchGetCompanyContact(id);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors du chargement des informations de l'entreprise",
			);
		}
		if (response.data.crmCompany) {
			return response.data.crmCompany;
		}
		return undefined as any;
	}
	async updateCompanyContact(
		companyId: string,
		valueToUpdate: CrmCompanyDomainModel.ContactValue[],
	): Promise<boolean> {
		const response = await fetchUpdateCompanyContact(companyId, valueToUpdate);
		if (response.responseLabel === "error") {
			throw new Error(response.message);
		}
		if (response.data) {
			return true;
		}
		return false;
	}
}
