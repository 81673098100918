import type { SelectionType } from "@/types/profileSharingTypes";
import type { UserType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Modal } from "@components/organisms/modal/Modal";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import { deleteSelectionAPI, querySelection } from "../ProfileSharingAPI";

import { trash2Outline } from "@assets/Icons";
import { InlineButton } from "@components/atoms/InlineButton";
import { Spinner } from "@components/atoms/Spinner";
import { EMAIL_SHARE_TYPE } from "@containers/school/CVBoard/ui/CVsList/TableView/ShareCVModal/ShareV2";
import { ShareSelectionEmailModal } from "@containers/school/ProfileSharing/ui/ShareSelectionEmailModal";
import { DeleteSelectionModal } from "./DeleteSelectionModal";
import { SelectionModalBody } from "./SelectionModalBody";

type SelectionModalType = {
	show: boolean;
	selectionId: string;
	onClose: () => void;
	onDelete?: () => void;
	onResend?: () => void;
	user: UserType;
};
export function SelectionModal({
	show,
	selectionId,
	onClose,
	user,
	onDelete = () => {},
	onResend = () => {},
}: SelectionModalType) {
	const [load, setLoad] = useState<boolean>(false);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
	const [selection, setSelection] = useState<SelectionType | null>(null);

	const deleteSelection = async () => {
		if (!selection) return;
		setLoad(true);
		try {
			await deleteSelectionAPI(selection.id);
			Success("La séléction a bien été supprimée.");
			onClose();
			if (onDelete) onDelete();
		} catch (_e) {
			ErrorToast("Une erreur est survenue lors de la suppression.");
		} finally {
			setLoad(false);
		}
	};

	const toggleEmailModal = () => {
		onClose();
		setShowEmailModal(!showEmailModal);
	};

	useEffect(() => {
		if ((!selection || selectionId !== selection.id) && show) {
			setSelection(null);
			querySelection(selectionId).then((selection: SelectionType) => {
				setSelection(selection);
			});
		}
	}, [selectionId, show]);

	return (
		<>
			<Modal
				translateTitle={false}
				title={`Partage de profil ${selection ? `- ${selection.name!}` : ""}`}
				size="lg"
				body={
					selection ? (
						<SelectionModalBody
							selection={selection}
							user={user}
							load={load}
							toggleEmailModal={toggleEmailModal}
						/>
					) : (
						<div className={"flex w-full items-center justify-center my-2"}>
							<Spinner />
						</div>
					)
				}
				buttonsRight={[
					<ButtonPrimary disabled={load} onClick={onClose} label="Fermer" />,
				]}
				buttonsLeft={
					selection
						? [
								<InlineButton
									id="delete-selection"
									label="Supprimer le partage"
									icon={trash2Outline}
									onClick={() => {
										setShowConfirmation(true);
									}}
								/>,
							]
						: []
				}
				onClose={onClose}
				show={show}
			/>

			<DeleteSelectionModal
				show={showConfirmation}
				onClose={() => setShowConfirmation(false)}
				onDelete={() => {
					setShowConfirmation(false);
					deleteSelection();
				}}
			/>

			<ShareSelectionEmailModal
				show={showEmailModal && selection?.type === EMAIL_SHARE_TYPE}
				selection={selection!}
				onClose={() => {
					setShowEmailModal(false);
					setSelection(null);
					if (onResend) onResend();
				}}
			/>
		</>
	);
}
