import React from "react";
import Skeleton from "react-loading-skeleton";

export const HeaderLoader = () => {
	return (
		<header className="flex flex-col">
			<div className="mx-2 w-full flex sm:max-w-xl md:max-w-2xl lg:max-w-6xl xl:max-w-6xl my-2 gap-4 md:mx-auto">
				<Skeleton circle height={60} width={60} />
				<div className="flex flex-row">
					<div className={"hidden sm:!flex sm:flex-row"}>
						<span className="flex self-center text-black font-semibold">
							<Skeleton width={100} height={24} />
						</span>
						<hr className="self-center vertical-separator" />
					</div>
					<span className="flex self-center text-black font-semibold">
						<Skeleton width={100} height={24} />
					</span>
				</div>
			</div>
			<Skeleton inline containerClassName={"h-0 mt-[-8px]"} height={8} />
		</header>
	);
};
