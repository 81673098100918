import { Icon } from "@iconify/react";
import React from "react";

import { RedirectCountDown } from "./RedirectCountDown";

export const SuccessRegistration = ({
	redirectUrl,
}: {
	redirectUrl: string;
}) => {
	return (
		<div className="flex flex-col items-center justify-center p-8 gap-8">
			<h1 className="text-2xl font-bold text-center">
				Merci pour votre inscription !
			</h1>
			<Icon
				icon="eva:checkmark-circle-2-outline"
				className="text-9xl font-bold text-green-500"
			/>
			<p className="text-center">
				Nous vous contacterons prochainement pour vous donner suite à votre
				demande.
			</p>
			{redirectUrl.length > 0 && <RedirectCountDown url={redirectUrl} />}
		</div>
	);
};
