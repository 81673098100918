import axios from "axios";

export async function getToken(token) {
	let rep = {};
	await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
		})
		.post(`azure_login/${token}`)
		.then(({ data }) => {
			rep = data;
		});
	return rep;
}
