import { Icon } from "@iconify/react";
import React from "react";
export const HeaderButton = ({
	icon,
	label,
	iconColor,
	bgColor,
	onClick = () => {},
}: {
	icon: string;
	label: string;
	iconColor: string;
	bgColor: string;
	onClick?: () => void;
}) => {
	return (
		<button
			className={
				"flex px-1.5 py-1 justify-center items-center border rounded-md gap-2 bg-white"
			}
			onClick={onClick}
		>
			<div
				className={`flex justify-center items-center w-6 h-6 ${bgColor} rounded`}
			>
				<Icon icon={icon} className={`text-xsm ${iconColor}`} />
			</div>
			<span className="text-sm font-semibold">{label}</span>
		</button>
	);
};
