import React from "react";
import Skeleton from "react-loading-skeleton";

export function InformationsSkeletons() {
	return (
		<div className="flex flex-col justify-start items-start gap-sm p-sm">
			<Skeleton width={500} height={48} />
			<div className="flex gap-sm">
				<Skeleton width={48} height={48} className="rounded-full" />
				<Skeleton width={400} height={48} />
			</div>
			<Skeleton width={300} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={250} height={48} />
			<Skeleton width={200} height={48} />
		</div>
	);
}
