import type { IconifyIcon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import { InputLargePrimary } from "./InputLargePrimary";
import { ItemSimpleLarge } from "./ItemSimpleLarge";

type ComboboxPropsType = {
	placeholder?: string | null;
	className?: string;
	options: { [key: string]: any }[];
	errorMessage?: string | undefined | boolean;
	onChange?: (target: { target: { value: string } }) => void;
	onBlur?: (target: { target: { value: string } }) => void;
	value?: string;
	label?: string;
	disabled?: boolean;
	onClick?: () => void;
	onClickOnItem?: (elem: any) => void;
	icon?: IconifyIcon | string;
	hideAvatar?: boolean;
};
export function Combobox({
	placeholder = "",
	className = "",
	options,
	errorMessage = "",
	onChange = () => {},
	onBlur = () => {},
	value = "",
	label = "",
	disabled = false,
	icon = "",
	onClickOnItem = () => {},
	hideAvatar = false,
}: ComboboxPropsType) {
	const [isOpen, setIsOpen] = useState(false);
	const [inputValue, setInputValue] = useState(value);
	const [width, setWidth] = useState(0);
	const ref = useRef(null);
	const refDropdownList = useRef(null);

	useEffect(() => {
		const checkModalDisplay = document.querySelector('[id*="modal"]');

		if (checkModalDisplay && refDropdownList.current) {
			// @ts-ignore
			const dropdownList = refDropdownList.current?.getBoundingClientRect();
			const bottomYdropdownList = dropdownList.y + dropdownList.height;

			if (window.innerHeight < bottomYdropdownList) {
				const translateY = -(dropdownList.height + 40);
				// @ts-ignore
				refDropdownList.current.style.transform = `translate3d(0px,${translateY}px,10px)`;
			} else {
				// @ts-ignore
				refDropdownList.current.style.transform = null;
			}
		}

		// @ts-ignore
		setWidth(ref.current.offsetWidth);
		const handleClickOutside = (event: any) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	return (
		<div data-id="combobox" ref={ref} className={`relative ${className}`}>
			{label && <p className="text-xxsm mb-xxsm text-primary-500">{label}</p>}
			<InputLargePrimary
				icon={icon}
				onChange={(e) => {
					onChange(e);
					setInputValue(e.target.value);
				}}
				onBlur={(e) => {
					onBlur(e);
					setInputValue(e.target.value);
				}}
				placeholder={placeholder ?? ""}
				value={inputValue}
				disabled={disabled}
				className={`${errorMessage && "error-message"} ${
					disabled && "disabled"
				} bg-primary-50 text-primary-700P outline-none`}
				onFocus={() => {
					setIsOpen(true);
				}}
			/>
			{errorMessage && (
				<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
			)}

			{options.length > 0 && isOpen && (
				<div className="relative">
					<div className={"absolute top-0"}>
						<div
							ref={refDropdownList}
							style={{ width: `${width}px` }}
							className={`z-10 fixed bg-white shadow-sm max-h-[215px] overflow-y-auto rounded-sm ${
								label && "with-label"
							}`}
						>
							{options.map((elem, i) => {
								return (
									<ItemSimpleLarge
										key={i}
										id={`options-item-${i}`}
										onClick={(e: any) => {
											e!.stopPropagation();
											onClickOnItem(elem);
											onChange({ target: { value: elem.label } });
											onBlur({ target: { value: elem.label } });
											setInputValue(elem.label);
											setIsOpen(false);
										}}
										avatar={
											!hideAvatar
												? {
														image: elem.logo || elem.image,
														firstname: elem.firstname || elem.label,
														lastname: elem.lastname || elem.label,
													}
												: null
										}
										label={elem.label}
									/>
								);
							})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
