import api from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
import axios from "axios";

export async function getProfileApi(schoolSub?: string) {
	try {
		const { data } = await api.post("get_current_employee", {
			school: schoolSub || null,
		});
		return data.data;
	} catch (e) {
		throw new Error(e);
	}
}

export async function openInvitationApi(invitationId: string) {
	await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.get(`/open_invitation/${invitationId}`);
}

export async function getNoSchoolDataApi(user: number) {
	const { data, status } = await api.post("get_current_employee_schools", {
		user,
	});
	if (status !== 200) {
		throw new Error();
	}
	return data;
}

export async function createProfileApi(
	school: string,
	company: string | number,
) {
	try {
		const newProfile = {
			school: school,
			entCompany:
				typeof company === "number" ? `api/ent_companies/${company}` : company,
			display: true,
		};
		const query = {
			query: `mutation {
            createEntCompanySchoolProfile(input: ${stringifyRequestInputs(
							newProfile,
						)}) {
                entCompanySchoolProfile {
                  id
                }
            }
        }`,
		};
		const { data } = await api.post("graphql", query);
		let newProfileId =
			data.data.createEntCompanySchoolProfile.entCompanySchoolProfile.id;
		newProfileId = Number.parseInt(
			newProfileId.split("/")[newProfileId.split("/").length - 1],
		);
		return Number.parseInt(newProfileId);
	} catch (e) {
		throw new Error(e);
	}
}
