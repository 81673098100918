import type { ApplicationType } from "@/types/applicationTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { OfferType } from "@/types/offerTypes";
import type { TagType } from "@/types/tagTypes";
import { createSlice } from "@reduxjs/toolkit";

type offerDetailSliceState = {
	loading: boolean;
	offer?: OfferType;
	defaultCv?: MediaObjectType;
	showMinifyHead: boolean;
	application?: ApplicationType;
	isSendingApplication?: boolean;
};

const initialState: offerDetailSliceState = {
	loading: true,
	offer: undefined,
	defaultCv: undefined,
	showMinifyHead: false,
	application: undefined,
	isSendingApplication: false,
};

const offerDetailSlice = createSlice({
	name: "offerDetailSlice",
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setOffer(state, action) {
			const offer = action.payload;
			if (action.payload.tags) {
				const filteredTags = action.payload.tags.filter(
					(tag: TagType) => tag.type !== "school",
				);
				offer.tags = filteredTags;
			}
			state.offer = offer;
		},
		setDefaultCv(state, action) {
			state.defaultCv = action.payload;
		},
		setShowMinifyHead(state, action) {
			state.showMinifyHead = action.payload;
		},
		setApplication(state, action) {
			state.application = action.payload;
		},
		setIsSendingApplication(state, action) {
			state.isSendingApplication = action.payload;
		},
	},
});

export const {
	setLoading,
	setOffer,
	setDefaultCv,
	setShowMinifyHead,
	setApplication,
	setIsSendingApplication,
} = offerDetailSlice.actions;

export default offerDetailSlice.reducer;
