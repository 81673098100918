import { graphqlRequestConstructor } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function fetchGetReferents(
	campusList: string[],
	schoolId: string,
) {
	const queryToSend = {
		query: `
      query {
        campusReferents : spsReferents (itemsPerPage: 1000, coreCampuses_list: ${stringifyRequestInputs(
					campusList,
				)}){
            collection {
                id
                firstname
                lastname
            }
          }
        schoolReferents: spsReferents (itemsPerPage: 1000, coreSchool: "${schoolId}"){
            collection {
                id
                firstname
                lastname
            }
        }
    }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getReferents",
		"Erreur lors de la récupération des référents",
	);
	return rep;
}

/* HERE, add avatar fetching ? */
