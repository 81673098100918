import type { OfferVisibilityLabelType } from "@/types/offerTypes";
import type { TagType } from "@/types/tagTypes";
import type { UserType } from "@/types/userTypes";
import { Icon, type IconifyIcon } from "@iconify/react";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import React, { type ReactElement } from "react";

import { formatDate } from "@tools/Dates";
import { getDotColorFromNumber } from "@tools/Table";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { TagsGroup } from "@components/atoms/TagsGroup";
import type { CrmCompanyDomainModel } from "@containers/school/Companies/core/model/crmCompany.domainModel";

export const cellFactory = () => {
	const title = (label: string): ReactElement => {
		return (
			<div className="w-max max-w-xs truncate text-xsm font-semibold text-primary-700P">
				{label}
			</div>
		);
	};
	const text = (label?: string, icon?: IconifyIcon): ReactElement => {
		return (
			<div className="flex w-full items-center justify-start gap-4 text-xsm font-normal text-primary-700P">
				{icon && <Icon icon={icon} className="h-4 w-4 text-accent-4" />}
				<p className="truncate">{label || ""}</p>
			</div>
		);
	};
	const number = (
		numberToShow: number,
		showDot = false,
		showZero = true,
	): ReactElement => {
		const defaultValueToDisplay = () => {
			if (showZero) {
				return 0;
			}
			return "-";
		};

		return (
			<div className="flex w-full items-center justify-start gap-2 text-xsm font-normal text-primary-700P">
				{showDot && (
					<div
						className={`h-3 w-3 rounded-full ${
							!showZero ? "bg-primary-150" : getDotColorFromNumber(numberToShow)
						}`}
					/>
				)}
				<div>
					{numberToShow === 0 || numberToShow === null
						? defaultValueToDisplay()
						: numberToShow}
				</div>
			</div>
		);
	};
	const tag = (tagSettings: OfferVisibilityLabelType): ReactElement => {
		return (
			<div className="whitespace-nowrap">
				<Badge
					label={tagSettings?.shortLabel || ""}
					size="medium"
					bgColor={tagSettings?.bgColor || "bg-stone-light"}
					hasBorder
					className={`
					${tagSettings?.textColor}
					${tagSettings?.borderColor}
					`}
				/>
			</div>
		);
	};
	const multiTag = (tags: TagType[]): ReactElement => {
		return (
			<div className="whitespace-nowrap">
				<TagsGroup tags={tags} limit={2} />
			</div>
		);
	};
	const action = (
		actionHandler: Function,
		icon?: IconifyIcon,
		buttonDesign?: "primary" | "secondary" | "tertiary",
		label?: string,
		dataToTransmit?: Object,
	): ReactElement => {
		switch (buttonDesign) {
			case "primary":
				return (
					<ButtonPrimary
						label={label}
						icon={icon || null}
						size="large"
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							actionHandler(dataToTransmit || e);
						}}
						className=""
						disabled={false}
						dropdownButton={false}
						shape=""
						id=""
						type=""
					/>
				);
			case "secondary":
				return (
					<ButtonPrimary
						label={label}
						icon={icon || null}
						size="large"
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							actionHandler(dataToTransmit || e);
						}}
						className=""
						disabled={false}
						dropdownButton={false}
						shape=""
						id=""
						type=""
					/>
				);
			case "tertiary":
				return (
					<ButtonTertiary
						label={label || undefined}
						icon={icon || (undefined as any)}
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							actionHandler(dataToTransmit || e);
						}}
					/>
				);
			default:
				return (
					<ButtonPrimary
						label={label}
						icon={icon || null}
						iconPlacement="left"
						size="small"
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							actionHandler(dataToTransmit || e);
						}}
						className=""
						disabled={false}
						dropdownButton={false}
						shape=""
						id=""
						type=""
					/>
				);
		}
	};
	const person = (
		userData: Partial<UserType>,
		useAvatar = false,
	): ReactElement => {
		return (
			<div className="flex w-full max-w-[390px] truncate items-center justify-start gap-2">
				{userData && useAvatar ? (
					<Avatar
						size="xs"
						firstname={userData?.firstname || ""}
						lastname={userData?.lastname || ""}
						image={userData?.avatar?.filePath || ""}
					/>
				) : (
					<p>{useAvatar && "-"}</p>
				)}
				<div className="line-clamp-2 w-max max-w-xs truncate text-base font-normal text-primary-700P">
					{userData?.firstname || ""} {userData?.lastname || ""}
				</div>
			</div>
		);
	};

	const company = (
		companyData: CrmCompanyDomainModel.Company,
		useAvatar = false,
	): ReactElement => {
		return (
			<div className="flex w-full max-w-[390px] truncate items-center justify-start gap-4">
				{companyData && useAvatar ? (
					<Avatar
						size="xs"
						firstname={companyData?.name}
						lastname={companyData?.name}
						image={companyData?.logo?.toString()}
					/>
				) : (
					<p>{useAvatar && "-"}</p>
				)}
				<div className="line-clamp-2 w-max max-w-xs truncate text-base font-normal text-primary-700P">
					{companyData?.name || ""}
				</div>
			</div>
		);
	};

	const date = (dateToShow: string, useDistanceToNow = false): ReactElement => {
		if (!dateToShow || dateToShow === "") {
			return (
				<div className="flex w-full items-center justify-start text-base font-normal text-primary-700P">
					-
				</div>
			);
		}
		return (
			<div className="flex w-full items-center justify-start text-base font-normal text-primary-700P">
				{useDistanceToNow
					? formatDistanceToNow(new Date(dateToShow), {
							addSuffix: true,
							locale: fr,
						})
					: formatDate(dateToShow)}
			</div>
		);
	};

	const testing = (textForTest: string): ReactElement => {
		return <div>{textForTest}</div>;
	};

	return {
		text,
		title,
		number,
		tag,
		multiTag,
		action,
		person,
		company,
		date,
		testing,
	};
};
