import type { FiltersType } from "@/types/usersFiltersTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { PAGES_WITH_TABLE } from "@constants/Table";

import {
	getCookie,
	retrieveCookiesNameFromUrl,
	setCookie,
} from "@tools/Cookies";

const filtersConsidered: string[] = [
	"spsPromotion_list",
	"spsPromotion_archived",
	"status_list",
	"lastConnexion",
	"tags",
	"contractSearched_list",
	"position",
	"birthdate",
];

const DEFAULT_CURRENT_FILTERS = {
	page: 1,
	spsPromotion_list: undefined,
	spsPromotion_archived: false,
	state: "all",
	status_list: [0],
	contractSearched_list: [],
	tags: undefined,
	lastConnexion: undefined,
	location: undefined,
	position: undefined,
	max_distance: undefined,
	birthdate: null,
};

export interface UsersFiltersState {
	filtersCounter: number;
	currentFilters: FiltersType;
	currentSearch: string;
	filterCategory: string;
	currentStructure: string;
	showFilters: boolean;
}

const loadFiltersUsingCookies = () => {
	const filters = getCookie(retrieveCookiesNameFromUrl());

	const newFilters = {
		order: undefined,
		...DEFAULT_CURRENT_FILTERS,
	};

	if (filters) {
		const { currentFilters } = JSON.parse(filters);

		if (currentFilters?.spsPromotion_list) {
			newFilters.spsPromotion_list = currentFilters.spsPromotion_list;
		}
		if (currentFilters?.promotion_archived !== undefined) {
			newFilters.spsPromotion_archived = currentFilters.spsPromotion_archived;
		}
		if (currentFilters?.state !== undefined) {
			const matchPage = PAGES_WITH_TABLE.find(
				(page) => page.path === window.location.href.split("/")[3],
			);

			newFilters.state = matchPage?.tabs.includes(currentFilters.state)
				? currentFilters.state
				: "all";
		}
		if (currentFilters?.status_list !== undefined) {
			newFilters.status_list = currentFilters.status_list;
		}
		if (currentFilters?.contractSearched_list !== undefined) {
			newFilters.contractSearched_list = currentFilters.contractSearched_list;
		}
		if (currentFilters?.tags !== undefined) {
			newFilters.tags = currentFilters.tags;
		}
		if (currentFilters?.lastConnexion !== undefined) {
			newFilters.lastConnexion = currentFilters.lastConnexion;
		}
		if (currentFilters?.max_distance !== undefined) {
			newFilters.max_distance = currentFilters.max_distance;
		}
		if (currentFilters?.position !== undefined) {
			newFilters.position = currentFilters.position;
		}
		if (currentFilters?.location !== undefined) {
			newFilters.location = currentFilters.location;
		}
		if (currentFilters?.birthdate !== undefined) {
			newFilters.birthdate = currentFilters.birthdate;
		}
		if (currentFilters?.page !== undefined) {
			newFilters.page = currentFilters.page;
		}
		if (currentFilters?.order !== undefined) {
			newFilters.order = currentFilters.order;
		}
	}
	return newFilters;
};

const loadSearchUsingCookies = () => {
	const filters = getCookie(retrieveCookiesNameFromUrl());
	let newSearch = "";
	if (filters) {
		const { currentSearch } = JSON.parse(filters);
		if (currentSearch) {
			newSearch = currentSearch;
		}
	}
	return newSearch;
};

const initialState: UsersFiltersState = {
	filtersCounter: 0,
	currentFilters: loadFiltersUsingCookies(),
	currentSearch: "",
	currentStructure: "",
	filterCategory: "all",
	showFilters: true,
};

const resetState: UsersFiltersState = {
	filtersCounter: 2,
	currentFilters: DEFAULT_CURRENT_FILTERS,
	currentSearch: "",
	currentStructure: "",
	filterCategory: "all",
	showFilters: true,
};

export const reloadFiltersFromCookies = createAsyncThunk(
	"usersFilters/reloadFiltersFromCookies",
	async () => {
		const currentFiltersLoaded = loadFiltersUsingCookies();
		return currentFiltersLoaded;
	},
);

const usersFiltersSlice = createSlice({
	name: "usersFilters",
	initialState,
	reducers: {
		resetUsersFilters(state) {
			const currentStructureCopy = state.currentStructure;
			const currentSearchCopy = state.currentSearch;
			const currentStateCopy = state.currentFilters.state;
			state.currentFilters = resetState.currentFilters;
			state.currentSearch = resetState.currentSearch;
			state.filtersCounter = resetState.filtersCounter;
			state.filterCategory = resetState.filterCategory;
			setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
			// preserve structure and search
			if (currentStructureCopy !== undefined) {
				state.currentStructure = currentStructureCopy;
			}

			if (currentSearchCopy !== undefined) {
				state.currentSearch = currentSearchCopy;
			}

			if (currentStateCopy !== undefined) {
				state.currentFilters = {
					...state.currentFilters,
					state: currentStateCopy,
				};
			}
		},
		setFiltersCounter(state, action) {
			state.filtersCounter = action.payload;
		},
		setCurrentFilters(state, action) {
			state.currentSearch = initialState.currentSearch;
			state.currentFilters = action.payload;
			setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
		},
		setPageState(state, action) {
			const currentSearchCopy = state.currentSearch;
			state.currentFilters = action.payload;
			// preserve actual search when switch state
			state.currentSearch = currentSearchCopy;
			setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
		},
		setPagination(state, action) {
			const currentSearchCopy = state.currentSearch;
			state.currentFilters.page = action.payload.page;
			// preserve actual search when switch page
			state.currentSearch = currentSearchCopy;
			setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
		},
		countCurrentFilters(state) {
			let count = 0;
			if (state.currentFilters === undefined) return;
			Object.keys(state.currentFilters).map(
				(filter: keyof typeof state.currentFilters) => {
					if (filtersConsidered.includes(filter)) {
						const filterValue =
							state.currentFilters[filter as keyof typeof state.currentFilters];
						if (filterValue !== undefined) {
							if (Array.isArray(filterValue)) {
								if (filterValue.length > 0) {
									count++;
								}
							} else {
								// you can add specific case here
								count++;
							}
						}
					}
					return count;
				},
			);
			state.filtersCounter = count;
		},
		setCurrentSearch(state, action) {
			state.currentSearch = action.payload;
			if (state.currentSearch === "" || state.currentSearch === undefined) {
				state.showFilters = true;
			} else {
				state.showFilters = false;
				state.currentFilters.page = 1;
			}
			setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
		},
		removeSpecificFilter(state, action) {
			const filterName = action.payload;
			if (state.currentFilters === undefined) return;
			if (
				state.currentFilters[
					filterName as keyof typeof state.currentFilters
				] !== undefined
			) {
				delete state.currentFilters[
					filterName as keyof typeof state.currentFilters
				];
				setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
			}
		},
		setCurrentStructure(state, action) {
			state.currentStructure = action.payload;
		},
		performReloadOnSearch(state) {
			const currentSearchLoaded = loadSearchUsingCookies();
			state.currentSearch = currentSearchLoaded;
		},
		setShowFilters(state, action) {
			state.showFilters = action.payload;
			setCookie(retrieveCookiesNameFromUrl(), JSON.stringify(state));
		},
	},
	extraReducers(builder) {
		builder.addCase(reloadFiltersFromCookies.fulfilled, (state, action) => {
			state.currentFilters = action.payload;
		});
	},
});

export const {
	setFiltersCounter,
	resetUsersFilters,
	setCurrentFilters,
	countCurrentFilters,
	removeSpecificFilter,
	setCurrentSearch,
	setPageState,
	setPagination,
	setCurrentStructure,
	performReloadOnSearch,
	setShowFilters,
} = usersFiltersSlice.actions;

export default usersFiltersSlice.reducer;
