import React, { type ChangeEvent } from "react";
import { useRef } from "react";
import { useEffect } from "react";

import "@assets/sass/Avatar.scss";

import { editOutline, trash2Outline } from "@assets/Icons";

import { checkImage } from "@tools/Checkers";

import { ButtonQuaternary } from "./ButtonQuaternary";

type AvatarTypes = {
	firstname?: string;
	lastname?: string;
	image: string | null;
	className?: string;
	onChange?: Function;
	onDelete?: Function;
	modify?: boolean;
	bgColor?: string;
	size?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl";
};

export const getInitials = (firstname = "", lastname = "") => {
	let name = "aa";
	if (firstname.length > 1 && !lastname) name = firstname[0] + firstname[1];
	else if (lastname.length > 1 && !firstname) name = lastname[0] + lastname[1];
	else if (lastname && firstname) name = firstname[0] + lastname[0];
	return name;
};

export function Avatar({
	firstname = "",
	lastname = "",
	image,
	onDelete,
	modify = false,
	onChange,
	className = "",
	bgColor,
	size = "sm",
}: AvatarTypes) {
	const handleOnDelete = () => {
		if (onDelete) onDelete();
	};

	const AvatarInputRef = useRef<HTMLInputElement | null>(null);

	const getSize = () => {
		if (size === "xxs") {
			return "w-[25px] h-[25px] min-w-[25px] text-xxsm";
		}
		if (size === "xs") {
			return "w-[32px] h-[32px] min-w-[32px] text-xxsm";
		}
		if (size === "sm") {
			return "w-[44px] h-[44px] min-w-[44px] text-sm";
		}
		if (size === "md") {
			return "w-[60px] !h-[60px] min-w-[60px] text-md";
		}
		if (size === "lg") {
			return "w-[72px] h-[72px] min-w-[72px] text-lg";
		}
		if (size === "xl") {
			return "w-[144px] h-[144px] min-w-[144px] text-xlg";
		}
		return "w-[96px] h-[96px] min-w-[96px] text-lg";
	};

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target?.files?.[0];
		if (!file) return;
		const url = URL.createObjectURL(file);

		if (onChange) onChange({ file, url });
	};

	const getColor = () => {
		const firstnameLetterPosition =
			firstname?.toUpperCase()?.charCodeAt(0) - 64;
		if (firstnameLetterPosition <= 3) {
			return "bg-aquamarine-light text-aquamarine-dark";
		}
		if (firstnameLetterPosition >= 4 && firstnameLetterPosition <= 6) {
			return "bg-sunflower-light text-sunflower-dark";
		}
		if (firstnameLetterPosition >= 7 && firstnameLetterPosition <= 10) {
			return "bg-terracotta-light text-terracotta-dark";
		}
		if (firstnameLetterPosition >= 11 && firstnameLetterPosition <= 14) {
			return "bg-lavender-light text-lavender-dark";
		}
		if (firstnameLetterPosition >= 15 && firstnameLetterPosition <= 19) {
			return "bg-sakura-light text-sakura-dark";
		}
		if (firstnameLetterPosition >= 20 && firstnameLetterPosition <= 23) {
			return "bg-kaki-light text-kaki-dark";
		}
		return "bg-emerald-light text-emerald-dark";
	};

	useEffect(() => {}, [image]);

	return (
		<div
			className={`${className} wg-no-translate group relative flex aspect-square ${getSize()} items-center justify-center ${
				image ? "overflow-hidden" : ""
			} rounded-full ${bgColor || getColor()} `}
		>
			{image && (image.includes("http") || checkImage(image)) ? (
				<img
					onError={() => {}}
					src={
						image.includes("http")
							? image
							: `${import.meta.env.VITE_S3}${image}`
					}
					alt="avatar"
					className="aspect-square h-full w-full object-cover"
				/>
			) : (
				<p className="font-bold uppercase">
					{getInitials(firstname, lastname)}
				</p>
			)}
			{modify && (
				<div className="absolute bottom-0 hidden h-2/6 w-full bg-primary-100 p-xxsm group-hover:flex">
					<ButtonQuaternary
						icon={editOutline}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							AvatarInputRef.current?.click();
						}}
						className="h-6 w-6/12 p-xxsm"
					/>
					<ButtonQuaternary
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleOnDelete();
						}}
						icon={trash2Outline}
						className="h-6 w-6/12 p-xxsm"
					/>
				</div>
			)}
			<input
				ref={AvatarInputRef}
				onChange={handleOnChange}
				type="file"
				accept="image/png, image/jpeg"
				className="hidden"
			/>
		</div>
	);
}
