import React from "react";
type AttentionDotType = {
	className?: string;
	bgColor?: "bg-error-dark" | "bg-success-dark" | "bg-warning-dark";
};
export function AttentionDot({
	className = "",
	bgColor = "bg-error-dark",
}: AttentionDotType) {
	return (
		<div className={`h-full flex ${className}`}>
			<span className={`w-2 h-2 mb-auto rounded-full ${bgColor}`} />
		</div>
	);
}
