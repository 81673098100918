import React from "react";
import Skeleton from "react-loading-skeleton";

export function MySearchSkeleton() {
	return (
		<div className="flex flex-col gap-sm">
			<p className="font-bold text-[18px]">Que recherches-tu ?</p>
			<div className="flex flex-col gap-lg">
				<Skeleton
					containerClassName="flex gap-sm justify-between"
					width={"30%"}
					height={150}
					count={3}
					enableAnimation={true}
					highlightColor="#f3f3f3"
					borderRadius={16}
				/>

				<div>
					<p className="text-primary-300 text-xsm">À partir de quelle date ?</p>
					<Skeleton height={24} />
				</div>
				<div>
					<p className="text-primary-300 text-xsm">Pour quel poste ?</p>
					<Skeleton height={24} />
				</div>
				<div>
					<p className="text-primary-300 text-xsm">
						Dans quelle zone géographique ?
					</p>
					<Skeleton height={24} />
				</div>
				<div>
					<p className="text-primary-300 text-xsm">
						Ta mobilité géographique ?
					</p>
					<Skeleton height={24} />
				</div>
			</div>
		</div>
	);
}
