import axios from "axios";

import api from "@tools/API";
import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";

function extractCityFrom(filters) {
	const filtersUpdated = { ...filters };
	if (filtersUpdated.locations?.toLowerCase().includes("arrondissement")) {
		const arrayWords = filtersUpdated.locations.split(" ");
		const cityWithoutBorough = arrayWords[arrayWords.length - 3];
		if (cityWithoutBorough?.length) {
			filtersUpdated.locations = cityWithoutBorough;
		}
	}
	return filtersUpdated;
}

export async function spontaneousApplicationData(user, filters, tab) {
	const filtersToUse = extractCityFrom(filters);
	let rep = {};
	const data = {
		query: `query {
      organizations:  ${
				tab === "mg" ? "mgOrganizations" : "spsOrganizations"
			}(itemsPerPage: 24, ${stringifyRequestFilters(filtersToUse)}${
				tab === "mg" ? "" : `, school: "${user?.school}"`
			}){
        collection {
          id 
          name
          locations
          size
          linkedin
          website
          businessSector
          illustration
          logo
          phone
        },
        paginationInfo {
          totalCount
          lastPage
        }
      },
      applications (itemsPerPage: 1000, spsStudent: "${user?.id}") {
        collection {
          id
          step
          organization : organizationV2
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});

	return rep;
}

export async function removeOrganization(organizationId) {
	let rep = {};
	const data = {
		query: `mutation {
      deleteSpsOrganization(input: {id: "${organizationId}"}) {
        organization: spsOrganization {
          id
          name
        }
      }
    }`,
	};
	await api
		.post("graphql", data)
		.then(({ data }) => {
			rep = data.data.deleteSpsOrganization.organization;
			return rep;
		})
		.catch((e) => {
			throw new Error(e);
		});
}

export async function retrieveOrganizations(user, filters, tab) {
	let rep = {};
	const filtersToUse = extractCityFrom(filters);
	const data = {
		query: `query {
      organizations : ${
				tab === "mg" ? "mgOrganizations" : "spsOrganizations"
			}(itemsPerPage: 24, ${JSON.stringify(filtersToUse)
				.replace(/"([^"]+)":/g, "$1:")
				.substring(1)
				.slice(0, -1)}${tab === "mg" ? "" : `, school: "${user?.school}"`}) {
        collection {
          id
          name
          locations
          linkedin
          size
          businessSector
          website
          illustration
          logo
          phone
        },
        paginationInfo {
          totalCount
          lastPage
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.organizations;
	});

	return rep;
}

export async function createSpontanenousApplication(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
      createApplication(input: ${stringifyRequestInputs(inputs)}) {
        application {
          id
          accepted
          favorite
          job
          step
          archived
          organization : organizationV2
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.createApplication.application;
	});
	return rep;
}

export async function autoComplet(value = "") {
	let rep = {};
	await axios
		.get(
			`https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`,
		)
		.then(({ data }) => {
			rep = data;
		});
	return rep;
}

export async function addOrganization(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
      createSpsOrganization(input: ${stringifyRequestInputs(inputs)}) {
        organization: spsOrganization {
          id
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.createSpsOrganization.organization;
	});
	return rep;
}
export async function editOrganization(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
      updateSpsOrganization(input: ${JSON.stringify(inputs).replace(
				/"([^"]+)":/g,
				"$1:",
			)}) {
        organization: spsOrganization {
          id
          name
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateSpsOrganization.organization;
	});
	return rep;
}
