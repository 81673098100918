import PropTypes from "prop-types";
import React, { useState } from "react";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import CompanyName from "@components/organisms/company/CompanyName";

export function Organization({ companyProps }) {
	const [displayModifyOrganization, setDisplayModifyOrganization] =
		useState(false);

	const { company } = companyProps;

	return (
		<div className="h-full">
			{!company.address &&
			!company.industry &&
			!company.website &&
			!company.twitter &&
			!company.linkedin &&
			!company.facebook &&
			!company.fondation &&
			!company.email &&
			!company.phone ? (
				<div className="flex h-full flex-col items-center justify-center">
					<div className="flex flex-col items-center">
						<Avatar
							image={company.logo}
							modify={false}
							firstname={company?.name?.split(" ")[0]}
							lastname={company?.name?.split(" ")[1]}
						/>
						<CompanyName
							nameToDisplay={company?.name}
							additionalClass="mt-sm"
						/>
						<ButtonSecondary
							className="mt-md"
							label="Ajouter des informations"
							onClick={() => {
								setDisplayModifyOrganization(true);
							}}
						/>
					</div>
				</div>
			) : (
				<div>
					<div className="flex items-center">
						<div className="flex justify-center">
							<Avatar
								image={company?.logo}
								modify={false}
								firstname={company?.name ? "E" : company?.name?.split(" ")[0]}
								lastname={company?.name ? "A" : company?.name?.split(" ")[1]}
								onChange={(e) => {
									setFieldValue("logo", e);
								}}
								size="lg"
							/>
						</div>
						<div className="ml-sm flex flex-col">
							<div className="flex flex-col">
								<CompanyName nameToDisplay={company?.name} />
								{!company.address &&
									!company.industry &&
									!company.website &&
									!company.twitter &&
									!company.linkedin &&
									!company.facebook &&
									!company.fondation &&
									!company.email &&
									!company.phone && (
										<ButtonSecondary
											className="mt-md"
											label="Ajouter des informations"
											onClick={() => {
												setDisplayModifyOrganization(true);
											}}
										/>
									)}
								{company.industry && <p>{company.industry}</p>}
								{company.fondation && <p>Fondation : {company.fondation}</p>}
							</div>
							<div className="mt-xsm flex">
								{company.website && (
									<ButtonQuaternary className="small" icon={globe2Outline} />
								)}
								{company.twitter && (
									<ButtonQuaternary className="small" icon={twitterFill} />
								)}
								{company.facebook && (
									<ButtonQuaternary className="small" icon={facebookFill} />
								)}
								{company.linkedin && (
									<ButtonQuaternary className="small" icon={linkedinFill} />
								)}
							</div>
						</div>
					</div>
					<ButtonSecondary
						onClick={() => {
							setDisplayModifyOrganization(!displayModifyOrganization);
						}}
						className="small mt-sm"
						label="Modifier les informations"
					/>
					<div className="mt-xlg">
						{company.address && (
							<div className="flex items-center justify-between">
								<p className="text-xsm font-bold text-primary-200">Siège</p>
								<p className="text-xsm text-primary-700P">{company.address}</p>
							</div>
						)}

						{company.email && (
							<div className="mt-sm flex items-center justify-between">
								<p className="text-xsm font-bold text-primary-200">Email</p>
								<p className="text-xsm text-primary-700P">{company.email}</p>
							</div>
						)}

						{company.phone && (
							<div className="mt-sm flex items-center justify-between">
								<p className="text-xsm font-bold text-primary-200">
									{" "}
									Téléphone
								</p>
								<p className="text-xsm text-primary-700P">{company.phone}</p>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
Organization.propTypes = {
	companyProps: PropTypes.shape({
		company: PropTypes.shape({
			name: PropTypes.string,
			address: PropTypes.string,
			industry: PropTypes.string,
			website: PropTypes.string,
			twitter: PropTypes.string,
			linkedin: PropTypes.string,
			facebook: PropTypes.string,
			fondation: PropTypes.string,
			email: PropTypes.string,
			phone: PropTypes.string,
			logo: PropTypes.string,
		}),
	}).isRequired,
};
