import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { ACTIVITY_OPTIONS } from "@constants/Users";

import { SelectSecondary } from "../../selectSecondary/SelectSecondary";
import { countCurrentFilters, setCurrentFilters } from "../usersFiltersSlice";

export function ActivityFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	function lastConnexionResolver(choice: string) {
		const activityDateLimit = new Date();
		activityDateLimit.setDate(activityDateLimit.getDate() - 15);

		switch (choice) {
			case "before":
				return {
					before: activityDateLimit,
				};
			case "after":
				return {
					after: activityDateLimit,
				};
			default:
				return null;
		}
	}

	return (
		<SelectSecondary
			position="right"
			placeholder="Activité : tous"
			options={ACTIVITY_OPTIONS}
			defaultValue={
				currentFilters.lastConnexion
					? ACTIVITY_OPTIONS.find((option) => {
							const lastConnexion = currentFilters?.lastConnexion;
							return (
								lastConnexion && option.value === Object.keys(lastConnexion)[0]
							);
						})
					: ACTIVITY_OPTIONS[0]
			}
			onChange={(e) => {
				dispatchEvent(
					setCurrentFilters({
						...currentFilters,
						lastConnexion: lastConnexionResolver(e.value),
						page: 1,
					}),
				);
				dispatchEvent(countCurrentFilters());
			}}
		/>
	);
}
