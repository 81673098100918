export const READY_TO_APPLY_ACTIONS = [
	{
		name: "Préparer le CV",
		note: "Bien cibler l'offre, mettre des compétences en avant selon le poste.",
	},
	{
		name: "Envoyer ma candidature",
		note: "Je trouve un contact, je le renseigne sur la plateforme et je lui envoie un mail.",
	},
	{
		name: "Relire ma candidature",
		note: "Je prends bien le temps de revoir l'orthographe",
	},
	{
		name: "Vérifier l'offre",
		note: "Voir si l'offre me correspond toujours",
	},
	{
		name: "Rédiger la lettre de motivation",
		note: "Voir les enjeux de l'entreprise, bien la cibler et répondre au mieux à leurs attentes",
	},
	{
		name: "Postuler",
		note: "J'envoie mon CV (avec une lettre de motivation)",
	},
];

export const SENT_ACTIONS = [
	{
		name: "Se renseigner",
		note: "Obtenir des informations sur l'entreprise : date de création, nombre d'employés, technologies, chiffres, etc.",
	},
	{
		name: "Relancer via un email",
		note: "Je relance par mail pour garder une trace écrite et que le recruteur ne m'oublie pas.",
	},
	{
		name: "Relancer en appel",
		note: "Appeler le recruteur pour le relancer et avoir des informations concernant ma candidature.",
	},
	{
		name: "Relancer via Linkedin",
		note: "Envoyer un message linkedin pour espérer une réponse rapide.",
	},
	{
		name: "S'améliorer",
		note: "Consolider ses acquis, se documenter.",
	},
];

export const RELAUNCH_ACTIONS = [
	{
		name: "Relancer via un email",
		note: "Je relance par mail pour garder une trace écrite et que le recruteur ne m'oublie pas.",
	},
	{
		name: "Relancer en appel",
		note: "Appeler le recruteur pour le relancer et avoir des informations concernant ma candidature.",
	},
	{
		name: "Relancer via Linkedin",
		note: "Envoyer un message linkedin pour espérer une réponse rapide.",
	},
	{
		name: "Vérifier l'offre",
		note: "Regarder si l'offre est toujours valable.",
	},
];

export const INTERVIEW_ACTIONS = [
	{
		name: "Préparer l'entretien",
		note: "Se renseigner un maximum sur l'entreprise.",
	},
	{ name: "S'entraîner", note: "Répeter sa présentation." },
	{
		name: "Remercier",
		note: "Remercier les personnes avec qui j'étais lors de l'entretien.",
	},
	{
		name: "Préparer une liste de question",
		note: "Afin de poser des questions et montrer qu'on s'intéresse à l'entreprise.",
	},
];
