import type { UserType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
	OFFER_CLOSE_REASON_OPTIONS,
	OFFER_SELECT_STATUS_OPTIONS,
} from "@constants/Offers";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Modal } from "@components/organisms/modal/Modal";
import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import { provideOffer } from "../../core/api/offersManagement.request";
import {
	modifyOfferToCreate,
	setOfferStatusWasUpdated,
	setOfferToCreate,
} from "../../core/store/offersManagement.slice";
import { fetchModifyOfferStatus } from "../../core/store/offersManagement.thunks";
import { CloseOfferModal } from "../modal/CloseOfferModal";

type StatusModifierProps = {
	useAutosave?: boolean;
};
function StatusModifier({ useAutosave }: StatusModifierProps) {
	const [showCloseOfferModal, setShowCloseOfferModal] =
		useState<boolean>(false);
	const [wantedStatus, setWantedStatus] = useState<string>("");
	const [closeReason, setCloseReason] = useState<string>(
		OFFER_CLOSE_REASON_OPTIONS[0].label,
	);

	const [studentWhoProvided, setStudentWhoProvided] = useState<
		string | undefined
	>();

	const dispatchEvent = useDispatch();
	const { offerToEdit, offerToCreate } = useAppSelector(
		(state) => state.offers,
	);

	const getDefaultStatus = () => {
		if (useAutosave) {
			if (!offerToEdit?.status) return OFFER_SELECT_STATUS_OPTIONS[0];
			return OFFER_SELECT_STATUS_OPTIONS.find(
				(data) => data.type === offerToEdit?.status,
			);
		}
		if (!offerToCreate?.status) return OFFER_SELECT_STATUS_OPTIONS[0];
		return OFFER_SELECT_STATUS_OPTIONS.find(
			(data) => data.type === offerToCreate?.status,
		);
	};

	const handleStatusChange = (value: string) => {
		if (useAutosave) {
			if (offerToEdit?.status) {
				if (value === "notProvided" || value === "provided") {
					setWantedStatus(value);
					setShowCloseOfferModal(true);
				} else {
					dispatchEvent(
						fetchModifyOfferStatus({
							id: offerToEdit?.id,
							status: value,
							closeReason,
						}) as any,
					);
				}
			}
		} else {
			if (value === "notProvided" || value === "provided") {
				setWantedStatus(value);
				setShowCloseOfferModal(true);
			} else {
				dispatchEvent(setOfferToCreate({ ...offerToCreate, status: value }));
			}
		}
	};

	const handleModifyCloseReason = (reason: string) => {
		setCloseReason(reason);
	};
	const handleConfirmCloseOffer = async () => {
		if (useAutosave) {
			if (studentWhoProvided) {
				const response = await provideOffer(
					offerToEdit?.id || "",
					studentWhoProvided || "",
				);
				if (response.responseLabel === "success") {
					dispatchEvent(
						fetchModifyOfferStatus({
							id: offerToEdit?.id,
							status: wantedStatus,
							closeReason,
						}) as any,
					);
				}
			} else {
				dispatchEvent(
					fetchModifyOfferStatus({
						id: offerToEdit?.id,
						status: wantedStatus,
						closeReason,
					}) as any,
				);
			}
			dispatchEvent(setOfferStatusWasUpdated(true));
		}
		setWantedStatus("");
		setShowCloseOfferModal(false);
	};

	const handleModifyStudentWhoProvided = (student: UserType) => {
		setStudentWhoProvided(student?.id);
	};

	useEffect(() => {
		if (!useAutosave) {
			dispatchEvent(modifyOfferToCreate({ status: getDefaultStatus()?.type }));
		}
	}, []);

	return (
		<>
			<SelectSecondary
				position="right"
				label="Statut de l’offre*"
				defaultValue={getDefaultStatus()}
				options={OFFER_SELECT_STATUS_OPTIONS}
				onChange={(e: any) => {
					handleStatusChange(e.type);
				}}
				tooltip="Correspond à l’état actuel de l’offre."
			/>
			<Modal
				show={showCloseOfferModal}
				onClose={() => {
					setShowCloseOfferModal(false);
				}}
				title="Clôturer l'offre"
				body={
					<CloseOfferModal
						defaultProvidedState={wantedStatus === "provided"}
						catchCloseReason={(e) => handleModifyCloseReason(e)}
						triggerStudentSelection={(e) => handleModifyStudentWhoProvided(e)}
						triggerWantedStatus={(e) => setWantedStatus(e)}
					/>
				}
				buttonsRight={[
					<ButtonPrimary
						key={1}
						onClick={() => {
							handleConfirmCloseOffer();
							setShowCloseOfferModal(false);
						}}
						label="Clôturer"
					/>,
				]}
			/>
		</>
	);
}

export default StatusModifier;
