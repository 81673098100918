import { Icon } from "@iconify/react";
import React, { type ReactElement } from "react";
import { useDropzone } from "react-dropzone";

import "@assets/sass/FileInput.scss";

import { fileAddFill } from "@assets/Icons";

import { stringifyFileFormats } from "@tools/Objects";

import { Spinner } from "@components/atoms/Spinner";

type FileInputType = {
	id: string;
	isLoading: boolean;
	subtitle?: string | ReactElement;
	acceptFormats: any;
	className?: string;
	onSuccess: Function;
	onError: Function;
	maxSize: number;
	error?: boolean;
};
export function FileInput({
	id = "",
	subtitle,
	acceptFormats,
	className = "",
	onSuccess,
	onError,
	maxSize = 1,
	error = false,
	isLoading = false,
}: FileInputType) {
	const onDrop = (files: File[]) => {
		const goodFiles = files.filter((file: File) => {
			if (file.size / 1000000 <= maxSize) return file;
			return 0;
		});
		const badFiles = files.filter((file: File) => {
			if (file.size / 1000000 > maxSize) return file;
			return 0;
		});
		if (files.length === 0) onError(files);
		else if (badFiles.length > 0) onError(badFiles);
		else onSuccess(goodFiles);
	};

	const { getRootProps, fileRejections, getInputProps, isDragActive } =
		useDropzone({
			onDrop,
			accept: acceptFormats,
		});

	return (
		<div
			id={id}
			data-id="fileInput"
			{...getRootProps()}
			className={`file-input min-h-[170px] min-w-full ${
				isDragActive && "drag-activated"
			} ${className} ${error && "error"}`}
		>
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<Icon icon={fileAddFill} />
					<input {...getInputProps()} />
					<div className="mt-xsm text-xsm text-primary-700P">
						Déposez votre fichier ici ou{" "}
						<span className="font-bold">cliquez ici pour l'importer</span>
						<p>
							Taille max : {maxSize}Mo {stringifyFileFormats(acceptFormats)}
						</p>
					</div>
					<div
						className={`mt-xxsm ${
							fileRejections.length > 0 && "text-error-dark"
						}`}
					>
						{subtitle}
					</div>
				</>
			)}
		</div>
	);
}
