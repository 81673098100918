import api from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function getResourceDetailData(id, school, type) {
	let rep = {};
	const data = {
		query: `
        query {
            resource(id: "${id}") {
                id
                title
                description
                color
                illustration {
                  id
                  filePath
                }
                pdf{
                  id
                  filePath
                  
                }
                types
                content
                isMentorgoal
                themes
                views
                createdAt
                link
                author: spsReferent {
                  firstname
                  lastname
                  avatar{
                    id
                    filePath
                  }
                }
            },
            resources(spsReferent_coreSchool: "${school}", type: "${type}", itemsPerPage: 1000, order: [{title: "ASC"}]) {
              collection {
                id
              }
            }
        }
    `,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});

	return rep;
}

export async function updateResource(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
    updateResource(input: ${stringifyRequestInputs(inputs)} ) {
      resource {
        id
      }
    }
  }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateResource;
	});
	return rep;
}
