import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { ARCHIVED_OPTIONS } from "@constants/Users";

import { SelectSecondary } from "../../selectSecondary/SelectSecondary";
import { countCurrentFilters, setCurrentFilters } from "../usersFiltersSlice";

export function ArchivedFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	return (
		<SelectSecondary
			position="right"
			options={ARCHIVED_OPTIONS}
			defaultValue={
				currentFilters.spsPromotion_archived !== undefined
					? ARCHIVED_OPTIONS.find((option) => {
							return option.value === currentFilters.spsPromotion_archived;
						})
					: ARCHIVED_OPTIONS[0]
			}
			onChange={(e) => {
				dispatchEvent(
					setCurrentFilters({
						...currentFilters,
						spsPromotion_archived: e.value,
						page: 1,
					}),
				);

				dispatchEvent(countCurrentFilters());
			}}
		/>
	);
}
