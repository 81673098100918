import type {
	BackgroundColorType,
	BorderColorType,
	TextColorType,
} from "@/types/colorsType";
import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";

export type TagSizeType = "small" | "medium";

type TagType = {
	label: string;
	size?: TagSizeType;
	leftIcon?: string | IconifyIcon;
	bgColor?: BackgroundColorType;
	textColor?: TextColorType | null;
	borderColor?: BorderColorType | null;
	className?: string;
	hasBorder?: boolean;
	onClick?: (label: string) => void;
};

export type BadgeType = TagType & {
	rightIcon?: string | IconifyIcon;
};

const SIZES_SPECS = new Map<TagSizeType, string>([
	["small", "px-xsm py-xxsm text-xxxsm h-full max-h-6"],
	["medium", "px-xsm py-xxsm text-xxsm font-bold max-h-7 h-full"],
]);

export function Badge({
	label,
	size = "small",
	leftIcon,
	rightIcon,
	bgColor = "bg-white",
	textColor = null,
	borderColor = null,
	className = "",
	hasBorder = false,
	onClick,
}: BadgeType) {
	textColor = textColor
		? textColor
		: bgColor === "bg-white"
			? "text-primary-700P"
			: (`text-${bgColor.split("-")[1]}-dark` as TextColorType);

	borderColor = borderColor
		? borderColor
		: bgColor === "bg-white"
			? "border-primary-700P"
			: (`border-${bgColor.split("-")[1]}-${hasBorder ? "dark" : "light"}` as BorderColorType);
	return (
		<div
			className={`
      flex items-center justify-center
      gap-xxsm
      rounded-full ${SIZES_SPECS.get(size)}
      ${textColor}
      ${borderColor}
      border ${bgColor}
      w-fit
      ${onClick ? "cursor-pointer" : ""}
			font-bold
			flex-nowrap
      ${className}
    `}
			onClick={() => {
				if (onClick) onClick(label);
			}}
		>
			{leftIcon && <Icon icon={leftIcon} />}
			{label}
			{rightIcon && <Icon icon={rightIcon} />}
		</div>
	);
}
