import React from "react";
import Skeleton from "react-loading-skeleton";

export function HistorySkeleton() {
	return (
		<div className="h-fit-content flex w-full flex-col gap-6 bg-white p-2">
			<>
				<div className="rounded-2xl p-2">
					<div className="mb-2 flex">
						<div className="mr-2">
							<Skeleton width={33} height={33} borderRadius="50%" />
						</div>
						<div className="w-full">
							<div className="flex w-full justify-between font-bold">
								<Skeleton width={120} height={22} />
								<Skeleton className="ml-auto" width={65} height={18} />
							</div>
							<Skeleton width={180} height={18} />
							<Skeleton width={200} height={18} />
						</div>
					</div>
					<div className="mb-2 flex">
						<div className="mr-2">
							<Skeleton width={33} height={33} borderRadius={"50%"} />
						</div>
						<div className="w-full">
							<div className="flex w-full justify-between font-bold">
								<Skeleton width={110} height={22} />
								<Skeleton className="ml-auto" width={65} height={18} />
							</div>
							<Skeleton width={160} height={18} />
							<Skeleton width={160} height={18} />
						</div>
					</div>
					<div className="flex">
						<div className="mr-2">
							<Skeleton width={33} height={33} borderRadius="50%" />
						</div>
						<div className="w-full">
							<div className="flex w-full justify-between font-bold">
								<Skeleton width={110} height={22} />
								<Skeleton className="ml-auto" width={65} height={18} />
							</div>
							<Skeleton width={160} height={18} />
							<Skeleton width={160} height={18} />
						</div>
					</div>
				</div>
			</>
		</div>
	);
}
