import React from "react";

import { ColumnsView } from "./Applications/ColumnsView";
import { ListView } from "./Applications/ListView";

export function Applications({
	applicationsProps,
	load,
	view,
	getApplications,
	lastPage,
	filtersProps,
}) {
	const {
		applications,
		setApplications,
		applicationsListView,
		setApplicationsListView,
	} = applicationsProps;
	return (
		<div className="container-view">
			{view === "columns" ? (
				<ColumnsView
					applicationsProps={{ applications, setApplications }}
					getApplications={getApplications}
					load={load}
					filtersProps={filtersProps}
				/>
			) : (
				<ListView
					applicationsProps={{
						applicationsListView,
						setApplicationsListView,
					}}
					load={load}
					lastPage={lastPage}
					getApplications={getApplications}
					filtersProps={filtersProps}
				/>
			)}
		</div>
	);
}
