import type { TagType } from "@/types/tagTypes";
import React, { useEffect, useRef, useState } from "react";

import { searchFill } from "@assets/Icons";

import { extractIdNumberFromId } from "@tools/Users";

import { Tag } from "@components/atoms/Tag";
import { ToggleButtonGroupLarge } from "@components/atoms/ToggleButtonGroup";
import TooltipContainer from "@components/atoms/TooltipContainer";
import TooltipElement from "@components/atoms/TooltipElement";
import { InputLargePrimary } from "@components/molecules/InputLargePrimary";

let uniqueId = 0;

type TagsFinderType = {
	placeholder?: string;
	className?: string;
	options: Array<any>;
	onChange?: Function;
	defaultOptions?: Array<any>;
	errorMessage?: string;
	useAsFilter?: boolean;
};

export function TagsFinder({
	defaultOptions,
	placeholder,
	options,
	className,
	onChange,
	errorMessage,
	useAsFilter = false,
}: TagsFinderType) {
	const tagsRef = useRef(null);
	const tagRef = useRef(null);
	const ref = useRef(null);

	const [componentId] = useState(() => uniqueId++);
	const [selectedItems, setSelectedItems] = useState<TagType[]>([]);
	const [items, setItems] = useState<TagType[]>([]);
	const [searchedValue, setSearchedValue] = useState<string>("");
	const [showTags, setShowTags] = useState(false);
	const [filterOperator, setFilterOperator] = useState("and");
	const [selectedButton, setSelectedButton] = useState(
		filterOperator === "and" ? 1 : 0,
	);

	const removeAlreadySelectedTags = (tags: TagType[]) => {
		return tags?.filter(
			(tag) =>
				!defaultOptions?.find(
					(defaultTag) =>
						extractIdNumberFromId(defaultTag.id) ===
						extractIdNumberFromId(tag.id),
				),
		);
	};
	const addSelected = (item: TagType) => {
		const selecteds = [...selectedItems];
		selecteds.push(item);
		setSelectedItems(selecteds);

		setSearchedValue("");
		if (onChange) {
			useAsFilter
				? onChange({
						list: selecteds.map((tag) => Number(tag.id.split("/")[3])),
						operator: filterOperator,
					})
				: onChange(selecteds);
		}
	};

	useEffect(() => {
		if (defaultOptions) {
			setSelectedItems(defaultOptions);
		}
		if (!useAsFilter) {
			setItems(removeAlreadySelectedTags([...options]));
		} else {
			setItems([...options]);
		}
	}, [options, defaultOptions]);

	useEffect(() => {
		if (!useAsFilter) {
			setItems(options.filter((item) => !defaultOptions?.includes(item)));
		}
	}, [selectedItems, defaultOptions]);
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setShowTags(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [showTags]);

	useEffect(() => {
		setSelectedButton(filterOperator === "and" ? 0 : 1);
	}, [filterOperator]);
	return (
		<div className={`flex flex-col gap-sm ${className}`} ref={ref}>
			<div ref={tagsRef} className={"flex items-center gap-sm"}>
				{useAsFilter && (
					<div className="flex items-center gap-sm">
						<p className="whitespace-nowrap text-xxsm font-bold text-primary-300">
							Filtrer par Tag
						</p>
						<div className="flex gap-xsm">
							<ToggleButtonGroupLarge
								className="h-fit"
								selectedIndex={selectedButton}
								buttons={[
									{
										label: "ET",
										onClick: () => {
											setFilterOperator("and");
											onChange?.({
												list: selectedItems?.map((tag) =>
													Number(tag.id.split("/")[3]),
												),
												operator: "and",
											});
										},
										value: "and",
									},
									{
										label: "OU",
										onClick: () => {
											setFilterOperator("or");
											onChange?.({
												list: selectedItems?.map((tag) =>
													Number(tag.id.split("/")[3]),
												),
												operator: "or",
											});
										},
										value: "or",
									},
								]}
							/>
							<TooltipElement id="tagFilterTooltip" />
							<TooltipContainer
								anchorId="#tagFilterTooltip"
								place="top"
								children={
									<p>
										ET : limite vos résultats à tous les tags séléctionnés
										<br />
										OU : élargit votre recherche en incluant un des tags ou tout
										les tags
									</p>
								}
							/>
						</div>
					</div>
				)}

				<div className="relative">
					<InputLargePrimary
						placeholder={placeholder}
						value={searchedValue}
						icon={searchFill}
						onChange={(e) => {
							setSearchedValue(e.target.value);
							setShowTags(true);
						}}
						className="w-fit"
						onFocus={() => {
							setShowTags(true);
						}}
					/>
					<div
						id={`combobox-dropdown-${componentId}`}
						className={` ${
							showTags ? "flex" : "hidden"
						} absolute left-0 top-[100%] z-10 max-h-[150px] flex-col gap-xxsm  overflow-y-scroll rounded bg-white shadow-lg`}
					>
						{items?.filter((item) =>
							item.name.toLowerCase().includes(searchedValue.toLowerCase()),
						)?.length === 0 && (
							<p className="text-primary-300">Aucun résultat</p>
						)}
						{items
							?.filter(
								(item) =>
									item.name
										.toLowerCase()
										.includes(searchedValue.toLowerCase()) &&
									!selectedItems?.find((tag) => tag.id === item.id),
							)
							?.map((elem, i) => {
								return (
									<div key={i}>
										<div
											key={i}
											className="px-xsm py-xxsm transition-all hover:bg-primary-50 w-full cursor-pointer"
											onClick={() => {
												setShowTags(false);
												addSelected(elem);
											}}
										>
											<Tag
												className="w-fit "
												bgColor={elem.color}
												label={elem.name}
												truncate
											/>
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>

			<div id="tags-container" className="flex gap-xsm flex-wrap w-full">
				{errorMessage && selectedItems.length === 0 && (
					<p className="subtitle-p mb-xsm text-primary-200">{errorMessage}</p>
				)}
				{selectedItems?.map((elem, i) => {
					return (
						<div id={`tag-${i}`} ref={tagRef} key={i}>
							<Tag
								className="cursor-pointer"
								bgColor={elem.color}
								truncate
								label={elem.name}
								onDelete={() => {
									const selecteds = [...selectedItems];

									const indexElem = selecteds?.indexOf(elem);
									selecteds?.splice(indexElem, 1);
									setSelectedItems(selecteds);
									if (onChange) {
										useAsFilter
											? onChange({
													list: selecteds?.map((tag) =>
														Number(tag?.id.split("/")[3]),
													),
													operator: filterOperator,
												})
											: onChange(selecteds);
									}
								}}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
}
