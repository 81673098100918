import { useAppDispatch } from "@config/redux/store";
import { verifyCompanySiretExistance } from "@containers/school/Companies/core/usecases/verifyCompanySiretExistance.usecase";

type CompanyCreation = () => {
	checkCompanySiret: (siret: string) => void;
};

export const useCompanyCreation: CompanyCreation = () => {
	const dispatch = useAppDispatch();

	const checkCompanySiret = async (siret: string) => {
		await dispatch(verifyCompanySiretExistance(siret));
	};

	return {
		checkCompanySiret,
	};
};
