import React, { type ChangeEvent, useEffect, useState } from "react";

import "@assets/sass/Inputs.scss";

import { lockOutline } from "@assets/Icons";

import { InputLargePrimary } from "./InputLargePrimary";

import "react-nice-dates/build/style.css";

type PasswordInputType = {
	key?: string | number;
	errorMessage?: string;
	name: string;
	value: string;
	className?: string;
	onBlur?: () => void;
	onChange: (e?: ChangeEvent<HTMLInputElement>) => void;
	placeholder: string;
	showRules?: boolean;
};

export function PasswordInput({
	key,
	errorMessage,
	name,
	value,
	className = "",
	onBlur,
	onChange,
	placeholder,
	showRules = true,
}: PasswordInputType) {
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const PASSWORD_RULES =
		"Minimum 8 caractères, dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.";

	return (
		<InputLargePrimary
			key={key}
			type="password"
			icon={lockOutline}
			placeholder={placeholder}
			onChange={onChange}
			errorMessage={errorMessage}
			name={name}
			onBlur={onBlur}
			helperText={showRules ? PASSWORD_RULES : undefined}
			className={className}
			value={inputValue}
		/>
	);
}
