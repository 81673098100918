import type { FetchCompaniesListParamsType } from "@/types/companyRequest.types";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
	getCampusesList,
	getReferents,
} from "@containers/school/Offers_v3/core/api/offersManagement.request";
import { fetchGetCompanies } from "../api/companiesManagement.request";

export const SETTINGS_COOKIE_NAME = "companiesManagementSettings";

export const fetchCompaniesList = createAsyncThunk(
	"companiesManagementAPI/fetchCompaniesList",
	async (params: FetchCompaniesListParamsType) => {
		const response = await fetchGetCompanies(params);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data.crmCompanies;
	},
);

export const fetchCampuses = createAsyncThunk(
	"companiesManagementAPI/fetchCampuses",
	async (params: { schoolId: string }) => {
		const response = await getCampusesList(params.schoolId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}

		return response.data.campuses.collection;
	},
);

export const fetchReferents = createAsyncThunk(
	"companiesManagementAPI/fetchReferents",
	async (params: { campusList: string[]; schoolId: string }) => {
		const response = await getReferents(params.campusList, params.schoolId);
		if (response.responseLabel === "error") {
			throw new Error("aborted");
		}
		return response.data;
	},
);
