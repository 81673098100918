/* Documentation : https://react-tooltip.com/docs/options */
import React, { type ReactNode } from "react";
import { type PlacesType, Tooltip } from "react-tooltip";

type TooltipPosition = PlacesType;
type TooltipProps = {
	anchorId: string;
	children: ReactNode;
	makeClickable?: boolean;
	place?: TooltipPosition;
	float?: boolean;
};
/***
 * @description TooltipContainer is a wrapper for the Tooltip component using adapter pattern.
 * @param {string} anchorId - The id of the element that will be the anchor of the tooltip.
 * @param {ReactNode} children - The content of the tooltip.
 * @param {boolean} makeClickable - If true, the tooltip will be clickable.
 * @param {TooltipPosition} place - The position of the tooltip.
 * @param {boolean} float - If true, the tooltip will be floating.
 * @returns {JSX.Element} - A Tooltip component.
 */
function TooltipContainer({
	anchorId,
	children,
	makeClickable,
	place,
	float = true,
}: TooltipProps) {
	return (
		<Tooltip
			anchorSelect={anchorId}
			clickable={makeClickable || false}
			place={place}
			className="!z-[99999] flex h-auto max-w-sm items-center justify-start whitespace-normal rounded-md !bg-primary-700P text-xxsm text-white"
			float={float}
			opacity={1}
		>
			{children}
		</Tooltip>
	);
}

export default TooltipContainer;
