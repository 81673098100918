import type {
	UsersFiltersContextType,
	UsersFiltersType,
} from "@/types/usersFiltersTypes";
import React, { createContext } from "react";

import { ActivityFilter } from "./Filters/ActivityFilter";
import { ArchivedFilter } from "./Filters/ArchivedFilter";
import { BirthdateFilter } from "./Filters/BirthdateFilter";
import { JobContractFilter } from "./Filters/JobContractFilter";
import { LocationFilter } from "./Filters/LocationFilter";
import { PromotionsFilter } from "./Filters/PromotionsFilter";
import { StatusFilter } from "./Filters/StatusFilter";
import { TagsFilter } from "./Filters/TagsFilter";

export const UsersFiltersContext = createContext<UsersFiltersContextType>({
	filters: { page: 1, itemsPerPage: 30 },
	setFilters: () => {},
});

export function UsersFilters({
	filtersProps,
	enabledFilters = [
		"activity",
		"status",
		"promotions",
		"tags",
		"archived",
		"location",
		"birthdate",
		"jobContract",
	],
}: UsersFiltersType) {
	const { filters, setFilters } = filtersProps;
	return (
		<UsersFiltersContext.Provider value={{ filters, setFilters }}>
			<section
				className="
        flex flex-col gap-sm 
        divide-y-2 divide-solid divide-primary-150
        rounded-lg border-[1px] border-primary-700P 
        bg-primary-100 p-sm"
			>
				<div className="flex flex-wrap gap-sm">
					{enabledFilters.includes("location") && <LocationFilter />}
					{enabledFilters.includes("birthdate") && <BirthdateFilter />}
					{enabledFilters.includes("status") && <StatusFilter />}
					{enabledFilters.includes("promotions") && <PromotionsFilter />}
					{enabledFilters.includes("jobContract") && <JobContractFilter />}
					{enabledFilters.includes("activity") && <ActivityFilter />}
					{enabledFilters.includes("archived") && <ArchivedFilter />}
				</div>
				<div>{enabledFilters.includes("tags") && <TagsFilter />}</div>
			</section>
		</UsersFiltersContext.Provider>
	);
}
