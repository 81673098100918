import type { EntOffersType } from "@/types/EntCompanyTypes";
import { PdfViewer } from "@components/atoms/PdfViewer";
import React from "react";

export function PdfDisplay({ activeOffer }: { activeOffer: EntOffersType }) {
	return activeOffer.isPdf && activeOffer.pdf && activeOffer.pdf?.filePath ? (
		<PdfViewer
			src={`${import.meta.env.VITE_S3}${activeOffer.pdf.filePath}`}
			title="PDF de l'offre"
			className="h-[calc(100vh-200px)]"
		/>
	) : null;
}
