import type { GoalType } from "@/types/goalsTypes";
import api from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function createGoal(
	inputs: GoalType & { spsStudent: string },
): Promise<{ goal: GoalType }> {
	const dataToSend = {
		query: `mutation {
          createGoal(input: ${stringifyRequestInputs(inputs)}) {
            goal{
              id
              title
              studentComment
              completionDate
              isCompleted
              deadline
            }
          }
        }`,
	};
	try {
		const { data } = await api.post("graphql", dataToSend);
		return data.data.createGoal;
	} catch (error) {
		throw new Error(error);
	}
}

export async function editGoal(inputs: GoalType) {
	const dataToSend = {
		query: `mutation {
          updateGoal(input: ${stringifyRequestInputs(inputs)}) {
            goal{
              id
              title
              studentComment
              completionDate
              isCompleted
              deadline
            }
          }
        }`,
	};
	try {
		const { data } = await api.post("graphql", dataToSend);
		return data;
	} catch (error) {
		throw new Error(error);
	}
}
