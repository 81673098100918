import { Icon } from "@iconify/react";
import React from "react";
import { type Id, ToastContainer, toast } from "react-toastify";

import { checkmarkCircle2Fill, closeFill } from "@assets/Icons";

import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";

export function ToastNotificationContainer() {
	return (
		<ToastContainer
			closeButton={true}
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			closeOnClick={false}
		/>
	);
}

export function Success(body: any) {
	toast.success(<p>{body}</p>, {
		icon: () => (
			<Icon icon={checkmarkCircle2Fill} className="text-success-dark" />
		),
		closeButton: () => <ButtonQuaternary icon={closeFill} className="small" />,
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
}

export function ErrorToast(message: string) {
	toast.error(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	});
}

export function InfoToast(message: string) {
	toast.info(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	});
}

export function updateToastMessage(id: Id, message: string) {
	toast.update(id, {
		render: message,
		isLoading: true,
		hideProgressBar: false,
		type: "default",
	});
}

export function updateToastWithSuccess(id: Id, message: string) {
	toast.update(id, {
		render: message,
		isLoading: false,
		hideProgressBar: false,
		type: "success",
		icon: () => (
			<Icon icon={checkmarkCircle2Fill} className="text-success-dark" />
		),
		closeButton: () => <ButtonQuaternary icon={closeFill} className="small" />,
		autoClose: 5000,
		closeOnClick: true,
	});
}
export function updateToastWithError(id: Id, message: string) {
	toast.update(id, {
		render: message,
		isLoading: false,
		hideProgressBar: false,
		type: "error",
		icon: () => <Icon icon={closeFill} className="text-error-dark" />,
		closeButton: () => <ButtonQuaternary icon={closeFill} className="small" />,
		autoClose: 5000,
		closeOnClick: true,
	});
}

export function updateLoadingToast(id: Id, message: string) {
	toast.update(id, {
		render: message,
		isLoading: true,
		hideProgressBar: true,
		type: "default",
		closeOnClick: false,
	});
}
