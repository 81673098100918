import { useBreakpoints } from "@hooks/useBreakpoints";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";

import { arrowBackFill, moreVerticalFill } from "@assets/Icons";

import { ButtonQuinary } from "@components/atoms/ButtonQuinary";
import { Dropdown } from "@components/molecules/Dropdown";
import CompanyName from "@components/organisms/company/CompanyName";

import {
	editApplication,
	getApplicationDetailData,
} from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";
import { Contacts } from "@containers/student/Applications/ApplicationDetail/ui/Contacts";
import { Head } from "@containers/student/Applications/ApplicationDetail/ui/Head";
import { Information } from "@containers/student/Applications/ApplicationDetail/ui/Information";
import { Organization } from "@containers/student/Applications/ApplicationDetail/ui/Organization";

import { DeleteModal } from "./ui/Modals/DeleteModal";

export function ApplicationDetail() {
	const location = useLocation();
	const { isMobile } = useBreakpoints(799);
	const applicationId = `/student/applications/${Number(
		location.pathname.split("/")[2],
	)}`;
	const [displayModalDelete, setDisplayModalDelete] = useState(false);

	const [application, setApplication] = useState({});
	const [company, setCompany] = useState({});
	const [contacts, setContacts] = useState([]);
	const [actions, setActions] = useState([]);
	const [loadingData, setLoadingData] = useState(false);
	const navigate = useNavigate();

	const { setTitle } = useOutletContext();

	const dropdownItems = [
		{
			label: "Supprimer la candidature",
			action: () => {
				setDisplayModalDelete(true);
			},
		},
		{
			label: application.archived
				? "Restaurer la candidature"
				: "Archiver la candidature ",
			action: async () => {
				await editApplication({
					id: application.id,
					archived: !application.archived,
				});
				navigate("/applications");
			},
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const getData = () => {
		setLoadingData(true);
		getApplicationDetailData(applicationId).then((data) => {
			let actions = [];
			let contacts = [];

			actions = data?.actions.collection.map((action) => {
				return {
					...action,
					contacts: action.contacts.collection.map((x) => x.id),
				};
			});

			contacts = data?.application.contacts.collection.map((contact) => {
				return contact;
			});

			setLoadingData(false);
			setTitle("Mes candidatures");
			setApplication(data?.application);

			setContacts(contacts);
			setCompany(data?.application.organization);
			setActions(actions);
		});
	};

	useEffect(() => {
		getData();
	}, [applicationId]);

	return (
		<main className="flex flex-col gap-md">
			{!isMobile && (
				<Head
					applicationProps={{ application, setApplication }}
					loading={loadingData}
				/>
			)}
			<div className="flex w-full gap-md p-0">
				<div className=" hidden w-5/12 flex-col gap-md p-0 md:flex">
					<Organization
						applicationProps={{ application, setApplication }}
						loading={loadingData}
						companyProps={{ company, setCompany }}
					/>
					<Contacts
						loading={loadingData}
						contactsProps={{ contacts, setContacts }}
						applicationId={applicationId}
					/>
				</div>
				<div className="flex w-full flex-col p-0 md:mt-0 md:w-7/12">
					{isMobile && (
						<div className="flex items-center justify-between p-sm">
							<div className="flex items-center justify-center">
								<ButtonQuinary
									icon={arrowBackFill}
									onClick={() => {
										navigate("/applications");
									}}
								/>
								<div className="ml-sm">
									<p className="text-xxsm font-bold text-primary-700P">
										{application.job}
									</p>
									<CompanyName
										nameToDisplay={application?.organization?.name}
										nameColor="text-primary-200"
										additionalClass="!text-xsm"
									/>
								</div>
							</div>
							<Dropdown
								direction="left"
								button={
									<ButtonQuinary dropdownButton icon={moreVerticalFill} />
								}
								items={dropdownItems}
							/>
						</div>
					)}
					<Information
						applicationProps={{ application, setApplication }}
						contactsProps={{ contacts, setContacts }}
						companyProps={{ company, setCompany }}
						actionsProps={{ actions, setActions }}
						applicationId={applicationId}
					/>
				</div>
			</div>
			<DeleteModal
				show={displayModalDelete}
				onClose={() => {
					setDisplayModalDelete(false);
				}}
				applicationProps={{ application, setApplication }}
			/>
		</main>
	);
}
