import React, { type ReactNode } from "react";

type LinkCardType = {
	title?: string;
	titleColor?: string;
	content: ReactNode;
	className?: string;
	size?: "small" | "medium" | "large";
};

export function WhiteCard({
	title,
	content,
	className = "",
	size = "medium",
	titleColor,
}: LinkCardType) {
	const getClasses = () => {
		switch (size) {
			case "small": {
				return " p-sm gap-sm";
			}
			case "medium": {
				return " p-md gap-sm";
			}
			case "large": {
				return " p-lg gap-md";
			}
		}
	};

	return (
		<div
			className={`flex h-fit flex-col rounded-md bg-white shadow-md ${className}${getClasses()}`}
		>
			{title && (
				<p className={`font-bold${titleColor ? ` text-${titleColor}` : ""}`}>
					{title}
				</p>
			)}
			<div>{content}</div>
		</div>
	);
}
