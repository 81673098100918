import DOMPurify from "dompurify";
import React from "react";

type RichTextPreviewType = {
	unsanitizedText: string;
	classname?: string;
};
function RichTextPreview({ unsanitizedText, classname }: RichTextPreviewType) {
	return (
		<div className={`ql-snow wg-no-translate ${classname}`}>
			<div
				className="ql-editor"
				id="break-line"
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(unsanitizedText),
				}}
			/>
		</div>
	);
}

export default RichTextPreview;
