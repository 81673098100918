import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { OfferCellNameType } from "@/types/offerTypes";

import API from "@tools/API";

export async function uploadOfferFileRequest(formdata: FormData) {
	try {
		const responseReceived = await API.post("media_objects", formdata);
		return responseReceived.data as MediaObjectType;
	} catch (error) {
		return {
			responseLabel: "error",
			message: `Erreur de type ${error}`,
		};
	}
}

export async function exportOffers(
	columns: OfferCellNameType[],
	filters: any,
	search: string,
) {
	try {
		const data = await API.post("export_offers", {
			columns,
			filters,
			search,
		});
		return data;
	} catch (error) {
		throw new Error(error);
	}
}
