import type { TagType } from "@/types/tagTypes";
import React, { useEffect, useState } from "react";

import { Tag } from "./Tag";

type TagsGroupType = {
	tags: any[];
	onChange?: (label: TagType) => void;
	className?: string;
	selectedTags?: string[];
	isRemovable?: boolean;
	limit?: number | null;
};
export function TagsGroup({
	tags,
	onChange,
	className = "",
	selectedTags,
	isRemovable = false,
	limit = null,
}: TagsGroupType) {
	const [indexActiveTag, setIndexActiveTag] = useState<number | null>();
	const tagsCountToShow = limit ?? tags.length;

	useEffect(() => {
		if (selectedTags?.length === 0) setIndexActiveTag(null);
	}, [selectedTags, indexActiveTag]);

	return (
		<div
			className={`flex-wrap max-w-[300px] w-full flex items-center ${className} gap-1`}
		>
			{tags.slice(0, tagsCountToShow).map((tag, i) => {
				return (
					<Tag
						bgColor={tag.color}
						label={tag.name}
						className="max-w-full"
						onDelete={
							isRemovable
								? () => {
										if (onChange) onChange(tag);
										if (indexActiveTag === i) setIndexActiveTag(null);
										else setIndexActiveTag(i);
									}
								: null
						}
					/>
				);
			})}
			{limit && tags.length > limit && (
				<Tag
					bgColor={"bg-stone-light"}
					label={`+${tags.length - limit}`}
					onDelete={null}
					key={"more"}
				/>
			)}
		</div>
	);
}
