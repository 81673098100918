import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import "./prepera.scss";

import Cards from "./ui/Cards";
import Content from "./ui/Content";

export default function Prepera() {
	const { setTitle }: any = useOutletContext();
	const { user }: any = useContext(GlobalContext);

	useEffect(() => {
		setTitle("Préparation aux entretiens");
	}, [user]);

	return (
		<main className="prepera grid w-full grid-cols-4 gap-4 sm:gap-6 md:grid-cols-8 lg:grid-cols-12 xxl:gap-8">
			<div className="page-container col-span-4 flex w-full flex-col p-0 md:col-span-8 lg:col-span-12">
				<div className="flex  flex-wrap justify-between">
					<Cards />
					<Content />
				</div>
				<p className="mt-md text-primary-300">
					*En cliquant sur ce bouton, vous serez redirigé(e) vers un site tiers
					et vos données personnelles seront traitées selon les conditions
					générales d'utilisation de ce site. Notez que Mentor Goal ne pourra
					plus garantir la protection de vos données personnelles une fois que
					vous aurez quitté notre plateforme.
				</p>
			</div>
		</main>
	);
}
