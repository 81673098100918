import useDebounce from "@hooks/useDebounce";
import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { peopleOutline } from "@assets/Icons";

import { resolveView } from "@tools/Users";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { CheckboxLabelPrimary } from "@components/molecules/CheckboxLabel";
import { Combobox } from "@components/molecules/Combobox";
import { FileInput } from "@components/molecules/FileInput";
import { FilesPrimary } from "@components/molecules/Files";
import { Modal } from "@components/organisms/modal/Modal";
import {
	updateToastWithError,
	updateToastWithSuccess,
} from "@components/organisms/toast/ToastNotification";

import {
	editUser,
	getStudents,
	sendFile,
} from "@containers/school/CVBoard/CVBoardAPI";

import { CVBoardContext } from "../..";

export function ImportModal({ onClose, show = false }) {
	const [students, setStudents] = useState([]);
	const { structureView } = useContext(CVBoardContext);
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const [search, setSearch] = useState("");
	const debounced = useDebounce(search, 1000);

	const formatStudents = (students) => {
		const formattedStudents = [];

		students.map((student) => {
			return formattedStudents.push({
				id: student.id,
				label: `${student.firstname} ${student.lastname}`,
				image: `${student.avatar?.filePath}`,
			});
		});

		return formattedStudents;
	};

	const retrieveOptions = async (name) => {
		const response = await getStudents(
			resolveView(structureView, "spsPromotion"),
			name,
		);
		if (!response) return;
		if (response?.collectionQuerySpsStudents) {
			setStudents(
				formatStudents(response.collectionQuerySpsStudents?.collection),
			);
		}
	};

	const importCV = async (values) => {
		setIsLoadingResume(true);
		const formData = new FormData();
		formData.append("file", values.cv);
		formData.append("type", "cv");
		formData.append("ownerId", values.student.id.split("/")[3]);
		formData.append("state", values.validated ? "validated" : "toValidate");

		const updatedUser = {
			id: values.student.id,
		};

		const uploadCVToastId = toast.loading("Importation du CV...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		const response = await sendFile(formData);
		if (response["@id"]) {
			updatedUser.cv = response["@id"];
			updateToastWithSuccess(
				uploadCVToastId,
				"L'importation a été réalisée avec succès !",
			);
			await editUser(updatedUser);
			setIsLoadingResume(false);
		} else {
			updateToastWithError(
				uploadCVToastId,
				"Une erreur est survenue lors de l’importation du CV",
			);
		}
		setIsLoadingResume(false);
		onClose();
	};

	useEffect(() => {
		retrieveOptions(debounced);
	}, [debounced]);

	return (
		<div>
			<Formik
				initialValues={{
					cv: {},
					student: {},
					cvState: false,
				}}
				validate={(values) => {
					const errors = {};

					if (!values?.cv?.path) {
						errors.cv = "Veuillez choisir un fichier.";
					}
					if (!values?.student?.id) {
						errors.student = "Veuillez choisir un étudiant.";
					}

					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					importCV(values);
					resetForm({});
					onClose();
				}}
			>
				{({
					values,
					errors,
					touched,
					setFieldValue,
					handleSubmit,
					resetForm,
				}) => (
					<Modal
						show={show}
						onClose={() => {
							onClose();
							resetForm({});
						}}
						scroll={false}
						title="Importer un CV"
						body={
							<div>
								{values.cv?.path ? (
									<FilesPrimary
										isLoading={isLoadingResume}
										title={values?.cv?.path}
										subtitle={`${Math.floor(values?.cv?.size / 1000)} Ko`}
										className="mt-3"
										onDelete={() => setFieldValue("cv", {})}
									/>
								) : (
									<FileInput
										isLoading={isLoadingResume}
										subtitle={
											<p className="text-xxxsm text-primary-200">
												Taille max : 1Mo - Format : PDF
											</p>
										}
										acceptFormats={{ "application/pdf": [] }}
										maxSize={1}
										onSuccess={(e) => {
											setFieldValue("cv", e[0]);
										}}
									/>
								)}
								{errors.cv && (
									<p className="mt-sm text-error-dark">{errors?.cv}</p>
								)}
								<p className="mt-md text-xsm text-primary-300">
									Nom de l’étudiant
								</p>

								<Combobox
									className="mt-xxsm"
									options={students}
									placeholder="Sélectionner un étudiant"
									icon={peopleOutline}
									onChange={(e) => {
										setSearch(e.target.value.replace(" ", "%"));
										if (e.target.value === "") {
											setFieldValue("student", {});
										}
									}}
									value={values?.student?.label}
									onClickOnItem={(e) => {
										setFieldValue("student", e);
									}}
									errorMessage={
										errors.student && touched.student && errors.student
									}
								/>
								<CheckboxLabelPrimary
									label="Valider le CV"
									className="mt-md"
									checked={values.validated}
									onCheck={(e) => {
										setFieldValue("validated", e);
									}}
								/>
							</div>
						}
						buttonsRight={[
							<ButtonSecondary
								key={0}
								onClick={() => {
									resetForm({});
									onClose();
								}}
								label="Annuler"
							/>,
							<ButtonPrimary
								key={1}
								onClick={() => {
									handleSubmit();
								}}
								label="Importer"
							/>,
						]}
					/>
				)}
			</Formik>
		</div>
	);
}
