import type { EventInvitationType } from "@/types/eventsInvitationType";
import type { EventType } from "@/types/eventsTypes";
import type { UserType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import { GlobalContext } from "@navigation/Router";
import { findStudentInvitation, isMentorGoalEvent } from "@tools/Events";
import { extractIdNumberFromId } from "@tools/Users";
import { useContext, useEffect, useState } from "react";
import { subscribeToEvent } from "../../core/api/events.request";
import { eventsActions } from "../../core/store/events.slice";

export const useEventSubscriptions = (eventDataToUse: EventType) => {
	const { fetchingInvitationsState } = useAppSelector((state) => state.events);

	const callSubscribeToEvent = async (reply: string) => {
		if (!eventDataToUse || !eventDataToUse.type) return;
		const response = await subscribeToEvent({
			eventId: extractIdNumberFromId(eventDataToUse.id.toString()),
			subscriptionReply: reply,
			isMentorgoalType: isMentorGoalEvent(eventDataToUse.type),
		});
		dispatchEvent(eventsActions.setPerformFetchEvents(true));
		return response;
	};

	const getOptionColors = (
		reply: "registered" | "maybe" | "notParticipating",
	): string => {
		return invitationFound?.reply
			? invitationFound?.reply === reply
				? "!bg-primary-50 [&>*]:!text-primary-700P"
				: "!border-primary-200 !bg-transparent !text-primary-200 [&>*]:!text-primary-200"
			: "";
	};

	const updateInvitationData = (
		reply: "registered" | "maybe" | "notParticipating",
	) => {
		setInvitationFound({
			...invitationFound,
			reply,
		});
	};

	const loadInvitationData = () => {
		if (!user || !eventDataToUse) return;
		const invitationFound = findStudentInvitation(
			eventDataToUse,
			user?.id || "",
		);
		setInvitationFound(invitationFound);
	};

	const { user }: { user: Partial<UserType> | undefined } =
		useContext(GlobalContext);
	const [invitationFound, setInvitationFound] = useState<
		Partial<EventInvitationType> | undefined
	>(undefined);

	const [isInit, setIsInit] = useState<boolean>(false);
	const dispatchEvent = useAppDispatch();

	useEffect(() => {
		if (!isInit) {
			loadInvitationData();
			setIsInit(true);
		}
	}, [eventDataToUse]);

	return {
		isFetchingData: fetchingInvitationsState === "loading",
		callSubscribeToEvent,
		getOptionColors,
		invitationData: invitationFound,
		reply: invitationFound?.reply,
		updateInvitationData,
		setInvitationFound,
		loadInvitationData,
	};
};
