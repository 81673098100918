import type { SchoolType } from "@/types/schoolTypes";
import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import React from "react";
import { useNavigate } from "react-router";

export function SchoolCard({ school }: { school: Partial<SchoolType> }) {
	const navigate = useNavigate();

	return (
		<div
			key={school.id}
			className="flex justify-between items-center border-t border-primary-100 py-sm"
		>
			<div className="flex items-center gap-sm">
				<Avatar firstname={school.name} image={school.logo?.filePath || null} />
				<span className="font-bold">{school.name}</span>
			</div>

			<ButtonPrimary
				label="Accéder à cet espace"
				onClick={() => {
					navigate(`/company?${school.subdomain}`);
					location.reload();
				}}
			/>
		</div>
	);
}
