import React from "react";

import RedirectionIllustration from "@assets/illustrations/redirection.svg";
export function AccessReplayModal() {
	return (
		<div className="flex flex-col items-center justify-center px-6">
			<img
				src={RedirectionIllustration}
				alt="Illustration redirection vers le lien du replay"
				className="object-cover"
			/>
			<h3 className="mt-8 !text-sm !font-semibold text-primary-700P">
				Vous allez être redirigé vers un site externe, pour visionner le replay.
			</h3>
		</div>
	);
}
