import { Icon } from "@iconify/react";
import React from "react";
import { Tooltip } from "react-tooltip";

import type { FieldType } from "../Kanban";

const getIconFromType = (type: string) => {
	switch (type) {
		case "text":
			return "eva:file-text-outline";
		case "date":
			return "eva:calendar-outline";
		case "email":
			return "eva:email-outline";
		case "phone":
			return "eva:phone-outline";
		case "number":
			return "eva:hash-outline";
		default:
			return "eva:file-text-outline";
	}
};

export const ProfileInformationRow = ({
	pid,
	value,
}: {
	pid: number;
	value: FieldType;
}) => {
	return (
		<div
			key={`PROSPECT-${pid}-${value.name}`}
			className="flex flex-row justify-between items-center"
		>
			<div className="flex flex-row gap-4 items-center">
				<Icon icon={getIconFromType(value.type)} className="text-xl" />
				<span className="text-sm font-semibold">{value.label}</span>
			</div>
			<div className="flex flex-row items-center gap-2">
				{value.type === "email" || value.type === "phone" ? (
					<>
						<a
							href={`${value.type === "email" ? "mailto" : "tel"}:${
								value.value
							}`}
							className="text-cyan-500 no-underline text-sm"
						>
							{value.type === "phone" && value.value.length > 10
								? `+${value.value.slice(
										0,
										value.value.length - 9,
									)} ${value.value.slice(-9)}`
								: value.value}
						</a>
						<button
							className="flex justify-center items-center w-8 h-8 copy-button"
							onClick={() => {
								navigator.clipboard.writeText(value.value);
							}}
						>
							<Icon icon="eva:copy-fill" className="text-xl text-gray-500" />
						</button>
						<Tooltip anchorSelect=".copy-button" place="bottom">
							Copier
						</Tooltip>
					</>
				) : value.type === "checkbox" ? (
					<span className="text-sm">{value.value ? "Oui" : "Non"}</span>
				) : (
					<span className="text-sm">{value.value}</span>
				)}
			</div>
		</div>
	);
};
