import type { OfferCellNameType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { CELL_DISALLOWED_RULES, offersTabsLabels } from "@constants/Offers";

import {
	convertOfferCellToSettingsItem,
	convertOfferCellsNamesToCells,
	convertSettingsItemToOfferCell,
	getAllCells,
	getTabsData,
} from "@tools/Offers";
import { getTabKeyFromIndex } from "@tools/Table";

import { Settings, type SettingsItem } from "@components/molecules/Settings";
import {
	type ScrollableTab,
	ScrollableTabGroup,
} from "@components/molecules/TabGroup";

import {
	setCurrentTabSelected,
	setUserTableConfig,
} from "../../core/store/offersManagement.slice";

export function OffersTabs() {
	const dispatchEvent = useDispatch();
	const {
		currentTabSelected,
		offersList,
		userTableConfig,
		counters,
		loadingStates,
	} = useAppSelector((state) => state.offers);

	const MakeScrollableTabGroupTabs = () => {
		const tabs: ScrollableTab[] = [];
		if (offersList) {
			getTabsData(counters).forEach((tab) => {
				tabs.push({
					label: `${tab.label}`,
					subLabel: loadingStates?.isFetchingOffersCounters
						? "..."
						: tab.counter,
					active: offersTabsLabels.get(currentTabSelected) === tab.label,
				});
			});
		}
		return tabs;
	};

	const handleSettingsModification = (settings: SettingsItem[]) => {
		const newSettings = settings.map(convertSettingsItemToOfferCell);
		const convertCellsToConfig: OfferCellNameType[] = [];
		newSettings.forEach((cell) => {
			convertCellsToConfig.push(cell.cellName as OfferCellNameType);
		});
		dispatchEvent(setUserTableConfig(convertCellsToConfig));
	};

	const handleTabChange = (id: number) => {
		dispatchEvent(
			setCurrentTabSelected(getTabKeyFromIndex(id, offersTabsLabels)),
		);
		dispatchEvent(
			setCurrentTabSelected(getTabKeyFromIndex(id, offersTabsLabels)),
		);
	};
	return (
		<div
			data-testid="offersTabsContainer"
			className="mt-md flex w-full justify-between"
		>
			<ScrollableTabGroup
				tabs={MakeScrollableTabGroupTabs()}
				onTabChange={(label) => handleTabChange(label)}
			/>
			<Settings
				settingsList={getAllCells()
					?.filter(
						(cell) =>
							!CELL_DISALLOWED_RULES.get(
								cell.cellName as OfferCellNameType,
							)?.includes(currentTabSelected),
					)
					?.map((cell, index) => convertOfferCellToSettingsItem(cell, index))}
				currentSettings={convertOfferCellsNamesToCells(userTableConfig)?.map(
					(cell, index) => convertOfferCellToSettingsItem(cell, index),
				)}
				onChangeTrigger={handleSettingsModification}
			/>
		</div>
	);
}
