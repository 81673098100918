import React from "react";

import type { ColumnType } from "./Column";
export const Header = ({
	column,
	numberOfCards,
}: {
	column: ColumnType;
	numberOfCards: number;
}) => {
	return (
		<div className="flex flex-row gap-4 p-4 pb-0 cursor-pointer">
			<span className="flex">{column.emoji}</span>
			<span className="flex font-bold">{column.title}</span>
			<span className="flex font-semibold text-gray-500">{numberOfCards}</span>
		</div>
	);
};
