import { useAppSelector } from "@config/redux/hook";
import useOfferReloadManagement from "@hooks/offers/useOfferReloadManagement";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import { setOfferToEdit } from "../core/store/offersManagement.slice";
import { EmptyList } from "../ui/listing/EmptyList";
import { OffersListingHeader } from "../ui/listing/OffersListingHeader";
import OffersTable from "../ui/listing/OffersTable";
import { OffersTabs } from "../ui/listing/OffersTabs";
export function OffersView() {
	useOfferReloadManagement();
	const dispatchEvent = useDispatch();
	const {
		structureView,
		setTitle,
	}: { structureView: object; setTitle: (title: string) => void } =
		useOutletContext();
	const { filteredOffersList, loadingStates } = useAppSelector(
		(state) => state.offers,
	);
	const canShowTables =
		structureView && filteredOffersList && filteredOffersList?.length > 0;

	useEffect(() => {
		dispatchEvent(setOfferToEdit(undefined));
		setTitle("Mes offres");
	}, []);
	return (
		<main className="" data-testid="offersView">
			<OffersListingHeader />
			<OffersTabs />
			{canShowTables || loadingStates?.isFetchingOffersList ? (
				<OffersTable
					data={filteredOffersList}
					currentStructureView={structureView}
				/>
			) : (
				<EmptyList />
			)}
		</main>
	);
}
