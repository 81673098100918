import { useAppSelector } from "@config/redux/hook";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { convertDateFromNow, getDateAfterDays } from "@tools/Dates";

import { DatePicker } from "@components/organisms/DatePicker";

import {
	modifyOfferToCreate,
	setOfferToCreate,
} from "../../core/store/offersManagement.slice";

function ExpirationDateModifier() {
	const dispatchEvent = useDispatch();
	const currentExpirationDate = useAppSelector(
		(state) => state.offers.offerToCreate?.expirationDate,
	);
	const { offerToCreate } = useAppSelector((state) => state.offers);

	const handleDateChange = (date: Date) => {
		dispatchEvent(
			setOfferToCreate({
				...offerToCreate,
				expirationDate: date.toISOString(),
			}),
		);
	};

	useEffect(() => {
		if (!currentExpirationDate) {
			dispatchEvent(
				modifyOfferToCreate({
					expirationDate: getDateAfterDays(90).toUTCString(),
				}),
			);
		}
	}, []);

	return (
		<div data-testid="expirationDateModifier" className="w-full">
			<DatePicker
				label={`Date d’expiration de l’offre (${convertDateFromNow(
					currentExpirationDate
						? new Date(currentExpirationDate)
						: getDateAfterDays(90),
				)})`}
				onDateChange={(date) => handleDateChange(date)}
				selectedDate={
					currentExpirationDate
						? new Date(currentExpirationDate)
						: getDateAfterDays(90)
				}
				withIcon
			/>
		</div>
	);
}

export default ExpirationDateModifier;
