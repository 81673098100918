import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
	alertTriangleOutline,
	bellOutline,
	calendarOutline,
} from "@assets/Icons";

import { capitalizeFirstLetter } from "@tools/Strings";

import { Divider } from "@components/atoms/Divider";
import { ListPrimary } from "@components/molecules/ListPrimary";
import { AccordionCustom } from "@components/organisms/Accordion";
import { Table } from "@components/templates/table/Table";

import {
	deleteAction,
	editAction,
} from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";
import { ManageActionModal } from "@containers/student/Applications/ApplicationDetail/ui/Modals/ManageActionModal";

export function ActivityFlow({ actionsState, contactsState }) {
	const [showManageActionModal, setShowManageActionModal] = useState(false);

	const [selectedActivity, setSelectedActivity] = useState({});
	const [activities, setActivities] = useState([]);
	const { actions, setActions } = actionsState;
	const { contacts } = contactsState;

	const getTimeOfActivity = (date) => {
		const momentDate = moment(date.split("/").reverse().join("-"));
		const result = moment().diff(momentDate, "d");
		if (result < 0) {
			return {
				label: "À venir",
				iconColor: "warning-dark",
				icon: calendarOutline,
			};
		}
		if (result > 0) {
			return {
				label: "En retard",
				iconColor: "error-dark",
				icon: alertTriangleOutline,
			};
		}
		return {
			label: "Aujourd'hui",
			iconColor: "success-dark",
			icon: bellOutline,
		};
	};

	const toggleActivity = (activity) => {
		const activitiesCopy = [...activities];

		const targetIndex = activitiesCopy.indexOf(activity);

		const targetActivity = { ...activitiesCopy.find((x) => x === activity) };
		targetActivity.done = !targetActivity.done;
		activitiesCopy.splice(targetIndex, 1);

		activitiesCopy.push(targetActivity);

		setActivities(activitiesCopy);
	};

	const wantEditAction = (id, action) => {
		const actionsCopy = [...actions];
		const idContacts = [];
		contacts.find((o) => {
			action?.contacts.forEach((contact) => {
				if (contact.includes(o.firstname)) {
					idContacts.push(o.id);
				}
			});
			return null;
		});
		const date = `${action.date} ${action.hour}`;

		const dataToSent = {
			id,
			title: action.title,
			note: action.notes,
			datetime: moment(date).tz("Europe/Paris").format("YYYY-MM-DDTHH:mm"),
			done: action.done,
			contacts: idContacts,
		};
		editAction(dataToSent).then(() => {
			Success("L'action a bien été modifiée");
		});
		const targetAction = actionsCopy.find((x) => x.id === Number(id));

		const indexTarget = actionsCopy.indexOf(targetAction);
		if (indexTarget > -1) {
			actionsCopy.splice(indexTarget, 1);
		}

		targetAction.title = action.title;
		targetAction.date = action.date;
		targetAction.hour = action.hour;
		targetAction.contacts = action.contacts;
		targetAction.notes = action.notes;
		targetAction.done = action.done;

		actionsCopy.unshift(targetAction);
		setActions([...actionsCopy]);
	};

	const deleteNote = (id) => {
		const actionsCopy = [...actions];
		const targetAction = actionsCopy.find((x) => x.id === Number(id));

		const indexTarget = actionsCopy.indexOf(targetAction);
		if (indexTarget > -1) {
			actionsCopy.splice(indexTarget, 1);
		}
		deleteAction(id);

		setActions([...actionsCopy]);
	};

	useEffect(() => {}, []);

	return (
		<div className="action-flow">
			<div className="mobile-container">
				<p className="title">"Flux d'activité"</p>
				<p className="subtitle">En cours</p>
				<div className="pl-sm pr-sm">
					<Divider className="" />
				</div>
				{activities
					.filter((x) => moment().diff(x.date, "day") < 0)
					.map((activity) => {
						const date = capitalizeFirstLetter(
							moment(activity.date.split("/").reverse().join("-")).format(
								"DD/MM",
							),
						);

						const activityTime = getTimeOfActivity(activity.date);
						return (
							<span key={activity.id}>
								<ListPrimary
									onClick={() => {
										setSelectedActivity(activity);
										setShowManageActionModal(true);
									}}
									onCheck={() => {
										toggleActivity(activity);
									}}
									label={date}
									icon={activityTime.icon}
									iconColor={activityTime.iconColor}
									title={activity.detail}
								/>
							</span>
						);
					})}
				<p className="subtitle history mt-md">Historique</p>
				<div className="pl-sm pr-sm">
					<Divider className="" />
				</div>
				{activities
					.filter((x) => moment().diff(x.date, "day") > 0)
					.map((activity) => {
						const date = capitalizeFirstLetter(
							moment(activity.date.split("/").reverse().join("-")).format(
								"DD/MM",
							),
						);
						const activityTime = getTimeOfActivity(activity.date);
						return (
							<span key={activity.id}>
								<ListPrimary
									onClick={() => {
										setSelectedActivity(activity);
										setShowManageActionModal(true);
									}}
									onCheck={() => {
										toggleActivity(activity);
									}}
									label={date}
									icon={activityTime.icon}
									iconColor={activityTime.iconColor}
									title={activity.detail}
								/>
							</span>
						);
					})}
			</div>

			<AccordionCustom
				className="accordion-action"
				title="Flux d'activité"
				headColor="white"
				deployed
				body={
					<Table
						className="mt-sm"
						rows={
							<>
								{activities
									.filter((x) => !x.done)
									.map((activity) => {
										const date = capitalizeFirstLetter(
											moment(
												activity.date.split("/").reverse().join("-"),
											).format("ddd DD MMMM"),
										);

										return {
											cells: [
												{
													children: date,
												},
												{
													label: activity.detail,
													onCheck: () => {
														toggleActivity(activity);
													},
													onClick: () => {
														setSelectedActivity(activity);
														setShowManageActionModal(true);
													},
													checked: false,
												},
												{
													children: getTimeOfActivity(activity.date),
												},
											],
										};
									})}
								<tr>
									<td colSpan={100}>
										<Divider className="mb-lg mt-lg" />
									</td>
								</tr>
								<p className="subtitle history mt-md">Historique</p>
								{actions
									.filter((x) => x.done)
									.map((activity) => {
										const date = capitalizeFirstLetter(
											moment(
												activity.date.split("/").reverse().join("-"),
											).format("ddd DD MMMM"),
										);

										const cellProps = () => {
											if (activity.detail === "actionCreated") {
												return [
													{
														children: date,
													},
													{
														children: activity.detail,
													},
													{
														children: getTimeOfActivity(activity.date),
													},
												];
											}
											if (
												activity.detail === "actionDeleted" ||
												activity.detail === "mailSended"
											) {
												return [
													{
														children: date,
													},
													{
														children: activity.detail,
													},
													{
														children: getTimeOfActivity(activity.date),
													},
												];
											}
											return null;
										};
										return { cells: { cellProps } };
									})}
							</>
						}
					/>
				}
			/>

			<ManageActionModal
				onDelete={deleteNote}
				onApply={wantEditAction}
				contacts={contacts}
				onClose={() => {
					setShowManageActionModal(false);
				}}
				action={selectedActivity}
				show={showManageActionModal}
			/>
		</div>
	);
}
ActivityFlow.propTypes = {
	actionsState: PropTypes.shape({
		actions: PropTypes.arrayOf(PropTypes.shape({})),
		setActions: PropTypes.func,
	}).isRequired,
	contactsState: PropTypes.shape({
		contacts: PropTypes.arrayOf(PropTypes.shape({})),
		setContacts: PropTypes.func,
	}).isRequired,
};
