import api from "../../../tools/API";

import type { CardType, FieldType, ProspectType } from "./ui/Kanban";
import type { ColumnType } from "./ui/Kanban/Column";

type AtsData = {
	columns: ColumnType[];
	cards: CardType[];
	fields: FieldType[];
};

export async function getAtsData() {
	let rep = {
		columns: [],
		cards: [],
		fields: [],
	};
	await api.get("/get_ats").then(({ data }) => {
		rep = data;
	});

	return rep as AtsData;
}

export function updateProspectsPosition(prospects: CardType[]) {
	// keep only the id and position and columnId of each prospect
	const prospectsPositions = prospects.map((prospect) => ({
		id: prospect.id,
		position: prospect.position,
		columnId: prospect.columnId,
	}));

	api.post("/update_prospects_position", prospectsPositions).catch(() => {});
}

export async function setProspectArchived(prospectId: number) {
	return api
		.post("/set_prospect_archived", { prospectId, isArchived: true })
		.catch(() => {
			return false;
		})
		.then(() => {
			return true;
		});
}

export async function setProspectNotOpened(prospectId: number) {
	return api
		.post("/set_prospect_not_opened", { prospectId })
		.catch(() => {
			return false;
		})
		.then(() => {
			return true;
		});
}

export async function getProspect(prospectId: number) {
	let rep = {};
	await api.get(`/get_prospect/${prospectId}`).then(({ data }) => {
		rep = data;
	});

	return rep as ProspectType;
}

export type ProspectActivity = {
	action: 0 | 1 | 2 | 3 | 4 | 5;
	createdAt: string;
	toProspect: string;
	byUser: string;
	comment: string;
};

export async function getProspectActivity(prospectId: number) {
	let rep = {};
	await api.get(`/get_prospect_activity/${prospectId}`).then(({ data }) => {
		rep = data;
	});

	return rep as ProspectActivity[];
}
