import { useBreakpoints } from "@hooks/useBreakpoints";
import { updateLogsConsultationLastDate } from "@navigation/NavigationAPI";
import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import SlidingPane from "react-sliding-pane";

import { Header } from "@components/organisms/header/Header";

import { ConversationPanel } from "@containers/shared/ConversationPanel";

import { crispSettings } from "@config/CrispSettings";
import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import { setTotalUnreadStudentMessages } from "@containers/school/Mailbox/controller/mailBox.slice";
import { fetchUnreadStudentMessagesCounter } from "@containers/school/Mailbox/controller/mailBoxManagement.thunks";
import { MenuNavigation } from "@navigation/MenuNavigation";
import { SlidingPaneContent } from "./SlidingPaneContent";

export type StudentLayoutContextType = {
	title: string;
	setTitle: (param: string) => string;
};

export type StudentContextType = {
	slidingPaneMessengerIsOpen: boolean;
	setSlidingPaneMessengerIsOpen: Function;
};
export const StudentContext = createContext<StudentContextType>({
	slidingPaneMessengerIsOpen: false,
	setSlidingPaneMessengerIsOpen: () => {},
});

export const StudentLayout = () => {
	const {
		user,
		setUser,
		currentInterface,
		setCurrentInterface,
		slidingPaneIsOpen,
		setSlidingPaneIsOpen,
	} = useContext<GlobalContextType>(GlobalContext);
	const { isMobile } = useBreakpoints();
	const { totalUnreadStudentMessages } = useAppSelector(
		(state) => state.mailBox,
	);
	const dispatchMailBox = useAppDispatch();
	const [notifCount, setNotifCount] = useState(0);
	const [slidingPaneMessengerIsOpen, setSlidingPaneMessengerIsOpen] =
		useState<boolean>(false);
	const [title, setTitle] = useState(
		`Bienvenue sur ton espace ${user?.schoolName}`,
	);
	const campusName =
		title === `Bienvenue sur ton espace ${user?.schoolName}` &&
		user?.schoolName !== user?.campusName
			? user?.campusName
			: "";
	const [structureView] = useState(
		user?.roles.includes("ROLE_SCHOOL")
			? { school: user?.school }
			: { campus: user?.campuses },
	);

	const scrollWhenClosePanel = () => {
		const bodyElement = document.getElementsByTagName("body")[0];
		bodyElement.style.overflow = "auto";
	};
	const closeCrisp = () => {
		const crispElement = document.getElementById("crisp-chatbox");
		if (crispElement) crispElement.classList.toggle("cc-tlyw", true);
	};
	const getCountTab = async () => {
		const totalT = await dispatchMailBox(fetchUnreadStudentMessagesCounter());
		dispatchMailBox(setTotalUnreadStudentMessages(totalT.payload));
	};

	useEffect(() => {
		document.title = `${title} - Mentor Goal`;
	}, [title]);

	useEffect(() => {
		if (user) {
			setNotifCount(user.newLogsCount);
			crispSettings(user, "student");
		}
	}, [user]);

	useEffect(() => {
		setCurrentInterface("student");
	}, []);
	useEffect(() => {
		getCountTab();
	}, [location.pathname, slidingPaneMessengerIsOpen]);

	return user ? (
		<StudentContext.Provider
			value={{
				slidingPaneMessengerIsOpen,
				setSlidingPaneMessengerIsOpen,
			}}
		>
			<MenuNavigation user={user} currentInterface={currentInterface} />
			<Header
				className="no-print no-margin"
				title={title}
				campusName={campusName ?? ""}
				notifCount={notifCount}
				messageCount={totalUnreadStudentMessages}
				onClick={() => {
					if (user?.id) {
						updateLogsConsultationLastDate(user?.id);
					}

					setNotifCount(0);
					setUser({ ...user, newLogsCount: 0 });
					setSlidingPaneIsOpen(true);
				}}
				onConversationClick={() => {
					setSlidingPaneMessengerIsOpen(true);
				}}
			/>

			<SlidingPane
				hideHeader
				isOpen={slidingPaneIsOpen}
				from="right"
				className="!z-[2147483647]"
				overlayClassName="!z-[2147483646]"
				width={isMobile ? "100%" : "40%"}
				onRequestClose={() => setSlidingPaneIsOpen(false)}
				shouldCloseOnEsc
			>
				<SlidingPaneContent setSlidingPaneIsOpen={setSlidingPaneIsOpen} />
			</SlidingPane>

			<SlidingPane
				hideHeader
				isOpen={slidingPaneMessengerIsOpen}
				from="right"
				className="!z-[2147483647]"
				overlayClassName="!z-[2147483646]"
				width={isMobile ? "100%" : "40%"}
				onRequestClose={() => {
					setSlidingPaneMessengerIsOpen(false);
					scrollWhenClosePanel();
					closeCrisp();
				}}
				shouldCloseOnEsc
			>
				<ConversationPanel
					onClose={() => {
						setSlidingPaneMessengerIsOpen(false);
						scrollWhenClosePanel();
						closeCrisp();
					}}
				/>
			</SlidingPane>
			<Outlet context={{ structureView, setTitle, currentInterface }} />
		</StudentContext.Provider>
	) : (
		<div />
	);
};
