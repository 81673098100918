import type { UserType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { fetchGoalsData } from "../DashboardAPI";

import { DefaultGoalsSkeleton } from "./DefaultGoals/DefaultGoalsSkeleton";
import { GoalBar } from "./DefaultGoals/GoalBar";

const OBJECTIVE_APPLICATIONS = 10;
const OBJECTIVE_CONTACTS = 5;
const OBJECTIVE_RELAUNCH = 5;

export type GoalType = {
	name: string;
	value: number;
	isCompleted: boolean;
	tippy: string;
	max: number;
};

export function DefaultGoals() {
	const { user } = useContext(GlobalContext);
	const [goals, setGoals] = useState<GoalType[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const calculateGoalsProgress = (data: UserType) => {
		setGoals([
			{
				name: `Envoyer ${OBJECTIVE_APPLICATIONS} candidatures`,
				value: data.thisWeekApplicationsCount,
				isCompleted: data.thisWeekApplicationsCount >= OBJECTIVE_APPLICATIONS,
				tippy:
					"Pour cela, envoie des candidatures à des entreprises et ajoute-les à ton tableau de suivi “Mes candidatures” !",
				max: OBJECTIVE_APPLICATIONS,
			},
			{
				name: `Réaliser ${OBJECTIVE_RELAUNCH} relance${OBJECTIVE_RELAUNCH > 1 ? "s" : ""}`,
				value: data.thisWeekRelaunchsCount,
				isCompleted: data.thisWeekRelaunchsCount >= OBJECTIVE_RELAUNCH,
				tippy:
					"Relance des entreprises pour lesquelles tu as déjà postulé et dont tu n’as pas eu de réponses. Déplace ensuite ta candidature dans “Relance réalisée” sur ton tableau de suivi !",
				max: OBJECTIVE_RELAUNCH,
			},
			{
				name: `Ajouter ${OBJECTIVE_CONTACTS} contacts`,
				value: data.thisWeekContactsCount,
				isCompleted: data.thisWeekContactsCount >= OBJECTIVE_CONTACTS,
				tippy:
					"Dans “Mes candidatures”, clique sur plusieurs d’entre elles et ajoute des contacts : ce sont tes interlocuteurs dans cette entreprise.",
				max: OBJECTIVE_CONTACTS,
			},
		]);
	};

	const fetchData = async () => {
		if (!user) return;
		const response = await fetchGoalsData(user.id);

		calculateGoalsProgress(response);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="flex flex-col">
			<p className="font-bold text-[18px]">Mes objectifs hebdomadaires</p>
			{isLoading && <DefaultGoalsSkeleton />}
			{goals?.map((goal, index) => {
				return <GoalBar key={index} goal={goal} />;
			})}
		</div>
	);
}
