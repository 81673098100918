import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "@assets/sass/Inputs.scss";

import { Input } from "./Input";
import { ItemSimpleLarge } from "./ItemSimpleLarge";

import "react-nice-dates/build/style.css";

export function InputTime({ icon, onChange, className, value }) {
	const [time, setTime] = useState(value);
	const [allTimes, setAllTimes] = useState([]);
	const [showOptions, setShowOptions] = useState(false);

	useEffect(() => {
		setTime(value);
	}, [value]);

	function getHours() {
		const items = [];
		new Array(12).fill().forEach((_acc, index) => {
			items.push({
				value: moment({ hour: index + 8 }).format("HH:mm"),
				label: moment({ hour: index + 8 }).format("HH:mm"),
			});
			items.push({
				value: moment({ hour: index + 8, minute: 15 }).format("HH:mm"),
				label: moment({ hour: index + 8, minute: 15 }).format("HH:mm"),
			});
			items.push({
				value: moment({ hour: index + 8, minute: 30 }).format("HH:mm"),
				label: moment({ hour: index + 8, minute: 30 }).format("HH:mm"),
			});
			items.push({
				value: moment({ hour: index + 8, minute: 45 }).format("HH:mm"),
				label: moment({ hour: index + 8, minute: 45 }).format("HH:mm"),
			});
		});
		items.push({
			value: moment({ hour: 20 }).format("HH:mm"),
			label: moment({ hour: 20 }).format("HH:mm"),
		});

		setAllTimes(items);
	}

	useEffect(() => {
		getHours();
	}, []);

	return (
		<div
			data-id="inputTime"
			className={`input-time-picker w-full ${className}`}
		>
			<div
				onClick={() => {
					setShowOptions(!showOptions);
				}}
			>
				<Input
					onClick={() => {
						setShowOptions(true);
					}}
					icon={icon}
					placeholder="HH:mm"
					value={time}
					onChange={() => {}}
				/>
			</div>
			{showOptions && (
				<div className="input-time-dropdown">
					{allTimes.map((time, i) => {
						return (
							<div
								data-id="combobox-dropdown-ItemSimple"
								className="option-cb"
								key={i}
							>
								<ItemSimpleLarge
									onClick={() => {
										setTime(time.value);
										onChange(time.value);
										setShowOptions(false);
									}}
									label={time.label}
								/>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
}

InputTime.propTypes = {
	icon: PropTypes.shape({}),
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
};
InputTime.defaultProps = {
	icon: null,
	className: "",
	value: "08:00",
};
