import type { LogType } from "@/types/logTypes";

import { briefcaseOutline } from "@assets/Icons";

import { LOGS_LABELS } from "@constants/Logs";

export const resolveLog = (log: LogType, currentInterface?: string) => {
	const logLabels = LOGS_LABELS.find((label) => {
		return label.action === log.action;
	});

	if (!logLabels) {
		return { icon: briefcaseOutline, label: log.action };
	}

	return {
		icon: logLabels.icon,
		label:
			currentInterface === "student"
				? logLabels.firstPersonLabel
				: logLabels.thirdPersonLabel,
	};
};
