import React from "react";

import { Avatar } from "../../../../components/atoms/Avatar";
import type { School } from "../index";

type HeaderProps = {
	school?: School;
	formStep: number;
	formTotalSteps: number;
};

export const Header = ({ school, formStep, formTotalSteps }: HeaderProps) => {
	return (
		<header className="flex flex-col">
			<div className="mx-2 w-full flex sm:max-w-xl md:max-w-2xl lg:max-w-6xl xl:max-w-6xl my-2 gap-4 md:mx-auto">
				<div>
					{school && (
						<Avatar
							size="md"
							modify={false}
							image={school?.logo}
							firstname={school?.name.split(" ")[0] || ""}
							lastname={school?.name.split(" ")[1]}
						/>
					)}
				</div>
				<div className="flex flex-row">
					{school && (
						<div className={"hidden sm:!flex sm:flex-row"}>
							<span className="flex self-center text-black font-semibold">
								{school?.name}
							</span>
							<hr className="self-center vertical-separator" />
						</div>
					)}
					{formTotalSteps > 0 && (
						<span className="flex self-center text-black font-semibold">
							Étape {formStep + 1} sur {formTotalSteps}
						</span>
					)}
				</div>
			</div>
			<div className="h-2 bg-indigo-400 w-full">
				<div
					className="bg-indigo-600 h-full"
					style={{ width: `${(100 / formTotalSteps) * (formStep + 1)}%` }}
				/>
			</div>
		</header>
	);
};
