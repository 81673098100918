export const TAG_TYPES_OPTIONS = [
	{ id: 0, label: "Général (école)", value: "school" },
	{ id: 1, label: "Compétence (Hard Skill)", value: "hardSkill" },
	{ id: 2, label: "Compétence (Soft Skill) ", value: "softSkill" },
];

export const COLORS_TAG_OPTIONS = [
	{ id: 0, value: "bg-emerald-light" },
	{ id: 1, value: "bg-terracotta-light" },
	{ id: 2, value: "bg-sakura-light" },
	{ id: 3, value: "bg-kaki-light" },
	{ id: 4, value: "bg-aquamarine-light" },
	{ id: 5, value: "bg-sunflower-light" },
	{ id: 6, value: "bg-lavender-light" },
	{ id: 7, value: "bg-stone-light" },
];
