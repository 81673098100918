import type { ApplicationType } from "@/types/applicationTypes";
import useDoubleClick from "@hooks/useDoubleClick";
import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
	alertTriangleFill,
	archiveOutline,
	checkmarkFill,
	closeFill,
	closeOutline,
	heartFill,
	heartOutline,
} from "@assets/Icons";

import { formatDate } from "@tools/Dates";

import { Badge } from "@components/atoms/Badge";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import CompanyName from "@components/organisms/company/CompanyName";
import {
	Success,
	updateToastWithError,
	updateToastWithSuccess,
} from "@components/organisms/toast/ToastNotification";

import { editApplication } from "../../../../ApplicationsManagementAPI";
import { useAppplicationsContext } from "../../context";

type ApplicationCardProps = {
	index: number;
	applicationInitialState: ApplicationType;
	moveApplication: Function;
	onClick: Function;
	ondrop: any;
};
export function ApplicationCard({
	applicationInitialState,
	onClick,
	ondrop,
}: ApplicationCardProps) {
	const applicationsContext = useAppplicationsContext();
	const [application, setApplication] = useState(applicationInitialState);
	const [thisCardWasDropped, setThisCardWasDropped] = useState<boolean>(false);

	const buttonRef = useRef<HTMLDivElement>(null);

	const updateApplication = async (updatedApplication: ApplicationType) => {
		const updateApplicationToast = toast.loading(
			"Mise à jour de la candidature...",
			{
				isLoading: true,
				hideProgressBar: false,
				type: "default",
			},
		);
		const inputs = {
			id: updatedApplication.id,
			archived: updatedApplication.archived,
			favorite: updatedApplication.favorite,
			accepted: updatedApplication.accepted,
		};

		const editApplicationResponse = await editApplication(inputs);
		if (editApplicationResponse.responseLabel === "success") {
			setApplication(updatedApplication);
			updateToastWithSuccess(updateApplicationToast, "Candidature mise à jour");
		} else {
			updateToastWithError(
				updateApplicationToast,
				"Erreur lors de la mise à jour",
			);
		}
	};

	const getMenu = (application: ApplicationType) => {
		return (
			<>
				<div ref={refuseRef}>
					<ButtonTertiary
						className="close-btn btn-menu small text-error-dark"
						icon={closeFill}
						size="small"
						onClick={() => {
							updateApplication({
								...application,
								accepted: false,
							});
						}}
						tooltip="Candidature refusée"
					/>
				</div>
				<div ref={archiveButtonRef}>
					<ButtonTertiary
						icon={archiveOutline}
						size="small"
						onClick={() => {
							updateApplication({
								...application,
								archived: !application?.archived,
							});
							if (application?.archived) {
								Success("Candidature restaurée");
							} else {
								Success(
									"La candidature a été archivée.Tu peux la retrouver en utilisant les filtres.",
								);
							}
						}}
						tooltip={`${
							application?.archived
								? "Restaurer la candidature"
								: "Archiver la candidature"
						}`}
					/>
				</div>
			</>
		);
	};
	const tagElements = () => {
		switch (application?.state) {
			case "accepted":
				return application?.isTreated ? (
					<Badge
						className="label-application-accepted slideInRight"
						label="Envoyée par l'école"
						bgColor="bg-success-light"
						hasBorder
					/>
				) : (
					<Badge
						className="label-application-accepted slideInRight"
						label="Candidature acceptée"
						rightIcon={checkmarkFill}
						bgColor="bg-success-light"
						hasBorder
					/>
				);
			case "onHold":
				return (
					<Badge
						className="label-application-accepted slideInRight"
						label="Nécessite une mise à jour"
						leftIcon={alertTriangleFill}
						bgColor="bg-warning-light"
						hasBorder
					/>
				);
			case "refused":
				return (
					<Badge
						className="label-application-accepted slideInRight"
						label="Non selectionnée"
						bgColor="bg-error-light"
						hasBorder
					/>
				);
			default:
				return null;
		}
	};
	const studentTagElement = () => {
		if (application?.accepted === null) {
			return;
		}
		if (application?.accepted) {
			return (
				<Badge
					className="label-application-accepted slideInRight"
					label="Candidature acceptée"
					rightIcon={checkmarkFill}
					bgColor="bg-success-light"
					hasBorder
				/>
			);
		}
		return (
			<Badge
				className="label-application-denied slideInRight"
				label="Candidature refusée"
				rightIcon={closeOutline}
				bgColor="bg-error-light"
				hasBorder
			/>
		);
	};

	const getDateToShow = () => {
		switch (application?.step) {
			case "readyToApply":
				return `Créée le ${formatDate(application?.createdAt)}`;
			case "sent":
				return application?.sentDate && application.sentDate !== null
					? `Envoyée le ${formatDate(application?.sentDate)}`
					: "";
			case "relaunch":
				return application?.relaunchDate && application.relaunchDate !== null
					? `Relancée le ${formatDate(application?.relaunchDate)}`
					: "";
			case "interview":
				return application?.interviewDate && application.interviewDate !== null
					? `Entretien le ${formatDate(application?.interviewDate)}`
					: "";
			default:
				return "";
		}
	};
	useEffect(() => {
		setThisCardWasDropped(true);
	}, [ondrop]);

	useEffect(() => {
		if (
			thisCardWasDropped === true &&
			applicationsContext.applicationData.id === application.id
		) {
			setApplication(applicationsContext.applicationData);
			setThisCardWasDropped(false);
		}
	}, [applicationsContext.applicationData]);

	const favoriteRef = useRef(null);
	const refuseRef = useRef(null);
	const archiveButtonRef = useRef(null);

	useDoubleClick({
		onSingleClick: () => {
			onClick();
		},
		onDoubleClick: () => {
			updateApplication({
				...application,
				favorite: !application?.favorite,
			});
		},
		ref: buttonRef,
		ignoreRefs: [favoriteRef, refuseRef, archiveButtonRef],
		latency: 250,
	});

	return (
		<div className="group relative flex justify-between gap-xsm flex-row">
			<div
				ref={buttonRef}
				className={`bg-white p-sm w-full cursor-pointer gap-xxsm transition-all rounded-lg shadow-xsm ${
					application?.favorite || application?.state ? "label-display" : ""
				}`}
			>
				<div className="absolute top-[-10px] right-0 flex">
					{application?.state !== null
						? tagElements() || studentTagElement()
						: null}
				</div>
				<div className="flex justify-between max-w-full">
					<p className="wg-no-translate text-sm text-primary-900 truncate">
						{application?.job}
					</p>

					<div
						onClick={(e: any) => {
							e.stopPropagation();
						}}
						className="md:hidden"
					/>
				</div>
				<div className="card-primary-bottom flex items-center justify-between">
					<CompanyName
						children={""}
						nameToDisplay={application?.organization?.name || ""}
					/>
				</div>
				<div className="flex gap-xxsm w-full justify-start items-center">
					<div
						onClick={() => {
							updateApplication({
								...application,
								favorite: !application?.favorite,
							});
						}}
						ref={favoriteRef}
						className="cursor-pointer"
					>
						{application?.favorite ? (
							<Icon icon={heartFill} className=" text-red-500 w-4 h-4" />
						) : (
							<Icon icon={heartOutline} className="text-primary-500 w-4 h-4" />
						)}
					</div>
					<p className="text-xxxsm font-bold text-primary-300 w-full">
						{getDateToShow()}
					</p>
					<div className="flex w-full cursor-pointer items-center justify-end gap-xsm ">
						{getMenu(application)}
					</div>
				</div>
			</div>
		</div>
	);
}
