import { Icon } from "@iconify/react";
import React, {
	type ChangeEvent,
	type HTMLInputTypeAttribute,
	useEffect,
	useRef,
	useState,
} from "react";

import "@assets/sass/Inputs.scss";

import {
	externalLinkOutline,
	infoOutline,
	minusOutline,
	plusOutline,
} from "@assets/Icons";

import { checkUrl } from "@tools/Checkers";

import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { Divider } from "@components/atoms/Divider";
import TooltipContainer from "@components/atoms/TooltipContainer";

import "react-nice-dates/build/style.css";

type InputProps = {
	name?: string;
	type?: HTMLInputTypeAttribute;
	useURLEncoding?: boolean;
	value?: string;
	numberValue?: number;
	label?: string;
	placeholder?: string | null;
	className?: string;
	icon?: any;
	disabled?: boolean;
	errorMessage?: string;
	helperText?: string;
	onChange?: (e: any) => void;
	onBlur?: any;
	onClick?: any;
	onKeyDown?: any;
	min?: number;
	infoText?: string;
};

export function Input({
	type = "text",
	name,
	value,
	numberValue,
	onChange,
	label,
	placeholder = "",
	className = "",
	icon,
	disabled = false,
	errorMessage = "",
	helperText = "",
	onBlur,
	onClick,
	onKeyDown,
	min = 0,
	infoText = "",
}: InputProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputValue, setInputValue] = useState<string | number>(value ?? "");
	const [inputNumber, setInputNumber] = useState<number>(min);
	const [isValidUrl, setIsValidUrl] = useState(false);

	const getInputProps = () => {
		if (type === "number") {
			return {
				min,
			};
		}
		return {};
	};
	const handleCountValue = (name: string) => {
		if (Number.isNaN(Number(inputNumber)) && type !== "number") return null;
		if (name === "minus" && Number(inputNumber) !== 1) {
			return setInputNumber((prev) => Number(prev) - 1);
		}
		if (name === "plus") {
			return setInputNumber((prev) => Number(prev) + 1);
		}
	};

	useEffect(() => {
		if (typeof value === "string") {
			setInputValue(value);
			if (value?.includes(".")) {
				setIsValidUrl(checkUrl(value));
			}
		}
	}, [value]);
	useEffect(() => {
		if (type === "number" && numberValue) setInputNumber(numberValue);
	}, [numberValue]);
	useEffect(() => {
		if (type === "number") {
			if (onChange) {
				onChange(inputNumber);
			}
		}
	}, [inputNumber]);
	return (
		<div
			data-id="inputTertiary"
			className={`input-tertiary-container ${className}`}
		>
			{label && (
				<p
					className={`mb-xxsm text-xxsm font-normal text-primary-500 flex items-center ${
						disabled && "is-disabled"
					}`}
				>
					{label}
					{infoText?.length > 0 && (
						<>
							<TooltipContainer
								anchorId={`#tooltip-${name}`}
								makeClickable={false}
								children={infoText}
								place="top"
							/>
							<Icon
								icon={infoOutline}
								className="h-4 w-4 ml-xsm !text-primary-500"
								id={`tooltip-${name}`}
							/>
						</>
					)}
				</p>
			)}
			<div className="flex items-center">
				<div className="flex min-h-[40px] w-full rounded-md border border-primary-150 bg-white px-4 py-2 transition hover:border-accent-1 hover:shadow-accent1Lighter">
					{icon && (
						<Icon
							className={`mr-xsm h-[24px] w-[24px] ${
								disabled && " is-disabled"
							}`}
							icon={icon}
						/>
					)}
					<input
						ref={inputRef}
						data-testid="input"
						autoComplete="on"
						onBlur={onBlur}
						type={type}
						name={name}
						value={type === "number" ? inputNumber : inputValue}
						onClick={onClick}
						onKeyDown={onKeyDown}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							if (type === "number") {
								return setInputNumber(Number(e.target.value));
							}

							if (onChange) {
								onChange(e);
							}

							setInputValue(e.target.value);
						}}
						placeholder={placeholder || ""}
						className={`w-11/12 truncate text-base font-normal text-primary-700P outline-0 ${
							icon && " with-icon"
						} ${errorMessage && "error-message"} ${
							disabled && "value-disabled"
						} ${type === "number" ? "arrow-hide" : ""}`}
						disabled={disabled}
						{...getInputProps()}
					/>
					{isValidUrl && (
						<div className="flex items-center">
							<Divider className="vertical-separator mr-xsm" />
							<span id={`tooltip-link-${name}`}>
								<ButtonTertiary
									className="!p-[5.5px]"
									iconClassName="!h-[13px] !w-[13px]"
									icon={externalLinkOutline}
									onClick={() => {
										if (typeof value === "string") {
											window.open(decodeURIComponent(value), "_blank");
										}
									}}
								/>
								<TooltipContainer
									anchorId={`#tooltip-link-${name}`}
									makeClickable={false}
									children="Consulter le lien"
									place="top"
								/>
							</span>
						</div>
					)}
					{type === "number" && (
						<div className="flex gap-xsm">
							<ButtonTertiary
								onClick={() => {
									handleCountValue("minus");
								}}
								className="shadow-primary150"
								size="small"
								icon={minusOutline}
							/>
							<ButtonTertiary
								onClick={() => {
									handleCountValue("plus");
								}}
								className="shadow-primary150"
								size="small"
								icon={plusOutline}
							/>
						</div>
					)}
				</div>
			</div>
			{helperText && !errorMessage && (
				<p className="text-p pt-xxsm text-primary-200">{helperText}</p>
			)}
			{errorMessage && (
				<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
			)}
		</div>
	);
}
