import { InlineIcon } from "@iconify/react";
import React from "react";

import { alertCircleOutline, infoOutline } from "@assets/Icons";

type TooltipElementProps = {
	id: string;
	type?: "info" | "alert";
	className?: string;
};
function TooltipElement({
	id,
	className = "",
	type = "info",
}: TooltipElementProps) {
	const getIcon = () => {
		switch (type) {
			case "info":
				return infoOutline;
			case "alert":
				return alertCircleOutline;
			default:
				return infoOutline;
		}
	};
	const getColor = () => {
		switch (type) {
			case "info":
				return "text-primary-300";
			case "alert":
				return "text-red-500";
			default:
				return "text-primary-300";
		}
	};
	return (
		<span
			className={`flex h-6 items-start justify-center ${className}`}
			id={id}
		>
			<InlineIcon icon={getIcon()} className={`"h-4 w-4 ${getColor()}`} />
		</span>
	);
}

export default TooltipElement;
