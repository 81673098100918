import React, { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";

import "@assets/sass/RichTextEditor.scss";

import "react-quill/dist/quill.snow.css";

type RichTextEditorType = {
	id?: string;
	className?: string;
	value: string;
	placeholder: string;
	onChange: (content: string) => void;
	onBlur?: (content: string) => void;
	onFocus?: (content: string) => void;
	modules?: {
		[key: string]: any;
	};
	maxLength?: number | null;
};

export function RichTextEditor({
	id,
	className,
	value,
	placeholder,
	onChange,
	onBlur,
	onFocus,
	modules = {},
	maxLength = null,
}: RichTextEditorType) {
	const color: string[] = [
		"transparent",
		"#000000",
		"#e60000",
		"#ff9900",
		"#ffff00",
		"#008a00",
		"#0066cc",
		"#9933ff",
		"#ffffff",
		"#facccc",
		"#ffebcc",
		"#ffffcc",
		"#cce8cc",
		"#cce0f5",
		"#ebd6ff",
		"#bbbbbb",
		"#f06666",
		"#ffc266",
		"#ffff66",
		"#66b966",
		"#66a3e0",
		"#c285ff",
		"#888888",
		"#a10000",
		"#b26b00",
		"#b2b200",
		"#006100",
		"#0047b2",
		"#6b24b2",
		"#444444",
		"#5c0000",
		"#663d00",
		"#666600",
		"#003700",
		"#002966",
		"#3d1466",
		"custom-color",
	];

	const formats: string[] = [
		"header",
		"font",
		"size",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
		"color",
		"align",
		"background",
	];

	const fontSizeArr: string[] = [
		"12px",
		"14px",
		"16px",
		"20px",
		"24px",
		"32px",
	];

	const Size = Quill.import("attributors/style/size");
	Size.whitelist = fontSizeArr;
	Quill.register(Size, true);

	const Font = Quill.import("formats/font");
	Font.whitelist = [
		"lato",
		"montserrat",
		"mulish",
		"nunito",
		"open-sans",
		"playfair-display",
		"poppins",
		"roboto",
	];
	Quill.register(Font, true);

	useEffect(() => {
		if (!modules) {
			modules = {
				toolbar: [
					[
						{
							font: [
								"lato",
								"montserrat",
								"mulish",
								"nunito",
								"open-sans",
								"playfair-display",
								"poppins",
								"roboto",
							],
						},
					],
					[{ size: fontSizeArr }],
					[{ header: 1 }, { header: 2 }, "blockquote"],
					[
						{
							color,
						},
						{
							background: color,
						},
					],
					["bold", "italic", "underline"],
					["image", "link", "video"],
					[{ list: "ordered" }, { list: "bullet" }],
					[
						{ align: "" },
						{ align: "center" },
						{ align: "right" },
						{ align: "justify" },
					],
				],
				clipboard: {
					matchVisual: false,
				},
			};
		}
	}, [modules]);

	const handleOnChange = (content: string) => {
		// biome-ignore lint/suspicious/noControlCharactersInRegex: This regex is used to remove control characters from the content https://github.com/webonyx/graphql-php/blob/8b17963e21c7ce4336ea4d799150962bbe7a1fbe/src/Language/Lexer.php#L639
		content = content.replace(/[\x00-\x1F\x7F-\x9F]/g, "");
		if (onChange) onChange(content);
	};

	return (
		<div className="w-full flex-col gap-4">
			<ReactQuill
				id={id}
				className={`
        wg-no-translate mt-xsm
        flex flex-col-reverse gap-xxsm [&._ql-toolbar]:!rounded-t-md [&._ql-toolbar]:!bg-primary-50
        [&_.ql-editor]:rounded-md [&_.ql-container]:bg-white [&_.ql-editor::before]:text-xsm [&_.ql-editor::before]:!not-italic [&_.ql-editor::before]:!text-primary-300
        [&_.ql-editor]:min-h-[320px] [&_.ql-editor]:!border [&_.ql-editor]:!border-primary-150 [&_.ql-container]:!border-0 ${className}
        `}
				value={value}
				onChange={handleOnChange}
				onBlur={() => {
					if (onBlur) onBlur(value);
				}}
				onFocus={() => {
					if (onFocus) onFocus(value);
				}}
				formats={formats}
				modules={modules}
				placeholder={placeholder}
			/>
			{maxLength !== null && (
				<p className={value?.length > maxLength ? "text-error-dark" : ""}>
					{value?.length} / {maxLength}
				</p>
			)}
		</div>
	);
}
