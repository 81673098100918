import { Icon, type IconifyIcon } from "@iconify/react";
import React, { type MouseEventHandler } from "react";

type InlineButtonPropsType = {
	className?: string;
	icon?: string | IconifyIcon;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	label?: string;
	iconPosition?: string;
	id?: string;
	disabled?: boolean;
	iconSize?: string;
	type?: "button" | "submit" | "reset" | undefined;
	textClasses?: string;
};

export function InlineButton({
	className = "",
	disabled = false,
	icon,
	onClick,
	label = "",
	id = "",
	iconPosition = "left",
	iconSize,
	type = "button",
	textClasses = "",
}: InlineButtonPropsType) {
	return (
		<button
			id={id}
			data-id="inline-button"
			type={type}
			onClick={onClick}
			disabled={disabled}
			className={`inline-button ${className}`}
		>
			{icon && iconPosition === "left" && (
				<Icon className={`mr-xsm h-4 w-4 ${iconSize}`} icon={icon} />
			)}
			{label && <p className={textClasses}>{label}</p>}
			{icon && iconPosition === "right" && (
				<Icon className={`ml-xsm h-4 w-4 ${iconSize}`} icon={icon} />
			)}
		</button>
	);
}
