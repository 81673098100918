import type { OfferCellNameType, SchoolOfferType } from "@/types/offerTypes";
import type { UserType } from "@/types/userTypes";

import { closeOutline, emailOutline } from "@assets/Icons";

import {
	CONTRACT_TYPE_OPTIONS,
	offerTypeLabel,
	offerVisibilityLabel,
} from "@constants/Offers";

import { cellFactory } from "@components/templates/table/Cell.factory";

type OfferCellFactoryProps = {
	handleShareAction: Function;
	handleCloseOffer: Function;
};
function useOfferCellFactory({
	handleShareAction,
	handleCloseOffer,
}: OfferCellFactoryProps) {
	const currentCellFactory = cellFactory();

	const buildOfferCellFromName = (
		cellName: OfferCellNameType | "testing",
		offerData: SchoolOfferType,
	) => {
		const cellsMapping = new Map<OfferCellNameType | "testing", JSX.Element>([
			["job", currentCellFactory.text(offerData.job)],
			[
				"crmCompany_name",
				currentCellFactory.title(
					offerData?.crmCompany?.name ? offerData?.crmCompany?.name : "-",
				),
			],
			[
				"applicationsToTreat",
				currentCellFactory.number(
					offerData?.applicationsToTreat,
					true,
					offerData?.type !== "direct",
				),
			],
			[
				"applicationsToSend",
				currentCellFactory.number(
					offerData?.applicationsToSend || 0,
					true,
					offerData?.type !== "direct",
				),
			],
			["location", currentCellFactory.text(offerData.location)],
			[
				"visibility",
				currentCellFactory.tag({
					label: offerVisibilityLabel.get(offerData?.visibility)?.label || "-",
					shortLabel:
						offerVisibilityLabel.get(offerData?.visibility)?.shortLabel || "-",
					bgColor:
						offerVisibilityLabel.get(offerData?.visibility)?.bgColor ||
						"bg-white",
					textColor:
						offerVisibilityLabel.get(offerData?.visibility)?.textColor ||
						"text-primary-700P",
					borderColor:
						offerVisibilityLabel.get(offerData?.visibility)?.borderColor ||
						"text-primary-700P",
				}),
			],
			["type", currentCellFactory.text(offerTypeLabel.get(offerData.type))],
			["sharesCount", currentCellFactory.number(offerData.sharesCount)],
			[
				"contract",
				currentCellFactory.text(
					CONTRACT_TYPE_OPTIONS.find((e) => e.value === offerData?.contract)
						?.label || "Non renseigné",
				),
			],
			["views", currentCellFactory.number(offerData.views)],
			[
				"applicationsCount",
				currentCellFactory.number(offerData.applicationsCount),
			],
			[
				"sentToCompanyCount",
				currentCellFactory.number(
					offerData?.sentToCompanyCount,
					false,
					offerData?.type !== "direct",
				),
			],
			[
				"responsable_firstname",
				currentCellFactory.person(offerData?.responsable || "", true),
			],
			[
				"conContract_spsStudent_id",
				currentCellFactory.person(
					(offerData?.studentGetJob?.student as Partial<UserType>) || "",
					true,
				),
			],
			["tags", currentCellFactory.multiTag(offerData.tags)],
			[
				"shared",
				currentCellFactory.action(
					handleShareAction,
					emailOutline,
					"primary",
					undefined,
					{ id: offerData?.id },
				),
			],
			["date", currentCellFactory.date(offerData?.date, true)],
			["updateAt", currentCellFactory.date(offerData?.updateAt, true)],
			["closeAt", currentCellFactory.date(offerData?.closeAt)],
			["providedAt", currentCellFactory.date(offerData?.closeAt)],

			["closeReason", currentCellFactory.text(offerData?.closeReason || "-")],
			["expiredAt", currentCellFactory.date(offerData?.expirationDate, true)],
			[
				"closeOffer",
				currentCellFactory.action(
					handleCloseOffer,
					closeOutline,
					"tertiary",
					undefined,
					{ id: offerData?.id },
				),
			],
			["testing", currentCellFactory.testing(offerData?.id)],
		]);
		return cellsMapping.get(cellName);
	};

	return {
		buildOfferCellFromName,
	};
}

export default useOfferCellFactory;
