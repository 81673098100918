import type { SchoolOfferType } from "@/types/offerTypes";
import React from "react";

import { Memo } from "@components/molecules/Memo";
import BodyGrid from "@components/templates/page/BodyGrid";
import Container from "@components/templates/page/Container";
import Subcontainer from "@components/templates/page/Subcontainer";

import { OfferDetailsDescription } from "./tabs/offer/OfferDetailsDescription";
import { OfferDetailsDocuments } from "./tabs/offer/OfferDetailsDocuments";
import { OfferDetailsInformations } from "./tabs/offer/OfferDetailsInformations";
import { OfferDetailsShareStudentPannel } from "./tabs/offer/OfferDetailsShareStudentPannel";

export function InformationsTab({
	offerToEdit,
}: {
	offerToEdit: Partial<SchoolOfferType>;
}) {
	return (
		<BodyGrid>
			<Container side="left">
				<Subcontainer>
					<OfferDetailsInformations />
					<OfferDetailsDescription />
					<OfferDetailsDocuments />
				</Subcontainer>
			</Container>
			<Container side="right">
				<Subcontainer>
					<OfferDetailsShareStudentPannel />
				</Subcontainer>
				<Subcontainer withoutBackground>
					<Memo memoId={offerToEdit?.id || ""} type="offer" />
				</Subcontainer>
			</Container>
		</BodyGrid>
	);
}
