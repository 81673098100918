import { type RefObject, useEffect, useRef, useState } from "react";

export default function useIsTextTruncated(ref: RefObject<HTMLElement>) {
	const [textTruncated, setTextTruncated] = useState<boolean>(false);
	const prevContentRef = useRef<string | null>(null);

	useEffect(() => {
		const element = ref.current;
		if (element) {
			const currentContent = element.textContent || "";
			if (prevContentRef.current !== currentContent) {
				prevContentRef.current = currentContent;
			}
			if (element.scrollWidth > element.offsetWidth) {
				setTextTruncated(true);
			} else {
				setTextTruncated(false);
			}
		}
	}, [ref, ref.current?.textContent]);

	return textTruncated;
}
