import api from "@tools/API";
import { stringifyRequestFilters } from "@tools/Objects";
export async function getResources(school: string, filters: any) {
	let rep = {};
	const data = {
		query: `
          query {
            schoolResources : resources (isMentorgoal: false, spsReferent_coreSchool: "${school}", itemsPerPage: 1000, order: [{title: "ASC"}], ${stringifyRequestFilters(
							filters,
						)}) {
              collection {
                id
                color
                link
                type
                title
                description
                isDirectLink
                isMentorgoal
                pdf{
                  filePath
                }
                illustration
                {
                  filePath
                }
              }
            },
            mgResources : resources (isMentorgoal: true, itemsPerPage: 1000, order: [{title: "ASC"}], ${stringifyRequestFilters(
							filters,
						)}) {
              collection {
                id
                color
                link
                title
                type
                description
                isDirectLink
                isMentorgoal
                pdf{
                  filePath
                }
                illustration
                {
                  filePath
                }
              }
            }
        }
    `,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});

	return rep;
}
