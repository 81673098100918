import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import "../ApplicationDetails.scss";

import { editOutline, globe2Outline, linkedinFill } from "@assets/Icons";

import {
	checkEmail,
	checkLinkedinCompany,
	checkPhone,
	checkUrl,
} from "@tools/Checkers";
import { removeEmptyString } from "@tools/Objects";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { InlineButton } from "@components/atoms/InlineButton";
import { Input } from "@components/molecules/Input";
import { InputPhoneNumber } from "@components/molecules/PhoneInput";
import CompanyName from "@components/organisms/company/CompanyName";

import { editApplication } from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";

import "react-loading-skeleton/dist/skeleton.css";

export function Organization({ applicationProps, companyProps, loading }) {
	const { application } = applicationProps;
	const [displayModify, setDisplayModify] = useState(false);
	const { company, setCompany } = companyProps;
	const companyCopy = { ...company };

	const updateAlternativeOrganizationData = async (values) => {
		const valuesCopy = removeEmptyString(values);

		const data = {
			id: application.id,
			alternativeName: valuesCopy.name ?? company.name,
			alternativeAddress: valuesCopy.address,
			alternativeEmail: valuesCopy.email,
			alternativePhone: valuesCopy.phone,
			alternativeLinkedin: valuesCopy.linkedin,
			alternativeWebsite: valuesCopy.website,
		};

		setCompany({
			...company,
			name: valuesCopy.name,
			website: valuesCopy.website,
			linkedin: valuesCopy.linkedin,
			address: valuesCopy.address,
			email: valuesCopy.email,
			phone: valuesCopy.phone,
		});

		editApplication(data);
		setDisplayModify(false);
	};
	useEffect(() => {}, [setCompany]);

	return (
		<div className="hidden w-full rounded-md bg-white px-sm py-md md:block">
			<div className="organization-header">
				<p className="text-sm font-bold text-primary-700P">Organisation</p>{" "}
				{(companyCopy.address ||
					companyCopy.email ||
					companyCopy.linkedin ||
					companyCopy.phone) && (
					<ButtonQuaternary
						onClick={() => {
							setDisplayModify(!displayModify);
						}}
						className={`${displayModify && "hidden"}`}
						icon={editOutline}
					/>
				)}
			</div>
			{loading ? (
				<div className="organization-loading mt-xlg">
					<Skeleton height={96} width={96} circle />
					<Skeleton className="mt-sm" height={12} width={90} />
					<div className="flex">
						<Skeleton className="mt-xsm" height={16} width={16} />
						<Skeleton className="ml-xsm mt-xsm" height={16} width={16} />
					</div>

					<div className="mt-xlg flex w-full justify-between">
						<Skeleton className="mt-sm" height={12} width={90} />
						<Skeleton className="mt-sm" height={12} width={140} />
					</div>
					<div className="flex w-full justify-between">
						<Skeleton className="mt-sm" height={12} width={90} />
						<Skeleton className="mt-sm" height={12} width={200} />
					</div>
					<div className="flex w-full justify-between">
						<Skeleton className="mt-sm" height={12} width={130} />
						<Skeleton className="mt-sm" height={12} width={90} />
					</div>
				</div>
			) : (
				<div>
					<Formik
						initialValues={{
							name: application.alternativeName || company?.name,
							website: application.alternativeWebsite || company?.website,
							linkedin: application.alternativeLinkedin || company?.linkedin,
							address: application.alternativeAddress || company?.address,
							email: application.alternativeEmail || company?.email,
							phone: application.alternativePhone || company?.phone,
						}}
						onSubmit={(values, { resetForm }) => {
							updateAlternativeOrganizationData(values);
							resetForm({});
						}}
						enableReinitialize
						validate={(values) => {
							const errors = {};
							if (!values.name) {
								errors.name = "Entrez un nom d'entreprise valide";
							}
							if (values.email && !checkEmail(values.email)) {
								errors.email = "Entrez un email valide";
							}
							if (values.phone && !checkPhone(values.phone)) {
								errors.phone = true;
							}
							if (values.linkedin && !checkLinkedinCompany(values.linkedin)) {
								errors.linkedin = "Entrez un Linkedin valide";
							}
							if (values.website && !checkUrl(values.website)) {
								errors.website =
									"Veuillez entrer un url valide exemple: https://mentorgoal.com ";
							}
							return errors;
						}}
					>
						{({
							values,
							resetForm,
							handleSubmit,
							errors,
							touched,
							setFieldValue,
						}) => (
							<div>
								<div className="mt-xlg flex flex-col items-center justify-center gap-md">
									<Avatar
										image=""
										modify={false}
										firstname={values?.name ? "E" : values?.name?.split(" ")[0]}
										lastname={values?.name ? "A" : values?.name?.split(" ")[1]}
										onChange={(e) => {
											setFieldValue("logo", e);
										}}
										size="lg"
									/>
									<CompanyName nameToDisplay={company?.name} />
								</div>

								{displayModify ? (
									<div>
										<Input
											className="mt-xlg"
											placeholder="Ex : 5 rue Rivoli"
											label="Adresse postale"
											name="address"
											onChange={(e) => {
												setFieldValue("address", e.target.value);
											}}
											value={values.address}
										/>
										<Input
											className="mt-sm"
											placeholder="exemple@exemple.com"
											label="Email"
											name="email"
											onChange={(e) => {
												setFieldValue("email", e.target.value);
											}}
											value={values.email}
											errorMessage={
												errors.email && touched.email && errors.email
											}
										/>
										<InputPhoneNumber
											required={false}
											value={values.phone}
											className="mt-sm"
											label="Téléphone"
											name="phone"
											onChange={(value) => {
												setFieldValue("phone", value);
											}}
										/>
										<div className="mt-sm">
											<Input
												placeholder="https://"
												label="Linkedin"
												name="linkedin"
												onChange={(e) => {
													setFieldValue("linkedin", e.target.value);
												}}
												value={values.linkedin}
												errorMessage={
													errors.linkedin && touched.linkedin && errors.linkedin
												}
												helperText="La page Linkedin affiche les employés de l’entreprise"
											/>
										</div>
										<div className="mt-sm">
											<Input
												placeholder="https://"
												label="Site internet"
												name="website"
												onChange={(e) => {
													setFieldValue("website", e.target.value);
												}}
												value={values.website}
												errorMessage={errors.website}
												helperText="Le site internet affiche des informations de contact"
											/>
										</div>
										<div className="flex justify-center">
											<div className="w-fit-content mt-xlg flex justify-center">
												<ButtonSecondary
													label="Annuler"
													onClick={() => {
														resetForm();
														setDisplayModify(false);
													}}
												/>
												<ButtonPrimary
													onClick={handleSubmit}
													className="ml-sm"
													label="Enregistrer"
												/>
											</div>
										</div>
									</div>
								) : (
									<div>
										<div className="flex flex-col items-center justify-center ">
											<div className="mt-xsm flex">
												{application.alternativeWebsite || company?.website ? (
													<ButtonQuaternary
														onClick={() =>
															window.open(
																`${
																	application.alternativeWebsite ||
																	company?.website
																}`,
																"_blank",
															)
														}
														className="small"
														icon={globe2Outline}
													/>
												) : (
													""
												)}
												{application.alternativeLinkedin ||
												company?.linkedin ? (
													<ButtonQuaternary
														className="small"
														icon={linkedinFill}
														onClick={() => {
															window.open(
																`${
																	application.alternativeLinkedin ||
																	company?.linkedin
																}`,
																"_blank",
															);
														}}
													/>
												) : (
													""
												)}
											</div>
										</div>
										<div className="mt-xlg flex flex-col">
											{application.alternativeAddress || company?.address ? (
												<div className="flex justify-between">
													<p className="text-xsm font-bold text-primary-200">
														Siège
													</p>
													<p className="ml-xlg text-right text-xsm text-primary-700P">
														{application.alternativeAddress || company?.address}
													</p>
												</div>
											) : (
												""
											)}

											{application.alternativeEmail || company?.email ? (
												<div className="mt-sm flex  justify-between">
													<p className="text-xsm font-bold text-primary-200">
														Email
													</p>
													<p className="ml-xlg text-right text-xsm text-primary-700P">
														{application.alternativeEmail || company?.email}
													</p>
												</div>
											) : (
												""
											)}

											{application.alternativePhone || company?.phone ? (
												<div className="mt-sm flex justify-between">
													<p className="text-xsm font-bold text-primary-200">
														Téléphone
													</p>
													<p className="ml-xlg text-right text-xsm text-primary-700P">
														{application.alternativePhone || company?.phone}
													</p>
												</div>
											) : (
												""
											)}
											{!company?.address &&
												!company?.email &&
												!company?.linkedin &&
												!company?.phone && (
													<div className="mt-4 flex justify-center">
														<InlineButton
															onClick={() => {
																setDisplayModify(!displayModify);
															}}
															label="Modifier les informations"
															icon={editOutline}
														/>
													</div>
												)}
										</div>
									</div>
								)}
							</div>
						)}
					</Formik>
				</div>
			)}
		</div>
	);
}
Organization.propTypes = {
	loading: PropTypes.bool.isRequired,
	applicationProps: PropTypes.shape({
		application: PropTypes.shape({
			id: PropTypes.string.isRequired,
			alternativeAddress: PropTypes.string.isRequired,
			alternativeWebsite: PropTypes.string.isRequired,
			alternativeLinkedin: PropTypes.string.isRequired,
			alternativeName: PropTypes.string.isRequired,
			alternativePhone: PropTypes.string.isRequired,
			alternativeEmail: PropTypes.string.isRequired,
			documents: PropTypes.shape({}),
		}),
		setApplication: PropTypes.func,
	}).isRequired,
	companyProps: PropTypes.shape({ company: "", setCompany: () => {} })
		.isRequired,
};
