import React from "react";
import { NavLink } from "react-router-dom";

import "./Onboarding.scss";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";

export function Onboarding() {
	return (
		<main className="onboard-container">
			<h1 className="title-onboard">Bienvenue sur Mentor Goal &#x1F44B;</h1>
			<p className="subtitle-onboard">
				La plateforme tout-en-un pour décrocher ton alternance, stage ou premier
				emploi
			</p>
			<iframe
				className="video-welcome"
				src="https://www.youtube.com/embed/rPsqe-mY1zk"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>

			<NavLink to="/discover" className=" text-decoration-none mt-xxlg">
				<ButtonPrimary label="Découvrir" className="w-fit-content" />
			</NavLink>
		</main>
	);
}
