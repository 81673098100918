import { Icon } from "@iconify/react";
import React from "react";
import { useContext, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Tooltip } from "react-tooltip";

import { Avatar } from "../../../../../components/atoms/Avatar";
import { setProspectArchived } from "../../AtsAPI";
import {
	RemoveProspectContext,
	SetProspectNotOpenedContext,
} from "../../index";

import { CardAction } from "./CardAction";
import type { CardType } from "./index";

export const DATEFORMAT = {
	year: "numeric" as const,
	month: "short" as const,
	day: "numeric" as const,
	hour: "numeric" as const,
	minute: "numeric" as const,
};
type CardProps = {
	card: CardType;
	onCardClick: (card: CardType) => void;
	index: number;
};
export const Card = ({ card, onCardClick, index }: CardProps) => {
	const [showActions, setShowActions] = useState<boolean>(false);
	const setProspectNotOpened = useContext(SetProspectNotOpenedContext);
	const handleShowActions = (e: React.MouseEvent) => {
		e.stopPropagation();
		setShowActions(!showActions);
	};
	const removeProspect = useContext(RemoveProspectContext);
	const OnArchiveClick = async () => {
		removeProspect(card.id);
		await setProspectArchived(card.id);
	};

	return (
		<Draggable
			key={`CARD-${card.id}`}
			draggableId={`CARD-${card.id}`}
			index={index}
		>
			{(provided) => (
				<div
					className="relative my-2 "
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<div
						className={`flex flex-row bg-white p-4 gap-2 rounded-md shadow-md relative ${
							!card.hasBeenOpened &&
							'before:content-[""] before:w-1.5 before:bg-cyan-500 before:absolute before:left-0 before:top-0 before:h-full before:rounded-l-3xl'
						}`}
						onClick={() => onCardClick(card)}
					>
						<Avatar
							firstname={card.data.firstName}
							lastname={card.data.lastName}
							image=""
						/>
						<div className="flex flex-1 flex-row justify-between ">
							<div className="flex flex-col justify-between">
								<span
									className="font-bold line-clamp-1"
									id={`card-full-name-${card.id}`}
								>
									{card.data.firstName} {card.data.lastName}
								</span>
								<Tooltip
									anchorSelect={`#card-full-name-${card.id}`}
									className={"z-50"}
								>
									{card.data.firstName} {card.data.lastName}
								</Tooltip>
								<span className="text-gray-500 text-xs">
									{new Intl.DateTimeFormat("fr-FR", DATEFORMAT).format(
										new Date(card.createdAt),
									)}
								</span>
							</div>
							<div
								className="flex items-center h-full text-xl text-gray-500 cursor-pointer"
								onClick={handleShowActions}
							>
								<Icon icon="eva:more-vertical-fill" />
							</div>
						</div>
					</div>
					<div
						className={`absolute flex flex-row justify-between right-0 top-0 left-0 bottom-0 transition-all ease-[ease] duration-200 bg-cyan-500 rounded-md shadow-md ${
							showActions ? "w-full opacity-1 py-4 pr-3 pl-4" : "w-0 opacity-0"
						}`}
					>
						{showActions && (
							<>
								<div className="flex flex-row gap-2 h-full">
									<CardAction
										cardId={card.id}
										icon="eva:archive-outline"
										onActionClick={OnArchiveClick}
										actionName="Archiver"
									/>
									<CardAction
										cardId={card.id}
										icon={"eva:loader-outline"}
										onActionClick={() => setProspectNotOpened(card.id)}
										actionName="Marquer comme non lu"
									/>
								</div>
								<div
									className="flex items-center h-full text-3xl text-white cursor-pointer"
									onClick={handleShowActions}
								>
									<Icon icon="eva:close-fill" />
								</div>
							</>
						)}
					</div>
				</div>
			)}
		</Draggable>
	);
};
