import { useBreakpoints } from "@hooks/useBreakpoints";
import { Icon, type IconifyIcon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import $ from "jquery";
import React, { type MouseEventHandler } from "react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Header.scss";

import { bellOutline, menuOutline, messageCircleOutline } from "@assets/Icons";

import { logout } from "@tools/Auth";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonQuinary } from "@components/atoms/ButtonQuinary";

import { deleteCookie, getCookie } from "@tools/Cookies";
import { getUserAvatarPath } from "@tools/Users";
import { Dropdown } from "../../molecules/Dropdown";
import { SelectCampus } from "../select/SelectCampus";

type HeaderLargeSecondaryType = {
	title: string;
	className?: string;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	notifCount: number;
	messageCount?: number;
	campusName?: string;
	onConversationClick?: () => void;
};

function resolveMenuItems() {
	const navigate = useNavigate();
	const { user } = useContext(GlobalContext);

	const dropdownItems = [
		{
			label: "Paramètres",
			action: () => {
				navigate(
					getCookie("interface") === "student"
						? "/student-parameters"
						: "/school-parameters",
					{
						replace: true,
					},
				);
			},
		},
		{
			label: "Déconnexion ",
			action: () => {
				logout(
					user?.schoolName !== undefined &&
						(user?.schoolName.toLowerCase() === "amos" ||
							user?.schoolName.toLowerCase() === "cmh" ||
							user?.schoolName.toLowerCase() === "esdac") &&
						(user.roles.includes("ROLE_SCHOOL") ||
							user.roles.includes("ROLE_CAMPUS_ADMIN") ||
							user.roles.includes("ROLE_CAMPUS_USER")),
				);
			},
		},
	];
	if (
		user?.roles.includes("ROLE_CAMPUS_USER") ||
		user?.roles.includes("ROLE_SCHOOL") ||
		user?.roles.includes("ROLE_CAMPUS_ADMIN")
	) {
		dropdownItems.unshift({
			label: "Parrainage",
			action: () => {
				window.open(
					`https://form.mentorgoal.com/parrainage?parrain=form.mentorgoal.com/filleul?parrain=${user?.id}`,
					"_blank",
				);
			},
		});
	}

	if (user && "profilesCount" in user && user?.profilesCount > 1) {
		dropdownItems.unshift({
			label: "Changer de profil",
			action: () => {
				deleteCookie("interface");
				deleteCookie("token");
				window.location.replace("/choose_profile");
			},
		});
	}
	return dropdownItems;
}

export function HeaderLargeSecondary({
	title,
	className = "",
	onClick,
	campusName,
	notifCount,
	messageCount = 0,
	onConversationClick,
}: HeaderLargeSecondaryType) {
	const { user } = useContext(GlobalContext);
	const [showNotifs, setShowNotifs] = useState(false);

	useEffect(() => {
		$(window).click(() => {
			if (setShowNotifs) setShowNotifs(false);
		});

		$(".notifications-center-container .button-quinary").click((event) => {
			event.stopPropagation();
			setShowNotifs(!showNotifs);
		});
	}, [showNotifs]);

	return (
		<div
			data-id="headerLargeSecondary"
			className={`justify-between pb-lg pl-[124px] pr-[52px] pt-lg  ${
				window.location.href.split("/")[3] === "test-softr"
					? "hidden"
					: "md:flex"
			} ${className}`}
		>
			<div className="alert-items-center flex">
				<Avatar
					firstname={user?.schoolName?.split(" ")[0] ?? ""}
					lastname={user?.schoolName?.split(" ")[1] ?? ""}
					image={user?.schoolLogo ?? ""}
					className="no-event"
				/>
				<div className="ml-md flex flex-col items-start justify-center">
					<p className="text-sm font-bold text-primary-700P">{title}</p>
					{campusName && (
						<p className="text-xsm font-bold text-primary-300">{campusName}</p>
					)}
				</div>
			</div>
			<div className="flex items-center">
				<div className="flex gap-sm">
					<ButtonQuinary
						className=""
						disabled={false}
						shape="rectangle"
						dropdownButton={false}
						icon={bellOutline}
						notifCount={notifCount}
						onClick={onClick}
					/>
					<ButtonQuinary
						className=""
						disabled={false}
						shape="rectangle"
						dropdownButton={false}
						icon={messageCircleOutline}
						notifCount={messageCount}
						onClick={() => {
							if (onConversationClick) onConversationClick();
						}}
					/>
				</div>
				<hr className="vertical-separator" />
				<p className="wg-no-translate secondary-large-header-name mr-sm">
					{user?.firstname} {user?.lastname}
				</p>
				<Dropdown
					direction="left"
					button={
						<Avatar
							className="cursor-pointer"
							firstname={user?.firstname || ""}
							lastname={user?.lastname || ""}
							image={(user && getUserAvatarPath(user)) || ""}
						/>
					}
					items={resolveMenuItems()}
					subtitle={"Déplacer la candidature" ?? ""}
				/>
			</div>
		</div>
	);
}

type HeaderSmallPrimaryType = {
	buttonIcon: string | IconifyIcon;
	title: string;
	className?: string;
	notifCount: number;
	messageCount?: number;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	onConversationClick?: () => void;
};

export function HeaderSmallPrimary({
	buttonIcon,
	title,
	className = "",
	onClick,
	notifCount,
	messageCount = 0,
	onConversationClick,
}: HeaderSmallPrimaryType) {
	const { user, setUser, setMenuActiveNavigation, menuActiveNavigation } =
		useContext(GlobalContext);
	return (
		<div
			data-id="headerSmallPrimary"
			className={`primary-small-header-container flex gap-sm md:hidden ${className}`}
		>
			<ButtonQuinary
				className=""
				disabled={false}
				shape="rectangle"
				dropdownButton={false}
				notifCount={0}
				onClick={() => {
					setUser({ ...user });
					if (menuActiveNavigation !== undefined) {
						setMenuActiveNavigation(!menuActiveNavigation);
					}
				}}
				icon={buttonIcon}
			/>
			<p className="primary-small-header-label truncate">{title}</p>
			<div className="flex items-center">
				<div className="flex gap-sm">
					<ButtonQuinary
						className=""
						disabled={false}
						shape="rectangle"
						dropdownButton={false}
						icon={bellOutline}
						notifCount={notifCount}
						onClick={onClick}
					/>
					<ButtonQuinary
						className=""
						disabled={false}
						shape="rectangle"
						dropdownButton={false}
						icon={messageCircleOutline}
						notifCount={messageCount}
						onClick={() => {
							if (onConversationClick) onConversationClick();
						}}
					/>
				</div>

				<Dropdown
					className="ml-md"
					button={
						<Avatar
							firstname={user?.firstname || ""}
							lastname={user?.lastname || ""}
							image={(user && getUserAvatarPath(user)) || ""}
						/>
					}
					items={resolveMenuItems()}
					subtitle={"Déplacer la candidature" ?? ""}
					direction="left"
				/>
			</div>
		</div>
	);
}

type HeaderType = {
	title: string;
	className?: string;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	notifCount: number;
	messageCount: number;
	campusName?: string;
	onConversationClick?: () => void;
};

export function Header({
	title,
	className,
	onClick,
	campusName,
	notifCount,
	messageCount,
	onConversationClick,
}: HeaderType) {
	const { isMobile } = useBreakpoints();

	return (
		<div>
			{!isMobile ? (
				<HeaderLargeSecondary
					title={title}
					className={className}
					onClick={onClick}
					campusName={campusName}
					notifCount={notifCount}
					messageCount={messageCount}
					onConversationClick={onConversationClick}
				/>
			) : (
				<HeaderSmallPrimary
					title={title}
					className={className}
					onClick={onClick}
					buttonIcon={menuOutline}
					notifCount={notifCount}
					messageCount={messageCount}
					onConversationClick={onConversationClick}
				/>
			)}
		</div>
	);
}

type HeaderSchoolType = {
	title: string;
	className?: string;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	onClickMessaging?: () => void;
	notifCount: number;
	messageCount?: number;
	onChange: (param: any) => void;
	structureView: any;
	accessList: any;
};

export function HeaderSchool({
	title,
	className,
	notifCount,
	messageCount = 0,
	onClick,
	onClickMessaging,
	accessList,
	onChange,
	structureView,
}: HeaderSchoolType) {
	const [showNotifs, setShowNotifs] = useState(false);
	const { user } = useContext(GlobalContext);

	const navigate = useNavigate();

	useEffect(() => {
		$(window).click(() => {
			if (setShowNotifs) setShowNotifs(false);
		});

		$(".notifications-center-container .button-quinary").click((event) => {
			event.stopPropagation();
			setShowNotifs(!showNotifs);
		});
	}, [showNotifs, accessList]);

	return (
		<div
			data-id="headerSchool"
			className={`school-header-container hidden pt-lg md:flex md:items-center md:justify-between ${className}`}
		>
			<div className="alert-items-center flex">
				<Avatar
					className="no-event"
					firstname={user?.schoolName?.split(" ")[0] ?? ""}
					lastname={user?.schoolName?.split(" ")[1] ?? ""}
					image={user?.schoolLogo ?? ""}
				/>
				{accessList && (
					<div className="ml-md">
						<p className="text-sm font-bold text-primary-700P">{title}</p>
						{accessList.length === 1 ? (
							<p className="text-xsm font-bold text-primary-300">
								{accessList[0].label}
							</p>
						) : (
							<SelectCampus
								options={accessList}
								className="p-0 text-primary-300"
								defaultValue={accessList.find(
									(access: any) =>
										JSON.stringify(access.value) ===
										JSON.stringify(structureView),
								)}
								onChange={onChange}
							/>
						)}
					</div>
				)}
			</div>

			<div className="flex items-center">
				<div className="flex gap-sm">
					<ButtonQuinary
						className=""
						disabled={false}
						shape="rectangle"
						dropdownButton={false}
						icon={bellOutline}
						notifCount={notifCount}
						onClick={onClick}
					/>
					<ButtonQuinary
						className=""
						disabled={false}
						shape="rectangle"
						dropdownButton={false}
						icon={messageCircleOutline}
						notifCount={messageCount ?? 0}
						onClick={() => {
							if (onClickMessaging) onClickMessaging();
							navigate("/mail-box");
						}}
					/>
				</div>
				{user?.roles.includes("ROLE_CAMPUS_ADMIN") &&
					user?.roles.includes("ROLE_ATS") && (
						<>
							<hr className="vertical-separator" />
							<Dropdown
								direction="right"
								className={"m-1.5 mb-0"}
								button={<Icon className={"text-2xl"} icon="eva:grid-outline" />}
								items={[
									{
										label: "ATS",
										action: () => {
											navigate("/ats", {
												replace: true,
											});
										},
									},
									{
										label: "MentorGoal",
										action: () => {
											navigate("/dashboard", {
												replace: true,
											});
										},
									},
								]}
								subtitle={"Déplacer la candidature" ?? ""}
							/>
						</>
					)}

				<hr className="vertical-separator" />
				<p className="wg-no-translate school-header-name mr-sm">
					{user?.firstname} {user?.lastname}
				</p>
				<Dropdown
					direction="left"
					button={
						<Avatar
							className="cursor-pointer"
							firstname={user?.firstname || ""}
							lastname={user?.lastname || ""}
							image={(user && getUserAvatarPath(user)) || ""}
						/>
					}
					items={resolveMenuItems()}
					subtitle={"Déplacer la candidature" ?? ""}
				/>
			</div>
		</div>
	);
}
