import type { ViewType } from "@/types/genericType";
import type { FetchOffersListParamsType } from "@/types/offersRequestTypes";
import { useAppSelector } from "@config/redux/hook";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

import type { OfferFiltersType } from "@/types/offersFiltersTypes";
import type { AppState } from "@config/redux/store";
import {
	setCurrentPage,
	setOfferStatusWasUpdated,
} from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import {
	fetchOffersCounters,
	fetchOffersList,
	fetchUserTableConfig,
} from "@containers/school/Offers_v3/core/store/offersManagement.thunks";

function useOfferReloadManagement() {
	const { structureView }: { structureView: ViewType } = useOutletContext();
	const dispatchEvent = useDispatch();
	const {
		currentTabSelected,
		pageShown,
		itemsPerPage,
		order,
		isInitialized,
		offerStatusWasUpdated,
		userWasInEditionPage,
	} = useAppSelector((state) => state.offers);

	const {
		currentSearch,
		currentFilters,
		useFilters,
	}: {
		currentSearch: string;
		currentFilters: OfferFiltersType;
		useFilters: boolean;
	} = useAppSelector((state: AppState) => state.offersFilters);

	const constructFetchParams = () => {
		const params: FetchOffersListParamsType = {
			search: currentSearch,
			page: pageShown,
			itemsPerPage,
			status: currentTabSelected,
			order,
			filters: useFilters ? currentFilters : {},
		};
		if (structureView?.campus) params.campuses = structureView.campus;
		if (structureView?.school) params.schoolId = structureView.school;
		return params;
	};
	useEffect(() => {
		if (isInitialized) {
			dispatchEvent(fetchOffersList(constructFetchParams()) as any);
			dispatchEvent(fetchOffersCounters(constructFetchParams()) as any);
			dispatchEvent(setOfferStatusWasUpdated(false));
		}
	}, [offerStatusWasUpdated]);

	useEffect(() => {
		if (isInitialized) {
			if (!userWasInEditionPage) {
				dispatchEvent(setCurrentPage(1));
			}
			dispatchEvent(fetchOffersList(constructFetchParams()) as any);
			dispatchEvent(fetchOffersCounters(constructFetchParams()) as any);
		}
	}, [currentSearch, currentFilters]);

	useEffect(() => {
		if (isInitialized) {
			dispatchEvent(fetchOffersList(constructFetchParams()) as any);
			dispatchEvent(fetchOffersCounters(constructFetchParams()) as any);
		}
	}, [currentTabSelected]);
	useEffect(() => {
		if (isInitialized) {
			dispatchEvent(fetchOffersList(constructFetchParams()) as any);
			dispatchEvent(fetchOffersCounters(constructFetchParams()) as any);
		}
	}, [pageShown, itemsPerPage, order, structureView]);

	useEffect(() => {
		if (isInitialized) {
			return;
		}
		dispatchEvent(fetchUserTableConfig() as any);
		dispatchEvent(fetchOffersList(constructFetchParams()) as any);
		dispatchEvent(fetchOffersCounters(constructFetchParams()) as any);
	}, []);
}

export default useOfferReloadManagement;
