import type { FormationType } from "@/types/userTypes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { trash2Outline } from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { TextArea } from "@components/atoms/TextArea";
import { CheckboxLabelPrimary } from "@components/molecules/CheckboxLabel";
import { Input } from "@components/molecules/Input";
import { DatePicker } from "@components/organisms/DatePicker";
import { Modal } from "@components/organisms/modal/Modal";

import DeleteModal from "./DeleteModal";

type FormationEditorModalType = {
	show: boolean;
	onClose: Function;
	editCareer: Function;
	formation?: FormationType;
};

export function FormationEditorModal({
	show,
	onClose,
	editCareer,
	formation,
}: FormationEditorModalType) {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [inProgress, setInProgress] = useState(formation?.endDate === null);
	const [keyForReload, setKeyForReload] = useState(0);

	useEffect(() => {
		setKeyForReload(keyForReload + 1);
	}, [formation]);

	return (
		<div>
			<Formik
				initialValues={{
					id: formation?.id || null,
					schoolName: formation?.schoolName || "",
					name: formation?.name || "",
					city: formation?.city || "",
					detail: formation?.detail || "",
					startDate: formation?.startDate
						? new Date(formation?.startDate)
						: undefined,
					endDate: formation?.endDate
						? new Date(formation?.endDate)
						: undefined,
				}}
				validate={(values) => {
					const errors: any = {};

					if (!values.schoolName) {
						errors.schoolName = "Veuillez rentrer une école";
					}
					if (!values?.name) {
						errors.name = "Veuillez entrer le nom de la formation";
					}
					if (!values.startDate) {
						errors.startDate = "Veuillez entrer une date de début";
					}
					if (!inProgress && !values.endDate) {
						errors.endDate = "Veuillez entrer une date de fin";
					}

					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					editCareer(
						"formation",
						formation === null ? "create" : "update",
						values,
					);
					resetForm({});
					onClose();
				}}
				key={keyForReload}
			>
				{({
					values,
					errors,
					setFieldValue,
					handleChange,
					handleSubmit,
					resetForm,
				}) => (
					<Modal
						show={show}
						onClose={() => {
							onClose();
							resetForm();
						}}
						title="Ajouter une formation"
						body={
							<div className="flex flex-col gap-sm">
								<div className="flex flex-col items-center">
									<Avatar
										firstname={values.schoolName}
										size="lg"
										lastname=""
										image=""
									/>
									<Input
										className="w-fit-content mt-sm"
										placeholder="Nom de l’école"
										name="schoolName"
										value={values.schoolName}
										onChange={handleChange}
										errorMessage={errors.schoolName as string}
									/>
								</div>
								<div className="inputs">
									<Input
										label="Intitulé de la formation"
										placeholder="Ex : Bachelor développeur web"
										className="mt-md w-full"
										name="name"
										value={values?.name}
										onChange={handleChange}
										errorMessage={errors?.name as string}
									/>
								</div>

								<DatePicker
									onDateChange={(date) => {
										setFieldValue("startDate", date);
									}}
									selectedDate={values.startDate}
									label="Début de la formation"
									errorMessage={errors.startDate as string}
								/>

								{!inProgress && (
									<DatePicker
										onDateChange={(date) => {
											setFieldValue("endDate", date);
										}}
										selectedDate={values.endDate}
										label="Fin de la formation"
										errorMessage={errors.endDate as string}
									/>
								)}
								<CheckboxLabelPrimary
									label="Formation en cours"
									className="w-fit-content"
									checked={inProgress}
									onCheck={() => {
										setInProgress(!inProgress);
									}}
								/>
								<Input
									label="Ville"
									placeholder="Ex: Lyon"
									name="city"
									value={values.city}
									onChange={handleChange}
								/>
								<TextArea
									label="Détails (optionnel)"
									placeholder="Tu peux indiquer le programme de ta formation, ce que tu as appris, etc..."
									height="150px"
									value={values.detail}
									onChange={(e) => {
										setFieldValue("detail", e);
									}}
								/>
							</div>
						}
						buttonsLeft={[
							formation && (
								<ButtonTertiary
									icon={trash2Outline}
									onClick={() => {
										onClose();

										setShowDeleteModal(true);
									}}
								/>
							),
						]}
						buttonsRight={[
							<ButtonSecondary
								label="Annuler"
								key="cancelBTN"
								onClick={() => {
									resetForm({});
									onClose();
								}}
							/>,
							<ButtonPrimary
								label={formation ? "Modifier" : "Ajouter"}
								key="addBTN"
								type="submit"
								onClick={() => {
									handleSubmit();
								}}
							/>,
						]}
					/>
				)}
			</Formik>
			<DeleteModal
				label="Es-tu sûr de vouloir supprimer cette formation ?"
				show={showDeleteModal}
				type="formation"
				editCareer={editCareer}
				entityId={formation?.id}
				onClose={() => {
					setShowDeleteModal(false);
				}}
			/>
		</div>
	);
}
