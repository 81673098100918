import { Icon } from "@iconify/react";
import React from "react";

import noSearchResultIllustration from "../../../../../assets/illustrations/noSearchResult.svg";
export const EmailSms = () => {
	return (
		<div className="flex flex-1 w-full flex-col">
			<div className="flex flex-row justify-between items-center">
				<span className="text-sm font-semibold flex">Derniers échanges</span>
				<div className="flex flex-row gap-3">
					<button className="flex items-center border px-2 rounded-md">
						<Icon icon={"eva:search-fill"} />
					</button>
					<button className="border text-sm p-2 py-1 rounded-md font-semibold">
						Marquer comme non lu
					</button>
				</div>
			</div>
			<div className="flex flex-1 flex-col items-center justify-center gap-8">
				<img
					src={noSearchResultIllustration}
					alt="No search result"
					className="h-32 w-80"
				/>
				<span className="text-center font-semibold text-2xl">
					Aucun message à l’horizon
				</span>
			</div>
			<div className="flex w-full mb-6">
				<textarea
					className="p-3 min-h-24 border w-full resize-none rounded-md focus:outline-none focus:border-indigo-600 focus:ring-1 focus:ring-indigo-600 ease-in-out duration-100"
					placeholder="Tapez votre message..."
				/>
			</div>
		</div>
	);
};
