import { useAppSelector } from "@config/redux/hook";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { InlineIcon } from "@iconify/react";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import { arrowBackOutline, globe2Outline, pinOutline } from "@assets/Icons";

import { getContractType } from "@tools/Offers";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";

import type { BackgroundColorType } from "@/types/colorsType";
import { PostulateButton } from "../shared/PostulateButton";

export function Head({ position }: { position: string }) {
	const { offer, showMinifyHead } = useAppSelector(
		(state) => state.offerDetail,
	);
	const { currentInterface }: { currentInterface: string } = useOutletContext();

	const { isMobile } = useBreakpoints();

	const navigate = useNavigate();
	return (
		<div id="head" className="relative mt-lg rounded-lg bg-white p-md md:mt-0">
			<p className="no-print w-fit-content absolute left-0 right-0 top-[-18px] m-auto truncate rounded-full bg-primary-100 px-sm py-xxsm text-primary-700P shadow-md">
				{`Offre exclusive ${offer.school?.name}`}
			</p>

			<div className="no-print">
				<ButtonQuaternary
					icon={arrowBackOutline}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</div>

			<div className="flex flex-col items-center wg-no-translate">
				<Avatar
					firstname={offer?.displayCompanyName.split(" ")[0]}
					lastname={offer?.displayCompanyName.split(" ")[1]}
					image=""
					size="lg"
					className="no-print"
				/>
				<p className="wg-no-translate mt-sm text-sm font-semibold text-primary-700P">
					{offer?.job}
				</p>
				<div
					className={`${
						position === "large" ? "md:flex-row-reverse" : "md:flex-col"
					} my-xsm flex flex-col items-center gap-xsm`}
				>
					<Badge
						label={getContractType(offer?.contract).label}
						bgColor={
							getContractType(offer?.contract).color as BackgroundColorType
						}
					/>

					<div className="flex items-center">
						<InlineIcon
							icon={globe2Outline}
							className="mr-xxsm no-print"
							height={20}
							width={20}
						/>
						<p className="flex items-center">{offer?.displayCompanyName}</p>
					</div>
					<div className="flex items-center">
						<InlineIcon
							icon={pinOutline}
							className="mr-xxsm"
							height={20}
							width={20}
						/>
						<p className="flex items-center">{offer?.location}</p>
					</div>
				</div>
			</div>
			{currentInterface === "student" && (
				<>
					{!showMinifyHead ? (
						<PostulateButton
							className={`${
								!isMobile
									? "md:absolute md:right-4 md:top-4"
									: "flex justify-center"
							} no-print`}
						/>
					) : (
						<PostulateButton className="no-print" />
					)}
				</>
			)}
		</div>
	);
}
