import type { CityType } from "@/types/LocationTypes";
import useDebounce from "@hooks/useDebounce";
import useGeocodePlacePredictions from "@hooks/useGeocodePlacePredictions";
import React, { type ChangeEvent } from "react";
import { useEffect, useState } from "react";

import { pinOutline } from "@assets/Icons";

import { findCities } from "@tools/API";

import { InputLargePrimary } from "@components/molecules/InputLargePrimary";

const MIN_LOCATION_LENGTH = 3;

type SelectLocationProps = {
	defaultValue?: CityType | null;
	errorMessage?: string;
	icon?: any;
	handleNewLocation?: Function;
	withLabel?: boolean;
	label?: string;
	placeholder?: string;
	className?: string;
	autoClear?: boolean;
};

const makeCityArray = (cities: { [key: string]: any }[]) => {
	if (!cities) return [];
	const cityArray: CityType[] = [];
	for (const city of cities) {
		if (!city?.properties || !city?.geometry) continue;
		const cityObject: CityType = {
			location: city.properties?.label,
			longitude: city.geometry?.coordinates[0],
			latitude: city.geometry?.coordinates[1],
			zipCode: city.properties?.postcode,
		};
		cityArray.push(cityObject);
	}
	return cityArray;
};

export default function SelectLocation({
	errorMessage,
	icon = pinOutline,
	handleNewLocation,
	defaultValue,
	withLabel = true,
	label = "Localisation*",
	placeholder = "Lieu",
	autoClear = true,
	className,
}: SelectLocationProps) {
	const [inputValue, setInputValue] = useState<string>("");
	const [locationToSearch, setLocationToSearch] = useState<string>("");
	const [cities, setCities] = useState<CityType[]>([]);
	const { geocodeByPlacePredictions, geocodeByLocality } =
		useGeocodePlacePredictions();

	const [selectedCity, setSelectedCity] = useState<CityType | null>(null);
	const [openSelection, setOpenSelection] = useState<boolean>(false);
	const [inputValueHasChanged, setInputValueHasChanged] =
		useState<boolean>(false);

	const debouncedValue = useDebounce(inputValue, 500);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setInputValueHasChanged(true);
		event.target.value = event.target.value.replace(/[+&#;]/g, "");
		if (event.target.value.length <= 1) {
			event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
		}

		setInputValue(event.target.value);
	};

	const handleSelection = async (citySelected: CityType | null) => {
		let citySelectedFormatted = citySelected;
		if (
			citySelected?.location &&
			citySelectedFormatted &&
			!citySelectedFormatted.longitude &&
			!citySelectedFormatted.latitude
		) {
			citySelectedFormatted = await geocodeByLocality(citySelected.location);
		}

		if (citySelectedFormatted) {
			setInputValue(`${citySelectedFormatted.location}`);
			setSelectedCity(citySelectedFormatted);
			setOpenSelection(false);
		}

		if (!handleNewLocation || !citySelectedFormatted) return;
		handleNewLocation(citySelectedFormatted);
	};

	const getCities = async (locationToFind: string) => {
		if (!locationToFind || locationToFind.length < MIN_LOCATION_LENGTH) return;
		const citiesFound = await findCities(locationToFind);
		const citiesFoundFilteredByScore = citiesFound?.filter(
			(city: { properties: { score: number } }) => city.properties.score > 0.5,
		);
		if (!citiesFoundFilteredByScore?.length) {
			geocodeByPlacePredictions(locationToFind).then((result) => {
				setCities(
					result.map((prediction) => {
						return { location: prediction.description };
					}),
				);
			});
		} else {
			setCities(makeCityArray(citiesFoundFilteredByScore));
		}

		setOpenSelection(true);
	};

	const resetInput = () => {
		setInputValue("");
		setSelectedCity(null);
		if (!handleNewLocation) return;
		handleNewLocation(null);
	};

	useEffect(() => {
		setInputValue(`${defaultValue ? defaultValue.location : ""}`);
	}, [defaultValue]);

	useEffect(() => {
		setLocationToSearch(debouncedValue);
	}, [debouncedValue]);

	useEffect(() => {
		if (locationToSearch === "") {
			setOpenSelection(false);
			if (autoClear) resetInput();
		}
		if (!locationToSearch || locationToSearch === `${selectedCity?.location}`) {
			return;
		}

		if (inputValueHasChanged) {
			getCities(locationToSearch);
		}
	}, [locationToSearch]);

	return (
		<aside data-id="localisation" className={`${className} relative`}>
			<InputLargePrimary
				icon={icon}
				label={withLabel ? label : null}
				placeholder={placeholder}
				value={inputValue || ""}
				onChange={(event) => {
					handleChange(event);
					if (event.target.value === "") {
						handleSelection(null);
					}
				}}
				errorMessage={errorMessage}
				onFocus={() => setOpenSelection(false)}
			/>
			{cities.length > 0 && openSelection ? (
				<SelectionOptionsContainer
					cities={cities}
					selectionFunction={handleSelection}
				/>
			) : null}
		</aside>
	);
}

function SelectionOptionsContainer({
	cities,
	selectionFunction,
}: {
	cities: CityType[];
	selectionFunction: Function;
}) {
	return (
		<div className="selection-options-container top-100 absolute right-0 z-10 my-2.5 grid max-h-[200px] w-full overflow-y-auto rounded-sm bg-white p-2 shadow-md">
			{cities.map((city: CityType, index) => (
				<SelectionLocationOption
					key={index}
					city={city}
					selectionFunction={selectionFunction}
				/>
			))}
		</div>
	);
}

function SelectionLocationOption({
	city,
	selectionFunction,
}: {
	city: CityType;
	selectionFunction: Function;
}) {
	return (
		<div
			key={city.location + city.zipCode}
			className="flex w-full cursor-pointer items-center justify-between rounded-sm px-sm py-xsm hover:bg-primary-50"
			onClick={() => selectionFunction(city)}
		>
			<p className="text-p text-primary-700P">
				{city.location} {city.zipCode ? `(${city.zipCode})` : ""}
			</p>
		</div>
	);
}
