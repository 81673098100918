import type { GoalType } from "@/types/goalsTypes";
import { useGoals } from "@hooks/useGoals";
import { Icon } from "@iconify/react";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { checkmarkCircle2Fill, clockOutline, refreshFill } from "@assets/Icons";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import TooltipContainer from "@components/atoms/TooltipContainer";
import { Input } from "@components/molecules/Input";
import { Collapse } from "@components/organisms/Collapse";
import { DatePicker } from "@components/organisms/DatePicker";
import { Success } from "@components/organisms/toast/ToastNotification";

import { createGoal, editGoal } from "../../StudentDetailGoalsAPI";
export function GoalsFormSkeleton() {
	return (
		<>
			<div className="mb-sm w-full">
				<Skeleton className="mt-sm" height={36} />
			</div>
			<div className="mb-sm w-full">
				<Skeleton className="mt-sm" height={36} />
			</div>
			<div className="mb-sm w-full">
				<Skeleton className="mt-sm" height={36} />
			</div>
		</>
	);
}

export default function Goals({ studentId }: { studentId: string }) {
	const emptyGoals = [
		{
			id: null,
			completionDate: null,
			deadline: null,
			isCompleted: false,
			studentComment: null,
			title: "",
			isArchived: false,
		},
		{
			id: null,
			completionDate: null,
			deadline: null,
			isCompleted: false,
			studentComment: null,
			title: "",
			isArchived: false,
		},
		{
			id: null,
			completionDate: null,
			deadline: null,
			isCompleted: false,
			studentComment: null,
			title: "",
			isArchived: false,
		},
	];
	const { goals, loading } = useGoals(studentId, emptyGoals);
	const [inputsDisabled, setInputsDisabled] = useState(true);
	const [reset, setReset] = useState(false);
	const [loader, setLoader] = useState(false);

	const createGoals = async (
		values: {
			date: Date;
			goalOne: GoalType;
			goalTwo: GoalType;
			goalThree: GoalType;
		},
		setFieldValue: (
			field: "goalOne" | "goalTwo" | "goalThree",
			value: GoalType,
		) => void,
	) => {
		const dataGoals = [
			...[values.goalOne, values.goalTwo, values.goalThree].map(
				(goal: GoalType) => {
					goal.id = undefined;
					return {
						...goal,
						deadline: values?.date.toISOString() || new Date().toISOString(),
					};
				},
			),
		];
		setLoader(true);
		const result: { goal: GoalType }[] | undefined[] = [];
		for (const goal of dataGoals) {
			const index = dataGoals.indexOf(goal);
			result[index] = goal.title
				? await createGoal({
						spsStudent: studentId,
						...goal,
					})
				: undefined;
		}
		typeof result[0] === "object" && setFieldValue("goalOne", result[0].goal);
		typeof result[1] === "object" && setFieldValue("goalTwo", result[1].goal);
		typeof result[2] === "object" && setFieldValue("goalThree", result[2].goal);
	};

	const resetGoals = (currentGoals: GoalType[]) => {
		const dataGoals = [
			...currentGoals.map((goal: GoalType) => {
				return {
					...goal,
					isArchived: true,
				};
			}),
		];
		setLoader(true);
		return Promise.all(dataGoals.map((goal) => goal.title && editGoal(goal)));
	};

	const getPlaceholder = (index: number) => {
		switch (index) {
			case 0:
				return "Ex: Envoyer 10 candidatures";
			case 1:
				return "Ex: Ajouter 1 contact par candidature";
			case 2:
				return "Ex: Lire 3 ressources CV";
			default:
				return null;
		}
	};

	useEffect(() => {
		setReset(goals.filter((goal: GoalType) => goal.deadline).length > 0);
	}, [goals, loading]);

	return (
		<div className="mb-lg mt-lg pl-md pr-md">
			<p className="color-primary700P mb-md">
				Les objectifs apparaîtront sur le tableau de bord de l&apos;étudiant.
			</p>
			{!loading ? (
				<Formik
					initialValues={{
						goalOne: goals.length > 0 ? goals[0] : emptyGoals[0],
						goalTwo: goals.length > 1 ? goals[1] : emptyGoals[1],
						goalThree: goals.length > 2 ? goals[2] : emptyGoals[2],
						date: goals.find((goal: GoalType) => goal?.deadline)?.deadline
							? new Date(
									goals.find((goal: GoalType) => goal?.deadline)?.deadline,
								)
							: new Date(),
					}}
					onSubmit={() => {}}
					validate={(values) => {
						const goalNotEmpty = [
							values.goalOne,
							values.goalTwo,
							values.goalThree,
						].filter((goal: GoalType) => goal.title?.length > 0).length;

						if (values.date !== undefined && goalNotEmpty > 0 && !reset) {
							setInputsDisabled(false);
						}
					}}
				>
					{({ values, handleChange, setFieldValue }) => (
						<Form>
							{loader ? (
								<GoalsFormSkeleton />
							) : (
								<>
									{((reset && values.goalOne.title.length > 0) || !reset) && (
										<div className="mb-sm flex flex-col">
											<div className="flex">
												{reset && (
													<div className="brLg mr-xsm flex items-start justify-start">
														<span id="gool1-tooltip">
															<TooltipContainer
																anchorId="#gool1-tooltip"
																place="top-end"
															>
																{values.goalOne.studentComment
																	? "L’objectif a été rempli par l’étudiant."
																	: "L’objectif n’a pas encore été rempli par l’étudiant."}
															</TooltipContainer>
															<Icon
																icon={
																	values.goalOne.studentComment
																		? checkmarkCircle2Fill
																		: clockOutline
																}
																className={`${
																	values.goalOne.studentComment
																		? "green"
																		: "orange"
																} icon`}
															/>
														</span>
													</div>
												)}
												<Input
													label="Objectif 1"
													placeholder={getPlaceholder(0)}
													className="mb-sm w-full"
													name={"goalOne.title"}
													onChange={handleChange}
													value={values.goalOne.title}
													disabled={reset}
												/>
											</div>

											{values.goalOne.studentComment && (
												<Collapse
													className="mt-sm"
													title="L’étudiant vous a laissé un commentaire."
													index={1}
													body={
														<p className="ml-sm">
															{values.goalOne.studentComment}
														</p>
													}
												/>
											)}
										</div>
									)}
									{((reset && values.goalTwo.title.length > 0) || !reset) && (
										<div className="mb-sm flex flex-col">
											<div className="flex">
												{reset && (
													<div className="brLg mr-xsm flex items-start justify-start">
														<span id="gool2-tooltip">
															<TooltipContainer
																anchorId="#gool2-tooltip"
																place="top-end"
															>
																{values.goalOne.studentComment
																	? "L’objectif a été rempli par l’étudiant."
																	: "L’objectif n’a pas encore été rempli par l’étudiant."}
															</TooltipContainer>
															<Icon
																icon={
																	values.goalTwo?.isCompleted
																		? checkmarkCircle2Fill
																		: clockOutline
																}
																className={`${
																	values.goalTwo?.isCompleted
																		? "green"
																		: "orange"
																} icon`}
															/>
														</span>
													</div>
												)}
												<Input
													label="Objectif 2"
													placeholder={getPlaceholder(1)}
													className="mb-sm w-full"
													name={"goalTwo.title"}
													onChange={handleChange}
													value={values.goalTwo.title}
													disabled={reset}
												/>
											</div>

											{values.goalTwo.studentComment && (
												<Collapse
													className="mt-sm"
													title="L’étudiant vous a laissé un commentaire."
													index={1}
													body={
														<p className="ml-sm">
															{values.goalTwo.studentComment}
														</p>
													}
												/>
											)}
										</div>
									)}
									{((reset && values.goalThree.title.length > 0) || !reset) && (
										<div className="mb-sm flex flex-col">
											<div className="flex">
												{reset && (
													<div className="brLg mr-xsm flex items-start justify-start">
														<span id="gool3-tooltip">
															<TooltipContainer
																anchorId="#gool3-tooltip"
																place="top-end"
															>
																{values.goalOne.studentComment
																	? "L’objectif a été rempli par l’étudiant."
																	: "L’objectif n’a pas encore été rempli par l’étudiant."}
															</TooltipContainer>
															<Icon
																icon={
																	values.goalThree.isCompleted
																		? checkmarkCircle2Fill
																		: clockOutline
																}
																className={`${
																	values.goalThree.isCompleted
																		? "green"
																		: "orange"
																} icon`}
															/>
														</span>
													</div>
												)}
												<Input
													label="Objectif 3"
													placeholder={getPlaceholder(2) || ""}
													className="mb-sm w-full"
													name={"goalThree.title"}
													onChange={handleChange}
													value={values.goalThree.title}
													disabled={reset}
												/>
											</div>

											{values.goalThree.studentComment && (
												<Collapse
													className="mt-sm"
													title="L’étudiant vous a laissé un commentaire."
													index={1}
													body={
														<p className="ml-sm">
															{values.goalThree.studentComment}
														</p>
													}
												/>
											)}
										</div>
									)}
								</>
							)}

							<hr />
							<div className="dataPicker">
								<DatePicker
									className="mb-md"
									label="Date limite pour remplir les objectifs"
									withIcon
									selectedDate={values.date}
									onDateChange={(date) => {
										setFieldValue("date", date);
									}}
									disabled={inputsDisabled}
								/>
							</div>

							{!reset ? (
								<div className="flex justify-end">
									<ButtonPrimary
										label="Envoyer"
										className="w-fit-content add-responsive"
										onClick={() => {
											createGoals(values, setFieldValue).finally(() => {
												Success("Les objectifs ont été créés avec succès.");
												setReset(true);
												setInputsDisabled(true);
												setLoader(false);
											});
										}}
										disabled={inputsDisabled}
									/>
								</div>
							) : (
								<div className="flex justify-start">
									<InlineButton
										id="reset-goals"
										type="reset"
										label="Réinitialiser les objectifs"
										icon={refreshFill}
										onClick={() => {
											resetGoals([
												values.goalOne,
												values.goalTwo,
												values.goalThree,
											]).finally(() => {
												setFieldValue("goalOne", emptyGoals[0]);
												setFieldValue("goalTwo", emptyGoals[1]);
												setFieldValue("goalThree", emptyGoals[2]);
												setFieldValue("date", new Date());
												setReset(false);
												setInputsDisabled(false);
												setLoader(false);
											});
										}}
									/>
								</div>
							)}
						</Form>
					)}
				</Formik>
			) : (
				<GoalsFormSkeleton />
			)}
		</div>
	);
}
