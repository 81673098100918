import type {
	EntCompanySchoolProfileType,
	EntOffersType,
} from "@/types/EntCompanyTypes";

import api, { checkGraphQLError } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function querySchoolInfo(school: string) {
	let rep = {};
	const data = {
		query: `query {
      school (id: "${school}") {
          id
          subdomain
          color
          name
      }
    }`,
	};
	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export async function queryMainData(school: number) {
	let rep = {};
	try {
		rep = await api.get(`get_partners_homepage_data/${school}`);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export async function getEployeeTokenFromReferent(): Promise<string> {
	try {
		const { data } = await api.get("/get_employee_token");
		return data.token;
	} catch (error) {
		throw new Error(error);
	}
}

export async function queryInvites(school: string) {
	const data = {
		query: `query {
    entInvitations(school: "${school}", display: true) {
      collection {
        id
        status
        lastSent
        email
      }
    }
  }`,
	};
	try {
		const rep = await api.post("graphql", data);
		return rep.data.data.entInvitations.collection;
	} catch (error) {
		throw new Error(error);
	}
}

export type InviteCreateDataType = {
	body: string;
	subject: string;
	receiver: string;
};
export type InviteResendDataType = {
	invitationId: number;
};

export async function createInvites(
	dataArray: InviteCreateDataType[] | InviteResendDataType[],
) {
	const invitesSent = [];
	for (const mail of dataArray) {
		try {
			const { data } = await api.post("send_invitation_mails", mail);
			if (data.invited) {
				invitesSent.push("sent");
			}
		} catch (e) {
			throw e.response?.data || e.message;
		}
	}
	return invitesSent;
}

export async function hideInvite(id: string) {
	const data = {
		query: `mutation {
            updateEntInvitation(input: { id: "${id}", display: false }) {
                entInvitation {
                  id
                  email
                  lastSent
                  school {
                    id
                    name
                  }
                }
            }
        }`,
	};
	try {
		const rep = await api.post("graphql", data);
		return rep;
	} catch (e) {
		throw new Error(e);
	}
}

export async function queryAccounts() {
	try {
		const { data } = await api.get("get_partners_profiles");
		return data.data;
	} catch (error) {
		throw new Error(error);
	}
}

export async function updateAccount(
	inputs: Partial<EntCompanySchoolProfileType>,
) {
	const data = {
		query: `mutation {
            updateEntCompanySchoolProfile(input: ${stringifyRequestInputs(
							inputs,
						)}) {
                entCompanySchoolProfile {
                  id
                  selectionCvtk
                  display
                }
            }
        }`,
	};
	try {
		const rep = await api.post("graphql", data);
		return rep;
	} catch (e) {
		throw new Error(e);
	}
}
export async function updateCompanyProfileCvtk(
	profileId: string,
	token: string,
) {
	const data = {
		query: `mutation {
            updateEntCompanySchoolProfile(input: {id: "${profileId}", selectionCvtk: "${token}"}) {
                entCompanySchoolProfile {
                  id
                  selectionCvtk
                }
            }
        }`,
	};
	try {
		const rep = await api.post("graphql", data);
		return rep;
	} catch (e) {
		Error("Une erreur est survenue");
		return e;
	}
}

export async function queryEntOffers(schoolId: number) {
	let offers;
	const data = {
		query: `query {
    entOffers(entCompanySchoolProfile_school_id:${schoolId}, order: [{createdAt: "DESC"}]) {
      collection {
        id
        job
        description
        location
        telework
        isPdf
        createdBy {
        	id
        	firstname
        	lastname
        }
        spsSelection {
          id
          token
        }
        pdf {
          id
          filePath
          size
        }
        entCompanySchoolProfile {
          id
          entCompany {
            id
            name
            crmCompany {
            	id
            	name
            }
          }
        }
        spsOffer {
          id
        }
        qualification
        contract
        status
        link
        emailPreference
        createdAt
      }
    }
  }`,
	};
	try {
		const rep = await api.post("graphql", data);
		offers = rep.data.data.entOffers.collection;
		return offers;
	} catch {
		Error("Une erreur est survenue");
	}
}

export async function updateEntOffer(inputs: Partial<EntOffersType>) {
	const data = {
		query: `mutation {
            updateEntOffer(input: ${stringifyRequestInputs(inputs)}) {
                entOffer {
                  id
                }
            }
        }`,
	};
	try {
		const rep = await api.post("graphql", data);
		return rep;
	} catch (e) {
		throw new Error(e);
	}
}

export async function createSelection(inputs: string, profileId?: string) {
	let rep: any = {};
	let token: string;
	const data = {
		query: `mutation {
            createSelection(input: ${inputs}) {
                selection {
                    id
                    name
                    token
                    crmCompany {
                   		name
                   	}
                    selectionUsers {
                      collection {
                        user: spsStudent {
                          id
                          firstname
                        }
                      }
                    }
                }
            }
        }`,
	};
	try {
		rep = await api.post("graphql", data);
		rep = rep.data.data.createSelection.selection;
		if (profileId) {
			token = rep.token;
			try {
				const profileRep = await updateCompanyProfileCvtk(profileId, token);
				return profileRep.data.data.updateEntCompanySchoolProfile
					.entCompanySchoolProfile;
			} catch (_e) {
				Error("Une erreur est survenue");
			}
		}
	} catch (_e) {
		Error("Une erreur est survenue");
	}
	return rep;
}
