import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { emailOutline, eyeOutline } from "@assets/Icons";

import { extractIdNumberFromId } from "@tools/Users";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import TooltipContainer from "@components/atoms/TooltipContainer";
import TooltipElement from "@components/atoms/TooltipElement";
import { InputCopy } from "@components/molecules/InputCopy";

import { setSendRelaunchInvitationWasClicked } from "@containers/school/Offers_v3/core/store/offersManagement.slice";

import { ReferentModifier } from "@containers/school/Offers_v3/ui/actions/ReferentModifier";

import CampusModifier from "../../../actions/CampusModifier";
import StatusModifier from "../../../actions/StatusModifier";
import VisibilityModifier from "../../../actions/VisibilityModifier";

export function OfferDetailsShareStudentPannel() {
	const dispatchEvent = useDispatch();
	const { offerToEdit } = useAppSelector((state) => state.offers);
	const handleClick = () => {
		dispatchEvent(setSendRelaunchInvitationWasClicked(true));
	};
	return (
		<section className="flex flex-col gap-sm pb-md">
			<div className="flex w-full items-center justify-start gap-xxsm">
				<p className="text-sm font-bold text-primary-700P">
					Partage aux étudiants
				</p>
				<TooltipElement id="shareToStudentPannelTooltip" />
				<TooltipContainer
					anchorId="#shareToStudentPannelTooltip"
					children="Partagez cette offre à vos étudiants."
					place="top"
				/>
			</div>
			<ButtonPrimary
				icon={emailOutline}
				iconPlacement="left"
				label="Inviter des étudiants à candidater"
				onClick={handleClick}
			/>
			<InputCopy
				URI={`/jobboard/${
					offerToEdit && extractIdNumberFromId(offerToEdit?.id)
				}`}
				label="Lien de l’offre pour les étudiants"
			/>
			<InlineButton
				id="previewOffer"
				label="Prévisualiser l’offre en tant qu’étudiant"
				icon={eyeOutline}
				iconPosition="left"
				iconSize="sm"
				onClick={() => {
					window.open(
						`/school-jobboard/${
							offerToEdit && extractIdNumberFromId(offerToEdit?.id)
						}`,
						"_blank",
					);
				}}
			/>
			<StatusModifier useAutosave={true} />
			<CampusModifier useAutosave={true} />
			<VisibilityModifier useAutosave={true} />
			<ReferentModifier useAutosave={true} />
		</section>
	);
}
