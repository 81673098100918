import type { ApplicationType } from "@/types/applicationTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { useAppSelector } from "@config/redux/hook";
import { InlineIcon } from "@iconify/react";
import { Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { globe2Outline, pinOutline, refreshOutline } from "@assets/Icons";

import { getTextError } from "@tools/Errors";
import { getContractType } from "@tools/Offers";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { InlineButton } from "@components/atoms/InlineButton";
import { FileInput } from "@components/molecules/FileInput";
import { FilesPrimary } from "@components/molecules/Files";
import { Modal } from "@components/organisms/modal/Modal";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import {
	setApplication,
	setIsSendingApplication,
} from "@containers/student/Jobboard/controller/offerDetail.slice";

import { getCookie } from "@tools/Cookies";

import { addUserApplication } from "@containers/student/Jobboard/controller/jobboard.slice";
import {
	editApplication,
	importOffer,
	sendFile,
} from "@containers/student/Jobboard/controller/offerDetail.api";
import { extractError } from "@tools/API";
import { invariant } from "@tools/Invariant";
export function PostulateModal({
	show,
	onClose,
}: {
	show: boolean;
	onClose: Function;
}) {
	const dispatchOfferDetail = useDispatch();
	const { offer, defaultCv } = useAppSelector((state) => state.offerDetail);

	const [cv, setCv] = useState<MediaObjectType | null>();
	const [motivationLetter, setMotivationLetter] =
		useState<MediaObjectType | null>();
	const [errorCv, setErrorCv] = useState("");
	const [errorMotivationLetter, setErrorMotivationLetter] = useState("");
	const [loadingCv, setLoadingCv] = useState(false);
	const [loadingMotivationLetter, setLoadingMotivationLetter] = useState(false);
	const [useDefaultCv, setUseDefaultCv] = useState(
		defaultCv?.filePath !== undefined,
	);

	const updateApplication = async (applicationId: string, inputs: object) => {
		const response = await editApplication({
			id: applicationId,
			...inputs,
		});
		if (response) {
			invariant("id" in response, "No id in response");
			dispatchOfferDetail(setApplication(response));
			Success("Candidature envoyée");
		} else {
			ErrorToast("Une erreur est survenue");
		}
	};

	const createApplication = async (inputs: {
		documents: (any | string)[];
		step: string;
	}) => {
		try {
			const { applicationId }: any = await importOffer(
				offer.id.split("/")[3],
				inputs,
			);
			dispatchOfferDetail(
				setApplication({
					id: `/${getCookie("interface")}/applications/${applicationId}`,
				}),
			);
			dispatchOfferDetail(addUserApplication({ offer: { id: offer.id } }));
			Success("Candidature envoyée");
		} catch (e) {
			ErrorToast(extractError(e).message);
		}
	};

	const postulate = async () => {
		dispatchOfferDetail(setIsSendingApplication(true));
		const documents = [];

		if (useDefaultCv && defaultCv) {
			documents.push(defaultCv.id);
		} else if (cv) {
			documents.push(cv["@id"]);
		}

		if (motivationLetter) {
			documents.push(motivationLetter["@id"]);
		}
		const inputs: { documents: (any | string)[]; step: string } = {
			documents,
			step: "sent",
		};

		const application =
			offer?.applications?.collection?.filter(
				(application: ApplicationType) => {
					return application.step === "readyToApply";
				},
			)[0] ?? undefined;

		if (application) {
			await updateApplication(application.id, inputs);
		} else {
			await createApplication(inputs);
		}
		dispatchOfferDetail(setIsSendingApplication(false));
	};

	const uploadFile = async (file: string | Blob, fileName: string) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append(
			"type",
			fileName === "motivationLetter" ? "motivation" : "cv",
		);
		if (fileName === "motivationLetter") setLoadingMotivationLetter(true);
		if (fileName === "cv") setLoadingCv(true);
		await sendFile(formData)
			.then((media: MediaObjectType) => {
				if (fileName === "cv") setCv(media);
				if (fileName === "motivationLetter") setMotivationLetter(media);
			})
			.catch((error) => {
				if (fileName === "cv") setErrorCv(getTextError(error.response.status));
				if (fileName === "motivationLetter") {
					setErrorMotivationLetter(getTextError(error.response.status));
				}
			});
		if (fileName === "cv") setLoadingCv(false);
		if (fileName === "motivationLetter") setLoadingMotivationLetter(false);
	};

	return (
		<Formik
			initialValues={{
				cv,
				motivationLetter,
			}}
			enableReinitialize
			validate={(values) => {
				const errors: any = {};

				if ((!cv || !values.cv) && !useDefaultCv) {
					errors.cv = "Veuillez ajouter votre CV";
				}

				if (!motivationLetter && offer.motivationLetterRequired) {
					errors.motivationLetter =
						"Veuillez ajouter votre écrit de motivation";
				}
				return errors;
			}}
			onSubmit={() => {
				postulate();
				const body = document.getElementsByTagName("body")[0];
				if (body) {
					body.classList.remove("overflow-hidden");
				}
				onClose();
			}}
		>
			{({ errors, handleSubmit, resetForm }) => (
				<Modal
					show={show}
					onClose={() => {
						onClose();
						resetForm();
					}}
					title="Postuler"
					body={
						<div>
							<div>
								<div className="flex flex-col items-center">
									<Avatar
										className="wg-no-translate"
										image=""
										firstname={offer.displayCompanyName?.split(" ")[0]}
										lastname={offer.displayCompanyName?.split(" ")[1]}
										size="sm"
									/>

									<div className="flex flex-col justify-between pb-xxsm pt-xxsm">
										<p className="wg-no-translate text-center">{offer.job}</p>
										<div className="mt-xxsm flex flex-col-reverse flex-wrap items-center justify-center gap-xxsm md:flex-row md:gap-md">
											<p className="flex items-center text-primary-300">
												<InlineIcon
													icon={globe2Outline}
													className="mr-xxsm h-4 text-primary-300 wg-no-translate"
												/>
												{offer?.displayCompanyName}
											</p>
											<p className="flex items-center text-primary-300">
												<InlineIcon
													icon={pinOutline}
													className="mr-xxsm h-4 text-primary-300"
												/>
												{offer.location}
											</p>

											{offer.contract && (
												<Badge
													className="no-event"
													label={getContractType(offer?.contract).label}
													bgColor={getContractType(offer?.contract).color}
												/>
											)}
										</div>
									</div>
								</div>
								<p className="mt-sm text-xsm text-primary-700P">
									Ceci étant une offre exclusive partagée par ton école, ta
									candidature sera envoyée directement à ton école. Cette
									dernière se chargera de traiter ta candidature et de la
									transmettre (ou non) à l'entreprise.
								</p>
							</div>
							<div className="form-app mt-md">
								<div className="mt-md flex flex-col flex-wrap justify-between  md:flex-row md:flex-nowrap">
									<div className={`${cv?.filePath && "doc"} w-full md:w-6/12`}>
										<p className="font-semifont-bold mb-xsm text-xsm text-primary-700P">
											CV
										</p>
										{useDefaultCv && defaultCv ? (
											<div>
												<p className="text-xxxsm text-primary-300">
													Le CV de ton profil est importé par défaut.
												</p>

												<FilesPrimary
													isLoading={loadingCv}
													title={defaultCv?.filePath}
													subtitle={`${Math.floor(defaultCv?.size / 1000)} Ko`}
													className="my-3 max-w-[75%] p-0"
													onDownload={() => {
														window.open(
															`${import.meta.env.VITE_S3}${
																defaultCv?.filePath
															}`,
														);
													}}
												/>
												<InlineButton
													id="use-default-cv"
													label="Remplacer par un CV personnalisé"
													icon={refreshOutline}
													onClick={() => {
														setUseDefaultCv(false);
													}}
												/>
											</div>
										) : cv?.filePath ? (
											<FilesPrimary
												isLoading={loadingCv}
												title={cv.filePath}
												subtitle={`${Math.floor(cv.size / 1000)} Ko`}
												className="my-3 max-w-[75%]"
												onDelete={() => {
													setCv(null);
												}}
												onDownload={() => {
													window.open(
														`${import.meta.env.VITE_S3}${cv.filePath}`,
													);
												}}
											/>
										) : (
											<div>
												<FileInput
													isLoading={loadingCv}
													onSuccess={(files: Array<string | Blob>) => {
														uploadFile(files[0], "cv");
														setErrorCv("");
													}}
													maxSize={1}
													acceptFormats={{ "application/pdf": [] }}
													onError={() => {
														setErrorCv(
															"Le fichier envoyé dépasse la limite de 1Mo ou n'est pas au format pdf",
														);
													}}
													id="cv"
												/>
											</div>
										)}
										{errorCv && <p className="text-error-dark">{errorCv}</p>}
									</div>

									<div
										className={`${
											motivationLetter?.filePath && "doc"
										} w-full md:w-6/12`}
									>
										<p className="font-semifont-bold mb-xsm text-xsm text-primary-700P">
											Écrit de motivation{" "}
											<span className="text-xsm text-primary-200">
												{offer.motivationLetterRequired
													? "(obligatoire)"
													: "(optionnel)"}
											</span>
										</p>
										{motivationLetter?.filePath ? (
											<FilesPrimary
												isLoading={loadingMotivationLetter}
												title={motivationLetter.filePath}
												subtitle={`${Math.floor(
													motivationLetter.size / 1000,
												)} Ko`}
												className="my-3 max-w-[75%]"
												onDelete={() => {
													setMotivationLetter(null);
												}}
												onDownload={() => {
													window.open(
														`${import.meta.env.VITE_S3}${
															motivationLetter.filePath
														}`,
													);
												}}
											/>
										) : (
											<div>
												<FileInput
													isLoading={loadingMotivationLetter}
													onSuccess={(files: Array<string | Blob>) => {
														uploadFile(files[0], "motivationLetter");
														setErrorMotivationLetter("");
													}}
													maxSize={1}
													acceptFormats={{ "application/pdf": [] }}
													onError={() => {
														setErrorMotivationLetter(
															"Le fichier envoyé dépasse la limite de 1Mo ou n'est pas au format pdf",
														);
													}}
													id="letter"
												/>
											</div>
										)}
									</div>
								</div>
								<div className="mt-sm flex flex-col">
									{errorMotivationLetter && (
										<p className="text-error-dark">{errorMotivationLetter}</p>
									)}
									{errors.cv && <p className="text-error-dark">{errors.cv}</p>}
									{errors.motivationLetter && (
										<p className="text-error-dark">{errors.motivationLetter}</p>
									)}
								</div>
							</div>
						</div>
					}
					buttonsRight={[
						<ButtonSecondary
							key="cancelBTN"
							onClick={() => {
								resetForm();
								onClose();
							}}
							label="Annuler"
						/>,
						<ButtonPrimary
							key="confirmBTN"
							onClick={() => {
								handleSubmit();
							}}
							label={"Envoyer"}
						/>,
					]}
				/>
			)}
		</Formik>
	);
}
