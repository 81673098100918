import { useEffect, useState } from "react";

export const useBreakpoints = (breakpoint?: number) => {
	const [width, setWidth] = useState(375);
	const MOBILE_BREAKPOINT = breakpoint || 799;
	useEffect(() => {
		setWidth(window.innerWidth);
		window.addEventListener("resize", () => setWidth(window.innerWidth));
		return () => {
			setWidth(375);
			window.removeEventListener("resize", () => setWidth(window.innerWidth));
		};
	}, []);

	return { isMobile: width <= MOBILE_BREAKPOINT };
};
