import React from "react";
import { useNavigate } from "react-router";

import NoAccessIllustration from "@assets/illustrations/403.svg";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";

export function ErrorNoAccess() {
	const navigate = useNavigate();
	return (
		<main className="errors">
			<div className="main-content flex flex-col items-center justify-center">
				<img alt="Une femme cherche une direction" src={NoAccessIllustration} />
				<h2 className="mt-md text-lg font-bold text-primary-900 md:text-3xl">
					Pas d'acces
				</h2>
				<p className="mt-md text-center text-xxsm text-primary-300 md:text-xsm">
					Vous n'avez pas les permissions pour accéder à cette page.
				</p>
				<ButtonPrimary
					className="mt-8 md:mt-6"
					onClick={() => navigate("/")}
					label={`Retourner à la page d'accueil`}
				/>
			</div>
		</main>
	);
}
