import axios from "axios";

// Paramètre school utilisé pour l'espace entreprise uniquement
export async function mailChangePassword(email: string, schoolId?: number) {
	let rep = {};
	const url = schoolId
		? `reset_password/${email}?schoolId=${schoolId}`
		: `reset_password/${email}`;
	try {
		const { data } = await axios
			.create({
				baseURL: `${import.meta.env.VITE_BACK}/`,
				timeout: 20000,
			})
			.post(url);
		rep = data;
		return rep;
	} catch (e) {
		throw new Error(e);
	}
}
