import { GlobalContext } from "@navigation/Router";
import { Formik } from "formik";
import React, { useContext } from "react";

import "../../Promotions.scss";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Input } from "@components/molecules/Input";
import { Modal } from "@components/organisms/modal/Modal";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

export function EditorPromotionModal({
	show,
	title,
	promotion,
	onClose,
	onApply,
	campuses,
}) {
	const { user } = useContext(GlobalContext);
	const promotionOptions = [
		{ label: "Active", value: "active" },
		{ label: "Archivée", value: "archived" },
	];

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={{
					id: promotion?.id,
					name: promotion?.name,
					archived: promotion?.archived,
					selectedCampus: promotion?.campus
						? campuses?.filter((x) => x.label === promotion.campus.name)[0]
						: campuses?.filter((x) => x.label === user.campuses[0].name)[0],
				}}
				validate={(values) => {
					const errors = {};

					if (!values.name) {
						errors.name = "Veuillez entrer le nom de la promotion";
					}
					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					onApply(values);
					resetForm({});
					onClose();
				}}
			>
				{({ values, errors, handleSubmit, handleChange, setFieldValue }) => (
					<Modal
						scroll={false}
						onClose={() => {
							onClose();
						}}
						show={show}
						title={title}
						className="modify-promotion-container"
						body={
							<div>
								<Input
									label={
										promotion ? "Modifier la promotion" : "Nom de la promotion"
									}
									placeholder={!promotion && "Ex : Bachelor Développement Web"}
									value={values.name}
									name="name"
									onChange={handleChange}
									errorMessage={errors.name}
								/>
								{(user.roles.includes("ROLE_SCHOOL") ||
									user.roles.includes("ROLE_CAMPUS_ADMIN")) && (
									<SelectTertiary
										label="Campus de rattachement"
										className="mt-sm"
										defaultValue={values.selectedCampus}
										onChange={(e) => {
											setFieldValue("selectedCampus", e);
										}}
										options={campuses}
									/>
								)}
								{promotion && (
									<SelectTertiary
										position="right"
										label="Statut"
										defaultValue={promotionOptions.find(
											(option) =>
												option.value ===
												(values.archived ? "archived" : "active"),
										)}
										options={promotionOptions}
										onChange={(e) => {
											setFieldValue("archived", e.value === "archived");
										}}
										className="mt-md"
									/>
								)}
							</div>
						}
						buttonsRight={[
							<ButtonSecondary
								key={0}
								label="Annuler"
								onClick={() => {
									onClose();
								}}
							/>,
							<ButtonPrimary
								key={1}
								label="Valider"
								onClick={() => {
									handleSubmit();
								}}
							/>,
						]}
					/>
				)}
			</Formik>
		</div>
	);
}
