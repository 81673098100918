import useCompaniesReloadManagement from "@hooks/compagnies/useCompaniesReloadManagement";
import React from "react";
import { CompaniesListingHeader } from "./sections/CompaniesListingHeader";
import { CompaniesTable } from "./sections/CompaniesTable";
import { EmptyList } from "./sections/EmptyList";
import { useCompaniesBoard } from "./useCompaniesBoard";
export function CompaniesBoard() {
	useCompaniesReloadManagement();
	const presenter = useCompaniesBoard();

	return (
		<main className="" data-testid="companiesManagementView">
			<CompaniesListingHeader />
			{presenter.canShowTables || presenter.fetchingCompanies ? (
				<CompaniesTable />
			) : (
				<EmptyList />
			)}
		</main>
	);
}
