import { Icon, type IconifyIcon } from "@iconify/react";
import React from "react";

import "@assets/sass/Button.scss";
import type { MouseEventHandler } from "react";

type ButtonOctonaryPropsType = {
	className?: string;
	icon: string | IconifyIcon;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	disabled: boolean;
};

export function ButtonOctonary({
	className = "",
	icon,
	onClick,
	disabled = false,
}: ButtonOctonaryPropsType) {
	return (
		<button
			disabled={disabled}
			type="button"
			onClick={onClick}
			className={`button-octonary ${className}`}
		>
			<Icon icon={icon} />
		</button>
	);
}
