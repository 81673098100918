import type { StatusOptionType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { STUDENT_STATUS_OPTIONS } from "@constants/Users";

import { Select } from "@components/organisms/select/Select";

import { countCurrentFilters, setCurrentFilters } from "../usersFiltersSlice";

export function StatusFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const getFormatedStatus = () => {
		const formattedStatusOptions: StatusOptionType[] =
			STUDENT_STATUS_OPTIONS.map((statusOption: StatusOptionType) => {
				return {
					...statusOption,
					checked:
						currentFilters.status_list?.includes(statusOption.label) || false,
					label: statusOption.label,
					value: statusOption.value,
					icon: statusOption.icon,
					type: "checkbox",
				};
			});

		return formattedStatusOptions;
	};

	const [status, setStatus] = useState<StatusOptionType[]>(getFormatedStatus());

	useEffect(() => {
		if (currentFilters.status_list?.length !== 0) {
			setStatus(getFormatedStatus());
		}
	}, [currentFilters]);

	const handleChange = (statusSelected: StatusOptionType[]) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				status_list: statusSelected.map((status) => status.value),
				page: 1,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<Select
			position="right"
			placeholder="Statut"
			inputs={status.map((statusItem) => ({
				id: statusItem.value,
				name: statusItem.label,
				value: statusItem.value,
				label: statusItem.label,
				type: "checkbox",
				checked:
					currentFilters.status_list?.includes(statusItem.value) || false,
			}))}
			onChange={(statusSelected: any) => {
				handleChange(statusSelected);
			}}
			multiselect
			breakSpaces
		/>
	);
}
