import type { CampusesCollectionType } from "@/types/campusTypes";
import type { FetchCompaniesListParamsType } from "@/types/companyRequest.types";
import API, { checkGraphQLError, graphqlRequestConstructor } from "@tools/API";
import { extractCampusList } from "@tools/Campuses";
import axios from "axios";
import type { CrmCompanyDomainModel } from "../model/crmCompany.domainModel";

const COMPANY_BASE_URL = "/api/crm_companies/";

export async function searchCompanyInClearbit(value = "") {
	try {
		const responseFromClearbit = await axios.get(
			`https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`,
		);
		return responseFromClearbit?.data;
	} catch (error) {
		return error;
	}
}

export async function fetchCreateCompany(
	companyData: CrmCompanyDomainModel.Company,
	schoolId: string,
) {
	try {
		const campusCollection: string[] = extractCampusList(
			companyData?.coreCampuses as unknown as CampusesCollectionType,
		);
		const queryToSend = {
			query: `mutation{
        createCrmCompany (input: {
					school: "${schoolId}", 
					name: "${companyData.name}", 
					relation: ${companyData.relation},
					spsReferent: "${companyData?.spsReferent?.id || null}",
					coreCampuses: [${campusCollection.map((campus) => `"${campus}"`)}],
					${companyData.logo ? `logo: "${companyData.logo}"` : ""},
					${companyData.siret ? `siret: "${companyData.siret}"` : ""},
					${companyData.address ? `address: "${companyData.address}",` : ""}
					${companyData.city ? `city: "${companyData.city}",` : ""}
					${
						companyData.postalCode
							? `postalCode: "${companyData.postalCode}",`
							: ""
					}
					${companyData.latitude ? `latitude: ${companyData.latitude},` : ""}
					${companyData.longitude ? `longitude: ${companyData.longitude},` : ""}
				}) {
          crmCompany{
              id
          }
  }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(response, "Erreur lors de la création de l'offre");
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function fetchGetCompanies(params: FetchCompaniesListParamsType) {
	const queryToSend = {
		query: `
    query {
      crmCompanies(
				itemsPerPage: ${params.itemsPerPage}, 
				page: ${params.page},
				order: ${params.order},
				${params.search ? `search: "${params.search}",` : ""}
				${params.schoolId ? `school: "${params.schoolId}",` : ""}
				${params.campuses ? `coreCampuses: "${params.campuses}",` : ""}
			){
          collection {
              id
              name
              logo
							relation
							city
							postalCode
							spsReferent {
								id
								firstname
								lastname
								avatar{
									filePath
								}
							}	
							offersCount
							studentsPlaced
							createdAt
          }
          paginationInfo {
                totalCount
                lastPage
          }
      }
   }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getCompanies",
		"Erreur lors de la récupération des entreprises",
	);
	return rep;
}

export async function fetchVerifyCompanyExistance(companyName: string) {
	const queryToSend = {
		query: `
		query {
			crmCompanies(itemsPerPage: 100, name: "${companyName}"){
					collection{
						id
						name
					}
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"verifyCompanyExistance",
		"Erreur lors de la vérification de l'existence de l'entreprise",
	);
	return rep;
}

export async function fetchVerifyCompanySiretExistance(siret: string) {
	const queryToSend = {
		query: `
		query {
			crmCompanies(itemsPerPage: 100, siret: "${siret}"){
					collection{
						id
						name
					}
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"verifyCompanySiretExistance",
		"Erreur lors de la vérification de l'existence du SIRET / SIREN",
	);
	return rep;
}

export async function fetchGetCompanyInformations(id: string) {
	const queryToSend = {
		query: `
		query {
			crmCompany(id: "${COMPANY_BASE_URL}${id}"){
					id
					name
					address
					city
					postalCode
					latitude
					longitude
					website
					logo
					siret
					mainSector
					structureSize
					foundedAt
					relation
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getCompanyInformations",
		"aborted",
	);
	return rep;
}

export async function fetchGetCompanyContact(id: string) {
	const queryToSend = {
		query: `
		query {
			crmCompany(id: "${COMPANY_BASE_URL}${id}"){
					id
					contactFirstname
					contactLastname
					contactEmail
					contactPhone
					contactJob
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getCompanyContact",
		"aborted",
	);
	return rep;
}

export async function fetchGetCompanyRelations(id: string) {
	const queryToSend = {
		query: `
		query {
			crmCompany(id: "${COMPANY_BASE_URL}${id}"){
					id
					relation
					spsReferent{
						id
						firstname
						lastname
						avatar{
							filePath
						}
					}
					coreCampuses{
						collection{
							id
							name
						}
					}
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getCompanyRelations",
		"aborted",
	);
	return rep;
}

export async function fetchGetCompanyOffers(id: string) {
	const queryToSend = {
		query: `
		query {
    crmCompany (id: "${COMPANY_BASE_URL}${id}") {
        id
        spsOffers{
            collection{
                id
                job
                location
                updateAt
                contract
								status
            }
        }
    	}
		}`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getCompanyOffers",
		"aborted",
	);
	return rep;
}

export async function fetchGetCompanymemo(id: string) {
	const queryToSend = {
		query: `
		query {
			crmCompany(id: "${COMPANY_BASE_URL}${id}"){
					id
					memo
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"getCompanyMemo",
		"aborted",
	);
	return rep;
}

export async function fetchUpdateCompanyInformations(
	companyId: string,
	informationsToUpdate: CrmCompanyDomainModel.CompanyValue[],
) {
	const queryToSend = {
		query: `
		mutation {
			updateCrmCompany(
				input: {
					id: "${COMPANY_BASE_URL}${companyId}",
					${informationsToUpdate
						.map(
							(info) =>
								`${info.key}: ${
									Array.isArray(info.value)
										? `[${info.value.map((item) => `"${item}"`).join(", ")}]`
										: typeof info.value === "string"
											? `"${info.value}"`
											: `${info.value}`
								},`,
						)
						.join("\n")}
				}
			){
					crmCompany{
						id
					}
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"updateCompanyInformations",
		"aborted",
		false,
	);
	return rep;
}

export async function fetchUpdateCompanyMemo(
	companyId: string,
	memoValue: string,
) {
	const queryToSend = {
		query: `
		mutation {
			updateCrmCompany(
				input: {
					id: "${COMPANY_BASE_URL}${companyId}",
					memo: ${memoValue}
				}
			){
					crmCompany{
						id
					}
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"updateCompanyInformations",
		"aborted",
	);
	return rep;
}

export async function fetchUpdateCompanyContact(
	companyId: string,
	contactToUpdate: CrmCompanyDomainModel.ContactValue[],
) {
	const queryToSend = {
		query: `
		mutation {
			updateCrmCompany(
				input: {
					id: "${COMPANY_BASE_URL}${companyId}",
					${contactToUpdate
						.map((info) => `${info.key}: "${info.value}",`)
						.join("\n")}
				}
			){
					crmCompany{
						id
					}
			}
	 }`,
	};
	const rep = graphqlRequestConstructor(
		queryToSend,
		"updateCompanyContact",
		"aborted",
		false,
	);
	return rep;
}
