import { useAppSelector } from "@config/redux/hook";
import useDebounce from "@hooks/useDebounce";
import React, { type ChangeEvent } from "react";
import { useEffect, useState } from "react";

import { pricetagsOutline } from "@assets/Icons";

import { RichTextEditor } from "@components/atoms/RichTextEditor";
import { Input } from "@components/molecules/Input";
import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";

export function EventInformations() {
	const targetEvent = useAppSelector((state) => state.events.targetEvent);
	const eventToCreate = useAppSelector((state) => state.events.eventToCreate);
	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;
	const [inputTitleValue, setInputTitleValue] = useState<string>(
		eventToCreate?.title || targetEvent?.title || "",
	);
	const debouncedTitleValue = useDebounce(inputTitleValue, 500);

	const handleTitleChange = (titleReceived: ChangeEvent<HTMLInputElement>) => {
		setInputTitleValue(titleReceived.target.value);
	};

	const handleDescriptionChange = (descriptionReceived: string) => {
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				description: descriptionReceived,
			}),
		);
	};
	useEffect(() => {
		dispatchEvent(
			actions.setEventToCreate({
				...eventToCreate,
				title: debouncedTitleValue,
			}),
		);
	}, [debouncedTitleValue]);

	return (
		<article data-testid="eventInfoSection" className="w-full">
			<div
				data-testid="eventInfoSectionTitle"
				className="text-sm font-bold text-primary-700P"
			>
				Informations de l&apos;évènement
			</div>
			<div className="mt-6 flex w-full flex-col gap-4">
				<div data-testid="eventTitleInput" className="w-full">
					<Input
						name="title"
						type="test"
						label="Titre de l'évènement"
						placeholder="Titre"
						value={eventToCreate?.title || targetEvent?.title || ""}
						icon={pricetagsOutline}
						onChange={(e) => {
							handleTitleChange(e);
						}}
						onBlur={(e: any) => {
							handleTitleChange(e);
						}}
						onClick={() => {}}
						onKeyDown={() => {}}
						className="w-full"
					/>
				</div>
				<div data-testid="eventDescriptionInput" className="w-full">
					<span className="text-xxsm font-normal text-primary-300">
						Description de l'évènement
					</span>
					<RichTextEditor
						value={eventToCreate?.description || targetEvent?.description || ""}
						placeholder=""
						onChange={(e) => {
							handleDescriptionChange(e);
						}}
					/>
				</div>
			</div>
		</article>
	);
}
