import { useElementVisibility } from "@hooks/useElementVisibility";
import { Icon } from "@iconify/react";
import React from "react";
import type { FormEvent } from "react";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import uuid from "react-uuid";

import { arrowDownFill, arrowUpFill } from "@assets/Icons";

type CellType = {
	children: any;
	onClick: any;
	className: string;
	url?: string;
};
export function Cell({ children, onClick, className, url }: CellType) {
	if (url && !onClick) {
		return (
			<td
				key={uuid()}
				className={`h-[68px] max-w-[400px] text-primary-300 ${
					typeof children === "string" && "truncate"
				} ${className} px-sm`}
			>
				<NavLink className={"flex h-full w-full items-center"} to={url}>
					{children}
				</NavLink>
			</td>
		);
	}

	return (
		<td
			key={uuid()}
			className={`h-[68px] max-w-[400px] px-sm text-primary-300 ${
				typeof children === "string" && "truncate"
			} ${className}`}
			onClick={onClick && onClick}
		>
			{children}
		</td>
	);
}

type SortType = {
	defaultOrder: string;
	fieldName: string;
};

export type HeadType = {
	key?: string;
	label?: string;
	sort?: SortType;
	filtersProps?: any;
	content?: any;
	className?: string;
};
export function Head({
	label,
	sort,
	filtersProps,
	content,
	className,
}: HeadType) {
	const [order, setOrder] = useState<string>(sort ? sort.defaultOrder : "ASC");
	const { filters, setFilters } = filtersProps;

	useEffect(() => {
		if (sort?.defaultOrder) {
			setOrder(sort.defaultOrder);
		}
	}, [sort?.defaultOrder]);

	return (
		<th
			key={uuid()}
			onClick={() => {
				if (sort) {
					setOrder(order === "ASC" ? "DESC" : "ASC");

					const newFilter: { [key: string]: any } = {};
					newFilter[sort.fieldName] = order === "ASC" ? "DESC" : "ASC";
					setFilters({
						...filters,
						order: [newFilter],
					});
				}
			}}
			className={`whitespace-nowrap px-sm py-md text-left  ${
				sort ? "cursor-pointer" : ""
			} ${className}`}
		>
			{content ?? (
				<div className="flex items-center gap-xsm font-semibold uppercase text-primary-300">
					<p className="text-xxsm">{label}</p>
					{sort && (
						<Icon icon={order === "ASC" ? arrowUpFill : arrowDownFill} />
					)}
				</div>
			)}
		</th>
	);
}

type RowType = {
	cells?: any[];
	url?: string;
	onClick?: Function;
	className?: string;
};

export function Row({ cells, onClick, className = "", url }: RowType) {
	const navagate = useNavigate();
	const handleRowClick = () => {
		if (url) {
			navagate(url);
		}
	};
	return (
		<tr
			key={uuid()}
			data-testid="tableRow"
			className={`border-t-[1px] border-primary-100 transition hover:bg-primary-50 ${
				onClick || url ? `cursor-pointer ${className}` : ""
			} ${className}`}
			onClick={handleRowClick}
		>
			{cells?.map((cell: any) => {
				return (
					<Cell
						onClick={
							onClick
								? (e: FormEvent<HTMLElement>) => {
										onClick();
										e.preventDefault();
										e.stopPropagation();
									}
								: undefined
						}
						url={url && url}
						{...cell}
					/>
				);
			})}
		</tr>
	);
}

export function LoadingRows({ headers }: { headers: HeadType[] }) {
	return (
		<tbody>
			{Array(10)
				.fill("")
				.map(() => {
					return (
						<Row
							cells={headers.map((header) => {
								return {
									children: (
										<Skeleton
											key={uuid()}
											height={16}
											width={header.label ? header.label.length * 12 : 24}
										/>
									),
								};
							})}
						/>
					);
				})}
		</tbody>
	);
}

type TableType = {
	headers?: HeadType[];
	rows: RowType[];
	className?: string;
	emptyMessage?: string | boolean;
	isLoading?: boolean;
	filtersProps?: any;
	nbColumns?: number;
	onReachEnd?: Function;
};

export function Table({
	headers,
	rows,
	className = "",
	emptyMessage,
	isLoading,
	filtersProps,
	onReachEnd = () => {},
}: TableType) {
	const tableRef = useRef<any>();
	const reachEnd = useElementVisibility(tableRef);

	useEffect(() => {
		if (reachEnd) {
			onReachEnd();
		}
	}, [reachEnd]);

	return (
		<div
			id="table"
			data-testid="table"
			className={`relative w-full overflow-x-auto rounded-lg bg-white px-xxsm ${className}`}
		>
			<table border={0} cellSpacing={24} className="w-full table-auto">
				<thead>
					<tr>
						{headers?.map((header) => {
							return (
								<Head
									label={header.label}
									sort={header.sort}
									filtersProps={filtersProps}
									content={header.content}
									className={header.className}
								/>
							);
						})}
					</tr>
				</thead>

				{headers && isLoading ? (
					<LoadingRows headers={headers} />
				) : (
					<>
						{emptyMessage && rows.length === 0 && (
							<div className="flex h-[270px] w-full items-center">
								<p className="absolute left-0 w-full text-center text-xsm text-primary-300">
									{emptyMessage}
								</p>
							</div>
						)}
						<tbody>
							{rows?.map((row) => {
								return (
									<Row
										className={row.className}
										onClick={row.onClick}
										url={row.url}
										cells={row.cells}
									/>
								);
							})}
						</tbody>
					</>
				)}
			</table>
			<div ref={tableRef} className="h-2" />
		</div>
	);
}
