import React, { type ReactNode } from "react";

function BodyGrid({ children }: { children: ReactNode }) {
	return (
		<div
			className="flex flex-col-reverse gap-6 sm:flex-row"
			data-testid="bodyGrid"
		>
			{children}
		</div>
	);
}

export default BodyGrid;
