import type { ApplicationType } from "@/types/applicationTypes";
import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { extractError } from "@tools/API";
import { useEffect, useState } from "react";
import {
	deleteFile,
	editApplication,
	getDocumentsData,
	uploadThisDocument,
} from "../../ApplicationDetailAPI";

export type ApplicationWithDocuments = ApplicationType & {
	documents?: { collection: MediaObjectType[] };
	cv?: { collection: MediaObjectType[] };
	motivation?: { collection: MediaObjectType[] };
};

type DocumentManagerProps = {
	documentType: string;
	initialData: ApplicationWithDocuments;
};
export const useDocumentManager = ({
	documentType,
	initialData,
}: DocumentManagerProps) => {
	const [document, setDocument] = useState<MediaObjectType>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>();

	const deleteDocument = async (document: MediaObjectType | undefined) => {
		if (!document) {
			return;
		}
		try {
			setIsLoading(true);
			const formatedId =
				typeof document.id === "string"
					? document.id
					: `/student/media_objects/${document.id}`;
			await deleteFile({ id: formatedId });
			setDocument(undefined);
			setError("");
			setIsLoading(false);
		} catch (error) {
			setError("Impossible de supprimer");
			ErrorToast(extractError(error).message);
			setIsLoading(false);
		}
	};

	const updateApplication = async (
		application: ApplicationWithDocuments,
		documentToAdd: MediaObjectType,
	) => {
		if (!application.documents) {
			return;
		}

		// to avoid overwriting other documents and resync with the backend
		const retrievesUpdatedDocuments: ApplicationWithDocuments =
			(await getDocumentsData(application.id)) as ApplicationWithDocuments;
		if (
			!retrievesUpdatedDocuments ||
			!retrievesUpdatedDocuments.cv ||
			!retrievesUpdatedDocuments.motivation ||
			!retrievesUpdatedDocuments.documents
		) {
			setError("Impossible de récupérer les documents");
			return;
		}

		editApplication({
			id: application.id,
			documents: [
				...retrievesUpdatedDocuments?.cv.collection.map(
					(item: MediaObjectType) => item.id,
				),
				...retrievesUpdatedDocuments?.motivation.collection.map(
					(item: MediaObjectType) => item.id,
				),
				...retrievesUpdatedDocuments?.documents.collection.map(
					(item: MediaObjectType) => item.id,
				),
				documentToAdd?.["@id"],
			],
		});
	};

	const uploadFile = async (
		files: File[],
		application: ApplicationWithDocuments,
	) => {
		setIsLoading(true);
		const data = new FormData();

		data.append("file", files[0]);
		data.append("type", documentType);

		const response = await uploadThisDocument(data);
		if (response) {
			setDocument(response);
			updateApplication(application, response);
			setError("");
		} else {
			setError("Impossible d'envoyer le fichier");
		}
		setIsLoading(false);
	};

	useEffect(() => {
		switch (documentType) {
			case "cv":
				if (initialData.cv) {
					setDocument(initialData?.cv.collection[0]);
				}
				break;
			case "motivation":
				if (initialData.motivation) {
					setDocument(initialData?.motivation.collection[0]);
				}
				break;
		}
	}, [initialData]);

	return {
		document,
		isLoading,
		error,
		deleteDocument,
		uploadFile,
		setError,
	};
};
