import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./Community.scss";

import { Cards } from "@containers/shared/Community/ui/Cards";
import { Content } from "@containers/shared/Community/ui/Content";

export function Community() {
	const [communityLink, setCommunityLink] = useState(null);
	const { setTitle } = useOutletContext();
	const { user } = useContext(GlobalContext);

	useEffect(() => {
		setTitle("Communauté");
		if (user?.communityLink) {
			setCommunityLink(user?.communityLink);
		}
	}, [user]);

	return (
		<main className="community grid w-full grid-cols-4 gap-4 sm:gap-6 md:grid-cols-8 lg:grid-cols-12 xxl:gap-8">
			<div className="page-container col-span-4 flex w-full p-0 md:col-span-8 lg:col-span-12">
				<div className="flex flex-wrap">
					<Cards />
					<Content communityLink={communityLink} />
				</div>
			</div>
		</main>
	);
}
