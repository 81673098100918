import type { ApplicationType } from "@/types/applicationTypes";
import type { SchoolOfferType } from "@/types/offerTypes";
import { extractIdNumberFromId } from "./Users";

export function getAccentColor(step: string): string {
	switch (step) {
		case "readyToApply":
			return "1";
		case "sent":
			return "2";
		case "relaunch":
			return "3";
		default:
			return "4";
	}
}

export function getColumnIndex(step: string): number {
	switch (step) {
		case "readyToApply":
			return 0;
		case "sent":
			return 1;
		case "relaunch":
			return 2;
		case "interview":
			return 3;
		default:
			return 0;
	}
}

export function getStepByIndex(step: number): string {
	switch (Number(step)) {
		case 0:
			return "readyToApply";
		case 1:
			return "sent";
		case 2:
			return "relaunch";
		case 3:
			return "interview";
		default:
			return "readyToApply";
	}
}

export const checkIfAlreadyApply = (
	userApplications: ApplicationType[],
	offer: SchoolOfferType,
): boolean => {
	return (
		userApplications.filter(
			(application: ApplicationType) =>
				extractIdNumberFromId(application?.offer?.id) ===
				extractIdNumberFromId(offer?.id),
		).length > 0
	);
};
