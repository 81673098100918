import type { EventInvitationType } from "@/types/eventsInvitationType";
import { checkmarkOutline, emailOutline } from "@assets/Icons";
import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { Spinner } from "@components/atoms/Spinner";
import { extractIdNumberFromId } from "@tools/Users";
import React from "react";
import { useReplyViewCard } from "./useReplyViewCard";

type ReplyViewCardProps = {
	replyData: EventInvitationType;
};
function ReplyViewCard({ replyData }: ReplyViewCardProps) {
	const presenter = useReplyViewCard(replyData);

	return (
		<a
			href={`/students/${extractIdNumberFromId(replyData?.student?.id)}`}
			rel="noreferrer noopener"
			target="_blank"
			className="flex flex-col gap-xxsm w-full justify-start items-start cursor-pointer"
			key={replyData?.id}
		>
			<aside className="text-primary-200 text-xxsm font-bold">
				Le {presenter.creationDateToShow}
			</aside>
			<div className="flex gap-4 w-full">
				<Avatar
					image={replyData?.student?.avatar?.filePath}
					size="sm"
					firstname={replyData?.student?.firstname}
					lastname={replyData?.student?.lastname}
				/>
				<div className="flex flex-col gap-xxsm w-full">
					<p className="text-primary-700P text-base font-semibold flex flex-1 gap-xxsm">
						<span>{replyData?.student?.firstname}</span>
						<span>{replyData?.student?.lastname}</span>
					</p>
					<p className="text-primary-700P text-base font-normal min-w-fit">
						{replyData?.student?.spsPromotion.name}
					</p>
				</div>
				{presenter.hasParticipated && (
					<Badge
						label="A participé"
						bgColor="bg-accent-4-lighter"
						borderColor="border-accent-4-dark"
						hasBorder
						size="medium"
						className="text-accent-4-dark w-fit text-nowrap"
						rightIcon={checkmarkOutline}
					/>
				)}
				<div
					className="flex flex-1 min-w-[96px] w-full justify-end items-center"
					id="resent-email"
				>
					{presenter.showResendDate && (
						<div className="flex w-full justify-end">
							<p className="text-primary-200 text-xxsm font-bold">
								Envoyé le {presenter.resendDate}
							</p>
						</div>
					)}
					{presenter.isResending ? (
						<div className="flex w-full justify-end">
							<Spinner size="small" />
						</div>
					) : (
						<ButtonTertiary
							icon={emailOutline}
							tooltip={presenter.resendTooltip}
							size="large"
							onClick={presenter.resendInvitationEmail}
							disabled={!presenter.canResentEmail}
						/>
					)}
				</div>
			</div>
		</a>
	);
}

export default ReplyViewCard;
