import { Icon, type IconifyIcon } from "@iconify/react";
import React, { type MouseEvent } from "react";
import { useEffect, useRef, useState } from "react";

import "@assets/sass/DropdownItem.scss";

import { arrowIosForwardOutline } from "@assets/Icons";

import TooltipContainer from "@components/atoms/TooltipContainer";

let uniqueId = 0;

type DropdownItemType = {
	icon?: IconifyIcon | string;
	label: string;
	onClick: (e: MouseEvent<HTMLElement>) => void;
	subMenu?: any;
	color?: string;
	isSelect?: boolean;
	truncate?: boolean;
	nodeElement?: any;
	breakSpaces?: boolean;
};

export function DropdownItem({
	icon,
	label,
	onClick,
	subMenu,
	color = "primary-500",
	isSelect = false,
	truncate,
	nodeElement,
	breakSpaces = false,
}: DropdownItemType) {
	const elementsRef = useRef(null);
	const [componentId] = useState(() => uniqueId++);
	const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false);

	useEffect(() => {
		const element = document.getElementById(`dropdown-item-${componentId}`);

		if (element) {
			element.addEventListener("keyup", (event) => {
				if (event.key === "Enter") element.click();
			});
		}
	}, [componentId]);

	useEffect(() => {
		// @ts-ignore
		const element = elementsRef.current.querySelector(".truncate");

		if (element && element.scrollWidth > element.offsetWidth) {
			setTooltipEnabled(true);
		}
	});

	const renderContent = () => {
		return (
			<p
				className="flex items-center truncate text-xxsm"
				id={`${label}-tooltip`}
			>
				{label}
				{subMenu && (
					<Icon
						icon={arrowIosForwardOutline}
						className="ml-auto h-4 text-primary-300"
					/>
				)}
				{tooltipEnabled && (
					<TooltipContainer
						anchorId={`#${label}-tooltip`}
						children={label}
						place="top"
					/>
				)}
			</p>
		);
	};
	return (
		<div
			id={`dropdown-item-${componentId}`}
			ref={elementsRef}
			data-id="dropdownItem"
			role="menu"
			onClick={onClick}
			className={`dropdown-item flex flex-[100%] flex-shrink flex-grow ${
				breakSpaces && "whitespace-break-spaces"
			} items-center ${truncate && "truncate"} ${
				isSelect && "border-2 border-primary-500"
			}`}
		>
			{icon && <Icon icon={icon} color={color} className="mr-xsm" />}
			{nodeElement ?? renderContent()}
		</div>
	);
}
