import type { ProjectType } from "@/types/userTypes";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import { trash2Outline } from "@assets/Icons";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { TextArea } from "@components/atoms/TextArea";
import { Input } from "@components/molecules/Input";
import { Modal } from "@components/organisms/modal/Modal";

import DeleteModal from "./DeleteModal";

type ProjectEditorModalType = {
	show: boolean;
	onClose: Function;
	editCareer: Function;
	project?: ProjectType;
};

export function ProjectEditorModal({
	show,
	onClose,
	editCareer,
	project,
}: ProjectEditorModalType) {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [keyForReload, setKeyForReload] = useState(0);

	useEffect(() => {
		setKeyForReload(keyForReload + 1);
	}, [project]);

	return (
		<div>
			<Formik
				initialValues={{
					id: project?.id || null,
					name: project?.name || "",
					detail: project?.detail || "",
					url: project?.url || "",
				}}
				validate={(values) => {
					const errors: any = {};

					if (!values?.name) errors.name = "Veuillez entrer un nom de projet";
					if (!values.detail) errors.detail = "Veuillez spécifier un détail";
					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					editCareer("project", project === null ? "create" : "update", values);
					resetForm({});
					onClose();
				}}
				key={keyForReload}
			>
				{({
					values,
					errors,
					setFieldValue,
					handleChange,
					handleSubmit,
					resetForm,
				}) => (
					<Modal
						show={show}
						title="Ajouter un projet"
						onClose={() => {
							onClose();
							resetForm();
						}}
						body={
							<div className="flex flex-col gap-sm">
								<Input
									label="Nom de ton projet"
									placeholder="Ex :  Création d’un CV en HTML"
									name="name"
									value={values?.name}
									onChange={handleChange}
									errorMessage={errors.name as string}
								/>
								<Input
									label="Lien vers ton projet (optionnel)"
									placeholder="Ex :  monportfolio.com"
									name="url"
									value={values.url}
									onChange={handleChange}
								/>
								<TextArea
									label="Détails"
									placeholder="Tu peux expliquer ici comment s’est déroulé ton projet, les compétences ciblées, etc..."
									value={values.detail}
									onChange={(e) => {
										setFieldValue("detail", e);
									}}
									errorMessage={errors.detail as string}
								/>
							</div>
						}
						buttonsLeft={[
							project && (
								<ButtonTertiary
									icon={trash2Outline}
									onClick={() => {
										onClose();

										setShowDeleteModal(true);
									}}
								/>
							),
						]}
						buttonsRight={[
							<ButtonSecondary
								label="Annuler"
								key="cancelBTN"
								onClick={() => {
									onClose();
									resetForm();
								}}
							/>,
							<ButtonPrimary
								label={project ? "Modifier" : "Ajouter"}
								key="addBTN"
								type="submit"
								onClick={() => {
									handleSubmit();
								}}
							/>,
						]}
					/>
				)}
			</Formik>
			<DeleteModal
				label="Es-tu sûr de vouloir supprimer ce projet ?"
				show={showDeleteModal}
				type="project"
				entityId={project?.id}
				editCareer={editCareer}
				onClose={() => {
					setShowDeleteModal(false);
				}}
			/>
		</div>
	);
}
