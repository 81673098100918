import type { ReferentType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";

import { sortReferentsByLastName } from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";

import { Spinner } from "@components/atoms/Spinner";
import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import {
	modifyOfferToCreate,
	updateReferentList,
} from "../../core/store/offersManagement.slice";
import {
	fetchModifyOfferReferent,
	fetchReferents,
} from "../../core/store/offersManagement.thunks";

const IS_EDITION_MODE = window.location.pathname.includes("edition");

type ReferentModifierProps = {
	useAutosave?: boolean;
};

export function ReferentModifier({ useAutosave }: ReferentModifierProps) {
	const { user } = useContext(GlobalContext);
	const dispatchEvent = useDispatch();
	const { offerToCreate, offerToEdit, loadingStates, referentsListReceived } =
		useAppSelector((state) => state.offers);

	const retrieveAllReferents = async () => {
		const campusId = user?.campuses[0]?.id;
		const campusList = [];
		if (campusId) {
			campusList.push(extractIdNumberFromId(campusId).toString());
		}
		dispatchEvent(
			fetchReferents({
				campusList,
				schoolId: `${user?.school}`,
			}) as any,
		);
	};

	const getReferentListByRole = () => {
		if (!referentsListReceived) return [];
		const roles = user?.roles;
		return roles?.includes("ROLE_SCHOOL")
			? referentsListReceived.schoolReferents
			: referentsListReceived.campusReferents;
	};

	const addReferentToTheList = (referent: ReferentType) => {
		const roles = user?.roles;
		if (!roles) return;
		const referentList = getReferentListByRole();
		if (referentList) {
			if (referentList.find((ref: ReferentType) => ref.id === referent.id)) {
				return;
			}
			const updatedReferentList = [...referentList, referent];
			const referents = {
				campusReferents: roles?.includes("ROLE_SCHOOL")
					? referentsListReceived?.campusReferents
					: sortReferentsByLastName(updatedReferentList),
				schoolReferents: roles?.includes("ROLE_SCHOOL")
					? sortReferentsByLastName(updatedReferentList)
					: referentsListReceived?.schoolReferents,
			};
			dispatchEvent(updateReferentList(referents));
		}
	};

	const makeSelectOptionsFromReferents = () => {
		return getReferentListByRole()?.map((referent: ReferentType) => {
			return {
				label: `${referent?.lastname} ${referent?.firstname}`,
				value: referent?.id,
			};
		});
	};

	const findReferentOptionFromId = (idOfReferentToFind: string) => {
		const referentList = getReferentListByRole();
		if (idOfReferentToFind === "" || referentList?.length === 0) {
			return "";
		}

		if (offerToCreate?.responsable || offerToEdit?.responsable) {
			const responsable =
				offerToEdit?.responsable || offerToCreate?.responsable;
			if (
				!referentList?.find((ref: ReferentType) => ref.id === responsable.id)
			) {
				addReferentToTheList(responsable);
			}
			return {
				label: `${responsable.lastname} ${responsable.firstname}`,
				value: responsable.id,
			};
		}
		const referentFind = getReferentListByRole()?.find(
			(referentFromTheList: ReferentType) => {
				return (
					extractIdNumberFromId(referentFromTheList.id) ===
					extractIdNumberFromId(idOfReferentToFind)
				);
			},
		);

		if (!referentFind) {
			return "";
		}
		return {
			label: `${referentFind?.lastname} ${referentFind?.firstname}`,
			value: referentFind?.id,
		};
	};

	const findReferentDataFromId = (id: string): ReferentType | undefined => {
		const referentFound = getReferentListByRole()?.find(
			(referent: ReferentType) => {
				return extractIdNumberFromId(referent.id) === extractIdNumberFromId(id);
			},
		);
		return referentFound;
	};

	const tryToSelectActiveUserAsReferent = () => {
		if (IS_EDITION_MODE || !getReferentListByRole()?.length || !user) {
			return "";
		}
		const canUseUserAsReferent = getReferentListByRole()?.find(
			(referent: ReferentType) => {
				return referent?.id?.toString() === user?.id.toString();
			},
		);
		if (!canUseUserAsReferent) return "";
		dispatchEvent(modifyOfferToCreate({ responsable: canUseUserAsReferent }));
		return {
			label: `${canUseUserAsReferent?.lastname} ${canUseUserAsReferent?.firstname}`,
			value: canUseUserAsReferent?.id,
		};
	};

	const handleReferentChange = (value: string) => {
		const referentData = findReferentDataFromId(value);
		if (useAutosave)
			dispatchEvent(
				fetchModifyOfferReferent({
					referent: value,
					id: extractIdNumberFromId(offerToEdit?.id).toString(),
				}) as any,
			);
		else
			dispatchEvent(
				modifyOfferToCreate({
					responsable: referentData,
				}),
			);
	};

	useEffect(() => {
		if (user) retrieveAllReferents();
	}, [user]);
	if (loadingStates.isFetchingReferents) {
		return <Spinner size="small" />;
	}

	if (!referentsListReceived) {
		return <p>Auncun responsable disponible.</p>;
	}
	const defaultValue =
		offerToEdit?.responsable || offerToCreate?.responsable
			? findReferentOptionFromId(
					offerToEdit?.responsable
						? offerToEdit?.responsable?.id || ""
						: offerToCreate?.responsable
							? offerToCreate?.responsable?.id || ""
							: "",
				)
			: tryToSelectActiveUserAsReferent();
	return (
		<SelectSecondary
			label="Responsable de l'offre"
			defaultValue={defaultValue}
			position="right"
			placeholder={
				referentsListReceived?.campusReferents?.length !== 0
					? "Choisir un responsable..."
					: "Pas de responsable disponible"
			}
			options={makeSelectOptionsFromReferents() || []}
			onChange={(e) => {
				handleReferentChange(e.value);
			}}
			className="wg-no-translate"
			tooltip="Personne en charge de l’offre."
		/>
	);
}
