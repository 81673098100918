export const TYPE_OF_EVENT = [
	"Mentor Goal - Conférence",
	"Mentor Goal - Coaching",
	"Mentor Goal - Atelier",
	"Évènement école",
	"Forum emploi",
	"Coaching emploi",
	"Salon étudiant",
	"Atelier",
	"Portes ouvertes",
	"Conférence",
	"Jobdating",
];
