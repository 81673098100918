import React, { useContext, useEffect, useState } from "react";

import { getCookie } from "@tools/Cookies";

import { InlineButton } from "@components/atoms/InlineButton";
import { Success } from "@components/organisms/toast/ToastNotification";

import { StudentsTargetingModalContext } from ".";
import { Filters } from "./SelectStudents/Filters";
import { Notifications } from "./SelectStudents/Notifications";

export function SelectStudents({ label }: { label: string }) {
	const previousStudentsSelectionCookie = getCookie(
		"previousStudentsSelection",
	);
	const [previousStudentsSelection, setPreviousStudentsSelection] = useState(
		[],
	);
	const { selectedStudents, setSelectedStudents, setView, showOptions } =
		useContext(StudentsTargetingModalContext);

	useEffect(() => {
		if (previousStudentsSelectionCookie !== null) {
			setPreviousStudentsSelection(JSON.parse(previousStudentsSelectionCookie));
		}
	}, []);

	return (
		<div className="flex w-1/2 flex-col gap-sm">
			<p className="text-primary-700P">{label}</p>
			{previousStudentsSelection.length > 0 &&
				JSON.stringify(previousStudentsSelection) !==
					JSON.stringify(selectedStudents) && (
					<InlineButton
						label="Reprendre la sélection précédente"
						onClick={() => {
							setSelectedStudents(previousStudentsSelection);
							Success("La sélection a bien été récupérée");
							setView("recap");
						}}
					/>
				)}
			<div className="flex flex-col gap-sm pr-xsm">
				<Filters />
				{showOptions && <Notifications />}
			</div>
		</div>
	);
}
