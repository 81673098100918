import React from "react";
import Skeleton from "react-loading-skeleton";

export function SchoolCardLoader() {
	return (
		<div className="flex justify-between items-center border-t-[1px] border-primary-100 py-sm">
			<div className="w-5/12">
				<Skeleton />
			</div>
			<div className="w-3/12">
				<Skeleton height={40} />
			</div>
		</div>
	);
}
