import type { UserType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect, useState } from "react";

import { eyeOutline, minusOutline } from "@assets/Icons";

import { ButtonQuinary } from "@components/atoms/ButtonQuinary";
import { CellAvatarLabelSecondary } from "@components/molecules/CellAvatarLabelSecondary";
import { setCurrentFilters } from "@components/organisms/usersFilters/usersFiltersSlice";
import { Table } from "@components/templates/table/Table";

import { StudentsTargetingModalContext } from ".";
import { viewSelection } from "./StudentsTargetingModalAPI";
export function YourSelection() {
	const { currentFilters } = useAppSelector((state) => state.usersFilters);
	const { selectedStudents } = useContext(StudentsTargetingModalContext);
	const [load, setLoad] = useState<boolean>(true);
	const [offset, setOffset] = useState<number>(0);
	const [students, setStudents] = useState<UserType[]>([]);

	const fetchStudents = async () => {
		setLoad(true);
		const { visibleStudents }: any = await viewSelection(
			selectedStudents
				.slice(offset, offset + 20)
				.map((student: UserType) => student.id),
		);

		setStudents([...students, ...visibleStudents.collection]);
		setLoad(false);
	};

	useEffect(() => {
		fetchStudents();
	}, [offset]);

	const cellsResolver = (student: UserType) => {
		return [
			{
				children: (
					<CellAvatarLabelSecondary
						firstname={student.firstname}
						lastname={student.lastname}
						avatar={student.avatar?.filePath}
						label={`${student.lastname} ${student.firstname}`}
						sublabel={student?.spsPromotion?.name}
					/>
				),
			},
			{
				className: "group-hover:opacity-100 opacity-0 transition",
				children: <ButtonQuinary icon={eyeOutline} onClick={() => {}} />,
			},
			{
				className: "group-hover:opacity-100 opacity-0 transition",
				children: (
					<ButtonQuinary
						icon={minusOutline}
						iconColor="text-error-dark"
						onClick={() => {}}
					/>
				),
			},
		];
	};
	return (
		<div>
			<div className="h-[320px] overflow-y-auto">
				<Table
					filtersProps={{ currentFilters, dispatchEvent: setCurrentFilters }}
					headers={[
						{ label: `${selectedStudents.length} étudiants sélectionnés` },
					]}
					isLoading={load}
					rows={students.map((student: UserType) => {
						return {
							cells: cellsResolver(student),
						};
					})}
					onReachEnd={() => {
						if (selectedStudents.length > offset + 20) setOffset(offset + 20);
					}}
				/>
			</div>
		</div>
	);
}
