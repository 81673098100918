export default function useGeocodePlacePredictions() {
	const geocodeByLocality = async (citySelected: string | null) => {
		const geocoderPrediction = new window.google.maps.Geocoder();

		const geocodeRequest = {
			address: citySelected ?? "",
		};
		const { results } = await geocoderPrediction.geocode(geocodeRequest);

		if (results.length > 0) {
			return {
				location:
					results[0].address_components.find((component) =>
						component.types.includes("locality"),
					)?.long_name ?? "",
				latitude: results[0].geometry.location.lat(),
				longitude: results[0].geometry.location.lng(),
				zipCode: results[0].address_components.find((component) =>
					component.types.includes("postal_code"),
				)?.long_name,
			};
		}
		return null;
	};

	const geocodeByPlacePredictions = async (address: string) => {
		const geocoder = new window.google.maps.places.AutocompleteService();

		const { predictions } = await geocoder?.getPlacePredictions({
			types: ["locality", "postal_code"],
			input: address,
		});

		return predictions;
	};

	return { geocodeByPlacePredictions, geocodeByLocality };
}
