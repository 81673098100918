import { Icon } from "@iconify/react";
import React from "react";

import { convertDateFromNow } from "@tools/Dates";
import { resolveLog } from "@tools/Logs";

import type { LogType } from "@/types/logTypes";
import { LogDescription } from "@components/organisms/LogHistory/LogDescription";

export function LogCard({ log }: { log: LogType }) {
	return (
		<div className="flex gap-md text-primary-700P">
			<div className="aspect-square h-fit w-fit rounded-full bg-primary-300 p-xsm text-white">
				<Icon icon={resolveLog(log).icon} height="35px" />
			</div>
			<div>
				<p
					className="text-sm font-semibold"
					dangerouslySetInnerHTML={{
						__html: resolveLog(log, "student").label,
					}}
				/>
				<LogDescription log={log} />

				<hr className="my-xsm w-10" />
				<p className="text-xxsm font-bold text-primary-200">
					{convertDateFromNow(log.createdAt as Date)}
				</p>
			</div>
		</div>
	);
}
