import type { ApplicationType } from "@/types/applicationTypes";
import { useAppSelector } from "@config/redux/hook";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import React, { useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { getOfferDetailData } from "@containers/student/Jobboard/controller/offerDetail.api";
import type { StudentLayoutContextType } from "@containers/student/StudentLayout/StudentLayout";

import { getCookie } from "@tools/Cookies";
import {
	setApplication,
	setDefaultCv,
	setLoading,
	setOffer,
	setShowMinifyHead,
} from "../controller/offerDetail.slice";
import { Head } from "../ui/offerDetail/ui//Head";
import { Description } from "../ui/offerDetail/ui/Description";
import { Informations } from "../ui/offerDetail/ui/Informations";
import { OfferDetailLoader } from "../ui/offerDetail/ui/OfferDetailLoader";

export function OfferDetail() {
	const dispatchOfferDetail = useDispatch();
	const navigate = useNavigate();
	const headRef = useRef<HTMLDivElement | null>(null);
	const { id } = useParams();
	const { isMobile } = useBreakpoints();
	const { setTitle }: StudentLayoutContextType = useOutletContext();
	const { user }: GlobalContextType = useContext(GlobalContext);
	const { loading } = useAppSelector((state) => state.offerDetail);

	const getOffer = () => {
		if (!user) return;
		dispatchOfferDetail(setLoading(true));
		getOfferDetailData(
			`/${getCookie("interface")}/offers/${id}` as string,
			user.id,
		).then(({ offer, user }: any) => {
			dispatchOfferDetail(setDefaultCv(user.cv));
			if (!offer) navigate("/offer-expired");
			dispatchOfferDetail(setOffer(offer));
			dispatchOfferDetail(
				setApplication(
					offer?.applications.collection.filter(
						(application: ApplicationType) => {
							return application.step !== "readyToApply";
						},
					)[0] ?? undefined,
				),
			);
			dispatchOfferDetail(setLoading(false));
		});
	};

	useEffect(() => {
		!isMobile &&
			window.addEventListener("scroll", () => {
				if (headRef.current) {
					const head = headRef.current.getBoundingClientRect();

					if (head.y <= -250) {
						dispatchOfferDetail(setShowMinifyHead(true));
					} else {
						dispatchOfferDetail(setShowMinifyHead(false));
					}
				}
			});
	});

	useEffect(() => {
		getOffer();
		setTitle("Jobthèque");
	}, []);

	if (loading) return <OfferDetailLoader />;

	return (
		<main id="offer-detail">
			<div ref={headRef}>
				<Head position="large" />
			</div>
			<div className="mt-md flex flex-wrap gap-md md:flex-nowrap">
				<Description />
				<Informations />
			</div>
		</main>
	);
}
