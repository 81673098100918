import type { UserRoleType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import { getVisibilityOptions } from "@tools/Offers";

import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import { setOfferToCreate } from "../../core/store/offersManagement.slice";
import { fetchModifyOfferVisibility } from "../../core/store/offersManagement.thunks";

type VisibilityModifierProps = {
	useAutosave?: boolean;
};

function VisibilityModifier({ useAutosave }: VisibilityModifierProps) {
	const dispatchEvent = useDispatch();
	const isEdition = useAutosave || false;
	const { user } = useContext(GlobalContext);
	const { offerToEdit, offerToCreate } = useAppSelector(
		(state) => state.offers,
	);
	const visibilities = getVisibilityOptions({
		cutDetails: false,
		roles: user?.roles as UserRoleType[],
		isEdition,
		currentVisibility: isEdition ? offerToEdit?.visibility : undefined,
	});

	const getDefaultVisibility = () => {
		if (useAutosave) {
			if (!offerToEdit?.visibility) {
				return visibilities[0];
			}
			return visibilities?.find(
				(data) => data?.value === offerToEdit?.visibility,
			);
		}
		if (!offerToCreate?.visibility) {
			return visibilities[0];
		}
		return visibilities?.find(
			(data) => data?.value === offerToCreate?.visibility,
		);
	};

	const handleVisibilityChange = (value: string) => {
		if (useAutosave) {
			if (!offerToEdit?.id) return;
			dispatchEvent(
				fetchModifyOfferVisibility({
					id: offerToEdit?.id,
					visibility: value,
				}) as any,
			);
			return;
		}
		dispatchEvent(setOfferToCreate({ ...offerToCreate, visibility: value }));
	};
	return (
		<SelectSecondary
			label="Visibilité"
			position="right"
			defaultValue={getDefaultVisibility()}
			options={visibilities}
			onChange={(e) => {
				handleVisibilityChange(e.value);
			}}
			tooltip="La visibilité correspond à la restriction de l'offre."
		/>
	);
}

export default VisibilityModifier;
