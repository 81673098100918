import { useBreakpoints } from "@hooks/useBreakpoints";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { NavigationBarContext } from "@components/organisms/NavigationBar/NavigationBar";

import { NavigationItem } from "./NavigationItem";
import type { NavigationItemType, SubMenuItemType } from "./type";

type subMenuType = NavigationItemType & {
	onClickSubMenu: Function;
};
export function SubMenu({ nav, onClickSubMenu }: subMenuType) {
	const { isMobile } = useBreakpoints();
	const {
		currentLabelSubMenu,
		setCurrentLabelSubMenu,
		activeNavigationBar,
		menuHover,
		setMenuHover,
	} = useContext(NavigationBarContext);
	const [currentSubMenu, setCurrentSubMenu] = useState<Array<SubMenuItemType>>(
		[],
	);
	const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

	const [activeSubMenu, setActiveSubMenu] = useState<boolean>(false);
	const refNavigationItem = useRef<HTMLDivElement>(null);
	const activeClass =
		"rounded-bl-sm rounded-br-md rounded-tl-md rounded-tr-sm bg-accent-2-light px-xsm py-xxsm text-primary700P font-bold";
	const updateSubMenu = (
		currentSubMenu: SubMenuItemType[],
		target: SubMenuItemType,
	) => {
		return currentSubMenu.map((subMenu: SubMenuItemType) => ({
			...subMenu,
			active: target.label === subMenu.label,
		}));
	};

	const handleOnClick = (target: SubMenuItemType) => {
		if (!target.link.includes("http")) {
			const newCurrentSubMenu = updateSubMenu(currentSubMenu, target);

			setMenuHover(false);
			setCurrentSubMenu(newCurrentSubMenu);
		}
	};
	const targetProps = (link: string) => {
		if (link?.includes("http")) return { target: "_blank" };
		return {};
	};

	useEffect(() => {
		const matchSubMenu = nav.subNavigation?.find(
			(item) => item.link?.split("/")[1] === location.pathname.split("/")[1],
		);

		if (nav.subNavigation) {
			const initialSubMenu = nav.subNavigation.map(
				(subMenu: SubMenuItemType) => ({
					...subMenu,
					active:
						subMenu.link?.split("/")[1] === location.pathname.split("/")[1],
				}),
			);

			setCurrentSubMenu(initialSubMenu ?? []);
		}

		if (!matchSubMenu) {
			setShowSubMenu(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		const matchUrl = nav.subNavigation?.find(
			(item) => item.link?.split("/")[1] === location.pathname.split("/")[1],
		);

		if (!isMobile && matchUrl) {
			setShowSubMenu(menuHover);
		} else {
			if (activeNavigationBar && currentLabelSubMenu === nav.label) {
				return setShowSubMenu(true);
			}

			setShowSubMenu(false);
		}
	}, [menuHover, activeNavigationBar]);

	useEffect(() => {
		if (nav.subNavigation) {
			const initActiveSubmenu = nav.subNavigation.map(
				(subMenu: SubMenuItemType) => ({
					...subMenu,
					active:
						subMenu.link?.split("/")[1] === location.pathname.split("/")[1],
				}),
			);

			setCurrentSubMenu(initActiveSubmenu);
		}

		if (currentLabelSubMenu !== nav.label && nav.subNavigation) {
			setActiveSubMenu(false);
			setShowSubMenu(false);
		}
	}, [currentLabelSubMenu]);

	return (
		<>
			<div ref={refNavigationItem}>
				<NavigationItem
					nav={nav}
					onClick={(label: string) => {
						if (menuHover) {
							setActiveSubMenu(!activeSubMenu);
							setShowSubMenu(!showSubMenu);
						}

						setCurrentLabelSubMenu(label);
					}}
					showSubMenu={showSubMenu}
				/>

				<div
					style={{
						height:
							showSubMenu && nav.subNavigation
								? `${
										30 *
										nav.subNavigation?.filter((item) => {
											return (
												!Object.prototype.hasOwnProperty.call(
													item,
													"invisible",
												) ||
												(Object.prototype.hasOwnProperty.call(
													item,
													"invisible",
												) &&
													item.invisible) ||
												false
											);
										}).length
									}px`
								: "0px",
					}}
					className={`${
						showSubMenu ? "mb-xsm" : ""
					} ml-sm border-l-2 border-accent-2-light transition-all ${
						showSubMenu ? "" : "pointer-events-none overflow-hidden"
					}
        `}
				>
					{currentSubMenu.map((subMenu: SubMenuItemType) => {
						if (
							Object.prototype.hasOwnProperty.call(subMenu, "invisible") &&
							!subMenu.invisible
						) {
							return null;
						}

						return (
							<div key={subMenu.label} className="mb-xsm">
								<NavLink
									className={`ml-xsm whitespace-nowrap text-xxsm   ${
										subMenu.active ? activeClass : "text-white"
									}`}
									onClick={() => {
										handleOnClick(subMenu);
										if (onClickSubMenu) onClickSubMenu();
									}}
									to={subMenu.link}
									{...targetProps(subMenu.link)}
								>
									{subMenu.label}
								</NavLink>
							</div>
						);
					})}
				</div>
			</div>
			{nav.separator && (
				<hr className="my-xsm h-[1px] w-full border-none bg-primary-200" />
			)}
		</>
	);
}
