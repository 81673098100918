import type { EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { trash2Outline } from "@assets/Icons";

import { buildTooltipFromStackErrors } from "@tools/Errors";
import { checkIfEventCouldBeCreated } from "@tools/Events";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { InlineButton } from "@components/atoms/InlineButton";
import TooltipContainer from "@components/atoms/TooltipContainer";
import TooltipElement from "@components/atoms/TooltipElement";
import { Modal } from "@components/organisms/modal/Modal";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import {
	createEventRequest,
	deleteEventRequest,
	updateEventRequest,
} from "@containers/shared/Events/core/api/events.request";

import { formatRichTextEditor } from "../../../../../tools/Strings";

import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";
import { extractIdNumberFromId } from "@tools/Users";
import DeleteConfirmationModal from "./modal/DeleteConfirmationModal";

const WORD_CONFIRMATION = "SUPPRIMER";

export function EventCreationSubmit() {
	const { user } = useContext(GlobalContext);
	const targetEvent = useAppSelector((state) => state.events.targetEvent);
	const eventToCreate = useAppSelector(
		(state) => state.events.eventToCreate,
	) as EventType;
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [sendLoading, setSendLoading] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<Uppercase<string>>("");

	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;
	const navigateTo = useNavigate();

	const handleAbortCreation = () => {
		dispatchEvent({ type: "events/resetEventToCreate" });
		navigateTo("/events");
	};

	const handleCreateEvent = async () => {
		const FormatedDescription = formatRichTextEditor(eventToCreate.description);
		const sanitizedEvent = {
			...eventToCreate,
			illustration: eventToCreate?.illustration?.["@id"] || null,
			description: FormatedDescription,
			startDate: new Date(eventToCreate.startDate).toLocaleString("en-US", {
				timeZone: "Europe/Paris",
			}),
			endDate: new Date(eventToCreate.endDate).toLocaleString("en-US", {
				timeZone: "Europe/Paris",
			}),
		};
		setSendLoading(true);
		const response = await createEventRequest(
			sanitizedEvent,
			user?.school as string,
		);
		if (response) {
			if (response.responseLabel === "success") {
				Success(response.message);
				dispatchEvent(actions.resetEventToCreate());
				dispatchEvent(actions.setInitialized(false));
				navigateTo("/events");
			} else {
				ErrorToast(`Une erreur est survenue : ${response.message}`);
			}
			setSendLoading(true);
		}
	};

	const handleModifyEvent = async () => {
		const FormatedDescription: string = formatRichTextEditor(
			eventToCreate.description,
		);
		const sanitizedEvent = {
			...eventToCreate,
			illustration: eventToCreate.illustration
				? `/api/media_objects/${
						extractIdNumberFromId(eventToCreate?.illustration?.id) ||
						extractIdNumberFromId(eventToCreate?.illustration?.["@id"])
					}`
				: null,
			invitations: eventToCreate.invitations?.collection.map((invitation) => {
				return invitation.id;
			}),
			description: FormatedDescription,
			startDate: new Date(eventToCreate.startDate).toLocaleString("en-US", {
				timeZone: "Europe/Paris",
			}),
			endDate: new Date(eventToCreate.endDate).toLocaleString("en-US", {
				timeZone: "Europe/Paris",
			}),
		};
		delete sanitizedEvent.invitationsCount;
		delete sanitizedEvent.inscriptionsCount;
		delete sanitizedEvent.participationsCount;
		setSendLoading(true);
		const response = await updateEventRequest(
			sanitizedEvent,
			user?.school as string,
		);
		if (response) {
			if (response.responseLabel === "success") {
				Success(response.message);
				dispatchEvent(actions.resetEventToCreate());
				dispatchEvent(actions.setInitialized(false));
				navigateTo("/events");
			} else {
				ErrorToast(`Une erreur est survenue : ${response.message}`);
			}
			setSendLoading(false);
		}
	};

	const handleDeleteEvent = async () => {
		const response = await deleteEventRequest(
			targetEvent?.id,
			targetEvent?.type,
		);
		if (response) {
			if (response.responseLabel === "success") {
				Success(response.message);
				dispatchEvent(actions.resetEventToCreate());
				dispatchEvent(actions.setInitialized(false));
				navigateTo("/events");
			} else {
				ErrorToast(`Une erreur est survenue : ${response.message}`);
			}
		}
	};
	return (
		<div
			className="flex w-full flex-col items-center justify-end gap-4 sm:flex-row"
			data-testid="eventSubmitButtonSection"
		>
			{targetEvent && (
				<InlineButton
					id="delete-event-button"
					label="Supprimer l'évènement"
					icon={trash2Outline}
					iconPosition="left"
					className="[&>*]:!text-error-dark"
					onClick={() => setOpenDeleteModal(true)}
				/>
			)}
			<div className="max-w-[105px]">
				<ButtonSecondary
					label="Annuler"
					onClick={() => handleAbortCreation()}
				/>
			</div>
			<TooltipContainer
				anchorId={"#creationbutton"}
				makeClickable={false}
				children={buildTooltipFromStackErrors(
					checkIfEventCouldBeCreated(eventToCreate),
				)}
				place="top"
			/>
			<ButtonPrimary
				id="creationbutton"
				label={targetEvent ? "Modifier" : "Publier"}
				onClick={targetEvent ? handleModifyEvent : handleCreateEvent}
				disabled={
					(eventToCreate && checkIfEventCouldBeCreated(eventToCreate))
						?.length !== 0 || sendLoading
				}
			/>
			<Modal
				title={`Supprimer ${targetEvent?.title}`}
				id="deleteEventModal"
				buttonsRight={[
					<ButtonSecondary
						label="Annuler"
						onClick={() => setOpenDeleteModal(false)}
					/>,
					<ButtonPrimary
						label="Confimer la suppression"
						onClick={() => {
							handleDeleteEvent();
							setOpenDeleteModal(false);
						}}
						disabled={confirmDelete !== WORD_CONFIRMATION}
					/>,
				]}
				show={openDeleteModal}
				onClose={() => setOpenDeleteModal(false)}
				body={<DeleteConfirmationModal setConfirmDelete={setConfirmDelete} />}
			/>
			{checkIfEventCouldBeCreated(eventToCreate)?.length !== 0 && (
				<TooltipElement id="creationbutton" type="alert" />
			)}
		</div>
	);
}
