import type { BackgroundColorType } from "@/types/colorsType";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "@assets/sass/ToggleButtonGroup.scss";

import { ToggleButtonTertiary } from "./ToggleButton";

type ToggleButtonGroupType = {
	buttons: any;
	selectedIndex: number;
	backgroundColor?: BackgroundColorType | "none";
	className: string;
	disabled?: boolean;
};

export function ToggleButtonGroupLarge({
	buttons,
	selectedIndex = -1,
	className = "",
	backgroundColor = "bg-white",
	disabled = false,
}: ToggleButtonGroupType) {
	const [activeButton, setActiveButton] = useState<number>();

	const handleOnClick = (onClick: Function, index: number) => {
		if (disabled) return;
		setActiveButton(index);
		onClick();
	};

	useEffect(() => {
		setActiveButton(selectedIndex);
	}, [selectedIndex]);

	return (
		<div
			data-id="toggleButtonGroupLarge"
			className={`toggle-button-group-large ${backgroundColor} ${
				backgroundColor !== "none" && "shadow-lg"
			} ${className}`}
		>
			{buttons.map((btn: any, index: number) => (
				<ToggleButtonTertiary
					key={index}
					onClick={() => {
						if (buttons.length === 1 && index === activeButton) {
							handleOnClick(btn.onClick, -1);
						} else {
							handleOnClick(btn.onClick, index);
						}
					}}
					active={activeButton === index && !disabled}
					icon={btn.icon}
					label={btn.label}
				/>
			))}
		</div>
	);
}

ToggleButtonGroupLarge.propTypes = {
	buttons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	selectedIndex: PropTypes.number,
	backgroundColor: PropTypes.string,
	className: PropTypes.string,
};
