import React from "react";
import Skeleton from "react-loading-skeleton";

import { CardLoading } from "./CardLoading";

export const ColumnLoading = ({
	backgroundColor,
	numberOfCards,
}: {
	backgroundColor: string;
	numberOfCards: number;
}) => {
	return (
		<div
			className={`flex flex-col gap-4 min-w-72 max-w-72 h-full ${backgroundColor} rounded-md`}
		>
			<div className="flex flex-row gap-4 p-4 pb-0 cursor-pointer">
				<span className="flex">
					<Skeleton inline height={24} width={24} circle />
				</span>
				<span className="flex items-center font-bold">
					<Skeleton inline width={80} height={24} />
				</span>
				<span className="flex items-center font-semibold text-gray-500">
					<Skeleton inline width={20} height={24} />
				</span>
			</div>
			<div className={"flex flex-1 flex-col overflow-y-auto p-4 pt-0"}>
				{Array.from({ length: numberOfCards }, (_, i) => (
					<CardLoading key={i} />
				))}
			</div>
		</div>
	);
};
