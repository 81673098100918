export const ITEM_PER_PAGE_OPTIONS: number[] = [10, 20, 50, 100];
export const STUDENT_MANAGEMENT_TABS = ["all", "active", "waitConnection"];
export const CV_BOARD_TABS = [
	"all",
	"validated",
	"toValidate",
	"editing",
	"without",
];

export const OFFERS_MANAGEMENT_TABS = [
	"online",
	"provided",
	"finiched",
	"draft",
];

export const PAGES_WITH_TABLE = [
	{ id: 0, path: "students", tabs: STUDENT_MANAGEMENT_TABS },
	{ id: 1, path: "cvboard", tabs: CV_BOARD_TABS },
	{ id: 2, path: "offers", tabs: OFFERS_MANAGEMENT_TABS },
];
