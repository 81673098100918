import React, { useEffect, useState } from "react";

import { ScrollableTabGroup } from "@components/molecules/TabGroup";

import MyCV from "./TabsBlock/MyCV";
import { MyCareer } from "./TabsBlock/MyCareer";
import { MyHistory } from "./TabsBlock/MyHistory";
import { MyProfile } from "./TabsBlock/MyProfile";
import { MySearch } from "./TabsBlock/MySearch";

type TabsBlockType = {
	selectedTab: number;
};

export function TabsBlock({ selectedTab }: TabsBlockType) {
	const [tabIndex, setTabIndex] = useState(0);

	useEffect(() => {
		setTabIndex(selectedTab);
	}, [selectedTab]);

	const returnTab = (index: number) => {
		switch (index) {
			case 1:
				return <MyCV />;
			case 2:
				return <MySearch />;
			case 3:
				return <MyCareer />;
			case 4:
				return <MyHistory />;
			default:
				return <MyProfile />;
		}
	};
	return (
		<div className="w-full rounded-lg bg-white p-md md:w-7/12 xl:w-8/12 flex flex-col gap-lg h-fit">
			<ScrollableTabGroup
				sizeSwitch="lg"
				tabs={[
					{
						label: "Profil",
						active: tabIndex === 0,
					},
					{ label: "Mon CV", active: tabIndex === 1 },
					{ label: "Recherche", active: tabIndex === 2 },
					{ label: "Parcours", active: tabIndex === 3 },
					{ label: "Mon historique", active: tabIndex === 4 },
				]}
				onTabChange={(i) => {
					setTabIndex(i);
				}}
			/>
			{returnTab(tabIndex)}
		</div>
	);
}
