import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { arrowBackOutline } from "@assets/Icons";

import { ButtonQuinary } from "@components/atoms/ButtonQuinary";

import { SchoolLogoAuth } from "../../../components/atoms/SchoolLogoAuth";

export function LoginAce({
	setDisplayAceInterface,
}: {
	setDisplayAceInterface: (display: boolean) => void;
}) {
	useEffect(() => {
		document.title = "Connexion - Mentor Goal";
	}, []);

	return (
		<div className="flex w-full justify-center">
			<div className="w-full md:w-10/12">
				<NavLink to="/" className="mt-sm flex w-full items-center md:w-8/12">
					<ButtonQuinary
						icon={arrowBackOutline}
						onClick={() => {
							setDisplayAceInterface(false);
						}}
					/>
				</NavLink>
				<div className="mt-sm flex w-full flex-col items-center md:mt-lg">
					<p className="mb-lg text-center text-md font-semibold">
						Quel espace école souhaitez-vous utiliser ?
					</p>
					<div
						className={
							"flex w-full flex-col items-center justify-around md:flex-row"
						}
					>
						<SchoolLogoAuth name="AMOS" subdomain="amos" />

						<SchoolLogoAuth name="CMH" subdomain="cmh" />

						<SchoolLogoAuth name="ESDAC" subdomain="esdac" />
					</div>
				</div>
			</div>
		</div>
	);
}
