import axios from "axios";

import { getCookie } from "@tools/Cookies";
import type { Step } from "./index";

const API = axios.create({
	baseURL: `${import.meta.env.VITE_BACK}/`,
	timeout: 20000,
	headers: {
		"Content-Type": "application/json",
		"X-Forwarded-For": getCookie("ip"),
	},
});

export async function getSchoolAndFormBySubdomain(subdomain: string) {
	let rep = {};
	await API.get(`register_for_school/${subdomain}`).then(({ data }) => {
		rep = data;
	});

	return rep;
}

export async function getStepForSchool(subdomain: string, step: number) {
	let rep = {};
	await API.get(`step_for_school/${subdomain}/${step}`).then(({ data }) => {
		rep = data;
	});

	return rep as Step;
}

type sendRegistrationValuesToAPIResponse = {
	code: number;
	message: string;
	token: string;
};
export const sendRegistrationValuesToAPI = async (
	values: object,
	subdomain: string,
) => {
	let rep = {};
	await API.post(`register_for_school/${subdomain}`, values).then(
		({ data }) => {
			rep = data;
		},
	);

	return rep as sendRegistrationValuesToAPIResponse;
};

export async function sendStepValuesToAPI(values: object, token: string) {
	let rep = {};
	await API.post("set_form_data", values, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Forwarded-For": getCookie("ip"),
		},
	}).then(({ data }) => {
		rep = data;
	});

	return rep;
}

export type sendFileResponse = {
	code: number;
	message: string;
	filePath: string;
};

async function sendFile(formdata: FormData, fieldName: string, token: string) {
	let rep = {};

	await API.post(`/upload_file_register_form/${fieldName}`, formdata, {
		headers: {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
			"X-Forwarded-For": getCookie("ip"),
		},
	}).then(({ data }) => {
		rep = data;
	});
	return rep as sendFileResponse;
}

export const uploadFile = (
	file: Blob,
	fieldName: string,
	token: string,
	prospectId = 0,
) => {
	const formData = new FormData();
	formData.append("file", file);
	if (prospectId !== 0) {
		formData.append("prospectId", String(prospectId));
	}
	return sendFile(formData, fieldName, token);
};
