import type { EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import { sortedEventsByDate } from "@tools/Events";
import type { EventsState } from "../../core/store/events.slice";

export const useEventsList = () => {
	const { isFetchingData, initialized } = useAppSelector(
		(state: AppState) => state.events as EventsState,
	);

	const getSortedEvents = (events: EventType[], listID: string) => {
		return listID !== "planned"
			? sortedEventsByDate(events).reverse()
			: sortedEventsByDate(events);
	};

	return {
		isFetchingData,
		initialized,
		showSkeleton: isFetchingData || !initialized,
		getSortedEvents,
	};
};
