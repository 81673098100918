import { Icon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

import "../StudentDetail.scss";

import {
	birthdayCake,
	briefcaseOutline,
	calendarOutline,
	clockOutline,
	cloudUploadOutline,
	emailOutline,
	globe2Outline,
	link2Outline,
	linkedinFill,
	moreHorizontalOutline,
	phoneOutline,
	pinOutline,
	searchOutline,
} from "@assets/Icons";

import { SEARCH_DISTANCE_OPTIONS } from "@constants/Others";
import { STUDENT_STATUS_OPTIONS } from "@constants/Users";

import { getAgeFromBirthdate } from "@tools/Dates";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonQuinary } from "@components/atoms/ButtonQuinary";
import { Dropdown } from "@components/molecules/Dropdown";
import { Collapse } from "@components/organisms/Collapse";
import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import {
	editUser,
	exportStudentsToGallia,
	getStudentGalliaStatus,
	sendFile,
} from "@containers/school/Students/StudentDetail/StudentDetailAPI";

import { Badge } from "@components/atoms/Badge";

import { EStatus } from "@/types/userTypes";
import { DeleteStudentModal } from "./Profile/DeleteStudentModal";
import { FormikStudentProfile } from "./Profile/FormikStudentProfile";
import { GalliaDiffStudentModal } from "./Profile/GalliaDiffStudentModal";

const STUDENT_IMPORTED_ON_GALLIA = 1;
const STUDENT_NOT_IMPORTED_ON_GALLIA = 0;
const STUDENT_NOT_SYNCED_ON_GALLIA = 2;

export function Profile({ student, promotions }) {
	const location = useLocation();
	const [displayModify, setDisplayModify] = useState(false);
	const [displayDelete, setDisplayDelete] = useState(false);
	const [displayGalliaDiff, setDisplayGalliaDiff] = useState(false);
	const [galliaDiff, setGalliaDiff] = useState({});
	const [studentCpy, setStudentCpy] = useState({});
	const [defaultPromotion, setDefaultPromotion] = useState({});
	const [galliaStatus, setGalliaStatus] = useState(-1);
	const [currentStatus, setCurrentStatus] = useState({
		label: "À placer",
		value: EStatus.TOPLACE,
		icon: clockOutline,
		color: "#F4A261",
	});

	const updateStatus = async (newStatus) => {
		const data = {
			id: studentCpy.id,
			status: newStatus,
		};
		await editUser(data);
		setStudentCpy({ ...studentCpy, ...data });
	};

	const syncStudentToGallia = async () => {
		const studentId = location.pathname.split("/")[2];
		exportStudentsToGallia([studentId]).then((response) => {
			switch (response[studentId].code) {
				case 200:
					toast.success("Étudiant importé sur Gallia");
					setGalliaStatus(STUDENT_IMPORTED_ON_GALLIA);
					break;
				case 400:
					toast.warn("Étudiant déjà importé sur Gallia");
					setGalliaStatus(STUDENT_IMPORTED_ON_GALLIA);
					break;
				default:
					toast.error("Erreur lors de l'import sur Gallia");
					setGalliaStatus(STUDENT_NOT_IMPORTED_ON_GALLIA);
					break;
			}
		});
	};

	const uploadFile = async (file, fileName) => {
		const formData = new FormData();
		formData.append("file", file);
		sendFile(formData).then((response) => {
			const data = {
				id: studentCpy.id,
			};
			if (fileName === "avatar") {
				data.avatar = response["@id"];
			}
			editUser(data);
			setStudentCpy({ ...studentCpy, avatar: response });
		});
	};

	const deleteFile = (fileName) => {
		const data = {
			id: studentCpy.id,
		};
		if (fileName === "avatar") {
			data.avatar = null;
		}
		editUser(data);
		setStudentCpy({ ...studentCpy, ...data });
	};

	const getJobContractSearched = () => {
		if (studentCpy?.contractSearched === 2) {
			return "CDI";
		}
		if (studentCpy?.contractSearched === 4) {
			return "Stage";
		}
		if (
			studentCpy?.contractSearched === 1 ||
			studentCpy?.contractSearched === 3
		) {
			return "Alternance";
		}
		return null;
	};

	const dropdownItems = [
		{
			label: "Modifier ses informations",
			action: () => {
				setDisplayModify(!displayModify);
			},
		},
		{
			label: "Supprimer",
			action: () => {
				setDisplayDelete(!displayDelete);
			},
			closeIt: true,
		},
	];

	function windowOpen(url) {
		if (!url.match(/^https?:\/\//i)) {
			url = `http://${url}`;
		}
		return window.open(url, "_blank");
	}

	useEffect(() => {
		if (student.id) {
			setStudentCpy(student);
			setCurrentStatus(
				STUDENT_STATUS_OPTIONS.find((x) => x.value === student.status),
			);
			setDefaultPromotion(
				promotions.find((promo) => {
					return promo.value === student.spsPromotion.name;
				}),
			);
		}
	}, [student, promotions]);

	useEffect(() => {
		setGalliaStatus(-1);
		if (studentCpy.campus?.isGalliaEnabled) {
			getStudentGalliaStatus(location.pathname.split("/")[2]).then(
				(response) => {
					if (response.code === 200) {
						setGalliaStatus(
							response.isSynced
								? STUDENT_IMPORTED_ON_GALLIA
								: STUDENT_NOT_SYNCED_ON_GALLIA,
						);
						setGalliaDiff(response.diff);
					} else if (response.code === 404) {
						setGalliaStatus(STUDENT_NOT_IMPORTED_ON_GALLIA);
					}
				},
			);
		}
	}, [studentCpy]);

	return (
		<div className="profile-container w-full p-0">
			<div className="profile-header">
				<Dropdown
					button={<ButtonQuinary dropdownButton icon={moreHorizontalOutline} />}
					items={dropdownItems}
				/>
			</div>

			<div>
				<section className="profile-top">
					<Avatar
						modify={displayModify}
						image={studentCpy?.avatar?.filePath}
						firstname={studentCpy?.firstname}
						lastname={studentCpy?.lastname}
						size={24}
						onChange={(e) => {
							uploadFile(e.file, "avatar");
						}}
						onDelete={() => {
							deleteFile("avatar");
						}}
					/>
					<p className="wg-no-translate name flex flex-wrap justify-center px-sm text-center">
						{`${studentCpy.firstname?.trim()} ${studentCpy.lastname?.trim()} `}
						<span className="ml-xsm">- {studentCpy?.spsPromotion?.name}</span>
					</p>
					{studentCpy.birthdate && (
						<p className="flex text-xxxsm text-primary-300">
							<Icon className="h-4 text-primary-300" icon={birthdayCake} />{" "}
							{getAgeFromBirthdate(studentCpy.birthdate)} ans -{" "}
							{moment(studentCpy.birthdate).format("DD/MM/YYYY")}
						</p>
					)}

					{(student.linkedin ||
						student.portfolio ||
						student.customLink1 ||
						student.customLink2 ||
						student.campus.isGalliaEnabled) && (
						<div className="mt-xsm flex items-center gap-4">
							{student.campus.isGalliaEnabled && (
								<Tippy
									className="truncate"
									content={"Importer l'etudiant sur Gallia"}
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonQuaternary
											icon={cloudUploadOutline}
											onClick={() => {
												if (galliaStatus === STUDENT_NOT_SYNCED_ON_GALLIA) {
													setDisplayGalliaDiff(true);
													return;
												}
												syncStudentToGallia();
											}}
											id="add-to-gallia"
										/>
									</span>
								</Tippy>
							)}
							{studentCpy.linkedin && (
								<ButtonQuaternary
									icon={linkedinFill}
									onClick={() => {
										windowOpen(studentCpy.linkedin);
									}}
								/>
							)}
							{studentCpy.portfolio && (
								<ButtonQuaternary
									icon={globe2Outline}
									onClick={() => {
										windowOpen(studentCpy.portfolio);
									}}
								/>
							)}
							{studentCpy.customLink1 && (
								<Tippy
									className="truncate"
									content={studentCpy.customLink1}
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonQuaternary
											icon={link2Outline}
											onClick={() => {
												windowOpen(studentCpy.customLink1);
											}}
										/>
									</span>
								</Tippy>
							)}
							{studentCpy.customLink2 && (
								<Tippy
									className="truncate"
									content={studentCpy.customLink2}
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonQuaternary
											icon={link2Outline}
											onClick={() => {
												windowOpen(studentCpy.customLink2);
											}}
										/>
									</span>
								</Tippy>
							)}
						</div>
					)}
				</section>
				{!displayModify && (
					<section className="profile-bottom">
						<div className="flex justify-center">
							<SelectSecondary
								className="mt-sm"
								defaultValue={() => {
									return STUDENT_STATUS_OPTIONS.find(
										(x) => x.value === currentStatus.value,
									);
								}}
								name="status"
								options={STUDENT_STATUS_OPTIONS}
								onChange={(e) => {
									setCurrentStatus(e);
									updateStatus(e.value);
								}}
							/>
						</div>
						{student?.createdAt && (
							<p className="mt-md text-center text-xxxsm text-primary-500">
								Étudiant créé le{" "}
								{moment(student?.createdAt).format("DD/MM/YYYY")}
							</p>
						)}
						{student?.campus?.isGalliaEnabled && (
							<div className="mt-md w-full flex items-center justify-center">
								{galliaStatus === STUDENT_IMPORTED_ON_GALLIA && (
									<Badge
										label="Étudiant importé sur Gallia"
										bgColor={"bg-success-light"}
										hasBorder
									/>
								)}
								{galliaStatus === STUDENT_NOT_IMPORTED_ON_GALLIA && (
									<Badge
										label="Étudiant non importé sur Gallia"
										bgColor={"bg-error-light"}
										hasBorder
									/>
								)}
								{galliaStatus === STUDENT_NOT_SYNCED_ON_GALLIA && (
									<Badge
										label="Étudiant non synchronisé sur Gallia"
										bgColor={"bg-warning-light"}
										hasBorder
									/>
								)}
								{galliaStatus === -1 && <span>Chargement...</span>}
							</div>
						)}
						<Collapse
							className="mt-xxlg"
							title="Sa recherche"
							index={1}
							body={
								<div className="pl-sm">
									<div className="mt-sm flex items-center">
										<Icon
											className="h-4 text-primary-500"
											icon={searchOutline}
										/>
										{studentCpy?.contractSearched ? (
											<p className="ml-sm text-xsm text-primary-700P">
												{`${getJobContractSearched()} 
                        `}
											</p>
										) : (
											<p className="ml-sm text-xsm font-bold text-primary-200">
												Non renseigné
											</p>
										)}
									</div>
									<div className="mt-xsm flex items-center">
										<Icon
											icon={briefcaseOutline}
											className="h-4 text-primary-500"
										/>
										{studentCpy?.desiredJob ? (
											<p className="ml-sm text-xsm text-primary-700P">
												{studentCpy?.desiredJob}
											</p>
										) : (
											<p className="ml-sm text-xsm font-bold text-primary-200">
												Non renseigné
											</p>
										)}
									</div>
									<div className="mt-xsm flex items-center">
										<Icon
											className="h-4 text-primary-500"
											icon={calendarOutline}
										/>
										{studentCpy?.desiredStartOfWork ? (
											<p className="ml-sm text-xsm text-primary-700P">
												{moment(
													studentCpy?.desiredStartOfWork
														?.split("/")
														.reverse()
														.join("-"),
												).format("DD/MM")}
											</p>
										) : (
											<p className="ml-sm text-xsm font-bold text-primary-200">
												Non renseigné
											</p>
										)}
									</div>

									<div className="mt-xsm flex items-center">
										<Icon className="h-4 text-primary-500" icon={pinOutline} />
										{studentCpy?.geoAreaOfResearch ? (
											<p className="ml-sm text-xsm text-primary-700P">
												{studentCpy?.geoAreaOfResearch} -{" "}
												{SEARCH_DISTANCE_OPTIONS.find((option) => {
													return option.value === studentCpy?.mobilityRadius;
												})}
											</p>
										) : (
											<p className="ml-sm text-xsm font-bold text-primary-200">
												Non renseigné
											</p>
										)}
									</div>
								</div>
							}
						/>

						<Collapse
							className="mt-sm"
							title="Ses coordonnées"
							index={2}
							body={
								<div className="pl-sm">
									<section>
										<div className="mt-sm flex items-center">
											<Icon
												className="h-4 text-primary-500"
												icon={emailOutline}
											/>
											{studentCpy.email ? (
												<p className="ml-sm text-xsm text-primary-700P">
													{studentCpy.email}
												</p>
											) : (
												<p className="ml-sm text-xsm font-bold text-primary-200">
													Non renseigné
												</p>
											)}
										</div>
										<div className="mt-xsm flex items-center">
											<Icon
												className="h-4 text-primary-500"
												icon={phoneOutline}
											/>
											{studentCpy.phone ? (
												<p className="ml-sm text-xsm text-primary-700P">
													{`+${studentCpy.phone.slice(
														0,
														studentCpy.phone.length - 9,
													)} ${studentCpy.phone.slice(-9)}`}
												</p>
											) : (
												<p className="ml-sm text-xsm font-bold text-primary-200">
													Non renseigné
												</p>
											)}
										</div>
										<div className="mt-xsm flex items-center">
											<Icon
												icon={pinOutline}
												className="h-4 text-primary-500"
											/>
											{studentCpy.address ? (
												<p className="ml-sm text-xsm text-primary-700P">
													{studentCpy.address}
												</p>
											) : (
												<p className="ml-sm text-xsm font-bold text-primary-200">
													Non renseigné
												</p>
											)}
										</div>
									</section>
								</div>
							}
						/>
					</section>
				)}
			</div>
			<FormikStudentProfile
				studentCpyProps={{ studentCpy, setStudentCpy }}
				currentStatusProps={{ currentStatus, setCurrentStatus }}
				defaultPromotionProps={{ defaultPromotion, setDefaultPromotion }}
				promotions={promotions}
				show={displayModify}
				onClose={() => {
					setDisplayModify(!displayModify);
				}}
			/>
			<DeleteStudentModal
				show={displayDelete}
				onClose={() => {
					setDisplayDelete(false);
				}}
				student={student}
			/>
			<GalliaDiffStudentModal
				diff={galliaDiff}
				show={displayGalliaDiff}
				onClose={() => {
					setDisplayGalliaDiff(false);
				}}
				syncStudentToGallia={syncStudentToGallia}
			/>
		</div>
	);
}

Profile.propTypes = {
	student: PropTypes.shape({
		spsPromotion: PropTypes.shape({ name: PropTypes.string }),
		id: PropTypes.string,
		firstname: PropTypes.string,
		lastname: PropTypes.string,
		linkedin: PropTypes.string,
		portfolio: PropTypes.string,
		customLink1: PropTypes.string,
		customLink2: PropTypes.string,
		dateOfInvitation: PropTypes.string,
		status: PropTypes.string,
		createdAt: PropTypes.string,
		avatar: PropTypes.shape({
			filePath: PropTypes.string,
			id: PropTypes.string,
		}),
	}).isRequired,
	promotions: PropTypes.arrayOf({}).isRequired,
};
