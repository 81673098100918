import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const verifyCompanySiretExistance =
	(siret: string) =>
	async (
		dispatch: AppDispatch,
		_api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.companyInformationsGateway) {
				throw new Error(
					"companyInformationsGateway is not available in dependencies",
				);
			}
			const result =
				await dependencies.companyInformationsGateway.verifyCompanySiretExistance(
					siret,
				);
			if (result.length > 0) {
				dispatch(
					companiesManagementSlice.actions.setCompanySiretAlreadyExist(true),
				);
				return true;
			}
			dispatch(
				companiesManagementSlice.actions.setCompanySiretAlreadyExist(false),
			);
			return false;
		} catch (_error: unknown) {
			//If we dont know, we consider that the siret is already taken
			return true;
		}
	};
