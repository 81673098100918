import type { EntCompanySchoolProfileType } from "@/types/EntCompanyTypes";
import type { UserType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";

import lookingForInvites from "@assets/illustrations/entreprise/loupe-404.svg";

import { stringifyRequestInputs } from "@tools/Objects";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";
import { Success } from "@components/organisms/toast/ToastNotification";
import { StudentsTargetingModal } from "@components/templates/studentsTargetingModal";
import { Table } from "@components/templates/table/Table";

import type { MediaObjectType } from "@/types/mediaObjectTypes";
import {
	createSelection,
	queryAccounts,
	updateAccount,
} from "../../../PartnersAPI";

export function AccountsTab() {
	const [profiles, setProfiles] = useState<EntCompanySchoolProfileType[]>([]);
	const [load, setLoad] = useState(true);
	const [displayTargetingModal, setDisplayTargetingModal] = useState(false);
	const [loadingProfile, setLoadingProfile] = useState<null | string>(null);
	const [selectedProfile, setSelectedProfile] = useState<null | string>(null);
	// const [showActions, setShowActions] = useState<false | string>(false);
	const [currentModalAction, setCurrentModalAction] = useState<
		"hide" | "refuse" | "remove" | undefined
	>(undefined);
	const { structureView }: { structureView: object } = useOutletContext();
	const getProfiles = async () => {
		try {
			const profiles = await queryAccounts();
			const sortedProfiles: EntCompanySchoolProfileType[] = [];
			for (const item of profiles.filter(
				(profile: EntCompanySchoolProfileType) => {
					return !profile.selectionCvtk;
				},
			)) {
				sortedProfiles.push(item);
			}
			for (const item of profiles.filter(
				(profile: EntCompanySchoolProfileType) => {
					return profile.selectionCvtk && profile.selectionCvtk !== "refused";
				},
			)) {
				sortedProfiles.push(item);
			}
			for (const item of profiles.filter(
				(profile: EntCompanySchoolProfileType) => {
					return profile.selectionCvtk === "refused";
				},
			)) {
				sortedProfiles.push(item);
			}
			setProfiles(sortedProfiles);
			setLoad(false);
		} catch {
			Error("Une erreur est survenue");
		}
	};

	const modalData = {
		hide: {
			title: "Confirmer la suppression ?",
			content: "Êtes-vous sûr de vouloir supprimer ce profil ?",
			irreversible: true,
		},
		remove: {
			title: "Confirmer la suppression ?",
			content: "Êtes-vous sûr de vouloir retirer l'accès cette entreprise ?",
			irreversible: false,
		},
		refuse: {
			title: "Confirmer le refus ?",
			content: "Êtes-vous sûr de vouloir refuser l'accès cette entreprise ?",
			irreversible: true,
		},
	};

	const updateProfilesFront = (data: Partial<EntCompanySchoolProfileType>) => {
		const indexToUpdate = profiles.findIndex((profile) => {
			return profile.id === data.id;
		});
		for (const [key, value] of Object.entries(data)) {
			if (key !== "id" && key !== "display") {
				// @ts-ignore
				profiles[indexToUpdate][key] = value;
			} else if (key === "display" && value === false) {
				delete profiles[indexToUpdate];
			}
			if (key === "selectionCvtk" && value === "refused") {
				profiles.push(profiles.splice(indexToUpdate, 1)[0]);
			}
		}
	};

	const updateProfile = async (data: Partial<EntCompanySchoolProfileType>) => {
		setLoadingProfile(data.id!);
		try {
			await updateAccount(data);
			Success("Compte mis à jour");
			updateProfilesFront(data);
		} catch {
			Error("Une erreur est survenue");
		} finally {
			setLoadingProfile(null);
		}
	};
	const generateSelection = async (users: UserType[], profileId: string) => {
		setLoadingProfile(profileId);
		const name = profiles.find((item) => {
			return item.id === profileId;
		})!.entCompany.name;
		const inputs = {
			name: `Sélection de ${name}`,
			users: users.map((student) => {
				return student.id;
			}),
		};
		const rep: Partial<EntCompanySchoolProfileType> = await createSelection(
			stringifyRequestInputs(inputs),
			profileId,
		);
		Success(`Sélection partagée (${users.length} profils)`);
		updateProfilesFront(rep);
		setLoadingProfile(null);
	};

	useEffect(() => {
		getProfiles();
	}, []);

	const cellsResolver = (profile: EntCompanySchoolProfileType) => {
		return [
			{
				children: (
					<div className="flex items-center gap-xxsm">
						<Avatar
							size="xs"
							firstname={profile.entCompany?.name as string}
							lastname={profile.entCompany?.name?.slice(1) || ""}
							image={
								(profile.entCompany.logo as MediaObjectType)?.filePath || null
							}
						/>
						<p className="font-bold">{profile.entCompany.name}</p>
					</div>
				),
			},
			// {
			//   children: (
			//     <div className="flex">
			//       {getProfileStatusLabel(profile.selectionCvtk)}
			//     </div>
			//   ),
			// },
			{
				children: (
					<div>
						<p>
							{/* eslint-disable-next-line no-irregular-whitespace */}
							• {(profile.entCompany?.createdBy as UserType).firstname}{" "}
							{(profile.entCompany?.createdBy as UserType).lastname}
						</p>
						{/* eslint-disable-next-line no-irregular-whitespace */}
						<p>• {(profile.entCompany?.createdBy as UserType).email}</p>
					</div>
				),
			},
			{
				children: (
					<div>
						<p className="font-bold">
							{profile.entCompany.address}, {profile.entCompany.city}
						</p>
					</div>
				),
			},
			// {
			//   children:
			//     !profile.selectionCvtk || profile.selectionCvtk !== 'refused' ? (
			//       <div className="flex gap-sm">
			//         {!profile.selectionCvtk && (
			//           <>
			//             <ButtonPrimary
			//               disabled={loadingProfile === profile.id}
			//               icon={closeOutline}
			//               onClick={async () => {
			//                 setSelectedProfile(profile.id);
			//                 setCurrentModalAction('refuse');
			//               }}
			//             />
			//             <ButtonPrimary
			//               disabled={loadingProfile === profile.id}
			//               icon={checkmarkOutline}
			//               onClick={async () => {
			//                 setSelectedProfile(profile.id);
			//                 setDisplayTargetingModal(true);
			//               }}
			//             />
			//           </>
			//         )}
			//         {profile.selectionCvtk && (
			//           <>
			//             <ButtonPrimary
			//               disabled={loadingProfile === profile.id}
			//               icon={trash2Outline}
			//               onClick={async () => {
			//                 setSelectedProfile(profile.id);
			//                 setCurrentModalAction('remove');
			//               }}
			//             />
			//             <ButtonPrimary
			//               disabled={loadingProfile === profile.id}
			//               icon={editOutline}
			//               onClick={async () => {
			//                 setSelectedProfile(profile.id);
			//                 setDisplayTargetingModal(true);
			//               }}
			//             />
			//             <ButtonTertiary
			//               disabled={loadingProfile === profile.id}
			//               icon={link2Outline}
			//               onClick={() => {
			//                 window.open(
			//                   `${import.meta.env.VITE_URL}/selection/subdomain/${profile.selectionCvtk}`
			//                 );
			//               }}
			//             />
			//           </>
			//         )}
			//       </div>
			//     ) : (
			//       <p>N/A</p>
			//     ),
			// },
			// {
			//   children: (
			//     <div className="relative flex">
			//       <ButtonTertiary
			//         icon={moreVerticalFill}
			//         onClick={() => {
			//           if (showActions === profile.id) {
			//             setShowActions(false);
			//           } else {
			//             setShowActions(profile.id);
			//           }
			//         }}
			//       />
			//       {showActions === profile.id && (
			//         <div className="absolute bottom-full right-0 min-w-[140px] rounded-sm bg-white p-xxsm shadow-sm">
			//           <button
			//             className="w-full rounded-sm px-xxsm transition hover:bg-primary-50"
			//             onClick={async () => {
			//               setSelectedProfile(profile.id);
			//               setCurrentModalAction('hide');
			//             }}
			//           >
			//             Supprimer le compte
			//           </button>
			//         </div>
			//       )}
			//     </div>
			//   ),
			// },
		];
	};

	const headers = [
		{
			label: "ENTREPRISE",
		},
		// {
		//   label: 'ACCÈS CVTHÈQUE',
		// },
		{
			label: "CONTACT",
		},
		{
			label: "LOCALISATION",
		},
		// { label: "MODIFIER L'ACCÈS" },
		// { label: 'ACTION' },
	];

	return (
		<div className="w-full rounded-md bg-white pt-sm">
			<p className="px-sm font-bold">Comptes créés</p>
			{profiles.length === 0 && !load ? (
				<div className="p-sm">
					<img
						src={lookingForInvites}
						alt="Aucun compte récente"
						className="mx-auto h-[162px] w-[162px]"
					/>
					<p className="text-center">Aucun compte pour le moment</p>
				</div>
			) : (
				<Table
					isLoading={load}
					filtersProps={
						{
							// filters: currentFilters,
							// setFilters: handleTableSetCurrentFilters,
						}
					}
					rows={profiles.map((company) => {
						return {
							cells: cellsResolver(company),
						};
					})}
					headers={headers}
				/>
			)}
			<StudentsTargetingModal
				show={displayTargetingModal}
				onClose={() => {
					setDisplayTargetingModal(false);
					setSelectedProfile(null);
				}}
				structureView={structureView}
				title="Sélection à partager"
				label="Vous pouvez sélectionner des profils d'étudiants à partager avec cette entreprise."
				buttonLabel="Valider la sélection"
				share={async (_, __, selectedStudents) => {
					await generateSelection(selectedStudents, selectedProfile!);
				}}
			/>
			<Modal
				show={currentModalAction !== undefined}
				onClose={() => {
					setCurrentModalAction(undefined);
					setSelectedProfile(null);
				}}
				title={
					currentModalAction !== undefined &&
					modalData[currentModalAction!].title
				}
				body={
					<p>
						{currentModalAction !== undefined &&
							modalData[currentModalAction!].content}

						{currentModalAction !== undefined &&
							modalData[currentModalAction!].irreversible && (
								<>
									<br />
									Cette action est irréversible
								</>
							)}
					</p>
				}
				buttonsRight={[
					<ButtonSecondary
						key={0}
						label="Non, annuler"
						onClick={() => {
							setCurrentModalAction(undefined);
							setSelectedProfile(null);
						}}
						disabled={loadingProfile === selectedProfile}
					/>,
					<ButtonPrimary
						disabled={loadingProfile === selectedProfile}
						key={1}
						label="Oui, confirmer"
						onClick={async () => {
							const data: Partial<EntCompanySchoolProfileType> = {
								id: selectedProfile!,
							};
							currentModalAction === "hide"
								? (data.display = false)
								: currentModalAction === "remove"
									? (data.selectionCvtk = null)
									: (data.selectionCvtk = "refused");
							await updateProfile(data);
							setCurrentModalAction(undefined);
							setSelectedProfile(null);
						}}
					/>,
				]}
			/>
		</div>
	);
}
