import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SlidingPane from "react-sliding-pane";

import { ScrollableTabGroup } from "@components/molecules/TabGroup";
import { CreateStudentModal } from "@components/organisms/CreateStudentModal";

import {
	getAtsData,
	getProspect,
	setProspectNotOpened as setProspectNotOpenedAPI,
	updateProspectsPosition,
} from "./AtsAPI";
import {
	type CardType,
	type FieldType,
	Kanban,
	type ProspectType,
} from "./ui/Kanban";
import type { ColumnType } from "./ui/Kanban/Column";
import { KanbanLoading } from "./ui/Kanban/KanbanLoading";
import { SlidingPaneContent } from "./ui/SlidingPane/SlidingPaneContent";
import { SlidingPaneHeader } from "./ui/SlidingPane/SlidingPaneHeader";

export const CurrentProspectContext = createContext<{
	currentProspect: ProspectType | null;
	setCurrentProspect: (prospect: ProspectType | null) => void;
}>({
	currentProspect: null,
	setCurrentProspect: () => {},
});

export const ColumnsContext = createContext<ColumnType[]>([]);

export const UpdateProspectsColumnContext = createContext<
	(cardId: number, dInd: number, destinationIndex: number, sInd: number) => void
>(() => {});

export const OnSlidingPanelCloseContext = createContext<() => void>(() => {});

export const RemoveProspectContext = createContext<
	(prospectId: number) => void
>(() => {});

export const SetProspectNotOpenedContext = createContext<(id: number) => void>(
	() => {},
);

export const FieldsContext = createContext<FieldType[]>([]);

export function Ats() {
	const navigate = useNavigate();
	const [cards, setCards] = useState<CardType[]>([]);
	const [columns, setColumns] = useState<ColumnType[]>([]);
	const [fields, setFields] = useState<FieldType[]>([]);
	const [selectedProspect, setSelectedProspect] = useState<ProspectType | null>(
		null,
	);
	const [displayNewStudent, setDisplayNewStudent] = useState<CardType | null>(
		null,
	);
	const [cardsToUpdate, setCardsToUpdate] = useState<CardType[]>([]);

	useEffect(() => {
		document.title = "Gestion des prospects - Mentor Goal";
	}, []);
	const onCardClick = (card: CardType) => {
		getProspect(card.id).then((prospect) => {
			const bodyElement = document.getElementsByTagName("body")[0];
			bodyElement.style.overflow = "hidden";
			setSelectedProspect(prospect);
		});
		const newCards = cards.map((c) => {
			if (c.id === card.id) {
				c.hasBeenOpened = true;
			}
			return c;
		});
		setCards(newCards);
	};

	const setProspectNotOpened = async (id: number) => {
		const newCards = cards.map((c) => {
			if (c.id === id) {
				c.hasBeenOpened = false;
			}
			return c;
		});
		setCards(newCards);
		await setProspectNotOpenedAPI(id);
	};

	const onSlidingPanelClose = () => {
		const bodyElement = document.getElementsByTagName("body")[0];
		bodyElement.style.overflow = "auto";
		setSelectedProspect(null);
	};

	const reorderProspects = (
		sourceIndex: number,
		destinationIndex: number,
		sInd: number,
	) => {
		const newCards = Array.from(cards.filter((p) => p.columnId === sInd));
		const [removed] = newCards.splice(sourceIndex, 1);
		newCards.splice(destinationIndex, 0, removed);
		if (newCards.length !== 0) {
			newCards.forEach((card, index) => {
				card.position = index;
			});
		}
		if (newCards.length > 1) updateProspectsPosition(newCards);
		setCards([
			...cards.filter((prospect) => prospect.columnId !== sInd),
			...newCards,
		]);
	};

	const updateProspectsColumn = (
		cardId: number,
		dInd: number,
		destinationIndex: number,
		sInd: number,
	) => {
		const card = cards.filter((prospect) => prospect.id === cardId)[0];
		card.columnId = dInd;
		card.position = destinationIndex;
		const destinationCards = cards.filter(
			(prospect) => prospect.columnId === dInd && prospect.id !== card.id,
		);
		destinationCards.splice(destinationIndex, 0, card);
		destinationCards.forEach((card, index) => {
			card.position = index;
		});

		const sourceCards = cards.filter(
			(prospect) => prospect.columnId === sInd && prospect.id !== card.id,
		);
		sourceCards.forEach((card, index) => {
			card.position = index;
		});
		if (dInd === 5) {
			setCardsToUpdate([...destinationCards, ...sourceCards]);
			setDisplayNewStudent(card);
		} else {
			updateProspectsPosition([...destinationCards, ...sourceCards]);
		}
		setCards([
			...cards.filter(
				(prospect) => prospect.columnId !== dInd && prospect.columnId !== sInd,
			),
			...destinationCards,
			...sourceCards,
		]);
	};

	const removeProspect = (prospectId: number) => {
		const newCards = cards.filter((prospect) => prospect.id !== prospectId);
		setCards(newCards);
	};

	useEffect(() => {
		const fetchData = async () => {
			const data = await getAtsData();
			setColumns(data.columns);
			// TODO: Show cards in reverse order From position
			setCards(data.cards.sort((a, b) => a.position - b.position));
			setFields(data.fields);
		};
		fetchData();
	}, []);

	return (
		<main className="flex flex-1 flex-col h-[80vh] gap-4">
			<ScrollableTabGroup
				tabs={[
					{
						label: "ATS",
						state: "ats",
						active: true,
					},
					{
						label: "Diffusion",
						state: "diffusion",
						active: false,
					},
					{
						label: "Formulaire",
						state: "form",
						active: false,
					},
				]}
				onTabChange={(index) => {
					switch (index) {
						default:
							navigate("/ats");
							break;
					}
				}}
			/>
			<RemoveProspectContext.Provider value={removeProspect}>
				<SetProspectNotOpenedContext.Provider value={setProspectNotOpened}>
					{columns.length !== 0 ? (
						<Kanban
							onCardClick={onCardClick}
							cards={cards}
							columns={columns}
							reorderCards={reorderProspects}
							updateCardsColumn={updateProspectsColumn}
						/>
					) : (
						<KanbanLoading />
					)}
				</SetProspectNotOpenedContext.Provider>

				<SlidingPane
					isOpen={selectedProspect !== null}
					from="right"
					width="1450px"
					onRequestClose={onSlidingPanelClose}
					shouldCloseOnEsc
					hideHeader
				>
					{selectedProspect !== null && (
						<div className="flex h-full flex-col flex-1 gap-4">
							<OnSlidingPanelCloseContext.Provider value={onSlidingPanelClose}>
								<ColumnsContext.Provider value={columns}>
									<CurrentProspectContext.Provider
										value={{
											currentProspect: selectedProspect,
											setCurrentProspect: setSelectedProspect,
										}}
									>
										<SlidingPaneHeader />

										<UpdateProspectsColumnContext.Provider
											value={updateProspectsColumn}
										>
											<FieldsContext.Provider value={fields}>
												<SlidingPaneContent />
											</FieldsContext.Provider>
										</UpdateProspectsColumnContext.Provider>
									</CurrentProspectContext.Provider>
								</ColumnsContext.Provider>
							</OnSlidingPanelCloseContext.Provider>
						</div>
					)}
				</SlidingPane>
			</RemoveProspectContext.Provider>
			{displayNewStudent && (
				<CreateStudentModal
					show={true}
					onClose={() => {
						updateProspectsColumn(
							displayNewStudent.id,
							4,
							0,
							displayNewStudent.columnId,
						);
						setDisplayNewStudent(null);
					}}
					initialValues={{
						firstname: displayNewStudent?.data.firstName || "",
						lastname: displayNewStudent?.data.lastName || "",
						email: displayNewStudent?.data.email || "",
						phone: displayNewStudent?.data.phone || "",
					}}
					onCreateUserSuccess={() => {
						updateProspectsPosition(cardsToUpdate);
						setDisplayNewStudent(null);
					}}
				/>
			)}
		</main>
	);
}
