import React from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";

type DeleteModalType = {
	label: string;
	show: boolean;
	type: string;
	onClose: () => void;
	entityId?: string;
	editCareer: Function;
};

export default function DeleteModal({
	label,
	show,
	type,
	onClose,
	entityId,
	editCareer,
}: DeleteModalType) {
	const handleDelete = async () => {
		try {
			await editCareer(type, "delete", { id: entityId });
			onClose();
		} catch (_error) {
			ErrorToast("Erreur lors de la suppression");
		}
	};

	return (
		<Modal
			onClose={onClose}
			title="Confirmer la suppression"
			show={show}
			body={<p>{label}</p>}
			buttonsRight={[
				<ButtonSecondary
					label="Non, annuler"
					onClick={() => {
						onClose();
					}}
				/>,
				<ButtonPrimary
					id="deleteExpBtn"
					label="Oui, supprimer"
					onClick={() => {
						handleDelete();
					}}
				/>,
			]}
		/>
	);
}
