import React from "react";
import Skeleton from "react-loading-skeleton";
export const ActivityRowLoading = () => {
	return (
		<div className="flex flex-row border rounded-md p-4 gap-4">
			<div className="flex items-center self-center justify-center rounded-md">
				<Skeleton width={40} height={40} />
			</div>
			<span className="flex-1 text-xsm font-semibold">
				<Skeleton width={400} height={24} />
			</span>
			<span className="text-xsm text-gray-500 flex items-center">
				<Skeleton width={135} height={25} />
			</span>
		</div>
	);
};
