import { useAppSelector } from "@config/redux/hook";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { funnelOutline } from "@assets/Icons";

import { ToggleButtonGroupLarge } from "@components/atoms/ToggleButtonGroup";
import TooltipContainer from "@components/atoms/TooltipContainer";

import {
	countCurrentFilters,
	performReloadOnSearch,
	reloadFiltersFromCookies,
	setCurrentSearch,
	setUseFilters,
} from "../offersFiltersSlice";

export function ToggleOffersFilters() {
	const dispatchEvent = useDispatch();
	const { useFilters, filtersCounter } = useAppSelector(
		(state) => state.offersFilters,
	);
	const performFullReload = async () => {
		await dispatchEvent(reloadFiltersFromCookies() as any);
		dispatchEvent(countCurrentFilters());
		dispatchEvent(performReloadOnSearch());
	};
	useEffect(() => {
		performFullReload();
		dispatchEvent(countCurrentFilters());
	}, []);
	return (
		<span
			className="filter-container-btn w-fit-content"
			id="toggleOffersFilters-Tooltip"
		>
			<div className="w-fit-content rounded-md bg-white">
				<ToggleButtonGroupLarge
					buttons={[
						{
							icon: funnelOutline,
							onClick: () => {
								if (useFilters === false) {
									dispatchEvent(setCurrentSearch(""));
								} else {
									dispatchEvent(setUseFilters(false));
								}
							},
						},
					]}
					selectedIndex={useFilters ? 0 : 1}
					className="hidden md:flex"
				/>
				{filtersCounter > 0 && (
					<span className="absolute -bottom-1 -right-1 h-3 w-3 rounded-full border border-accent-1-dark bg-white" />
				)}
			</div>
			<TooltipContainer
				anchorId="#toggleOffersFilters-Tooltip"
				children={useFilters ? "Fermer les filtres" : "Ouvrir les filtres"}
				place="top"
				float={false}
			/>
		</span>
	);
}
