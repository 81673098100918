import type { promotionType } from "@/types/promotionTypes";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";

import { peopleOutline } from "@assets/Icons";

import { LOG_ACTION_OPTIONS } from "@constants/Logs";

import { Input } from "@components/molecules/Input";
import { Select } from "@components/organisms/select/Select";

type SearchProps = {
	updateLogs: Function;
	promotions: any[];
	filters: any;
};
export function SearchByUser({ updateLogs, promotions, filters }: SearchProps) {
	const [promotionsOptions, setPromotionsOptions] = useState<any[]>([]);
	const [search, setSearch] = useState<string>(filters.search || "");
	const debouncedValue = useDebounce(search, 500);

	const getPromotionsForSelect = () => {
		let promotionsSelectOptions = promotions;

		if (filters.promotion_list) {
			promotionsSelectOptions = promotions?.map((promo) => {
				return filters.promotion_list.includes(promo.value)
					? { ...promo, checked: true }
					: {
							...promo,
							checked: false,
						};
			});
		}

		return promotionsSelectOptions;
	};

	const getActionsForMultiselect = () => {
		let actionsMultiselectOptions = LOG_ACTION_OPTIONS;

		if (filters.action_list) {
			actionsMultiselectOptions = LOG_ACTION_OPTIONS?.map((action) => {
				return filters.action_list.includes(action.value)
					? { ...action, checked: true }
					: {
							...action,
							checked: false,
						};
			});
		}

		return actionsMultiselectOptions;
	};

	useEffect(() => {
		updateLogs({
			name: "search",
			value: debouncedValue !== "" ? debouncedValue?.replace(" ", "%") : "",
		});
	}, [debouncedValue]);

	useEffect(() => {
		setPromotionsOptions(getPromotionsForSelect());
	}, [promotions]);

	return (
		<div className="filters">
			<Input
				placeholder="Rechercher un utilisateur..."
				icon={peopleOutline}
				onChange={(e) => {
					setSearch(e.target.value);
				}}
				value={filters.slugname ? filters.slugname : ""}
				type="text"
			/>
			<div className="mt-sm flex">
				<Select
					placeholder="Promotion"
					className="w-6/12"
					bgColor="bg-primary-50"
					inputs={promotionsOptions}
					multiselect
					onChange={(promotionsSelected: promotionType[]) => {
						updateLogs({
							name: "spsStudent_spsPromotion_list",
							value: promotionsSelected?.map(
								(promotion: promotionType) => promotion.id,
							),
						});
						setPromotionsOptions(
							promotionsOptions?.map((promotion) => {
								return promotionsSelected.includes(promotion)
									? { ...promotion, checked: true }
									: { ...promotion, checked: false };
							}),
						);
					}}
				/>
				<Select
					placeholder="Type d'activité"
					inputs={getActionsForMultiselect()}
					multiselect
					bgColor="bg-primary-50"
					onChange={(actionsSelected: any) => {
						updateLogs({
							name: "action_list",
							value: actionsSelected?.map((action: any) => action.value),
						});
					}}
					className="ml-sm w-6/12"
				/>
			</div>
		</div>
	);
}
