import {
	emailOutline,
	globe2Outline,
	linkedinOutline,
	phoneOutline,
} from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import React from "react";
import type { Referent } from "../SelectionAPI";

type ReferentCardProps = {
	referent: Referent;
};

export function ReferentCard({ referent }: ReferentCardProps) {
	return (
		<div className="flex flex-col items-center md:flex-row gap-md md:gap-xxlg">
			<div className="flex flex-col items-center gap-sm md:gap-lg md:flex-row">
				<Avatar
					firstname={referent.firstname}
					lastname={referent.lastname}
					modify={false}
					image={
						referent.avatar
							? `${import.meta.env.VITE_S3}${referent.avatar}`
							: null
					}
					size="lg"
				/>
				<div className="flex flex-col justify-between gap-xxsm">
					<p className="wg-no-translate text-md font-bold">
						{referent.firstname} {referent.lastname}
					</p>
					<div className="flex justify-center gap-xsm md:justify-start">
						{referent.portfolio && (
							<ButtonTertiary
								icon={globe2Outline}
								onClick={() => window.open(referent.portfolio)}
							/>
						)}
						{referent.linkedin && (
							<ButtonTertiary
								icon={linkedinOutline}
								onClick={() => window.open(referent.linkedin)}
							/>
						)}
						{referent.email && (
							<ButtonTertiary
								icon={emailOutline}
								onClick={() => window.open(`mailto:${referent.email}`)}
							/>
						)}
						{referent.phone && (
							<ButtonTertiary
								icon={phoneOutline}
								onClick={() => window.open(`tel:${referent.phone}`)}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="flex h-full flex-col justify-between gap-xsm">
				{referent.email && (
					<div className="flex gap-md">
						<p className="font-bold text-primary-200">Email</p>
						<p>{referent.email}</p>
					</div>
				)}
				{referent.phone && (
					<div className="flex gap-md">
						<p className="font-bold text-primary-200">Téléphone</p>
						<p>{referent.phone}</p>
					</div>
				)}
			</div>
		</div>
	);
}
