import React from "react";
import Skeleton from "react-loading-skeleton";

export function FileLoader() {
	return (
		<div className="mb-sm flex items-center justify-between">
			<div className="w-8/12">
				<div className="mb-xsm w-8/12">
					<Skeleton />
				</div>

				<div className="flex gap-xsm">
					<Skeleton width={40} height={40} borderRadius={10} />
					<div className="w-full">
						<Skeleton />
						<Skeleton />
					</div>
				</div>
			</div>
			<div className="flex gap-sm">
				<Skeleton width={25} height={25} />
				<Skeleton width={25} height={25} />
			</div>
		</div>
	);
}
