import PropTypes from "prop-types";
import React from "react";

import "./Toast.scss";

export function ToastCustom({ children }) {
	return (
		<div className="toast-primary flex items-center rounded-lg">{children}</div>
	);
}

ToastCustom.propTypes = {
	children: PropTypes.node.isRequired,
};
