import React from "react";
import Skeleton from "react-loading-skeleton";
import uuid from "react-uuid";
export default function StudentsListLoader() {
	const arrayStudentCard: any[] = [];
	arrayStudentCard.length = 8;
	arrayStudentCard.fill("");
	return (
		<div className="flex flex-col gap-sm">
			{arrayStudentCard?.map(() => (
				<div
					key={`card-${uuid()}`}
					className="flex w-full cursor-pointer rounded bg-white p-sm"
				>
					<div className="w-2/12">
						<Skeleton width={30} height={30} circle />
					</div>
					<div className="flex w-8/12 flex-col">
						<Skeleton width={150} height={20} />
						<Skeleton width={100} height={10} />
					</div>
				</div>
			))}
		</div>
	);
}
