import type { OrganizationType } from "@/types/organizationTypes";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { GlobalContext } from "@navigation/Router";
import Tippy from "@tippyjs/react";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

import {
	globe2Outline,
	linkedinFill,
	phoneOutline,
	trash2Outline,
} from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { Modal } from "@components/organisms/modal/Modal";
import { Success } from "@components/organisms/toast/ToastNotification";

import { SpontaneousApplicationContext } from "../..";
import { removeOrganization } from "../../SpontaneousApplicationAPI";

import { CompanyTagsIcon } from "./CompanyTagsIcon";

type CompanyModalType = {
	company: OrganizationType;
	showCompanyModalProps: any;
	setShowUpdateOrganization: Function;
};
export function CompanyModal({
	company,
	showCompanyModalProps,
	setShowUpdateOrganization,
}: CompanyModalType) {
	const { showCompanyModal, setShowCompanyModal } = showCompanyModalProps;
	const { user } = useContext(GlobalContext);
	const { isMobile } = useBreakpoints();
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const { setFilters, filters, tab } = useContext(
		SpontaneousApplicationContext,
	);

	const [loading, setLoading] = useState(false);

	const deleteButton = (
		<ButtonQuaternary
			disabled={loading}
			onClick={() => {
				setLoading(true);
				removeOrganization(company.id)
					.then(() => {
						Success(`Vous avez bien supprimé ${company.name}`);
						setShowCompanyModal(false);

						setFilters({ ...filters });
					})
					.catch(() => {
						Error("Une erreur est survenue lors de la suppression");
					})
					.finally(() => {
						setLoading(false);
					});
			}}
			icon={trash2Outline}
		/>
	);

	const modifyButton = (
		<ButtonSecondary
			disabled={loading}
			label="Modifier"
			onClick={() => {
				setShowCompanyModal(false);
				setShowUpdateOrganization(true);
			}}
		/>
	);

	const defaultButton = (
		<ButtonPrimary
			disabled={loading}
			label="Fermer"
			onClick={() => {
				setShowCompanyModal(false);
			}}
		/>
	);

	const renderButtons = () => {
		const renderButtonsView: any = {};

		if (currentInterface === "student") {
			renderButtonsView.buttonsRight = [defaultButton];
		} else if (currentInterface === "school") {
			if (user?.roles.includes("ROLE_MG") || tab !== "mg") {
				renderButtonsView.buttonsLeft = [deleteButton];
				renderButtonsView.buttonsRight = [modifyButton, defaultButton];
			} else {
				renderButtonsView.buttonsRight = [defaultButton];
			}
		}
		return renderButtonsView;
	};

	useEffect(() => {}, [showCompanyModal]);

	return (
		<Modal
			title={`Fiche de ${company.name}`}
			show={showCompanyModal}
			size="lg"
			body={
				<div
					className="flex h-[calc(100vh-200px)] flex-col md:h-[668px] md:max-h-fit lg:h-[468px] lg:flex-row xl:h-[550px]"
					id="company-modal"
				>
					<div className="mr-0 w-full p-0 md:overflow-y-auto lg:mr-sm lg:h-[468px] lg:w-4/12 xl:h-[550px]  xl:w-3/12">
						<div id="head" className="relative mb-sm w-full">
							{company.illustration && (
								<div
									id="baneer"
									className="illustration-container z-index-1 absolute h-full w-full overflow-hidden rounded-lg shadow-lg"
								>
									<img
										src={company.illustration}
										alt={company.name}
										className="h-full w-full object-cover"
									/>
								</div>
							)}
							<div
								id="head-content"
								className="z-index-5 relative flex h-full flex-col items-center"
							>
								<div className="flex flex-col items-center justify-center p-sm">
									<Avatar
										firstname={company.name}
										lastname=""
										image={company.logo}
										size="lg"
									/>
									<p
										className={`text-center text-md font-bold ${
											company.illustration && "text-white"
										}`}
									>
										{company.name}
									</p>
								</div>
								{isMobile && CompanyTagsIcon(company)}
							</div>
						</div>
						{!isMobile && CompanyTagsIcon(company)}
						<div id="infos" className="mb-sm mt-xsm flex w-full flex-col">
							<div className="mb-xsm flex flex-wrap justify-center gap-sm">
								<Tippy
									content="Accéder au Linkedin"
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonTertiary
											onClick={() => {
												window.open(
													company.linkedin
														? company.linkedin
														: `https://www.linkedin.com/search/results/companies/?keywords=${company.name}`,
												);
											}}
											className="enabled"
											icon={linkedinFill}
										/>
									</span>
								</Tippy>

								<Tippy
									content="Accéder au site web"
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonTertiary
											onClick={() => {
												window.open(
													company.website
														? company.website
														: `https://www.google.com/search?q=${company.name}`,
												);
											}}
											icon={globe2Outline}
											iconColor="text-primary-700P"
										/>
									</span>
								</Tippy>
								<Tippy
									content="Contacter l'entreprise"
									theme="primary"
									animation="fade"
									placement="top"
									zIndex={5}
								>
									<span>
										<ButtonTertiary
											onClick={() => {
												window.open(
													company.phone
														? `tel:${company.phone}`
														: `https://www.google.com/search?q=phone%20${company.name}`,
												);
											}}
											className="enabled"
											icon={phoneOutline}
											iconColor="text-primary-700P"
										/>
									</span>
								</Tippy>
							</div>
							<div>
								<p className="text-sm font-bold text-primary-700P">
									Toutes les localisations
								</p>
								<div
									id="locations-list"
									className="mt-sm flex max-h-[100px] flex-wrap md:max-h-[200px]"
								>
									{company.locations && company.locations.length > 0 ? (
										<>
											{company.locations?.map((location: string, index) => {
												return (
													<Badge
														key={`location${index}`}
														label={location}
														className="pointer-events-none mb-xsm mr-xsm"
													/>
												);
											})}
										</>
									) : (
										<Badge
											label="Non renseigné"
											className="pointer-events-none mb-xsm mr-xsm"
										/>
									)}
								</div>
							</div>
						</div>
					</div>

					<div
						id="description"
						className="w-full text-primary-700P md:overflow-y-auto lg:h-[468px] lg:w-8/12 xl:h-[550px] xl:w-9/12"
					>
						<p className="text-md">
							{company.name} peut recruter dans ton école !
						</p>

						<p className="mb-sm mt-lg text-sm font-bold">Avant de candidater</p>

						<p>
							Voilà 3 étapes clefs qui te permettront de faire une candidature
							impactante :
						</p>

						<p className="mt-xsm font-bold">1 - Découvre l’entreprise</p>
						<p>
							Pour être pertinent dans ta candidature, renseigne-toi sur son
							cœur d'activité et ses valeurs.
						</p>
						<p>Pour cela, fais un tour sur :</p>
						<p>
							- leur site internet. Il te suffit de faire une{" "}
							<a
								href={`https://www.google.com/search?q=${company.name}`}
								rel="noopener noreferrer"
								target="_blank"
							>
								recherche rapide sur Google
							</a>
							.
						</p>
						<p>
							- leur{" "}
							<a
								href={`https://www.linkedin.com/search/results/companies/?keywords=${company.name}`}
								rel="noopener noreferrer"
								target="_blank"
							>
								page LinkedIn
							</a>
							.
						</p>

						<p className="mt-xsm font-bold">
							2 - Identifie la localisation du siège social
						</p>
						<p>
							C'est sûrement ici que l'entreprise recrute !{" "}
							<a
								href={`https://www.google.com/search?q=${company.name}+adresse+siege`}
								rel="noopener noreferrer"
								target="_blank"
							>
								Une recherche rapide sur Google fera l’affaire.
							</a>
						</p>

						<p className="mt-xsm font-bold">
							3 – Trouve leur numéro de téléphone
						</p>
						<p>
							Cela te sera utile lors de ta candidature, que ce soit pour te
							renseigner par rapport aux opportunités de recrutement ou
							relancer.
						</p>
						<p>
							Tu pourras le trouver sur leur site ou sur leur page LinkedIn.
						</p>

						<p className="mb-sm mt-lg text-sm font-bold">
							Comment réussir ta candidature spontanée chez {company.name} ?
						</p>

						<p>
							Ça y est, tu as toutes les informations pour faire ta candidature
							!
						</p>

						<p>Maintenant voici la marche à suivre :</p>

						<p className="mt-xsm font-bold">
							1 - Ajoute la candidature à ton tableau de suivi en cliquant sur
							le bouton "+"
						</p>

						<p className="mt-xsm font-bold">2 - Clique sur "Postuler"</p>
						<p>Tu arriveras directement sur la page LinkedIn de l'entreprise</p>
						<p className="mt-xsm font-bold">
							3 - Identifie 2 contacts de l'entreprise et ajoute-les à ta
							candidature dans ton tableau de suivi
						</p>

						<p className="mt-xsm font-bold">4 - Créé le premier échange</p>
						<p>
							Appelle l'entreprise ou envoie un email aux personnes identifiées
							pour connaître leurs besoins de recrutement.
						</p>
						<p className="mt-xsm font-bold">5 - Planifie ta prochaine action</p>
						<p>
							Sur ta candidature, tu peux planifier la prochaine action à
							effectuer pour faire avancer ta candidature.
						</p>
						<p>
							Pense à la suivre régulièrement et à relancer si tu n'as pas eu de
							réponse.
						</p>
					</div>
				</div>
			}
			onClose={() => {
				setShowCompanyModal(false);
			}}
			buttonsLeft={renderButtons().buttonsLeft}
			buttonsRight={renderButtons().buttonsRight}
		/>
	);
}
