import type { EventType, EventsTabLabelType } from "@/types/eventsTypes";

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { categorizePlannedEvents } from "@tools/Events";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Modal } from "@components/organisms/modal/Modal";

import EventsList from "@containers/shared/Events/UI/list/EventsList";
import { KnowMoreModal } from "@containers/shared/Events/UI/modal/KnowMoreModal";

import {
	EventsTabLabelEnum,
	useEventsView,
} from "@containers/shared/Events/UI/useEventsView";
import EventsTabsRow from "../../../shared/Events/UI/EventsTabsRow";
import Header from "./Header";

export function EventsStudentView() {
	const presenter = useEventsView({ title: "Évènements et Coachings Emploi" });
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			presenter.handleFetchTargetEvent(id);
		}
	}, [id]);

	return (
		<main className="grid-page" data-testid="eventStudentView">
			<Header />
			<div
				className="
          col-span-4 flex flex-col
          gap-8 rounded-lg bg-white px-4 py-6 md:col-span-8 lg:col-span-12"
			>
				<EventsTabsRow
					eventTabs={presenter.tabsCounters}
					selectTabEvent={presenter.setActiveTabLabel}
					activeTabLabel={presenter.activeTabLabel}
				/>
				{renderEventsList(presenter.events, presenter.activeTabLabel)}
			</div>
			<Modal
				title="En savoir plus"
				id="knowMoreModal"
				buttonsRight={[
					<ButtonPrimary
						label="Fermer"
						onClick={presenter.closeKnowMoreModal}
					/>,
				]}
				show={presenter.canShowKnowMore}
				onClose={presenter.closeKnowMoreModal}
				body={
					<KnowMoreModal
						eventTarget={presenter.targetEvent as EventType}
						setIsKnowMoreModalOpen={presenter.openKnowMoreModal}
					/>
				}
			/>
		</main>
	);
}

const renderEventsList = (
	events: EventType[],
	tabLabel: EventsTabLabelType,
) => {
	switch (tabLabel) {
		case EventsTabLabelEnum.planned: {
			const { todayEvents, upcomingEvents } = categorizePlannedEvents(events);
			return (
				<>
					{todayEvents.length > 0 && (
						<EventsList
							events={todayEvents}
							listTitle="Aujourd'hui"
							listID="planned"
						/>
					)}
					{upcomingEvents.length > 0 && (
						<EventsList
							events={upcomingEvents}
							listTitle="Prochainement"
							listID="planned"
						/>
					)}
					{todayEvents.length === 0 && upcomingEvents.length === 0 && (
						<EventsList
							events={upcomingEvents}
							listTitle="Prochainement"
							listID="planned"
						/>
					)}
				</>
			);
		}
		case EventsTabLabelEnum.replay:
			return (
				<EventsList
					events={events}
					listTitle="Évènements passés"
					listID="replay"
				/>
			);
		case EventsTabLabelEnum.finished:
			return (
				<EventsList
					events={events}
					listTitle="Évènements terminés"
					listID="finished"
				/>
			);
		default:
			return (
				<EventsList
					events={events}
					listTitle="Prochainement"
					listID="planned"
				/>
			);
	}
};
