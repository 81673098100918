import PropTypes from "prop-types";
import React from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";
import { Success } from "@components/organisms/toast/ToastNotification";

import { deleteTag } from "@containers/school/TagsManagement/TagsAPI";

export function DeleteTagModal({ show, onClose, tag, filtersProps, getData }) {
	const { filters, setFilters } = filtersProps;
	const removeTag = () => {
		deleteTag(tag.id).then(() => {
			setFilters(filters);
			getData();
			Success("Tag supprimé avec succès.");
		});
	};

	return (
		<div>
			<main>
				<Modal
					show={show}
					onClose={onClose}
					title={`Supprimer ${tag.name}`}
					body={
						<div>
							<p className="text-xsm text-primary-700P">
								En le supprimant, ce tag sera effacé définitivement. Êtes-vous
								sûr de vouloir continuer ?
							</p>
						</div>
					}
					buttonsLeft={[
						<ButtonSecondary
							key={0}
							label="Non, annuler"
							onClick={() => {
								onClose();
							}}
						/>,
						<ButtonPrimary
							key={1}
							label=" Oui, supprimer"
							onClick={() => {
								removeTag();
								onClose();
							}}
						/>,
					]}
				/>
			</main>
		</div>
	);
}

DeleteTagModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	tag: PropTypes.shape({
		id: PropTypes.string,
		name: PropTypes.string,
	}).isRequired,
};
