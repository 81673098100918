import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { type StandardGraphQLResponse, extractError } from "@tools/API";
import { eventsSlice } from "../store/events.slice";

type Counter = {
	counter: {
		totalCount: number;
	};
};

export type FetchRepliesCounters = {
	event: {
		registered: Counter;
		maybe: Counter;
		notParticipating: Counter;
		noReply: Counter;
	};
};

/**
 * Guard function for the FetchRepliesCounters type
 * @param data
 * @returns
 */
function isFetchRepliesCounter(data: unknown): data is FetchRepliesCounters {
	if (typeof data !== "object" || data === null) {
		console.warn("data is not an object");
		return false;
	}

	const d = data as FetchRepliesCounters;
	return (
		"event" in d &&
		"registered" in d.event &&
		"counter" in d.event.registered &&
		"totalCount" in d.event.registered.counter &&
		"maybe" in d.event &&
		"counter" in d.event.maybe &&
		"totalCount" in d.event.maybe.counter &&
		"notParticipating" in d.event &&
		"counter" in d.event.notParticipating &&
		"totalCount" in d.event.notParticipating.counter &&
		"noReply" in d.event &&
		"counter" in d.event.noReply &&
		"totalCount" in d.event.noReply.counter
	);
}

export const fetchRepliesCounters =
	(eventId: string, isMentorGoalEvent: boolean) =>
	async (
		dispatch: AppDispatch,
		_api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.eventsGateway) {
				throw new Error("eventsGateway is not available in dependencies");
			}
			dispatch(eventsSlice.actions.handleRepliesCountersLoading());
			const fetchingResponse: StandardGraphQLResponse =
				await dependencies.eventsGateway.getRepliesCounters(
					eventId,
					isMentorGoalEvent,
				);
			if (
				!fetchingResponse ||
				fetchingResponse.responseLabel !== "success" ||
				!fetchingResponse.data
			) {
				throw new Error("Failed to fetch replies counters");
			}

			const rawData: unknown = fetchingResponse.data;
			if (isFetchRepliesCounter(rawData)) {
				const repliesCounters = {
					registered: rawData.event.registered.counter.totalCount,
					maybe: rawData.event.maybe.counter.totalCount,
					notParticipating: rawData.event.notParticipating.counter.totalCount,
					noReply: rawData.event.noReply.counter.totalCount,
				};

				dispatch(
					eventsSlice.actions.handleRepliesCountersSuccess(repliesCounters),
				);
			} else {
				throw new Error("Invalid data format");
			}
		} catch (error: unknown) {
			dispatch(
				eventsSlice.actions.handleRepliesCountersError(
					extractError(error).message.toString(),
				),
			);
		}
	};
