import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { UserType } from "@/types/userTypes";

import API, { checkGraphQLError, graphqlRequestConstructor } from "@tools/API";
import { getCookie } from "@tools/Cookies";
import { isMentorGoalEvent } from "@tools/Events";
import { stringifyRequestInputs } from "@tools/Objects";
import { computeUsersIdNumber, computeUsersIdString } from "@tools/Users";

const ELEMENTS_PER_PAGE = 250;

export type InvitationsRequestDataType = {
	eventId: number;
	selectedStudents: Partial<UserType>[];
	sendEmail: boolean;
	sendSMS: boolean;
};

export async function uploadEventIllustrationRequest(formdata: FormData) {
	try {
		const responseReceived = await API.post("media_objects", formdata);
		return responseReceived.data as MediaObjectType;
	} catch (error) {
		return {
			responseLabel: "error",
			message: `Erreur de type ${error}`,
		};
	}
}

export async function subscribeToEvent({
	eventId,
	subscriptionReply,
	hasParticipated = false,
	isMentorgoalType,
}: {
	eventId: number;
	subscriptionReply: string;
	hasParticipated?: boolean;
	isMentorgoalType: boolean;
}) {
	if (!eventId) throw new Error("L'id de l'évènement n'est pas défini");
	try {
		const responseReceived = await API.post("subscribe_event", {
			id: eventId,
			hasParticipated,
			reply: subscriptionReply,
			type: isMentorgoalType ? "mentorgoalEvent" : "event",
		});
		if (responseReceived.data) {
			return {
				responseLabel: "success",
				message: "Votre inscription a bien été prise en compte",
				data: responseReceived.data,
			};
		}
		return {
			responseLabel: "error",
			message: "Une erreur est survenue lors de l'inscription",
		};
	} catch (error) {
		return {
			responseLabel: "error",
			message: `Erreur de type ${error}`,
		};
	}
}

export async function sendMentorgoalInvitations({
	eventId,
	selectedStudents,
	sendEmail,
	sendSMS,
}: InvitationsRequestDataType) {
	if (!eventId) throw new Error("L'id de l'évènement n'est pas défini");
	if (!selectedStudents) throw new Error("L'id des étudiants n'est pas défini");
	const studentsIds = computeUsersIdNumber(selectedStudents as UserType[]);
	const data = {
		id: eventId,
		studentsIds,
		sendEmail,
		sendSMS,
	};

	try {
		const responseReceived = await API.post("share_mentorgoal_event", data);
		if (responseReceived.data) {
			return {
				responseLabel: "success",
				message: "Les invitations ont bien été envoyées",
			};
		}
		return {
			responseLabel: "error",
			message: "Une erreur est survenue lors de l'envois des invitations",
		};
	} catch (error) {
		return {
			responseLabel: "error",
			message: `Erreur de type ${error}`,
		};
	}
}

export async function sendEventInvitations({
	eventId,
	selectedStudents,
	sendEmail,
	sendSMS,
}: InvitationsRequestDataType) {
	try {
		const studentIds = computeUsersIdString(selectedStudents as UserType[]);
		const query = {
			query: `
      mutation {
        updateEvent(input: {id:"${getCookie("interface")}/events/${eventId}", sendEmail: ${sendEmail}, sendSMS:${sendSMS} ,spsStudents:${stringifyRequestInputs(
					studentIds,
				)}}) {
            event {
                id
            }
        }
    }`,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Erreur lors de l'envois des invitations",
		);
	} catch (error) {
		throw new Error(error);
	}
}

const EVENTS_COLLECTION = `collection {
  id
  title
  description
  startDate
  endDate
  type
  location
  address
  eventLink
  replayLink
  defaultIllustration
  illustration {
    id
    filePath
    size
  }
}`;

const EVENTS_MG_COLLECTION = `collection {
  id
  title
  description
  startDate
  endDate
  type
  location
  address
  eventLink
  replayLink
  defaultIllustration
  illustration {
    id
    filePath
    size
  }
}`;

const getTabFilter = (tab: string) => {
	let tabFilter = "";
	switch (tab) {
		case "Terminés":
			tabFilter = 'endDate: {before: "now"}';
			break;
		case "Replays":
			tabFilter = "exists: {replayLink: true}";
			break;
		default:
			tabFilter = 'endDate: {after: "now"}';
	}
	return tabFilter;
};

export async function getEventsRequest(
	title: string,
	school: string,
	tab: string,
) {
	const tabFilter = getTabFilter(tab);

	const query = {
		query: `
        query {
          events (itemsPerPage: ${ELEMENTS_PER_PAGE}, title: "${title}", school: "${school}", ${tabFilter}) {
            ${EVENTS_COLLECTION}
            paginationInfo {
              totalCount
            }
          },
          mentorgoalEvents (itemsPerPage: ${ELEMENTS_PER_PAGE}, title: "${title}", ${tabFilter}) {
            ${EVENTS_MG_COLLECTION}
            paginationInfo {
              totalCount
            }
          }
        }
      `,
	};

	const response = graphqlRequestConstructor(
		query,
		"events",
		"erreur lors de la récupération des events",
		true,
	);
	return response;
}

type fetchGetStudentInvitationsParams = {
	studentId: string;
	tab?: string;
};

export async function fetchGetStudentInvitations({
	studentId,
	tab,
}: fetchGetStudentInvitationsParams) {
	try {
		const tabFilter = getTabFilter(tab || "À venir");
		const query = {
			query: `
				query {
					mentorgoalEvents (itemsPerPage: ${ELEMENTS_PER_PAGE}, ${tabFilter}) {
					collection {
              id
              ${
								studentId
									? `invitations: mgEventInvitations  (spsStudent: "/api/users/${studentId}"){
                collection {
                  id
                  reply
									spsStudent {
										id
									}
                }
              }`
									: ""
							}
            }
					}
					events (itemsPerPage: ${ELEMENTS_PER_PAGE}, ${tabFilter}){
					collection {
              id
              ${
								studentId
									? `invitations (spsStudent: "/api/users/${studentId}") {
                collection {
                  id
                  reply
									spsStudent {
										id
									}
                }
              }`
									: ""
							}
            }
					}
				}
			`,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Un ou plusieurs évènements ont été trouvés",
		);
	} catch (error) {
		throw new Error(error);
	}
}
export async function getInvitationsEventsCounts(
	title: string,
	school: string,
	tab: string,
) {
	let tabFilter = "";
	switch (tab) {
		case "Terminés":
			tabFilter = 'endDate: {before: "now"}';
			break;
		case "Replays":
			tabFilter = "exists: {replayLink: true}";
			break;
		default:
			tabFilter = 'endDate: {after: "now"}';
	}

	try {
		const query = {
			query: `
        query {
          events (itemsPerPage: ${ELEMENTS_PER_PAGE}, title: "${title}", school: "${school}", ${tabFilter}) {
            collection {
							invitationsCount
							inscriptionsCount
							participationsCount
						}
            paginationInfo {
              totalCount
            }
          },
          mentorgoalEvents (itemsPerPage: ${ELEMENTS_PER_PAGE}, title: "${title}", ${tabFilter}) {
            collection {
							invitationsCount
							inscriptionsCount
							participationsCount
						}
            paginationInfo {
              totalCount
            }
          }
        }
      `,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Un ou plusieurs évènements ont été trouvés",
		);
	} catch (error) {
		throw new Error(error);
	}
}

export async function getEventsTabCount(title: string, school: string) {
	const tabs = [
		{ name: "finished", filter: 'endDate: {before: "now"}' },
		{ name: "replay", filter: "exists: {replayLink: true}" },
		{ name: "planned", filter: 'endDate: {after: "now"}' },
	];
	const query = {
		query: `
        query {
          ${tabs.map((tab) => {
						return `
            ${tab.name}Events: events (title: "${title}", school: "${school}", ${tab.filter}) {
              paginationInfo {
                totalCount
              }
            },
            ${tab.name}MgEvents: mentorgoalEvents (title: "${title}", ${tab.filter}) {
              paginationInfo {
                totalCount
              }
            }`;
					})}
      }`,
	};
	const response = graphqlRequestConstructor(query, "eventsTabsCounters");
	return response;
}
export async function getTargetEventRequest(
	id: string,
	isMentorgoal: boolean,
	studentId?: string,
) {
	try {
		let query;
		if (isMentorgoal) {
			query = {
				query: `
          query {
            mentorgoalEvents: mentorgoalEvent (id: "${id.split("-")[1]}"){
              id
              title
              description
              startDate
              endDate
              type
              location
              address
              eventLink
              replayLink
              defaultIllustration
              illustration {
                id
                filePath
                size
              }
              ${
								studentId
									? `mgEventInvitations (spsStudent: "${studentId}") {
                collection {
                  id
                  reply
                  hasParticipated
                }
              }`
									: ""
							}
              invitationsCount
              inscriptionsCount
              participationsCount
            },
          }`,
			};
		} else {
			query = {
				query: `
        query {   
          schoolEvents: event (id: "${id}"){
            id
            title
            description
            startDate
            endDate
            type
            location
            address
            eventLink
            replayLink
            defaultIllustration
            illustration {
              id
              filePath
              size
            }
            invitationsCount
            inscriptionsCount
            participationsCount
            ${
							studentId
								? `invitations (spsStudent: "${studentId}") {
              collection {
                id
                reply
                hasParticipated
              }
            }`
								: ""
						}
          },
      }`,
			};
		}

		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Un ou plusieurs évènements ont été trouvés",
		);
	} catch (error) {
		throw new Error(error);
	}
}
export async function createEventRequest(evenToCreate: any, schoolId: string) {
	try {
		const createMentorgoalEvent = isMentorGoalEvent(evenToCreate?.type);
		if (!createMentorgoalEvent) evenToCreate.school = schoolId;
		const query = {
			query: `
        mutation {
          ${
						createMentorgoalEvent ? "createMentorgoalEvent" : "createEvent"
					} (input: ${stringifyRequestInputs(evenToCreate)}){
            ${createMentorgoalEvent ? "mentorgoalEvent" : "event"}
            {
              id
            }
          }
        }
      `,
		};
		const data = await API.post("graphql", query);
		return checkGraphQLError(data, "L'évènement a bien été créé");
	} catch (error) {
		throw new Error(error);
	}
}
export async function updateEventRequest(eventModified: any, schoolId: string) {
	const modifyMentorgoalEvent = isMentorGoalEvent(eventModified?.type);
	if (!modifyMentorgoalEvent) eventModified.school = schoolId;
	const eventWithoutInvitations = {
		...eventModified,
		invitations: undefined,
	};
	try {
		const query = {
			query: `
      mutation {
        ${
					modifyMentorgoalEvent ? "updateMentorgoalEvent" : "updateEvent"
				} (input: ${stringifyRequestInputs(eventWithoutInvitations)}){
          ${modifyMentorgoalEvent ? "mentorgoalEvent" : "event"}
          {
            id
          }
        }
      }
      `,
		};
		const data = await API.post("graphql", query);
		return checkGraphQLError(data, "L'évènement a bien été mis à jour");
	} catch (error) {
		throw new Error(error);
	}
}
export async function deleteEventRequest(
	eventId: any,
	type: string | undefined,
) {
	try {
		if (!type) throw new Error("Type d'évènement non défini");
		const deleteMentorgoalEvent = isMentorGoalEvent(type);
		const query = {
			query: `
        mutation {
          ${
						deleteMentorgoalEvent ? "deleteMentorgoalEvent" : "deleteEvent"
					} (input: { id: ${stringifyRequestInputs(eventId)} }) {
            ${deleteMentorgoalEvent ? "mentorgoalEvent" : "event"} {
              id
            }
          }
        }
      `,
		};
		const data = await API.post("graphql", query);
		return checkGraphQLError(data, "L'évènement a bien été supprimé");
	} catch (error) {
		throw new Error(error);
	}
}
// USE EXIST pour filtrer par null ou non null
export async function getEventParticipants(
	eventId: string,
	isMentorgoal: boolean,
	filterPreset?: number,
) {
	try {
		if (!eventId) throw new Error("L'id de l'évènement n'est pas défini");
		let additionalFilters = "";
		switch (filterPreset) {
			case 0:
				additionalFilters = ',reply: "registered"';
				break;
			case 1:
				additionalFilters = ',reply: "maybe"';
				break;
			case 2:
				additionalFilters = ",exists: {reply: false}";
				break;
			case 3:
				additionalFilters = ',reply: "notParticipating"';
				break;
			default:
				additionalFilters = "exists: {reply: true}";
		}
		const query = {
			query: `
        query {
          ${
						isMentorgoal ? "event: mentorgoalEvent" : "event"
					}   (id: "${eventId}"){
            id
            ${
							isMentorgoal ? "invitations : mgEventInvitations " : "invitations"
						} (itemsPerPage: 1000${additionalFilters}){
              collection {
                id
								createdAt
                hasParticipated
								sentAt
								reply
                student: spsStudent {
                  id
                  firstname
                  lastname
                  avatar {
                    id
                    filePath
                  }
                  spsPromotion {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Aucun participant n'a été trouvé pour cet évènement",
		);
	} catch (error) {
		throw new Error(error);
	}
}

export async function getEventParticipantsCounters(
	eventId: string,
	isMentorgoal: boolean,
) {
	try {
		if (!eventId) throw new Error("L'id de l'évènement n'est pas défini");
		const query = {
			query: `
				query {
					${
						isMentorgoal ? "event: mentorgoalEvent" : "event"
					}   (id: "${eventId}"){
						 ${
								isMentorgoal
									? "registered : mgEventInvitations "
									: "registered : invitations"
							}(reply: "registered"){
								counter : paginationInfo {
									totalCount
								}
						}

						${isMentorgoal ? "maybe : mgEventInvitations " : "maybe : invitations"}
						(reply: "maybe"){
							counter : paginationInfo {
								totalCount
							}
						}

						${isMentorgoal ? "notParticipating : mgEventInvitations " : "notParticipating : invitations"}
						(reply: "notParticipating"){
							counter : paginationInfo {
								totalCount
							}
						}

						${isMentorgoal ? "noReply : mgEventInvitations " : "noReply : invitations"}
						(exists: {reply: false}){
							counter : paginationInfo {
								totalCount
							}
						}
					}
				}
			`,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Aucun participant n'a été trouvé pour cet évènement",
		);
	} catch (error) {
		throw new Error(error);
	}
}
export async function updateEventReplyRequest(
	reply: string,
	eventInvitationId: number,
	isMentorgoalEvent: boolean,
) {
	try {
		const query = {
			query: `
        mutation {
          ${
						isMentorgoalEvent
							? "updateMentorgoalEventInvitation"
							: "updateEventInvitation"
					}(input: ${stringifyRequestInputs({
						id: eventInvitationId,
						reply,
					})}) {${
						isMentorgoalEvent ? "mentorgoalEventInvitation" : "eventInvitation"
					}
        {
          id
          reply
          hasParticipated
        }
      }
    }`,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Erreur lors de l'envois de votre réponse",
		);
	} catch (error) {
		throw new Error(error);
	}
}

export async function updateHasParticipatedState(
	eventInvitationId: number,
	isMentorgoalEvent: boolean,
) {
	try {
		const query = {
			query: `
        mutation {
          ${
						isMentorgoalEvent
							? "updateMentorgoalEventInvitation"
							: "updateEventInvitation"
					}(input: ${stringifyRequestInputs({
						id: eventInvitationId,
						hasParticipated: true,
						reply: "registered",
					})}) {${
						isMentorgoalEvent ? "mentorgoalEventInvitation" : "eventInvitation"
					}
        {
          id
          reply
          hasParticipated
        }
      }
    }`,
		};
		const response = await API.post("graphql", query);
		return checkGraphQLError(
			response,
			"Une erreur est survenue lors de la mise à jour de votre participation",
		);
	} catch (error) {
		throw new Error(error);
	}
}

export async function exportUsersFromIdsList(studentIdList: number[]) {
	try {
		const data = await API.post("export_users_from_ids_list", studentIdList);
		return data;
	} catch (error) {
		throw new Error(error);
	}
}
