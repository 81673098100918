import React, { useEffect, useState } from "react";
import uuid from "react-uuid";

import "@assets/sass/Switch.scss";

type SwitchType = {
	className?: string;
	checked?: boolean;
	onEnabled?: () => void;
	onDisabled?: () => void;
};

export function Switch({
	className = "",
	checked = false,
	onEnabled = () => {},
	onDisabled = () => {},
}: SwitchType) {
	const [activated, setActivated] = useState(checked);
	const [switchId] = useState(`switch-${uuid()}`);

	useEffect(() => {
		setActivated(checked);
	}, [checked]);

	return (
		<div className={`switch-container ${className}`}>
			<label htmlFor={switchId} className="switch">
				{" "}
				<input
					id={switchId}
					data-testid="idTest-checkbox"
					className="switch-input"
					type="checkbox"
					onClick={() => {
						setActivated(!activated);
						if (!activated && onEnabled) onEnabled();
						else if (activated && onDisabled) onDisabled();
					}}
					checked={activated}
					readOnly
				/>
				<span className="slider round" />
			</label>
		</div>
	);
}
