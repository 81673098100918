import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const fetchCompanyContact = async (
	dispatch: AppDispatch,
	api: AppGetState,
	dependencies: Dependencies,
) => {
	try {
		if (!dependencies.companyContactGateway) {
			throw new Error("companyContactGateway is not available in dependencies");
		}
		dispatch(companiesManagementSlice.actions.handleContactLoading());
		const companyContact =
			await dependencies.companyContactGateway.getCompanyContact(
				api().companiesManagement.targetCompany,
			);
		dispatch(
			companiesManagementSlice.actions.modifyCompanyContact(companyContact),
		);
	} catch (error: unknown) {
		dispatch(
			companiesManagementSlice.actions.handleContactError(
				extractError(error).message.toString(),
			),
		);
	}
};
