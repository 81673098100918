export const ORGANIZATIONS_BUSINESS_LINE_OPTIONS = [
	{
		label: "Agriculture / Industrie forestière",
		value: "Agriculture / Industrie forestière",
	},
	{ label: "Agroalimentaire / Boissons", value: "Agroalimentaire / Boissons" },
	{ label: "Architecture / Design", value: "Architecture / Design" },
	{ label: "Assurances", value: "Assurances" },
	{ label: "Audit", value: "Audit" },
	{ label: "Automobile", value: "Automobile" },
	{ label: "Autre", value: "Autre" },
	{ label: "Autres Industries", value: "Autres Industries" },
	{
		label: "Autres services aux particuliers et aux entreprises",
		value: "Autres services aux particuliers et aux entreprises",
	},
	{ label: "Bureau d'études / Design", value: "Bureau d'études / Design" },
	{ label: "BTP / Urbanisme", value: "BTP / Urbanisme" },
	{ label: "Banque / Finance", value: "Banque / Finance" },
	{ label: "Biens de consommation", value: "Biens de consommation" },
	{ label: "Chimie", value: "Chimie" },
	{ label: "Commerce de détail", value: "Commerce de détail" },
	{ label: "Communication / Publicité", value: "Communication / Publicité" },
	{ label: "Construction / Urbanisme", value: "Construction / Urbanisme" },
	{ label: "Conseil en management", value: "Conseil en management" },
	{ label: "Conseil en stratégie", value: "Conseil en stratégie" },
	{ label: "Cosmétique", value: "Cosmétique" },
	{ label: "Défense", value: "Défense" },
	{ label: "Digital / E-commerce", value: "Digital / E-commerce" },
	{ label: "Distribution", value: "Distribution" },
	{ label: "ESN / Informatique", value: "ESN / Informatique" },
	{ label: "Environnement", value: "Environnement" },
	{ label: "ESN / Informatique", value: "ESN / Informatique" },
	{ label: "Électronique", value: "Électronique" },
	{
		label: "Énergie / Services publics / Pétrole et gaz",
		value: "Énergie / Services publics / Pétrole et gaz",
	},
	{
		label: "Événements / Tourisme / Hôtellerie",
		value: "Événements / Tourisme / Hôtellerie",
	},
	{ label: "Grande consommation", value: "Grande consommation" },
	{ label: "Ingénierie", value: "Ingénierie" },
	{
		label: "IT / Conseil en informatique / Logiciel",
		value: "IT / Conseil en informatique / Logiciel",
	},
	{ label: "Juridique", value: "Juridique" },
	{ label: "Loisirs / Culture / Sports", value: "Loisirs / Culture / Sports" },
	{ label: "Luxe / Mode", value: "Luxe / Mode" },
	{ label: "Maritime / Ferroviaire", value: "Maritime / Ferroviaire" },
	{ label: "Matériaux", value: "Matériaux" },
	{ label: "Médias / Édition", value: "Médias / Édition" },
	{
		label: "Médecine / Pharmaceutique / Santé",
		value: "Médecine / Pharmaceutique / Santé",
	},
	{ label: "Public / Éducation", value: "Public / Éducation" },
	{ label: "R&D / Design", value: "R&D / Design" },
	{ label: "Recrutement / Formation", value: "Recrutement / Formation" },
	{ label: "Restauration", value: "Restauration" },
	{ label: "Social / ONG", value: "Social / ONG" },
	{
		label: "Social / ONG / Organisme de bienfaisance",
		value: "Social / ONG / Organisme de bienfaisance",
	},
	{ label: "Télécommunications", value: "Télécommunications" },
	{ label: "Tourisme / Hôtellerie", value: "Tourisme / Hôtellerie" },
	{ label: "Transport / Logistique", value: "Transport / Logistique" },
	{
		label: "Événements / Tourisme / Hôtellerie",
		value: "Événements / Tourisme / Hôtellerie",
	},
];

export const ORGANIZATIONS_SIZE_OPTIONS = [
	{ label: "< 15 salariés", value: "< 15 salariés" },
	{ label: "Entre 15 et 49 salariés", value: "Entre 15 et 49 salariés" },
	{ label: "Entre 50 et 249 salariés", value: "Entre 50 et 249 salariés" },
	{ label: "Entre 250 et 2000 salariés", value: "Entre 250 et 2000 salariés" },
	{ label: "> 2000 salariés", value: "> 2000 salariés" },
];

export const ORGANIZATIONS_SECTORS = [
	{
		label: "Recrutement et placement de personnel",
		value: "recrutement-placement-personnel",
	},
	{
		label: "Services de ressources humaines",
		value: "services-ressources-humaines",
	},
	{
		label: "Technologie, information et Internet",
		value: "technologie-information-internet",
	},
	{ label: "Sécurité civile", value: "securite-civile" },
	{
		label: "Services et conseil en informatique",
		value: "services-conseil-informatique",
	},
	{ label: "Services à la personne", value: "services-a-la-personne" },
	{
		label: "Programmes d’administration de l’éducation",
		value: "programmes-administration-education",
	},
	{ label: "Ressources humaines", value: "ressources-humaines" },
	{ label: "Hôtellerie", value: "hotellerie" },
	{ label: "Assurances", value: "assurances" },
	{ label: "Services financiers", value: "services-financiers" },
	{
		label: "Commerce de détail de machines et d’équipements de bureau",
		value: "commerce-detail-machines-equipements-bureau",
	},
	{ label: "Immobilier", value: "immobilier" },
	{
		label: "Fabrication de produits chimiques",
		value: "fabrication-produits-chimiques",
	},
	{
		label: "Filtrer par Fabrication de produits chimiques",
		value: "filtrer-fabrication-produits-chimiques",
	},
	{
		label: "Fabrication de parfums et de produits pour la toilette",
		value: "fabrication-parfums-produits-toilette",
	},
	{
		label: "Commerce de détail d’habillement et d’accessoires de mode",
		value: "commerce-detail-habillement-accessoires-mode",
	},
	{ label: "Télécommunications", value: "telecommunications" },
	{ label: "Enseignement supérieur", value: "enseignement-superieur" },
	{ label: "Cabinet d’avocats", value: "cabinet-avocats" },
];
