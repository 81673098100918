import type { OfferFiltersType } from "@/types/offersFiltersTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getCookie, setCookie } from "@tools/Cookies";

const COOKIE_NAME = "offersFilters";
const filtersConsidered: string[] = [
	"status_list",
	"tags",
	"position",
	"visibility",
	"type",
	"referent",
	"contract",
];

const DEFAULT_CURRENT_FILTERS = {
	tags: undefined,
	location: undefined,
	position: undefined,
	visibility: undefined,
	referent: undefined,
	type: undefined,
	contract: undefined,
};

export interface OffersFiltersState {
	filtersCounter: number;
	currentFilters: OfferFiltersType;
	currentSearch: string;
	filterCategory: string;
	currentStructure: string;
	useFilters: boolean;
}

const loadFiltersUsingCookies = () => {
	const filters = getCookie(COOKIE_NAME);

	const newFilters = { ...DEFAULT_CURRENT_FILTERS };
	if (filters) {
		const { currentFilters } = JSON.parse(filters);
		if (currentFilters?.tags !== undefined) {
			newFilters.tags = currentFilters.tags;
		}
		if (currentFilters?.position !== undefined) {
			newFilters.position = currentFilters.position;
		}
		if (currentFilters?.location !== undefined) {
			newFilters.location = currentFilters.location;
		}
		if (currentFilters?.visibility !== undefined) {
			newFilters.visibility = currentFilters.visibility;
		}
		if (currentFilters?.type !== undefined) {
			newFilters.type = currentFilters.type;
		}
		if (currentFilters?.contract !== undefined) {
			newFilters.contract = currentFilters.contract;
		}
		if (currentFilters?.referent !== undefined) {
			newFilters.referent = currentFilters.referent;
		}
	}
	return newFilters;
};

const loadSearchUsingCookies = () => {
	const filters = getCookie(COOKIE_NAME);
	let newSearch = "";
	if (filters) {
		const { currentSearch } = JSON.parse(filters);
		if (currentSearch) {
			newSearch = currentSearch;
		}
	}
	return newSearch;
};

const initialState: OffersFiltersState = {
	filtersCounter: 0,
	currentFilters: loadFiltersUsingCookies(),
	currentSearch: "",
	currentStructure: "",
	filterCategory: "all",
	useFilters: true,
};

const resetState: OffersFiltersState = {
	filtersCounter: 0,
	currentFilters: DEFAULT_CURRENT_FILTERS,
	currentSearch: "",
	currentStructure: "",
	filterCategory: "all",
	useFilters: true,
};

export const reloadFiltersFromCookies = createAsyncThunk(
	"offersFilters/reloadFiltersFromCookies",
	async () => {
		const currentFiltersLoaded = loadFiltersUsingCookies();
		return currentFiltersLoaded;
	},
);

const offersFiltersSlice = createSlice({
	name: "offersFilters",
	initialState,
	reducers: {
		resetUsersFilters(state) {
			const currentStructureCopy = state.currentStructure;
			const currentSearchCopy = state.currentSearch;
			state.currentFilters = resetState.currentFilters;
			state.currentSearch = resetState.currentSearch;
			state.filtersCounter = resetState.filtersCounter;
			state.filterCategory = resetState.filterCategory;
			setCookie(COOKIE_NAME, JSON.stringify(state));
			// preserve structure and search
			if (currentStructureCopy !== undefined) {
				state.currentStructure = currentStructureCopy;
			}

			if (currentSearchCopy !== undefined) {
				state.currentSearch = currentSearchCopy;
			}
		},
		setFiltersCounter(state, action) {
			state.filtersCounter = action.payload;
		},
		setCurrentFilters(state, action) {
			state.currentSearch = initialState.currentSearch;
			state.currentFilters = action.payload;
			setCookie(COOKIE_NAME, JSON.stringify(state));
		},
		countCurrentFilters(state) {
			let count = 0;
			if (state.currentFilters === undefined) return;
			Object.keys(state.currentFilters).map(
				(filter: keyof typeof state.currentFilters) => {
					if (filtersConsidered.includes(filter)) {
						const filterValue =
							state.currentFilters[filter as keyof typeof state.currentFilters];
						if (filterValue !== undefined) {
							if (Array.isArray(filterValue)) {
								if (filterValue.length > 0) {
									count++;
								}
							} else {
								// you can add specific case here
								count++;
							}
						}
					}
					return count;
				},
			);
			state.filtersCounter = count;
		},
		setCurrentSearch(state, action) {
			state.currentSearch = action.payload;
			if (state.currentSearch === "" || state.currentSearch === undefined) {
				state.useFilters = true;
			} else state.useFilters = false;
			setCookie(COOKIE_NAME, JSON.stringify(state));
		},
		removeSpecificFilter(state, action) {
			const filterName = action.payload;
			if (state.currentFilters === undefined) return;
			if (
				state.currentFilters[
					filterName as keyof typeof state.currentFilters
				] !== undefined
			) {
				delete state.currentFilters[
					filterName as keyof typeof state.currentFilters
				];
				setCookie(COOKIE_NAME, JSON.stringify(state));
			}
		},
		setCurrentStructure(state, action) {
			state.currentStructure = action.payload;
		},
		performReloadOnSearch(state) {
			const currentSearchLoaded = loadSearchUsingCookies();
			state.currentSearch = currentSearchLoaded;
		},
		setUseFilters(state, action) {
			state.useFilters = action.payload;
			setCookie(COOKIE_NAME, JSON.stringify(state));
		},
	},
	extraReducers(builder) {
		builder.addCase(reloadFiltersFromCookies.fulfilled, (state, action) => {
			state.currentFilters = action.payload;
		});
	},
});

export const {
	setFiltersCounter,
	resetUsersFilters,
	setCurrentFilters,
	countCurrentFilters,
	removeSpecificFilter,
	setCurrentSearch,
	setCurrentStructure,
	performReloadOnSearch,
	setUseFilters,
} = offersFiltersSlice.actions;

export default offersFiltersSlice.reducer;
