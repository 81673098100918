import type { EventTagColorsType, EventType } from "@/types/eventsTypes";
import type { FormErrorType } from "@/types/genericType";
import {
	differenceInCalendarDays,
	differenceInHours,
	differenceInMinutes,
	formatDistanceToNow,
	isAfter,
	isBefore,
	isFuture,
	isPast,
	isToday,
	isValid,
} from "date-fns";
import { fr } from "date-fns/locale";

import BlueDefaultIllustration from "@assets/illustrations/events/blue-event.png";
import GreenDefaultIllustration from "@assets/illustrations/events/green-event.png";
import OrangeDefaultIllustration from "@assets/illustrations/events/orange-event.png";
import RedDefaultIllustration from "@assets/illustrations/events/red-event.png";
import YellowDefaultIllustration from "@assets/illustrations/events/yellow-event.png";

import type { BackgroundColorType, TextColorType } from "@/types/colorsType";
import type { EventInvitationType } from "@/types/eventsInvitationType";
import { checkUrl } from "./Checkers";

export const checkSeveralDaysEvent = (event: EventType): boolean => {
	const startDate = new Date(event.startDate);
	const endDate = new Date(event.endDate);
	return (
		startDate.getDate() === endDate.getDate() &&
		startDate.getMonth() === endDate.getMonth() &&
		startDate.getFullYear() === endDate.getFullYear()
	);
};

/**
 * Check if event is finished
 * @param event
 * @returns true if event is finished -> on site event finished since 1h or
 * distance event finished since 3 days without replay link
 */
export const checkEventIsFinished = (event: EventType): boolean => {
	const adjustedEndDate = new Date(event.endDate);
	const today = new Date();
	if (adjustedEndDate === undefined) return false;
	if (isAfter(adjustedEndDate, today)) return false;
	if (
		event?.location === "Sur site" &&
		differenceInHours(today, adjustedEndDate) >= 1
	) {
		return true;
	}

	if (event.location === "Distanciel" || event.location === "Hybride") {
		if (
			!event.replayLink &&
			differenceInCalendarDays(today, adjustedEndDate) >= 3
		) {
			return true;
		}
	}
	return false;
};

/**
 * Check if event has a replay link
 * @param event
 * @returns true if event has a replay link and is a distance or hybrid event
 * finished since 1h
 */
export const checkEventReplay = (event: EventType): boolean => {
	const endDate = new Date(event.endDate);
	const today = new Date();
	if (endDate === undefined) return false;
	if (isAfter(endDate, today)) return false;
	if (event?.location === "Distanciel" || event?.location === "Hybride") {
		if (event?.replayLink) return true;
		if (isToday(endDate)) {
			if (differenceInMinutes(today, endDate) >= 60) return true;
		} else {
			if (Math.abs(differenceInHours(today, endDate)) <= 72) return true;
		}
	}
	return false;
};

export const sortedEventsByDate = (events: EventType[]): EventType[] => {
	return [...events].sort((a, b) => {
		return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
	});
};

export const categorizePlannedEvents = (events: EventType[]) => {
	const today = new Date();
	const todayEvents: EventType[] = [];
	const upcomingEvents: EventType[] = [];

	for (const event of sortedEventsByDate(events)) {
		const eventDate = new Date(event.startDate);
		if (isBefore(eventDate, today) || isToday(eventDate)) {
			todayEvents.push(event);
		} else {
			upcomingEvents.push(event);
		}
	}

	return { todayEvents, upcomingEvents };
};

export const getDiplayableEventDate = (dateReceived: string): string => {
	const dateToDisplay = new Date(dateReceived);
	const today = new Date();
	if (
		dateToDisplay.getDate() === today.getDate() &&
		dateToDisplay.getMonth() === today.getMonth() &&
		dateToDisplay.getFullYear() === today.getFullYear()
	) {
		return "Aujourd'hui";
	}
	// check if the date is tomorrow and return "Demain" handle the case of the end of the month
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);
	if (
		dateToDisplay.getDate() === tomorrow.getDate() &&
		dateToDisplay.getMonth() === tomorrow.getMonth() &&
		dateToDisplay.getFullYear() === tomorrow.getFullYear()
	) {
		return "Demain";
	}

	if (isValid(dateToDisplay)) {
		const stringifiedDate = dateToDisplay.toLocaleDateString("fr-FR", {
			weekday: "long",
			day: "numeric",
			month: "long",
		});
		return stringifiedDate.charAt(0).toUpperCase() + stringifiedDate.slice(1);
	}
	return "";
};

export const getDisplayableEventTime = (event: EventType): string => {
	const startTime = new Date(event.startDate);
	const endTime = new Date(event.endDate);
	if (isValid(startTime) && isValid(endTime)) {
		const stringifiedStartTime = startTime.toLocaleTimeString("fr-FR", {
			hour: "2-digit",
			minute: "2-digit",
		});
		const stringifiedEndTime = endTime.toLocaleTimeString("fr-FR", {
			hour: "2-digit",
			minute: "2-digit",
		});
		return `${stringifiedStartTime} à ${stringifiedEndTime}`;
	}
	return "";
};

export const getTagColorFromEventType = (
	eventType: string,
): EventTagColorsType => {
	const colors = new Map<
		string,
		{ bgColor: BackgroundColorType; textColor: TextColorType }
	>([
		[
			"Mentor Goal - Conférence",
			{ bgColor: "bg-aquamarine-light", textColor: "text-black" },
		],
		[
			"Mentor Goal - Coaching",
			{ bgColor: "bg-aquamarine-light", textColor: "text-black" },
		],
		[
			"Mentor Goal - Atelier",
			{ bgColor: "bg-aquamarine-light", textColor: "text-black" },
		],
		["Forum emploi", { bgColor: "bg-emerald-light", textColor: "text-black" }],
		[
			"Coaching emploi",
			{ bgColor: "bg-terracotta-light", textColor: "text-black" },
		],
		["Conférence", { bgColor: "bg-sunflower-light", textColor: "text-black" }],
		["Jobdating", { bgColor: "bg-stone-light", textColor: "text-black" }],
		[
			"Salon étudiant",
			{ bgColor: "bg-lavender-light", textColor: "text-black" },
		],
		["Atelier", { bgColor: "bg-sakura-light", textColor: "text-black" }],
		[
			"Portes ouvertes",
			{ bgColor: "bg-emerald-light", textColor: "text-black" },
		],
		[
			"Évènement école",
			{ bgColor: "bg-lavender-light", textColor: "text-black" },
		],
	]);

	return (
		colors.get(eventType) || {
			bgColor: "bg-accent-1-light",
			textColor: "text-black",
		}
	);
};

export const checkEventStartedStatus = (event: EventType): boolean => {
	const startDate = new Date(event.startDate);
	const endDate = new Date(event.endDate);
	const today = new Date();
	if (startDate < today && endDate > today) return true;
	return false;
};

export const getDefaultEventIllustration = (
	defaultIllustration: string,
): string => {
	const defaultIllustrations = new Map([
		["blue", BlueDefaultIllustration],
		["green", GreenDefaultIllustration],
		["orange", OrangeDefaultIllustration],
		["red", RedDefaultIllustration],
		["yellow", YellowDefaultIllustration],
	]);

	return (defaultIllustrations.get(defaultIllustration) as string) || "";
};

export const checkAccessMentorGoalEvent = (userMail: string): boolean => {
	if (!userMail || userMail === "") return false;
	const splitMail = userMail.split("@");
	if (splitMail.length < 2) return false; // email is not valid, exemple ddlehacker@mentorgoal.com@ledomaine.fr
	const domain = userMail.split("@")[1];
	if (domain === "mentorgoal.com") return true;
	return false;
};

export const isMentorGoalEvent = (type: string): boolean => {
	return type.includes("Mentor Goal");
};

export const checkIfEventCouldBeCreated = (
	event: NonNullable<EventType>,
): FormErrorType[] => {
	const errorStack: FormErrorType[] = [];
	if (!event) {
		errorStack.push({
			id: "event",
			errorMessage: "Erreur dans la création de l'évènement",
		});
	}
	if (!event?.title) {
		errorStack.push({
			id: "title",
			errorMessage: "Titre manquant",
		});
	}
	if (!event?.startDate) {
		errorStack.push({
			id: "startDate",
			errorMessage: "Date de début manquante",
		});
	}
	if (!event?.endDate) {
		errorStack.push({
			id: "endDate",
			errorMessage: "Date de fin manquante",
		});
	}
	if (!event?.type) {
		errorStack.push({
			id: "type",
			errorMessage: "Type manquant",
		});
	}
	if (!event?.description) {
		errorStack.push({
			id: "description",
			errorMessage: "Description manquante",
		});
	}
	if (
		(event?.description && event.description.length < 10) ||
		event?.description === "<p><br></p>"
	) {
		errorStack.push({
			id: "description",
			errorMessage: "Description trop courte",
		});
	}
	if (!event?.location) {
		errorStack.push({
			id: "location",
			errorMessage: "Lieu manquant",
		});
	}
	if (!event?.illustration && !event?.defaultIllustration) {
		errorStack.push({
			id: "illustration",
			errorMessage: "Illustration manquante",
		});
	}
	if (event?.location === "Distanciel" || event?.location === "Hybride") {
		if (!event.eventLink) {
			errorStack.push({
				id: "eventLink",
				errorMessage: "Lien de l’évènement manquant",
			});
		}
		if (event?.eventLink && !checkUrl(event.eventLink)) {
			errorStack.push({
				id: "eventLink",
				errorMessage: "Lien de l’évènement invalide",
			});
		}
	}
	if (event?.location === "Sur site" || event?.location === "Hybride") {
		if (!event.address) {
			errorStack.push({
				id: "address",
				errorMessage: "Adresse manquante",
			});
		}
		if (event?.address && event.address?.length < 3) {
			errorStack.push({
				id: "address",
				errorMessage: "Adresse invalide",
			});
		}
	}
	return errorStack;
};

export const countEventParticipations = (event: EventType): number => {
	let count = 0;
	if (!event?.invitations?.collection) return count;
	if (event?.invitations?.collection?.length === 0) return count;
	for (const invitation of event.invitations.collection) {
		if (invitation?.hasParticipated) count++;
	}

	return count;
};

export const countEventConfirmations = (event: EventType): number => {
	let count = 0;
	if (!event?.invitations?.collection) return count;
	if (event?.invitations?.collection?.length === 0) return count;
	for (const invitation of event.invitations.collection) {
		if (invitation?.reply === "registered") count++;
	}
	return count;
};

export const getAccessButtonLabel = (event: EventType) => {
	if (checkEventAccessiblity(event)) {
		return "Accéder à l’évènement";
	}
	return `Démarre dans ${
		formatDistanceToNow(new Date(event.startDate), {
			locale: fr,
		}) || ""
	}`;
};

/**
 * return true if the event is accessible or false if not
 */
export const checkEventAccessiblity = (event: EventType) => {
	const eventStartDate = new Date(event.startDate);
	const eventEndDate = new Date(event.endDate);
	if (isPast(eventStartDate) && isPast(eventEndDate)) return false;
	if (isFuture(eventStartDate)) return false;
	if (
		isToday(eventStartDate) ||
		isToday(eventEndDate) ||
		isFuture(eventEndDate)
	) {
		const nowTime = new Date().getTime();
		const eventEndTime = eventEndDate.getTime();
		const diff = eventEndTime - nowTime;
		const diffMinutes = diff / 1000 / 60;
		return diffMinutes >= 0;
	}
	return false;
};

export const findStudentInvitation = (
	event: Pick<EventType, "invitations">,
	studentId: string,
): EventInvitationType | undefined => {
	let invitationFound: EventInvitationType | undefined;
	if (!event?.invitations?.collection) return;
	for (const invitation of event.invitations.collection) {
		if (invitation?.spsStudent?.id) {
			if (invitation.spsStudent.id === studentId) {
				invitationFound = invitation as EventInvitationType;
			}
		}
	}
	return invitationFound;
};

export const combineEvents = (
	EventCollectionA: EventType[],
	EventCollectionB: EventType[],
): EventType[] => {
	const combinedEvents: EventType[] = [
		...EventCollectionA,
		...EventCollectionB,
	];
	return combinedEvents;
};
