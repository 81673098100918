import type { EventType } from "@/types/eventsTypes";
import { InlineButton } from "@components/atoms/InlineButton";
import React from "react";
import type { useEventReplies } from "../useEventReplies";
import { useEventCounters } from "./useEventCounters";

type EventCountersProps = {
	event: EventType;
	currentInterface: string;
	repliesPresenter: ReturnType<typeof useEventReplies>;
};

export const EventCounters = ({
	event,
	currentInterface,
	repliesPresenter,
}: EventCountersProps) => {
	const countersPresenter = useEventCounters(event, currentInterface);

	return (
		<>
			{currentInterface === "school" ? (
				<InlineButton
					id="eventCounters"
					className="text-xsm font-bold text-primary-700P"
					label={countersPresenter.labelForCounterButton}
					onClick={() => {
						repliesPresenter.targetThisEvent(event);
						repliesPresenter.openRepliesModal(event);
					}}
				/>
			) : (
				<p className="text-xsm font-normal text-primary-500">
					{countersPresenter.participantsLabelForPlanned}
				</p>
			)}
		</>
	);
};
