import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { getOfferTypeOptions } from "@tools/Offers";

import { SelectSecondary } from "@components/organisms/selectSecondary/SelectSecondary";

import { countCurrentFilters, setCurrentFilters } from "../offersFiltersSlice";

export function TypeFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.offersFilters.currentFilters,
	);

	const handleChange = (typeSelected: string) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				type: typeSelected,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};
	return (
		<SelectSecondary
			position="right"
			defaultValue={
				getOfferTypeOptions(true)?.find(
					(type) => type.value === currentFilters?.type,
				) || { value: "", label: "Type" }
			}
			options={[
				{
					value: null,
					label: "Tous",
				},
				...getOfferTypeOptions(true),
			]}
			onChange={(e) => {
				handleChange(e.value);
			}}
		/>
	);
}
