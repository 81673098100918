export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toCamel(string: string) {
	const capitalize = (str: string) =>
		str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
	const stringToCamel = string
		.toLowerCase()
		.replace(/[^A-Za-z0-9]/g, " ")
		.split(" ")
		.reduce((result, word) => result + capitalize(word.toLowerCase()));
	return stringToCamel.charAt(0).toLowerCase() + stringToCamel.slice(1);
}

export function disableAccent(string: string) {
	return string
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLowerCase();
}

export const formatRichTextEditor = (content: string) => {
	const newContent = content
		.replaceAll("<ul>", '<ul class="pl-6 list-disc">')
		.replaceAll("<ol>", '<ol class="pl-6 list-decimal">')
		.replaceAll(
			'<p class="ql-align-right">',
			'<p class="ql-align-right text-right">',
		)
		.replaceAll(
			'<p class="ql-align-center">',
			'<p class="ql-align-center text-center">',
		)
		.replaceAll(
			'<p class="ql-align-justify">',
			'<p class="ql-align-justify text-justify">',
		);
	return newContent;
};
