import { type RefObject, useEffect, useState } from "react";

export function useElementVisibility(ref: RefObject<HTMLElement>) {
	const [isVisible, setIsVisible] = useState(false);
	const callbackFunction = (entries: IntersectionObserverEntry[]) => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	const options = {
		root: null,
		rootMargin: "0px",
		treshold: 0.1,
	};

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options);
		if (ref?.current) observer.observe(ref?.current);
		return () => {
			if (ref?.current) observer.unobserve(ref?.current);
		};
	}, [ref, options]);

	return isVisible;
}
