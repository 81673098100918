import { Icon } from "@iconify/react";
import { fr } from "date-fns/locale";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { DatePicker } from "react-nice-dates";

import "@assets/sass/Inputs.scss";

import "react-nice-dates/build/style.css";

// Degager et remplacer par DatePicker
export function InputDatePicker({
	disabled,
	icon,
	onChange,
	className,
	label,
	value,
	errorMessage,
}) {
	const [date, setDate] = useState();
	useEffect(() => {
		if (value) setDate(new Date(value));
		else setDate();
		window.resizeTo(1280, 720);
	}, [value]);

	const changeDate = (newDate) => {
		setDate(newDate);
		onChange(newDate);
	};

	return (
		<div
			data-id="inputDatePicker"
			className={`input-date-picker w-full ${className}`}
		>
			<DatePicker date={date} onDateChange={changeDate} locale={fr}>
				{({ inputProps, focused }) => (
					<div className="flex flex-col">
						{label && (
							<p
								className={`text-p mb-xxsm text-primary-300 ${
									disabled && " is-disabled"
								}`}
							>
								{label}
							</p>
						)}
						<div className="input-with-icon">
							{icon && (
								<Icon
									className={`icon-input-tertiary ${
										disabled && " is-disabled"
									}`}
									icon={icon}
								/>
							)}
							<input
								type="date"
								id="name"
								name="name"
								autoComplete="off"
								onChange={onChange}
								className={`input-tertiary ${focused ? " -focused" : ""} ${
									icon && " with-icon"
								} ${errorMessage && "error-message"} `}
								{...inputProps}
								placeholder="jj/mm/aaaa"
							/>
							{errorMessage && (
								<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
							)}
						</div>
					</div>
				)}
			</DatePicker>
		</div>
	);
}
InputDatePicker.propTypes = {
	disabled: PropTypes.bool,
	value: PropTypes.string,
	icon: PropTypes.shape({}),
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
	errorMessage: PropTypes.string,
};
InputDatePicker.defaultProps = {
	disabled: false,
	icon: null,
	className: "",
	label: "",
	value: "",
	errorMessage: "",
};
