import type { UnsolvedConversationCounterType } from "@/types/mailbox";
import { MenuNavigation } from "@navigation/MenuNavigation";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SlidingPane from "react-sliding-pane";

import "./SlidingPanel.scss";

import { menuOutline } from "@assets/Icons";

import { getCookie, setCookie } from "@tools/Cookies";
import { extractIdNumberFromId, resolveView } from "@tools/Users";

import {
	HeaderSchool,
	HeaderSmallPrimary,
} from "@components/organisms/header/Header";

import { useBreakpoints } from "../../../hooks/useBreakpoints";
import { fetchUnsolvedConversationCounter } from "../Mailbox/controller/mailBoxManagement.thunks";

import {
	retrieveCampuses,
	updateLogsConsultationLastDate,
} from "./SchoolLayoutAPI";
import { SlidingPanelContent } from "./SlidingPanelContent";

import { crispSettings } from "@config/CrispSettings";
import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import "react-sliding-pane/dist/react-sliding-pane.css";
import type { CampusType } from "@/types/campusTypes";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";
import { fetchReferents } from "../Referents/core/usecases/fetchReferents.usecase";

export type SchoolLayoutContextType = {
	title: string;
	setTitle: (param: string) => string;
	structureView: object;
	setStructureView: (param: object) => object;
	currentInterface: string;
};

export const SchoolLayout = () => {
	const location = useLocation();
	const dispatchMailBox = useAppDispatch();

	const { currentSearch, totalUnsolvedConversationCount } = useAppSelector(
		(state) => state.mailBox,
	);
	const {
		user,
		setUser,
		setCampuses,
		currentInterface,
		setCurrentInterface,
		slidingPaneIsOpen,
		setSlidingPaneIsOpen,
	} = useContext(GlobalContext);

	const [notifCount, setNotifCount] = useState(user?.newLogsCount);
	const [conversationCount, setConversationCount] = useState<number>(0);
	const { isMobile } = useBreakpoints(540);
	const [title, setTitle] = useState(
		`Bienvenue sur votre espace ${user?.schoolName}`,
	);

	const [structureView, setStructureView] = useState(
		user?.roles.includes("ROLE_SCHOOL")
			? { school: user?.school }
			: { campus: user?.campuses[0].id },
	);

	const [accessList, setAccessList] = useState<any>();

	const dispatchEvent = useAppDispatch();

	const fetchCampuses = async () => {
		const options = [];

		if (
			user?.roles.includes("ROLE_SCHOOL") ||
			user?.roles.includes("ROLE_CAMPUS_ADMIN")
		) {
			options.push({
				label: "Tous les campus",
				value: { school: user?.school },
			});
		}
		let campuses: any = await retrieveCampuses(user?.school);
		campuses = campuses.filter((campus: any) => {
			return (
				user?.roles.includes("ROLE_SCHOOL") ||
				user?.campuses.find((userCampus: CampusType) => {
					return userCampus.id === campus.id;
				})
			);
		});
		setCampuses(campuses);

		campuses.forEach((campus: any) => {
			options.push({
				label: campus.name,
				value: { campus: campus.id },
			});
		});
		setAccessList(options);
	};

	const toggleNotif = () => {
		if (user) {
			setSlidingPaneIsOpen(true);
			setNotifCount(0);
			setUser({ ...user, newLogsCount: 0 });
			updateLogsConsultationLastDate(user.id);
		}
	};

	const scrollWhenClosePanel = () => {
		const bodyElement = document.getElementsByTagName("body")[0];
		bodyElement.style.overflow = "auto";
	};

	useEffect(() => {
		fetchCampuses();
		setCurrentInterface("school");

		if (user)
			dispatchEvent(eventsSlice.actions.setIsMentorGoalUser(user?.email));

		if (slidingPaneIsOpen) {
			const bodyElement = document.getElementsByTagName("body")[0];
			bodyElement.style.overflow = "hidden";
		}
		const crispElement = document.getElementById("crisp-chatbox");
		if (crispElement) crispElement.classList.toggle("cc-tlyw", true);
	}, [slidingPaneIsOpen]);

	useEffect(() => {
		document.title = `${title} - Mentor Goal`;
	}, [title]);

	const getCountTab = async () => {
		dispatchMailBox(
			fetchUnsolvedConversationCounter({
				search: location.pathname !== "/mail-box" ? "" : currentSearch,
				...(resolveView(
					structureView,
					"spsPromotion",
				) as UnsolvedConversationCounterType),
			}) as any,
		);
	};
	useEffect(() => {
		getCountTab();
		if (location.pathname !== "/mail-box") {
			setConversationCount(
				typeof totalUnsolvedConversationCount === "number"
					? totalUnsolvedConversationCount
					: 0,
			);
		}
		if (user) {
			crispSettings(user, "referent");
		}
	}, [
		location.pathname,
		user,
		structureView.school,
		totalUnsolvedConversationCount,
	]);

	const retrieveAllReferents = async () => {
		const campusId = user?.campuses;
		const campusList = [];
		if (campusId) {
			campusList.push(extractIdNumberFromId(campusId[0].id).toString());
		}
		dispatchEvent(fetchReferents(campusList, `${user?.school}`) as any);
	};
	useEffect(() => {
		retrieveAllReferents();
	}, [structureView]);

	return user ? (
		<div>
			<MenuNavigation user={user} currentInterface={currentInterface} />
			<HeaderSchool
				title={title}
				notifCount={notifCount ?? 0}
				messageCount={conversationCount}
				structureView={structureView}
				accessList={accessList}
				onChange={(e) => {
					if (getCookie("StudentsManagementFilters")) {
						const filters = JSON.parse(
							getCookie("StudentsManagementFilters") ?? "",
						);
						delete filters.promotion_list;
						setCookie("StudentsManagementFilters", JSON.stringify(filters));
					}
					setStructureView(e.value);
				}}
				onClick={() => {
					toggleNotif();
				}}
				onClickMessaging={() => {
					setConversationCount(0);
				}}
			/>
			<HeaderSmallPrimary
				title={title}
				notifCount={notifCount ?? 0}
				onClick={() => {
					toggleNotif();
				}}
				buttonIcon={menuOutline}
			/>
			<Outlet context={{ structureView, setTitle, currentInterface }} />
			<SlidingPane
				hideHeader
				isOpen={slidingPaneIsOpen}
				from="right"
				width={isMobile ? "100%" : "35%"}
				onRequestClose={() => {
					setSlidingPaneIsOpen(false);
					scrollWhenClosePanel();
				}}
				shouldCloseOnEsc
			>
				<SlidingPanelContent
					structureView={structureView}
					setSlidingPaneIsOpen={setSlidingPaneIsOpen}
					scrollWhenClosePanel={scrollWhenClosePanel}
				/>
			</SlidingPane>
		</div>
	) : (
		<div />
	);
};
