import type {
	promotionOptionType,
	promotionType,
} from "@/types/promotionTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router";

import {
	type UsersFiltersState,
	countCurrentFilters,
	removeSpecificFilter,
	setCurrentFilters,
	setCurrentStructure,
} from "@components/organisms/usersFilters/usersFiltersSlice";

import { StudentsManagementContext } from "../../../../containers/school/Students/StudentsManagement";
import { resolveView } from "../../../../tools/Users";
import { Select } from "../../select/Select";
import { getPromotions } from "../UsersFiltersAPI";

export function PromotionsFilter() {
	const { structureView }: any = useOutletContext();
	const studentManagementContext = useContext(StudentsManagementContext);
	const [fetchedPromotions, setFetchedPromotions] = useState<promotionType[]>(
		[],
	);
	const [promotionsOptions, setPromotionsOptions] = useState<
		promotionOptionType[]
	>([]);

	const dispatchEvent = useDispatch();
	const { currentFilters, currentStructure } = useAppSelector(
		(state) => state.usersFilters as UsersFiltersState,
	);

	const getPromotionsForSelect = () => {
		let promotionsSelectOptions: promotionOptionType[] = [];
		promotionsSelectOptions = fetchedPromotions?.map((promo) => {
			return currentFilters?.spsPromotion_list?.includes(promo.id)
				? {
						value: promo.name,
						label: promo.name,
						type: "checkbox",
						checked: true,
					}
				: {
						value: promo.name,
						label: promo.name,
						type: "checkbox",
						checked: false,
					};
		}) as promotionOptionType[];
		return promotionsSelectOptions;
	};
	const retrievePromotions = () => {
		getPromotions(resolveView(structureView)).then(
			({ collection }: { collection: promotionType[] }) => {
				setFetchedPromotions(collection);
			},
		);
	};

	const handleStructureModification = (structureId: string) => {
		dispatchEvent(setCurrentStructure(structureId));
		dispatchEvent(removeSpecificFilter("promotion_list"));
		dispatchEvent(countCurrentFilters());

		retrievePromotions();
	};

	useEffect(() => {
		if (structureView?.school && structureView?.school !== currentStructure) {
			handleStructureModification(structureView.school);
		} else if (
			structureView?.campus &&
			structureView?.campus !== currentStructure
		) {
			handleStructureModification(structureView.campus);
		} else {
			retrievePromotions();
		}
	}, [structureView, studentManagementContext.promotions]);

	useEffect(() => {
		setPromotionsOptions(getPromotionsForSelect());
	}, [fetchedPromotions, currentFilters]);

	const handleChange = (promotionsSelected: promotionOptionType[]) => {
		setPromotionsOptions(
			promotionsOptions?.map((promotion) => {
				return promotionsSelected?.includes(promotion)
					? { ...promotion, checked: true }
					: { ...promotion, checked: false };
			}),
		);
		const promotionSelectedIds: string[] = promotionsSelected
			.map((promotion) => {
				const matchedPromotion = fetchedPromotions.find(
					(fetchedPromotion) => fetchedPromotion.name === promotion.value,
				);
				return matchedPromotion ? matchedPromotion.id : "";
			})
			.filter((id) => id !== ""); // Filter out any empty strings

		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				spsPromotion_list: promotionSelectedIds,
				page: 1,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	return (
		<>
			<Select
				position="right"
				placeholder="Promotions"
				inputs={promotionsOptions}
				multiselect={true}
				withSearchbar
				breakSpaces
				onChange={handleChange}
				searchbarPlaceholder="Rechercher une promo"
				className="wg-no-translate"
			/>
		</>
	);
}
