import type { UserCompleteType } from "@/types/userTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { StandardGraphQLResponse } from "@tools/API";

import type { ViewType } from ".";
import { getStudentsManagementData } from "./StudentsManagementAPI";

export type FetchStudensListParamsType = {
	formatView: ViewType;
	filters: Partial<FiltersType>;
};
export const fetchStudentsList = createAsyncThunk(
	"studentsManagementAPI/fetchStudentsList",
	async (params: FetchStudensListParamsType) => {
		const data: StandardGraphQLResponse = await getStudentsManagementData(
			params.formatView,
			params.filters,
		);
		if (data.responseLabel === "error") {
			throw new Error("aborted");
		}

		return data;
	},
);

export interface StudentsManagementState {
	studentsList: UserCompleteType[];
	requestedUsersCount: number;
	requestedUsersCountLoading: boolean;
	studentsListLoading: boolean;
	studentsListInitialised: boolean;
	loadStudentsListError: string;
	studentsListLastPage: number;
	studentsListTotalCount: number;
}

const initialState: StudentsManagementState = {
	studentsList: [],
	requestedUsersCount: 0,
	requestedUsersCountLoading: true,
	studentsListLoading: false,
	studentsListInitialised: false,
	loadStudentsListError: "",
	studentsListLastPage: 0,
	studentsListTotalCount: 0,
};

const studentsManagementSlice = createSlice({
	name: "studentsManagement",
	initialState,
	reducers: {
		setStudentsList(state, action) {
			state.studentsList = action.payload;
		},
		setRequestedUsersCount(state, action) {
			state.requestedUsersCount = action.payload;
		},
		setRequestedUsersCountLoading(state, action) {
			state.requestedUsersCountLoading = action.payload;
		},

		setStudentsListLoading(state, action) {
			state.studentsListLoading = action.payload;
		},
		setStudentsListInitialised(state, action) {
			state.studentsListInitialised = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchStudentsList.pending, (state) => {
			state.studentsListLoading = true;
		});
		builder.addCase(fetchStudentsList.fulfilled, (state, action) => {
			state.loadStudentsListError = "";
			state.studentsList = action.payload.data.users.collection;

			state.studentsListTotalCount =
				action.payload.data.users.paginationInfo.totalCount;
			state.studentsListLastPage =
				action.payload.data.users.paginationInfo.lastPage;
			state.studentsListLoading = false;
		});
		builder.addCase(fetchStudentsList.rejected, (state, action) => {
			if (action.error.message === "aborted") {
				// stay in loading state because a new request is running
				state.studentsListLoading = true;
			}
			state.loadStudentsListError = action.error.message || "";
		});
	},
});

export const {
	setStudentsList,
	setRequestedUsersCount,
	setRequestedUsersCountLoading,
	setStudentsListLoading,
	setStudentsListInitialised,
} = studentsManagementSlice.actions;

export default studentsManagementSlice.reducer;
