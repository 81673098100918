import { Icon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useEffect } from "react";

import { alertTriangleFill, eyeFill, infoOutline } from "@assets/Icons";

import { convertDateFromNow } from "@tools/Dates";

import { Badge } from "@components/atoms/Badge";

export function TagState({
	application,
	setShowOnHoldModal,
	setShowSeeMotif,
	setShowUpdateModal,
}) {
	useEffect(() => {}, [application]);

	switch (application.state) {
		case "accepted":
			return (
				<div>
					<div className="flex items-center">
						<Badge
							label="Envoyée par l'école"
							className="mr-xsm"
							bgColor="bg-success-light"
							hasBorder
						/>
						<p className="text-xxxsm text-primary-200">
							{convertDateFromNow(application.updateAt)}
						</p>
					</div>
					<div className="mb-lg mt-xsm flex items-center">
						<p className="mr-xxsm text-xsm font-bold text-primary-700P">
							Ta candidature a été acceptée ou créée par ton école, qui l&apos;a
							transmise à l&apos;entreprise.
						</p>
						<Tippy
							content="Consulte le detail et note 3 points à mettre en avant si tu reçois un appel du recruteur."
							theme="primary"
							animation="fade"
							placement="top"
							zIndex={10}
						>
							<span>
								<Icon className="text-primary-150" icon={infoOutline} />
							</span>
						</Tippy>
					</div>
				</div>
			);
		case "onHold":
			return (
				<div className="mb-sm">
					<div className="flex items-center">
						<Badge
							label="Necessite une mise à jour"
							leftIcon={alertTriangleFill}
							className="mr-xsm"
							bgColor="bg-warning-light"
							hasBorder
						/>
						<p className="text-xxxsm text-primary-200">
							{convertDateFromNow(application.updateAt)}
						</p>
					</div>
					<p className="mt-xsm text-xsm text-primary-700P">
						Ton école a besoin que tu mettes à jour ta candidature pour
						déterminer si elle va l&apos;envoyer ou non à son entreprise
						partenaire.
					</p>
					<div
						onClick={() => {
							setShowOnHoldModal(true);
						}}
						className="right-part-link mt-xsm"
					>
						<Icon icon={eyeFill} />
						<p className="link-label ml-xsm">
							Voir le motif et mettre à jour ma candidature
						</p>
					</div>
				</div>
			);
		case "refused":
			return (
				<div className="mb-sm">
					<div className="flex items-center">
						<Badge
							label="Non selectionnée par l'école"
							className="mr-xsm"
							bgColor="bg-error-light"
							hasBorder
						/>
						<p className="text-xxxsm text-primary-200">
							{convertDateFromNow(application.updateAt)}
						</p>
					</div>
					<p className="mt-xsm">
						Ta candidature n&apos;as pas été sélectionnée par ton école, elle ne
						va pas la transmettre à l&apos;entreprise
					</p>
					<div
						onClick={() => {
							setShowSeeMotif(true);
						}}
						className="right-part-link mt-xsm"
					>
						<Icon icon={eyeFill} />
						<p className="link-label ml-xsm">Voir le motif</p>
					</div>
				</div>
			);
		case "update":
			return (
				<div className="mb-sm">
					<p className="text-xsm text-primary-700P">
						Tu as mis à jour ta candidature{" "}
						{convertDateFromNow(application.updateAt)}
					</p>
					<div
						onClick={() => {
							setShowUpdateModal(true);
						}}
						className="right-part-link mt-xsm"
					>
						<Icon icon={eyeFill} />
						<p className="link-label ml-xsm">
							Voir les informations mises à jour
						</p>
					</div>
				</div>
			);
		default:
			return null;
	}
}
