import { useBreakpoints } from "@hooks/useBreakpoints";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

import { PaginationPrimary } from "@components/molecules/PaginationPrimary";
import { ApplicationItem } from "@components/organisms/ApplicationItem";
import { Success } from "@components/organisms/toast/ToastNotification";
import { Table } from "@components/templates/table/Table";

import { editApplication } from "../../ApplicationsManagementAPI";

export function ListView({ applicationsProps, filtersProps, load, lastPage }) {
	const { isMobile } = useBreakpoints();
	const { applicationsListView } = applicationsProps;
	const { filters, setFilters } = filtersProps;
	const [page, setPage] = useState(filters.page ? filters.page : 1);
	const topPageRef = useRef();

	const navigate = useNavigate();

	const headers = [
		{
			label: "Candidature",
			isMobile: true,
		},
		{
			label: "Prochaine action",
			isMobile: false,
		},
	].filter((header) => {
		return !isMobile || (isMobile && header.isMobile);
	});

	const changeApplicationStatus = (applicationId, newStep) => {
		editApplication({
			id: applicationId,
			step: newStep,
		}).then(() => {
			Success("Le status de votre candidature a bien été mis à jour.");
		});
	};

	const resolveAction = (action) => {
		return action ? action.title : "Non renseigné";
	};

	const cellsResolver = (application) => {
		return [
			{
				children: (
					<ApplicationItem
						application={application}
						companyName={application?.organization?.name}
						onClick={() => {
							navigate(`/applications/${application.id.split("/")[3]}`);
						}}
						className="max-w-[350px] md:max-w-[600px] xl:max-w-[1000px]"
						onClickOnStep={(e) => {
							changeApplicationStatus(application.id, e);
						}}
					/>
				),
			},
			{
				children: resolveAction(application.actions.collection[0]),
			},
		];
	};

	useEffect(() => {}, [applicationsListView]);

	return (
		<div ref={topPageRef}>
			<Table
				isLoading={load}
				headers={headers}
				filtersProps={{ filters, setFilters }}
				rows={applicationsListView?.map((application) => {
					return {
						url: `/applications/${application.id.split("/")[3]}`,
						cells: cellsResolver(application),
					};
				})}
			/>
			<PaginationPrimary
				current={page}
				onChange={(newPage) => {
					setFilters({ ...filters, page: newPage });
					setPage(newPage);
					topPageRef.current.scrollIntoView({ behavior: "smooth" });
				}}
				last={lastPage}
				className="py-md"
			/>
		</div>
	);
}
