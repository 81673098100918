import { Icon } from "@iconify/react";
import React from "react";

type InfoProps = {
	text: string;
};

export const Info = ({ text }: InfoProps) => {
	return (
		<div className="flex gap-4 bg-blue-50 p-4">
			<div>
				<Icon icon="eva:info-fill" className="text-2xl text-blue-500" />
			</div>
			<p>{text}</p>
		</div>
	);
};
