import React from "react";

import { Files } from "./Files";
import { Informations } from "./Informations";

export const SlidingPaneContent = () => {
	return (
		<div className="flex flex-1 flex-row gap-4 pr-10 pl-16">
			<Informations />
			<Files />
		</div>
	);
};
