import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import { checkmarkCircle2Fill } from "@assets/Icons";

type InputCopyType = {
	URI: string;
	label?: string;
};

const AUTORESET_TIMEOUT = 4000;

export function InputCopy({ URI, label }: InputCopyType) {
	const [clipboard, setClipboard] = useState(false);

	const fullUrl = URI.includes(import.meta.env.VITE_URL)
		? decodeURIComponent(URI)
		: `${import.meta.env.VITE_URL}${decodeURIComponent(URI)}`;

	const handleCopy = () => {
		navigator.clipboard.writeText(fullUrl);
		setClipboard(true);
	};

	useEffect(() => {
		if (clipboard) {
			setTimeout(() => {
				setClipboard(false);
			}, AUTORESET_TIMEOUT);
		}
	}, [clipboard]);

	useEffect(() => {
		setClipboard(false);
	}, [URI]);

	return (
		<div className="flex flex-col gap-xsm" data-testid="input-copy">
			{label && <p className="text-xxsm text-primary-300">{label}</p>}
			<div className="translate-all flex items-center rounded bg-transparent px-sm py-xsm shadow-sm hover:bg-primary-50">
				<p
					className="items-center truncate flex-1 text-xsm text-primary-500"
					data-testid="urlToCopy"
				>
					{fullUrl}
				</p>
				<hr className="vertical-separator mr-xsm !ml-auto" />
				<button
					data-testid="copyButton"
					className={`text-xsm font-bold ${
						clipboard && "success text-success-darker"
					} cursor-pointer`}
					onClick={handleCopy}
				>
					{`${clipboard ? "Copié" : "Copier"}`}
				</button>
				{clipboard && (
					<Icon
						className="ml-xxsm h-4 text-success-darker"
						icon={checkmarkCircle2Fill}
					/>
				)}
			</div>
		</div>
	);
}
