import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { TagType } from "@/types/tagTypes";
import type { UserType } from "@/types/userTypes";
import axios from "axios";
import uuid from "react-uuid";

import api, { graphqlRequestConstructor } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function getCV(id: string) {
	const data = {
		query: `query {
            user: spsStudent(id: "${id}") {
                id
                cv {
                    id
                    filePath
                    createdAt
                    size
                }
            }
        }`,
	};
	const rep = await api.post("graphql", data);
	const cv: MediaObjectType = rep.data.data.user.cv;
	return cv;
}

export async function getSearchStudent(id: string) {
	const data = {
		query: `query {
            user: spsStudent(id: "${id}") {
                id
                desiredJob
                contractSearched
                desiredStartOfWork
                geoAreaOfResearch
                city
                mobility
                mobilityRadius
              }
        }`,
	};
	const response = await api.post("graphql", data);
	const user: UserType = response.data.data.user;
	return user;
}

export async function sendFile(formData: object) {
	const response = await api.post("media_objects", formData);
	const media: MediaObjectType = response.data;
	return media;
}

export async function getUserStatusLogs(userId: string) {
	try {
		const request = {
			query: `query {
                logs(
                    action_list: [
                        "studentUpdatesStudentStatus"
                        "schoolRefusesStudentStatus"
                        "schoolAcceptsStudentStatus"
                        "schoolChangesStudentStatus"
                    ]
                    spsStudent:"${userId}"
                    itemsPerPage: 1
                    order: [{ createdAt: "DESC" }]
                ) {
                    collection {
                        id
                        action
                        createdAt
                    }
                }
            }`,
		};
		const { data } = await api.post("graphql", request);
		return data;
	} catch (e) {
		throw new Error(e);
	}
}

export async function manageCareer(
	operation: string,
	type: string,
	inputs: object,
) {
	const data = {
		query: `mutation {
          ${operation}(input: ${stringifyRequestInputs(inputs)}) {
            ${type} {
                id
            }
          }
        }`,
	};
	const rep = await api.post("graphql", data);
	return rep.data.data;
}

export async function deleteFormation(formationId: string) {
	const data = {
		query: `mutation {
            deleteFormation(input: {id:"${formationId}"}) {
                formation {
                    id
                }
            }
          }`,
	};
	const rep = await api.post("graphql", data);
	return rep.data;
}

export async function getSchoolTags(school: string) {
	const data = {
		query: `query{
            hardSkills: tags(school:"${school}", type:"hardSkill", itemsPerPage: 1000){
                collection{
                  id
                  name
                  color
                  description
                  type
              }
            }
            softSkills: tags(school:"${school}", type:"softSkill", itemsPerPage: 1000){
              collection{
                id
                name
                color
                description
                type
            }
          }
            
          }`,
	};
	const rep = await api.post("graphql", data);

	const hardSkills: TagType[] = rep.data.data.hardSkills.collection;
	const softSkills: TagType[] = rep.data.data.softSkills.collection;

	return { hardSkills, softSkills };
}

export async function autoComplet(value = "") {
	const rep = await axios.get(
		`https://autocomplete.clearbit.com/v1/companies/suggest?query=${value}`,
	);
	return rep.data;
}

export async function fetchStepsToCompleteData(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        applicationsCount
        contactsCount
        actionsCount
        desiredJob
        desiredStartOfWork
        geoAreaOfResearch
        formations: spsFormations {
          paginationInfo {
            totalCount
          }
        }
        cv {
          id
        }
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "fetchStepsToCompleteData");

	return rep.data.user;
}

export async function fetchGoalsData(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        thisWeekApplicationsCount
        thisWeekRelaunchsCount
        thisWeekContactsCount
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "fetchGoalsData");

	return rep.data.user;
}

export async function fetchStatisticsData(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        thisWeekApplicationsCount
        lastWeekApplicationsCount
        thisWeekRelaunchsCount
        lastWeekRelaunchsCount
        thisWeekInterviewsCount
        lastWeekInterviewsCount
        thisWeekContactsCount
        lastWeekContactsCount
        applicationsCount
        relaunchsCount
        contactsCount
        interviewsCount
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "fetchStatisticsData");

	return rep.data.user;
}

export async function fetchProfileData(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        id
        firstname
        lastname
        avatar {
        	id
        	filePath
        }
        promotion: spsPromotion {
          name
        }
        birthdate
        email
        phone
        address
        linkedin
        portfolio
        mobility
        status
        studentStatus
        streetNumber
        streetName
        longitude
        latitude
        country
        city
        postalCode
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "fetchProfileData");

	return rep.data.user;
}

export async function updateSpsStudent(inputs: object) {
	const data = {
		query: `mutation {
      updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
        user: spsStudent {
          id
        } 
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(
		data,
		`updateSpsStudent-${uuid()}`,
	);

	return rep.data.user;
}

export async function updateTags(inputs: object) {
	const data = {
		query: `mutation {
      updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
        user: spsStudent {
          id
        } 
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "updateTags");

	return rep.data.user;
}

export async function getHistoryUser(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        id
        firstname
        lastname
        avatar {
          filePath
        }
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "getHistoryUser");

	return rep.data.user;
}

export async function getUserDocuments(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        id
        cv {
          id
          filePath
          size
        }
        motivationLetter {
          id
          filePath
          size
        }
        portfolioPdf {
          id
          filePath
          size
        }
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "getUserDocuments");

	return rep.data.user;
}

export async function getUserTags(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        id
        tags
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "getUserTags");

	return rep.data.user;
}

export async function getCareerProfile(id: string) {
	const data = {
		query: `query {
      user: spsStudent(id: "${id}") {
        professionalExperiences: spsProfessionalExperiences {
          collection {
            id
            job
            organizationV2
            contract
            startDate
            endDate
            detail
          }
        }
        formations: spsFormations {
          collection {
            id
            name
            schoolName
            startDate
            endDate
            city
            detail
          }
        }
        projects: spsProjects {
          collection {
            id
            name
            detail
            url
          }
        }
      }
    }`,
	};
	const rep = await graphqlRequestConstructor(data, "getHistoryUser");

	return rep.data.user;
}
