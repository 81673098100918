import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import api, { checkGraphQLError } from "@tools/API";

export async function querySelections(stringifyFilters: string) {
	let rep = {};
	const data = {
		query: `query {
        collectionQuerySelections (itemsPerPage: 10, ${stringifyFilters}, order: [{sendDate: "DESC"}]) {
          collection {
            id
            name
            sendDate
            crmCompany {
            	name
            }
            lastOpening
            studentsCount
            consultedEntities
            spsReferent {
				id
				firstname
				lastname
				avatar {
					filePath
				}
            }
            token
          }
          paginationInfo {
            lastPage
          }
        },
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.collectionQuerySelections;
	});
	return rep;
}

export async function querySelectionEmailTrackingAndConsultedEntities(
	selectionId: string,
) {
	let rep = {};
	const data = {
		query: `query {
        selection (id: "${selectionId}") {
			id
			emailsTracking
			consultedEntities
		}
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.selection;
	});
	return rep;
}

export async function querySelection(selectionId: string) {
	let rep = {};
	const data = {
		query: `query {
        selection (id: "${selectionId}") {
			id
			name
			sendDate
			crmCompany {
				name
			}
			lastOpening
			consultedEntities
			type
			action
			spsReferent {
				id
				firstname
				lastname
				avatar {
					filePath
				}
			}
			coreCampuses {
			  collection {
					name
			  }  
			}
			emailsTracking
			selectionUsers (itemsPerPage: 1000) {
			  collection {
				comment
				status
				stars
				consultedTimes
				user: spsStudent {
				  id
				  firstname
				  avatar {
					filePath
				  }
				  lastname
				  cv {
					filePath
				  }
				}
			  }
			}
			token
		  }
      }`,
	};
	await api
		.post("graphql", data)
		.then(({ data }) => {
			rep = data.data.selection;
		})
		.catch((_e) => {
			ErrorToast(
				"Une erreur est survenue lors de la récupération de la sélection",
			);
		});
	return rep;
}

type ResponseType = {
	status?: 200;
	statusText?: string;
	data: {
		data?: {
			deleteSelection: {
				selection: { id: string };
			};
		};
		error?: {
			message: string;
		};
		errors?: {
			message: string;
		}[];
	};
};

export async function deleteSelectionAPI(id: string) {
	let rep: ResponseType;
	const data = {
		query: `mutation {
        deleteSelection(input: {id:"${id}"}) {
          selection {
            id
          }
        },
      }`,
	};
	try {
		rep = await api.post("graphql", data);
		if (rep.data.errors) {
			throw {
				responseLabel: "error",
				data: {},
				message: `Une erreur est survenue : ${rep.data.errors[0].message}`,
			};
		}
		return rep.data.data;
	} catch (error) {
		throw {
			responseLabel: "error",
			data: {},
			message: `Une erreur est survenue : ${error.message}`,
		};
	}
}

export async function getSchoolSubDomain(school: string) {
	let rep = {};
	const data = {
		query: `query {
      school (id: "${school}") {
          id
          subdomain
      }
    }`,
	};
	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}
