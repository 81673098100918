import { Icon, type IconifyIcon } from "@iconify/react";
import React, { type MouseEventHandler } from "react";

import "@assets/sass/Button.scss";

import { Spinner } from "./Spinner";

type ButtonSecondaryPropsType = {
	label?: string;
	className?: string;
	disabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	dropdownButton?: boolean;
	img?: string;
	icon?: IconifyIcon;
	size?: "small" | "large";
	iconDirection?: "left" | "right";
	isLoading?: boolean;
};

export function ButtonSecondary({
	label = "",
	className = "",
	disabled = false,
	onClick,
	dropdownButton = false,
	img,
	icon,
	size = "large",
	iconDirection = "left",
	isLoading,
}: ButtonSecondaryPropsType) {
	// TODO ADD POSSIBILITY TO SHOW THE ICON AT RIGHT AND CONTAINER QUERIES FOR SMALL SIZE
	const dimensions = size === "small" ? "h-4 w-4" : "h-6 w-6";

	return (
		<button
			data-id="button-secondary"
			type="button"
			data-toggle={dropdownButton && "dropdown"}
			aria-haspopup={dropdownButton && true}
			aria-expanded={dropdownButton && false}
			onClick={onClick}
			disabled={disabled}
			className={`button-secondary 
        relative flex items-center justify-center
      gap-2 overflow-hidden rounded-md border border-primary-500 bg-transparent
      ${size === "small" ? "p-xxsm gap-xxsm" : "p-xsm gap-xsm min-h-6"} ${isLoading ? "text-primary-700P" : "text-primary-500"} hover:border-primary-300 hover:text-primary-300 active:border-primary-150
      active:text-primary-150 disabled:cursor-not-allowed disabled:border-primary-200
      [&>*]:hover:text-primary-300 [&>*]:active:text-primary-150 [&>*]:disabled:text-primary-200 
      ${className}`}
		>
			{icon && iconDirection === "left" && (
				<Icon
					icon={icon}
					className={`bg-transparent ${dimensions}
					${isLoading ? "text-primary-700P" : "text-primary-500"}`}
				/>
			)}

			{img && iconDirection === "left" && (
				<img
					className={`${isLoading ? "text-primary-700P" : "text-primary-500"} ${
						dimensions
					}
          bg-transparent`}
					src={img}
					alt={img}
				/>
			)}
			<span
				className={`whitespace-nowrap ${
					size === "small"
						? "text-xxsm font-semibold"
						: "font-normal text-xsm  lg:text-base"
				}`}
			>
				{label}
			</span>
			{isLoading && (
				<Spinner
					className={`${
						size === "small"
							? "!h-4 !w-4 [&>*]:!h-4 [&>*]:!w-4"
							: "!h-5 !w-5 [&>*]:!h-5 [&>*]:!w-5"
					}
 text-primary-700P [&>*]:!m-0 [&>*]:!border-2`}
				/>
			)}
			{icon && iconDirection === "right" && (
				<Icon
					icon={icon}
					className={`${dimensions}
 bg-transparent ${isLoading ? "text-primary-700P" : "text-primary-500"}`}
				/>
			)}

			{img && iconDirection === "right" && (
				<img
					className={`${isLoading ? "text-primary-700P" : "text-primary-500"} ${
						dimensions
					}

          bg-transparent`}
					src={img}
					alt={img}
				/>
			)}
		</button>
	);
}
