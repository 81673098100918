import type { TagType } from "@/types/tagTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React from "react";

import {
	birthdayCake,
	diagonalArrowRightUpOutline,
	emailOutline,
	phoneOutline,
	pinOutline,
	searchFill,
} from "@assets/Icons";

import { SEARCH_DISTANCE_OPTIONS } from "@constants/Others";

import { formatDate, getAgeFromBirthdate } from "@tools/Dates";

import type { BackgroundColorType } from "@/types/colorsType";
import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { InlineButton } from "@components/atoms/InlineButton";
import TooltipContainer from "@components/atoms/TooltipContainer";

export function OfferStudentProfilCard() {
	const { applicationSelectedData } = useAppSelector((state) => state.offers);
	return (
		<article className="flex flex-col gap-sm">
			<div className="mb-md rounded-lg bg-primary-50 p-sm">
				<div className="mb-sm flex w-full justify-between">
					<div className="flex">
						<Avatar
							firstname={applicationSelectedData?.spsStudent.firstname}
							lastname={applicationSelectedData?.spsStudent.lastname}
							image={applicationSelectedData?.spsStudent?.avatar?.filePath}
							size="md"
						/>
						<div className="ml-sm flex flex-col">
							<p className="wg-no-translate truncate font-bold text-primary-700P">
								{`${applicationSelectedData?.spsStudent.firstname} ${applicationSelectedData?.spsStudent.lastname}`}
							</p>
							<p className="wg-no-translate font-semibold text-primary-700P">
								{applicationSelectedData?.spsStudent?.promotion?.name}
							</p>
						</div>
					</div>
					<div>
						<span id="application-tooltip">
							<InlineButton
								id="cv-modal-tooltip"
								label=""
								iconSize="h-6 w-6"
								iconPosition="right"
								icon={diagonalArrowRightUpOutline}
								onClick={() => {
									window.open(
										`/students/${
											applicationSelectedData?.spsStudent?.id?.split("/")[3]
										}`,
										"_blank",
									);
								}}
							/>
							<TooltipContainer
								children={"Voir la fiche de l’étudiant"}
								place="top-start"
								anchorId="#cv-modal-tooltip"
							/>
						</span>
					</div>
				</div>
				<div className="mb-xsm flex flex-wrap">
					{applicationSelectedData?.spsStudent?.birthdate && (
						<div className="mb-xsm mr-md flex items-center text-primary-500">
							<Icon className="mr-xxsm" icon={birthdayCake} />{" "}
							<p className="font-semibold">
								{getAgeFromBirthdate(
									applicationSelectedData?.spsStudent?.birthdate || "",
								)}{" "}
								ans -{" "}
								{formatDate(applicationSelectedData?.spsStudent?.birthdate)}
							</p>
						</div>
					)}
					{applicationSelectedData?.spsStudent?.email && (
						<div className="mb-xsm mr-md flex items-center text-primary-500">
							<Icon className="mr-xxsm" icon={emailOutline} />{" "}
							<p className="font-semibold">
								{applicationSelectedData?.spsStudent?.email}
							</p>
						</div>
					)}
					{applicationSelectedData?.spsStudent?.phone && (
						<div className="mb-xsm mr-md flex items-center text-primary-500">
							<Icon className="mr-xxsm" icon={phoneOutline} />{" "}
							<p className="font-semibold">
								{" "}
								{`+${applicationSelectedData?.spsStudent?.phone?.slice(
									0,
									applicationSelectedData?.spsStudent?.phone.length - 9,
								)} ${applicationSelectedData?.spsStudent?.phone?.slice(-9)}`}
							</p>
						</div>
					)}
					{(applicationSelectedData?.spsStudent?.city ||
						applicationSelectedData?.spsStudent?.postalCode) && (
						<div className="mb-xsm mr-md flex items-center text-primary-500">
							<Icon className="mr-xxsm" icon={pinOutline} />

							<p className="whitespace-pre-line font-semibold">
								{`${applicationSelectedData?.spsStudent?.city} ${applicationSelectedData?.spsStudent?.postalCode}`}
							</p>
						</div>
					)}

					{applicationSelectedData?.spsStudent?.geoAreaOfResearch && (
						<div className="mb-xsm mr-md flex items-center text-primary-500">
							<Icon className="mr-xxsm" icon={searchFill} />{" "}
							<p className="font-semibold">
								<p className="font-semibold">
									{applicationSelectedData.spsStudent.geoAreaOfResearch} -
									{
										SEARCH_DISTANCE_OPTIONS.find((option) => {
											return (
												option.value ===
												applicationSelectedData.spsStudent.mobilityRadius
											);
										})?.label
									}
								</p>
							</p>
						</div>
					)}
				</div>

				{
					<div className="flex flex-col">
						<div className="flex flex-wrap">
							{applicationSelectedData?.spsStudent?.tags?.collection
								?.filter((tag: TagType) => tag.type === "hardSkill")
								?.map((tag: TagType) => (
									<Badge
										label={tag.name}
										bgColor={tag.color as BackgroundColorType}
									/>
								))}
						</div>
						<div className="flex flex-wrap">
							{applicationSelectedData?.spsStudent?.tags?.collection
								?.filter((tag: TagType) => tag.type === "softSkill")
								?.map((tag: TagType) => (
									<Badge
										label={tag.name}
										bgColor={tag.color as BackgroundColorType}
									/>
								))}
						</div>
					</div>
				}
			</div>
		</article>
	);
}
