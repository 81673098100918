import { useGoals } from "@hooks/useGoals";
import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { DefaultGoals } from "./Goals/DefaultGoals";
import { SchoolGoals } from "./Goals/SchoolGoals";

export default function Goals() {
	const { user } = useContext(GlobalContext);
	const { goals } = useGoals(user?.id, null);

	return (
		<div className="rounded-lg bg-white px-sm py-md">
			{goals?.length > 0 ? <SchoolGoals goals={goals} /> : <DefaultGoals />}
		</div>
	);
}
