import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "./Tags.scss";

import { getTagsManagementData } from "@containers/school/TagsManagement/TagsAPI";

import type { ViewType } from "@/types/genericType";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { Head } from "./ui/Head";
import { TagsList } from "./ui/TagsList";

type FiltersType = {
	type: string;
	type_list?: string[];
};

export function TagsManagement() {
	const {
		structureView,
		setTitle,
	}: { structureView: ViewType; setTitle: Function } = useOutletContext();
	const { user } = useContext(GlobalContext);

	const [load, setLoad] = useState<boolean>(true);
	const [tags, setTags] = useState([]);
	const [tab, setTab] = useState<"generalTags" | "skillsTags">("generalTags");
	const [lastPage, setLastPage] = useState<number>(1);
	const [filters, setFilters] = useState<FiltersType>({ type: "school" });
	const [displayNewTag, setDisplayNewTag] = useState<boolean>(false);

	const triggerGetData = (type_list: string[]) => {
		setFilters({ ...filters, type_list });
	};
	const getData = async () => {
		setLoad(true);
		const getDataResponse = await getTagsManagementData(user?.school, filters);
		if (getDataResponse.responseLabel === "success") {
			setTags(getDataResponse.data?.tags?.collection);
			setLastPage(getDataResponse.data?.tags?.paginationInfo?.lastPage);
		} else if (getDataResponse.message !== "aborted") {
			ErrorToast(getDataResponse.message);
		}
		setLoad(false);
	};

	useEffect(() => {
		setTitle("Gestion des tags");
		getData();
	}, [filters, structureView]);

	return (
		<main className="">
			<Head
				filtersProps={{ filters, setFilters }}
				triggerGetData={(type_list: string[]) => triggerGetData(type_list)}
				tabProps={{ tab, setTab }}
				displayNewTagProps={{ displayNewTag, setDisplayNewTag }}
			/>
			<TagsList
				load={load}
				tags={tags}
				lastPage={lastPage}
				tabProps={{ tab, setTab }}
				filtersProps={{ filters, setFilters }}
				getData={getData}
			/>
		</main>
	);
}
