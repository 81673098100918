import React, { type MouseEvent } from "react";

import "@assets/sass/Tabs.scss";

type TabType = {
	label: string;
	className?: string;
	active?: boolean;
	onClick: (e?: MouseEvent<HTMLElement>) => void;
};
type ScrollableTabPrimaryType = TabType & {
	subLabel?: number | string;
	display?: boolean;
};

export function ScrollableTabPrimary({
	label,
	subLabel = undefined,
	className = "",
	active = false,
	onClick = () => {},
	display = true,
}: ScrollableTabPrimaryType) {
	if (!display) return null;

	return (
		<div
			data-id="scrollableTabPrimary"
			role="tab"
			tabIndex={0}
			onClick={onClick}
			className={`${className} font-semibold cursor-pointer rounded-t-sm  px-sm pb-[6px] pt-xxsm text-center text-sm transition-all hover:bg-primary-150 ${
				active
					? "border-b-2 border-primary-700P text-primary-700P "
					: "first-letter:border-primary-100 text-primary-300 mb-[1px]"
			}`}
		>
			<p className="truncate">
				{label}{" "}
				{subLabel !== undefined && (
					<span
						className={active ? "text-primary-700P" : "text-primary-200"}
					>{`(${subLabel})`}</span>
				)}
			</p>
		</div>
	);
}

export function ScrollableTabSecondary({
	label,
	className = "",
	active = false,
	onClick = () => {},
}: TabType) {
	return (
		<div
			data-id="scrollableTabSecondary"
			role="tab"
			tabIndex={0}
			onClick={onClick}
			className={`scrollable-tab-secondary ${className} ${active && "active"}`}
		>
			<p className="text-primary-300">{label}</p>
		</div>
	);
}

export function FixedTabPrimary({
	label,
	className = "",
	active = false,
	onClick = () => {},
}: TabType) {
	return (
		<div
			data-id="fixedTabPrimary"
			role="tab"
			tabIndex={0}
			onClick={onClick}
			className={`fixed-tab-primary ${className} ${active && "active"}`}
		>
			<p className="text-primary-300 ">{label}</p>
		</div>
	);
}
