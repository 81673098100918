export type SoftrConfig = {
	templateID: string;
	subdomain: string;
};

type CategoryName =
	| "relations"
	| "prospection"
	| "events"
	| "coaching"
	| "jobboard";

export const SOFTR_MAPPING = new Map<CategoryName, SoftrConfig>([
	[
		"relations",
		{
			templateID: "0eb21295-f2d7-4000-9f07-e1c2d33a0cc1",
			subdomain: "angela7670",
		},
	],
	[
		"prospection",
		{
			templateID: "8bbcb629-069e-468e-88c0-eb2ef7673018",
			subdomain: "desmond7164",
		},
	],
	[
		"events",
		{
			templateID: "5b0a835d-bf79-4991-b80a-0e9fdfafb656",
			subdomain: "lacresha357",
		},
	],
	[
		"coaching",
		{
			templateID: "9efcff13-f4c6-4f05-b86e-f6a4b2ad3e57",
			subdomain: "lacresha357",
		},
	],
	[
		"jobboard",
		{
			templateID: "f9e45ab6-ed1a-4e94-a1ce-a0750b1c496d",
			subdomain: "layla3882",
		},
	],
]);
