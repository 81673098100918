import { Icon } from "@iconify/react";
import React, { useContext } from "react";

import { setProspectArchived } from "../../AtsAPI";
import {
	CurrentProspectContext,
	OnSlidingPanelCloseContext,
	RemoveProspectContext,
} from "../../index";
import type { ProspectType } from "../Kanban";

import { HeaderButton } from "./HeaderButton";

export const SlidingPaneHeader = () => {
	const onSlidingPanelClose = useContext(OnSlidingPanelCloseContext);
	const currentProspect = useContext(CurrentProspectContext)
		.currentProspect as ProspectType;
	const removeProspect = useContext(RemoveProspectContext);

	const OnArchiveClick = async () => {
		removeProspect(currentProspect.id);
		onSlidingPanelClose();
		await setProspectArchived(currentProspect.id);
	};
	return (
		<div className="flex flex-row p-8 px-16 bg-gray-100 justify-between">
			<div
				className="flex flex-row gap-2 items-center hover:cursor-pointer hover:text-gray-500"
				onClick={onSlidingPanelClose}
			>
				<Icon icon="eva:close-fill" className="text-2xl" />
				<span className="text-sm">Fermer</span>
			</div>
			<div className="flex flex-row gap-2 items-center">
				<HeaderButton
					icon={"eva:people-outline"}
					label={"Assigner"}
					iconColor={"text-orange-500"}
					bgColor={"bg-orange-200"}
				/>
				<HeaderButton
					icon={"eva:share-outline"}
					label={"Partager"}
					iconColor={"text-green-500"}
					bgColor={"bg-green-200"}
				/>
				<HeaderButton
					icon={"eva:shuffle-2-fill"}
					label={"Transferer"}
					iconColor={"text-blue-500"}
					bgColor={"bg-blue-100"}
				/>
				<HeaderButton
					icon={"eva:archive-outline"}
					label={"Archiver"}
					iconColor={"text-red-500"}
					bgColor={"bg-red-100"}
					onClick={OnArchiveClick}
				/>
			</div>
		</div>
	);
};
