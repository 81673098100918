import React, { useEffect, useState } from "react";

import "@assets/sass/Pagination.scss";

type PaginationButtonPropsType = {
	className?: string | "";
	active?: boolean;
	disabled?: boolean;
	onChange?: Function;
	onClick?: Function;
	label?: string | number | null;
};
export function PaginationButton({
	label = "",
	className = "",
	active = false,
	onChange = () => {},
	onClick = () => {},
	disabled = false,
}: PaginationButtonPropsType) {
	const [isActive, setIsActive] = useState(active);

	const fakeEvent = {
		stopPropagation: () => false,
	};

	useEffect(() => {
		setIsActive(active);
	}, [active]);

	return (
		<button
			data-id="toggleButtonOctonary"
			tabIndex={0}
			disabled={disabled}
			className={`pagination-button-container ${
				isActive && "active"
			} ${className} `}
			onClick={(e = fakeEvent as any) => {
				e.stopPropagation();
				if (disabled) {
					return;
				}
				onClick();
				onChange(!isActive);
			}}
		>
			<p>{label}</p>
		</button>
	);
}
