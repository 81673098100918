import React from "react";
import { useLocation } from "react-router";

import TemplateLayout from "./TemplateLayout";

type SoftrViewerProps = {
	templateID: string;
	subdomain: string;
	className?: string;
};
/***
 * @description  toolkit to ship fast, cms build some pages, for mg academy.
 * @param {string} templateID - the template id
 * @param {string} subdomain - the subdomain
 * @param {string} className - the class name for override styles
 * @docs https://docs.softr.io
 */
export default function SoftrViewer({
	templateID = "softr-c39f8491-741c-498c-8754-4eee2e9cfab8",
	subdomain = "https://karyn9541.softr.app",
	className,
}: SoftrViewerProps) {
	const { pathname } = useLocation();
	return (
		<main className={`h-[100vh] pb-0 pl-xxlg pr-0 ${className}`}>
			{pathname === "/test-softr-preview" ? (
				<>
					<TemplateLayout />
					<iframe
						title="test"
						id={`softr-${templateID}`}
						src={`https://${subdomain}.softr.app/?user=1`}
						width="100%"
						height="100%"
						className={className}
					/>
				</>
			) : (
				<iframe
					title="test"
					className="h-full w-full"
					id={`softr-${templateID}`}
					src={`https://${subdomain}.softr.app/?user=1`}
				/>
			)}
		</main>
	);
}
