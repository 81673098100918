import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { alertTriangleOutline, editOutline, link2Outline } from "@assets/Icons";

import { OFFER_TYPE_LABEL_FOR_DETAILS } from "@constants/Offers";

import { convertDateFromNow } from "@tools/Dates";
import {
	getContractOption,
	getContractType,
	getQualificationOption,
	getTeleworkOption,
} from "@tools/Offers";
import { extractIdNumberFromId } from "@tools/Users";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ButtonTertiary } from "@components/atoms/ButtonTertiary";

import { setOfferToCreate } from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import TagsModifier from "../../../actions/TagsModifier";

export function OfferDetailsInformations() {
	const dispatchEvent = useDispatch();
	const navigation = useNavigate();
	const { offerToEdit } = useAppSelector((state) => state.offers);
	const handleOpenEditionPage = () => {
		dispatchEvent(setOfferToCreate(offerToEdit));
		navigation(`/offers/edition/${extractIdNumberFromId(offerToEdit?.id)}`);
	};
	const handleOpenExternalLink = () => {
		window.open(offerToEdit?.externalLink, "_blank");
	};
	const getOrganizationLogo = () => {
		if (!offerToEdit?.crmCompany?.logo || offerToEdit?.anonymous) {
			return undefined;
		}
		return offerToEdit?.crmCompany?.logo;
	};

	return (
		<div className="flex flex-col gap-8">
			<article className="flex w-full items-center justify-between">
				<div className="flex w-full items-center justify-start gap-2">
					<Avatar
						lastname=""
						size="sm"
						image={getOrganizationLogo()}
						firstname={offerToEdit?.crmCompany?.name}
					/>
					<div className="flex flex-col gap-sm">
						<div className="flex w-full flex-col flex-wrap gap-2 sm:flex-row">
							<p className="text-sm font-bold text-primary-700P">
								Offre {offerToEdit?.job} -
							</p>
							<p className="text-xsm font-semibold text-primary-300">
								{offerToEdit?.crmCompany?.name}
							</p>
						</div>
						{offerToEdit?.anonymous && (
							<div className="flex items-center gap-sm text-base font-semibold text-accent-3-dark">
								<Icon icon={alertTriangleOutline} /> Entreprise anonyme pour les
								étudiants
							</div>
						)}
					</div>
				</div>
				<div className="flex gap-sm">
					{offerToEdit?.externalLink && (
						<ButtonTertiary
							icon={link2Outline}
							onClick={handleOpenExternalLink}
						/>
					)}
					<ButtonTertiary icon={editOutline} onClick={handleOpenEditionPage} />
				</div>
			</article>
			<article className="flex w-full gap-sm border-b-2 border-b-primary-100 pb-2">
				<div className="flex flex-1 flex-col gap-4 rounded-md p-xsm">
					{renderInfoWithTag("Contrat", getContractOption(offerToEdit).label)}
					{renderInfoWithText("Lieu du poste", offerToEdit?.location)}
					{renderInfoWithText(
						"Niveau requis",
						getQualificationOption(offerToEdit).label,
					)}
					{renderInfoWithText(
						"Télétravail",
						getTeleworkOption(offerToEdit).label,
					)}
					{renderInfoWithText(
						"Nombres de postes à pourvoir",
						String(offerToEdit?.openPositionsCount),
					)}
				</div>
				<div className="flex flex-1 border-spacing-4 flex-col gap-4  border-l-2 border-l-primary-100 p-xsm pl-4">
					{offerToEdit &&
						renderInfoWithText(
							"Type d'offre",
							OFFER_TYPE_LABEL_FOR_DETAILS.get(offerToEdit?.type)?.label,
						)}
					{renderInfoWithText(
						"Création",
						formatCreationDate(offerToEdit?.date),
					)}
					<div className="flex w-full gap-4">
						{renderInfoWithText(
							"Actualisation",
							convertDateFromNow(offerToEdit?.updateAt || ""),
						)}
						{renderInfoWithText(
							"Expiration",
							convertDateFromNow(offerToEdit?.expirationDate || ""),
						)}
					</div>
					{offerToEdit?.externalId &&
						renderInfoWithText(
							"Numéro de référence interne",
							offerToEdit.externalId,
						)}
				</div>
			</article>
			<article className="w-full">
				<TagsModifier useAutosave={true} />
			</article>
		</div>
	);
}

const formatCreationDate = (date: string | undefined) => {
	if (!date) return "Date inconnue";
	const dateToFormat = new Date(date);
	return `${convertDateFromNow(
		dateToFormat,
	)} - Le ${dateToFormat.toLocaleDateString()}`;
};

const renderInfoWithTag = (label: string, tagValue: string) => {
	return (
		<div className="flex flex-col gap-1">
			<p className="text-xxxsm font-normal text-primary-300">{label}</p>

			<Badge label={tagValue} bgColor={getContractType(tagValue)?.color} />
		</div>
	);
};

const renderInfoWithText = (label: string, textValue = "") => {
	return (
		<div className="flex flex-col gap-1">
			<p className="text-xxxsm font-normal text-primary-300">{label}</p>
			<p className="text-xsm font-normal text-primary-700P">{textValue}</p>
		</div>
	);
};
