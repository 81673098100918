import React from "react";
import Skeleton from "react-loading-skeleton";

export function OffersSkeletons() {
	return (
		<section className="flex flex-col justify-start items-start gap-sm p-sm">
			<div className="flex gap-sm">
				<Skeleton width={400} height={48} />
				<Skeleton width={48} height={48} className="rounded-full" />
			</div>
			<article className="flex flex-col gap-sm w-full">
				<Skeleton width={700} height={72} />
				<Skeleton width={700} height={72} />
				<Skeleton width={700} height={72} />
				<Skeleton width={700} height={72} />
				<Skeleton width={700} height={72} />
				<Skeleton width={700} height={72} />
			</article>
		</section>
	);
}
