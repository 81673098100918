import { InlineIcon } from "@iconify/react";
import React from "react";
import { type ReactElement, useEffect, useRef, useState } from "react";

import "@assets/sass/Dropdown.scss";

import { arrowBackFill } from "@assets/Icons";

import { DropdownItem } from "./DropdownItem";

let uniqueId = 0;

type DropdownPropsType = {
	button: ReactElement;
	items: { [key: string]: any }[];
	title?: string;
	subtitle?: string;
	className?: string;
	direction?: string;
	triggerOpening?: Function;
};

export function Dropdown({
	className = "",
	button,
	items,
	title = "",
	subtitle = "",
	direction = "",
	triggerOpening,
}: DropdownPropsType) {
	const [componentId] = useState(() => uniqueId++);
	const [menuItems, setMenuItems] = useState([...items]);
	const [isSubMenu, setIsSubMenu] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef<HTMLHeadingElement | null>(null);

	const goSubMenu = (e: React.MouseEvent<HTMLElement>, subItems: any[]) => {
		e.stopPropagation();
		setMenuItems([...subItems]);
		setIsSubMenu(true);
	};

	useEffect(() => {
		setMenuItems([...items]);
	}, [items]);

	const goMenu = (
		itemsToShow: any[],
		e: React.MouseEvent<HTMLElement> | null = null,
	) => {
		if (e) e.stopPropagation();
		setMenuItems([...itemsToShow]);
		setIsSubMenu(false);
	};

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (event instanceof MouseEvent) {
				if (ref.current && !ref.current.contains(event.target as Node)) {
					setIsOpen(false);
					if (triggerOpening) {
						triggerOpening(undefined);
					}
				}
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div
			data-id="dropdown"
			data-toggle="dropdown"
			data-bs-toggle="dropdown"
			className={`dropdown w-fit ${className}`}
			id={`dropdown-${componentId}`}
			ref={ref}
		>
			<button
				className="relative"
				onClick={(e: any) => {
					e.stopPropagation();
					setIsOpen(!isOpen);
					if (triggerOpening) {
						triggerOpening(e);
					}
				}}
			>
				{button}
			</button>
			<div className="dropdown-container relative ">
				<div
					className={`dropdown-menu absolute ${
						direction === "left" ? "right" : "left"
					}-0  bg-white ${isOpen ? "show" : "hidden"} ${
						items.length > 4 ? "overflow-y-scroll" : "!overflow-hidden"
					} flex flex-col`}
				>
					<p className="dropdown-title mb-md text-center text-xsm">
						{isSubMenu ? subtitle : title}
					</p>
					{isSubMenu && (
						<div
							data-id="menu"
							role="menu"
							onClick={(e: any) => {
								goMenu(items, e);
							}}
						>
							<p className="dropdown-item flex">
								<InlineIcon className="mr-xsm h-4" icon={arrowBackFill} />{" "}
								Retour
							</p>
						</div>
					)}
					{menuItems?.map((item, i) => {
						return (
							<DropdownItem
								color={item?.color || "primary-500"}
								breakSpaces={false}
								icon={item?.icon}
								key={i}
								label={item?.label}
								onClick={(e: React.MouseEvent<HTMLElement>) => {
									if (item.subMenu) goSubMenu(e, item.subMenu);
									else {
										item.action();
										setIsOpen(false);
									}
								}}
								truncate
								subMenu={item?.subMenu}
								nodeElement={item?.nodeElement}
								isSelect={item?.isSelect}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
