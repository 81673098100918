import React, { useState } from "react";

import { ScrollableTabGroup } from "../../../../../components/molecules/TabGroup";

import { Activity } from "./Activity";
import { EmailSms } from "./EmailSms";
import { Evaluation } from "./Evaluation";
import { Notes } from "./Notes";
import { Profile } from "./Profile";

const TABS = [
	{
		label: "Profil",
		state: "profile",
		active: true,
	},
	{
		label: "Email/SMS",
		state: "email-sms",
		active: false,
	},
	{
		label: "Note",
		state: "note",
		active: false,
	},
	{
		label: "Evaluation",
		state: "evaluation",
		active: false,
	},
	{
		label: "Activité",
		state: "activity",
		active: false,
	},
];

export const Informations = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const getTabs = () => {
		return TABS.map((tab, index) => {
			return {
				...tab,
				active: index === selectedTab,
			};
		});
	};
	return (
		<div
			className={`flex flex-1 flex-col gap-10 ${selectedTab !== 0 && "pr-10"}`}
		>
			<ScrollableTabGroup
				tabs={getTabs()}
				onTabChange={(index) => {
					setSelectedTab(index);
				}}
			/>
			<div className="flex flex-auto h-0 flex-col overflow-y-auto pb-8">
				{selectedTab === 0 && <Profile />}
				{selectedTab === 1 && <EmailSms />}
				{selectedTab === 2 && <Notes />}
				{selectedTab === 3 && <Evaluation />}
				{selectedTab === 4 && <Activity />}
			</div>
		</div>
	);
};
