import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { pricetagsOutline } from "@assets/Icons";

import { InputLargePrimary } from "@components/molecules/InputLargePrimary";

import { modifyOfferToCreate } from "../../core/store/offersManagement.slice";

function JobTitleModifier() {
	const dispatchEvent = useDispatch();
	const { offerToCreate } = useAppSelector((state) => state.offers);

	const handleJobTitleChange = (value: string) => {
		dispatchEvent(modifyOfferToCreate({ job: value }));
	};
	return (
		<div data-testid="jobTitleSelect">
			<InputLargePrimary
				name="company"
				className="w-full"
				placeholder="Nom de l'offre"
				label="Nom de l'offre*"
				value={offerToCreate?.job || ""}
				onChange={(e) => handleJobTitleChange(e.target.value)}
				errorMessage={""}
				icon={pricetagsOutline}
			/>
		</div>
	);
}

export default JobTitleModifier;
