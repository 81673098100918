import React, { useEffect, useState } from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { TextArea } from "@components/atoms/TextArea";
import { Modal } from "@components/organisms/modal/Modal";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

type OnHoldApplicationModalProps = {
	show: boolean;
	onClose: () => void;
	edit: () => void;
	state: string;
	treatmentProps: {
		treatmentReason: string;
		setTreatmentReason: (treatmentReason: string) => void;
	};
	messagesProps: {
		messages: string[];
		setMessages: (messages: string[]) => void;
	};
};

export function OnHoldApplicationModal({
	show,
	onClose,
	edit,
	treatmentProps,
	state,
	messagesProps,
}: OnHoldApplicationModalProps) {
	const { setTreatmentReason } = treatmentProps;
	const { messages, setMessages } = messagesProps;
	const [choice, setChoice] = useState("requestInformation");
	const motifText = [
		{ label: "Demande d'information", value: "requestInformation" },
		{ label: "Lettre de motivation", value: "motivationLetter" },
		{ label: "Mettre à jour le CV", value: "updateCV" },
		{ label: "Zone Géographique", value: "geographicalArea" },
	];

	const textValue = (motif: string) => {
		switch (motif) {
			case "motivationLetter":
				return "Merci d'ajouter une lettre de motivation.";
			case "updateCV":
				return "Merci de mettre à jour votre CV.";
			case "geographicalArea":
				return "Merci de préciser votre zone géographique de recherche.";
			default:
				return "Pouvez-vous préciser les informations suivantes :\n-\n-";
		}
	};

	useEffect(() => {
		if (show) {
			setTreatmentReason("requestInformation");
			setMessages(state !== "onHold" ? [textValue("")] : messages);
		}
	}, [show]);

	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Message d'information"
			body={
				<div>
					<p className="text-xsm text-primary-700P">
						Vous avez indiqué que{" "}
						<span className="font-semifont-bold">
							le profil de cet étudiant est susceptible d&apos;être intéressant
						</span>{" "}
						pour l&apos;offre concernée.
						<br /> <br /> Cette candidature sera transférée dans l&apos;onglet
						&quot;Candidatures traitées&quot;.{" "}
						<span className="font-semifont-bold">
							L&apos;étudiant sera notifié.
						</span>
					</p>

					<SelectTertiary
						defaultValue={motifText.find((option) => option.value === choice)}
						className="mt-md w-6/12 p-0"
						label="Motif*"
						onChange={(e) => {
							setChoice(e.value);
							setTreatmentReason(e.value);
							setMessages([textValue(e.value)]);
						}}
						options={motifText}
						position="right"
					/>

					<TextArea
						height="208px"
						className="mt-sm"
						value={messages[0]}
						onBlur={(e) => {
							setMessages([e.target.value]);
						}}
						label="Message à envoyer à l'étudiant"
					/>
				</div>
			}
			buttonsRight={[
				<ButtonSecondary
					key={0}
					onClick={(e: any) => {
						e.stopPropagation();
						onClose();
					}}
					label="Annuler"
				/>,
				<ButtonPrimary
					key={1}
					onClick={(e: any) => {
						e.stopPropagation();
						onClose();
						edit();
					}}
					label="Envoyer"
				/>,
			]}
		/>
	);
}
