import React from "react";

import { pinOutline } from "@assets/Icons";

import { InlineButton } from "@components/atoms/InlineButton";

type ButtonOpenAddressProps = {
	address: string;
	onClick?: Function;
};
function ButtonOpenAddress({ address, onClick }: ButtonOpenAddressProps) {
	const openAddressInGoogleMaps = () => {
		window.open(
			`https://www.google.com/maps/search/?api=1&query=${address}`,
			"_blank",
		);
	};
	return (
		<InlineButton
			id="open-address-button"
			label={address}
			icon={pinOutline}
			iconPosition="left"
			onClick={() => {
				openAddressInGoogleMaps();
				if (onClick) onClick();
			}}
		/>
	);
}

export default ButtonOpenAddress;
