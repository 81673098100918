import type { AnswerType, InterviewDataType } from "@/types/interviewTypes";
import { InlineIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";

import { infoOutline } from "@assets/Icons";

import { INTERVIEW_STRINGS } from "@constants/Others";

import { TextArea } from "@components/atoms/TextArea";

export function Questions({
	interviewInfo,
	interviewLoaded,
}: InterviewDataType) {
	const [currentAnswers, setValues] = useState<AnswerType[]>([]);

	const questions = new Array(10).fill(0, 0).map((_e, i) => {
		return {
			id: i,
			question:
				INTERVIEW_STRINGS.questions[
					i.toString() as keyof typeof INTERVIEW_STRINGS.questions
				],
			tooltip:
				INTERVIEW_STRINGS.tooltips[
					i.toString() as keyof typeof INTERVIEW_STRINGS.tooltips
				],
			placeholder:
				INTERVIEW_STRINGS.placeholder[
					i.toString() as keyof typeof INTERVIEW_STRINGS.placeholder
				],
		};
	});

	useEffect(() => {
		if (
			!interviewLoaded ||
			!interviewInfo ||
			!interviewInfo.answerPreparationQuestions
		) {
			return;
		}
		setValues(interviewInfo.answerPreparationQuestions);
	}, [interviewInfo, interviewLoaded]);

	const getAnswer = (id: number) => {
		if (!currentAnswers) return "Erreur de chargement";
		if (currentAnswers.length === 0) return "Aucune réponse";
		if (!currentAnswers[id]) return "";

		const answer = currentAnswers[id];
		if (!answer) return "";
		return answer.value;
	};

	const handleOnChange = (answer: AnswerType) => {
		const currentAnswersCopy = [...currentAnswers];
		currentAnswersCopy[answer.id] = answer;

		setValues(currentAnswersCopy);
	};

	const handleOnBlur = (answer: AnswerType) => {
		return answer.value;
	};

	useEffect(() => {}, [interviewInfo]);
	return (
		<div className="w-full pl-sm pr-sm">
			{questions.map((value, index) => {
				return (
					<div key={index}>
						<div className="mb-xxsm flex">
							<p className="text-primary-500">{value.question}</p>
							<Tippy
								content={value.tooltip}
								theme="primary"
								animation="fade"
								placement="top"
								zIndex={5}
							>
								<span className="ml-xxsm cursor-pointer">
									<InlineIcon
										className=" h-4 text-primary-500"
										icon={infoOutline}
									/>
								</span>
							</Tippy>
						</div>
						<TextArea
							value={getAnswer(value.id)}
							disabled
							placeholder={value.placeholder}
							className="mb-sm"
							onChange={(e) => {
								const answer = {
									id: value.id,
									value: e,
								};
								handleOnChange(answer as AnswerType);
							}}
							onBlur={(e) => {
								const answer = {
									id: value.id,
									value: e.value || "",
								};
								handleOnBlur(answer as AnswerType);
							}}
							height={150}
						/>
					</div>
				);
			})}
		</div>
	);
}
