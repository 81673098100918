import * as Sentry from "@sentry/browser";
import { deleteAllCookies } from "./Cookies";

export const logout = async (isAce = false, redirectTo?: string) => {
	Sentry.setUser(null);
	deleteAllCookies();
	if ("caches" in window) {
		caches.keys().then((names) => {
			names.forEach((name) => {
				caches.delete(name);
			});
		});
	}
	localStorage.clear();
	if (isAce) {
		location.replace(`https://ace.${import.meta.env.VITE_DOMAIN}`);
	} else if (redirectTo) {
		location.replace(import.meta.env.VITE_URL + redirectTo);
	} else {
		location.replace(import.meta.env.VITE_URL ?? "/");
	}
};

export const getSubdomain = () => {
	const url = window.location.href;
	if (url.includes("https://")) {
		if (url.includes("?")) {
			const splitUrl = url.split("?")[1];
			if (splitUrl.includes("&")) {
				return splitUrl.split("&")[0];
			}
			return splitUrl;
		}

		return url.split("https://")[1].split(".")[0];
	}
	if (url.includes("http://") && url.includes("?")) {
		const splitUrl = url.split("?")[1];
		if (splitUrl.includes("&")) {
			return splitUrl.split("&")[0];
		}
		return splitUrl;
	}

	return null;
};

export const getCompanySubdomain = () => {
	const url = window.location.href;
	let splitToReturn: string | null = null;
	if (url.includes("https://") || url.includes("http://")) {
		if (url.includes("?")) {
			const splitUrl = url.split("?")[1];
			if (splitUrl.includes("&")) {
				splitUrl.split("&").forEach((e) => {
					if (!e.includes("=") && !splitToReturn?.length) splitToReturn = e;
				});
			} else {
				if (!splitUrl.includes("=")) {
					splitToReturn = splitUrl;
				}
			}
		}
	}

	return splitToReturn;
};
