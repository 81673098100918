import React, { type ReactElement } from "react";
import { useContext, useEffect, useState } from "react";

import { CurrentProspectContext } from "../../index";
import type { ProspectType } from "../Kanban";

import { CurrentStep } from "./CurrentStep";
import { ProfileCard } from "./ProfileCard";
import { ProfileInformationRow } from "./ProfileInformationRow";
export const Profile = () => {
	const currentProspect = useContext(CurrentProspectContext)
		.currentProspect as ProspectType;

	const [generatedInformations, setGeneratedInformations] = useState<
		ReactElement[]
	>([]);
	useEffect(() => {
		const generateInformations: ReactElement[] = [];
		Object.entries(currentProspect.data).map(([key, value]) => {
			if (
				key === "firstName" ||
				key === "lastName" ||
				value.type === "file" ||
				key === "cgu"
			) {
				return null;
			}
			generateInformations.push(
				<ProfileInformationRow
					key={key}
					pid={currentProspect.id}
					value={value}
				/>,
			);
			return null;
		});
		setGeneratedInformations(generateInformations);
	}, [currentProspect.data, currentProspect.id]);

	return (
		<div className="flex flex-col gap-8 overflow-y-auto">
			<ProfileCard prospect={currentProspect} />
			<CurrentStep prospect={currentProspect} />
			{generatedInformations.length > 0 && (
				<div className="flex flex-col gap-4 border rounded-md p-4">
					<span className="text-sm font-bold">Informations</span>
					<div className="flex flex-col gap-2">
						{generatedInformations.map(
							(generatedInformation) => generatedInformation,
						)}
					</div>
				</div>
			)}
		</div>
	);
};
