import { InlineIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "./Tabs.scss";

import {
	checkmarkCircle2Outline,
	checkmarkFill,
	closeFill,
	fileTextOutline,
	infoOutline,
	lockOutline,
	pinOutline,
} from "@assets/Icons";

import {
	APPLICATION_STEP_OPTIONS,
	APPLICATION_TYPE_OPTIONS,
} from "@constants/Applications";
import { CONTRACT_TYPE_OPTIONS } from "@constants/Offers";

import { getColumnIndex } from "@tools/Applications";
import { bigConfetti } from "@tools/Confetti";

import { Badge } from "@components/atoms/Badge";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { FileInput } from "@components/molecules/FileInput";
import { FilesPrimary } from "@components/molecules/Files";
import { Input } from "@components/molecules/Input";
import { InputSecondary } from "@components/molecules/InputSecondary";
import { SelectSecondaryStepper } from "@components/organisms/select/SelectSecondaryStepper";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";
import { Success } from "@components/organisms/toast/ToastNotification";

import {
	deleteFile,
	editApplication,
	editDocuments,
	getDocumentsData,
	upload,
} from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";

import { OnHoldApplicationModal } from "../Modals/OnHoldApplicationModal";
import { RefusedApplicationModal } from "../Modals/RefusedApplicationModal";
import { UpdateModal } from "../Modals/UpdateModal";

import { TagState } from "./Application/TagState";
import DocumentManager from "./DocumentManager";

export function Application({ applicationProps }) {
	const [modificationLoading, setModificationLoading] = useState(false);
	const [transitionStep, setTransitionStep] = useState({});
	const [showSeeMotif, setShowSeeMotif] = useState(false);
	const [showOnHoldModal, setShowOnHoldModal] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [redirect, setRedirect] = useState(false);

	const { application, setApplication } = { ...applicationProps };
	const [userAction, setUserAction] = useState(null); // accept / decline / null

	const [currentStep, setCurrentStep] = useState(application.step);

	const [otherDocuments, setOtherDocuments] = useState([]);
	const [_allDocuments, setAllDocuments] = useState([]);

	const [failedDocuments, setFailedDocuments] = useState([]);
	const [otherDocumentsLoadingState, setOtherDocumentsLoadingState] =
		useState(false);

	useEffect(() => {
		if (application) {
			setAllDocuments([
				...(application?.documents?.collection ?? []),
				...(application?.cv?.collection ?? []),
				...(application?.motivation?.collection ?? []),
			]);
			const array = application?.documents?.collection ?? [];
			setOtherDocuments(array);
		}
	}, [application]);

	const [values, setValues] = useState({
		job: "",
		location: "",
		offerLink: "",
	});
	const [error] = useState({});

	useEffect(() => {
		setValues({
			job: application?.job ?? "",
			location: application?.location ?? "",
			offerLink: application?.offerLink ?? "",
		});

		if (application?.accepted) {
			setUserAction("accept");
		} else if (application?.accepted === false) {
			setUserAction("decline");
		}

		setCurrentStep(getColumnIndex(application?.step));
	}, [application]);

	const handleOnChange = (name, value) => {
		const ValuesCopy = { ...values };
		ValuesCopy[name] = value;
		setValues(ValuesCopy);
	};

	const handleOnBlur = async (name, value) => {
		if (application[name] !== value) {
			await editApplication({
				id: application.id,
				[name]: value,
			});
			Success("Modification enregistrée !");
			setApplication({
				...application,
				[name]: value,
			});
		}
	};

	const resetLoadings = () => {
		setOtherDocumentsLoadingState(false);
	};

	async function progressPromise(promises) {
		const len = promises.length;
		let progress = 0;

		// to avoid overwriting other documents and resync with the backend
		const retrievesUpdatedDocuments = await getDocumentsData(application.id);
		if (
			!retrievesUpdatedDocuments ||
			!retrievesUpdatedDocuments.cv ||
			!retrievesUpdatedDocuments.motivation ||
			!retrievesUpdatedDocuments.documents
		) {
			setError("Impossible de récupérer les documents");
			return;
		}
		function tick(promise) {
			promise.then((data) => {
				progress++;
				const filesCopy = [
					...otherDocuments,
					{
						id: data["@id"],
						...data,
						progress: Math.round((progress / len) * 100),
					},
				];
				editDocuments({
					id: application.id,
					documents: [
						...retrievesUpdatedDocuments.cv.collection.map((item) => item.id),
						...retrievesUpdatedDocuments.motivation.collection.map(
							(item) => item.id,
						),
						...application.documents.collection.map((item) => item.id),
						data["@id"],
					],
				}).then(() => {
					setAllDocuments([
						...retrievesUpdatedDocuments.cv.collection,
						...retrievesUpdatedDocuments.motivation.collection,
						...filesCopy,
					]);
					resetLoadings();
				});
				setOtherDocuments(filesCopy);
			});
			return promise;
		}

		return Promise.all(promises.map(tick));
	}

	const toggleLoading = (type) => {
		if (type === "other") {
			setOtherDocumentsLoadingState(!otherDocumentsLoadingState);
		}
	};

	const uploadFile = async (files, type) => {
		toggleLoading(type);
		const promises = [];

		await files.map(async (file) => {
			const data = new FormData();

			data.append("file", file);
			data.append("type", type);

			const promise = upload(data);
			promises.push(promise);
		});
		try {
			await progressPromise(promises);
		} catch (error) {
			throw new Error(error.message);
		}
	};

	const deleteDocument = async (file, type) => {
		toggleLoading(type);

		// to avoid overwriting other documents and resync with the backend
		const retrievesUpdatedDocuments = await getDocumentsData(application.id);
		if (
			!retrievesUpdatedDocuments ||
			!retrievesUpdatedDocuments.cv ||
			!retrievesUpdatedDocuments.motivation ||
			!retrievesUpdatedDocuments.documents
		) {
			setError("Impossible de récupérer les documents");
			return;
		}
		const response = await deleteFile({ id: file.id });
		if (!response) {
			setError("Impossible de supprimer le document");
			return;
		}
		if (otherDocuments.length === 1) {
			setOtherDocuments([]);
			setAllDocuments([
				...retrievesUpdatedDocuments.cv.collection,
				...retrievesUpdatedDocuments.motivation.collection,
			]);
			resetLoadings();
			return;
		}
		const updatedDocuments = otherDocuments.filter((doc) => doc.id !== file.id);
		setOtherDocuments(updatedDocuments);
		setAllDocuments([
			...retrievesUpdatedDocuments.cv.collection,
			...retrievesUpdatedDocuments.motivation.collection,
			...updatedDocuments,
		]);
		resetLoadings();
	};

	const findStepFromIndex = (indexToFind) => {
		const step = APPLICATION_STEP_OPTIONS.find(
			(_, index) => index === indexToFind,
		);
		if (!step) return;
		return step;
	};

	const sendNewStep = async (step) => {
		setModificationLoading(true);
		setTransitionStep(findStepFromIndex(step));
		setCurrentStep(getColumnIndex(step));
		try {
			const response = await editApplication({
				id: application.id,
				step,
			});
			setApplication(response);
		} catch (_error) {
		} finally {
			setModificationLoading(false);
		}
	};

	return (
		<div className="w-full">
			<div className="stepper-small">
				<SelectSecondaryStepper
					defaultValue={
						modificationLoading
							? transitionStep
							: findStepFromIndex(currentStep)
					}
					modalTitle="État de la candidature"
					placeholder={
						modificationLoading
							? "Chargement..."
							: findStepFromIndex(currentStep)
					}
					options={APPLICATION_STEP_OPTIONS}
					indexActive={findStepFromIndex(currentStep)?.indexColor}
					accentColor={`${currentStep + 1}`}
					onChange={(value) => {
						setTransitionStep(value);
						sendNewStep(value);
					}}
					value={
						modificationLoading
							? transitionStep
							: findStepFromIndex(currentStep)
					}
					className="mb-xsm w-full relative"
				/>
				<div className="hidden md:flex">
					{userAction && (
						<ButtonQuaternary
							onClick={() => {
								setUserAction(null);
								editApplication({ id: application.id, accepted: null });
							}}
							icon={lockOutline}
							className="small mr-xsm"
						/>
					)}
					{userAction === "decline" ? (
						<Badge
							label="Candidature refusée"
							className="no-pointer mb-sm mr-xsm"
							leftIcon={closeFill}
							bgColor="bg-error-light"
							hasBorder
						/>
					) : (
						userAction === "accept" && (
							<Badge
								label="Candidature acceptée"
								className="no-pointer mb-sm mr-xsm"
								leftIcon={checkmarkFill}
								bgColor="bg-success-light"
								hasBorder
							/>
						)
					)}
				</div>
				{userAction === "accept" && (
					<ButtonPrimary
						className={"w-full"}
						icon={checkmarkCircle2Outline}
						label="Candidature acceptée"
						onClick={() => {
							setUserAction(null);
							editApplication({ id: application.id, accepted: null }).then(
								() => {
									Success("Modification enregistrée !");
								},
							);
						}}
					/>
				)}
				{userAction === "decline" && (
					<ButtonPrimary
						className={"w-full"}
						icon={closeFill}
						label="Candidature refusée"
						onClick={() => {
							setUserAction(null);
							editApplication({ id: application.id, accepted: null }).then(
								() => {
									Success("Modification enregistrée !");
								},
							);
						}}
					/>
				)}

				{userAction === null && (
					<div className={"flex w-full justify-between gap-sm"}>
						<ButtonSecondary
							onClick={() => {
								setUserAction("accept");
								bigConfetti();
								editApplication({
									id: application.id,
									accepted: true,
								}).then(() => {
									Success("Modification enregistrée !");
								});
							}}
							label="Acceptée"
							className={" w-6/12"}
						/>
						<ButtonSecondary
							onClick={() => {
								setUserAction("decline");
								editApplication({ id: application.id, accepted: false }).then(
									() => {
										Success("Modification enregistrée !");
									},
								);
							}}
							label="Refusée"
							className={`${userAction !== null && "hidden"} w-6/12`}
						/>
					</div>
				)}
			</div>

			<TagState
				application={application}
				setShowOnHoldModal={setShowOnHoldModal}
				setShowSeeMotif={setShowSeeMotif}
				setShowUpdateModal={setShowUpdateModal}
			/>

			<Input
				value={values.job}
				onChange={(e) => handleOnChange("job", e.target.value)}
				onBlur={(e) => handleOnBlur("job", e.target.value)}
				label="Poste"
				type="text"
				name="job"
				placeholder="Poste"
				className="poste"
			/>
			<Input
				value={values.location}
				onChange={(e) => handleOnChange("location", e.target.value)}
				onBlur={(e) => handleOnBlur("location", e.target.value)}
				label="Localisation"
				placeholder="Lieu"
				icon={pinOutline}
				name="location"
				type="text"
				className="localisation"
			/>

			<InputSecondary
				value={decodeURIComponent(values.offerLink)}
				onChange={(e) => {
					handleOnChange("offerLink", e.target.value);
				}}
				onBlur={(e) => {
					handleOnBlur("offerLink", e.target.value);
				}}
				onClick={() => {
					window.open(decodeURIComponent(values?.offerLink), "_blank").focus();
					setRedirect(!redirect);
				}}
				redirect={redirect}
				label={"URL de l'offre" || ""}
				placeholder="http://"
				name="url"
				type="text"
				errorMessage={error?.offerLink}
			/>
			<div className="mt-sm flex flex-wrap gap-sm md:flex-nowrap">
				<SelectTertiary
					className=" w-full md:w-6/12"
					position="right"
					onChange={(e) => handleOnBlur("contract", e.value)}
					label="Type de contrat"
					defaultValue={
						CONTRACT_TYPE_OPTIONS.find(
							(contract) => contract.value === application.contract,
						) || CONTRACT_TYPE_OPTIONS[0]
					}
					options={CONTRACT_TYPE_OPTIONS}
				/>

				<SelectTertiary
					position="right"
					onChange={(e) => handleOnBlur("type", e.value)}
					label="Type de candidature"
					defaultValue={APPLICATION_TYPE_OPTIONS.find(
						(type) => type.value === application.type,
					)}
					className="w-full md:w-6/12"
					options={APPLICATION_TYPE_OPTIONS}
				/>
			</div>
			<div className="mt-lg flex items-start">
				<p className="text-sm font-bold text-primary-700P">Mes documents</p>

				<Tippy
					content="Avoir un CV et une lettre de motivation personnalisées augmentent tes chances de décrocher un entretien de 50%."
					theme="primary"
					animation="fade"
					placement="top"
					zIndex={5}
				>
					<span className="ml-xxsm cursor-pointer">
						<InlineIcon className=" h-4 text-primary-300" icon={infoOutline} />
					</span>
				</Tippy>
			</div>

			<div className="files mb-lg mt-4 flex flex-wrap items-center justify-between gap-sm md:flex-nowrap">
				<div className="w-full self-baseline md:w-6/12">
					<p className="mb-xsm text-xsm font-bold text-primary-700P">CV</p>
					<DocumentManager documentType="cv" application={application} />
				</div>

				<div className="w-full self-baseline md:w-6/12">
					<p className="mb-xsm text-xsm font-bold text-primary-700P">
						Lettre de motivation
					</p>
					<DocumentManager
						documentType="motivation"
						application={application}
					/>
				</div>
			</div>
			<div className="document">
				<div className="mb-sm flex items-center ">
					<p className="text-xsm font-bold text-primary-700P">
						Autres documents
					</p>
				</div>
				<FileInput
					isLoading={otherDocumentsLoadingState}
					id="documents-upload"
					onError={(files) => setFailedDocuments(files)}
					onSuccess={(e) => uploadFile(e, "other")}
					title={
						<p className="text-xxsm text-primary-300">
							Dépose ton CV, lettre de motivation, etc. ou{" "}
							<span className="cursor-pointer font-bold text-primary-700P">
								clique ici pour l’importer
							</span>
						</p>
					}
					acceptFormats={["jpg", "pdf", "png", "docx"]}
				/>
			</div>
			<div className="import-fichier">
				{failedDocuments.map((doc) => {
					return (
						<FilesPrimary
							isLoading={otherDocumentsLoadingState}
							icon={fileTextOutline}
							title={doc.name}
							subtitle="Échec de la mise en ligne"
							error
							onRefresh={() =>
								document.getElementById("documents-upload").click()
							}
							className="mb-sm"
						/>
					);
				})}
				<p className="text-xxsm font-bold text-primary-300">
					Fichiers importés
				</p>
				<div className="mt-xsm">
					{otherDocuments
						.filter((doc) => doc.type === "other")
						.slice(0)
						.reverse()
						.map((doc) => {
							const fileName = doc.filePath.split(".");
							const extension = fileName[fileName.length - 1].toUpperCase();
							if (doc.progress && doc.progress < 100) {
								return (
									<FilesPrimary
										icon={fileTextOutline}
										title={doc.filePath}
										progressValue={doc.progress}
										className="mb-sm"
										key={doc.filePath}
									/>
								);
							}
							return (
								<FilesPrimary
									key={doc.filePath}
									isLoading={otherDocumentsLoadingState}
									icon={fileTextOutline}
									title={doc.filePath}
									onDelete={() => deleteDocument(doc, "other")}
									onView={() => {
										window.open(`${import.meta.env.VITE_S3}${doc.filePath}`);
									}}
									subtitle={`${moment(doc.createdAt).format(
										"DD/MM/YYYY",
									)} ‧ ${extension} ‧ ${Math.round(doc.size / 1000)} Ko`}
									className="mb-sm"
								/>
							);
						})}
				</div>
			</div>

			<OnHoldApplicationModal
				onClose={() => {
					setShowOnHoldModal(!showOnHoldModal);
				}}
				show={showOnHoldModal}
				uploadFile={uploadFile}
				applicationProps={applicationProps}
			/>
			<RefusedApplicationModal
				show={showSeeMotif}
				state={application.state}
				updateAt={application.updateAt}
				treatmentReason={application.treatmentReason}
				onClose={() => {
					setShowSeeMotif(!showSeeMotif);
				}}
				messages={application.messages}
			/>
			<UpdateModal
				messages={application.messages}
				show={showUpdateModal}
				onClose={() => {
					setShowUpdateModal(!showUpdateModal);
				}}
			/>
		</div>
	);
}

Application.propTypes = {
	applicationProps: PropTypes.shape({
		application: PropTypes.shape({
			id: PropTypes.string,
			job: PropTypes.string,
			localisation: PropTypes.string,
			offerLink: PropTypes.string,
			contract: PropTypes.string,
			type: PropTypes.string,
			accepted: PropTypes.bool,
			step: PropTypes.string,
		}),
		setApplication: PropTypes.func,
	}).isRequired,
};
