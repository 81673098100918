import type { ApplicationStepType } from "@/types/applicationTypes";
import React from "react";

import { APPLICATION_STEP_OPTIONS } from "@constants/Applications";

import { ProgressIndicator } from "@components/atoms/Progress";
import { Stepper } from "@components/molecules/Stepper";

type ApplicationStepProps = {
	step: ApplicationStepType;
	useIndicator?: boolean;
};

export function ApplicationStep({
	step,
	useIndicator = false,
}: ApplicationStepProps) {
	const findStepIndex = (step: ApplicationStepType) => {
		return APPLICATION_STEP_OPTIONS.findIndex(
			(stepOption) => stepOption.value === step,
		);
	};

	return (
		<article>
			{useIndicator ? (
				<ProgressIndicator
					steps={[
						{ label: "En préparation", accentColor: 1, value: 0 },
						{ label: "Candidature envoyée", accentColor: 2, value: 0 },
						{ label: "Relance réalisée", accentColor: 3, value: 0 },
						{ label: "Entretien obtenu", accentColor: 4, value: 0 },
					]}
					indexActive={findStepIndex(step)}
					className="!flex max-w-full"
					onClickOnStep={() => {}}
				/>
			) : (
				<Stepper
					indexActive={findStepIndex(step)}
					steps={[
						{ label: "En préparation" },
						{ label: "Candidature envoyée" },
						{ label: "Relance réalisée" },
						{ label: "Entretien obtenu" },
					]}
					color={findStepIndex(step) + 1}
					className="mt-sm"
				/>
			)}
		</article>
	);
}
