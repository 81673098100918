import type { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { fetchCompanyContact } from "../usecases/fetchCompanyContact.usecase";
import { fetchCompanyInformations } from "../usecases/fetchCompanyInformations.usecase";
import { fetchCompanyOffers } from "../usecases/fetchCompanyOffers.usecase";
import { fetchCompanyRelations } from "../usecases/fetchCompanyRelations.usecase";
import { companiesManagementSlice } from "./companiesManagement.slice";

export const registerCompanyFetcherListeners = (
	listener: ListenerMiddlewareInstance,
) => {
	listener.startListening({
		actionCreator: companiesManagementSlice.actions.endOfCompanyIdExtraction,
		effect: (_, api) => {
			api.dispatch(fetchCompanyInformations);
			api.dispatch(fetchCompanyContact);
			api.dispatch(fetchCompanyOffers);
			api.dispatch(fetchCompanyRelations);
		},
	});
};
