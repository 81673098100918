import type { CampusType, CampusesCollectionType } from "@/types/campusTypes";

import type { ViewType } from "@/types/genericType";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext, type GlobalContextType } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { extractIdNumberFromId } from "@tools/Users";

import { Spinner } from "@components/atoms/Spinner";
import { Select } from "@components/organisms/select/Select";
import { useAppDispatch } from "@config/redux/store";
import { extractCampusList } from "@tools/Campuses";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";
import { fetchCampuses } from "../../core/store/companiesManagement.thunks";

export function SelectCampuses() {
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatchEvent = useAppDispatch();
	const { structureView }: { structureView: ViewType } = useOutletContext();
	const { user }: GlobalContextType = useContext(GlobalContext);
	const { companyToCreate, loadingStates, campusesListReceived } =
		useAppSelector((state) => state.companiesManagement);
	const [campusesOptions, setCampusesOptions] = useState<any[]>([]);

	const isActuallySelected = (campusId: string | undefined): boolean => {
		if (typeof campusId !== "string") return false;

		const rawCampuses: CampusesCollectionType[] = [
			companyToCreate?.campuses as CampusesCollectionType,
		];
		const extractedCampusList = extractCampusList(
			rawCampuses[user?.roles.includes("ROLE_SCHOOL") ? 1 : 0],
		);

		if (extractedCampusList?.length === 0) {
			return false;
		}

		const occurencesOfThisCampus =
			extractedCampusList?.filter((campusToCheck: string) => {
				return campusToCheck === campusId;
			}) || [];

		return occurencesOfThisCampus?.length > 0;
	};
	const addUserCampusToTheList = async (campus: CampusType | undefined) => {
		dispatchEvent(
			modifyCompanyToCreate({
				coreCampuses: [
					campus?.id,
					[
						{
							id: campus?.id,
							name: campus?.name,
							value: campus?.id,
							checked: true,
							type: "checkbox",
						},
					],
				],
			}),
		);
	};

	const isTheCampusOfTheUser = (campus: CampusType | undefined): boolean => {
		if (!companyToCreate?.campuses && user?.roles.includes("ROLE_SCHOOL")) {
			const userCampusId = extractIdNumberFromId(user?.campuses[0].id);
			const campusId = extractIdNumberFromId(campus?.id);
			addUserCampusToTheList(campus);
			return userCampusId === campusId;
		}
		if (!companyToCreate?.coreCampuses) {
			if (
				extractIdNumberFromId(structureView?.campus) ===
				extractIdNumberFromId(campus?.id)
			) {
				addUserCampusToTheList(campus);
				return true;
			}
		}
		return false;
	};

	const getCampusOptions = () => {
		if (!campusesListReceived) return;
		const campusesForCheck =
			companyToCreate?.coreCampuses as unknown as Array<any>;
		setCampusesOptions(
			campusesListReceived?.map((campusElement: CampusType) => {
				return {
					label: campusElement?.name,
					value: campusElement?.id,
					checked:
						!companyToCreate?.coreCampuses ||
						(campusesForCheck?.length === 2 &&
							campusesForCheck?.[1].length === 1)
							? isTheCampusOfTheUser(campusElement)
							: isActuallySelected(campusElement?.id),

					type: "checkbox",
				};
			}),
		);
	};

	const updateCampusOptions = (campuses: any[]) => {
		const campusSelectedIds: string[] = [];
		campuses?.forEach((campus) => {
			campusSelectedIds.push(campus?.value);
		});
		setCampusesOptions(
			campusesOptions?.map((campusElement) => {
				return {
					...campusElement,
					checked: campusSelectedIds?.includes(campusElement?.value),
				};
			}),
		);
	};

	const handleChangeCampus = (campus: string) => {
		const campusSelectedIds: string[] = [];
		if (campusSelectedIds.includes(campus)) {
			campusSelectedIds.splice(campusSelectedIds.indexOf(campus), 1);
		} else {
			campusSelectedIds.push(campus);
		}
		dispatchEvent(
			modifyCompanyToCreate({ coreCampuses: ["", campusSelectedIds[0]] }),
		);
	};

	useEffect(() => {
		if (campusesListReceived) {
			if (user?.roles.includes("ROLE_CAMPUS_USER")) {
				dispatchEvent(
					modifyCompanyToCreate({ coreCampuses: [user?.campuses[0]] }),
				);
			} else {
				getCampusOptions();
			}
		}
	}, [campusesListReceived]);

	useEffect(() => {
		dispatchEvent(fetchCampuses({ schoolId: `${user?.school}` || "" }) as any);
	}, []);

	if (loadingStates.isFetchingCampuses) {
		return <Spinner size="small" />;
	}
	return (
		<>
			{!user?.roles?.includes("ROLE_CAMPUS_USER") && (
				<Select
					label="Campus*"
					position="right"
					placeholder="Campus"
					inputs={campusesOptions}
					multiselect
					onChange={(e: any) => {
						handleChangeCampus(e);
						updateCampusOptions(e);
					}}
					searchbarPlaceholder="Rechercher un campus"
					className="wg-no-translate w-full"
				/>
			)}
		</>
	);
}
