import React from "react";

import "@assets/sass/Legend.scss";

type LegendPropsType = {
	legend: { color?: string; name: string }[];
	direction?: string;
	className?: string;
};

export function Legend({
	legend,
	direction = "row",
	className = "",
}: LegendPropsType) {
	return (
		<div data-id="legend" className={` ${className} flex flex-${direction}`}>
			{legend.map((elem, i) => {
				return (
					<div
						className={`${
							direction === "column" ? "mb-sm" : "mr-sm"
						} flex items-center`}
						key={i}
					>
						<div
							style={{
								backgroundColor: elem.color,
								width: 15,
								height: 8,
								borderRadius: 8,
							}}
						/>
						<p className="legend-p">{elem.name}</p>
					</div>
				);
			})}
		</div>
	);
}
