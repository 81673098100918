import type { EntEmployeeType, EntOffersType } from "@/types/EntCompanyTypes";
import { format } from "date-fns";
import React from "react";

import {
	CONTRACT_TYPE_OPTIONS,
	QUALIFICATION_OPTIONS,
	TELEWORK_OPTIONS,
} from "@constants/Offers";

import { convertDateFromNow } from "@tools/Dates";

export function OfferMainInfo({ activeOffer }: { activeOffer: EntOffersType }) {
	const getContract = (contract: string) => {
		return (
			CONTRACT_TYPE_OPTIONS.find((e) => e.value === contract)?.label ||
			CONTRACT_TYPE_OPTIONS.find((e) => e.default === true)?.label
		);
	};
	const getTelework = (telework: string) => {
		return (
			TELEWORK_OPTIONS.find((e) => e.value === telework)?.label ||
			TELEWORK_OPTIONS.find((e) => e.default === true)?.label
		);
	};
	const getQualification = (qualification: string) => {
		return (
			QUALIFICATION_OPTIONS.find((e) => e.value === qualification)?.label ||
			QUALIFICATION_OPTIONS.find((e) => e.default === true)?.label
		);
	};
	const infosLeft = [
		{ title: "Contrat", content: getContract(activeOffer.contract) },
		{ title: "Lieu du poste", content: activeOffer.location },
		{
			title: "Niveau requis",
			content: getQualification(activeOffer.qualification!),
		},
	];
	const createdByUser = activeOffer.createdBy as EntEmployeeType;
	const infosRight = [
		{ title: "Télétravail", content: getTelework(activeOffer.telework) },
		{
			title: "Publiée par",
			content: createdByUser
				? `${createdByUser?.firstname} ${createdByUser?.lastname}`
				: "Inconnu",
		},
		{
			title: "Création",
			content: `${convertDateFromNow(activeOffer.createdAt!)} - ${format(
				new Date(activeOffer.createdAt!),
				"d/M/y",
			)}`,
		},
	];

	return (
		<div className="border-b-[1px] border-primary-100 pb-sm text-center sm:text-start md:flex">
			<div className="w-full border-b-[1px] border-primary-100 p-sm md:border-b-0 md:border-r-[1px]">
				{infosLeft.map((info) => {
					return (
						<div key={`left-${info.title.replace(" ", "-").toLowerCase()}`}>
							<p className="text-xxsm text-primary-500">{info.title}</p>
							<p className="mb-sm">{info.content}</p>
						</div>
					);
				})}
			</div>
			<div className="w-full p-sm">
				{infosRight.map((info) => {
					return (
						<div key={`right-${info.title.replace(" ", "-").toLowerCase()}`}>
							<p className="text-xxsm text-primary-500">{info.title}</p>
							<p className="mb-sm">{info.content}</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}
