import type { EventInvitationType } from "@/types/eventsInvitationType";
import type { EventRepliesState, EventType } from "@/types/eventsTypes";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { checkAccessMentorGoalEvent } from "@tools/Events";

export type RepliesTabSelectedPayload = {
	tabSelected: number;
	targetEventId: string;
	isMentorGoalEvent: boolean;
};

export type EventsState = {
	replayLinkToAdd: string;
	targetEvent: EventType | undefined;
	eventToCreate: EventType | undefined;
	events: EventType[];
	isMentorGoalUser: boolean;
	titleToSearch: string;
	isFetchingData: boolean;
	initialized: boolean;
	isReplyingToInvitation: boolean;
	isFetchingTargetEvent: boolean;
	fetchingInvitationsState: "idle" | "loading" | "success" | "error";
	fetchingInvitationsError: string;
	relaunchInvitationError: string;
	performFetchEvents: boolean;
	studentId?: string;
	showRepliesListModal?: boolean;
	eventReplies: EventRepliesState;
};

const initialState: EventsState = {
	replayLinkToAdd: "",
	targetEvent: undefined,
	eventToCreate: undefined,
	events: [],
	isMentorGoalUser: false,
	titleToSearch: "",
	isFetchingData: false,
	initialized: false,
	isReplyingToInvitation: false,
	isFetchingTargetEvent: false,
	performFetchEvents: false,
	fetchingInvitationsState: "idle",
	fetchingInvitationsError: "",
	relaunchInvitationError: "",
	studentId: "",
	showRepliesListModal: false,
	eventReplies: {
		replies: [],
		status: "idle",
		error: "",
		counters: {
			maybe: 0,
			registered: 0,
			notParticipating: 0,
			noReply: 0,
			status: "idle",
			error: "",
		},
	},
};

export const eventsSlice = createSlice({
	name: "eventsManagement",
	initialState,
	reducers: {
		handleInvitationsLoading(state) {
			state.fetchingInvitationsState = "loading";
		},
		addInvitationsToEvents(state, action: PayloadAction<Partial<EventType>[]>) {
			state.fetchingInvitationsState = "success";

			const tmpEvents = state.events;
			action.payload.forEach((event) => {
				const index = tmpEvents.findIndex((e) => e.id === event.id);
				if (index !== -1) {
					tmpEvents[index] = {
						...tmpEvents[index],
						invitations: event.invitations,
					};
				}
			});
			state.events = tmpEvents;
		},
		handleInvitationsError(state, action: PayloadAction<string>) {
			state.fetchingInvitationsState = "error";
			state.fetchingInvitationsError = action.payload;
			ErrorToast(action.payload);
		},
		handleRepliesLoading(state) {
			state.eventReplies.status = "loading";
		},
		handleRepliesError(state, action: PayloadAction<string>) {
			state.eventReplies.status = "error";
			state.eventReplies.error = action.payload;
			ErrorToast(action.payload);
		},
		handleRepliesSuccess(state, action: PayloadAction<EventInvitationType[]>) {
			state.eventReplies.status = "success";
			state.eventReplies.replies = action.payload;
		},
		resetEventReplies(state) {
			state.eventReplies = initialState.eventReplies;
		},
		handleRepliesCountersLoading(state) {
			state.eventReplies.counters.status = "loading";
		},
		handleRepliesCountersError(state, action: PayloadAction<string>) {
			state.eventReplies.counters.status = "error";
			state.eventReplies.counters.error = action.payload;
			ErrorToast(action.payload);
		},
		handleRepliesCountersSuccess(
			state,
			action: PayloadAction<{ [key: string]: number }>,
		) {
			state.eventReplies.counters.status = "success";
			state.eventReplies.counters.maybe = action.payload.maybe;
			state.eventReplies.counters.registered = action.payload.registered;
			state.eventReplies.counters.notParticipating =
				action.payload.notParticipating;
			state.eventReplies.counters.noReply = action.payload.noReply;
		},
		handleRelaunchIndividualInvitationError(
			state,
			action: PayloadAction<string>,
		) {
			state.relaunchInvitationError = action.payload;
			ErrorToast(action.payload);
		},
		setReplayLinkToAdd(state, action) {
			state.replayLinkToAdd = action.payload;
		},
		setEventTarget(state, action) {
			state.targetEvent = action.payload;
			state.eventToCreate = action.payload;
		},
		setEventToCreate(state, action) {
			state.eventToCreate = action.payload;
			if (
				action.payload?.location === "Distanciel" &&
				state.eventToCreate?.address
			) {
				state.eventToCreate.address = "";
			}
			if (
				action.payload?.location === "Sur site" &&
				state.eventToCreate?.eventLink
			) {
				state.eventToCreate.eventLink = "";
			}
			if (
				state?.eventToCreate?.startDate &&
				state?.eventToCreate?.endDate === undefined
			) {
				state.eventToCreate.endDate = state.eventToCreate.startDate;
			}
			if (state?.eventToCreate?.startDate && state?.eventToCreate?.endDate) {
				if (state.eventToCreate?.endDate < state.eventToCreate?.startDate) {
					state.eventToCreate.endDate = state.eventToCreate.startDate;
				}
			}
		},
		setIsMentorGoalUser(state, action) {
			state.isMentorGoalUser = checkAccessMentorGoalEvent(action.payload);
		},
		resetEventToCreate(state) {
			state.eventToCreate = undefined;
			state.targetEvent = undefined;
			state.titleToSearch = "";
			state.events = [];
		},
		setEvents(
			state,
			action: PayloadAction<
				EventType[] | { events: EventType[]; studentId: string; tab?: string }
			>,
		) {
			if (Array.isArray(action.payload)) {
				state.events = action.payload;
			} else {
				state.events = action.payload.events;
				state.studentId = action.payload.studentId;
			}
		},
		setTitleToSearch(state, action) {
			state.titleToSearch = action.payload;
		},
		refreshModifiedEvent(state, action) {
			state.events = state.events.map((event) => {
				if (event.id === action.payload.id) {
					return action.payload;
				}
				return event;
			});
		},
		setIsFetchingData(state, action) {
			state.isFetchingData = action.payload;
		},
		setInitialized(state, action) {
			state.initialized = action.payload;
		},
		updateEventInvitationReply(state, action) {
			const eventsWithInvitationsUpdated = state.events.map((event) => {
				if (event.id === action.payload.eventId) {
					const invitationsCollectionCopy = [];
					invitationsCollectionCopy.push({
						reply: action.payload.reply,
						student: {
							id: `${action.payload.studentId}`,
						},
					});
					return {
						...event,
						invitations: {
							...event.invitations,
							collection: invitationsCollectionCopy,
						},
					};
				}
				return event;
			});
			state.events = eventsWithInvitationsUpdated as any;
		},
		setIsReplayingToInvitation(state, action) {
			state.isReplyingToInvitation = action.payload;
		},
		setIsFetchingTargetEvent(state, action) {
			state.isFetchingTargetEvent = action.payload;
		},
		setPerformFetchEvents(state, action) {
			state.performFetchEvents = action.payload;
		},
		setShowRepliesListModal(
			state,
			action: PayloadAction<{
				showRepliesListModal: boolean;
				eventId?: string;
				isMentorGoalEvent?: boolean;
			}>,
		) {
			state.showRepliesListModal = action.payload.showRepliesListModal;
		},
	},
});

export const eventsActions = eventsSlice.actions;
export const eventsReducer = eventsSlice.reducer;
