import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { STUDENT_BIRTHDATE_OPTIONS } from "@constants/Users";

import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

import { countCurrentFilters, setCurrentFilters } from "../usersFiltersSlice";

type SelectOptionType = {
	id?: number;
	label?: string;
	value: string | number | null | object;
};

export function BirthdateFilter() {
	const dispatchEvent = useDispatch();
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);

	const handleChangeBirthdateFilter = (option: SelectOptionType) => {
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				birthdate: option?.value
					? { label: option.label, value: option.value }
					: null,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const getBirthdateLabelFromFilters = (): any => {
		if (currentFilters?.birthdate) {
			const optionFind = STUDENT_BIRTHDATE_OPTIONS.find((option) => {
				return option.label === currentFilters.birthdate?.label;
			});
			return optionFind?.label;
		}
		return "Age : Tous";
	};
	return (
		<SelectTertiary
			position="right"
			placeholder="Age : Tous"
			options={STUDENT_BIRTHDATE_OPTIONS}
			defaultValue={getBirthdateLabelFromFilters()}
			className="w-full max-w-[160px]"
			onChange={(option) => {
				handleChangeBirthdateFilter(option);
			}}
		/>
	);
}
