export const SEARCH_DISTANCE_OPTIONS = [
	{
		label: "Moins de 10km",
		value: 0,
	},
	{
		label: "10 - 20 km",
		value: 1,
	},
	{
		label: "20 - 30 km",
		value: 2,
	},
	{
		label: "30 - 45 km",
		value: 3,
	},
	{
		label: "45 - 60 km",
		value: 4,
	},
	{
		label: "60 km et plus",
		value: 5,
	},
	{
		label: "Toute la France",
		value: 6,
	},
];

export enum EOnboarding {
	DASHBOARD = 0,
	APPLICATIONS = 1,
	RESOURCES = 2,
	JOBBOARD = 3,
}

type OnboardingTitlesItemType = {
	title: string;
	firstContentStartMsg?: string;
	secondContentStartMsg?: string;
	firstContentEndMsg?: string;
	secondContentEndMsg?: string;
	firstContent?: string;
	secondContent?: string;
	firstBtn: { label: string };
	secondBtn: { label: string };
};
type OnboardingTitlesType = {
	dashboard: OnboardingTitlesItemType[];
	applications: OnboardingTitlesItemType[];
	resources: OnboardingTitlesItemType[];
	jobboard: OnboardingTitlesItemType[];
};

export const ONBOARDING_TITLES: OnboardingTitlesType = {
	dashboard: [
		{
			title: "Bienvenue",
			firstContentStartMsg: "Bonjour",
			firstContentEndMsg: "et bienvenue sur ta plateforme emploi. 🎉",
			secondContent:
				"Retrouve ici toutes les informations nécessaires à ton profil. Dans un premier temps, importe ton image de profil. ✨",
			firstBtn: { label: "Je le ferai plus tard" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Tes premiers pas",
			firstContent:
				"Pour bien lancer ta recherche, on te guide pour tes permiers pas sur la plateforme.",
			secondContent:
				"Elles te seront utiles pour commencer l’aventure de recherche d’emploi ! ✨",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Les objectifs hebdomadaires",
			firstContent:
				"On t’a mis des objectifs hebdomadaires et on compte sur toi pour les atteindre.",
			secondContent:
				"Chaque semaine, atteins les objectifs pour optimiser ta recherche d’emploi. ✨",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Les actions",
			firstContent:
				"Tu peux retrouver la liste des tâches à faire pour tes candidatures.",
			secondContent:
				"Les actions à venir : celles-ci sont les objectifs à atteindre dès lors qu’une offre t’intérresse ! ✨",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "J'ai compris" },
		},
	],
	applications: [
		{
			title: "Découvre ton tableau de suivi",
			firstContent:
				"Bienvenue sur tes candidatures, où tu pourras gérer l'avancement de ta recherche d’emploi.",
			secondContent:
				"Tu peux glisser tes candidatures d’une colonne à l’autre ! 😎",
			firstBtn: { label: "Passer" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Le détail d’une candidature",
			firstContent:
				"Clique sur une candidature pour voir le détail et compléter les informations.",
			secondContent:
				"Ce tableau te permet de faire un suivi précis de tes candidatures. N'oublie pas de régulièrement mettre à jour les informations pour faciliter ta recherche d'emploi.",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "J'ai compris" },
		},
	],
	jobboard: [
		{
			title: "La jobthèque",
			firstContent:
				"Bienvenue sur la Jobthèque, où tu pourras trouver des offres d’emploi.",
			secondContent:
				"N’hésite pas à mettre des mots clés dans la barre de recherche pour optimiser tes recherches.",
			firstBtn: { label: "Passer" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Les offres partenaires",
			firstContent:
				"Retrouve facilement les offres exclusives de ton école ou les offres qui proviennent de jobboards comme Indeed, LinkedIn, Pôle emploi...",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Postuler à une offre",
			firstContent:
				"Quand une offre t’intéresse, tu peux cliquer sur le “+“ pour l’ajouter directement dans ton espace “Mes candidatures”.",
			secondContentStartMsg:
				"Les offres jobboard sont des offres avec des liens externes. Les offres",
			secondContentEndMsg:
				"sont proposées par les entreprises partenaires de ton école. Avec elles, tu peux postuler directement via la plateforme Mentor Goal.",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "J'ai compris" },
		},
	],
	resources: [
		{
			title: "Trouve la ressource qu’il te faut !",
			firstContentStartMsg:
				"Ici, tu peux retrouver toutes les ressources partagées par",
			firstContentEndMsg: "et par Mentor Goal. 😎",
			firstBtn: { label: "Passer" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Modèles de CV",
			firstContent:
				"Tu peux retrouver les différents modèles de CV proposés par ton école et Mentor Goal ✨",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "Suivant" },
		},
		{
			title: "Modèles de documents de motivation",
			firstContent:
				"Tu peux également retrouver des modèles de documents de motivation créés par ton école et par Mentor Goal ✨",
			firstBtn: { label: "Précédent" },
			secondBtn: { label: "J'ai compris" },
		},
	],
};

export const INTERVIEW_STRINGS = {
	questions: {
		"0": "1. Parlez moi de vous...",
		"1": "2. Que savez-vous de notre entreprise ?",
		"2": "3. En quoi le poste vous intéresse-t-il ?",
		"3": "4. Parlez moi d'un projet qui vous tient particulièrement à coeur.",
		"4": "5. Quels sont vos objectifs à court, moyen et long termes ?",
		"5": "6. Pourquoi vous et pas un autre ?",
		"6": "7. Si vous pouviez revenir dans le passé, feriez-vous différemment certaines choses ?",
		"7": "8. Quels sont vos axes d’amélioration / Vos points faibles ?",
		"8": "9. Qui sont nos concurrents ?",
		"9": "10. Avez-vous des questions ?",
	},
	tooltips: {
		"0": "Le recruteur s'attend à ce que tu présentes ton parcours professionnel de manière simple et chronologique.",
		"1": "Explique ta connaissance globale de l'entreprise, son organisation, sa culture, ses valeurs, son activité, et même du département dans lequel tu aimerais travailler.",
		"2": "C'est le moment de montrer tes motivations avec des éléments concrets. Parle de tes projets, de tes réalisations qui t'ont stimulé lors de tes jobs ou formations précédents.",
		"3": "Explique ton projet, pourquoi tu l'apprécies, comment il s'est déroulé, les points positifs et négatifs. Prends du recul.",
		"4": "Reste humble, mais montre aussi que tu as assez d'ambition pour te projeter dans l'avenir. Tes objectifs professionnels doivent être en ligne avec les opportunités de développement offertes par l'entreprise : ainsi, ton interlocuteur pourra se projeter avec toi.",
		"5": "Donne des exemples de projets ou de réussites qui mettent en avant les compétences que tu recherches. Fais attention à ne pas être trop confiant.",
		"6": "Prends du recul sur tes expériences. Le recruteur veut voir si tu as tiré des leçons de tes expériences.",
		"7": "Ne réponds pas de manière stéréotypée : explique quelque chose que tu peux justifier.",
		"8": "Il n'y a pas de truc, mais sois prêt à justifier tes choix : pourquoi sont-ils pertinents ?",
		"9": "Évite les questions dont les réponses sont disponibles sur Internet. Montre que tu es intéressé par l'entreprise en posant des questions sur ton futur environnement de travail, par exemple.",
	},
	placeholder: {
		"0": "J’ai suivi cette formation en 2018, puis...",
		"1": "Votre entreprise est reputée car...",
		"2": "Ce poste m’intéresse car j’ai déjà travaillé sur...",
		"3": "C’est un projet qui consiste en... Il me passionne car...",
		"4": "À court terme, mes objectifs sont...",
		"5": "Je pense répondre en tous points à vos attentes car...",
		"6": "J’ai tiré une leçon de certaines expériences, dont...",
		"7": "Je peux m’améliorer sur... car...",
		"8": "J’ai remarqué que... et... sont vos concurrents, parce que...",
		"9": "Quel environnement de travail proposez-vous ?",
	},
};

export const MODULE_PRESET = {
	toolbar: [["bold", "italic", "underline"], [{ list: "bullet" }]],
	clipboard: {
		matchVisual: false,
	},
};

export const MODULE_PRESET_STUDENT = {
	toolbar: [],
	clipboard: {
		matchVisual: false,
	},
};

export const MODULE_PRESET_OFFER = {
	toolbar: [
		["bold", "italic", "underline"],
		[{ list: "bullet" }, { list: "ordered" }],
		[{ header: "1" }, { header: "2" }],
		[{ align: "" }, { align: "center" }, { align: "right" }],
		[{ size: ["12px", "14px", "16px", "20px", "24px", "32px"] }],
		[
			{
				font: [
					"lato",
					"montserrat",
					"mulish",
					"nunito",
					"open-sans",
					"playfair-display",
					"poppins",
					"roboto",
				],
			},
		],
	],
	clipboard: {
		matchVisual: false,
	},
};
