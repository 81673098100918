import type { SelectionType } from "@/types/profileSharingTypes";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Spinner } from "@components/atoms/Spinner";
import { Modal } from "@components/organisms/modal/Modal";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";
import { sendSelectionByEmail } from "@containers/school/CVBoard/CVBoardAPI";
import { validateEmail } from "@containers/school/CVBoard/ui/CVsList/TableView/CreateSelectionModal";
import { ShareEmail } from "@containers/school/CVBoard/ui/CVsList/TableView/ShareCVModal/ShareEmail";
import { useState } from "react";
import React from "react";

export interface ShareSelectionModalType {
	show: boolean;
	onClose: () => void;
	selection: Partial<SelectionType> | undefined;
}

const EMAIL_BODY =
	"Bonjour,<br/><br/>Voici des profils qui pourraient intéresser votre entreprise.<br/><br/>Cliquez sur le bouton ci-dessous pour voir les profils.<br/><br/>Bonne journée";

const EMAIL_SUBJECT = "Invitation - Partage de profils";

export const ShareSelectionEmailModal = ({
	show,
	selection,
	onClose,
}: ShareSelectionModalType) => {
	const [load, setLoad] = useState(false);
	const [recipient, setRecipient] = useState("");
	const [cc, setCc] = useState("");
	const [subjectInputValue, setSubjectInputValue] = useState(EMAIL_SUBJECT);
	const [bodyInputValue, setBodyInputValue] = useState(EMAIL_BODY);

	const resetModal = () => {
		setRecipient("");
		setCc("");
		setSubjectInputValue(EMAIL_SUBJECT);
		setBodyInputValue(EMAIL_BODY);
	};

	const sendEmails = async () => {
		setLoad(true);
		const emails = recipient.trim();
		const ccEmails = cc.split(",").map((email) => email.trim());
		// delete empty emails
		ccEmails.forEach((email, index) => {
			if (email === "") {
				ccEmails.splice(index, 1);
			}
		});
		// check if the email is valid
		if (!validateEmail(emails)) {
			ErrorToast("L'email n'est pas valide");
			setLoad(false);
			return;
		}
		// check if the cc emails are valid
		for (const email of ccEmails) {
			if (!validateEmail(email)) {
				ErrorToast("L'email n'est pas valide");
				setLoad(false);
				return;
			}
		}
		// check if the email is not the same as the cc emails
		if (ccEmails.includes(emails)) {
			ErrorToast("L'email ne peut pas être en copie");
			setLoad(false);
			return;
		}
		// check if object size is not too big
		if (subjectInputValue.length > 255) {
			ErrorToast("L'objet de l'email est trop long");
			setLoad(false);
			return;
		}
		// check if body size is not too big
		if (bodyInputValue.length > 1000) {
			ErrorToast("Le contenu de l'email est trop long");
			setLoad(false);
			return;
		}
		const response = await sendSelectionByEmail(
			selection?.id as string,
			emails,
			ccEmails,
			subjectInputValue,
			bodyInputValue,
		).catch((error) => {
			return error;
		});
		if (response?.status === 200) {
			Success("L'email a bien été envoyé");
			resetModal();
			onClose();
		} else {
			ErrorToast("Une erreur est survenue lors de l'envoi des emails");
		}
		setLoad(false);
	};

	return (
		<Modal
			title={`Partager par email - ${selection?.name}`}
			body={
				load ? (
					<div className="flex items-center justify-center">
						<Spinner />
					</div>
				) : (
					<ShareEmail
						selection={selection}
						recipientProps={{ recipient, setRecipient }}
						ccProps={{ cc, setCc }}
						subjectInputValueProps={{ subjectInputValue, setSubjectInputValue }}
						bodyInputValueProps={{ bodyInputValue, setBodyInputValue }}
					/>
				)
			}
			buttonsRight={[
				<ButtonPrimary
					onClick={sendEmails}
					disabled={
						load ||
						recipient.trim() === "" ||
						subjectInputValue.trim() === "" ||
						bodyInputValue.trim() === "" ||
						bodyInputValue.length > 1000
					}
					label="Envoyer"
				/>,
			]}
			size="lg"
			forceLarge={true}
			show={show}
			onClose={onClose}
		/>
	);
};
