import React, { useEffect, useState } from "react";

export const RedirectCountDown = ({ url }: { url: string }) => {
	const [remaining, setRemaining] = useState(5);

	useEffect(() => {
		function tick() {
			if (remaining > 0) setRemaining(remaining - 1);
		}

		const countdown = setInterval(tick, 1000);

		if (remaining <= 0) {
			clearInterval(countdown);
			window.location.href = url;
		}

		return () => clearInterval(countdown);
	}, [remaining]);
	return (
		<p>
			Vous allez être redirigé dans{" "}
			<span className={"font-bold"}>{remaining}</span> seconde
			{remaining > 1 ? "s" : ""}.
		</p>
	);
};
