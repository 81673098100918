import api, { graphqlRequestConstructor } from "@tools/API";
import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";

export async function createTag(inputs) {
	const data = {
		query: `mutation{
            createTag(input: ${stringifyRequestInputs(inputs)}){
               tag{
                id
             }  
            }
        }`,
	};
	const rep = await graphqlRequestConstructor(data, "createTag");
	return rep;
}

export async function editTag(inputs) {
	const data = {
		query: `mutation{
      updateTag(input: ${stringifyRequestInputs(inputs)}){
         tag{
          id
       }  
      }
  }`,
	};

	const rep = await graphqlRequestConstructor(data, "editTag");
	return rep;
}

export async function deleteTag(tagId) {
	let rep = {};
	const data = {
		query: `mutation{
      deleteTag(input:{id: "${tagId}"}){
        tag{
          id
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.deleteTag.tag;
	});
	return rep;
}

export async function getTagsManagementData(school, filters) {
	const data = {
		query: `query{
					tags(${
						filters.order ? "" : 'order: [{id: "DESC"}],'
					} school: "${school}", itemsPerPage: 10, ${stringifyRequestFilters(
						filters,
					)}){
									collection{
										id
										name
										color
										description
										type
								}
								paginationInfo {
									lastPage
								}
							}
							
						}`,
	};

	const rep = await graphqlRequestConstructor(data, "getTags");
	return rep;
}
