import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	getSchoolOffersCount,
	retrieveSchoolOffers,
	retrieveScrapedOffers,
} from "./jobboard.api";

export const fetchSchoolOffers = createAsyncThunk(
	"jobboardAPI/fetchSchoolOffers",
	async (params: any) => {
		const response = await retrieveSchoolOffers(params);
		if (response.responseLabel === "error" && response.message === "aborted") {
			throw new Error("aborted");
		}
		if (response.responseLabel === "error") {
			throw new Error(response.message);
		}
		return response;
	},
);

type FetchScrapedOffersParamsType = {
	search: string;
	algoliaFilters: object;
};
export const fetchScrapedOffers = createAsyncThunk(
	"jobboardAPI/fetchScrapedOffers",
	async (params: FetchScrapedOffersParamsType) => {
		const response = await retrieveScrapedOffers(
			params.search,
			params.algoliaFilters,
		);
		if (response.responseLabel === "error" && response.message === "aborted") {
			throw new Error("aborted");
		}
		if (response.responseLabel === "error") {
			throw new Error(response.message);
		}
		return response;
	},
);

export const fetchSchoolOffersCount = createAsyncThunk(
	"jobboardAPI/fetchSchoolOffersCount",
	async (params: any) => {
		const response = await getSchoolOffersCount(params);
		if (response.responseLabel === "error" && response.message === "aborted") {
			throw new Error("aborted");
		}
		if (response.responseLabel === "error") {
			throw new Error(response.message);
		}
		return response;
	},
);
