import useDebounce from "@hooks/useDebounce";
import React, { useContext, useEffect, useState } from "react";

import { MOBILITY_OPTIONS } from "@constants/Users";

import { checkPhone } from "@tools/Checkers";
import { objectsAreEqual } from "@tools/Objects";

import { ComboboxSearchAddress } from "@components/molecules/ComboboxSearchAddress";
import { Input } from "@components/molecules/Input";
import { InputPhoneNumber } from "@components/molecules/PhoneInput";
import { DatePicker } from "@components/organisms/DatePicker";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

import { MyProfileContext } from "@containers/student/StudentDashboard/TabsBlock/MyProfile";

export function Details() {
	const { profile, setProfile, updateProfileValue } =
		useContext(MyProfileContext);
	const [values, setValues] = useState({
		phone: profile.phone,
		address: profile.address,
		city: profile.city,
		postalCode: profile.postalCode,
		linkedin: profile.linkedin,
		portfolio: profile.portfolio,
		mobility: profile.mobility,
		birthdate: profile.birthdate,
		streetNumber: profile.streetNumber,
		streetName: profile.streetName,
		longitude: profile.longitude,
		latitude: profile.latitude,
		country: profile.country,
	});
	const debouncedValue = useDebounce(values);

	useEffect(() => {
		if (!objectsAreEqual(values, profile)) {
			updateProfileValue({ ...values });
			setProfile({ ...profile, ...values });
		}
	}, [debouncedValue]);

	return (
		<>
			<div className="flex flex-wrap gap-sm md:flex-nowrap">
				<DatePicker
					className="w-full md:w-6/12"
					label="Date de naissance"
					withIcon
					placeholder="Non renseignée"
					selectedDate={
						profile.birthdate ? new Date(profile.birthdate) : undefined
					}
					onDateChange={(date: Date, action: string) => {
						if (action === "click" || action === "input") {
							setValues({
								...values,
								birthdate: date.toISOString(),
							});
						}
					}}
				/>
				<SelectTertiary
					position="right"
					label="Moyen de transport"
					placeholder="Permis et véhiculé"
					className="w-full md:w-6/12"
					defaultValue={MOBILITY_OPTIONS.find(
						(mob) => mob.value === profile.mobility,
					)}
					options={MOBILITY_OPTIONS}
					onChange={(e) => {
						setValues({
							...values,
							mobility: e.value,
						});
					}}
				/>
			</div>
			<InputPhoneNumber
				label="Téléphone*"
				value={profile.phone || ""}
				onChange={(e: string) => {
					if (checkPhone(e)) {
						setValues({
							...values,
							phone: e,
						});
					}
				}}
				onBlur={() => {}}
			/>

			<ComboboxSearchAddress
				label="Adresse postale*"
				name="address"
				value={profile.address}
				onChange={({
					streetNumber,
					streetName,
					city,
					postalCode,
					searchValue,
					longitude,
					latitude,
					country,
				}) => {
					setValues({
						...values,
						address: searchValue,
						streetNumber: streetNumber?.longName,
						streetName: streetName?.longName,
						postalCode: postalCode?.longName,
						city: city?.longName,
						longitude,
						latitude,
						country: country?.longName,
					});
				}}
				placeholder="Adresse"
			/>

			<div className="flex flex-wrap gap-md md:flex-nowrap">
				<ComboboxSearchAddress
					className="w-full md:w-6/12"
					label="Ville"
					name="city"
					value={profile.city}
					onChange={({ searchValue }) => {
						setValues({
							...values,
							city: searchValue,
						});
					}}
					placeholder="Ville"
				/>

				<ComboboxSearchAddress
					className="w-full md:w-6/12"
					label="Code postal"
					name="postalCode"
					value={profile.postalCode}
					onChange={({ searchValue }) => {
						setValues({
							...values,
							postalCode: searchValue,
						});
					}}
					placeholder="Code postal"
				/>
			</div>

			<div className="flex flex-wrap gap-md md:flex-nowrap">
				<Input
					label="LinkedIn"
					placeholder="https://"
					name="linkedin"
					className="w-full p-0 md:w-6/12"
					value={profile.linkedin}
					onChange={(e) => {
						setValues({
							...values,
							linkedin: e.target.value,
						});
					}}
				/>

				<Input
					label="Portfolio"
					placeholder="https://"
					className="portfolio w-full py-0 pr-0 md:w-6/12"
					name="portfolio"
					value={profile.portfolio}
					onChange={(e) => {
						setValues({
							...values,
							portfolio: e.target.value,
						});
					}}
				/>
			</div>
		</>
	);
}
