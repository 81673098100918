import type { SelectionType } from "@/types/profileSharingTypes";

export const selectionHasNewUsers = (
	selection: Partial<SelectionType> | undefined,
) => {
	if (!selection || !selection.name || !selection.name.length) return false;
	return (
		selection.selectionUsers?.collection.length &&
		selection.userLastAdded &&
		(!selection.lastOpening || selection.userLastAdded > selection.lastOpening)
	);
};
