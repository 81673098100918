import type { IconifyIcon } from "@iconify/react";
import { de, enGB, es, fr } from "date-fns/locale";

import { flagDe, flagEs, flagFr, flagUk } from "@assets/Icons";

export type LangType = "fr" | "en" | "es" | "de";
// ALSO UPDATE MomentLocales.ts WHEN ADDING LANGUAGES
export const SUPPORTED_LANGUAGES: Array<{
	label: string;
	icon: IconifyIcon;
	value: LangType;
	dateFnsLocale: Locale;
}> = [
	{ label: "Français", icon: flagFr, value: "fr", dateFnsLocale: fr },
	{ label: "Allemand", icon: flagDe, value: "de", dateFnsLocale: de },
	{ label: "Espagnol", icon: flagEs, value: "es", dateFnsLocale: es },
	{ label: "Anglais", icon: flagUk, value: "en", dateFnsLocale: enGB },
];
export const SUPPORTED_LANGUAGES_CODES = SUPPORTED_LANGUAGES.map(
	(item) => item.value,
);

export const DATEFNS_LOCALES = () => {
	const locales: { [key: string]: Locale } = {};
	SUPPORTED_LANGUAGES.forEach((item) => {
		locales[item.value] = item.dateFnsLocale;
	});
	return locales;
};
