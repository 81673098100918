import React from "react";
import Skeleton from "react-loading-skeleton";
export const SeparatorLoading = () => {
	return (
		<div className="flex flex-row justify-center items-center gap-4">
			<div className="flex-1 border-b border-[1.5px] border-gray-300" />
			<span className="text-sm text-gray-500 font-semibold">
				<Skeleton width={100} height={24} />
			</span>
			<div className="flex-1 border-b border-[1.5px] border-gray-300" />
		</div>
	);
};
