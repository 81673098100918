import type { CityType } from "@/types/LocationTypes";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import SelectLocation from "@components/organisms/select/SelectLocation";

import { countCurrentFilters, setCurrentFilters } from "../offersFiltersSlice";

export function LocationFilter() {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.offersFilters);

	const handleLocationChange = (cityReceived: CityType) => {
		if (!cityReceived) {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					location: undefined,
					position: undefined,
				}),
			);
			return;
		}
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				location: cityReceived.location,
				position: [cityReceived.longitude, cityReceived.latitude],
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	const getCityObjectFromFilters = (): CityType | null => {
		if (currentFilters.location && currentFilters.position) {
			return {
				location: currentFilters.location,
				latitude: currentFilters.position[1],
				longitude: currentFilters.position[0],
			};
		}
		return null;
	};

	return (
		<div className="flex w-fit gap-1">
			<SelectLocation
				withLabel={false}
				handleNewLocation={(cityReceived: CityType) => {
					handleLocationChange(cityReceived);
				}}
				defaultValue={
					currentFilters.location ? getCityObjectFromFilters() : null
				}
			/>
		</div>
	);
}
