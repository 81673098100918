import type { ApplicationType } from "@/types/applicationTypes";
import TooltipContainer from "@components/atoms/TooltipContainer";
import TooltipElement from "@components/atoms/TooltipElement";
import { SystemIDProvider } from "@config/core/system.id-provider";
import React, { useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { ApplicationCard } from "./Column/ApplicationCard";

type ColumnProps = {
	title: string;
	className: string;
	index: number;
	applications: ApplicationType[];
	step: string;
	description: string;
	moveApplication: any;
	filtersProps: any;
	currentDestinationColumnName: string;
};

export function Column({
	title,
	className,
	applications,
	step,
	description,
	moveApplication,
}: ColumnProps) {
	const navigate = useNavigate();
	const idProvider = new SystemIDProvider();
	const [height, setHeight] = useState<number>(0);
	const ref = useRef<HTMLDivElement>(null);
	8;
	const [uniqueKey, setUniqueKey] = useState<string>("");

	const handleClickOnCard = (application: ApplicationType) => {
		navigate(`/applications/${application.id.split("/")[3]}`);
	};

	useEffect(() => {
		if (ref.current) {
			const screenHeight = window.innerHeight;
			setHeight(
				Math.round(screenHeight - ref.current.getBoundingClientRect().top),
			);
		}
		setUniqueKey(idProvider.generate());
	}, []);

	return (
		<div className="md:w-3/12">
			<div className={`border-b-2 ${className} pb-xsm`}>
				<div className="flex items-start gap-xxsm">
					<p className="truncate text-sm font-semibold">{title}</p>
					<TooltipElement id={`${step}-tooltip`} />
					<TooltipContainer
						anchorId={`#${step}-tooltip`}
						children={description}
						place="top"
					/>
				</div>
				<p className="truncate text-xxsm text-primary-700P">
					{applications?.length} candidature
					{applications?.length > 1 ? "s" : ""}
				</p>
			</div>

			<Droppable key={uniqueKey} droppableId={step}>
				{(provided) => (
					<div
						ref={ref}
						style={{ height: `${height}px` }}
						className="overflow-y-scroll scrollbar-hide w-64 pt-sm md:w-full"
					>
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
							className="flex flex-col gap-sm min-h-[50vh]"
						>
							{applications?.length > 0 &&
								applications.map((application, applicationIndex) => {
									const draggableId = application.id;
									return (
										<Draggable
											key={draggableId}
											draggableId={draggableId}
											index={applicationIndex}
										>
											{(provided) => (
												<div
													className="card-container-provider px-xsm w-full"
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<ApplicationCard
														applicationInitialState={application}
														moveApplication={moveApplication}
														onClick={() => handleClickOnCard(application)}
														index={applicationIndex}
														ondrop={() => {
															return "drop";
														}}
													/>
												</div>
											)}
										</Draggable>
									);
								})}
							{provided.placeholder}
						</div>
					</div>
				)}
			</Droppable>
		</div>
	);
}
