import React from "react";

import { PdfViewer } from "@components/atoms/PdfViewer";
import { Modal } from "../modal/Modal";

type DocViewerType = {
	title: string;
	file: string;
	show: boolean;
	onClose: Function;
};
export function DocViewer({ file, show, onClose, title }: DocViewerType) {
	if (!file) {
		<p>Chargment...</p>;
	}

	return (
		<div>
			<Modal
				show={show}
				title={title}
				onClose={onClose}
				body={<PdfViewer src={file} title={title} />}
				className="my-2 !h-screen"
			/>
		</div>
	);
}
