import {
	combineReducers,
	configureStore,
	createListenerMiddleware,
} from "@reduxjs/toolkit";

import type { Dependencies } from "@config/dependencies";
import { REDUX_SLICES } from "@constants/Slices";
import { registerCompanyFetcherListeners } from "@containers/school/Companies/core/store/fetchDetails.listener";
import { registerEventsFetcherListeners } from "@containers/shared/Events/core/store/events.listener";
import { useDispatch } from "react-redux";

export const store = configureStore({
	reducer: REDUX_SLICES,
});

const rootReducer = combineReducers(REDUX_SLICES);

export type AppStore = ReturnType<typeof createStore>;
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore["dispatch"];
export type AppGetState = AppStore["getState"];

export const createStore = (config: {
	initialState?: AppState;
	dependencies: Dependencies;
}) => {
	const store = configureStore({
		reducer: rootReducer,
		preloadedState: config?.initialState,
		devTools: true,
		middleware: (getDefaultMiddleware) => {
			const listener = createListenerMiddleware();
			registerCompanyFetcherListeners(listener);
			registerEventsFetcherListeners(listener);
			return getDefaultMiddleware({
				thunk: {
					extraArgument: config.dependencies,
				},
			}).prepend(listener.middleware);
		},
	});

	return store;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof rootReducer>;
