import React from "react";

import { ColumnContent } from "./ColumnContent";
import { Header } from "./Header";
import type { CardType } from "./index";
export type ColumnType = {
	id: number;
	title: string;
	emoji?: string;
	color: 1 | 2 | 3 | 4 | 5;
};

export const COLORS = {
	1: "bg-gray-200",
	1.5: "bg-gray-300/50",
	2: "bg-green-200",
	2.5: "bg-green-300/50",
	3: "bg-blue-200",
	3.5: "bg-blue-300/50",
	4: "bg-purple-200",
	4.5: "bg-purple-300/50",
	5: "bg-red-200",
	5.5: "bg-red-300/50",
};

export const Column = ({
	column,
	cards,
	onCardClick,
}: {
	column: ColumnType;
	cards: CardType[];
	onCardClick: (card: CardType) => void;
}) => {
	return (
		<div
			className={`flex flex-col gap-4 min-w-72 max-w-72 h-full ${
				COLORS[column.color]
			} rounded-md`}
		>
			<Header column={column} numberOfCards={cards.length} />
			<ColumnContent
				cards={cards}
				columnId={column.id}
				columnStrongColor={COLORS[(column.color + 0.5) as keyof typeof COLORS]}
				onCardClick={onCardClick}
			/>
		</div>
	);
};
