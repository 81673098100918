import PropTypes from "prop-types";
import React from "react";

import { cornerDownRightOutline } from "@assets/Icons";
import discord from "@assets/illustrations/discord.jpg";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";

export function Content({ communityLink }) {
	return (
		<div className="content mx-sm my-sm flex flex-col sm:mx-sm md:mx-sm lg:mx-0 lg:w-5/12">
			<img className="discord-img" src={discord} alt="discord" />
			<div className="content-text mb-auto mt-auto pl-sm pr-sm">
				<h3 className="text-primary-700P">
					Rejoins la communauté dès maintenant !
				</h3>
				<p className="mt-sm text-primary-700P">
					Mentor Goal a pour mission de t'aider à décrocher ton alternance,
					alors n'hésite pas à retrouver d'autres étudiants de ton école sur
					notre serveur Discord !
				</p>
				<ButtonPrimary
					icon={cornerDownRightOutline}
					label="Rejoins nous !"
					className="w-fit-content mt-md"
					blank_
					onClick={() => {
						window.open(communityLink, "_blank").focus();
					}}
				/>
			</div>
		</div>
	);
}

Content.propTypes = {
	communityLink: PropTypes.string.isRequired,
};
