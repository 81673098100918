import { Icon, type IconifyIcon } from "@iconify/react";
import React, { type MouseEventHandler } from "react";
import uuid from "react-uuid";

import "@assets/sass/Button.scss";

import TooltipContainer from "./TooltipContainer";

type ButtonTertiaryPropsType = {
	label?: string;
	className: string;
	disabled: boolean;
	icon: IconifyIcon;
	onClick: MouseEventHandler<HTMLButtonElement> | undefined;
	dropdownButton: boolean;
	id?: string;
	img?: string;
	iconClassName?: string;
	iconColor?: string;
	activeImg?: string;
	active: boolean;
	activeIcon: IconifyIcon;
	tooltip?: string;
	size?: "small" | "large";
};

export function ButtonTertiary({
	className,
	icon,
	iconClassName,
	iconColor,
	onClick,
	dropdownButton,
	disabled,
	img,
	activeImg,
	active,
	activeIcon,
	tooltip = "",
	size = "large",
}: ButtonTertiaryPropsType) {
	const id = uuid();
	return (
		<button
			data-id="button-tertiary"
			type="button"
			id={`button-tertiary-tooltip-${id}`}
			data-toggle={dropdownButton && "dropdown"}
			aria-haspopup={dropdownButton && true}
			aria-expanded={dropdownButton && false}
			onClick={onClick}
			disabled={disabled}
			className={`rounded-md flex justify-center items-center bg-white border-white shadow-lg transition-all${
				disabled ? " !bg-primary-50 " : " hover:bg-primary-100 "
			}${size === "large" ? "p-xsm" : "p-xxsm"} ${className}
      ${active ? "!bg-primary-50 border !border-primary-700P" : ""}
      `}
		>
			{tooltip !== "" && (
				<TooltipContainer
					anchorId={`#button-tertiary-tooltip-${id}`}
					makeClickable={false}
					children={tooltip}
					place="top"
				/>
			)}
			{(icon || activeIcon) && (
				<Icon
					className={`${iconClassName} ${iconColor}`}
					icon={active ? activeIcon : icon}
					width={size === "large" ? 24 : 16}
					height={size === "large" ? 24 : 16}
				/>
			)}
			{(img || activeImg) && (
				<img
					className={`${iconColor} icon`}
					src={active ? activeImg : img}
					alt={active ? activeImg : img}
				/>
			)}
		</button>
	);
}

ButtonTertiary.defaultProps = {
	className: "",
	onClick: () => {},
	dropdownButton: false,
	active: false,
	disabled: false,
	iconColor: "",
	activeIcon: {},
};
