import type { EntOffersType } from "@/types/EntCompanyTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";

import { Spinner } from "@components/atoms/Spinner";
import { WhiteCard } from "@components/molecules/WhiteCard";
import { OfferPanel } from "@containers/company/Offers/ui/OfferPanel";
import { OfferSmallList } from "@containers/company/Offers/ui/OfferSmallList";

import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import {
	setActiveOffer,
	setEntOffers,
	setEntOffersLoading,
} from "@containers/company/slice/companySlice";
import { extractIdNumberFromId } from "@tools/Users";
import { useDispatch } from "react-redux";
import { queryEntOffers } from "../../../PartnersAPI";

export function OffersTab() {
	const [offersToTreat, setOffersToTreat] = useState<EntOffersType[] | []>([]);
	const [offersAccepted, setOfferAccepted] = useState<EntOffersType[] | []>([]);
	const [offersRefused, setOffersRefused] = useState<EntOffersType[] | []>([]);
	const [offersExpired, setOffersExpired] = useState<EntOffersType[] | []>([]);

	const { user } = useContext(GlobalContext);
	const { entOffers, entOffersLoading, activeOffer } = useAppSelector(
		(state: AppState) => state.company,
	);
	const dispatchEvent = useDispatch();

	useEffect(() => {
		if (!user) return;
		const getOffers = async () => {
			dispatchEvent(setEntOffersLoading(true));
			try {
				const entOffers = await queryEntOffers(
					extractIdNumberFromId(user.school),
				);
				dispatchEvent(setEntOffers(entOffers));
				dispatchEvent(setEntOffersLoading(false));
			} catch {
				ErrorToast("Une erreur est survenue");
			}
		};
		getOffers();
	}, [user]);

	useEffect(() => {
		if (!entOffers.length) return;

		const offersToTreat = [];
		const offersAccepted = [];
		const offersRefused = [];
		const offersExpired = [];
		for (const offer of entOffers) {
			if (offer.status === "pending") {
				offersToTreat.push(offer);
			} else if (offer.status === "active") {
				offersAccepted.push(offer);
			} else if (offer.status === "refused") {
				offersRefused.push(offer);
			} else {
				offersExpired.push(offer);
			}
			setOfferAccepted(offersAccepted);
			setOffersRefused(offersRefused);
			setOffersToTreat(offersToTreat);
			setOffersExpired(offersExpired);
			const offersSorted = [
				...offersToTreat,
				...offersAccepted,
				...offersRefused,
				...offersExpired,
			];
			if (offersSorted.length) {
				dispatchEvent(setActiveOffer(offersSorted[0]));
			}
		}
	}, [entOffers]);

	return entOffers.length || entOffersLoading ? (
		<div className="flex w-full gap-sm mb-xlg pt-sm">
			<div className="w-5/12 flex h-fit-content rounded-md bg-white slg:w-4/12 xl:w-3/12">
				<OfferSmallList
					offersAccepted={offersAccepted}
					offersToTreat={offersToTreat}
					offersRefused={offersRefused}
					offersExpired={offersExpired}
				/>
			</div>

			{entOffersLoading ? (
				<div className="flex w-full h-36 items-center">
					<Spinner className="mx-auto" />
				</div>
			) : (
				activeOffer && !entOffersLoading && <OfferPanel />
			)}
		</div>
	) : (
		<WhiteCard
			content="Aucune offre créée par une entreprise"
			title="Aucune offre"
		/>
	);
}
