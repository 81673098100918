import { InlineIcon } from "@iconify/react";
import React from "react";

import { checkmarkCircle2Fill } from "@assets/Icons";

import { ProgressBar } from "@components/atoms/Progress";
import TooltipContainer from "@components/atoms/TooltipContainer";

import type { GoalType } from "../DefaultGoals";

export function GoalBar({ goal }: { goal: GoalType }) {
	return (
		<div className="flex w-full items-center justify-between gap-4">
			{goal.isCompleted ? (
				<div className="mt-2 flex items-center justify-center">
					<span>{goal.name}</span>

					<TooltipContainer
						anchorId={"#tooltip-checkmark-goal"}
						makeClickable={false}
						children="Tu as réalisé l'objectif"
						place="top"
					/>
					<span id="tooltip-checkmark-goal">
						<InlineIcon
							icon={checkmarkCircle2Fill}
							className="text-primary-200"
						/>
					</span>
				</div>
			) : (
				<ProgressBar
					tippyLabel={goal.tippy}
					label={goal.name}
					progressValue={goal.value}
					fullValue={goal.max}
					className="mt-sm flex-auto"
				/>
			)}
		</div>
	);
}
