import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import api, { checkGraphQLError } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function editDocuments(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
      updateApplication(input: ${stringifyRequestInputs(inputs)}) {
        application {
          id
          cv: documents (type: "cv", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          motivation: documents (type: "motivation", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          documents (type: "other",  order: [{createdAt: "DESC"}]) {
            collection{
              id
              filePath
              size
              type
            }
          }
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateApplication.application;
	});
	return rep;
}

export async function editApplication(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
      updateApplication(input: ${stringifyRequestInputs(inputs)}) {
        application {
          id
          cv: documents (type: "cv", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          motivation: documents (type: "motivation", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateApplication.application;
	});
	return rep;
}

export async function editAction(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
    updateAction(input: ${stringifyRequestInputs(inputs)} ) {
      action {
        id
        title
        done
        note
        datetime
      }
    }
  }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function deleteAction(uri) {
	let rep = {};
	const data = {
		query: `mutation {
      deleteAction(input: {id:"${uri}"}) {
        action {
          id
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function deleteApplication(uri) {
	const rep = {};
	const data = {
		query: `mutation {
        deleteApplication(input: {id:"${uri}"}) {
          application {
            id
          }
        }
      }`,
	};
	await api.post("graphql", data);
	return rep;
}

export async function createAction(inputs) {
	try {
		const data = {
			query: `mutation {
      createAction(input: ${stringifyRequestInputs(inputs)}) {
        action {
          id
          application{
              id
          }
          title
          note
          datetime
          type
          done
        }
      }
    }`,
		};
		const serverResponse = await api.post("graphql", data);
		const rep = checkGraphQLError(serverResponse, "createAction");
		return rep;
	} catch (error) {
		return error;
	}
}

export async function uploadThisDocument(formdata) {
	try {
		const uploadResponse = await api.post("media_objects", formdata);
		if (uploadResponse.status === 201) {
			return uploadResponse.data;
		}
	} catch (_error) {
		ErrorToast("Error uploading document");
	}

	return rep;
}

export async function upload(formdata, onProgress) {
	let rep = {};
	const config = {
		onUploadProgress: (progressEvent) => {
			const progress = (progressEvent.loaded / progressEvent.total) * 100;
			if (onProgress) {
				onProgress(progress);
			}
		},
	};

	await api.post("media_objects", formdata, config).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function deleteFile(inputs) {
	try {
		let rep = {};
		const data = {
			query: `mutation {
      deleteMediaObject(input: ${stringifyRequestInputs(inputs)}) {
        mediaObject {
          id
        }	
      }
    }`,
		};
		await api.post("graphql", data).then(({ data }) => {
			rep = data;
		});
		return rep;
	} catch (error) {
		throw new Error(error);
	}
}

export async function getAnswer(applicationId) {
	let rep = {};
	const data = {
		query: `
    query {
      application(id: "${applicationId}") {
          id
          answerPreparationQuestions
      }
    }
    `,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.application;
	});
	return rep;
}

export async function getCVData(id) {
	let rep = {};
	const data = {
		query: `query {
      application (id: "${id}") {
          cv: documents (type: "cv", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
      }
  }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
export async function getDocumentsData(applicationId) {
	try {
		let rep = {};
		const data = {
			query: `query {
      application (id: "${applicationId}") {
          cv: documents (type: "cv", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          motivation: documents (type: "motivation", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          documents (type: "other",  order: [{createdAt: "DESC"}]) {
            collection{
              id
              filePath
              size
              type
            }
          }
      }
  }`,
		};
		await api.post("graphql", data).then(({ data }) => {
			rep = data.data.application;
		});
		return rep;
	} catch (error) {
		throw new Error(error);
	}
}
export async function getApplicationDetailData(id) {
	let rep = {};
	const data = {
		query: `query {
      actions (application: "${id}") {
        collection {
          id
          done
          title
          note
          datetime
          type
          contacts{
            collection{
             id
            }
          }
        }
      },
      application (id: "${id}") {
          id
          job
          favorite
          state
          isTreated
          treatmentReason
          messages
          step
          sentDate
          createdAt
          relaunchDate
          interviewDate
          contract
          location
          interviewLocation
          type
          spsStudent {
          motivationLetter{
          id
          }
          cv{
          id}
          }
          offerLink
          archived
          accepted
          updateAt
          answerPreparationQuestions
          alternativeAddress
          alternativeWebsite
          alternativeLinkedin
          alternativeName
          alternativePhone
          alternativeEmail
          cv: documents (type: "cv", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          motivation: documents (type: "motivation", order: [{createdAt: "DESC"}], itemsPerPage: 1) {
            collection{
              id
              filePath
              size
              type
            }
          }
          documents (type: "other",  order: [{createdAt: "DESC"}]) {
            collection{
              id
              filePath
              size
              type
            }
          }
          organization: organizationV2
          contacts {
              collection {
                  id
                  firstname
                  lastname
                  job
                  phone
                  email
                  linkedin
                  avatar{
                    id
                  }
              }
          }
      }
  }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}
export async function getInterview(applicationId) {
	let rep = {};
	const data = {
		query: `
    query {
      application(id: "${applicationId}") {
          id
          explanationEvaluation
          interviewEvaluation
          answerPreparationQuestions
          interviewDate
          alternativeAddress
          interviewLocation
          contacts{
            collection{
              id
            }
          }
      }
    }
    `,
	};
	await api.post("graphql", data).then((response) => {
		rep = response.data.data.application;
	});
	return rep;
}

export async function editContact(inputs) {
	let rep = {};
	const data = {
		query: `mutation {
    updateContact(input: ${stringifyRequestInputs(inputs)} ) {
      contact {
        id
        firstname
        lastname
        job
        phone
        email
        linkedin
      }
    }
  }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function deleteContact(uri) {
	let rep = {};
	const data = {
		query: `mutation {
        deleteContact(input: {id:"${uri}"}) {
          contact {
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function createContact(inputs) {
	let rep = {};

	const data = {
		query: `mutation {
        createContact(input: ${stringifyRequestInputs(inputs)}) {
          contact {
            id
            firstname
            lastname
            applications {
              collection {
                id
              }
            }
            job
            phone
            email
            linkedin
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getNotes(applicationId) {
	let rep = {};

	const data = {
		query: `
    query {
      application(id: "${applicationId}") {
        notes
      }
    }
    `,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.application;
	});

	return rep;
}
