import { useBreakpoints } from "@hooks/useBreakpoints";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "../../ApplicationDetails.scss";

import { moreHorizontalOutline } from "@assets/Icons";

import { formatDate } from "@tools/Dates";
import { capitalizeFirstLetter } from "@tools/Strings";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { Divider } from "@components/atoms/Divider";
import { TextArea } from "@components/atoms/TextArea";
import { Dropdown } from "@components/molecules/Dropdown";
import { AccordionPrimarySmall } from "@components/organisms/Accordion";
import { Success } from "@components/organisms/toast/ToastNotification";

import {
	editApplication,
	getNotes,
} from "@containers/student/Applications/ApplicationDetail/ApplicationDetailAPI";
import { ManageNoteModal } from "@containers/student/Applications/ApplicationDetail/ui/Modals/Notes/ManageNoteModal";

export function Notes({ applicationId }) {
	const { isMobile } = useBreakpoints();

	const [showManageNoteModal, setShowManageNoteModal] = useState(false);

	const [notes, setNotes] = useState([]);
	const [selectedNote, setSelectedNote] = useState({});
	useEffect(() => {
		if (applicationId) {
			getNotes(applicationId).then(({ notes }) => {
				setNotes(notes ?? []);
			});
		}
	}, [applicationId, setNotes]);

	const newNote = (values) => {
		const notesCopy = [...notes];

		notesCopy.unshift({
			id: notesCopy.length + 1,
			date: new Date().toISOString(),
			content: values.note,
		});
		editApplication({
			id: applicationId,
			notes: notesCopy,
		}).then(() => {
			Success("La note a bien été créée");
		});

		setNotes([...notesCopy]);
	};

	const editNote = (id, content) => {
		const notesCopy = [...notes];

		const targetNote = notesCopy.find((x) => x.id === Number(id));

		const indexTarget = notesCopy.indexOf(targetNote);
		if (indexTarget > -1) {
			notesCopy.splice(indexTarget, 1);
		}

		targetNote.content = content;

		notesCopy.unshift(targetNote);

		editApplication({
			id: applicationId,
			notes: notesCopy,
		}).then(() => {
			Success("La note a bien été modifiée");
		});
		setNotes([...notesCopy]);
	};

	const deleteNote = (id) => {
		const notesCopy = [...notes];

		const targetNote = notesCopy.find((x) => x.id === Number(id));

		const indexTarget = notesCopy.indexOf(targetNote);
		if (indexTarget > -1) {
			notesCopy.splice(indexTarget, 1);
		}

		editApplication({
			id: applicationId,
			notes: notesCopy,
		});
		setNotes([...notesCopy]);
	};

	return (
		<div className="notes-container">
			<Formik
				initialValues={{
					note: "",
				}}
				enableReinitialize
				validate={(values) => {
					const errors = {};
					if (!values.note) {
						errors.note = "Veuillez remplir le champs afin d'ajouter une note";
					}
					return errors;
				}}
				onSubmit={(values, { resetForm }) => {
					resetForm({});
					newNote(values);
				}}
			>
				{({ values, handleSubmit, resetForm, setFieldValue, errors }) => (
					<div>
						<TextArea
							value={values.note}
							onChange={(note) => {
								setFieldValue("note", note);
							}}
							errorMessage={errors.note}
							placeholder="Ecris ta note ici..."
							height="30vh"
						/>
						<div className="button-form w-fit-content ml-auto mt-sm flex">
							<ButtonQuaternary
								onClick={resetForm}
								label="Réinitialiser"
								className="w-fit-content reset-responsive mr-sm"
							/>
							<ButtonPrimary
								onClick={handleSubmit}
								label="Ajouter"
								className="w-fit-content add-responsive"
							/>
						</div>
					</div>
				)}
			</Formik>
			<p className="text-sm font-bold">Toutes les notes</p>

			<div className="action-flow mt-sm">
				<div className="pl-sm pr-sm">
					<Divider className="" />
				</div>

				{notes?.map((note) => {
					const date = capitalizeFirstLetter(formatDate(note?.date || ""));

					return (
						<span key={note.id}>
							<AccordionPrimarySmall
								translate={false}
								label={date}
								title={isMobile ? "Note ajoutée" : note.content}
								subtitle={note.content}
								content={note.content}
								button={
									<Dropdown
										direction="left"
										items={[
											{
												label: "Modifier",
												action: () => {
													setSelectedNote(note);
													setShowManageNoteModal(true);
												},
												closeIt: true,
											},
											{
												label: "Supprimer",
												action: () => {
													deleteNote(note.id);
												},
											},
										]}
										button={
											<ButtonQuaternary
												className="small"
												dropdownButton
												icon={moreHorizontalOutline}
											/>
										}
									/>
								}
							/>
						</span>
					);
				})}

				<ManageNoteModal
					onDelete={deleteNote}
					onApply={editNote}
					note={selectedNote}
					onClose={() => {
						setShowManageNoteModal(false);
					}}
					show={showManageNoteModal}
				/>
			</div>
		</div>
	);
}

Notes.propTypes = {
	application: PropTypes.shape({
		notes: PropTypes.arrayOf(PropTypes.shape({})),
		id: PropTypes.string,
	}).isRequired,
};
