import React from "react";
import { useNavigate } from "react-router";

import InternalServerErrorIllustration from "@assets/illustrations/500.svg";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";

export function ErrorInternalServerError() {
	const navigate = useNavigate();
	return (
		<main className="errors">
			<div className="main-content flex flex-col items-center justify-center">
				<img alt="Maison qui brûle" src={InternalServerErrorIllustration} />
				<h2 className="mt-md text-lg font-bold text-primary-900 md:text-3xl">
					Erreur interne du serveur
				</h2>
				<p className="mt-md text-center text-xxsm text-primary-300 md:text-xsm">
					Un problème est survenue, réessaie ultérieurement.
				</p>
				<ButtonPrimary
					className="mt-8 md:mt-6"
					onClick={() => navigate("/")}
					label={`Retourner à la page d'accueil`}
				/>
			</div>
		</main>
	);
}
