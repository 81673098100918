import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import React, { useState } from "react";

import "@assets/sass/Collapse.scss";

import { arrowDownOutline } from "@assets/Icons";

export function Collapse({ body, title, index, className }) {
	const [collapseId, setCollapseId] = useState(2);
	return (
		<div className={className}>
			<button
				type="button"
				id={index}
				className="cursor-pointe flex w-full items-center justify-between rounded-md border-0 bg-primary-50 p-sm"
				onClick={() => {
					return collapseId === index ? setCollapseId(0) : setCollapseId(index);
				}}
			>
				<p className="text-xsm font-bold text-primary-700P">{title}</p>
				<Icon
					className={
						collapseId === index
							? "rotate-mirror h-6 text-primary-700P"
							: "h-6 text-primary-700P "
					}
					icon={arrowDownOutline}
				/>
			</button>
			{collapseId === index && body}
		</div>
	);
}

Collapse.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	body: PropTypes.node.isRequired,
	index: PropTypes.number,
};

Collapse.defaultProps = {
	title: "",
	className: "",
	index: 0,
};
