import type { SchoolOfferType } from "@/types/offerTypes";
import { fetchGetCompanyOffers } from "../api/companiesManagement.request";
import type { ICompanyOffersGateway } from "../gateway/companyOffers.gateway";
export class InAPICompanyOffersGateway implements ICompanyOffersGateway {
	async getCompanyOffers(id: string): Promise<SchoolOfferType[]> {
		const response = await fetchGetCompanyOffers(id);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors du chargement des informations de l'entreprise",
			);
		}
		if (response.data.crmCompany.spsOffers.collection) {
			return response.data.crmCompany.spsOffers.collection;
		}
		throw new Error("Erreur, format de données incorrect");
	}
}
