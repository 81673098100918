import { Icon } from "@iconify/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import {
	alertTriangleOutline,
	bellOutline,
	calendarOutline,
} from "@assets/Icons";

import { diffDate } from "@tools/Dates";

import { ListPrimary } from "@components/molecules/ListPrimary";

export function Actions({ actions }) {
	const [displayActions, setDisplayActions] = useState([]);

	useEffect(() => {
		if (actions) {
			setDisplayActions(actions);
		}
	}, [actions]);

	return (
		<div className="action-container ">
			{actions.length > 0 ? (
				<div className="hidden md:flex">
					<h4 className="text-sm font-bold text-primary-700P">
						Flux des actions
					</h4>
					<div className="mt-lg flex w-full items-center p-0">
						<div className="flex w-full flex-col p-0">
							{displayActions.length > 0
								? displayActions.map((action) => {
										return (
											<div className="flex justify-between">
												<p className="text-xsm text-primary-300">
													{moment(action.datetime).format("DD/MM/YYYY")}
												</p>
												<p className="w-6/12 text-xsm text-primary-700P">
													{action.title}
												</p>
												{diffDate(action.datetime) < 0 ? (
													<p>
														<Icon
															className="text-warning-dark"
															icon={calendarOutline}
														/>
														À venir
													</p>
												) : (
													""
												)}
												{diffDate(action.datetime) > 0 ? (
													<p className="text-xsm text-primary-200">
														<Icon
															className="mr-xsm text-error-dark"
															icon={alertTriangleOutline}
														/>
														En retard
													</p>
												) : (
													""
												)}
												{diffDate(action.datetime) === 0 ? (
													<p className="text-xsm text-primary-200">
														<Icon
															className="mr-xsm text-success-dark"
															icon={bellOutline}
														/>
														Aujourd&apos;hui
													</p>
												) : (
													""
												)}
											</div>
										);
									})
								: ""}
						</div>
					</div>
					<div className="action-container-responsive hidden">
						{displayActions.length > 0
							? displayActions.map(() => {
									return (
										<ListPrimary
											onCheck
											label="24/06"
											title="En retard"
											icon={alertTriangleOutline}
											iconColor="error-dark"
											subtitle="Rédiger la lettre de motivation"
										/>
									);
								})
							: ""}
					</div>
				</div>
			) : (
				<p className="text-primary-300">Aucune action prévue</p>
			)}
		</div>
	);
}

Actions.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
