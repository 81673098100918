import { Divider } from "@components/atoms/Divider";
import React from "react";
import type { Referent, School } from "../SelectionAPI";
import { ReferentCard } from "./ReferentCard";
import { SchoolCard } from "./SchoolCard";

type ContactCardProps = {
	school: School;
	referent: Referent;
};

export function ContactCard({ referent, school }: ContactCardProps) {
	return (
		<div className="relative flex flex-1 w-full flex-col items-center justify-center gap-md lg:gap-xxlg rounded-lg bg-white p-6 px-8 text-primary-700P lg:flex-row shadow-sm">
			<div className="absolute left-6 top-[-1rem] rounded-full bg-alert-info-turquoise-100 border border-alert-info-turquoise-500 text-alert-info-turquoise-500 px-sm py-xxsm">
				Votre contact
			</div>
			<ReferentCard referent={referent} />
			<div className="flex flex-col items-center h-full justify-center">
				<Divider className="vertical-separator !w-32 !h-[1px] lg:!h-2/3 lg:!w-[1px]" />
			</div>
			<SchoolCard school={school} />
		</div>
	);
}
