import { useAppSelector } from "@config/redux/hook";
import React from "react";

import LetterRequiredModifier from "@containers/school/Offers_v3/ui/actions/LetterRequiredModifier";
import ApplicationDetailModifier from "../actions/ApplicationDetailModifier";
import DescriptionModifier from "../actions/DescriptionModifier";
import ExpirationDateModifier from "../actions/ExpirationDateModifier";
import OfferFileUploader from "../actions/OfferFileUploader";
import TagsModifier from "../actions/TagsModifier";

function OfferCreationDetails() {
	const { offerToCreate } = useAppSelector((state) => state.offers);
	return (
		<article
			data-testid="offerCreationDetails"
			className="flex w-full flex-col gap-4"
		>
			<ExpirationDateModifier />
			{offerToCreate?.type === "direct" && <ApplicationDetailModifier />}
			<DescriptionModifier />
			<TagsModifier />
			<OfferFileUploader />
			<LetterRequiredModifier />
		</article>
	);
}

export default OfferCreationDetails;
