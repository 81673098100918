import React, { useContext, useEffect, useState } from "react";

import type { sendFileResponse } from "../../../../public/InscriptionForm/RegisterFormAPI";
import { CurrentProspectContext, FieldsContext } from "../../index";
import type { FieldType, ProspectType } from "../Kanban";

import { PdfViewer } from "@components/atoms/PdfViewer";
import { DragDropFile } from "./DragDropFile";

export const Files = () => {
	const fields = useContext(FieldsContext);
	const currentProspectContext = useContext(CurrentProspectContext);
	const currentProspect =
		currentProspectContext.currentProspect as ProspectType;
	const setCurrentProspect = currentProspectContext.setCurrentProspect;
	const [selectedFile, setSelectedFile] = useState<FieldType>();
	const [filesField] = useState(
		fields.filter((field) => field.type === "file"),
	);
	const [currentProspectFiles, setCurrentProspectFiles] = useState<FieldType[]>(
		[],
	);
	useEffect(() => {
		const numberOfFiles: FieldType[] = [];
		for (let i = 0; i < filesField.length; i++) {
			if (
				currentProspect?.data[
					filesField[i].name as keyof typeof currentProspect.data
				]
			) {
				numberOfFiles.push(
					currentProspect?.data[
						filesField[i].name as keyof typeof currentProspect.data
					],
				);
			}
		}
		setSelectedFile(
			numberOfFiles.length > 0 ? numberOfFiles[0] : filesField[0],
		);
		setCurrentProspectFiles(numberOfFiles);
	}, [filesField, currentProspect]);
	const onFileUploadSuccess = (res: sendFileResponse) => {
		const newProspect = { ...currentProspect };
		newProspect.data[selectedFile?.name as keyof typeof newProspect.data] = {
			...newProspect.data[selectedFile?.name as keyof typeof newProspect.data],
			value: res.filePath,
		};
		setCurrentProspect(newProspect);
	};
	return (
		<div className="flex flex-1 flex-col gap-4 ">
			<div className="flex flex-row gap-4 justify-between align-middle">
				<span className="text-xl font-bold flex align-middle">
					Documents ({currentProspectFiles.length})
				</span>
				<div>
					<select
						className={
							"border border-gray-200 p-2 rounded-sm focus:outline-none focus:border-indigo-600 hover:border-indigo-600 focus:ring-1 focus:ring-indigo-600 ease-in-out duration-100 min-w-60"
						}
						value={selectedFile?.name}
						onChange={(e) =>
							setSelectedFile(
								currentProspectFiles.find(
									(file) => file.name === e.target.value,
								),
							)
						}
					>
						{filesField.map((file, index) => (
							<option key={index} value={file.name}>
								{file.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="flex flex-1 flex-col gap-4 pb-8">
				{selectedFile?.value ? (
					<PdfViewer
						src={`${import.meta.env.VITE_S3}${selectedFile?.value}`}
						title={selectedFile.label}
					/>
				) : (
					<DragDropFile
						onSuccess={onFileUploadSuccess}
						fieldName={selectedFile?.name as string}
						prospectId={currentProspect?.id || 0}
					/>
				)}
			</div>
		</div>
	);
};
