import React from "react";

import { FilesPrimary } from "@components/molecules/Files";

export function Documents({ cv, motivationLetter }) {
	return (
		<div>
			<p className="mb-lg text-sm font-bold text-primary-700P">Documents</p>
			{cv && (
				<FilesPrimary
					isLoading={false}
					className="mt-lg"
					title={cv.filePath}
					subtitle="07/08/2021 ‧ DOC ‧ 500Ko"
					onView={() => {
						window.open(`${import.meta.env.VITE_S3}${cv.filePath}`);
					}}
				/>
			)}
			{motivationLetter && (
				<FilesPrimary
					isLoading={false}
					className="mt-sm"
					title={motivationLetter.filePath}
					subtitle="07/08/2021 ‧ DOC ‧ 300Ko"
					onView={() => {
						window.open(
							`${import.meta.env.VITE_S3}${motivationLetter.filePath}`,
						);
					}}
				/>
			)}
			<hr className="mb-md mt-md" />
		</div>
	);
}
