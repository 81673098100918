import type {
	ApplicationStepType,
	ApplicationThumbType,
} from "@/types/applicationTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React, { type Dispatch } from "react";

import { chevronRightOutline, pinOutline } from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";

import { setSelectedApplicationId } from "@containers/school/Offers_v3/core/store/offersManagement.slice";

import type { AnyAction } from "@reduxjs/toolkit";
import { ApplicationStep } from "./ApplicationStep";
import { useOfferDetailsApplications } from "./useOfferDetailsApplications";

export function ApplicationThumb({
	dispatchEvent,
	thumbnailData,
	isSelected = false,
}: {
	dispatchEvent: Dispatch<AnyAction>;
	thumbnailData: ApplicationThumbType;
	isSelected?: boolean;
}) {
	const { offerToEdit } = useAppSelector((state) => state.offers);
	const handleSelectThisApplication = () => {
		dispatchEvent(setSelectedApplicationId(thumbnailData?.id));
	};
	const presenter = useOfferDetailsApplications();
	return (
		<div
			className={`flex w-full justify-between rounded-md border ${
				isSelected ? "border-primary-500" : "border-transparent"
			} px-sm py-xsm shadow-md transition-all hover:cursor-pointer hover:border-primary-500`}
			onClick={() => handleSelectThisApplication()}
		>
			<div className="flex flex-col justify-between gap-xxsm">
				<div
					className={`min-h-5 flex w-full gap-xsm items-center ${
						offerToEdit?.type === "preselection" ? "flex-row" : "flex-col"
					}`}
				>
					<p className="text-xs font-normal text-primary-300">
						{presenter.getDateToShow(thumbnailData)}
					</p>
					{(offerToEdit?.type === "preselection" &&
						!thumbnailData?.isTreated) ||
						(thumbnailData?.state === "update" && (
							<Badge
								label="A traiter"
								size="small"
								bgColor="bg-error-light"
								hasBorder
							/>
						))}
					{offerToEdit?.type !== "preselection" && (
						<ApplicationStep
							useIndicator={true}
							step={(thumbnailData?.step as ApplicationStepType) || ""}
						/>
					)}
				</div>
				<div className="flex gap-xsm">
					<Avatar
						size="xs"
						firstname={thumbnailData?.spsStudent?.firstname || ""}
						lastname={thumbnailData?.spsStudent?.lastname || ""}
						image={thumbnailData?.spsStudent?.avatar?.filePath || ""}
					/>
					<div>
						<p className="text-base font-bold text-primary-700P">
							{thumbnailData?.spsStudent?.firstname}{" "}
							{thumbnailData?.spsStudent?.lastname}
						</p>
						<p className="text-xxsm font-normal text-primary-700P">
							{thumbnailData?.spsStudent?.promotion?.name || "-"}
						</p>
					</div>
				</div>
				<div className="flex items-center justify-start gap-xxsm text-xxsm font-normal text-primary-300">
					<Icon icon={pinOutline} /> {thumbnailData?.spsStudent?.city || "-"}
				</div>
			</div>
			<div className="flex items-center justify-center">
				<Icon
					icon={chevronRightOutline}
					className={`${
						isSelected ? "block" : "hidden"
					} h-6 w-6 text-primary-700P`}
				/>
			</div>
		</div>
	);
}
