import type { TagType } from "@/types/tagTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { TagsFinder } from "../../TagsFinder/TagsFinder";
import { getTags } from "../UsersFiltersAPI";
import { countCurrentFilters, setCurrentFilters } from "../usersFiltersSlice";

export function TagsFilter() {
	const dispatchEvent = useDispatch();
	const { user } = useContext(GlobalContext);
	const currentFilters = useAppSelector(
		(state) => state.usersFilters.currentFilters,
	);
	const [tagsOptions, setTagsOptions] = useState<TagType[]>([]);
	const handleOnTagsChange = (tagsList: number[] = [], type = "and") => {
		const filtersCpy = { ...currentFilters };

		if (tagsList.length === 0) {
			filtersCpy.tags = undefined;
			dispatchEvent(setCurrentFilters(filtersCpy));
		} else {
			dispatchEvent(
				setCurrentFilters({
					...currentFilters,
					tags: { list: tagsList, operator: type },
					page: 1,
				}),
			);
		}
		dispatchEvent(countCurrentFilters());
	};

	const retrieveTags = () => {
		if (user) {
			getTags(user.school).then(({ collection }: { collection: TagType[] }) => {
				setTagsOptions(collection);
			});
		}
	};
	const getDefaultTags = () => {
		let tagsList: TagType[] = [];

		if (currentFilters.tags) {
			tagsList = tagsOptions.filter((tagOption) =>
				currentFilters.tags?.list.includes(
					Number.parseInt(tagOption.id.split("/")[3], 10),
				),
			);
		}

		return tagsList;
	};

	useEffect(() => {
		retrieveTags();
	}, [user]);

	return (
		<TagsFinder
			options={tagsOptions?.sort((a, b) => a.name.localeCompare(b.name))}
			defaultOptions={getDefaultTags()}
			className="mt-sm"
			placeholder="Rechercher un tag... "
			useAsFilter={true}
			onChange={(tagFilter: any) => {
				handleOnTagsChange(tagFilter.list, tagFilter.operator);
			}}
		/>
	);
}
