import { Icon } from "@iconify/react";
import React from "react";

import type { ProspectActivity } from "../../AtsAPI";
export const ActivityRow = ({ activity }: { activity: ProspectActivity }) => {
	const getContent = () => {
		switch (activity.action) {
			case 0:
				return (
					<span className="flex-1 text-xsm font-semibold">
						<b>{activity.toProspect}</b> a postulé sur le <b>site web</b>
					</span>
				);
			case 1:
				return (
					<span className="flex-1 text-xsm font-semibold">
						<b>{activity.byUser}</b> a marqué <b>{activity.toProspect}</b> comme
						non ouvert
					</span>
				);
			case 2:
				return (
					<span className="flex-1 text-xsm font-semibold">
						<b>{activity.byUser}</b> a marqué <b>{activity.toProspect}</b> comme
						ouvert
					</span>
				);
			case 3:
				return (
					<span className="flex-1 text-xsm font-semibold">
						<b>{activity.byUser}</b> a archivé <b>{activity.toProspect}</b>
					</span>
				);
			case 4:
				return (
					<span className="flex-1 text-xsm font-semibold">
						<b>{activity.byUser}</b> a désarchivé
						<b>{activity.toProspect}</b>
					</span>
				);
			case 5:
				return (
					<span className="flex-1 text-xsm font-semibold">
						<b>{activity.byUser}</b> a déplacé <b>{activity.toProspect}</b> dans
						la colonne <b>{activity.comment}</b>
					</span>
				);
			default:
				return "";
		}
	};
	const getIcon = () => {
		switch (activity.action) {
			case 0:
				return "eva:person-add-outline";
			case 1:
				return "eva:loader-outline";
			case 2:
				return "eva:checkmark-circle-outline";
			case 3:
				return "eva:archive-outline";
			case 4:
				return "eva:archive-outline";
			case 5:
				return "eva:swap-fill";
			default:
				return "";
		}
	};
	return (
		<div className="flex flex-row border rounded-md p-4 gap-4">
			<div className="bg-green-200 h-10 w-10 flex items-center self-center justify-center rounded-md">
				<Icon icon={getIcon()} className="text-3xl text-green-500" />
			</div>
			{getContent()}
			<span className="text-xsm text-gray-500 flex items-center">
				{new Date(activity.createdAt).toLocaleDateString("fr-FR", {
					year: "numeric",
					month: "numeric",
					day: "numeric",
					hour: "numeric",
					minute: "numeric",
				})}
			</span>
		</div>
	);
};
