import { useAppSelector } from "@config/redux/hook";
import React from "react";

import { UsersFilters } from "@components/organisms/usersFilters";
import { ResetUserFiltersButton } from "@components/organisms/usersFilters/widgets/ResetUserFiltersButton";
import { ToggleUserFilters } from "@components/organisms/usersFilters/widgets/ToggleUserFilters";
import UserfilterSearchbar from "@components/organisms/usersFilters/widgets/UserfilterSearchbar";

export function Filters() {
	const { currentFilters, showFilters } = useAppSelector(
		(state) => state.usersFilters,
	);

	return (
		<div className="flex flex-col gap-md">
			<div className="flex gap-sm">
				<UserfilterSearchbar />
				<ToggleUserFilters />
				<ResetUserFiltersButton />
			</div>
			{showFilters && (
				<UsersFilters
					filtersProps={{ filters: currentFilters, setFilters: () => {} }}
				/>
			)}
		</div>
	);
}
