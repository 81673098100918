import type { EntOffersType } from "@/types/EntCompanyTypes";
import { Icon } from "@iconify/react";
import React from "react";

import { archiveOutline } from "@assets/Icons";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import TooltipContainer from "@components/atoms/TooltipContainer";
import { openSelection } from "@containers/company/slice/companySlice";
import { useDispatch } from "react-redux";

export function CompanyHeader({
	activeOffer,
	seeModal,
	setSeeModal,
}: {
	activeOffer: EntOffersType;
	seeModal: boolean | string;
	setSeeModal: (e: boolean | string) => void;
}) {
	const dispatchEvent = useDispatch();

	return (
		<div className="flex items-center">
			{!["pending", "refused"].includes(activeOffer.status as string) ? (
				<ButtonPrimary
					label="Voir les candidats"
					disabled={!activeOffer.spsSelection}
					onClick={() => {
						dispatchEvent(openSelection(activeOffer));
					}}
				/>
			) : null}
			{activeOffer.status !== "expired" && (
				<>
					<TooltipContainer
						anchorId="#offer-close-btn"
						makeClickable={false}
						place="top"
					>
						Clôturer l'offre
					</TooltipContainer>
					<span
						id="offer-close-btn"
						className="ml-xsm h-full w-[24px] cursor-pointer"
						onClick={() => {
							seeModal === activeOffer.id
								? setSeeModal!(false)
								: setSeeModal!(activeOffer.id);
						}}
					>
						<Icon icon={archiveOutline} className="h-full w-full" />
					</span>
				</>
			)}
		</div>
	);
}
