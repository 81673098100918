import { Icon, type IconifyIcon } from "@iconify/react";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { type Key, type MouseEventHandler, type ReactNode } from "react";
import { useEffect, useState } from "react";

import "@assets/sass/Accordion.scss";
import "@assets/sass/AccordionItem.scss";

import { arrowDownOutline, arrowUpOutline } from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import { Divider } from "@components/atoms/Divider";

let uniqueId = 0;

type AccordionItemType = {
	icon: string | IconifyIcon;
	label: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
};

export function AccordionItem({ icon, label, onClick }: AccordionItemType) {
	return (
		<div
			data-id="accordion-item"
			role="menu"
			className="accordion-item mb-xsm flex items-center"
			onClick={onClick}
		>
			<Icon icon={icon} className="text-primary-500" />
			<p data-id="label" className="label ml-sm text-primary-700P">
				{label}
			</p>
		</div>
	);
}

AccordionItem.defaultProps = {
	onClick: () => {},
};

type AccordionBaseProps = {
	title: string;
	subtitle: string;
	items:
		| Array<{
				icon: string;
				label: string;
				onClick: () => void;
		  }>
		| any; // TO DO :  Sorry guys, emergency first <3
	avatarImage?: string;
	toggleButton?: ReactNode;
	className?: string;
	deployed?: boolean;
	link?: ReactNode;
	parentID?: string;
	noAvatar?: boolean;
	translate?: boolean;
};

export function Accordion({
	translate = true,
	title,
	subtitle,
	avatarImage = "",
	items,
	className,
	deployed = false,
	link = null,
	toggleButton = null,
	parentID = "accordion",
	noAvatar = false,
}: AccordionBaseProps) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		$(`#accordion-${componentId}`).on("hide.bs.collapse", () => {
			setIsOpen(false);
		});
		$(`#accordion-${componentId}`).on("show.bs.collapse", () => {
			setIsOpen(true);
		});
	}, [componentId]);

	useEffect(() => {
		setIsOpen(deployed);
	}, [deployed]);

	return (
		<button
			type="button"
			data-id="accordion"
			className={`card accordion-container w-full ${className}`}
		>
			<div
				className="card-header flex w-full cursor-pointer items-center"
				data-toggle="collapse"
				data-target={`#accordion-${componentId}`}
				aria-controls={`accordion-${componentId}`}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				id="headingOne"
			>
				{toggleButton && <div>{toggleButton}</div>}
				{!noAvatar && (
					<Avatar
						className="min-h-[44px] min-w-[44px]"
						image={avatarImage}
						firstname={title.split(" ")[0]}
						lastname={title.split(" ")[1]}
					/>
				)}

				<div className="ml-sm flex w-full items-center justify-between">
					<div style={{ position: "relative" }}>
						<p
							className={`title font-bold text-primary-700P${
								!translate ? " wg-no-translate" : ""
							}`}
						>
							{title}
						</p>
						<p
							className={`subtitle-p flex justify-start text-primary-700P${
								!translate ? " wg-no-translate" : ""
							}`}
						>
							{subtitle}
						</p>
					</div>
					<Icon
						icon={isOpen ? arrowUpOutline : arrowDownOutline}
						className="h-6 text-primary-700P"
					/>
				</div>
			</div>

			<div
				id={`accordion-${componentId}`}
				className={`collapse-${isOpen ? "show" : "hide"}`}
				aria-labelledby={`collapse-${componentId}`}
				data-parent={`#${parentID}`}
			>
				<div className="card-body">
					{items.map(
						(
							elem: {
								icon: string | IconifyIcon;
								label: string;
								onClick: MouseEventHandler<HTMLDivElement> | undefined;
							},
							i: Key | null | undefined,
						) => {
							return (
								<div key={i}>
									<AccordionItem
										icon={elem.icon}
										label={elem.label}
										onClick={elem.onClick}
									/>
								</div>
							);
						},
					)}
					{link}
				</div>
			</div>
		</button>
	);
}

type AccordionPrimaryProps = AccordionBaseProps & {
	label: string;
	button?: ReactNode;
	className?: string;
	content: string | ReactNode;
	translate?: boolean;
};

export function AccordionPrimarySmall({
	label,
	title,
	subtitle,
	className = "",
	parentID = "accordion",
	button = null,
	content,
	translate = true,
}: AccordionPrimaryProps) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={className}>
			<div className="card accordion-primary-small-container">
				<div
					className="card-header cursor-pointer"
					data-toggle="collapse"
					data-target={`#accordion-primary-small-${componentId}`}
					onClick={() => {
						setIsOpen(!isOpen);
					}}
				>
					<div className="flex cursor-pointer items-center" id="headingOne">
						<p className="label w-2/12">{label}</p>
						<div
							className={`${
								label && "ml-sm"
							} flex w-8/12 items-center justify-between`}
						>
							<p
								className={`title truncate font-bold text-primary-700P${
									!translate ? " wg-no-translate" : ""
								}`}
							>
								{title}
							</p>
						</div>
						<div className=" flex w-2/12 justify-end">
							{button && <div className="mr-xsm">{button}</div>}
							<Icon
								icon={isOpen ? arrowUpOutline : arrowDownOutline}
								className="h-6 text-primary-700P"
							/>
						</div>
					</div>
					{!isOpen && (
						<p className={`subtitle-p${!translate ? " wg-no-translate" : ""}`}>
							{subtitle}
						</p>
					)}
					<Divider className="mt-xsm" />
				</div>
				{isOpen && (
					<div
						id={`accordion-primary-small-${componentId}`}
						className={` ${isOpen ? "show" : "collapse"}`}
						aria-labelledby={`collapse-${componentId}`}
						data-parent={`#${parentID}`}
					>
						<div className="card-body">
							<pre className={`content${!translate ? " wg-no-translate" : ""}`}>
								<p>{content}</p>
							</pre>
							<div className="pl-xsm pr-xsm">
								<Divider className="" />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

AccordionPrimarySmall.propTypes = {
	label: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	className: PropTypes.string,
	parentID: PropTypes.string,
	button: PropTypes.node,
	content: PropTypes.string.isRequired || PropTypes.elementType.isRequired,
};

AccordionPrimarySmall.defaultProps = {
	className: "",
	parentID: "accordion",
	button: null,
};

type AccordionCustomProps = AccordionBaseProps & {
	body?: ReactNode;
	className?: string;
	headColor?: string;
	headClassName?: string;
	defaultState?: boolean;
};
export function AccordionCustom({
	title,
	body,
	className,
	headColor,
	parentID = "accordion",
	headClassName,
	defaultState = false,
}: AccordionCustomProps) {
	const [componentId] = useState(() => uniqueId++);
	const [isOpen, setIsOpen] = useState(defaultState);

	useEffect(() => {
		$(`#accordion-custom-${componentId}`).on("hide.bs.collapse", () => {
			setIsOpen(false);
		});
		$(`#accordion-custom-${componentId}`).on("show.bs.collapse", () => {
			setIsOpen(true);
		});
	}, [componentId]);

	return (
		<div className={`card accordion-custom-container w-full ${className}`}>
			<button
				className={`card-header flex w-full cursor-pointer items-center p-lg bg-${headColor}`}
				id="headingOne"
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				type="button"
			>
				<div
					className={`flex w-full items-center justify-between ${headClassName}`}
				>
					<p className="title text-sm font-bold text-primary-700P">{title}</p>
					<Icon
						icon={isOpen ? arrowUpOutline : arrowDownOutline}
						className="h-6 text-primary-700P"
					/>
				</div>
			</button>

			<div
				id={`accordion-custom-${componentId}`}
				className={`card-body w-full ${isOpen ? "flex" : "hidden"}`}
				aria-labelledby={`collapse-${componentId}`}
				data-parent={parentID}
			>
				{body}
			</div>
		</div>
	);
}

AccordionCustom.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.node.isRequired,
	className: PropTypes.string,
	headColor: PropTypes.string,
	parentId: PropTypes.string,
	headClassName: PropTypes.string,
	defaultState: PropTypes.bool,
};

AccordionCustom.defaultProps = {
	className: "",
	headColor: "",
	parentId: "",
	headClassName: "",
	defaultState: false,
};
