import { Icon } from "@iconify/react";
import React from "react";

import { checkmarkOutline } from "@assets/Icons";

type CheckboxType = {
	className?: string;
	checked?: boolean;
	onEnabled?: Function;
	onDisabled?: Function;
	disabled?: boolean;
};

export function Checkbox({
	className = "",
	checked = false,
	onEnabled = () => {},
	onDisabled = () => {},
	disabled = false,
}: CheckboxType) {
	return (
		<button
			data-testid="checkbox"
			type="button"
			disabled={disabled}
			className={`${className} rounded-full border w-[16px] h-[16px] border-primary-200 ${
				checked && "bg-primary-700P"
			}`}
			onClick={(event) => {
				if (checked) {
					onDisabled(event);
				} else {
					onEnabled(event);
				}
			}}
		>
			{checked && (
				<Icon icon={checkmarkOutline} className="h-full w-full text-white" />
			)}
		</button>
	);
}
