import type { ApplicationType } from "@/types/applicationTypes";
import { useAppSelector } from "@config/redux/hook";
import { useBreakpoints } from "@hooks/useBreakpoints";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { checkmarkCircle2Fill, externalLinkOutline } from "@assets/Icons";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { Spinner } from "@components/atoms/Spinner";

import { setApplication } from "../../../controller/offerDetail.slice";
import { HaveYouPostulateModal } from "../../shared/HaveYouPostulateModal";

import { PostulateModal } from "./PostulateButton/PostulateModal";
export function PostulateButton({ className = "" }: { className?: string }) {
	const dispatchOfferDetail = useDispatch();
	const { isMobile } = useBreakpoints();
	const { showMinifyHead, application, offer, isSendingApplication } =
		useAppSelector((state) => state.offerDetail);

	const displayMobileFormat = showMinifyHead || isMobile;
	const [showPostulateModal, setShowPostulateModal] = useState<boolean>(false);
	const [showHaveYouPostulateModal, setShowHaveYouPostulateModal] =
		useState(false);
	const navigate = useNavigate();

	const openApplication = () => {
		navigate(`/applications/${application.id.split("/")[3]}`);
	};
	if (isSendingApplication) {
		return (
			<div className={className}>
				<Spinner size={"small"} />
			</div>
		);
	}
	return application ? (
		<div
			className={`flex flex-col items-center gap-xxsm text-primary-700P ${className}`}
		>
			<div className={`${displayMobileFormat && "flex-col"} flex gap-sm`}>
				<div className={"flex items-center gap-xxsm"}>
					<Icon
						icon={checkmarkCircle2Fill}
						className="text-accent-4"
						height="16px"
						width="16px"
					/>
					<p className="text-xxsm font-bold text-primary-300">
						Tu as postulé à cette offre
					</p>
				</div>
				{!displayMobileFormat && (
					<ButtonPrimary
						label="Voir la candidature"
						onClick={openApplication}
					/>
				)}
			</div>
			{displayMobileFormat && (
				<InlineButton
					id="see-application"
					label="Voir la candidature dans mon suivi"
					onClick={openApplication}
				/>
			)}
		</div>
	) : (
		<div className={className}>
			{offer.type === "direct" && offer?.wayToApply && (
				<ButtonPrimary
					label="Postuler"
					className="w-full"
					icon={externalLinkOutline}
					onClick={() => {
						if (!offer.wayToApply.includes("@")) {
							window.open(decodeURIComponent(offer.wayToApply), "_blank");
						}
						setShowHaveYouPostulateModal(true);
					}}
				/>
			)}
			{offer.type !== "direct" && (
				<ButtonPrimary
					label="Postuler"
					className="w-full"
					onClick={() => setShowPostulateModal(true)}
					disabled={isSendingApplication}
				/>
			)}
			<PostulateModal
				show={showPostulateModal}
				onClose={() => setShowPostulateModal(false)}
			/>
			<HaveYouPostulateModal
				show={showHaveYouPostulateModal}
				offer={offer}
				onClose={() => {
					setShowHaveYouPostulateModal(false);
				}}
				setApplication={(app: ApplicationType) => {
					dispatchOfferDetail(setApplication(app));
				}}
				postulateUsingMail={
					offer.type === "direct" &&
					offer?.wayToApply &&
					offer.wayToApply.includes("@")
				}
				mailForApplication={offer?.wayToApply}
			/>
		</div>
	);
}
