import type { ApplicationType } from "@/types/applicationTypes";
import type { OrganizationType } from "@/types/organizationTypes";
import React, { useContext, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";

import {
	alertTriangleFill,
	bulbOutline,
	checkmarkCircle2Fill,
	eyeOutline,
	globe2Outline,
	linkedinFill,
	phoneOutline,
	plusOutline,
} from "@assets/Icons";

import { extractIdNumberFromId } from "@tools/Users";

import { InlineButton } from "@components/atoms/InlineButton";
import { PaginationPrimary } from "@components/molecules/PaginationPrimary";
import { ScrollableTabGroup } from "@components/molecules/TabGroup";
import { Success } from "@components/organisms/toast/ToastNotification";
import InformationBanner from "@components/templates/informationBanner/InformationBanner";

import { createSpontanenousApplication } from "../SpontaneousApplicationAPI";
import { SpontaneousApplicationContext } from "../index";

import { deleteCookie, getCookie, setCookie } from "@tools/Cookies";
import { CompanyModal } from "./TableView/CompanyModal";
import { OrganizationNetworkCard } from "./TableView/OrganizationNetworkCard";
import { OrganizationScrappingCard } from "./TableView/OrganizationScrappingCard";
import { SaveApplicationModal } from "./TableView/SaveApplicationModal";
import { SpontaneousApplicationSchoolLoader } from "./TableView/SpontaneousApplicationSchoolLoader";
import { SpontaneousApplicationScrappingLoader } from "./TableView/SpontaneousApplicationScrappingLoader";
import { UpdateOrganizationModal } from "./TableView/UpdateOrganizationModal";

const DONT_SHOW_AGAIN_COOKIE = "dontShowSaveScrappedApplicationModalAgain";

type TableViewType = {
	cards: OrganizationType[];
	loadingState: { loading: boolean };

	lastPage: number;
};
export function TableView({ cards, loadingState, lastPage }: TableViewType) {
	const { loading } = loadingState;
	const navigate = useNavigate();
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const [targetCompany, setTargetCompany] = useState<OrganizationType>();
	const [showCompanyModal, setShowCompanyModal] = useState(false);
	const [showUpdateOrganization, setShowUpdateOrganization] = useState(false);
	const [choosenCompany, setChoosenCompany] = useState<OrganizationType>();
	const {
		alreadyApplyCompanies,
		setAlreadyApplyCompanies,
		setFilters,
		filters,
		user,
		tab,
		setTab,
	} = useContext(SpontaneousApplicationContext);

	const generateSpontaneousApplication = async (
		organization: OrganizationType,
	) => {
		const application = {
			organizationV2: {
				name: organization.name,
				logo: organization.logo,
			},
			job: `Candidature spontanée chez ${organization.name}`,
			step: "readyToApply",
			type: "spontaneous",
		};
		try {
			const response: any = await createSpontanenousApplication(application);

			if (response) {
				const alreadyApplyCompaniesCopy = [...alreadyApplyCompanies];

				setAlreadyApplyCompanies([
					...alreadyApplyCompaniesCopy,
					{ name: organization.name, step: "readyToApply", id: response.id },
				]);

				Success(
					<>
						Offre ajoutée à{" "}
						<a
							className="font-bold text-primary-700P"
							href={`/applications/${extractIdNumberFromId(response.id)}`}
							target="_blank"
							rel="noreferrer"
						>
							Mes candidatures
						</a>
					</>,
				);
			}
		} catch (error) {
			throw new Error(`Une erreur est survenue ${error}`);
		}
	};

	const getLinerColor = (index: number) => {
		if (index % 5 === 0) {
			return "bg-emerald-light";
		}
		if (index % 4 === 0) {
			return "bg-sakura-light";
		}
		if (index % 3 === 0) {
			return "bg-sunflower-light";
		}
		if (index % 2 === 0) {
			return "bg-lavender-light";
		}

		return "bg-aquamarine-light";
	};

	const getLocationString = (company: OrganizationType) => {
		if (filters.locations) {
			return `${
				company.locations?.find((city) => city === filters.locations[0]) ??
				company.locations?.[0]
			} ${
				company.locations.length > 1
					? ` et ${company.locations.length - 1} autre(s)`
					: ""
			}`;
		}
		if (company.locations.length === 0) {
			return "Localisation non renseignée";
		}
		return `${company.locations?.[0]} ${
			company.locations.length > 1
				? ` et ${company.locations.length - 1} autre(s)`
				: ""
		}`;
	};

	const [showSaveApplicationModal, setShowSaveApplicationModal] =
		useState<boolean>(false);

	const handleClickOnSaveApplication = (company: OrganizationType) => {
		const cookie = getCookie(DONT_SHOW_AGAIN_COOKIE);
		if (cookie) {
			saveAppplication(company);
		} else {
			setTargetCompany(company);
			setShowSaveApplicationModal(true);
		}
	};

	const triggerCloseModal = () => {
		setShowSaveApplicationModal(false);
	};

	const triggerClickOnApproved = () => {
		setShowSaveApplicationModal(false);
		saveAppplication();
	};

	const saveAppplication = (company?: OrganizationType) => {
		if (company) {
			generateSpontaneousApplication(company);
		} else if (targetCompany) {
			generateSpontaneousApplication(targetCompany);
		}
	};

	const triggerDontShowAgain = (status: boolean) => {
		if (status) {
			setCookie(DONT_SHOW_AGAIN_COOKIE, "true", 31536000);
		} else {
			deleteCookie(DONT_SHOW_AGAIN_COOKIE);
		}
	};

	return (
		<section className="col-span-4 md:col-span-8 lg:col-span-12">
			<SaveApplicationModal
				showModal={showSaveApplicationModal}
				triggerClose={triggerCloseModal}
				triggerApproved={triggerClickOnApproved}
				triggerDontShowAgain={triggerDontShowAgain}
				companyName={targetCompany?.name || ""}
			/>
			<ScrollableTabGroup
				tabs={[
					{
						label: `${
							currentInterface === "school"
								? `Annuaire ${user?.schoolName}`
								: "Réseau école"
						}`,
						state: "school",
						active: tab === "school",
					},
					{
						label: `${
							currentInterface === "school" ? "Annuaire général" : "Annuaire"
						}`,
						state: "scrapping",
						active: tab === "mg",
					},
				]}
				onTabChange={(index) => {
					switch (index) {
						case 1:
							navigate(
								currentInterface === "student"
									? "/student-spontaneous-application"
									: "/school-spontaneous-application",
							);
							setTab("mg");
							break;
						default:
							navigate(
								currentInterface === "student"
									? "/student-network"
									: "/school-network",
							);
							setTab("school");
							break;
					}
				}}
			/>
			<InformationBanner
				icon={bulbOutline}
				className="mt-sm hidden md:flex"
				body={
					<div className="ml-xsm">
						<div className="flex text-primary-700P">
							{currentInterface === "student" ? (
								<p>
									Tu trouveras ci-dessous une liste d’entreprises qui sont
									susceptibles de recruter
									{tab === "school" ? " dans ton école " : " "}
									pour leur adresser ta candidature spontanée.
								</p>
							) : (
								<>
									{tab === "school" ? (
										<p>
											Le réseau école regroupe vos entreprises partenaires et
											celles identifiées selon vos critères.
										</p>
									) : (
										<p>
											L'annuaire offre un catalogue élargi d'entreprises de
											différents secteurs et localisations. Elles sont ajoutées
											automatiquement par notre équipe Mentor Goal.
										</p>
									)}
								</>
							)}
						</div>
						{currentInterface === "student" && (
							<InlineButton
								id="guide-candidature"
								label="Besoin d’aide pour envoyer ta candidature ? Découvrir le guide
                  candidatures spontanées"
								className="mt-xsm p-0 text-left"
								onClick={() => navigate("/resources/117?type=resource")}
							/>
						)}
					</div>
				}
			/>
			{loading ? (
				tab === "school" ? (
					<SpontaneousApplicationSchoolLoader />
				) : (
					<SpontaneousApplicationScrappingLoader />
				)
			) : (
				<div className="xs:grid-cols-1 grid-list mt-sm gap-lg md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
					{cards.length === 0 && (
						<div className="vh-50 flex w-full items-center justify-center">
							<p className="text-xsm text-primary-300">
								{Object.keys(filters).length -
									(Object.prototype.hasOwnProperty.call(filters, "school")
										? 3
										: 2) >
									0 && "Aucune entreprise ne correspond à votre recherche."}
							</p>
							<p className="text-xsm text-primary-300">
								{Object.keys(filters).length -
									(Object.prototype.hasOwnProperty.call(filters, "school")
										? 3
										: 2) ===
									0 &&
									tab === "school" &&
									"Votre école n'a pas encore publié d'entreprise partenaire"}
							</p>
						</div>
					)}
					{tab === "school"
						? cards.map((company, index) => {
								const application = alreadyApplyCompanies?.find(
									(application: Partial<ApplicationType>) =>
										application.name === company.name,
								);

								return (
									<OrganizationNetworkCard
										key={`organizationNetworkCard-${index}`}
										id={company.id}
										name={company.name}
										location={getLocationString(company)}
										image={company.logo}
										onClick={() => {
											setChoosenCompany(company);
											setShowCompanyModal(true);
										}}
										className="w-full"
										application={application}
										generateSpontaneousApplication={
											generateSpontaneousApplication
										}
										links={[
											{
												icon: globe2Outline,
												link: company.website
													? company.website
													: `https://www.google.com/search?q=${company.name}`,
											},
											{
												icon: linkedinFill,
												link: company.linkedin
													? company.linkedin
													: `https://www.linkedin.com/search/results/companies/?keywords=${company.name}`,
											},
											{
												icon: phoneOutline,
												link: company.phone
													? `tel:${company.phone}`
													: `https://www.google.com/search?q=phone%20${company.name}`,
											},
										]}
									/>
								);
							})
						: cards?.map((company, index: number) => {
								const application: OrganizationType | undefined =
									alreadyApplyCompanies?.find(
										(application: Partial<ApplicationType>) =>
											application.name === company.name,
									);
								const applicationId: string | undefined = application?.id;

								return (
									<OrganizationScrappingCard
										key={`${company.id}-${index}`}
										title={company.name}
										subtitle={company.businessSector}
										illustration={company.illustration}
										logo={company.logo}
										onClick={() => {
											setChoosenCompany(company);
											setShowCompanyModal(true);
										}}
										className="w-full min-w-fit"
										color={getLinerColor(index)}
										infos={[
											application
												? {
														icon: checkmarkCircle2Fill,
														iconColor: "text-accent-4",
													}
												: {
														icon: alertTriangleFill,
														iconColor: "text-accent-3",
													},
										]}
										buttons={[
											application
												? {
														icon: eyeOutline,
														onClick: () => {
															if (!applicationId) return;
															navigate(
																`/applications/${applicationId.split("/")[3]}`,
															);
														},
														tooltip: "Voir ma candidature",
													}
												: {
														icon: plusOutline,
														onClick: () =>
															handleClickOnSaveApplication(company),
														tooltip: "Ajouter à mes candidatures",
													},
										]}
										links={[
											{
												icon: globe2Outline,
												link: company.website
													? company.website
													: `https://www.google.com/search?q=${company.name}`,
											},
											{
												icon: linkedinFill,
												link: company.linkedin
													? company.linkedin
													: `https://www.linkedin.com/search/results/companies/?keywords=${company.name}`,
											},
											{
												icon: phoneOutline,
												link: company.phone
													? `tel:${company.phone}`
													: `https://www.google.com/search?q=phone%20${company.name}`,
											},
										]}
									/>
								);
							})}
				</div>
			)}
			<div className="pb-md">
				<PaginationPrimary
					onChange={(page) => {
						setFilters({ ...filters, page });
					}}
					className="pagination-list mt-md flex items-center justify-center"
					current={filters.page}
					last={lastPage}
				/>
			</div>

			{choosenCompany && (
				<>
					{!showUpdateOrganization && (
						<CompanyModal
							company={choosenCompany}
							showCompanyModalProps={{ showCompanyModal, setShowCompanyModal }}
							setShowUpdateOrganization={setShowUpdateOrganization}
						/>
					)}

					{!showCompanyModal && (
						<UpdateOrganizationModal
							company={choosenCompany}
							show={showUpdateOrganization}
							onClose={() => {
								setShowUpdateOrganization(false);
							}}
						/>
					)}
				</>
			)}
		</section>
	);
}
