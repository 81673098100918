import React from "react";

import CompanyNameModifier from "../actions/CompanyNameModifier";
import InternalReferenceModifier from "../actions/InternalReferenceModifier";
import JobTitleModifier from "../actions/JobTitleModifier";
import LocationModifier from "../actions/LocationModifier";

export function OfferIdentityInformations() {
	return (
		<section
			className="flex w-full flex-col gap-4"
			data-testid="identityInformations"
		>
			<JobTitleModifier />
			<CompanyNameModifier />
			<LocationModifier />
			<InternalReferenceModifier />
		</section>
	);
}
