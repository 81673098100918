import DOMPurify from "dompurify";
import React from "react";
import { NavLink } from "react-router-dom";

import type { NotificationType } from "@/types/notificationTypes";
import { convertDateFromNow } from "@tools/Dates";

export function NotificationCard({
	notification,
}: { notification: NotificationType }) {
	const resolveNotificationText = () => {
		switch (notification.type) {
			case "newResource":
				return `Une nouvelle ressource a été partagé par ton école <strong>${notification.info}</strong>`;
			case "applicationAccepted":
				return `Votre candidature <strong>${notification.info}</strong> à été validée par ton école`;
			case "newOffers":
				return `<strong>${notification.info}</strong> nouvelles offres sont disponibles sur ton Espace Emploi`;
			case "applicationNeedInfo":
				return `Votre candidature <strong>${notification.info}</strong> doit être complétée`;
			default:
				return "";
		}
	};

	return (
		<NavLink to={notification.link as string}>
			<div
				id="break-line"
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(resolveNotificationText()),
				}}
				className="text-primary-700P"
			/>

			<p className="text-xxsm text-primary-300">
				{convertDateFromNow(notification.createdAt as Date)}
			</p>
		</NavLink>
	);
}
