import type { OffersStatusType } from "@/types/offerTypes";
import type { UserType } from "@/types/userTypes";
import React, { useEffect, useState } from "react";

import { OFFER_CLOSE_REASON_OPTIONS } from "@constants/Offers";

import { RadioPrimitive } from "@components/atoms/RadioPrimitive";
import { TextArea } from "@components/atoms/TextArea";
import { CheckboxLabelPrimary } from "@components/molecules/CheckboxLabel";
import { ComboboxSearchStudent } from "@components/molecules/ComboboxSearchStudent";
import { SelectTertiary } from "@components/organisms/select/SelectTertiary";

const MAX_LENGTH_CLOSE_REASON = 30;

type CloseOfferModalProps = {
	defaultProvidedState?: boolean;
	catchCloseReason?: (reason: string) => void;
	triggerStudentSelection?: (student: UserType) => void;
	triggerWantedStatus?: (status: OffersStatusType) => void;
};

type CloseOfferReason = {
	closeReason: string;
	label: string;
	value: string;
};
export function CloseOfferModal({
	defaultProvidedState = true,
	catchCloseReason,
	triggerStudentSelection,
	triggerWantedStatus,
}: CloseOfferModalProps) {
	const [isProvided, setIsProvided] = useState<boolean>(defaultProvidedState);
	const [provideOutOfMG, setProvideOutOfMG] = useState<boolean>(false);
	const [closeMotif, setCloseMotif] = useState<CloseOfferReason>(
		OFFER_CLOSE_REASON_OPTIONS[0],
	);
	const [closeCustomMotif, setCloseCustomMotif] = useState<string>(
		OFFER_CLOSE_REASON_OPTIONS[0].label,
	);

	const handleReasonChange = (newReason: CloseOfferReason) => {
		setCloseMotif(newReason);
		if (catchCloseReason) catchCloseReason(newReason.label);
	};
	const handleCustomReasonChange = (newReason: string) => {
		setCloseCustomMotif(newReason);
		if (catchCloseReason) catchCloseReason(newReason);
	};
	const handleStudentSelection = (newStudentSelected: UserType) => {
		if (triggerStudentSelection) triggerStudentSelection(newStudentSelected);
	};

	const toggleIsProvided = () => {
		if (isProvided) {
			if (triggerWantedStatus) triggerWantedStatus("notProvided");
		} else {
			if (triggerWantedStatus) triggerWantedStatus("provided");
		}
		setIsProvided(!isProvided);
	};

	const toggleProvideOutOfMG = () => {
		setProvideOutOfMG(!provideOutOfMG);
	};

	useEffect(() => {
		handleReasonChange(OFFER_CLOSE_REASON_OPTIONS[0]);
	}, []);

	return (
		<div className="flex flex-col gap-md">
			<p className="text-primary-700p text-base font-semibold">
				L'offre est-elle pourvue ?
			</p>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-4">
					<div className="flex cursor-pointer items-center gap-xsm w-full">
						<RadioPrimitive
							name="radiogroup-zaezae-0"
							defaultState={true}
							itemSelected={isProvided ? 0 : 1}
							onChange={toggleIsProvided}
						/>
						<p
							className="text-xxsm text-primary-700P"
							onClick={toggleIsProvided}
						>
							Oui, l'offre a été pourvue
						</p>
					</div>
					{isProvided && (
						<div className="flex h-auto flex-col gap-2">
							<p>Quel étudiant a décroché le poste ?*</p>
							{!provideOutOfMG && (
								<ComboboxSearchStudent
									handleNewSelection={handleStudentSelection}
								/>
							)}
							<CheckboxLabelPrimary
								label="Étudiant hors Mentor Goal"
								checked={provideOutOfMG}
								onCheck={toggleProvideOutOfMG}
							/>
						</div>
					)}
				</div>
				<div className="flex flex-col gap-4">
					<div className="flex cursor-pointer items-center gap-xsm w-full">
						<RadioPrimitive
							name="radiogroup-azeaze-1"
							defaultState={true}
							onChange={toggleIsProvided}
							itemSelected={isProvided ? 0 : 1}
						/>
						<p
							className="text-xxsm text-primary-700P"
							onClick={toggleIsProvided}
						>
							Non, l'offre n'a pas été pourvue
						</p>
					</div>
					{!isProvided && (
						<>
							<SelectTertiary
								defaultValue={OFFER_CLOSE_REASON_OPTIONS[0]}
								label="Motif"
								onChange={(selectedOption: CloseOfferReason) => {
									handleReasonChange(selectedOption);
								}}
								options={OFFER_CLOSE_REASON_OPTIONS}
								position="right"
							/>
							{closeMotif.value === "other" && (
								<div className="flex flex-col gap-1">
									<TextArea
										height="auto"
										className="mt-sm"
										value={closeCustomMotif}
										onChange={(reasonChanged: string) => {
											handleCustomReasonChange(reasonChanged);
										}}
										label="Message à envoyer à l'étudiant"
										autosize
										maxLength={MAX_LENGTH_CLOSE_REASON}
									/>
									<p className="text-primary- text-xxsm">
										{closeCustomMotif.length}/{MAX_LENGTH_CLOSE_REASON}{" "}
										caractères
									</p>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
