import type { MediaObjectType } from "@/types/mediaObjectTypes";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";

import cvModels from "@assets/illustrations/cvModels.png";

import { FileInput } from "@components/molecules/FileInput";
import { FilesPrimary } from "@components/molecules/Files";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import { getCV, sendFile, updateSpsStudent } from "../DashboardAPI";

export default function MyCV() {
	const { user } = useContext(GlobalContext);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [cv, setCv] = useState<MediaObjectType | null>();
	const [errorCv, setErrorCv] = useState("");

	const updateProfileValue = async (updateInputs: object) => {
		if (!user) return;

		try {
			setIsLoading(true);
			await updateSpsStudent({
				id: user.id,
				...updateInputs,
			});
			setIsLoading(false);
			Success("Modification enregistrée");
		} catch (_e) {
			ErrorToast("Erreur lors de la modification");
		}
	};

	const uploadFile = async (file: any) => {
		const formData = new FormData();

		formData.append("file", file);
		formData.append("type", "cv");

		setIsLoading(true);
		const response = await sendFile(formData);
		await updateProfileValue({ cv: response["@id"] });
		setCv(response);
		setIsLoading(false);
	};

	const fetchData = async () => {
		if (!user?.id) return;
		const response = await getCV(user?.id);

		setCv(response);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div className="flex flex-col items-center">
			<p className="w-full">
				Le CV c’est la première et la plus importante des étapes de ta recherche
				d’emploi. Il existe mille et une façon de faire un bon CV.
			</p>
			<div className="my-md flex w-full flex-col justify-center">
				<p className="text-sm font-bold text-primary-700P">Dépose ton CV</p>
				<p className="mt-sm">Ton CV est prêt ?</p>
				<p className="mt-sm">
					Dépose ton CV ici, il sera désormais accessible à ton école qui pourra
					le valider et l’envoyer à des entreprises partenaires.
				</p>

				{isLoading ? (
					<Skeleton height={40} />
				) : cv ? (
					<>
						<FilesPrimary
							isLoading={isLoading}
							title={cv.filePath}
							subtitle={`${Math.floor(cv.size / 1000)} Ko`}
							className="mt-3"
							onDelete={() => {
								setCv(null);
								updateProfileValue({ cv: null });
							}}
							onView={() => {
								window.open(`${import.meta.env.VITE_S3}${cv.filePath}`);
							}}
						/>
					</>
				) : (
					<>
						<FileInput
							id="cv"
							isLoading={isLoading}
							className="mt-sm"
							maxSize={1}
							acceptFormats={{ "application/pdf": [] }}
							onSuccess={(files: any) => {
								if (files !== undefined) {
									uploadFile(files[0]);
									setErrorCv("");
								} else {
									setErrorCv(
										"Le fichier envoyé dépasse la limite de 1Mo ou n'est pas sous format PDF",
									);
								}
							}}
							onError={(files: any) => {
								setErrorCv(
									files[0]?.size / 1000000 >= 1
										? "Le fichier envoyé dépasse la limite de 1Mo"
										: "Le fichier envoyé n'est pas sous format PDF",
								);
							}}
						/>
					</>
				)}
				{errorCv ? <p className="text-error-dark">{errorCv}</p> : ""}
			</div>
			<div className="my-md flex  w-full flex-col justify-center">
				<p className="text-sm font-bold text-primary-700P">
					Besoin d’aide pour faire ton CV ?
				</p>

				<div
					className="flex flex-col justify-center"
					onClick={() => {
						navigate("/cv-models");
					}}
				>
					<div className="mt-xsm flex cursor-pointer flex-col items-center rounded-lg bg-white p-3 shadow-xsm hover:shadow-lg ">
						<p className="my-xsm text-sm text-primary-700P">Modèles de CV</p>
						<img className="w-full" src={cvModels} alt="illustration-model" />
					</div>
				</div>
			</div>
		</div>
	);
}
