import type { OrganizationType } from "@/types/organizationTypes";
import type { UserType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useOutletContext } from "react-router-dom";

import { getCookie, setCookie } from "@tools/Cookies";

import type { SchoolLayoutContextType } from "@containers/school/SchoolLayout/SchoolLayout";
import {
	retrieveOrganizations,
	spontaneousApplicationData,
} from "./SpontaneousApplicationAPI";
import { Head } from "./ui/Head";
import { TableView } from "./ui/TableView";

type FiltersType = {
	page: number;
	[key: string]: any;
};

type SpontaneousApplicationContextType = {
	alreadyApplyCompanies: OrganizationType[];
	setAlreadyApplyCompanies: Function;
	filters: FiltersType;
	setFilters: Function;
	loading: boolean;
	DEFAULT_FILTERS: FiltersType;
	user?: UserType;
	tab: string;
	setTab: Function;
};

const DEFAULT_FILTERS: FiltersType = {
	page: 1,
};

export const SpontaneousApplicationContext =
	createContext<SpontaneousApplicationContextType>({
		alreadyApplyCompanies: [],
		setAlreadyApplyCompanies: () => null,
		filters: DEFAULT_FILTERS,
		setFilters: () => null,
		loading: true,
		DEFAULT_FILTERS,
		tab: "school",
		setTab: () => null,
	});

export function SpontaneousApplication() {
	const { user } = useContext(GlobalContext);
	const { structureView, setTitle, currentInterface }: SchoolLayoutContextType =
		useOutletContext();
	const [cards, setCards] = useState<OrganizationType[]>([]);
	const [tab, setTab] = useState("school");
	const headOfPage = useRef<HTMLInputElement>(null);
	const [filters, setFilters] = useState<FiltersType>(
		getCookie("SpontaneousApplicationFilters")
			? {
					...JSON.parse(getCookie("SpontaneousApplicationFilters") ?? ""),
				}
			: DEFAULT_FILTERS,
	);
	const [loading, setLoading] = useState(true);
	const [lastPage, setLastPage] = useState(1);
	const [alreadyApplyCompanies, setAlreadyApplyCompanies] = useState([]);

	const getTab = () => {
		switch (window.location.href.split("/")[3]) {
			case "school-spontaneous-application":
				setTab("mg");
				break;
			case "student-spontaneous-application":
				setTab("mg");
				break;
			default:
				setTab("school");
		}
	};

	const getData = () => {
		spontaneousApplicationData(user, filters, tab).then(
			({ organizations, applications }: any) => {
				setAlreadyApplyCompanies(
					applications.collection.map(
						(app: {
							id: string;
							organization: { name: string };
							step: string;
						}) => {
							return {
								name: app.organization?.name,
								step: app.step,
								id: app.id,
							};
						},
					),
				);
				setCards(organizations.collection);
				setLastPage(organizations.paginationInfo.lastPage);
				setLoading(false);
			},
		);
	};

	const updateOrganizations = async () => {
		setLoading(true);
		setCookie("SpontaneousApplicationFilters", JSON.stringify(filters));

		const { collection, paginationInfo }: any = await retrieveOrganizations(
			user,
			filters,
			tab,
		);

		if (collection && paginationInfo) {
			setCards(collection);
			setLastPage(paginationInfo.lastPage);
			setLoading(false);
		}
	};

	useEffect(() => {
		setTitle(
			currentInterface === "school" ? "Prospection" : "Candidatures spontanées",
		);

		if (user) {
			if (loading) {
				getData();
			} else {
				updateOrganizations();
			}
		}
	}, [filters, structureView]);

	useEffect(() => {
		getTab();
		if (getCookie("SpontaneousApplicationFilters")) {
			setFilters({
				...JSON.parse(getCookie("SpontaneousApplicationFilters") ?? ""),
			});
		} else {
			setFilters({
				...filters,
			});
		}
	}, [location.pathname]);

	return (
		<main ref={headOfPage} className="flex flex-col gap-md">
			<SpontaneousApplicationContext.Provider
				value={{
					alreadyApplyCompanies,
					setAlreadyApplyCompanies,
					filters,
					setFilters,
					loading,
					DEFAULT_FILTERS,
					user,
					tab,
					setTab,
				}}
			>
				<Head />
				<TableView
					cards={cards}
					loadingState={{ loading }}
					lastPage={lastPage}
				/>
			</SpontaneousApplicationContext.Provider>
		</main>
	);
}
