import React from "react";

import { alertCircleOutline, checkMarkCircleOutline } from "@assets/Icons";

import { Divider } from "@components/atoms/Divider";
import { ListSecondary } from "@components/molecules/ListSecondary";

type ContentType = {
	blocs: {
		title: string;
		actions: {
			title: string;
			isComplete: boolean;
			onclick: () => void;
		}[];
	}[];
};

export function Content({ blocs }: ContentType) {
	return (
		<div className="flex flex-col md:flex-row gap-md">
			{blocs?.map((bloc) => {
				return (
					<div className="bg-primary-50 p-md w-full md:w-1/2 rounded-lg flex flex-col">
						<p className="font-bold text-xxsm">{bloc.title}</p>
						{bloc.actions?.map((action) => {
							return (
								<div>
									<Divider className="mt-[12px] mb-sm" />
									<ListSecondary
										title=""
										subtitle=""
										label={action.title}
										icon={
											action.isComplete
												? checkMarkCircleOutline
												: alertCircleOutline
										}
										iconColor={
											action.isComplete ? "success-dark" : "error-dark"
										}
										onClick={action.onclick}
									/>
								</div>
							);
						})}
					</div>
				);
			})}
		</div>
	);
}
