import { useBreakpoints } from "@hooks/useBreakpoints";
import useIsTextTruncated from "@hooks/useIsTextTruncated";
import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";

import { arrowDownOutline, arrowUpOutline } from "@assets/Icons";

import TooltipContainer from "@components/atoms/TooltipContainer";
import { DropdownItem } from "@components/molecules/DropdownItem";
import { ItemSimpleSmall } from "@components/molecules/ItemSimpleSmall";

import { SelectModal } from "../selectModal/SelectModal";

const MIN_LENGTH_FOR_SCROLL = 4;
const DROPDOWN_LIST_HEIGHT_ADJUSTMENT = 40;

type SelectTertiaryType = {
	className?: string;
	placeholder?: string;
	options: Array<{
		label: string;
		value: any;
	}>;
	onChange?: (input: any) => void;
	disabled?: boolean;
	label?: string;
	icon?: any;
	defaultValue?: any;
	menuPosition?: string;
	position?: string;
	errorMessage?: string;
	truncate?: boolean;
	breakSpaces?: boolean;
};

export function SelectTertiary({
	className,
	placeholder,
	options,
	onChange,
	disabled = false,
	label,
	defaultValue = {},
	icon,
	position,
	errorMessage,
	breakSpaces,
}: SelectTertiaryType) {
	const { isMobile } = useBreakpoints(599);
	const [isOpen, setIsOpen] = useState(false);
	const [isInModal, setIsInModal] = useState(false);
	const [value, setValue] = useState(defaultValue);
	const [width, setWidth] = useState(0);
	const [randomId] = useState(Math.floor(Math.random() * 1000));

	const ref = useRef(null);
	const refOfValue = useRef(null);
	const valueTruncate = useIsTextTruncated(refOfValue);
	const refDropdownList = useRef(null);
	const returnValue = (input: any) => {
		if (typeof input === "boolean" || typeof input === "object") {
			return input.value;
		}
		if (typeof input === "string") return input;
		return input.label;
	};

	useEffect(() => {
		if (defaultValue === null) {
			setValue(null);
		} else if (Object.keys(defaultValue).length !== 0) {
			setValue(returnValue(defaultValue));
		}
	}, [defaultValue, options]);

	useEffect(() => {
		const modal = document.getElementById("modal-body");
		const select = document.getElementById(`select-tertiary-${randomId}`);
		if (modal?.contains(select)) {
			setIsInModal(true);
		}
	}, [options]);

	// disable ts verification for all this block
	useEffect(() => {
		const checkModalDisplay = document.querySelector('[id*="modal"]');

		if (checkModalDisplay && refDropdownList.current) {
			// @ts-ignore
			const dropdownList = refDropdownList.current?.getBoundingClientRect();
			const bottomYdropdownList = dropdownList.y + dropdownList.height;

			if (window.innerHeight < bottomYdropdownList) {
				const translateY = -(
					dropdownList.height + DROPDOWN_LIST_HEIGHT_ADJUSTMENT
				);
				// @ts-ignore
				refDropdownList.current.style.transform = `translate3d(0px,${translateY}px,10px)`;
			} else {
				// @ts-ignore
				refDropdownList.current.style.transform = null;
			}
		}

		// @ts-ignore
		setWidth(ref.current.offsetWidth);
		const handleClickOutside = (event: any) => {
			// @ts-ignore
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [isOpen]);

	return (
		<div
			className={`relative cursor-pointer ${className} ${
				disabled ? "pointer-events-none" : ""
			}`}
			id={`select-tertiary-${randomId}`}
			ref={ref}
		>
			{label && (
				<p
					className={`mb-xxsm text-xxsm font-normal text-primary-500 ${
						disabled ? "!text-primary-200" : ""
					}`}
				>
					{label}
				</p>
			)}
			<div
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				className={`flex h-10 justify-between !rounded-md border border-primary-150 bg-white px-sm py-xsm hover:shadow-primary150 focus:shadow-primary500${
					icon ? " pl-xsm" : ""
				}`}
			>
				<div className="flex items-center overflow-hidden">
					{icon && (
						<Icon
							className={`mr-xsm h-4 w-4 text-primary-700P ${
								disabled
									? "bg-primary-150 text-primary-200 grayscale-[50%]"
									: ""
							}`}
							icon={icon}
						/>
					)}
					<p
						className={`truncate text-primary-700P disabled:text-primary-300 ${
							disabled ? "!text-primary-300" : ""
						}`}
						id={`text-truncate-${label}`}
						ref={refOfValue}
					>
						{options?.find(
							(option) => option.value === value || option.label === value,
						)?.label || placeholder}
					</p>
					{valueTruncate && (
						<TooltipContainer
							children={
								options?.find(
									(option) => option.value === value || option.label === value,
								)?.label || placeholder
							}
							anchorId={`#text-truncate-${label}`}
							place="top"
						/>
					)}
				</div>

				<Icon
					className={`my-auto h-4 w-4 ${disabled ? "text-primary-200" : ""}`}
					icon={isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>

			{!isMobile && (
				<div className=" relative z-10 h-auto">
					<div className={"absolute top-0"}>
						<div
							ref={refDropdownList}
							style={{ width: `${width}px` }}
							className={`z-10${
								isInModal ? " fixed " : ""
							}h-auto max-h-[215px] rounded bg-white shadow-sm${
								isOpen ? "" : " hidden"
							}${
								options.length > MIN_LENGTH_FOR_SCROLL
									? " overflow-y-scroll"
									: ""
							} ${position || ""}`}
						>
							{options?.map((input, i) => {
								return (
									<DropdownItem
										key={i}
										onClick={() => {
											setValue(input.value);
											if (onChange) {
												onChange(input);
											}

											setIsOpen(false);
										}}
										breakSpaces={breakSpaces}
										label={input.label}
									/>
								);
							})}
						</div>
					</div>
					{errorMessage && (
						<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
					)}
				</div>
			)}

			<SelectModal
				title={label || placeholder || ""}
				onClick={() => {
					setIsOpen(false);
				}}
				body={
					<div>
						{options?.map((input, i) => {
							return (
								<div key={i}>
									<ItemSimpleSmall
										onClick={() => {
											setValue(input.value);
											if (onChange) onChange(input);
										}}
										label={input.label}
									/>
								</div>
							);
						})}
					</div>
				}
				show={isOpen}
			/>
		</div>
	);
}
