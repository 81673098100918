import useDebounce from "@hooks/useDebounce";
import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
// TO DO : rework it with memo column in the offer table ,etc
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";

import { checkmarkCircle2Outline, refreshFill } from "@assets/Icons";

import { TextArea } from "@components/atoms/TextArea";
import TooltipContainer from "@components/atoms/TooltipContainer";
import TooltipElement from "@components/atoms/TooltipElement";

import type { AppState } from "@config/redux/store";
import { setCommentSelectionUser } from "@containers/public/Selection/SelectionAPI";
import { fetchCompanyMemo } from "@containers/school/Companies/core/usecases/fetchCompanyMemo.usecase";
import { updateCompanyMemo } from "@containers/school/Companies/core/usecases/updateCompanyMemo.usecase";

import { getOfferMemo } from "@containers/school/Offers_v3/core/api/offersManagement.request";
import { fetchModifyOfferMemo } from "@containers/school/Offers_v3/core/store/offersManagement.thunks";

type MemoType = "offer" | "company" | "test" | "selection";

type MemoProps = {
	memoId: string;
	type: MemoType;
	defaultValue?: string;
};

export function Memo({ memoId, type, defaultValue = "" }: MemoProps) {
	const dispatchEvent = useDispatch();
	const [memoBody, setMemoBody] = useState<string>(defaultValue);
	const [fetchingError, setFetchingError] = useState<boolean>(false);
	const [memoInitialised, setMemoInitialised] = useState<boolean>(false);
	const [initialValue, setInitialValue] = useState<string>("");
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [hasCompletedSaving, setHasCompletedSaving] = useState<boolean>(false);
	const debounced = useDebounce(memoBody, 1000);
	const { user } = useContext(GlobalContext);
	const id = uuid();

	const { targetCompany } = useSelector(
		(state: AppState) => state.companiesManagement,
	);

	const handleMemoChange = (value: string) => {
		setMemoBody(value);
	};

	const handleMemoSaving = async (value: string) => {
		if (!memoInitialised) {
			setFetchingError(false);
			return;
		}
		setIsSaving(true);
		switch (type) {
			case "offer": {
				const response = await dispatchEvent(
					fetchModifyOfferMemo({
						id: memoId || "",
						memo: value,
					}) as any,
				);
				if (response?.payload === undefined || response?.payload === null) {
					setFetchingError(true);
					break;
				}
				setFetchingError(false);
				break;
			}
			case "company": {
				if (initialValue === value) break;
				const response = await dispatchEvent(updateCompanyMemo(value) as any);
				if (response === undefined) {
					setFetchingError(true);
					break;
				}
				setFetchingError(false);
				break;
			}
			case "test":
				await dispatchEvent(
					fetchModifyOfferMemo({ id: memoId || "", memo: value }) as any,
				);
				break;
			case "selection": {
				const [token, userId] = memoId.split("#");
				const repsponse = await setCommentSelectionUser(
					token,
					Number.parseInt(userId),
					value,
				);
				if (repsponse.code !== 200) {
					setFetchingError(true);
					break;
				}
				setFetchingError(false);
				break;
			}
		}
		setIsSaving(false);
		setHasCompletedSaving(true);
		return value;
	};

	const retrieveMemo = async () => {
		switch (type) {
			case "offer": {
				const response = await getOfferMemo(memoId || "");
				setMemoBody(response?.data?.offer?.memo || "");
				setMemoInitialised(true);
				break;
			}
			case "company": {
				const response = await dispatchEvent(fetchCompanyMemo as any);
				setInitialValue(response?.length > 0 ? response : "");
				setMemoBody(response?.length > 0 ? response : "");
				setMemoInitialised(true);
				setFetchingError(false);
				break;
			}
			case "test":
				await dispatchEvent(getOfferMemo(memoId || "") as any);
				break;
			case "selection":
				setMemoBody(defaultValue || "");
				setMemoInitialised(true);
				break;
		}
	};

	useEffect(() => {
		setHasCompletedSaving(false);
		handleMemoSaving(debounced);
	}, [debounced]);

	useEffect(() => {
		// force new initialisation because id has changed
		setMemoInitialised(false);
		retrieveMemo();
	}, [memoId, targetCompany]);

	return (
		<aside className="flex  min-h-fit w-full flex-col items-center gap-6 rounded-lg bg-secondary-100 p-4">
			<div className="flex items-center justify-start gap-1">
				<p className="text-sm font-bold text-primary-700P">
					{type === "selection" ? "Commentaire" : "Mémo"}
				</p>
				<TooltipElement id={`tooltip-memo-${id}`} />
				<TooltipContainer
					anchorId={`#tooltip-memo-${id}`}
					makeClickable={false}
					children={
						type === "selection"
							? "Votre commentaire sera visible par l’école"
							: `Seuls vous et les autres référents de ${user?.schoolName} pourront voir et modifier le mémo.`
					}
					place="top"
				/>
			</div>
			<TextArea
				value={memoBody}
				onChange={handleMemoChange}
				placeholder={
					memoInitialised
						? type === "selection"
							? "Écrivez votre commentaire ici…"
							: "Écrivez un mémo ici…"
						: "Chargement du mémo..."
				}
				areaBackground="bg-transparent"
				height={"auto"}
				autosize
			/>

			<div
				className="flex w-full items-center justify-start gap-1"
				data-testid="memo-status"
			>
				{isSaving && (
					<>
						<p className="text-xxsm font-bold text-primary-200">
							Enregistrement...
						</p>
						<Icon
							icon={refreshFill}
							className="animate-spin text-secondary-700"
						/>
					</>
				)}
				{hasCompletedSaving && !isSaving && !fetchingError && (
					<>
						<p className="text-xxsm font-bold text-primary-200">Enregistré</p>{" "}
						<Icon
							icon={checkmarkCircle2Outline}
							className="text-success-dark"
						/>
					</>
				)}
				{hasCompletedSaving && !isSaving && fetchingError && (
					<p className="text-xxsm font-bold text-primary-200">
						Erreur lors de la modification du mémo
					</p>
				)}
			</div>
		</aside>
	);
}
