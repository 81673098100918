import React, { forwardRef } from "react";
import type { RefObject } from "react";

type MonthPopUpPropsType = {
	show?: boolean;
	handleOnChangeMonth: (year: number) => void;
	selectedMonth?: number;
};

export const SHORT_MONTH_LABELS = [
	"Janv",
	"Févr",
	"Mars",
	"Avr",
	"Mai",
	"Juin",
	"Juil",
	"Août",
	"Sept",
	"Oct",
	"Nov",
	"Déc",
];
export const FULL_MONTH_LABELS = [
	"Janvier",
	"Février",
	"Mars",
	"Avril",
	"Mai",
	"Juin",
	"Juillet",
	"Août",
	"Septembre",
	"Octobre",
	"Novembre",
	"Décembre",
];

export const MonthPopUp = forwardRef(
	(props: MonthPopUpPropsType, ref: RefObject<HTMLDivElement>) => {
		const currentMonth = new Date().getFullYear();
		const {
			show = true,
			handleOnChangeMonth,
			selectedMonth = currentMonth,
		} = props;

		if (!show) {
			return <></>;
		}

		return (
			<div ref={ref} className="month-picker">
				{SHORT_MONTH_LABELS.map((monthLabel, index) => (
					<div
						onClick={() => handleOnChangeMonth(index)}
						className={`month-pick ${
							selectedMonth === index ? "selected" : ""
						}`}
						key={index}
					>
						<p>{monthLabel}</p>
					</div>
				))}
			</div>
		);
	},
);
