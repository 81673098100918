import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const updateCompanyMemo =
	(memo: string) =>
	async (
		dispatch: AppDispatch,
		api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.companyInformationsGateway) {
				throw new Error(
					"companyInformationsGateway is not available in dependencies",
				);
			}
			const targetCompany = api().companiesManagement.targetCompany;
			const result =
				await dependencies.companyInformationsGateway.updateCompanyMemo(
					targetCompany,
					memo,
				);
			if (result) {
				dispatch(
					companiesManagementSlice.actions.modifyCompanyMemo({
						memo,
					}),
				);
				return memo;
			}
			return null;
		} catch (error: unknown) {
			dispatch(
				companiesManagementSlice.actions.handleUpdateInformationsError(
					extractError(error).message.toString(),
				),
			);
			return null;
		}
	};
