import React from "react";

import HeroSource from "@assets/illustrations/heroJobboard.svg";

type PageBannerProps = {
	title: string;
	link?: string;
};

function PageBanner({ title, link = HeroSource }: PageBannerProps) {
	return (
		<div
			id="hero"
			role="banner"
			className="relative flex aspect-video !w-full items-center justify-center overflow-hidden rounded-lg object-cover md:aspect-10/1"
		>
			<img
				src={link}
				alt="Jobboard illustration"
				className="h-full w-full object-cover"
			/>
			<p className="absolute text-center text-lg text-white">{title}</p>
		</div>
	);
}

export default PageBanner;
