import type { ConversationType, MessageType } from "@/types/mailbox";

import api, { checkGraphQLError } from "@tools/API";
import { stringifyRequestFilters } from "@tools/Objects";

let currentAbortController: any = null;
export async function getStudents(stringifyFilters: string) {
	let rep = {};

	const data = {
		query: `query {
        users: collectionQuerySpsStudents (itemsPerPage: 10, order: [{spsConversation_status:"DESC"}], ${stringifyFilters}) {
          collection {
            id
            firstname
            lastname
            avatar {
              filePath
            }
            conversation: spsConversation {
              id
              status
              updatedAt
              spsMessages(itemsPerPage: 1, order: [{datetime: "DESC"}]){
                collection{
                    id
                    content
                    datetime
                    isReadByStudent
                    spsStudent {
                      id
                      firstname
                      lastname
                      avatar{
                        id
                        filePath
                      }
                    }
                    spsReferent {
                      id
                      firstname
                      lastname
                      avatar{
                        id
                        filePath
                      }
                    }
                }
              }
            }
          }
          paginationInfo {
            lastPage
            totalCount
          }
        },
      }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function getStudentConversation(studentId: string) {
	let rep = {};

	const data = {
		query: `query {
        user: spsStudent(id:"${studentId}") {
          id
          firstname
          lastname
          avatar {
              filePath
          }
          conversation: spsConversation {
            id
            status
            updatedAt
            spsMessages(itemsPerPage: 1000, order: [{datetime: "ASC"}]){
              collection{
                id
                content
                datetime
                isSendBySMS
                isSendByMail
                isGroupedMessage
                spsStudent {
                  id
                  firstname
                  lastname
                  avatar{
                    id
                    filePath
                  }
                }
                spsReferent {
                  id
                  firstname
                  lastname
                  avatar{
                    id
                    filePath
                  }
                }
              }
            }
          }
        }
      }`,
	};
	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}
export async function getUnsolvedConversationCounter(filters: any) {
	let rep = {};
	const stringifyFilters = stringifyRequestFilters(filters);
	const data = {
		query: `query {
        users: collectionQuerySpsStudents (spsConversation_status: "unsolved", ${stringifyFilters}) {
          paginationInfo {
            lastPage
            totalCount
          }
        },
      }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export async function getUnreadStudentMessagesCounter() {
	try {
		const { data } = await api.get("get_unread_message_count");
		return data;
	} catch (error) {
		throw new Error(error);
	}
}

export async function updateConversation(conversation: ConversationType) {
	let rep = {};
	const queryStringify = stringifyRequestFilters(conversation);
	const data = {
		query: `mutation {
      updateSpsConversation(input:{${queryStringify}}){
        spsConversation {
          id
          spsStudent {
            id
          }
          status
        }
      }
    }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export async function getConversation(conversationId: string) {
	let rep = {};

	const data = {
		query: `query {
      spsConversation(id:"${conversationId}"){
          id
          updatedAt
          spsMessages( itemsPerPage: 1000, order:[{datetime:"ASC"}]){
            collection{
              id
              content
              datetime
              isSendBySMS
              isSendByMail
              isGroupedMessage
              isReadByStudent
              spsStudent {
                id
                firstname
                lastname
                avatar{
                  id
                  filePath
                }
              }
              spsReferent {
                id
                firstname
                lastname
                avatar{
                  id
                  filePath
                }
              }
            }
          }
      }       
    }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function getSMSCredits(school: string) {
	let rep = {};

	const data = {
		query: `query {
        school (id: "${school}") {
            smsCredits
        }
      }`,
	};

	try {
		if (currentAbortController !== null) {
			currentAbortController.abort();
			currentAbortController = null;
		}
		currentAbortController = new AbortController();
		rep = await api.post("graphql", data, {
			signal: currentAbortController.signal,
		});
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function postMessage(stringifyFilters: string) {
	let rep = {};
	const data = {
		query: `mutation {
      createSpsMessage(input:{${stringifyFilters}}){
        spsMessage{
              id
              content
              conversation {
                  id
                  spsStudent{
                      id
                      firstname
                  }
              }
          }
      }
    }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function updateMessage(message: MessageType) {
	let rep = {};
	const queryStringify = stringifyRequestFilters(message);
	const data = {
		query: `mutation {
      updateSpsMessage(input:{${queryStringify}}){
        spsMessage{
          id
          content
          conversation: spsConversation {
              id
              updatedAt
              spsStudent {
                  id
                  firstname
              }
          }
        }
      }
    }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}
