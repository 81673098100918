import { Icon } from "@iconify/react";
import React from "react";

import { bulbOutline } from "@assets/Icons";

export function ReplyListHelp() {
	return (
		<article
			data-testid="eventHelpSection"
			className="z-50 flex w-full flex-col rounded-lg bg-[#fff] px-6 py-4 shadow-md"
		>
			<div
				className="flex gap-4 text-sm font-bold text-primary-700P"
				data-testid="modalReplyList"
			>
				<Icon icon={bulbOutline} className="h-6 w-6 text-secondary-500P" />
				<div className="font-blod mb-6 text-xxsm text-primary-700P">
					<ul className="list-disc pl-[10px]">
						<li>Inscrits : étudiants inscrits à l'événement.</li>
						<li>Peut-être : étudiants intéressés par l'événement.</li>
						<li>Invités : étudiants invités qui n'ont pas encore répondu.</li>
						<li>Ne participent pas : étudiants qui ont refusé l'invitation.</li>
						<li>
							A participé : étudiants qui ont cliqué sur le lien d'accès à
							l'événement (événements à distance uniquement).
						</li>
					</ul>
				</div>
			</div>
		</article>
	);
}
