import React, { useRef } from "react";
import EmailEditor, { type EditorRef } from "react-email-editor";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Modal } from "@components/organisms/modal/Modal";

type CreateGroupedMessagesModalType = {
	show: boolean;
	onClose: Function;
	onClick: Function;
};
export default function CreateGroupedMessagesModal({
	show,
	onClose,
	onClick,
}: CreateGroupedMessagesModalType) {
	const emailEditorRef = useRef<EditorRef>(null);

	const onLoad = () => {
		const unlayer = emailEditorRef.current?.editor;
		unlayer?.destroy();
		unlayer?.init({
			id: "editor",
			projectId: 200963,
			templateId: 439925,
		});
	};
	return (
		<Modal
			show={show}
			size="lg"
			onClose={() => {
				onClose();
			}}
			title="Message Multiple"
			body={
				<EmailEditor
					editorId="editor"
					minHeight="75vh"
					ref={emailEditorRef}
					onLoad={onLoad}
				/>
			}
			buttonsRight={[
				<ButtonPrimary
					key={1}
					label="Envoyer l'email"
					onClick={() => {
						onClick();
						onClose();
					}}
				/>,
			]}
		/>
	);
}
