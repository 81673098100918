import type {
	ConversationType,
	MessageType,
	studentSelectedType,
} from "@/types/mailbox";
import type { UserCompleteType } from "@/types/userTypes";
import { createSlice } from "@reduxjs/toolkit";

import {
	fetchMessage,
	fetchUnsolvedConversationCounter,
	fetchUpdateConversation,
} from "./mailBoxManagement.thunks";

type MailBoxState = {
	currentSearch: string;
	loading: boolean;
	fetchInBackGround: boolean;
	studentsList: studentSelectedType[] | null;
	studentSelected?: studentSelectedType | null;
	filteredStudentsList: UserCompleteType[];
	currentTabSelected: "all" | "unsolved";
	activePage: number;
	mailBoxFilters?: any;
	currentPage: number;
	lastPage: number;
	itemPerPage: number;
	totalUnsolvedConversationCount: number | "...";
	totalUnreadStudentMessages: number | "...";
	messages: MessageType[];
	conversation: ConversationType | null;
};

const initialState: MailBoxState = {
	currentSearch: "",
	loading: false,
	fetchInBackGround: false,
	studentsList: null,
	studentSelected: undefined,
	filteredStudentsList: [],
	currentTabSelected: "all",
	activePage: 0,
	mailBoxFilters: {},
	currentPage: 1,
	lastPage: 1,
	itemPerPage: 0,
	totalUnsolvedConversationCount: "...",
	totalUnreadStudentMessages: "...",
	messages: [],
	conversation: null,
};

const mailBoxSlice = createSlice({
	name: "MailBox",
	initialState,
	reducers: {
		setCurrentSearch(state, action) {
			state.studentsList = [];
			state.currentSearch = action.payload;
		},
		setCurrentTabSelected(state, action) {
			if (action.payload && action.payload !== state.currentTabSelected) {
				state.activePage = initialState.activePage;
				state.currentTabSelected = action.payload;
				state.filteredStudentsList = [...state.filteredStudentsList];
			}
		},
		setStudentsList(state, action) {
			state.studentsList = action.payload;
		},
		setStudentSelected(state, action) {
			state.studentSelected = action.payload;
		},
		setMailBoxFilters(state, action) {
			state.mailBoxFilters = action.payload;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setFetchInBackGround(state, action) {
			state.fetchInBackGround = action.payload;
		},
		setCurrentPage(state, action) {
			state.currentPage = action.payload;
		},
		setLastPage(state, action) {
			state.lastPage = action.payload;
		},
		setItemPerPage(state, action) {
			state.itemPerPage = action.payload;
		},
		setTotalUnsolvedConversationCount(state, action) {
			state.totalUnsolvedConversationCount = action.payload;
		},
		setTotalUnreadStudentMessages(state, action) {
			state.totalUnreadStudentMessages = action.payload;
		},
		setMessages(state, action) {
			state.messages = action.payload;
		},
		setConversation(state, action) {
			state.conversation = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchUpdateConversation.fulfilled, (state, action) => {
			state.conversation = { ...state.conversation, ...action.payload };
			if (typeof state.totalUnsolvedConversationCount === "number") {
				if (action?.payload.status === "unsolved") {
					state.totalUnsolvedConversationCount += 1;
				} else {
					state.totalUnsolvedConversationCount -= 1;
				}
			}
			if (state?.studentsList) {
				state.studentsList = state.studentsList
					?.map((student) =>
						student.id === action.payload.spsStudent.id
							? {
									...student,
									conversation: { ...student.conversation, ...action.payload },
								}
							: student,
					)
					?.sort((student) => {
						if (student.conversation?.status === "unsolved") return -1;
						return 1;
					});
			}
		});
		builder.addCase(
			fetchUnsolvedConversationCounter.fulfilled,
			(state, action) => {
				state.totalUnsolvedConversationCount = action.payload;
			},
		);

		builder.addCase(fetchMessage.fulfilled, (state, action) => {
			state.studentSelected = action.payload;
			if (action.payload.conversation) {
				state.conversation = action.payload.conversation;
			}
		});
	},
});

export const {
	setCurrentSearch,
	setCurrentTabSelected,
	setStudentsList,
	setStudentSelected,
	setMailBoxFilters,
	setLoading,
	setFetchInBackGround,
	setCurrentPage,
	setLastPage,
	setItemPerPage,
	setTotalUnsolvedConversationCount,
	setTotalUnreadStudentMessages,
	setMessages,
	setConversation,
} = mailBoxSlice.actions;
export default mailBoxSlice.reducer;
