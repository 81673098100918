import React from "react";
import { Tooltip } from "react-tooltip";

import { Avatar } from "../../../../../components/atoms/Avatar";
import type { ProspectType } from "../Kanban";
import { DATEFORMAT } from "../Kanban/Card";

export const ProfileCard = ({ prospect }: { prospect: ProspectType }) => {
	return (
		<div className="flex flex-row p-8 gap-4 border rounded-xl">
			<Avatar
				size="lg"
				firstname={prospect.data.firstName.value}
				lastname={prospect.data.lastName.value}
				image=""
			/>
			<div className="flex flex-1 flex-col">
				<span
					className="text-2xl font-bold line-clamp-1 w-fit-content"
					id={`profile-card-full-name-${prospect.id}`}
				>
					{prospect.data.firstName.value} {prospect.data.lastName.value}
				</span>
				<Tooltip anchorSelect={`#profile-card-full-name-${prospect.id}`}>
					{prospect.data.firstName.value} {prospect.data.lastName.value}
				</Tooltip>
				<span>Source : Site Web</span>
				<span>
					Postulé le :{" "}
					{new Intl.DateTimeFormat("fr-FR", DATEFORMAT).format(
						new Date(prospect.createdAt),
					)}
				</span>
			</div>
		</div>
	);
};
