import type { CellType } from "@/types/TableTypes";

export const getDotColorFromNumber = (number: number) => {
	if (number > 0) {
		return "bg-error-dark";
	}
	if (number === 0) {
		return "bg-accent-4";
	}
	return "bg-primary-150";
};

export const getTabKeyFromIndex = (
	id: number,
	tableToUse: Map<string, string>,
) => {
	if (!tableToUse) return "";
	const keys = [...tableToUse.keys()];
	return keys[id];
};

export const sortCellsByIndex = (cells: CellType[]): CellType[] => {
	if (!cells) return [];
	const cellsCopy = [...cells];
	return cellsCopy.sort((a, b) => a.cellIndex - b.cellIndex);
};
