import React from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";

type GalliaDiffStudentModalType = {
	diff: Record<string, { student: string; gallia: string }>;
	show: boolean;
	onClose: () => void;
	syncStudentToGallia: () => void;
};

export function GalliaDiffStudentModal({
	diff,
	show,
	onClose,
	syncStudentToGallia,
}: GalliaDiffStudentModalType) {
	return (
		<div>
			<Modal
				show={show}
				onClose={() => {
					onClose();
				}}
				title={"Différences entre Mentorgoal et Gallia"}
				body={
					<div className="flex flex-col gap-4">
						<p className="border p-2 rounded">
							Attention, la synchronisation des données va écraser les données
							de Gallia par celles de Mentorgoal.
						</p>
						<table className="w-full">
							<thead>
								<tr className="text-left">
									<th>Champ</th>
									<th>Valeur Mentorgoal</th>
									<th>Valeur Gallia</th>
								</tr>
							</thead>
							<tbody>
								{Object.keys(diff).map((key, index) => (
									<tr key={index}>
										<td>{key.replace("_", " ")}</td>
										<td>{diff[key as keyof typeof diff].student}</td>
										<td>{diff[key as keyof typeof diff].gallia}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				}
				buttonsRight={[
					<ButtonSecondary
						label="Annuler"
						onClick={() => {
							onClose();
						}}
					/>,
					<ButtonPrimary
						onClick={() => {
							syncStudentToGallia();
							onClose();
						}}
						label="Confirmer la synchronisation"
					/>,
				]}
			/>
		</div>
	);
}
