import amosLogo from "@assets/logos/amos_logo.webp";
import cmhLogo from "@assets/logos/logo-cmh.webp";
import esdacLogo from "@assets/logos/logo-esdac.webp";
import React from "react";
import { NavLink } from "react-router-dom";

type SchoolLogoAuthType = {
	name: string;
	subdomain: string;
};

export function SchoolLogoAuth({ name, subdomain }: SchoolLogoAuthType) {
	const logo =
		name === "AMOS" ? amosLogo : name === "CMH" ? cmhLogo : esdacLogo;

	return (
		<NavLink
			to={`https://${subdomain}.${import.meta.env.VITE_DOMAIN}`}
			className="mb-md flex flex-col gap-xsm"
		>
			<img
				src={logo}
				alt={name}
				className={
					"w br h-[140px] w-[140px] rounded-full border-[3px] border-white bg-accent-1-lighter shadow-md hover:border-[6px] hover:brightness-105"
				}
			/>
			<span className="text-center font-semibold">{name}</span>
		</NavLink>
	);
}
