import { Icon } from "@iconify/react";
import React from "react";

import { bellOutline, closeOutline } from "@assets/Icons";
import logoLight from "@assets/logos/logoLight.svg";
import tinyLogoLight from "@assets/logos/tinyLogoLight.svg";

import { Avatar } from "@components/atoms/Avatar";

export default function TemplateLayout() {
	return (
		<>
			<div className="menu group w-[72px] hover:ease-in sm:p-0  md:p-sm">
				<div role="menu" className="menu-container">
					<div
						className={"menu group hover:ease-in md:p-sm"}
						onClick={(e: any) => {
							e.stopPropagation();
						}}
					>
						<div className="mb-md flex items-center justify-between">
							<img
								alt="Logo Mentor Goal"
								src={logoLight}
								className="logo-menu mt-xsm max-w-fit"
							/>
							<img
								alt="Logo Mentor Goal"
								src={tinyLogoLight}
								className="tiny-logo-menu mt-xsm max-w-fit"
							/>
							<Icon className="md:hidden" icon={closeOutline} />
						</div>
					</div>
				</div>
			</div>
			<div className="school-header-container hidden pt-lg md:flex md:items-center md:justify-between">
				<div className="alert-items-center flex">
					<Avatar className="no-event" lastname="" firstname="" image="" />
					<div className="ml-md">
						<p className="text-sm font-bold text-primary-700P">promotion</p>
					</div>
				</div>
				<div className="flex items-center">
					<Icon icon={bellOutline} />
					<hr className="vertical-separator" />
					<p className="wg-no-translate school-header-name mr-sm">prénom nom</p>
					<Avatar className="no-event" lastname="" firstname="" image="" />
				</div>
			</div>
		</>
	);
}
