import React, { useState } from "react";

import { convertDateFromNow } from "@tools/Dates";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Success } from "@components/organisms/toast/ToastNotification";

import { PdfViewer } from "@components/atoms/PdfViewer";
import { RefusalModal } from "@containers/school/CVBoard/ui/CVsList/TableView/RefusalModal";

type ToValidateProps = {
	cv: any;
	changeStep: (newStep: string) => void;
	studentIdentity: {
		firstname: string;
		lastname: string;
	};
};
export function ToValidate({
	cv,
	changeStep,
	studentIdentity,
}: ToValidateProps) {
	const [showRefusalModal, setShowRefusalModal] = useState(false);

	return (
		<div>
			<p className="font-weight-font-bold text-sm text-primary-700P">
				{`Le CV a été importé le ${convertDateFromNow(cv.createdAt)}`}
			</p>
			<p className="font-weight-font-bold text-sm text-primary-700P">
				Validation du CV
			</p>

			<div className="mb-md mt-sm flex items-center">
				<ButtonPrimary
					className="w-fit-content"
					label="Valider le CV"
					onClick={() => {
						changeStep("validated");
					}}
				/>
				<ButtonSecondary
					className="w-fit-content ml-sm"
					label="CV à modifier"
					onClick={() => {
						setShowRefusalModal(true);
					}}
				/>
			</div>
			<PdfViewer
				src={`${import.meta.env.VITE_S3}${cv.filePath}`}
				title={`cv_${studentIdentity.firstname}_${studentIdentity.lastname}`}
			/>

			<RefusalModal
				className=""
				cv={cv}
				show={showRefusalModal}
				onClose={() => {
					setShowRefusalModal(false);
				}}
				onSend={() => {
					setShowRefusalModal(false);
					Success("Le CV est à modifier. L'étudiant sera notifié.");
					setTimeout(() => {
						changeStep("editing");
					}, 500);
				}}
			/>
		</div>
	);
}
