import { isValidPhoneNumber } from "react-phone-number-input";

function locationReplaceAce(subdomain: string) {
	location.replace(`https://${subdomain}.${import.meta.env.VITE_DOMAIN}`);
}

export function checkEmail(email: string) {
	return !!email.match(/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/);
}

export function checkLinkedinProfile(linkedin: string) {
	return !!linkedin.match(
		"(https?:\\/\\/(www.)?linkedin.com\\/(mwlite\\/|m\\/)?in\\/[a-zA-Z0-9_.-]+\\/?)",
	);
}
export function checkPhone(phone: string): boolean {
	if (!phone || !phone.length) return false;
	return isValidPhoneNumber(phone);
}

export function checkLinkedinCompany(linkedin: string) {
	return !!linkedin.match(
		"(https?:\\/\\/(www.)?linkedin.com\\/(mwlite\\/|m\\/)?company\\/[a-zA-Z0-9_.-]+\\/?)",
	);
}
export function checkPassword(pw: string, pwVerify?: string) {
	// 8 caractères, 1 maj, 1 min,1 chiffre, 1 caractère spécial
	const missing = [];
	let status = true;
	let missingMessage = "(manquant(s) : ";
	let message = "Votre mot de passe n'est pas conforme ";
	if (pw.length < 8) {
		missing.push("tooShort");
		missingMessage += "minimum 8 caractères";
	}
	if (!/[A-Z]/.test(pw)) {
		missing.push("upper");
		if (missing.length > 1) missingMessage += ", ";
		missingMessage += "lettre majuscule";
	}
	if (!/[a-z]/.test(pw)) {
		missing.push("lower");
		if (missing.length > 1) missingMessage += ", ";
		missingMessage += "lettre minuscule";
	}
	if (!/[0-9]/.test(pw)) {
		missing.push("number");
		if (missing.length > 1) missingMessage += ", ";
		missingMessage += "chiffre";
	}
	if (!/[@\[\]^_!"#$%&'()*+,\-.\\/:;{}<>=|~?]/.test(pw)) {
		missing.push("special");
		if (missing.length > 1) missingMessage += ", ";
		missingMessage += "caractère spécial";
	}
	missingMessage += ")";
	if (missing.length) {
		status = false;
		message += missingMessage;
	} else if (pwVerify && pw !== pwVerify) {
		message = "Vos deux mots de passe ne correspondent pas";
		status = false;
	} else {
		message = "Ok";
		status = true;
	}

	return {
		status,
		missing,
		message,
	};
}

export function checkLinkedinProfil(linkedin: string) {
	return !!linkedin.match(
		"(https?:\\/\\/(www.)?linkedin.com\\/(mwlite\\/|m\\/)?in\\/[a-zA-Z0-9_.-]+\\/?)",
	);
}
export function checkSiret(siret: string) {
	return !!siret.match("^$|^\\d{14}$");
}

export function checkSiren(siren: string) {
	return !!siren.match("^$|^\\d{9}$");
}

export function checkUrl(url: string) {
	const encodeURL = (url: string): string => {
		try {
			const urlObject = new URL(url);
			const pathAndQuery =
				urlObject.pathname.slice(1) + urlObject.search + urlObject.hash;
			const encodedPathAndQuery = encodeURIComponent(pathAndQuery);
			return `${urlObject.protocol}//${urlObject.host}/${encodedPathAndQuery}`;
		} catch (_error) {
			return url;
		}
	};

	const pattern = new RegExp(
		"^(https?:\\/\\/)" +
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
			"((\\d{1,3}\\.){3}\\d{1,3}))" +
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+\\s]*)*" +
			"(\\?[;&a-z\\d%_.~+=-]*)?" +
			"(\\#[-a-z\\d_/]*)?$",
		"i",
	);
	return !!pattern.test(encodeURL(url));
}

export function checkStartUrlAndAutocomplete(url: string): string {
	if (url.startsWith("http://") || url.startsWith("https://")) {
		return url;
	}
	return `https://${url}`;
}

export function checkMicrosoftEmail(
	email: string,
	setIsMicrosoftLogin: Function,
	navigate: Function,
) {
	if (email.includes("amos-") || email.includes("ace-education")) {
		locationReplaceAce("amos");
	} else if (email.includes("esdac-")) {
		locationReplaceAce("esdac");
	} else if (email.includes("cmh-")) {
		locationReplaceAce("cmh");
	} else if (email.includes("esiea-") || email.includes("intechinfo-")) {
		setIsMicrosoftLogin(true);
		navigate("/");
	} else if (email.includes("groupe-igs")) {
		navigate("/login/okta");
	}
	return null;
}

export function checkImage(str: string) {
	const regex = /\.(jpg|jpeg|png)$/;
	return regex.test(str);
}
