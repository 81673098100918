import api from "@tools/API";
import { stringifyRequestFilters } from "@tools/Objects";

export async function getTags(school: string) {
	let rep = {};

	const data = {
		query: `query {
        tags (school: "${school}", itemsPerPage: 1000) {
          collection {
            id
            color
            name
          }
        }
      }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.tags;
	});
	return rep;
}

export async function getPromotions(view: object) {
	let rep = {};

	const data = {
		query: `query {
      promotions (${stringifyRequestFilters(view)}, archived: false, type: "student", itemsPerPage: 1000, order: [{name: "ASC"}]) {
        collection {
          id
          name
          campus {
            isGalliaEnabled
          }
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.promotions;
	});
	return rep;
}
