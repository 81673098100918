import { Icon } from "@iconify/react";
import React from "react";
export const EvaluationRow = ({
	isGlobal = false,
	title,
	evaluation = null,
}: {
	isGlobal?: boolean;
	title: string;
	evaluation?: number | null; // number between 0 and 5 it's the number of stars
}) => {
	return (
		<div className="flex flex-row justify-between w-full border px-8 py-5 rounded-md">
			<div className="flex flex-row gap-4 items-center ">
				<button className="flex justify-center items-center bg-orange-200 w-8 h-8 rounded-md">
					{isGlobal ? (
						<Icon icon="eva:star-fill" className="text-sm text-orange-500" />
					) : (
						<Icon
							icon="eva:arrow-ios-forward-fill"
							className="text-md text-orange-500"
						/>
					)}
				</button>
				<span className="text-sm font-semibold">{title}</span>
			</div>
			{evaluation !== null && (
				<div className="flex flex-row gap-1 items-center">
					{[1, 2, 3, 4, 5].map((star) => {
						return (
							<Icon
								key={star}
								icon="eva:star-fill"
								className={`text-2xl ${
									evaluation >= star ? "text-orange-500" : "text-gray-300"
								}`}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
