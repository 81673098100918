import { useAppSelector } from "@config/redux/hook";
import useDebounce from "@hooks/useDebounce";
import React, { useEffect, useState } from "react";

import { searchFill } from "@assets/Icons";

import { Input } from "@components/molecules/Input";
import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "../core/store/events.slice";

export function EventSearchbar() {
	const dispatchEvent = useAppDispatch();
	const titleToSearch = useAppSelector((state) => state.events.titleToSearch);
	const [search, setSearch] = useState<string>(titleToSearch || "");

	const debouncedValue = useDebounce(search);
	useEffect(() => {
		dispatchEvent(eventsSlice.actions.setTitleToSearch(debouncedValue));
	}, [debouncedValue]);

	return (
		<Input
			placeholder="Rechercher"
			icon={searchFill}
			value={search}
			onChange={(e) => {
				e.target.value = e.target.value.replace(";", "");
				setSearch(e.target.value);
			}}
			className="sm:max-w-[245px]"
		/>
	);
}
