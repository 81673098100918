import React from "react";

type SelectionHeaderTitleProps = {
	companyName: string;
	selectionName: string;
};

export const SelectionHeaderTitle = ({
	companyName,
	selectionName,
}: SelectionHeaderTitleProps) => {
	return (
		<div className="flex flex-col items-center gap-lg w-full justify-center py-10">
			<div className="flex-col items-center gap-sm md:flex-row flex w-full max-w-[1400px] px-10">
				<p className="w-10/12 text-center text-sm md:text-left font-bold">
					{companyName} {selectionName && "-"} {selectionName}
				</p>
			</div>
		</div>
	);
};
