import React, { useContext, useState } from "react";

import { editOutline } from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonQuaternary } from "@components/atoms/ButtonQuaternary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Input } from "@components/molecules/Input";

import { MyProfileContext } from "../MyProfile";

import { EStatus } from "@/types/userTypes";
import {
	updateToastWithError,
	updateToastWithSuccess,
} from "@components/organisms/toast/ToastNotification";
import { GlobalContext } from "@navigation/Router";
import { toast } from "react-toastify";
import { Details } from "./Identity/Detail";
import { StatusSwitcher } from "./Identity/StatusSwitcher";
import { Surrend } from "./Identity/Surrend";

export function Identity() {
	const { profile, setProfile, updateProfileValue, uploadFile } =
		useContext(MyProfileContext);
	const { user, setUser } = useContext(GlobalContext);
	const [isEditingName, setIsEditingName] = useState(false);
	const [profileCopy, setProfileCopy] = useState(profile);
	const [errorAvatar, setErrorAvatar] = useState("");

	const handleOnSuccess = async (file: any) => {
		const toastLoading = toast.loading("Changement d'image de profil...", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		try {
			const avatar = await uploadFile(file, "avatar");
			updateToastWithSuccess(toastLoading, "Modification enregistrée.");
			updateProfileValue({ avatar: avatar["@id"] }, false);
			setProfile({ ...profile, avatar });
			setUser({ ...user, avatar });
		} catch {
			updateToastWithError(
				toastLoading,
				"Erreur lors de la modification de profil.",
			);
		}
	};

	return (
		<div className="flex flex-col gap-sm">
			<div className="flex w-full justify-end">
				{!isEditingName && (
					<ButtonQuaternary
						id="editUserBtn"
						icon={editOutline}
						onClick={() => setIsEditingName(!isEditingName)}
					/>
				)}
			</div>
			<div className="flex flex-col items-center gap-sm">
				<div>
					<Avatar
						className="mx-auto"
						image={profile.avatar?.filePath}
						firstname={profile.firstname}
						lastname={profile.lastname}
						modify
						size="lg"
						onChange={({ file }: { file: any }) => {
							if (file.size > 1000000) {
								setErrorAvatar("Le fichier envoyé dépasse la limite de 1Mo");
							} else {
								setErrorAvatar("");
								handleOnSuccess(file);
							}
						}}
						onDelete={() => {
							updateProfileValue({ avatar: null });
							setProfile({ ...profile, avatar: null });
						}}
					/>
					{errorAvatar && <p className="text-error-dark">{errorAvatar}</p>}
				</div>
				{isEditingName ? (
					<section className="pl-sm pr-sm">
						<div className="mt-sm flex flex-wrap items-center gap-sm">
							<Input
								className="w-full"
								label="Prénom"
								name="firstname"
								value={profile.firstname}
								onChange={(e) => {
									setProfileCopy({ ...profileCopy, firstname: e.target.value });
								}}
							/>
							<Input
								className="w-full"
								label="Nom de famille"
								name="lastname"
								value={profile.lastname}
								onChange={(e) => {
									setProfileCopy({ ...profileCopy, lastname: e.target.value });
								}}
							/>
						</div>
						<div className="flex items-center justify-end">
							<ButtonSecondary
								label="Annuler"
								className="w-fit-content mr-sm"
								onClick={() => {
									setProfileCopy(profile);
									setIsEditingName(!isEditingName);
								}}
							/>
							<ButtonPrimary
								className="w-fit-content mb-sm mt-sm"
								label="Modifier"
								onClick={() => {
									updateProfileValue({
										firstname: profileCopy.firstname,
										lastname: profileCopy.lastname,
									});
									setProfile({ ...profileCopy });
									setIsEditingName(!isEditingName);
								}}
								disabled={
									profileCopy.firstname === "" || profileCopy.lastname === ""
								}
							/>
						</div>
					</section>
				) : (
					<div className="flex flex-col items-center">
						<p className="wg-no-translate font-bold">{`${profile.firstname} ${profile.lastname}`}</p>
						<p className="wg-no-translate text-xsm font-bold text-primary-200 ">
							{profile.spsPromotion?.name}
						</p>
					</div>
				)}
			</div>
			{profile.status === EStatus.SURREND ? <Surrend /> : <StatusSwitcher />}

			<Details />
		</div>
	);
}
