import type { EventsTabLabelType, EventsTabType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import { type AppState, useAppDispatch } from "@config/redux/store";
import { GlobalContext } from "@navigation/Router";
import { mergeCollection } from "@tools/Arrays";
import { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
	getEventsRequest,
	getEventsTabCount,
	getInvitationsEventsCounts,
	getTargetEventRequest,
} from "../core/api/events.request";
import { eventsSlice } from "../core/store/events.slice";

type EventsViewType = {
	title: string;
};

export enum EventsTabLabelEnum {
	planned = "Prévus",
	replay = "Replays",
	finished = "Terminés",
}

export const useEventsView = ({ title }: EventsViewType) => {
	const getTabsData = async () => {
		const { data, responseLabel } = await getEventsTabCount(
			titleToSearch,
			user?.school as string,
		);
		const tabsDate: EventsTabType[] = [];

		if (data && responseLabel === "success") {
			tabsDate.push({
				label: EventsTabLabelEnum.planned,
				counter:
					data.plannedEvents.paginationInfo.totalCount +
					data.plannedMgEvents.paginationInfo.totalCount,
			});
			tabsDate.push({
				label: EventsTabLabelEnum.replay,
				counter:
					data.replayEvents.paginationInfo.totalCount +
					data.replayMgEvents.paginationInfo.totalCount,
			});
			tabsDate.push({
				label: EventsTabLabelEnum.finished,
				counter:
					data.finishedEvents.paginationInfo.totalCount +
					data.finishedMgEvents.paginationInfo.totalCount,
			});
			setTabsCounters(tabsDate);
		}
	};

	const handleSetActiveTabLabel = (label: EventsTabLabelType) => {
		if (label === activeTabLabel) return;
		setActiveTabLabel(label);
		dispatchEvent(actions.setIsFetchingData(true));
	};

	const callEventsFetching = async () => {
		const { data, responseLabel } = await getEventsRequest(
			titleToSearch,
			user?.school as string,
			activeTabLabel,
		);

		if (data && responseLabel === "success") {
			const dataEvents = [
				...data?.events?.collection,
				...data?.mentorgoalEvents?.collection,
			];
			dispatchEvent(
				actions.setEvents({
					events: dataEvents,
					studentId: user?.id || "",
				}),
			);
			dispatchEvent(actions.setIsFetchingData(false));

			if (activeTabLabel === EventsTabLabelEnum.planned) {
				const response = await getInvitationsEventsCounts(
					titleToSearch,
					user?.school as string,
					activeTabLabel,
				);

				if (response.data && response.responseLabel === "success") {
					const countsEvents = [
						...response.data?.events?.collection,
						...response.data?.mentorgoalEvents?.collection,
					];
					const fullDataEvents = mergeCollection(dataEvents, countsEvents);

					dispatchEvent(
						actions.setEvents({
							events: fullDataEvents,
							studentId: user?.id || "",
							tab: activeTabLabel,
						}),
					);
				}
			}
		}
		if (!initialized) {
			dispatchEvent(actions.setInitialized(true));
		}
		getTabsData();
	};

	const callFetchTargetEvent = async (id: string) => {
		dispatchEvent(actions.setIsFetchingTargetEvent(true));
		const isMentorGoalEvent = id.includes("MG");
		const response = await getTargetEventRequest(
			id,
			isMentorGoalEvent,
			user ? user.id : undefined,
		);
		if (response && response.responseLabel === "success") {
			dispatchEvent(
				actions.setEventTarget(
					isMentorGoalEvent
						? response?.data?.mentorgoalEvents
						: response?.data?.schoolEvents,
				),
			);
			setIsTargetEventModalOpen(true);
		}
		dispatchEvent(actions.setIsFetchingTargetEvent(false));
		getTabsData();
	};

	const [tabsCounters, setTabsCounters] = useState<EventsTabType[]>([
		{ label: EventsTabLabelEnum.planned, counter: "..." },
		{ label: EventsTabLabelEnum.replay, counter: "..." },
		{ label: EventsTabLabelEnum.finished, counter: "..." },
	]);

	const [isTargetEventModalOpen, setIsTargetEventModalOpen] =
		useState<boolean>(false);

	const [activeTabLabel, setActiveTabLabel] =
		useState<EventsTabLabelType>("Prévus");

	const { setTitle }: { setTitle: Function } = useOutletContext();
	const { user } = useContext(GlobalContext);

	const {
		titleToSearch,
		events,
		targetEvent,
		performFetchEvents,
		initialized,
	} = useAppSelector((state: AppState) => state.events);

	const dispatchEvent = useAppDispatch();
	const actions = eventsSlice.actions;

	useEffect(() => {
		dispatchEvent(actions.setEvents([]));
		callEventsFetching();
	}, [activeTabLabel, titleToSearch]);

	useEffect(() => {
		if (performFetchEvents) {
			callEventsFetching();
			dispatchEvent(actions.setPerformFetchEvents(false));
		}
	}, [performFetchEvents]);

	useEffect(() => {
		setTitle(title);
	}, []);

	return {
		getTabsData,
		tabsCounters,
		titleToSearch,
		targetEvent,
		events,
		activeTabLabel,
		setActiveTabLabel: handleSetActiveTabLabel,
		handleEventsFetching: callEventsFetching,
		handleFetchTargetEvent: callFetchTargetEvent,
		canShowKnowMore: isTargetEventModalOpen && targetEvent !== null,
		openKnowMoreModal: () => setIsTargetEventModalOpen(true),
		closeKnowMoreModal: () => setIsTargetEventModalOpen(false),
	};
};
