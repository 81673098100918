import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

export function OfferDetailLoader() {
	return (
		<main className="flex flex-col gap-md">
			<div className="rounded-lg bg-white p-md">
				<div className="w-full">
					<Skeleton width="200px" height="20px" />
				</div>
			</div>
			<div className="flex flex-wrap gap-md md:flex-nowrap">
				<div className="w-full rounded-lg bg-white p-md md:w-9/12">
					<Skeleton width="200px" height="20px" />
				</div>
				<div className="w-full rounded-lg bg-white p-md md:w-3/12">
					<Skeleton width="200px" height="20px" />
					<Skeleton width="200px" height="20px" />
					<Skeleton width="200px" height="20px" />
				</div>
			</div>
		</main>
	);
}
