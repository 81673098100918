import type { UserType } from "@/types/userTypes";
import api, { type StandardGraphQLResponse } from "../../../tools/API";

export async function getLogHistory(
	user: UserType,
	filters: { action_list: string[]; page: number },
) {
	let rep = {};
	const page = filters.page ?? 1;
	let actionList = "[";
	filters.action_list.forEach((item, index) => {
		actionList += `"${item}"`;
		if (index < filters.action_list.length - 1) {
			actionList += ",";
		}
	});
	actionList += "]";
	const data = {
		query: `query {
      logs (page: ${page}, order: [{createdAt: "DESC"}], itemsPerPage: 10, spsStudent: "${user.id}", action_list : ${actionList}) {
        collection {
          id
          user: spsStudent {
            id
          }
          action
          createdAt
          description
        },
        paginationInfo {
          lastPage
        }
      }
    }`,
	};
	try {
		await api.post("graphql", data).then(({ data }) => {
			rep = data.data.logs;
		});
		return rep;
	} catch (error) {
		const responseToReturnIfError: StandardGraphQLResponse = {
			responseLabel: "error",
			data: { error },
			message: "Une erreur est survenue",
		};
		throw responseToReturnIfError;
	}
}
