import React from "react";

export const dataAppDetails = {
	modals: {
		accept: {
			title: "Confirmation",
			body: (
				<div>
					<p className="text-xsm text-primary-700P">
						Vous avez indiqué que le profil de cet étudiant est intéressant pour
						l’offre concernée.
					</p>
					<p className="mt-lg text-xsm text-primary-700P">
						Cela signifie que vous allez transmettre sa candidature à votre
						entreprise partenaire. L’étudiant recevera une notification
						d’information.
					</p>
					<p className="mt-lg text-xsm text-primary-700P">
						Voulez-vous confirmer ?
					</p>
				</div>
			),
			cancel: "Non, annuler",
			confirm: "Confirmer",
			confirmValue: "accepted",
		},
		maybe: {
			title: "Message d’information",
			body: (
				<div>
					<p className="text-xsm text-primary-700P">
						Message d’information Vous avez indiqué que le profil de cet
						étudiant est susceptible d’être intéressant pour l’offre concernée.
					</p>
					<p className="mt-lg text-xsm text-primary-700P">
						Cette candidature sera transférée dans l’onglet “Candidatures
						traitées”. L’étudiant n’en sera pas notifié.
					</p>
				</div>
			),
			cancel: "Annuler",
			confirm: "Confirmer",
			confirmValue: "onHold",
		},
		decline: {
			title: "Confirmation",
			body: (
				<div>
					<p className="text-xsm text-primary-700P">
						Vous avez indiqué que le profil de cet étudiant n’est pas
						intéressant pour l’offre concernée.
					</p>
					<p className="mt-lg text-xsm text-primary-700P">
						Cela signifie que vous n’allez pas transmettre sa candidature à
						votre entreprise partenaire. L’étudiant recevera une notification
						d’information.
					</p>
					<p className="mt-lg text-xsm text-primary-700P">
						Voulez-vous confirmer ?
					</p>
				</div>
			),
			cancel: "Non, annuler",
			confirm: "Confirmer",
			confirmValue: "refused",
		},
	},
};
