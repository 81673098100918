import moment from "moment"; /* DEPRECATED, USE DAYJS */
import PropTypes from "prop-types";
import React from "react";

import "@assets/sass/ApplicationItem.scss";

import { getColumnIndex } from "@tools/Applications";

import { Avatar } from "@components/atoms/Avatar";
import { Badge } from "@components/atoms/Badge";
import { ProgressIndicator } from "@components/atoms/Progress";

import CompanyName from "./company/CompanyName";

export function ApplicationItem({
	onClick,
	application,
	companyName,
	className,
	type,
}) {
	const matchString = (app) => {
		switch (app.step) {
			case "interview":
				return `Entretien obtenu le ${moment(
					app.interviewDate || app.createdAt,
					"YYYYMMDD, hh:mm:ss",
				).format("MMMM DD, YYYY")}`;
			case "relaunch":
				return `Relancée le ${moment(
					app.relaunchDate || app.createdAt,
					"YYYYMMDD, hh:mm:ss",
				).format("MMMM DD, YYYY")}`;
			case "sent":
				return `Envoyée le ${moment(
					app.sentDate || app.createdAt,
					"YYYYMMDD, hh:mm:ss",
				).format("MMMM DD, YYYY")}`;
			default:
				return `Crée le ${moment(app.createdAt, "YYYYMMDD, hh:mm:ss").format(
					"MMMM DD, YYYY",
				)}`;
		}
	};
	return (
		<button
			className={`applicationItem flex items-center gap-sm ${className} ${
				onClick && "application"
			}`}
			onClick={onClick}
			type="button"
			key={application.id}
		>
			<Avatar
				image=""
				modify={false}
				firstname={companyName?.split(" ")[0]}
				lastname={companyName?.split(" ")[1]}
				onChange={(e) => {
					setFieldValue("logo", e);
				}}
				size="sm"
				className="hidden md:flex"
			/>
			<div className="w-full">
				<div className="flex w-full items-center justify-between gap-sm">
					<div className="w-full text-left">
						<p className="text-xxxsm font-bold text-primary-300">
							{matchString(application)}
						</p>
						<div className="flex w-full items-center justify-between">
							<p className="wg-no-translate max-w-11/12 truncate text-left text-xsm">
								{type === "offer"
									? `${application.spsStudent.firstname} ${application.spsStudent.lastname}`
									: application.job}{" "}
								-{" "}
								{type === "offer" ? (
									application.job
								) : (
									<CompanyName
										nameToDisplay={companyName}
										nameColor="text-primary-300"
									/>
								)}
							</p>
							<div className="flex gap-xsm items-center">
								{application.offer && (
									<Badge
										label={`${
											(application.offer?.type === "direct" &&
												"Offre direct") ||
											(application.offer?.type === "preselection" &&
												"Offre présélection") ||
											application.offer.type
										}`}
										bgColor={`${
											(application.offer?.type === "direct" &&
												"bg-sunflower-light") ||
											(application.offer?.type === "preselection" &&
												"bg-emerald-light") ||
											"bg-stone-light"
										}`}
									/>
								)}
								{application.state === "refused" && (
									<Badge label="Non retenu" bgColor="bg-error-light" />
								)}
								{application.state === "accepted" && (
									<Badge label="Retenu" bgColor="bg-success-light" />
								)}
							</div>
						</div>
					</div>
				</div>

				<ProgressIndicator
					className="application-progress mt-xsm flex items-center"
					indexActive={getColumnIndex(application.step)}
					steps={[
						{
							label: "Préparation",
							date:
								application.createdAt &&
								moment(application.createdAt).format("Do MMM"),
							accentColor: 1,
							value: "readyToApply",
						},
						{
							label: "Envoyée",
							date:
								application.sentDate &&
								moment(application.sentDate).format("Do MMM"),
							accentColor: 2,
							value: "sent",
						},
						{
							label: "Relancée",
							date:
								application.relaunchDate &&
								moment(application.relaunchDate).format("Do MMM"),
							accentColor: 3,
							value: "relaunch",
						},
						{
							label: "Entretien",
							date:
								application.interviewDate &&
								moment(application.interviewDate).format("Do MMM"),
							accentColor: 4,
							value: "interview",
						},
					]}
				/>
			</div>
		</button>
	);
}

ApplicationItem.propTypes = {
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
	application: PropTypes.shape({
		id: PropTypes.string,
		job: PropTypes.string,
		accepted: PropTypes.bool,
		createdAt: PropTypes.string,
		sentDate: PropTypes.string,
		relaunchDate: PropTypes.string,
		interviewDate: PropTypes.string,
		step: PropTypes.string,
		organization: PropTypes.shape({
			name: PropTypes.string,
			logo: PropTypes.string,
		}),
	}).isRequired,
};

ApplicationItem.defaultProps = {
	className: "",
};
