import type { UserType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { createContext, useContext, useEffect, useState } from "react";

import { eyeOutline } from "@assets/Icons";

import { setCookie } from "@tools/Cookies";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { InlineButton } from "@components/atoms/InlineButton";
import { Modal } from "@components/organisms/modal/Modal";
import { resetUsersFilters } from "@components/organisms/usersFilters/usersFiltersSlice";

import { useAppDispatch } from "@config/redux/store";
import { SelectStudents } from "./SelectStudents";
import { StudentsList } from "./StudentsList";
import { getSchoolCredits } from "./StudentsTargetingModalAPI";
import { YourSelection } from "./YourSelection";

type ViewType = {
	school?: string;
	campus?: string;
};

export type StudentsTargetingModalContextType = {
	selectedStudents: UserType[];
	setSelectedStudents: Function;
	sendEmail: boolean;
	setSendEmail: Function;
	sendSMS: boolean;
	setSendSMS: Function;
	structureView: ViewType;
	totalCount: number;
	setTotalCount: Function;
	setView: Function;
	SMSCredits: number;
	getStudentWithCv: boolean;
	showOptions?: boolean;
};

export const StudentsTargetingModalContext =
	createContext<StudentsTargetingModalContextType>({
		selectedStudents: [],
		setSelectedStudents: () => {},
		sendEmail: true,
		setSendEmail: () => {},
		sendSMS: true,
		setSendSMS: () => {},
		structureView: {},
		totalCount: 1,
		setTotalCount: () => {},
		setView: () => {},
		SMSCredits: 0,
		getStudentWithCv: false,
		showOptions: true,
	});

type StudentsTargetingModalType = {
	show: boolean;
	onClose: Function;
	onClick?: Function;
	structureView: {};
	label: string;
	title: string;
	buttonLabel?: string;
	share: (
		sendEmail: boolean,
		sendSMS: boolean,
		selectedStudents: UserType[],
	) => void;
	showOptions?: boolean;
	getStudentWithCv?: boolean;
};

export function StudentsTargetingModal({
	show,
	onClose,
	structureView,
	share,
	onClick,
	label,
	buttonLabel = "Partager à la sélection",
	title,
	showOptions = true,
	getStudentWithCv = false,
}: StudentsTargetingModalType) {
	const dispatchEvent = useAppDispatch();
	const { user } = useContext(GlobalContext);
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [sendEmail, setSendEmail] = useState(true);
	const [sendSMS, setSendSMS] = useState(false);
	const [totalCount, setTotalCount] = useState(1);
	const [view, setView] = useState<string>("select");
	const [SMSCredits, setSMSCredits] = useState<number>(0);

	const resetModal = () => {
		setView("select");
		setSelectedStudents([]);
		dispatchEvent(resetUsersFilters());
		setSendEmail(true);
		setSendSMS(false);
	};
	const getCredits = async () => {
		if (!user) return;
		const { smsCredits }: { smsCredits?: number } = await getSchoolCredits(
			user.school,
		);

		if (smsCredits) setSMSCredits(smsCredits);
	};

	useEffect(() => {
		if (show) getCredits();
	}, [show]);

	const getButtons = () => {
		return [
			view === "select" ? (
				<InlineButton
					id="students-targeting-modal-view-selection"
					label={`Voir toute la sélection (${selectedStudents.length})`}
					icon={eyeOutline}
					iconPosition="right"
					onClick={() => {
						setView("recap");
					}}
				/>
			) : (
				<ButtonSecondary
					key={0}
					label="Modifier la sélection"
					onClick={() => {
						setView("select");
					}}
				/>
			),
			<ButtonPrimary
				key={1}
				disabled={
					selectedStudents.length === 0 ||
					(sendSMS &&
						selectedStudents.filter((student: UserType) => student.phone)
							.length > SMSCredits) ||
					(sendEmail && selectedStudents.length === 0) ||
					(!sendEmail && !sendSMS)
				}
				label={buttonLabel}
				onClick={() => {
					setCookie(
						"previousStudentsSelection",
						JSON.stringify(selectedStudents),
					);

					share(sendEmail, sendSMS, selectedStudents);
					if (onClick) onClick();
					resetModal();
					onClose();
				}}
			/>,
		];
	};

	return (
		<StudentsTargetingModalContext.Provider
			value={{
				selectedStudents,
				setSelectedStudents,
				sendEmail,
				setSendEmail,
				sendSMS,
				setSendSMS,
				structureView,
				totalCount,
				setTotalCount,
				setView,
				SMSCredits,
				getStudentWithCv,
				showOptions,
			}}
		>
			<Modal
				show={show}
				size={view === "select" ? "lg" : "md"}
				onClose={() => {
					resetModal();
					onClose();
				}}
				title={title}
				body={
					view === "select" ? (
						<div className="flex">
							<SelectStudents label={label} />
							<StudentsList />
						</div>
					) : (
						<YourSelection />
					)
				}
				buttonsRight={getButtons()}
			/>
		</StudentsTargetingModalContext.Provider>
	);
}
