import type { ViewType } from "@/types/genericType";
import { useAppSelector } from "@config/redux/hook";
import { fetchReferents } from "@containers/school/Referents/core/usecases/fetchReferents.usecase";
import { GlobalContext } from "@navigation/Router";
import { extractIdNumberFromId } from "@tools/Users";
import { useContext, useEffect } from "react";
import { useOutletContext } from "react-router";

const PAGE_TITLE = "Gestion des entreprises";

export const useCompaniesBoard = () => {
	const {
		structureView,
		setTitle,
	}: { structureView: ViewType; setTitle: Function } = useOutletContext();

	const { user } = useContext(GlobalContext);

	const { loadingStates, filteredCompaniesList } = useAppSelector(
		(state) => state.companiesManagement,
	);

	const retrieveAllReferents = async () => {
		const campusId = user?.campuses;
		const campusList = [];
		if (campusId) {
			campusList.push(extractIdNumberFromId(campusId[0].id).toString());
		}
		dispatchEvent(fetchReferents(campusList, `${user?.school}`) as any);
	};

	useEffect(() => {
		setTitle(PAGE_TITLE);
	}, []);

	useEffect(() => {
		// Pre-fetch referents for the creation modal
		retrieveAllReferents();
	}, [user]);

	const tablesCanBeShown = (): boolean => {
		return (
			structureView &&
			filteredCompaniesList &&
			filteredCompaniesList?.length > 0
		);
	};
	return {
		canShowTables: tablesCanBeShown(),
		fetchingCompanies: loadingStates?.isFetchingCompanies,
	};
};
