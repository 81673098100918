import { Icon } from "@iconify/react";
import React, { useState } from "react";

import { externalLinkOutline } from "@assets/Icons";

import { CellAvatarLabelSecondary } from "@components/molecules/CellAvatarLabelSecondary";
import { PaginationPrimary } from "@components/molecules/PaginationPrimary";
import { Table } from "@components/templates/table/Table";

import { ContactDetailModal } from "./TableView/ContactDetailModal";

export function TableView({ load, contacts, lastPage, filtersProps }) {
	const [currentContact, setCurrentContact] = useState({});
	const [displayContactDetailModal, setDisplayContactDetailModal] =
		useState(false);
	const { filters, setFilters } = filtersProps;

	const headers = [
		{
			label: "Contact",
			sort: {
				fieldName: "lastname",
				defaultOrder: "ASC",
			},
		},
		{
			label: "Poste",
			sort: {
				fieldName: "job",
				defaultOrder: "ASC",
			},
		},
		{
			label: "Entreprise",
		},
		{
			label: "Téléphone",
		},
		{
			label: "Email",
		},
		{
			label: "LinkedIn",
		},
	];

	const cellsResolver = (contact) => {
		return [
			{
				children: (
					<CellAvatarLabelSecondary
						label={`${contact.lastname} ${contact.firstname}`}
						firstname={contact.firstname}
						lastname={contact.lastname}
						avatar={contact.avatar}
					/>
				),
			},
			{
				children: contact.job,
			},
			{
				children: `${
					contact.applications.collection[0]
						? contact.applications.collection[0].organization?.name
						: "Non renseignée"
				}`,
			},
			{
				children: `${
					contact.phone.length > 0 ? contact.phone.join(", ") : "Non renseigné"
				}`,
			},
			{
				children: contact.email ? contact.email : "Non renseigné",
			},
			{
				onClick: () => {
					if (contact.linkedin) {
						window.open(contact.linkedin, "_blank");
					}
				},
				children: (
					<div>
						{contact.linkedin && <Icon icon={externalLinkOutline} />}
						<p>{contact.linkedin ? "Voir le profil" : "Non renseigné"}</p>
					</div>
				),
			},
		];
	};

	const getEmptyMessage = () => {
		return contacts?.length === 0
			? "Aucun contact pour l’instant.\n Dès lors que vos étudiants auront ajouté des contacts à leurs candidatures, ils apparaîtront ici."
			: "Aucun résultat ne correspond à votre recherche.";
	};

	return (
		<div className="col-span-4 md:col-span-8 lg:col-span-12">
			<Table
				isLoading={load}
				emptyMessage={
					(contacts === null || contacts.length === 0) && getEmptyMessage()
				}
				className="mt-lg"
				headers={headers}
				filtersProps={{ filters, setFilters }}
				rows={contacts.map((contact) => {
					return {
						onClick: () => {
							setDisplayContactDetailModal(!displayContactDetailModal);
							setCurrentContact(contact);
						},
						cells: cellsResolver(contact),
					};
				})}
			/>
			<PaginationPrimary
				onChange={(page) => {
					setFilters({ ...filters, page });
				}}
				current={filters.page}
				className="pagination-container pagination-list flex w-full items-center justify-center"
				last={lastPage}
			/>

			<ContactDetailModal
				contactProps={{ currentContact, setCurrentContact }}
				displayProps={{
					displayContactDetailModal,
					setDisplayContactDetailModal,
				}}
			/>
		</div>
	);
}
