import type { ViewType } from "@/types/genericType";

import { DEFAULT_MAX_DISTANCE } from "@constants/Location";

import api, {
	checkGraphQLError,
	graphqlRequestConstructor,
	type StandardGraphQLResponse,
} from "@tools/API";
import {
	stringifyRequestFilters,
	stringifyRequestInputs,
} from "@tools/Objects";

import { EState, EStatus, EStatusState } from "@/types/userTypes";
import type { structureViewType } from "@containers/school/CVBoard";

export async function getStudentsManagementData(
	formatView: ViewType,
	filters: any,
) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters?.birthdate?.value;
	const isWaitApproval = filters?.state === EState.WAIT_APPROVAL;
	if (isWaitApproval) {
		filters = {
			page: filters.page,
			status_list: [
				EStatus.TOPLACE,
				EStatus.PLACED,
				EStatus.BEING_SIGNED,
				EStatus.NOT_SEARCHING,
				EStatus.SURREND,
			],
			order: filters.order,
			studentStatusState: EStatusState.REQUESTED,
			search: filters.search,
		};
	}
	const cleanFilters = { ...filters };
	if (cleanFilters.location) {
		cleanFilters.location = undefined;
	}
	if (cleanFilters.birthdate) {
		const cleanBirthdate = {
			...cleanFilters.birthdate.value,
		};
		cleanFilters.birthdate = cleanBirthdate;
	}
	if (birthdateExists) {
		cleanFilters.birthdate = undefined;
	}
	for (const key of Object.keys(cleanFilters)) {
		if (cleanFilters[key] === undefined) {
			delete cleanFilters[key];
		}
	}
	if (filters.position && !filters.max_distance) {
		cleanFilters.max_distance = DEFAULT_MAX_DISTANCE;
	}
	const filtersCpy = stringifyRequestFilters({
		...formatView,
		...cleanFilters,
	});
	cleanFilters.page = undefined;
	const queryToSend = {
		query: `query {
        users: collectionQuerySpsStudents (${
					birthdateExists ? "exists: {birthdate: false}" : ""
				} ${filtersCpy}){
          collection {
            id
            firstname
            lastname
            postalCode
            city
            status
            studentStatus
            studentStatusState
            studentStatusRequestTime
            lastConnexion
            createdAt
            applicationsCount
            interviewsCount
            relaunchsCount
            dateOfInvitation
            avatar{
              filePath
            }
            campus: coreCampus {
              isGalliaEnabled
            }
            promotion: spsPromotion {
                name
            }
          },
          paginationInfo {
            totalCount
            lastPage
          }
        },
      }`,
	};

	const rep = graphqlRequestConstructor(
		queryToSend,
		"getStudentsManagementData",
		"Récupération des étudiants impossible",
	);
	return rep;
}

export async function getStudentsToRelaunch(
	formatView: ViewType,
	filters: any,
) {
	const birthdateExists =
		filters?.birthdate?.value && "exists" in filters.birthdate.value;

	const isWaitApproval = filters?.state === EState.WAIT_APPROVAL;
	if (isWaitApproval) {
		filters = {
			page: filters.page,
			status_list: [
				EStatus.TOPLACE,
				EStatus.PLACED,
				EStatus.BEING_SIGNED,
				EStatus.NOT_SEARCHING,
				EStatus.SURREND,
			],
			studentStatusState: EStatusState.REQUESTED,
			search: filters.search,
		};
	}
	const cleanFilters = { ...filters };
	if (cleanFilters.location) {
		delete cleanFilters.location;
	}
	if (cleanFilters.birthdate) {
		const cleanBirthdate = {
			...cleanFilters.birthdate.value,
		};
		cleanFilters.birthdate = cleanBirthdate;
	}
	if (birthdateExists) {
		cleanFilters.birthdate = undefined;
	}
	for (const key of Object.keys(cleanFilters)) {
		if (cleanFilters[key] === undefined) {
			delete cleanFilters[key];
		}
	}
	if (filters.position && !filters.max_distance) {
		cleanFilters.max_distance = DEFAULT_MAX_DISTANCE;
	}
	const filtersCpy = stringifyRequestFilters({
		...formatView,
		...cleanFilters,
	});
	cleanFilters.page = undefined;
	const queryToSend = {
		query: `query {
        users: collectionQueryUsers (${
					birthdateExists ? "exists: {birthdate: false}" : ""
				} ${filtersCpy}, dateOfInvitation: {before: "${new Date().toISOString()}"}){
          collection {
            id
          }
        },
      }`,
	};

	const rep = graphqlRequestConstructor(
		queryToSend,
		"getStudentsToRelaunch",
		"Récupération des étudiants impossible",
	);
	return rep;
}

export async function getAllStudentsTabsData(filters: any) {
	let rep = {};

	if (filters.page) filters.page = undefined;

	const data = {
		query: `query {
      users: collectionQuerySpsStudents(itemsPerPage: 100000) {
        collection {
            id
            firstname
            lastname
            postalCode
            city
            status
            lastConnexion
            createdAt
            applicationsCount
            interviewsCount
            relaunchsCount
            dateOfInvitation
            avatar{
              filePath
            }
            promotion: spsPromotion  {
                name
            }
          },
          paginationInfo {
            totalCount
            lastPage
          }
        },
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getActiveStudentsTabsData(view: any, filters: any) {
	let rep = {};

	const filtersCpy = stringifyRequestFilters({ ...view, ...filters });

	filters.page = undefined;

	const data = {
		query: `query {
      users: collectionQuerySpsStudents (${filtersCpy}){
        collection {
          id
          firstname
          lastname
          status
          lastConnexion
          avatar{
            filePath
          }
          applicationsCount
          relaunchsCount
          interviewsCount
          promotion: spsPromotion  {
              name
          },
        },
        paginationInfo {
          totalCount
          lastPage
        }
      }
 
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getWaitingStudentsTabsData(view: any, filters: any) {
	let rep = {};

	const filtersCpy = stringifyRequestFilters({ ...view, ...filters });

	delete filters.page;

	const data = {
		query: `query {
      users: collectionQuerySpsStudents (${filtersCpy}){
        collection {
          id
          firstname
          lastname
          status
          dateOfInvitation
          avatar{
            filePath
          }
          promotion: spsPromotion  {
              name
          },
        },
        paginationInfo {
          totalCount
          lastPage
        }
      }
    }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function editUser(inputs: any) {
	let rep = {};
	const data = {
		query: `mutation {
            updateSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
                user: spsStudent {
                    id
                }
            }
        }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateSpsStudent.user;
	});
	return rep;
}

export async function createUser(inputs: any) {
	const data = {
		query: `mutation {
            createSpsStudent(input: ${stringifyRequestInputs(inputs)}) {
            user: spsStudent {
                id
          	  }
            }
        }`,
	};
	const rep: StandardGraphQLResponse = await api.post("graphql", data);
	return rep;
}

export async function uploadStudents(formdata: any, onProgress: Function) {
	let rep = {};
	const config = {
		onUploadProgress: (progressEvent: any) => {
			const progress = (progressEvent.loaded / progressEvent.total) * 100;
			if (onProgress) {
				onProgress(progress);
			}
		},
	};
	await api.post("import_students_csv", formdata, config).then(({ data }) => {
		rep = data;
	});
	return rep;
}

export async function relaunchStudent(ids: string[]) {
	try {
		const response = await api.post("/relaunch_students", ids);
		if (response.status === 200) {
			return {
				responseLabel: "success",
				message: "Les relances ont bien été envoyées",
				data: response.data,
			};
		}
		return {
			responseLabel: "error",
			message: "Erreur lors de l'envois des relances",
			data: {},
		};
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function exportStudentsCsv(
	structureView: structureViewType,
	filters: any,
) {
	try {
		const data = await api.post("export_students_csv", {
			...structureView,
			...filters,
		});
		return data;
	} catch (error) {
		throw new Error(error);
	}
}

export async function getSchoolSmsCredits(schoolId: string) {
	let rep = {};

	const data = {
		query: `query {
      school(id: "${schoolId}"){
            id
            smsCredits
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data;
	});
	return rep;
}

export async function getPromotions(view: any) {
	let rep = {};

	const data = {
		query: `query {
      promotions (${stringifyRequestFilters(view)}, archived: false, type: "student", itemsPerPage: 1000, order: [{name: "ASC"}]) {
        collection {
          id
          name
          campus {
            isGalliaEnabled
          }
        }
      }
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.promotions;
	});
	return rep;
}

export async function getStudents(view: any, name: string) {
	try {
		const data = {
			query: `query {
        users: collectionQuerySpsStudents (search: "${name}", ${view}, page: 1, itemsPerPage: 5) {
          collection {
            id
            firstname
            lastname
            avatar {
              filePath
            }
          }
        },
      }`,
		};
		const response = await api.post("graphql", data);
		return checkGraphQLError(
			response,
			"Erreur lors de l'envois des invitations",
		);
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}

export async function getRequestedUsersCount(searchFilter: any) {
	const filtersFinal = {
		...searchFilter,
		spsPromotion_archived: false,
		status_list: [
			EStatus.TOPLACE,
			EStatus.PLACED,
			EStatus.BEING_SIGNED,
			EStatus.NOT_SEARCHING,
			EStatus.SURREND,
		],
		studentStatusState: EStatusState.REQUESTED,
	};
	try {
		const data = {
			query: `query {
        users: collectionQuerySpsStudents (${stringifyRequestFilters(
					filtersFinal,
				)}) {
          paginationInfo {
            totalCount
          }
        },
      }`,
		};
		const response = await api.post("graphql", data);
		return response.data.data.users.paginationInfo.totalCount;
	} catch (error) {
		const responseToReturnIfError = {
			responseLabel: "error",
			message: error,
			data: {},
		};
		return responseToReturnIfError;
	}
}
