import type { UserType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, useParams } from "react-router";
import { toast } from "react-toastify";

import { Spinner } from "@components/atoms/Spinner";
import {
	updateToastWithError,
	updateToastWithSuccess,
} from "@components/organisms/toast/ToastNotification";
import { StudentsTargetingModal } from "@components/templates/studentsTargetingModal";

import { updateShareOffer } from "../core/api/offerDetails.request";
import {
	setCurrentDetailTabSelected,
	setSendRelaunchInvitationWasClicked,
	setUserWasInEditionPage,
} from "../core/store/offersManagement.slice";
import {
	fetchOfferDetails,
	fetchOffersApplicationsThumbnails,
} from "../core/store/offersManagement.thunks";
import { InformationsTab } from "../ui/details/InformationsTab";
import OfferDetailsHeader from "../ui/details/OfferDetailsHeader";
import { OfferDetailsStatsRow } from "../ui/details/OfferDetailsStatsRow";
import OfferDetailsTabs from "../ui/details/OfferDetailsTabs";
import { ShipmentTrackingCard } from "../ui/details/ShipmentTrackingCard";
import { ApplicationsTab } from "../ui/details/tabs/applications/ApplicationsTab";
import { SendToCompanyTab } from "../ui/details/tabs/sendToCompany/SendToCompanyTab";

export function OffersDetailsView() {
	const {
		setTitle,
		structureView,
	}: { setTitle: Function; structureView: object } = useOutletContext();

	const dispatchEvent = useDispatch();
	const {
		offerToEdit,
		currentDetailTabSelected,
		loadingStates,
		sendRelaunchInvitationWasClicked,
	} = useAppSelector((state) => state.offers);
	const { id } = useParams<{ id: string }>();

	const loadOfferToEdit = async () => {
		if (!id) return;
		await dispatchEvent(
			fetchOfferDetails({ id: `/referent/offers/${id}` || "" }) as any,
		);
	};

	const shareOffer = async (
		sendEmail: boolean,
		sendSMS: boolean,
		selectedStudents: any,
	) => {
		const toastId = toast.loading("Envoi des invitations...", {
			isLoading: true,
			hideProgressBar: false,
			type: "info",
		});
		const data = {
			offerId: offerToEdit?.id || "",
			sendEmail,
			sendSMS,
			students: selectedStudents.map((user: Partial<UserType>) => user.id),
		};
		const response = await updateShareOffer(data);
		if (response.responseLabel === "success") {
			updateToastWithSuccess(toastId, "Invitations envoyées");
			dispatchEvent(
				fetchOfferDetails({ id: `/referent/offers/${id}` || "" }) as any,
			);
		} else {
			updateToastWithError(
				toastId,
				"Une erreur est survenue lors de l'envoi des invitations",
			);
		}
		return [sendEmail, sendSMS, selectedStudents];
	};

	const handleCloseTargetingModal = () => {
		dispatchEvent(setSendRelaunchInvitationWasClicked(false));
	};

	useEffect(() => {
		setTitle("Détails de l'offre");
		dispatchEvent(setCurrentDetailTabSelected("offer"));

		loadOfferToEdit();

		if (offerToEdit?.id) {
			dispatchEvent(fetchOffersApplicationsThumbnails(offerToEdit?.id) as any);
		}
		dispatchEvent(setUserWasInEditionPage(true));
	}, []);

	useEffect(() => {
		if (offerToEdit?.id) {
			dispatchEvent(fetchOffersApplicationsThumbnails(offerToEdit?.id) as any);
		}
	}, [offerToEdit]);

	return (
		<main data-testid="offersDetailsView" className="flex flex-col gap-md">
			{!offerToEdit || loadingStates.isFetchingOfferDetails ? (
				<div className="flex h-[70vh] w-full flex-col items-center justify-center gap-6">
					<p className="text-base font-bold text-primary-700P">
						Chargement de l'offre
					</p>
					<Spinner />
				</div>
			) : (
				<>
					<div className="flex flex-col gap-sm">
						<OfferDetailsHeader />
						<OfferDetailsStatsRow />
					</div>
					<div className="flex flex-col gap-sm">
						<OfferDetailsTabs />
						{currentDetailTabSelected === "offer" && (
							<InformationsTab offerToEdit={offerToEdit} />
						)}
						{currentDetailTabSelected === "applications" && (
							<ApplicationsTab offerToEdit={offerToEdit} />
						)}
						{currentDetailTabSelected === "sendToCompany" && (
							<>
								<ShipmentTrackingCard />
								<SendToCompanyTab offerToEdit={offerToEdit} />
							</>
						)}
					</div>
					<StudentsTargetingModal
						show={sendRelaunchInvitationWasClicked || false}
						onClose={() => {
							handleCloseTargetingModal();
						}}
						structureView={structureView}
						label="Vous pouvez sélectionner des étudiants à qui partager l’offre pour les notifier."
						title={`Partager l'offre - ${offerToEdit?.job} chez ${offerToEdit?.crmCompany?.name}`}
						share={shareOffer}
					/>
				</>
			)}
		</main>
	);
}
