import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { referentsSlice } from "../store/referents.slice";

export const fetchReferents =
	(campusList: string[], schoolId: string) =>
	async (
		dispatch: AppDispatch,
		_api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.referentsGateway) {
				throw new Error("referentsGateway is not available in dependencies");
			}
			const referentsList = {
				campusReferents: [],
				schoolReferents: [],
			};
			dispatch(referentsSlice.actions.handleReferentsLoading());
			const result = await dependencies.referentsGateway.getReferents(
				campusList,
				schoolId,
			);
			if (!result) {
				throw new Error("No referents found");
			}
			const { data, responseLabel, message } = result;
			if (responseLabel !== "success") {
				if (responseLabel === "error" && message === "aborted") {
					dispatch(referentsSlice.actions.handleReferentsFetchingAborted());
				} else {
					console.error(" else", message);
					throw new Error(message);
				}
				return;
			}

			if (!data) {
				throw new Error("No referents found");
			}

			if (data.campusReferents) {
				referentsList.campusReferents = data.campusReferents?.collection || [];
			}

			if (data.schoolReferents) {
				referentsList.schoolReferents = data.schoolReferents?.collection || [];
			}

			dispatch(
				referentsSlice.actions.modifyReferentsListReceived(referentsList),
			);
		} catch (error: unknown) {
			dispatch(
				referentsSlice.actions.handleReferentsFetchingError(
					extractError(error).message.toString(),
				),
			);
		}
	};
