import React, { useState } from "react";

import "../select/Select.scss";

let uniqueId = 0;

type labelType = {
	onClick: () => void;
	label: string;
};

type SelectModalType = {
	labelLeft?: labelType;
	labelRight?: labelType;
	body: any;
	button1?: any;
	button2?: any;
	title: string;
	subtitle?: string;
	show: boolean;
	className?: string;
	onClick: () => void;
};
export function SelectModal({
	labelLeft,
	labelRight,
	body,
	button1,
	button2,
	title,
	subtitle,
	show,
	className = "",
	onClick,
}: SelectModalType) {
	const [componentId] = useState(() => uniqueId++);

	return (
		<div
			className={`select-modal select-modal-${componentId} ${className} ${
				show ? "show" : ""
			}`}
			onClick={() => {
				onClick();
			}}
		>
			<div className="select-modal-container">
				<div
					className={`select-modal-header flex  justify-between ${className}`}
				>
					{labelLeft && (
						<p
							className="left-label cursor-pointer text-primary-300"
							onClick={labelLeft.onClick}
						>
							{labelLeft.label}
						</p>
					)}
					<p className="title mb-md text-center text-primary-900">
						{title} <span className="text-primary-200">{subtitle}</span>
					</p>
					{labelRight && (
						<p
							onClick={labelRight.onClick}
							className="right-label cursor-pointer text-primary-300"
						>
							{labelRight.label}
						</p>
					)}
				</div>
				<div className="select-modal-body">{body}</div>
				<div className={`select-modal-footer ${className}`}>
					<div className="w-full">{button1}</div>
					<div className="w-full">{button2}</div>
				</div>
			</div>
		</div>
	);
}
