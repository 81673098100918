import { Icon } from "@iconify/react";
import { GlobalContext } from "@navigation/Router";
import React from "react";
import { useContext, useState } from "react";

import { closeOutline, moonOutline, sunOutline } from "@assets/Icons";

import { ButtonQuinary } from "@components/atoms/ButtonQuinary";
import { ScrollableTabGroup } from "@components/molecules/TabGroup";

import { Actions } from "./ui/Tabs/Actions";
import { Logs } from "./ui/Tabs/Logs";
import { Notifications } from "./ui/Tabs/Notifications";

export function SlidingPaneContent({
	setSlidingPaneIsOpen,
}: { setSlidingPaneIsOpen: (isOpen: boolean) => void }) {
	const [tab, setTab] = useState("actions");
	const currentHour = new Date().getHours();
	const { user } = useContext(GlobalContext);

	const renderBodyPage = () => {
		switch (tab) {
			case "notifications":
				return <Notifications />;
			case "logs":
				return <Logs />;
			default:
				return <Actions setSlidingPaneIsOpen={setSlidingPaneIsOpen} />;
		}
	};

	return (
		<div className="h-full p-sm md:px-xlg no-print">
			<div className="mb-md flex justify-end">
				<ButtonQuinary
					className=""
					disabled={false}
					shape="rectangle"
					dropdownButton={false}
					notifCount={0}
					icon={closeOutline}
					onClick={() => {
						setSlidingPaneIsOpen(false);
					}}
				/>
			</div>
			<div className="mx-xxsm h-[70vh]">
				<div className="flex w-full  justify-center text-md text-primary-700P">
					{currentHour >= 4 && currentHour <= 17 ? (
						<div className="flex items-center">
							<p>
								Bonjour{" "}
								<span className="wg-no-translate">{user?.firstname}</span>
							</p>
							<Icon icon={sunOutline} className="ml-xsm text-accent-2" />
						</div>
					) : (
						<div className="flex items-center">
							<p>
								Bonsoir{" "}
								<span className="wg-no-translate">{user?.firstname}</span>
							</p>
							<Icon icon={moonOutline} className="ml-xsm text-accent-2" />
						</div>
					)}
				</div>

				<ScrollableTabGroup
					tabs={[
						{ label: "À faire", active: tab === "actions" },
						{ label: "Notifications", active: tab === "notifications" },
						{ label: "Mon historique", active: tab === "logs" },
					]}
					className="mt-lg [&>div]:w-4/12"
					onTabChange={(i) => {
						switch (i) {
							case 1:
								setTab("notifications");
								break;
							case 2:
								setTab("logs");
								break;
							default:
								setTab("actions");
						}
					}}
				/>
				<div className="mt-md  h-full">{renderBodyPage()}</div>
			</div>
		</div>
	);
}
