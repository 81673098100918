import type { EventInvitationType } from "@/types/eventsInvitationType";
import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { type StandardGraphQLResponse, extractError } from "@tools/API";
import { eventsSlice } from "../store/events.slice";

type FetchEventReplies = {
	event: {
		invitations: {
			collection: EventInvitationType[];
		};
	};
};

/**
 * Guard function for the FetchEventReplies type
 * @param data
 * @returns
 */
function isFetchEventReplies(data: unknown): data is FetchEventReplies {
	const d = data as FetchEventReplies;
	return (
		d !== null &&
		typeof d === "object" &&
		"event" in d &&
		typeof d.event === "object" &&
		"id" in d.event &&
		typeof d.event.id === "string" &&
		"invitations" in d.event &&
		typeof d.event.invitations === "object" &&
		"collection" in d.event.invitations &&
		Array.isArray(d.event.invitations.collection)
	);
}

export const fetchEventReplies =
	(eventId: string, isMentorGoalEvent: boolean, currentTab: number) =>
	async (
		dispatch: AppDispatch,
		_api: AppGetState,
		dependencies: Dependencies,
	) => {
		try {
			if (!dependencies.eventsGateway) {
				throw new Error("eventsGateway is not available in dependencies");
			}
			dispatch(eventsSlice.actions.handleRepliesLoading());
			const fetchingResponse: StandardGraphQLResponse =
				await dependencies.eventsGateway.getEventReplies(
					eventId,
					isMentorGoalEvent,
					currentTab,
				);
			if (
				!fetchingResponse ||
				fetchingResponse.responseLabel !== "success" ||
				!fetchingResponse.data
			) {
				throw new Error("Failed to fetch replies");
			}

			const rawData: unknown = fetchingResponse.data;

			if (isFetchEventReplies(rawData)) {
				const replies: EventInvitationType[] = rawData.event.invitations
					.collection as EventInvitationType[];
				dispatch(eventsSlice.actions.handleRepliesSuccess(replies));
			} else {
				throw new Error("Invalid data format");
			}
		} catch (error: unknown) {
			dispatch(
				eventsSlice.actions.handleRepliesError(
					extractError(error).message.toString(),
				),
			);
		}
	};
