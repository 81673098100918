import React from "react";

type FormFooterProps = {
	formStep: number;
	goBack: () => void;
	formTotalSteps: number;
	errors: object;
};

export const FormFooter = ({
	formStep,
	goBack,
	formTotalSteps,
	errors,
}: FormFooterProps) => {
	const isDisabled = Object.keys(errors).length > 0;
	return (
		<div
			className={`border-t border-gray-200 p-4 px-6 w-full flex ${
				formStep > 0 ? "justify-between" : "justify-end"
			}`}
		>
			{formStep > 0 && (
				<button
					type={"button"}
					onClick={goBack}
					className="bg-gray-200 text-black rounded-sm py-2 px-4 font-bold mr-4"
				>
					Précédent
				</button>
			)}
			<button
				type={"submit"}
				disabled={isDisabled}
				className={`${
					isDisabled ? "bg-gray-400" : "bg-indigo-600"
				} text-white rounded-sm py-2 px-4 font-bold`}
			>
				{formStep === formTotalSteps - 1 ? "Envoyer" : "Suivant"}
			</button>
		</div>
	);
};
