import React from "react";
import Skeleton from "react-loading-skeleton";

import { WhiteCard } from "@components/molecules/WhiteCard";

export function StatCardLoader() {
	return (
		<WhiteCard
			size="small"
			className="w-full"
			titleColor="accent-1-dark"
			content={
				<div>
					<Skeleton className="!w-10/12" height={24} />
					<div className="mt-xsm flex h-full w-full items-center gap-sm">
						<Skeleton width={30} height={40} />
						<Skeleton height={16} width={80} />
					</div>
				</div>
			}
		/>
	);
}
