export function mergeArray(array: Array<Array<any>>) {
	const arrayCopy = [...array];
	const finalArray: Array<any> = [];

	arrayCopy.map((array) => {
		return array.map((value) => {
			return finalArray.push(value);
		});
	});
	return finalArray;
}

export function getRandomItem(array: Array<any>) {
	const random = Math.floor(Math.random() * array.length);

	return array[random];
}

export const isArraysEquals = (array1: Array<any>, array2: Array<any>) => {
	if (!Array.isArray(array1) || !Array.isArray(array2)) {
		return false;
	}
	const array2Sorted = array2.slice().sort();

	const isEquals = array1
		.slice()
		.sort()
		.every((value, index) => value === array2Sorted[index]);

	if (array1.length === array2.length && isEquals) {
		return true;
	}

	return false;
};

export function flattenArray(array: Array<any>) {
	return [].concat(...array);
}
export function flattenArrayLength(array: Array<any>): number {
	return [].concat(...array).length;
}

/**
 * Format options to be used in a multi select component
 * @param options
 * @returns an array of objects with the following structure: { label: string, value: string, type: string, checked: boolean }
 */
export const formatOptionsToMultiSelect = (options: any) => {
	const array: any = [];

	if (!options) {
		return [];
	}
	options?.forEach((option: any) => {
		array.push({
			...option,
			label: option.name,
			value: option.name,
			type: "checkbox",
			checked: false,
		});
	});

	return array;
};

export const mergeCollection = (
	collection1: Array<any>,
	collection2: Array<any>,
) => {
	const mergedCollection: any[] = [];

	collection1.forEach((item, index) => {
		mergedCollection.push({ ...item, ...collection2[index] });
	});

	return mergedCollection;
};
