import { arrowForwardOutline } from "@assets/Icons";
import { InlineButton } from "@components/atoms/InlineButton";
import { ErrorToast } from "@components/organisms/toast/ToastNotification";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import { logout } from "@tools/Auth";
import React, { useContext } from "react";

export function LogoutBtn() {
	const { user } = useContext(GlobalContext);
	const { school } = useAppSelector((state) => state.company);
	return (
		user && (
			<div className="flex w-full">
				<p className="mx-auto my-sm flex text-center">
					Vous n'êtes pas {user.firstname} ?
					<InlineButton
						className="ml-sm"
						onClick={async () => {
							try {
								await logout(
									false,
									`/company/login?${school?.subdomain || ""}`,
								);
							} catch {
								ErrorToast("Erreur lors de la déconnexion");
							}
						}}
						icon={arrowForwardOutline}
						label="Se déconnecter"
					/>
				</p>
			</div>
		)
	);
}
