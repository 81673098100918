import React from "react";

import { ColumnLoading } from "./ColumnLoading";
const getRandomNumber = () => Math.floor(Math.random() * 10) + 1;
const LOADING_COLUMNS = [
	{ backgroundColor: "bg-red-200", numberOfCards: getRandomNumber() },
	{ backgroundColor: "bg-gray-200", numberOfCards: getRandomNumber() },
	{ backgroundColor: "bg-green-200", numberOfCards: getRandomNumber() },
	{ backgroundColor: "bg-blue-200", numberOfCards: getRandomNumber() },
	{ backgroundColor: "bg-purple-200", numberOfCards: getRandomNumber() },
	{ backgroundColor: "bg-gray-200", numberOfCards: getRandomNumber() },
];

export const KanbanLoading = () => {
	return (
		<div className="flex flex-1 flex-row gap-4 overflow-x-auto">
			{LOADING_COLUMNS.map((column, index) => (
				<ColumnLoading
					key={index}
					backgroundColor={column.backgroundColor}
					numberOfCards={column.numberOfCards}
				/>
			))}
		</div>
	);
};
