import React from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { Modal } from "@components/organisms/modal/Modal";

type RefusedApplicationModalProps = {
	onClose: () => void;
	messages: string[];
	show: boolean;
};
export function RefusedApplicationModal({
	onClose,
	messages,
	show,
}: RefusedApplicationModalProps) {
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title="Motif de refus"
			body={
				<div>
					<p className="text-xsm text-primary-200">
						Message envoyé par l&apos;école :
					</p>
					<p className="mt-xsm text-primary-700P">{messages?.[0]}</p>
				</div>
			}
			buttonsRight={[
				<ButtonPrimary
					key={1}
					onClick={(e) => {
						e.stopPropagation();
						onClose();
					}}
					label="Fermer"
				/>,
			]}
		/>
	);
}
