import { Icon } from "@iconify/react";
import React from "react";

import { arrowForwardFill, bulbOutline } from "@assets/Icons";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
export default function Content() {
	return (
		<div className="shadow rounded-lg overflow-hidden bg-white  mb-sm mt-sm flex flex-col sm:ml-sm sm:mr-sm md:ml-sm md:mr-sm lg:ml-0 lg:mr-0 lg:w-6/12">
			<iframe
				className="h-full"
				src="https://www.youtube.com/embed/wy23Uw8uVm0"
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			/>
			<div className="content-text mb-auto  mt-lg pl-sm pr-sm">
				<h3 className="text-primary-700P">
					Le coach digital pour réussir tes entretiens !
				</h3>
				<p className="mt-sm text-primary-700P">
					Une carrière, ça se construit. Accède à une bibliothèque de questions
					d’entretien par métier, des conseils et vidéos de recruteurs pour
					décrocher le job que tu vises !{" "}
				</p>
				<div className="mt-sm flex items-center">
					<Icon className="text-md text-secondary-500P" icon={bulbOutline} />
					<p className="ml-sm text-primary-700P">
						Utilise le code <b>PREPxMG23</b> quand tu crées ton compte. *
					</p>
				</div>
				<ButtonPrimary
					icon={arrowForwardFill}
					label="Créer mon compte"
					className="w-fit-content mb-lg mt-md"
					onClick={() => {
						window.open("https://bit.ly/PreperaxMentorgoal", "_blank");
					}}
				/>
			</div>
		</div>
	);
}
