import type { StandardGraphQLResponse } from "@tools/API";
import { fetchGetReferents } from "../api/referents.request";
import type { IReferentGateway } from "../gateway/referent.gateway";

export class InAPIReferentGateway implements IReferentGateway {
	async getReferents(
		campusList: string[],
		schoolId: string,
	): Promise<
		| StandardGraphQLResponse
		| {
				responseLabel: string;
				message: any;
				data: {};
		  }
	> {
		return fetchGetReferents(campusList, schoolId);
	}
}
