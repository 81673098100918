import { GlobalContext } from "@navigation/Router";
import React from "react";
import { useContext, useEffect, useMemo, useState } from "react";

import { extractIdNumberFromId } from "@tools/Users";

import { Avatar } from "@components/atoms/Avatar";
import TooltipContainer from "@components/atoms/TooltipContainer";

import { Dropdown } from "./Dropdown";

export type AvatarType = {
	id: string;
	firstname: string;
	lastname: string;
	filePath?: string;
};

export type AvatarGroupPropsType = {
	avatars: AvatarType[];
	tooltipPrefix?: string;
	ownerTooltip?: string;
	onClickOnUser: (id: string) => void;
	defaultSelectedId?: string;
	shouldBeReset?: boolean;
};

const MAX_AVATARS_TO_SHOW = 6;

export function AvatarGroup(props: AvatarGroupPropsType) {
	const {
		avatars,
		onClickOnUser,
		tooltipPrefix,
		ownerTooltip,
		defaultSelectedId,
	} = props;
	const { user } = useContext(GlobalContext);
	const [indexSelected, setIndexSelected] = useState<number>(-1);
	const [idSelectedOutsideAvatarRow, setIdSelectedOutsideAvatarRow] = useState<
		string | undefined
	>(undefined);
	const [isSelectedOutsideAvatarRow, setIsSelectedOutsideAvatarRow] =
		useState<boolean>(false);

	const resetSelection = () => {
		setIndexSelected(-1);
		setIdSelectedOutsideAvatarRow(undefined);
		setIsSelectedOutsideAvatarRow(false);
	};

	const checkIfThisItemIsSelected = (id: string, outsideRow = false) => {
		if (defaultSelectedId && defaultSelectedId === id) return true;
		if (outsideRow) {
			const result = id === idSelectedOutsideAvatarRow;
			return result;
		}
		return false;
	};
	const dropdownItems = useMemo(
		() =>
			avatars.slice(MAX_AVATARS_TO_SHOW).map((avatar) => ({
				label: `${avatar.firstname} ${avatar.lastname}`,
				color: checkIfThisItemIsSelected(avatar?.id, true) && "primary-700P",
				isSelect: checkIfThisItemIsSelected(avatar?.id, true),
				action: () => onClickOnOptionOutsideAvatarRow(avatar?.id),
			})),
		[avatars, idSelectedOutsideAvatarRow],
	);

	const resetIsSelectedOutsideAvatarRow = () => {
		setIsSelectedOutsideAvatarRow(false);
		setIdSelectedOutsideAvatarRow(undefined);
	};

	const onClickOnOptionOutsideAvatarRow = (avatarId: string) => {
		setIdSelectedOutsideAvatarRow(avatarId);
		onClickOnUser(avatarId);

		if (avatarId === idSelectedOutsideAvatarRow) {
			resetSelection();
		}
	};
	const onClickOnAvatar = (avatar: AvatarType, index: number) => {
		setIndexSelected(index);
		resetIsSelectedOutsideAvatarRow();
		onClickOnUser(avatar?.id);

		if (index === indexSelected) {
			resetSelection();
		}
	};

	const itemSelectedOustideAvatarRow = () => {
		if (
			isSelectedOutsideAvatarRow &&
			idSelectedOutsideAvatarRow !== undefined
		) {
			return true;
		}
		return false;
	};

	const checkIfItsMyAvatar = (id: string, userId: string) => {
		return extractIdNumberFromId(id).toString() === userId.toString();
	};
	useEffect(() => {
		if (props.shouldBeReset) {
			resetSelection();
		}
	}, [props.shouldBeReset]);
	return (
		<div className="flex">
			{avatars
				?.filter((_, index) => index < MAX_AVATARS_TO_SHOW)
				?.map(({ id, firstname, lastname, filePath }: AvatarType, index) => {
					return (
						<div
							key={index}
							className={`h-[40px] w-[40px] min-w-[40px] p-2 cursor-pointer rounded-full flex justify-center items-center bg-white ${`z-[${index}]`} ${
								index < MAX_AVATARS_TO_SHOW && "border-2"
							} hover:border-primary-500 ${
								checkIfThisItemIsSelected(id, itemSelectedOustideAvatarRow())
									? "border-primary-700P"
									: "border-white"
							}`}
							onClick={() =>
								onClickOnAvatar({ id, firstname, lastname, filePath }, index)
							}
							style={{
								marginLeft: index > 0 ? -8 : 0,
								zIndex: Math.abs(index - avatars.length),
							}}
						>
							{index < MAX_AVATARS_TO_SHOW && (
								<span
									id={`avatar-${index}`}
									onClick={() => {
										resetIsSelectedOutsideAvatarRow();
									}}
								>
									<Avatar
										key={index}
										firstname={firstname}
										lastname={lastname}
										image={filePath || ""}
										size="xs"
									/>
									<TooltipContainer
										anchorId={`#avatar-${index}`}
										children={
											checkIfItsMyAvatar(id, user?.id || "")
												? ownerTooltip
												: `${tooltipPrefix} ${firstname} ${lastname}`
										}
										place="top"
										float={false}
									/>
								</span>
							)}
						</div>
					);
				})}
			<div className="flex justify-center items-center h-full -ml-2 mt-[2px]">
				{avatars.length > MAX_AVATARS_TO_SHOW && (
					<Dropdown
						button={
							<Avatar
								image=""
								firstname="+"
								lastname={`${avatars.length - MAX_AVATARS_TO_SHOW}`}
								className={`${
									dropdownItems?.find((item) => item.isSelect) &&
									"border-2 border-primary-700P"
								} [&>p]:text-primary-700P ![&>p]:text-xxsm bg-primary-200`}
								size="xs"
							/>
						}
						items={dropdownItems}
						direction="right"
						triggerOpening={() => {
							if (idSelectedOutsideAvatarRow === undefined) {
								setIsSelectedOutsideAvatarRow(!isSelectedOutsideAvatarRow);
							}
						}}
					/>
				)}
			</div>
		</div>
	);
}
