import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import {
	checkmarkCircle2Outline,
	closeCircleOutline,
	pinOutline,
	starFill,
} from "@assets/Icons";

import { Avatar } from "@components/atoms/Avatar";
import type { BadgeType } from "@components/atoms/Badge";
import { Badge } from "@components/atoms/Badge";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { TagsGroup } from "@components/atoms/TagsGroup";
import { useNavigate } from "react-router";
import type { User } from "../SelectionAPI";
import {
	APPRENTICESHIP_CONTRACT,
	CIVIC_CONTRACT,
	INTERNSHIP_CONTRACT,
	PERMANENT_CONTRACT,
	PROFESSIONALIZATION_CONTRACT,
	TEMPORARY_CONTRACT,
	VOLUNTEERING_CONTRACT,
} from "../SelectionAPI";

export type StudentStatus = 0 | 1 | 2 | 3;

type StudentCardType = {
	student: User;
	key: number;
};

export const getFrContractType = (contract: number) => {
	switch (contract) {
		case APPRENTICESHIP_CONTRACT:
			return "Apprentissage";
		case INTERNSHIP_CONTRACT:
			return "Stage";
		case TEMPORARY_CONTRACT:
			return "CDD";
		case PERMANENT_CONTRACT:
			return "CDI";
		case PROFESSIONALIZATION_CONTRACT:
			return "Professionnalisation";
		case VOLUNTEERING_CONTRACT:
			return "Volontariat";
		case CIVIC_CONTRACT:
			return "Service civique";
		default:
			return "Non renseigné";
	}
};

export const getBadgeByStatus = (status: StudentStatus): BadgeType | null => {
	switch (status) {
		case 0:
			return {
				label: "Nouveau !",
				bgColor: "bg-alert-error-100",
				textColor: "text-alert-error-500",
				borderColor: "border-alert-error-500",
			};
		case 2:
			return {
				label: "À rencontrer",
				bgColor: "bg-alert-success-vert-100",
				textColor: "text-alert-success-vert-500",
				borderColor: "border-alert-success-vert-500",
				leftIcon: checkmarkCircle2Outline,
			};
		case 3:
			return {
				label: "Refusé",
				bgColor: "bg-primary-100",
				textColor: "text-primary-500",
				borderColor: "border-primary-500",
				leftIcon: closeCircleOutline,
			};
		default:
			return null;
	}
};

export function StudentCard({ student, key }: StudentCardType) {
	const navigate = useNavigate();
	const [badge, setBadge] = useState<BadgeType | null>(null);
	useEffect(() => {
		setBadge(getBadgeByStatus(student.selectionStatus));
	}, []);
	return (
		<div
			className="flex w-full flex-col items-center justify-between rounded-2xl bg-white p-md md:w-[calc(50%-12px)] lg:w-[calc(33%-12.02px)] shadow-sm"
			key={key}
		>
			<div className="flex w-full h-full flex-col items-center gap-1 relative">
				{badge && (
					<Badge
						label={badge.label}
						bgColor={badge.bgColor}
						textColor={badge.textColor}
						borderColor={badge.borderColor}
						leftIcon={badge?.leftIcon}
						className="absolute top-0 right-0"
						size="small"
					/>
				)}
				{student.stars > 0 && (
					<div className="flex gap-1 flex-col absolute top-0 left-0">
						<Icon icon={starFill} className="text-secondary-600" />
						<span className="text-center text-primary-700P font-bold">
							{student.stars}
						</span>
					</div>
				)}
				<Avatar
					firstname={student.firstname}
					lastname={student.lastname}
					modify={false}
					size="lg"
					image={
						student.avatar
							? `${import.meta.env.VITE_S3}${student.avatar} `
							: null
					}
				/>
				<div className="flex flex-col items-center gap-xxsm">
					<p className="mt-xxsm font-bold text-primary-700P">
						{student.firstname} {student.lastname}
					</p>
					<p
						className={
							"flex items-center justify-center text-primary-700P text-xxsm"
						}
					>
						<Icon icon={pinOutline} /> {student.postalCode} - {student.city}
					</p>
				</div>
				<div className={"py-sm"}>
					<Badge
						label={getFrContractType(student.contractSearched)}
						size="small"
						bgColor="bg-alert-info-turquoise-100"
						textColor="text-alert-info-turquoise-500"
						borderColor="border-alert-info-turquoise-500"
					/>
				</div>
				<TagsGroup tags={student.tags} limit={5} />
				<div className="flex flex-1 items-end">
					<ButtonPrimary
						label="Voir le profil"
						className="mt-md"
						onClick={() => {
							navigate(`users/${student.id}`);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
