import type { EventType } from "@/types/eventsTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { type Dispatch, useContext, useState } from "react";

import { useOutletContext } from "react-router-dom";

import { editOutline, externalLinkOutline, plusOutline } from "@assets/Icons";

import { checkUrl } from "@tools/Checkers";
import { checkAccessMentorGoalEvent, isMentorGoalEvent } from "@tools/Events";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { ButtonSecondary } from "@components/atoms/ButtonSecondary";
import { Modal } from "@components/organisms/modal/Modal";
import { Success } from "@components/organisms/toast/ToastNotification";

import { updateEventRequest } from "@containers/shared/Events/core/api/events.request";

import { useAppDispatch } from "@config/redux/store";
import { eventsSlice } from "@containers/shared/Events/core/store/events.slice";

import { AccessReplayModal } from "../../modal/AccessReplayModal";
import { EditReplayLinkModal } from "../../modal/EditReplayLinkModal";

type OptionReplayProps = {
	event: EventType;
};
export function OptionReplay({ event }: OptionReplayProps) {
	const { user } = useContext(GlobalContext);
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const dispatchEvent = useAppDispatch();
	const replayLinkToAdd = useAppSelector(
		(state) => state.events.replayLinkToAdd,
	);
	const [isAccessReplayModalOpen, setIsAccessReplayModalOpen] =
		useState<boolean>(false);
	const [isEditReplayLinkModalOpen, setIsEditReplayLinkModalOpen] =
		useState<boolean>(false);

	const getModificationAccessStatus = () => {
		if (currentInterface === "school") {
			if (
				isMentorGoalEvent(event?.type) &&
				checkAccessMentorGoalEvent(user?.email || "")
			) {
				return true;
			}
			if (!isMentorGoalEvent(event?.type)) {
				return true;
			}
		}
		return false;
	};
	const handleLinkOppening = () => {
		if (event?.replayLink) {
			window.open(decodeURIComponent(event.replayLink), "_blank");
		}
	};

	const handleEditReplayLinkValidation = async () => {
		setIsEditReplayLinkModalOpen(false);
		const urlIsValid = checkUrl(replayLinkToAdd);
		if (urlIsValid) {
			const sanitizedEvent = {
				...event,
				illustration: event?.illustration?.id || null,
				invitations: event.invitations?.collection.map((invitation) => {
					return invitation.id;
				}),
				replayLink: replayLinkToAdd,
			};
			const response = await updateEventRequest(
				sanitizedEvent,
				user?.school as string,
			);
			if (response) {
				if (response.responseLabel === "success") {
					const modifiedEvent = {
						...event,
						replayLink: replayLinkToAdd,
					};
					Success("Le lien du replay a bien été ajouté.");
					dispatchEvent(
						eventsSlice.actions.refreshModifiedEvent(modifiedEvent),
					);
				} else {
					Success(`Une erreur est survenue : ${response.message}`);
				}
			}
		} else {
			Success(`Le lien renseigné n'est pas une URL valide.`);
		}
	};
	return (
		<>
			<div className="mt-2 flex flex-col items-center justify-center gap-4">
				{event.replayLink
					? renderLinkOptions(
							setIsAccessReplayModalOpen,
							setIsEditReplayLinkModalOpen,
							currentInterface === "school",
							getModificationAccessStatus(),
						)
					: renderNoLinkOptions(
							setIsEditReplayLinkModalOpen,
							currentInterface === "school",
							getModificationAccessStatus(),
						)}
			</div>
			<Modal
				title={`Replay ${event?.title}`}
				id="knowMoreModal"
				buttonsRight={[
					<ButtonSecondary
						label="Annuler"
						onClick={() => setIsAccessReplayModalOpen(false)}
					/>,
					<ButtonPrimary
						label="Accéder au replay"
						onClick={() => handleLinkOppening()}
						icon={externalLinkOutline}
					/>,
				]}
				show={isAccessReplayModalOpen}
				onClose={() => setIsAccessReplayModalOpen(false)}
				body={<AccessReplayModal />}
			/>
			<Modal
				title={`Ajout du replay ${event?.title}`}
				id="editReplayLinkModal"
				buttonsRight={[
					<ButtonSecondary
						label="Annuler"
						onClick={() => setIsEditReplayLinkModalOpen(false)}
					/>,
					<ButtonPrimary
						label="Ajout du replay"
						onClick={() => {
							handleEditReplayLinkValidation();
						}}
						disabled={!replayLinkToAdd || !checkUrl(replayLinkToAdd)}
					/>,
				]}
				show={isEditReplayLinkModalOpen}
				onClose={() => setIsEditReplayLinkModalOpen(false)}
				body={<EditReplayLinkModal />}
			/>
		</>
	);
}

const renderLinkOptions = (
	setIsAccessReplayModalOpen: Dispatch<boolean>,
	setIsEditReplayLinkModalOpen: Dispatch<boolean>,
	isSchool = true,
	modificationAccessGranted = false,
) => {
	return (
		<>
			<ButtonPrimary
				label="Accéder au replay"
				onClick={() => {
					setIsAccessReplayModalOpen(true);
				}}
				className="w-full"
			/>
			{!isSchool ? null : (
				<div className="flex w-full flex-col justify-between gap-2 sm:flex-row">
					{!modificationAccessGranted ? null : (
						<ButtonSecondary
							label="Modifier le lien du replay"
							icon={editOutline}
							className="flex-1"
							onClick={() => {
								setIsEditReplayLinkModalOpen(true);
							}}
						/>
					)}
				</div>
			)}
		</>
	);
};

const renderNoLinkOptions = (
	setIsEditReplayLinkModalOpen: Dispatch<boolean>,
	isSchool = true,
	modificationAccessGranted = false,
) => {
	if (!isSchool) {
		return (
			<ButtonPrimary
				label="Accéder au replay"
				onClick={() => {}}
				className="w-full"
				disabled
			/>
		);
	}
	if (modificationAccessGranted) {
		return (
			<>
				<ButtonSecondary
					label="Ajouter le lien du replay"
					icon={plusOutline}
					className="w-full"
					onClick={() => {
						setIsEditReplayLinkModalOpen(true);
					}}
				/>
			</>
		);
	}
	return null;
};
