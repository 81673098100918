import { Icon } from "@iconify/react";
import type { IconifyIcon } from "@iconify/types";
import React, { type MouseEventHandler } from "react";

import "@assets/sass/Button.scss";

type ButtonPrimaryType = {
	label?: string | undefined;
	name?: string;
	className?: string;
	disabled?: boolean;
	icon?: string | IconifyIcon | null;
	activeIcon?: string | IconifyIcon | undefined;
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
	dropdownButton?: boolean;
	shape?: string;
	size?: "small" | "large";
	id?: string;
	type?: string;
	iconPlacement?: "left" | "right";
};

export function ButtonPrimary({
	label = "",
	name,
	className = "",
	disabled = false,
	icon,
	onClick,
	dropdownButton = false,
	id = "",
	type = "button",
	size = "large",
}: ButtonPrimaryType) {
	return (
		<button
			id={id}
			data-id="buttonPrimary"
			name={name}
			type={type === "submit" ? "submit" : "button"}
			data-toggle={dropdownButton && "dropdown"}
			aria-haspopup={dropdownButton && true}
			aria-expanded={dropdownButton && false}
			onClick={onClick}
			disabled={disabled}
			className={`flex w-fit items-center justify-center rounded-md  transition-all
        ${size === "large" ? "p-xsm" : "p-xxsm"}
        ${className}
        ${
					disabled
						? "bg-primary-150 text-primary-300"
						: "bg-primary-700P text-white hover:bg-primary-500 active:bg-primary-300"
				}
      `}
		>
			{icon && (
				<Icon
					width={size === "large" ? 24 : 16}
					height={size === "large" ? 24 : 16}
					icon={icon}
				/>
			)}
			{label && (
				<span
					className={`whitespace-nowrap ${
						size === "large" ? "px-xsm text-xsm" : "px-xxsm text-xxsm"
					}`}
				>
					{label}
				</span>
			)}
		</button>
	);
}
