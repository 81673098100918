import React, { useEffect, useState } from "react";

import {
	clockOutline,
	closeCircleOutline,
	doneAllOutline,
	editOutline,
	eyeOutline,
	globe2Outline,
	peopleOutline,
} from "@assets/Icons";

import { StatsSecondary } from "@components/molecules/StatsSecondary";

export function StudentEssentialsByNumbers({ structure }) {
	const [essentials, setEssentials] = useState([]);
	const [status, setStatus] = useState([]);

	const defineEssentialsNumbers = () => {
		const totalStudent =
			structure.placed +
			structure.toPlace +
			structure.surrend +
			structure.beingSigned;

		setEssentials([
			{
				label: "Étudiants totaux",
				value: `${totalStudent}`,
				icon: peopleOutline,
				color: "text-primary-500",
				bgIcon: "bg-primary-100",
				tippyContent:
					"Nombre d’étudiants inscrits sur la plateforme Mentor Goal",
			},
			{
				label: "Placement global",
				value: `${
					totalStudent > 0
						? Math.round((structure.placed / totalStudent) * 100)
						: 0
				}%`,
				icon: eyeOutline,
				color: "text-accent-1-dark",
				bgIcon: "bg-info-light",
				tippyContent: "% d’étudiants placés par rapport au nombre d’inscrits",
			},
			{
				label: "Placement utilisateurs",
				value: `${structure.userPlacementRate}%`,
				icon: globe2Outline,
				color: "text-secondary-700",
				bgIcon: "bg-secondary-50",
				tippyContent:
					"% d’étudiants placés par rapport au nombre d’étudiants qui utilisent Mentor Goal",
			},
		]);
	};

	const defineStatusNumbers = () => {
		setStatus([
			{
				label: "Placés",
				value: structure.placed,
				icon: doneAllOutline,
				color: "text-accent-4-dark",
				bgIcon: "bg-accent-4-lighter",
				tippyContent: "Nombre d’étudiants avec le statut “placé”",
			},
			{
				label: "En cours de signature",
				value: structure.beingSigned,
				icon: editOutline,
				color: "text-accent-2-dark",
				bgIcon: "bg-accent-2-lighter",
				tippyContent:
					"Nombre d’étudiants avec le statut “en cours de signature”",
			},
			{
				label: "À placer",
				value: structure.toPlace,
				icon: clockOutline,
				color: "text-primary-500",
				bgIcon: "bg-primary-100",
				tippyContent: "Nombre d’étudiants avec le statut “à placer”",
			},
			{
				label: "Abandons",
				value: structure.surrend,
				icon: closeCircleOutline,
				color: "text-accent-3-dark",
				bgIcon: "bg-accent-3-lighter",
				tippyContent: "Nombre d’étudiants avec le statut “abandons”",
			},
		]);
	};

	useEffect(() => {
		if (structure) {
			defineEssentialsNumbers();
			defineStatusNumbers();
		}
	}, [structure]);

	return (
		<div>
			<p className="font-semifont-bold pb-sm text-xsm text-primary-700P">
				L&apos;essentiel des étudiants en chiffres
			</p>
			<div className="-m-sm flex flex-col overflow-x-scroll p-sm scrollbar-hide">
				<div className="flex">
					{essentials?.map((item, i) => {
						return (
							<StatsSecondary
								key={i}
								label={item.label}
								value={item.value}
								icon={item.icon}
								iconColor={item.color}
								color={item.color}
								bgIcon={item.bgIcon}
								tippyContent={item.tippyContent}
							/>
						);
					})}
				</div>
				<div className="mt-md flex">
					{status?.map((item, i) => {
						return (
							<StatsSecondary
								key={i}
								label={item.label}
								value={item.value.toString()}
								icon={item.icon}
								iconColor={item.color}
								color={item.color}
								bgIcon={item.bgIcon}
								tippyContent={item.tippyContent}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
