import React from "react";

import { ScrollableTabGroup } from "@components/molecules/TabGroup";
import RepliesSkeleton from "./RepliesSkeleton";
import { ReplyListHelp } from "./ReplyListHelp";
import ReplyViewCard from "./ReplyViewCard";
import { useRepliesModal } from "./useRepliesModal";

export function ReplyListModal() {
	const presenter = useRepliesModal();
	return (
		<div className="flex flex-col gap-6">
			<ReplyListHelp />
			<div>
				<ScrollableTabGroup
					tabs={[
						{
							label: `Inscrits (${presenter.isFetchingCounters ? "..." : presenter.registeredCounter})`,
							active: presenter.repliesTabSelected === 0,
						},
						{
							label: `Peut-être (${presenter.isFetchingCounters ? "..." : presenter.maybeCounter})`,
							active: presenter.repliesTabSelected === 1,
						},
						{
							label: `Invités (${presenter.isFetchingCounters ? "..." : presenter.noReplyCounter})`,
							active: presenter.repliesTabSelected === 2,
						},
						{
							label: `Ne participent pas (${presenter.isFetchingCounters ? "..." : presenter.notParticipatinCounter})`,
							active: presenter.repliesTabSelected === 3,
						},
					]}
					onTabChange={(index) => presenter.selectThisRepliesTab(index)}
				/>
			</div>
			<article className="flex flex-col justify-center items-center min-h-32">
				{presenter.isFetchingInvitations && (
					<div className="h-96 max-h-96 overflow-hidden w-full pr-6 flex flex-col gap-4">
						<RepliesSkeleton />
					</div>
				)}
				{presenter.repliesToShow.length === 0 &&
					!presenter.isFetchingInvitations && (
						<p className="text-center text-gray-500 h-96 flex justify-center items-center">
							Aucune réponse
						</p>
					)}

				{!presenter.isFetchingInvitations &&
					presenter.repliesToShow.length > 0 && (
						<div className="h-96 max-h-96 overflow-auto w-full pr-6 flex flex-col gap-4">
							{presenter.repliesToShow?.map((replyData) => (
								<ReplyViewCard replyData={replyData} />
							))}
						</div>
					)}
			</article>
		</div>
	);
}
