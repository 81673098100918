import { useBreakpoints } from "@hooks/useBreakpoints";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import "../ApplicationDetails.scss";

import { capitalizeFirstLetter } from "@tools/Strings";

import { AccordionPrimarySmall } from "@components/organisms/Accordion";

export function Notes({ notes }) {
	const { isMobile } = useBreakpoints();
	return (
		<div className="notes-container">
			{notes?.length > 0 ? (
				<div>
					<h1 className="text-sm font-bold text-primary-700P">
						Toutes les notes
					</h1>
					{notes.map((note) => {
						const date = capitalizeFirstLetter(
							moment(note.date.split("/").reverse().join("-")).format(
								isMobile ? "dD/MM" : "ddd DD MMMM",
							),
						);

						return (
							<span key={note.id}>
								<AccordionPrimarySmall
									label={date}
									title={isMobile ? "Note ajoutée" : note.content}
									subtitle={note.content}
									content={note.content}
								/>
							</span>
						);
					})}
				</div>
			) : (
				<p className="text-primary-300">Aucune note ajoutée</p>
			)}
		</div>
	);
}

Notes.propTypes = {
	notes: PropTypes.arrayOf.isRequired,
};
