import { getCookie } from "@tools/Cookies";
import axios from "axios";

export async function login(data: { email: string | null; password: string }) {
	let rep: { token?: string } = {};
	await axios
		.create({
			baseURL: `${import.meta.env.VITE_BACK}/`,
			timeout: 20000,
			headers: {
				"Content-Type": "application/json",
				"X-Forwarded-For": getCookie("ip"),
			},
		})
		.post("authentication_token", data)
		.then(({ data }) => {
			rep = data;
		});

	return rep;
}
