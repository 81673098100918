import DOMPurify from "dompurify";
import React, { useContext } from "react";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";

import logo from "@containers/public/Selection/assets/logo.svg";
import { GlobalContext } from "@navigation/Router";

export function ShareMailPreview({
	message,
	subject,
}: {
	subject: string;
	message: string;
}) {
	const { user } = useContext(GlobalContext);

	return (
		<div className="flex-1 flex-col overflow-auto">
			<p className="text-primary-200 text-xxsm mb-sm uppercase">
				Prévisualisation de votre message
			</p>
			<div className="shadow-sm p-xsm rounded-md border-[1px] border-primary-150 relative">
				<div className="mt-md mb-lg flex flex-col">
					<img
						src={user?.schoolLogo || logo}
						alt="logo"
						className="mx-auto mb-sm rounded-full bg-primary-100 h-[75px] w-auto"
					/>
					<h3 className="!text-md !font-bold text-center">{subject}</h3>
				</div>

				<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
				<div className="flex mt-lg mb-xxlg">
					<ButtonPrimary className="mx-auto" label="Voir les profils" />
				</div>
				<p className="text-xxxsm text-center mb-md">
					Envoyé via Mentor Goal <br />
					<br />
					Ne plus recevoir d'email de Mentor Goal
				</p>
			</div>
		</div>
	);
}
