export const amosConfig = {
	auth: {
		clientId: "b131757b-359c-48a9-9503-0face05ae5c6",
		authority:
			"https://login.microsoftonline.com/7c5167bd-e1d7-4564-b2c2-bdc37def9c9c",
		redirectUri: "https://amos.mentorgoal.com",
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
};

export const esdacConfig = {
	auth: {
		clientId: "3847eaeb-1739-4412-8a7c-406bbf4166b4",
		authority:
			"https://login.microsoftonline.com/14e7f56b-7371-419b-ae55-fb06fec031c1",
		redirectUri: "https://esdac.mentorgoal.com",
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
};

export const cmhConfig = {
	auth: {
		clientId: "6d7b352d-ec0a-4eb0-b495-cd12f0e04d0d",
		authority:
			"https://login.microsoftonline.com/6fe0775e-be0a-4ff5-a0be-a4cbd92b0595",
		redirectUri: "https://cmh.mentorgoal.com",
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
};

export const esieaConfig = {
	auth: {
		clientId: "f3d697d3-748c-47e5-a619-26a4d3b88291",
		authority:
			"https://login.microsoftonline.com/2397b885-afe4-4bc7-9e1e-e4899ef44d1a",
		redirectUri: "https://esiea.mentorgoal.com",
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
};

export const loginRequest = {
	scopes: ["User.Read"],
};

export const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
