import type { EventType } from "@/types/eventsTypes";
import React from "react";

type OptionReplayProps = {
	event: EventType;
};
export function OptionFinished({ event }: OptionReplayProps) {
	return (
		<div className="flex flex-col items-start justify-start gap-4">
			{event?.address && (
				<div className="flex flex-col">
					<p className="text-xsm font-bold text-primary-900">Lieu :</p>
					<p className="text-xsm font-normal text-primary-500">
						{event.address}
					</p>
				</div>
			)}
		</div>
	);
}
