import type { MediaObjectType } from "@/types/mediaObjectTypes";
import type { UserCompleteType } from "@/types/userTypes";
import React, { useEffect } from "react";

import {
	checkMarkCircleOutline,
	emailOutline,
	refreshOutline,
} from "@assets/Icons";

import { DAYS_BETWEEN_RELAUNCH } from "@constants/CvBoard";

import { convertDateFromNow, diffDate, formatDate } from "@tools/Dates";

import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";
import { FileInput } from "@components/molecules/FileInput";
import {
	ErrorToast,
	Success,
} from "@components/organisms/toast/ToastNotification";

import { relaunchCVStudent, sendFile } from "../../../CVBoardAPI";

type ToValidateType = {
	student: UserCompleteType;
	load: boolean;
	setLoad: (param: boolean) => void;
	setFileUpload: Function;
	changeStep: (
		newStep: string,
		refusalMotive?: string,
		validMotive?: string,
		sendMail?: boolean,
		updatedStudent?: Partial<UserCompleteType> | null,
	) => void;
};

export function Editing({
	student,
	load,
	setLoad,
	setFileUpload,
	changeStep,
}: ToValidateType) {
	const handleOnRelaunchStudent = async (studentId: string) => {
		if (diffDate(new Date(student.cvRelaunchDate)) >= DAYS_BETWEEN_RELAUNCH) {
			setLoad(true);
			await relaunchCVStudent(studentId);
			Success("Étudiant relancé avec succès. Il va recevoir un mail.");
			student.cvRelaunchDate = new Date().toString();
			setLoad(false);
		}
	};

	const importCV = async (cv: any) => {
		setLoad(true);
		const formData = new FormData();

		formData.append("file", cv);
		formData.append("type", "cv");
		formData.append("ownerId", student.id.split("/")[3]);
		formData.append("state", "validated");

		const response = (await sendFile(formData)) as MediaObjectType;
		let updatedStudent: Partial<UserCompleteType> | null = null;
		if (response) {
			updatedStudent = {
				cv: {
					...response,
					id: response["@id"],
					spsStudent: student.id,
				},
			};

			setFileUpload({
				...response,
				...cv,
				id: response["@id"],
				importedByReferent: true,
				spsStudent: student.id,
			});
		}

		setLoad(false);

		changeStep("toValidate", undefined, undefined, false, updatedStudent);
		Success("L'importation a été réalisée avec succès !");
	};
	useEffect(() => {}, [student]);
	return (
		<>
			<div className="relative mb-4 rounded-2xl bg-secondary-50 p-sm  ">
				<p>
					Le CV est en attente de modification depuis le{" "}
					{formatDate(new Date(student.cv?.updatedAt ?? ""))}.
				</p>
				<InlineButton
					id="cancel-decision"
					onClick={() => changeStep("toValidate", undefined, undefined, false)}
					className="mt-xxsm"
					label="Annuler la décision"
					disabled={load}
					icon={refreshOutline}
				/>
			</div>
			<p className="mb-4 mt-6">
				Vous pouvez relancer votre étudiant. En cliquant sur le bouton
				ci-dessous, il recevra une notification pour lui rappeler d’importer son
				CV.
			</p>
			<ButtonPrimary
				onClick={() => handleOnRelaunchStudent(student.id.split("/")[3])}
				icon={
					diffDate(new Date(student.cvRelaunchDate)) < DAYS_BETWEEN_RELAUNCH
						? checkMarkCircleOutline
						: emailOutline
				}
				label="Relancer l'étudiant"
				disabled={
					diffDate(new Date(student.cvRelaunchDate)) < DAYS_BETWEEN_RELAUNCH
				}
			/>
			<p className="mb-sm text-xxsm text-primary-500">
				{student.cvRelaunchDate
					? `relancé ${convertDateFromNow(new Date(student.cvRelaunchDate))}`
					: "Jamais relancé"}
			</p>
			<p className="mb-sm font-normal text-primary-700P">
				Votre étudiant a modifié son CV et vous l’a transmis directement ? Vous
				pouvez le déposer à sa place ci-dessous.
			</p>

			<FileInput
				acceptFormats={{ "application/pdf": [] }}
				isLoading={load}
				id="cv-uploaded"
				maxSize={1}
				onSuccess={(e: any) => {
					importCV(e[0]);
				}}
				onError={() => {
					ErrorToast("une erreur est survenue");
				}}
			/>
		</>
	);
}
