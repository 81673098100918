import { setDefaultOptions } from "date-fns";

import {
	DATEFNS_LOCALES,
	type LangType,
	SUPPORTED_LANGUAGES_CODES,
} from "@constants/Locales";
export const changeAppLanguage = (language: string) => {
	const languageCode = language.split("-")[0];
	if (!SUPPORTED_LANGUAGES_CODES.includes(languageCode as LangType)) {
		return;
	}
	Weglot.switchTo(language);
	// sets datefns locale
	setDefaultOptions({ locale: DATEFNS_LOCALES()[language] });
};
