import { Icon } from "@iconify/react";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { briefcaseOutline, pinOutline } from "@assets/Icons";

export function OfferSkeleton() {
	const color = "#e9f5e4";
	return (
		<div className="flex flex-col gap-sm">
			<div className="flex flex-col gap-xxsm rounded-md shadow-md">
				<div
					id="offer"
					className="flex w-full cursor-pointer justify-between rounded-md bg-white p-sm shadow-sm"
				>
					<div id="leftPart" className="flex flex-col">
						<div className="flex w-fit flex-col text-xxsm font-bold text-primary-200">
							<Skeleton width={240} height={16} />
						</div>
						<div className="mt-sm items-center">
							<div className="flex items-center">
								<Icon
									icon={briefcaseOutline}
									className="mr-xxsm"
									height="16px"
								/>
								<Skeleton width={120} height={16} />
								<Icon
									icon={pinOutline}
									className="ml-sm mr-xxsm"
									height="16px"
								/>
								<Skeleton width={120} height={16} />
							</div>
						</div>
						<div className="mt-sm">
							<Skeleton width={180} height={16} />
						</div>
					</div>
					<div id="rightPart" className="h-full">
						<div className="ml-auto h-full">
							<Skeleton
								className="mb-auto"
								width={90}
								height={16}
								baseColor={color}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
