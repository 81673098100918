import React from "react";
import Skeleton from "react-loading-skeleton";

export function ContactSkeletons() {
	return (
		<div className="flex flex-col justify-start items-start gap-sm p-sm">
			<Skeleton width={500} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={300} height={48} />
			<Skeleton width={30} height={48} />
		</div>
	);
}
