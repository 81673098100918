import { Icon } from "@iconify/react";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { briefcaseOutline, pinOutline } from "@assets/Icons";

export function OfferCardsSkeleton() {
	const skeletons = Array(3)
		.fill(["#e9f5e4", "#fdf0e4", null, "#fbe6e1"])
		.flat();

	return (
		<div className="mt-lg flex flex-col gap-sm">
			{skeletons.map((color) => {
				return (
					<div className="flex flex-col gap-xxsm transition-shadow hover:shadow-lg">
						<div
							id="offer"
							className="flex w-full cursor-pointer flex-col justify-between rounded-t-lg bg-white p-sm shadow-sm md:flex-row  md:items-end md:rounded-lg"
						>
							<div id="leftPart" className="flex flex-col">
								<div className="flex w-fit flex-col text-xxsm font-bold text-primary-200">
									<Skeleton width={180} height={16} />
								</div>
								<div className="mt-sm flex items-center gap-md">
									<div className="hidden md:block">
										<Skeleton baseColor={color} circle height={44} width={44} />
									</div>
									<div className="flex max-w-full flex-col">
										<div className="flex items-center gap-xxsm">
											<Skeleton width={260} height={16} />
										</div>
										<div className="mt-xxsm flex gap-md text-primary-700P">
											<div className="flex items-center">
												<Icon
													icon={briefcaseOutline}
													className="mr-xxsm"
													height="16px"
												/>
												<Skeleton width={240} height={16} />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								id="rightPart"
								className="mt-xsm flex items-end md:flex-col md:gap-xxsm"
							>
								<Skeleton width={90} height={25} baseColor={color} />
								<div className="ml-xsm flex items-center text-primary-300 ">
									<Icon icon={pinOutline} className="mr-xxsm" height="16px" />
									<Skeleton width={120} height={16} />
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}
