import type { EntOffersType } from "@/types/EntCompanyTypes";

import api from "@tools/API";

export async function queryLatestOffers(profileId: number) {
	const data = {
		query: `query {
              entOffers(entCompanySchoolProfile: "${profileId}", status_list:["active", "pending", "new"], order: [{createdAt: "DESC"}],itemsPerPage:2) {
                collection {
                  id
                  job
                  description
                  location
                  telework
                  qualification
                  status
                  link
                  contract
                  emailPreference
                  createdAt
                  spsSelection {
                    id
                    name
                    token
                    lastOpening
                    userLastAdded
                    selectionUsers {
											collection {
												id
											} 
                  	}
                  }
                }
              }
            }`,
	};
	try {
		const rep = await api.post("graphql", data);
		const collection: EntOffersType[] = rep.data.data.entOffers.collection;
		return collection;
	} catch (error) {
		throw new Error(error);
	}
}
