import type {
	OffersFiltersContextType,
	OffersFiltersType,
} from "@/types/offersFiltersTypes";
import React, { createContext } from "react";

import { ContractFilter } from "./Filters/ContractFilter";
import { LocationFilter } from "./Filters/LocationFilter";
import { ReferentFilter } from "./Filters/ReferentFilter";
import { TagsFilter } from "./Filters/TagsFilter";
import { TypeFilter } from "./Filters/TypeFilter";
import { VisibilityFilter } from "./Filters/VisibilityFilter";

export const OffersFiltersContext = createContext<OffersFiltersContextType>({
	filters: {},
	setFilters: () => {},
});

export function OffersFilters({ filtersProps }: OffersFiltersType) {
	const { filters, setFilters } = filtersProps;
	return (
		<OffersFiltersContext.Provider value={{ filters, setFilters }}>
			<section
				className="
        flex w-full flex-col  gap-sm
        divide-y-2 divide-solid divide-primary-150
        rounded-lg border-[1px] border-primary-150 
        p-sm"
			>
				<div className="flex flex-wrap gap-sm">
					<VisibilityFilter />
					<TypeFilter />
					<ContractFilter />
					<ReferentFilter />
				</div>
				<div className="flex flex-col gap-sm pt-sm">
					<LocationFilter />
					<TagsFilter />
				</div>
			</section>
		</OffersFiltersContext.Provider>
	);
}
