import type { ReferentType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { extractIdNumberFromId } from "@tools/Users";

import {
	AvatarGroup,
	type AvatarType,
} from "@components/molecules/AvatarGroup";

import { getReferents } from "../OffersFiltersAPI";
import { countCurrentFilters, setCurrentFilters } from "../offersFiltersSlice";

export function ReferentFilter() {
	const { user } = useContext(GlobalContext);
	const dispatchEvent = useDispatch();
	const { currentFilters, useFilters } = useAppSelector(
		(state) => state.offersFilters,
	);
	const [referents, setReferents] = useState<ReferentType[] | undefined>();
	const avatarGrpRef = useRef<any>(null);

	const placeOwnerAtTop = (referents: ReferentType[]) => {
		const referentsCopy = [...referents];
		const ownerReferent = referents?.find(
			(referent) =>
				extractIdNumberFromId(referent?.id || "") === Number(user?.id),
		);
		if (ownerReferent) {
			referentsCopy.splice(referentsCopy.indexOf(ownerReferent), 1);
			referentsCopy.unshift(ownerReferent);
		}
		return referentsCopy;
	};

	const getReferentOptions = async () => {
		const response = await getReferents(user?.school || "");
		if (response.responseLabel === "success") {
			setReferents(
				placeOwnerAtTop(response?.data?.schoolReferents?.collection),
			);
		}
	};

	const buildReferentAvatars = () => {
		let referentAvatars: AvatarType[] = [];
		if (referents !== undefined && referents?.length !== 0) {
			referentAvatars = referents
				?.sort((referent) => (referent.lastname === user?.lastname ? -1 : 1))
				.map(
					(referent) =>
						({
							id: referent?.id,
							firstname: referent?.firstname,
							lastname: referent?.lastname,
							filePath: referent?.avatar?.filePath,
						}) as AvatarType,
				);
		}
		return referentAvatars;
	};

	const handleAvatarClick = (referentSelectedId: string) => {
		const filterShouldBeReset = referentSelectedId === currentFilters.referent;
		dispatchEvent(
			setCurrentFilters({
				...currentFilters,
				referent: filterShouldBeReset ? undefined : referentSelectedId,
			}),
		);
		dispatchEvent(countCurrentFilters());
	};

	useEffect(() => {
		if (useFilters === true) getReferentOptions();
	}, [user, useFilters]);

	return (
		<div className="h-full w-32" ref={avatarGrpRef}>
			<AvatarGroup
				onClickOnUser={(referentSelectedId) => {
					handleAvatarClick(referentSelectedId);
				}}
				avatars={buildReferentAvatars()}
				tooltipPrefix="Offres de "
				ownerTooltip="Mes offres"
				defaultSelectedId={currentFilters?.referent}
				shouldBeReset={currentFilters?.referent === undefined}
			/>
		</div>
	);
}
