import type { ConversationStatusType, ConversationType } from "@/types/mailbox";
import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { doubleCheck } from "@assets/Icons";

import { ButtonSecondary } from "@components/atoms/ButtonSecondary";

import { fetchUpdateConversation } from "../../controller/mailBoxManagement.thunks";

export default function ConversationButton() {
	const dispatchMailBox = useDispatch();
	const { studentSelected, conversation } = useAppSelector(
		(state) => state.mailBox,
	);

	const editConversation = async (status: ConversationStatusType) => {
		if (!studentSelected) return;
		const query: ConversationType = {
			id: studentSelected?.conversation?.id,
			status,
		};

		dispatchMailBox(fetchUpdateConversation(query) as any);
	};
	if (conversation?.status === "resolved") {
		return (
			<ButtonSecondary
				className="text-primary-700P [&>span]:font-semibold !p-xxsm"
				label="Marquer la conversation comme non résolue"
				onClick={() => {
					editConversation("unsolved");
				}}
			/>
		);
	}
	return (
		<ButtonSecondary
			className="text-primary-700P [&>span]:font-semibold !p-xxsm"
			label="Marquer la conversation comme résolue"
			icon={doubleCheck}
			onClick={() => {
				editConversation("resolved");
			}}
		/>
	);
}
