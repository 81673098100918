import { Icon, type IconifyIcon } from "@iconify/react";
import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";

import { externalLinkOutline } from "@assets/Icons";

import { checkUrl } from "@tools/Checkers";

import { ButtonTertiary } from "@components/atoms/ButtonTertiary";
import { Divider } from "@components/atoms/Divider";

type InputSecondaryType = {
	type?: string;
	name: string;
	value: string;
	onChange?: (param: any) => void;
	label?: string;
	placeholder: string;
	className?: string;
	icon?: IconifyIcon;
	disabled?: boolean;
	errorMessage?: string;
	helperText?: string;
	onBlur?: () => {};
	useAutofocus?: boolean;
};

export function InputSecondary({
	type = "text",
	name,
	value,
	onChange,
	label,
	placeholder,
	className = "",
	icon,
	disabled = false,
	errorMessage = "",
	helperText = "",
	onBlur,
}: InputSecondaryType) {
	const [inputValue, setInputValue] = useState(value);
	const [isValidUrl, setIsValidUrl] = useState(false);

	useEffect(() => {
		setInputValue(value);
		setIsValidUrl(checkUrl(value));
	}, [value]);

	return (
		<div
			data-id="InputSecondary"
			className={`input-secondary-container ${className}`}
		>
			{label && (
				<p
					className={`text-p mb-xxsm text-primary-300 ${
						disabled && "is-disabled"
					}`}
				>
					{label}
				</p>
			)}
			<div className="flex items-center">
				<div className="input-secondary flex w-full items-center">
					<div className="flex w-11/12 items-center gap-xsm">
						{icon && (
							<Icon
								className={`${disabled && " is-disabled"}`}
								icon={icon}
								height={24}
								width={24}
							/>
						)}
						<input
							autoComplete="off"
							onBlur={onBlur}
							type={type}
							name={name}
							value={inputValue}
							onChange={(e) => {
								if (onChange) onChange(e);
								setInputValue(e.target.value);
							}}
							placeholder={placeholder}
							className={`w-11/12 truncate bg-primary-50 pr-xlg outline-0 ${
								icon && " with-icon"
							} ${errorMessage && "error-message"} ${
								disabled && "value-disabled"
							}`}
							disabled={disabled}
						/>
					</div>
					{isValidUrl && (
						<div className="flex items-center">
							<Divider className="vertical-separator mr-xsm" />
							<Tippy
								content="Consulter le lien"
								theme="primary"
								animation="fade"
								placement="top"
								zIndex={10}
							>
								<span>
									<ButtonTertiary
										className="!p-[5.5px]"
										iconClassName="!h-[13px] !w-[13px]"
										icon={externalLinkOutline}
										onClick={() => {
											window.open(decodeURIComponent(value), "_blank");
										}}
									/>
								</span>
							</Tippy>
						</div>
					)}
				</div>
				{helperText && !errorMessage && (
					<p className="text-p pt-xxsm text-primary-200">{helperText}</p>
				)}
				{errorMessage && (
					<p className="text-p pt-xxsm text-error-dark">{errorMessage}</p>
				)}
			</div>
		</div>
	);
}
