import type { LogType } from "@/types/logTypes";
import { format } from "date-fns";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

import { resolveLog } from "@tools/Logs";

import { Avatar } from "@components/atoms/Avatar";
import { ButtonPrimary } from "@components/atoms/ButtonPrimary";
import { InlineButton } from "@components/atoms/InlineButton";

import type { UserType } from "@/types/userTypes";
import { refreshFill } from "../../../assets/Icons";

import { getLogHistory } from "./HistoryAPI";
import { HistorySkeleton } from "./HistorySkeleton";
import { LogDescription } from "./LogDescription";

type HistoryType = {
	currentStudent: UserType;
	filters: { action_list: string[]; page: number };
	className?: string;
	showByDefault?: boolean;
	formatLogLabelFunction?: (logLabel: string) => string;
	formatLogDescriptionFunction?: (logDescription: string) => string;
};
type UpdateHistoryType = {
	updateHistory: number;
	setUpdateHistory: Function;
};
export const HistoryContext = createContext<UpdateHistoryType>({
	updateHistory: 0,
	setUpdateHistory: () => {},
});

export function History({
	currentStudent,
	filters,
	className = "mb-4 ml-4 mt-4 rounded-2xl md:w-full lg:mb-0 lg:ml-0",
	showByDefault = false,
	formatLogLabelFunction,
	formatLogDescriptionFunction,
}: HistoryType) {
	const [load, setLoad] = useState<boolean>(false);
	const [nextLoad, setNextLoad] = useState<boolean>(false);
	const [showHistory, setShowHistory] = useState<boolean>(showByDefault);
	const [error, setError] = useState<boolean>(false);
	const [lastPage, setLastPage] = useState<number>(0);
	const [history, setHistory] = useState<LogType[]>([]);
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const { updateHistory } = useContext(HistoryContext);
	useEffect(() => {
		if (showHistory) {
			filters.page = 1;
			toggleHistory();
		}
	}, [updateHistory]);
	async function toggleHistory() {
		setLoad(true);
		let history: { collection?: any[]; paginationInfo?: { lastPage?: number } };
		try {
			history = await getLogHistory(currentStudent, filters);
			setHistory(history.collection!);
			setLastPage(history.paginationInfo!.lastPage!);
		} catch {
			setError(true);
		} finally {
			setShowHistory(true);
			setLoad(false);
		}
	}
	useEffect(() => {
		if (!showByDefault) {
			setShowHistory(false);
			setHistory([]);
			setError(false);
		} else {
			toggleHistory();
		}
	}, [currentStudent.id]);

	function toggleSeeMore(index: number) {
		const newLogs = [...history];
		newLogs[index].seeMore
			? (history[index].seeMore = false)
			: (history[index].seeMore = true);
		setHistory(newLogs);
	}

	async function getNextLogs() {
		filters.page = filters.page + 1;
		let nextLogs: {
			collection?: any[];
			paginationInfo?: { lastPage?: number };
		};
		setNextLoad(true);
		try {
			nextLogs = await getLogHistory(currentStudent, filters);
			setHistory(history.concat(nextLogs.collection!));
		} catch {
			setError(true);
		} finally {
			setNextLoad(false);
		}
	}

	return (
		<div
			className={`h-fit-content flex flex-col gap-6 rounded-lg bg-white p-sm ${className}`}
		>
			<p className="text-sm font-bold">Historique</p>
			{load ? (
				<div className="flex">
					<HistorySkeleton />
				</div>
			) : showHistory ? (
				<>
					{error ? (
						<p className="font-bold text-accent-3-dark">
							Une erreur est survenue.
						</p>
					) : history.length === 0 ? (
						<p className="font-semibold">L'historique est vide.</p>
					) : (
						history?.map((log: LogType, index: number) => {
							const label = resolveLog(log, currentInterface)!.label;
							return (
								<div className={"flex rounded-2xl p-2"}>
									<div className="w-full">
										<p className="mb-1 flex items-center font-bold">
											<div className="mr-xxsm">
												<Avatar
													image={currentStudent?.avatar?.filePath}
													firstname={currentStudent.firstname}
													lastname={currentStudent.lastname}
													size="xs"
												/>
											</div>
											<span className="wg-no-translate">
												{currentStudent.firstname} {currentStudent.lastname}
											</span>
											<span className="ml-auto text-[16px] font-normal text-primary-300">
												Le {format(new Date(log.createdAt!), "dd/MM/yyyy")}
											</span>
										</p>

										{label.length > 60 ? (
											<>
												<p className="mt-1 text-xxsm">
													{log.seeMore ? label : `${label.slice(0, 60)}...`}
												</p>
												<InlineButton
													label={log.seeMore ? "Voir moins" : "Voir plus"}
													onClick={() => {
														toggleSeeMore(index);
													}}
												/>
											</>
										) : (
											<p
												dangerouslySetInnerHTML={{
													__html: formatLogLabelFunction
														? formatLogLabelFunction(label)
														: label,
												}}
											/>
										)}

										<LogDescription
											log={log}
											formatLogDescriptionFunction={
												formatLogDescriptionFunction
											}
										/>
									</div>
								</div>
							);
						})
					)}
				</>
			) : (
				<ButtonPrimary
					className="w-fit"
					onClick={toggleHistory}
					label="Voir l'historique"
					iconPlacement="right"
					icon={refreshFill}
				/>
			)}
			{!error && showHistory && !load && (
				<>
					{filters.page < lastPage && !nextLoad && (
						<InlineButton
							className="mx-auto w-fit"
							onClick={getNextLogs}
							disabled={nextLoad}
							label="Voir plus"
						/>
					)}
					{nextLoad && <HistorySkeleton />}
				</>
			)}
		</div>
	);
}
